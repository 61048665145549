import React from 'react';
import PropTypes from 'prop-types';
import ReactCounterInput from "react-counter-input";


export default class CounterInput extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props}  the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = { labelFocused: false };

        this.onBlur = this.onBlur.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    /**
     * Fired once the input focus changes.
     *
     * @param {bool} labelFocused determines if the element is focused or not.
     */
    changeFocus(labelFocused) {
        this.setState({ labelFocused });
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event.target.value);
        }
        this.changeFocus(true);
    };

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(event.target.value);
        }
        this.changeFocus(false);
    }

    /**
     * Fired once the input text changes.
     *
     * @param {Proxy.dispatcher} event  contains the input's new value.
     */
    onTextChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <label className="form-label">
                {this.props.label &&
                    <span className="label">
                        {this.props.icon && (
                            <span className="fl__icon fl__icon--left">
                                <i className={this.props.icon} />
                            </span>
                        )}
                        {this.props.label}
                    </span>
                }
                <div
                    style={{height: '44px', padding: '4px 2% 8px'}}
                    className="form-control text-center">
                    <ReactCounterInput
                        disabled={this.props.disabled}
                        min={0}
                        max={this.props.max}
                        count={this.props.value}
                        wrapperStyle={this.props.wrapperStyle}
                        inputStyle={this.props.inputStyle}
                        onCountChange={this.onTextChange}
                    />
                </div>

            </label>
        );
    }
}

CounterInput.defaultProps = {max: 100};

/**
 * Phone input properties validation.
 */
CounterInput.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};
