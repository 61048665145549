'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';
import InformationModal from 'common/components/information-modal';

import ForgotPasswordForm from './forgot-password-form';

function mapDispatchToProps(dispatch) {
    return {
        forgotPassword: (email) => {
            dispatch(userActions.forgotPassword(email));
        },
        validateEmail: (email) => {
            dispatch(userActions.validateEmail(email));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        authUser: userSelectors.getAuthUser(state),
        emailSent: userSelectors.getEmailSent(state),
        error: userSelectors.getError(state),
        isValidEmail: userSelectors.getIsValidEmail(state),
        validateEmailError: userSelectors.getValidateEmailError(state)
    };
};

/**
 * Container which handles the forgot password logic.
 */
class ForgotPasswordContainer extends React.Component {

    /**
     * Set the states and makes the binds as needed.
     */
    constructor(props) {
        super(props);

        this.onSend = this.onSend.bind(this);
        this.changeEmail = this.changeEmail.bind(this);

        this.state = {email: ''};
    }

    changeEmail(email) {
        this.setState({ email });
    }

    /**
     * Fired once the user attempts to log in.
     */
    onSend() {
        const email = this.state.email;

        this.props.forgotPassword(email);
    }

    onValidateEmail = (email) => {
        if (email !== '') {
            this.props.validateEmail(email);
        }
    };

    componentDidMount() {
        this.props.resetUserMessages();
    }

    renderInformationModal() {
        return (
            <InformationModal imageSuccess="/images/email_sent.svg" showModal={this.props.emailSent}>
                <div className="text-center">
                    <p>Tu enlace ha sido enviado!</p>
                    <div style={{margin: '0px 15%'}}>
                        <button
                            onClick={() => this.props.resetUserMessages()}
                            className="btn btn-outline">
                            Entendido
                        </button>
                    </div>
                </div>
            </InformationModal>
        );
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <React.Fragment>
                <div className="text-center">
                    <p>Te enviaremos un enlace para restablecer tu contraseña.</p>
                </div>

                <ForgotPasswordForm
                    email={this.state.email}
                    validateEmailError={this.props.validateEmailError}
                    emailSent={this.props.emailSent}
                    onSend={this.onSend}
                    isValidEmail={this.props.isValidEmail}
                    location={this.props.location}
                    error={this.props.error}
                    onValidateEmail={this.onValidateEmail}
                    onEmailChange={this.changeEmail}
                />
                {this.renderInformationModal()}
            </React.Fragment>
        );
    }
}

/**
  * Container props validation.
  */
ForgotPasswordContainer.propTypes = { forgotPassword: PropTypes.func.isRequired };

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordContainer));
