import parseBody from 'common/helpers/parse-body';
import formatQueryString from 'common/helpers/format-query-string';

import {post, deleteRequest, get} from './api.service';


export function authUserRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/auth/login',
        formData
    );
}

export function updateUserRequest(id, data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/edit/' + id,
        formData
    );
}

export function addAddressRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/addAddress',
        formData
    );
}

export function editFinancingProfileRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/editFinancingProfile',
        formData
    );
}

export function createFinancingProfileRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/createFinancingProfile',
        formData
    );
}

export function editAddressRequest(id, data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/editAddress/' + id,
        formData
    );
}

export function activateUserRequest(id, value) {
    const formData = new FormData();

    parseBody(formData, {active: value});

    return post(
        '/json/users/activate/' + id,
        formData
    );
}

export function markAddressAsDefaultRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/markAddress',
        formData
    );
}

export function deleteAddressRequest(data) {

    const formData = new FormData();

    parseBody(formData, {userid: data.userid});

    return post(
        '/json/users/address/' + data.id, 
        formData
    );
}

export function getUsersRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);
    }

    return get(
        `/json/users/search?${queryString}`
    );
}

export function getUserRequest(id) {

    return get(
        '/json/users/' + id
    );
}

export function getClientRequest(id) {

    return get(
        '/json/users/client/' + id
    );
}

export function getUserActionsRequest(id) {

    return get(
        '/json/users/actions/' + id
    );
}

export function deleteUserRequest(id) {

    return deleteRequest(
        '/json/users/' + id
    );
}

export function bulkDeleteUsersRequest(ids) {

    const formData = new FormData();

    parseBody(formData, {ids: ids});

    return post(
        '/json/users/bulk/delete',
        formData
    );
}

export function bulkActivateUsersRequest(ids, value) {

    const formData = new FormData();

    parseBody(formData, {ids: ids, active: value});

    return post(
        '/json/users/bulk/activate',
        formData
    );
}

export function createUserRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/users/create',
        formData
    );
}

export function registerUserRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/auth/register',
        formData
    );
}

export function logoutUserRequest() {
    return post(
        '/json/auth/logout'
    );
}

export function sendVerificationEmailRequest() {
    return post(
        '/json/auth/sendVerificationEmail'
    );
}

export function forgotPasswordRequest(email) {
    const formData = new FormData();

    parseBody(formData, {email: email});

    return post(
        '/json/auth/forgot',
        formData
    );
}

export function validateEmailRequest(email) {
    const formData = new FormData();

    parseBody(formData, {email: email});

    return post(
        '/json/auth/validateEmail',
        formData
    );
}

export function resetPasswordRequest(password, passwordConfirmation, token, id ) {
    const formData = new FormData();

    parseBody(formData, {
        password: password,
        token: token,
        passwordConfirmation: passwordConfirmation,
        id: id
    });


    return post(
        `/json/auth/reset`,
        formData
    );
}

export function getUsersDropdownsRequest() {
    return get(
        '/json/generic/usersFilters'
    );
}

export function getRegisterUserDropdownsRequest() {
    return get(
        '/json/generic/userDropdowns'
    );
}

export function userForgotPasswordRequest(id) {
    const formData = new FormData();

    parseBody(formData, {id: id});

    return post(
        '/json/users/forgot',
        formData
    );
}



export function validateUserByEmailRequest(email, password) {
    const formData = new FormData();

    parseBody(formData, {email: email, password: password});

    return post(
        '/json/auth/validateUserByEmail',
        formData
    );
}