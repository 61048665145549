'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';

import DashboardBody from './dashboard-body';
import DashboardHeader from './dashboard-header';
import moment from 'moment';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            dashboard: transactionSelectors.getDashboard(state),
            loading: transactionSelectors.getIsLoading(state),
            error: transactionSelectors.getError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboard: (params) => {
            dispatch(transactionActions.getDashboard(params));
        },
        clearDashboard: () => {
            dispatch(transactionActions.clearDashboard());
        },
        resetFinancingMessages: () => {
            dispatch(transactionActions.resetFinancingMessages());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class DashboardContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            filters: {
                from: moment().startOf('month').format('MM/DD/YYYY'),
                to: moment().format('MM/DD/YYYY')
            },
            monthselected: moment().month(),
            byMonth: true
        };
    }

    componentDidMount() {
        this.getData(true);
    }

    componentWillUnmount() {
        this.props.clearDashboard();
        this.props.resetFinancingMessages();
    }

    getData = (bymonth) => {
        let params = {
            startDate: (bymonth === true) ? this.state.monthselected: this.state.filters.from,
            endDate: (bymonth === true) ? this.state.monthselected + 1 : this.state.filters.to,
            byMonth: bymonth
        };

        this.props.getDashboard(params);
    };

    updateMonth = (val) => {
        let filters = Object.assign({}, this.state.filters);
        if (val === 12) {
            filters['from'] = moment().startOf('year').format('MM/DD/YYYY');
            filters['to'] = moment().format('MM/DD/YYYY');
            this.setState({monthselected: val, filters:filters, byMonth: false}, () => this.getData(false));
        } else {
            filters['from'] = moment().month(val).startOf('month').format('MM/DD/YYYY');
            filters['to'] = moment().month(val).endOf('month').format('MM/DD/YYYY');
            this.setState({monthselected: val, filters:filters, byMonth: true}, () => this.getData(true));
        }

    };

    onDateChanged = (value) => {
        let filters = Object.assign({}, this.state.filters);
        filters['from'] = moment(value.startDate).format('MM/DD/YYYY');
        filters['to'] = moment(value.endDate).format('MM/DD/YYYY');

        this.setState({filters:filters, byMonth: false}, () => this.getData(false));
    };


    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {!this.props.loading &&
                    this.props.dashboard &&
                    <React.Fragment
                        key='financing-detail'>
                        <div className="layout-body__header pdB0">
                            <DashboardHeader
                                dashboard={this.props.dashboard}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                resetFinancingMessages={this.props.resetFinancingMessages}
                                monthSelected={this.state.monthselected}
                                onUpdateMonth={this.updateMonth}
                                onDateChanged={this.onDateChanged}
                                dateFilters={this.state.filters}
                            />
                            <hr className="w-100 mgT15 mgB2"/>
                        </div>
                        <div className="layout-body__content">
                            {<DashboardBody
                                dashboard={this.props.dashboard}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                resetFinancingMessages={this.props.resetFinancingMessages}
                                monthSelected={this.state.monthselected}
                                dateFilters={this.state.filters}
                                byMonth={this.state.byMonth}
                            />}
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

DashboardContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withAlert()(withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(DashboardContainer)));
