import React from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';

/**
* Extends an input component by adding Formsy validations.
*/
class FormsyInputWrapper extends React.Component {
    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {

        const hasError = this.props.customError
                || (!this.props.isPristine && !this.props.isValid);
        const errorMessage = this.props.customError || this.props.errorMessage;

        return (
            <div style={this.props.style} className={`input-wrapper ${this.props.className} ${hasError || this.props.errorClassName !== null ? ' has-error' : ''}`.trim()}>
                {this.props.children}
                <span className={`form-text text-muted ${this.props.errorClassName || ''}`}>{hasError ? errorMessage : ''}</span>
            </div>
        );
    }
}


FormsyInputWrapper.defaultProps = {
    className: '',
    errorClassName: null
};


/**
 * Formsy Wrapper properties validation.
 */
FormsyInputWrapper.propTypes = {
    className: PropTypes.string,
    customError: PropTypes.string,
    isValid: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    errorMessage: PropTypes.string,
    errorClassName: PropTypes.string
};

export default withFormsy(FormsyInputWrapper);
