'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import Formsy from 'formsy-react';
import Media from 'react-media';


import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';
import EmptyState from 'common/components/empty-state';

import Modal from 'common/components/modal';
import {
    BACK_ICON,
    PASSWORD_ICON,
    CANCEL_ICON,
    STAR_ICON,
    DELETE_ICON
} from 'common/constants/icon.constant';
import { USERTYPES } from 'common/constants/enums.constant';
import InformationModal from 'common/components/information-modal';

import {
    PasswordInput,
    FormsyInputWrapper,
    Radio
} from 'common/components/inputs';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            userDeleted: userSelectors.getUserDeleted(state),
            emailSent: userSelectors.getEmailSent(state),
            passwordChanged: userSelectors.getPasswordChanged(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteUser: (id) => {
            dispatch(userActions.deleteUser(id));
        },
        activateUser: (id, value) => {
            dispatch(userActions.activateUser(id, value));
        },
        userForgotPassword: (id, email) => {
            dispatch(userActions.userForgotPassword(id, email));
        },
        userResetPassword: (password, id) => {
            dispatch(userActions.resetPassword(password, password, '_', id ));
        }
    };
};

class UserDetailHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showActivateModal: false,
            showPasswordModal: false,
            isProfile: props.authUser.id === props.user.id,
            password: '',
            passwordConfirmation: '',
            resetByEmail: true
        };

        this.changePassword = this.changeField.bind(this, 'password');
        this.changePasswordConfirmation = this.changeField.bind(this, 'passwordConfirmation');
    }

    componentDidUpdate() {

        if (this.props.userDeleted) {
            this.props.resetUserMessages();
            if (this.props.history.location.pathname.includes('clients')) {
                location.replace('/clients');
            } else {
                location.replace('/users');
            }

        }

        if (this.props.emailSent && this.state.showPasswordModal) {
            this.setState({showPasswordModal: false});
            this.props.alert.show(<span>Se ha enviado un enlace a <strong>{this.props.user.name}</strong> para que cree su nueva contraseña.</span>, {
                onClose: () => {
                    this.props.resetUserMessages();
                }
            });
        }

        if (this.props.passwordChanged && this.state.showPasswordModal) {
            this.setState({showPasswordModal: false, password: null, passwordConfirmation: null, resetByEmail: true});
            this.props.alert.show(<span>Se ha reestablecido una nueva contraseña al usuario <strong>{this.props.user.name}</strong>.</span>, {
                onClose: () => {
                    this.props.resetUserMessages();
                }
            });
        }
    }
    /**
     * Fired once any form input changes.
    */
    changeField(field, value) {
        this.setState({[field]: value});
    }

    renderInformationModal() {
        return (
            <InformationModal imageSuccess="/images/email_sent.svg" showModal={false}>
                <div className="text-center">
                    <p>Se ha enviado un enlace a <strong>{this.props.user.name}</strong> para que cree su nueva contraseña.</p>
                    <div style={{margin: '0px 15%'}}>
                        <button
                            onClick={() => this.props.resetUserMessages()}
                            className="btn btn-outline">
                            Entendido
                        </button>
                    </div>
                </div>
            </InformationModal>
        );
    }

    renderActivateUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showActivateModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showActivateModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image={this.props.user.active ? "/images/deactivate_user.svg" : "/images/activate_user.svg"}
                        title=""
                        text=""
                    />
                    <h2 className="text-center">{this.props.user.active ? 'Suspender' : 'Reactivar'} usuario</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea {this.props.user.active ? 'suspender' : 'reactivar'} este usuario?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>El usuario {this.props.user.active ? 'no' : ''} podrá ingresar a la plataforma.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.activateUser(this.props.user.id, !this.props.user.active)}
                            className="btn btn-outline col-md-4">
                            {this.props.user.active ? 'Suspender' : 'Reactivar'}
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showActivateModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    resetPassword = () => {
        if (this.state.resetByEmail) {
            this.props.userForgotPassword(this.props.user.id);
        } else {
            this.props.userResetPassword(this.state.password, this.props.user.id);
        }
    };
    renderPasswordModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showPasswordModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showPasswordModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/reset_password.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Restablecer contraseña</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea restablecer {this.state.isProfile ? 'su contraseña' : 'la contraseña de este usuario'}?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>{(this.state.isProfile || this.props.authUser.userType.id !== 4 ) ? 'Se le enviará un enlace al correo para reestablecer la contraseña.' : 'La contraseña del usuario puede ser reestablecida por dos vias.'} </p>
                    </div>

                    {!this.state.isProfile && this.props.authUser.userType.id === 4 &&
                    <div className="mgB2 mgT2">
                        <Radio
                            selectedValue={this.state.resetByEmail}
                            radioStyle={{ padding: '0px' }}
                            onChange={(val) => this.setState({resetByEmail: val})}
                            label="Usted desea reestablecer la contraseña via correo electrónico?"
                            options={[
                                {value: true, label: 'Sí'},
                                {value: false, label: 'No'}
                            ]}
                        />
                    </div>}
                    {!this.state.resetByEmail && !this.state.isProfile && this.props.authUser.userType.id === 4 &&
                       <div className='mgT2'>
                           <Formsy
                               onValid={this.enableSubmit}
                               onInvalid={this.disableSubmit}>
                               <FormsyInputWrapper
                                   required
                                   className="col-12 mgB2"
                                   name="password"
                                   value={this.state.passwordConfirmation === this.state.password}
                                   validations="isTrue"
                                   validationError="Las contraseñas deben ser iguales.">
                                   <PasswordInput
                                       icon={PASSWORD_ICON}
                                       label="Nueva contraseña"
                                       dangerLabel={this.state.passwordConfirmation !== this.state.password}
                                       value={this.state.password}
                                       onChange={this.changePassword}
                                   />
                               </FormsyInputWrapper>
                               <FormsyInputWrapper
                                   required
                                   className="col-12 mgB2"
                                   name="passwordConfirmation"
                                   value={this.state.passwordConfirmation === this.state.password}
                                   validations="isTrue"
                                   validationError="Las contraseñas deben ser iguales.">
                                   <PasswordInput
                                       icon={PASSWORD_ICON}
                                       label="Confirmar contraseña"
                                       dangerLabel={this.state.passwordConfirmation !== this.state.password}
                                       value={this.state.passwordConfirmation}
                                       onChange={this.changePasswordConfirmation}
                                   />
                               </FormsyInputWrapper>
                           </Formsy>
                       </div>
                    }


                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={this.resetPassword}
                            className="btn btn-outline col-md-4">
                            Restablecer
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showPasswordModal: false, resetByEmail: !this.state.isProfile})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderDeleteUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/delete_user.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Eliminar usuario</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea eliminar este usuario?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Será eliminado del sistema y no podrá acceder a la plataforma.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.deleteUser(this.props.user.id)}
                            className="btn btn-outline col-md-4">
                            Eliminar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeleteModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderProfileActions() {
        return (
            <React.Fragment>
                <Media query='(max-width: 440px)'>
                    {matches =>
                        matches ? (
                            <div className="row" style={{marginTop:'10px'}}>
                                <div className="col-4 pdL0 mgB text-left">
                                    <button
                                        onClick={() => this.props.history.goBack()}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                Volver
                                        </strong>
                                    </button>
                                </div>
                                <div className="col-8 pdL0 mgB text-right">
                                    <button
                                        onClick={() => this.setState({showPasswordModal: true, resetByEmail:true})}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '12px', marginRight: '10px'}} className={PASSWORD_ICON}/>
                                Contraseña
                                        </strong>
                                    </button>
                                </div>
                            </div>
                        ):(
                            <div className="row">
                                <div className="col-md-6 pdL0 mgB">
                                    <button
                                        onClick={() => this.props.history.goBack()}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                        Volver
                                        </strong>
                                    </button>
                                </div>
                                <div className="col-md-6 pdL0 mgB text-md-right">
                                    <button
                                        onClick={() => this.setState({showPasswordModal: true, resetByEmail:true})}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '14px', marginRight: '10px'}} className={PASSWORD_ICON}/>
                                        Restablecer contraseña
                                        </strong>
                                    </button>
                                </div>
                            </div>
                        )}
                </Media>
            </React.Fragment>
        );
    }

    renderDefaultActions() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-5 pdL0 mgB">
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                Volver
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-2 pdL0 mgB">
                        <button
                            onClick={() => this.setState({showActivateModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                {this.props.user.active &&
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={CANCEL_ICON}/>
                                }
                                {!this.props.user.active &&
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={STAR_ICON}/>
                                }
                                {this.props.user.active ? 'Desactivar' : 'Activar'}
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-3 pdL0 mgB">
                        <button
                            onClick={() => this.setState({showPasswordModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PASSWORD_ICON}/>
                                Restablecer contraseña
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-2 mgB pdL0 text-md-right">
                        <button
                            onClick={() => this.setState({showDeleteModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={DELETE_ICON}/>
                                Eliminar
                            </strong>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderUserInfo() {
        return (
            <div>
                {(this.props.user.id === this.props.authUser.id || this.props.authUser.userType.id === USERTYPES.AGENT) &&
                    this.renderProfileActions()
                }
                {this.props.user.id !== this.props.authUser.id && this.props.authUser.userType.id !== USERTYPES.AGENT &&
                    this.renderDefaultActions()
                }


                <h1 className="section-headline mgB">{this.props.user.name + '  ' + this.props.user.lastname} {!this.state.isProfile && <span style={{color: 'lightgray'}}>({this.props.user.active ? 'activo' : 'suspendido'})</span>}</h1>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderUserInfo()}
                {this.renderDeleteUserModal()}
                {this.renderActivateUserModal()}
                {this.renderPasswordModal()}
                {this.renderInformationModal()}
            </React.Fragment>
        );
    }
}

/**
 * Properties validations.
 */
UserDetailHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    user: PropTypes.object
};

export default withAlert()(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(UserDetailHeader));
