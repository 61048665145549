let colors = {};
colors.names = {
    GREEN: '#74A90A',
    RED: "#D1153B",
    BLACK: "rgba(0,0,0,1)",
    BLACK_024: "rgba(0,0,0,0.24)",
    ORANGE: "rgba(251,140,0,1)",
    aqua: "#00ffff",
    black: "#000000",
    blue: "#0000ff",
    brown: "#a52a2a",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgrey: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkviolet: "#9400d3",
    fuchsia: "#ff00ff",
    gold: "#ffd700",
    green: "#008000",
    indigo: "#4b0082",
    lightblue: "#add8e6",
    lightcyan: "#e0ffff",
    lightgreen: "#90ee90",
    lightgrey: "#d3d3d3",
    lightpink: "#ffb6c1",
    lightyellow: "#ffffe0",
    magenta: "#ff00ff",
    maroon: "#800000",
    navy: "#000080",
    olive: "#808000",
    orange: "#ffa500",
    pink: "#ffc0cb",
    purple: "#800080",
    violet: "#800080",
    red: "#ff0000",
    silver: "#c0c0c0",
    yellow: "#ffff00"
};

colors.normal = function () {
    return [this.names.GREEN, this.names.RED];
};

colors.random = function (n) {
    let results = [];
    let count = 0;
    for (let prop in this.names) {
        if (count < n) {
            results.push(this.names[prop]);
        }
        count++;
    }

    return results;
};

export default colors;
