import { combineEpics } from 'redux-observable';

import * as userEpics from './user.epic';
import * as financingEntitiesEpics from './financing-entity.epic';
import * as transactionEpics from './transaction.epic';
import * as promotionEpics from './promotions.epic';
import * as apicardEpics from './apicard.epic';

export const rootEpics = combineEpics(
    userEpics.authUserEpic,
    userEpics.logoutUserEpic,
    userEpics.forgotPasswordEpic,
    userEpics.resetPasswordEpic,
    userEpics.createUserEpic,
    userEpics.registerUserEpic,
    userEpics.updateUserEpic,
    userEpics.deleteUserEpic,
    userEpics.getUserEpic,
    userEpics.getUsersEpic,
    userEpics.getUsersDropdownsEpic,
    userEpics.bulkDeleteUsersEpic,
    userEpics.activateUserEpic,
    userEpics.bulkActivateUsersEpic,
    userEpics.getUserActionsEpic,
    userEpics.userForgotPasswordEpic,
    userEpics.validateEmailEpic,
    userEpics.getRegisterUserDropdownsEpic,
    userEpics.sendVerificationEmail,
    userEpics.getClientEpic,
    userEpics.markAddressAsDefaultEpic,
    userEpics.addAddressEpic,
    userEpics.editAddressEpic,
    userEpics.deleteAddressEpic,
    userEpics.editFinancingProfileEpic,
    userEpics.createFinancingProfileEpic,
    userEpics.findByEmailandPassEpic,

    financingEntitiesEpics.getFinancingEntitiesEpic,
    financingEntitiesEpics.getFinancingEntityEpic,
    financingEntitiesEpics.deleteFinancingEntityEpic,
    financingEntitiesEpics.updateFinancingEntityEpic,
    financingEntitiesEpics.createFinancingEntityEpic,
    financingEntitiesEpics.getFinancingEntitiesListEpic,
    financingEntitiesEpics.activateFinancingEntityEpic,
    financingEntitiesEpics.updatePlanEpic,
    financingEntitiesEpics.addPlansEpic,
    financingEntitiesEpics.copyPlansEpic,
    financingEntitiesEpics.bulkEnablePlansEpic,
    financingEntitiesEpics.bulkDeletePlansEpic,
    financingEntitiesEpics.addFinancingEntityBrancheEpic,
    financingEntitiesEpics.getFinancingEntityProvincesCitiesEpic,
    financingEntitiesEpics.bulkDeleteBranchesEpic,
    financingEntitiesEpics.updateBranchEpic,
    financingEntitiesEpics.addFinancingEntityBinEpic,
    financingEntitiesEpics.enabledFinancingEntityBinesEpic,
    financingEntitiesEpics.deleteFinancingEntityBinesEpic,
    financingEntitiesEpics.agregatePlanstoBinEpic,

    transactionEpics.verifyOrderTokenEpic,
    transactionEpics.getAllEntitiesEpic,
    transactionEpics.createFinancingEpic,
    transactionEpics.getFinancingsEpic,
    transactionEpics.getFinancingEpic,
    transactionEpics.cancelFinancingEpic,
    transactionEpics.approveFinancingEpic,
    transactionEpics.undoApproveFinancingEpic,
    transactionEpics.changeFinancingStatusEpic,
    transactionEpics.declineFinancingEpic,
    transactionEpics.getAllFinancingEntityTypesEpic,
    transactionEpics.getFinancingActionsEpic,
    transactionEpics.updateFinancingEpic,
    transactionEpics.uploadQuoteEpic,
    transactionEpics.uploadDocumentEpic,
    transactionEpics.removeDocumentEpic,
    transactionEpics.addCommentEpic,
    transactionEpics.getDashboardEpic,
    transactionEpics.signFinancingEpic,
    transactionEpics.resendFinancingEpic,
    transactionEpics.sellConfirmFinancingEpic,
    transactionEpics.getFinancingsTotalsEpic,
    transactionEpics.createTempFinancingEpic,
    transactionEpics.createFinancingFromTempEpic,
    transactionEpics.getTempFinancingEpic,
    transactionEpics.delTempFinancingEpic,
    transactionEpics.createCardFinancingEpic,
    transactionEpics.getCardTransactionsEpic,
    transactionEpics.assignBranchFinancingTransactionsEpic,
    transactionEpics.cancelCheckoutTransactionsEpic,

    promotionEpics.createPromotionEpic,
    promotionEpics.getAllPromotionsEpic,
    promotionEpics.getPromotionEpic,
    promotionEpics.setEnabledPromotionEpic,
    promotionEpics.removePromotionEpic,
    promotionEpics.updatePromotionEpic,
    promotionEpics.addCuponPromotionEpic,
    promotionEpics.removeCuponPromotionEpic,
    promotionEpics.searchSkuEpic,
    promotionEpics.addSkuEpic,
    promotionEpics.updateSkuEpic,
    promotionEpics.removeBulkSkuesEpic,
    promotionEpics.addRulePromotionEpic,
    promotionEpics.updateRulePromotionEpic,
    promotionEpics.removeRulePromotionEpic,
    promotionEpics.getAllPromotionsActiveEpic,

    apicardEpics.authorizeCardEpic,
    apicardEpics.registerTokensEpic
);
