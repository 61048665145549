import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    Radio,
    TextInput
} from 'common/components/inputs';

import {IDENTIFICATION_TYPES} from 'common/constants/enums.constant';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

export default class EditClientForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            userData: {
                name: props.userData.name,
                lastname: props.userData.lastname,
                email: props.userData.email,
                identificationType: props.userData.identificationType ? props.userData.identificationType.id : null,
                identification: props.userData.identification ? props.userData.identification : null,
                phone: props.userData.phone ? props.userData.phone : '',
                mobile: '',
                extension: ''
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                lastname: { errorClass: null, dangerLabel: false },
                identificationType: { errorClass: null, dangerLabel: false },
                identification: { errorClass: null, dangerLabel: false },
                email: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false }
            }
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let userData = {... this.state.userData};
        userData[field] = value;
        this.setState({userData: userData});
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.userData});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <Formsy
                    ref={this.basicDataRef}
                    onValid={this.enableSubmit}
                    onInvalid={this.disableSubmit}>
                    <div className="row mgB2">
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-6 mgB2"
                            name="name"
                            errorClassName={this.state.errorFields.name.errorClass}
                            value={this.state.userData.name}>
                            <TextInput
                                label={<span>Nombre <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.name.dangerLabel}
                                placeholder="Inserte el nombre"
                                value={this.state.userData.name}
                                onChange={(val) => this.onFieldChange('name', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-6 mgB2"
                            name="lastname"
                            errorClassName={this.state.errorFields.lastname.errorClass}
                            value={this.state.userData.lastname}>
                            <TextInput
                                label={<span>Apellidos <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.lastname.dangerLabel}
                                placeholder="Inserte el apellido"
                                value={this.state.userData.lastname}
                                onChange={(val) => this.onFieldChange('lastname', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-12 mgB2"
                            name="identificationType"
                            errorClassName={this.state.errorFields.identificationType.errorClass}
                            value={this.state.userData.identificationType !== null && this.state.userData.identificationType !== undefined}
                            validations="isTrue">
                            <Radio
                                selectedValue={this.state.userData.identificationType}
                                radioStyle={{ padding: '0px' }}
                                optionsClassName="bordered mgR"
                                className="d-flex"
                                labelStyle={{paddingRight: '5px'}}
                                inputStyle={{height: '44px', padding: '7px'}}
                                onChange={(val) => this.onFieldChange('identificationType', val)}
                                label={<span>Documento de identidad <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.identificationType.dangerLabel}
                                options={this.props.dropdowns.identificationTypes.map(element => {
                                    return {
                                        value: element.value,
                                        label: element.label
                                    };
                                })}
                            />
                        </FormsyInputWrapper>
                        {this.state.userData.identificationType === IDENTIFICATION_TYPES.CEDULA &&
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-12 mgB"
                                name="identification"
                                errorClassName={this.state.errorFields.identification.errorClass}
                                value={this.state.userData.identification}
                                validationError='Ingrese una cédula válida.'
                                validations='isValidIdentification'>
                                <TextInput
                                    label={<span>No. Documento <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.identification.dangerLabel}
                                    value={this.state.userData.identification}
                                    onChange={(val) => this.onFieldChange('identification', val)}
                                    maxLength={11}
                                    numbers={true}
                                    onlyNumbers={true}
                                />

                            </FormsyInputWrapper>
                        }
                        {this.state.userData.identificationType === IDENTIFICATION_TYPES.PASSPORT &&
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-12 mgB2"
                                errorClassName={this.state.errorFields.identification.errorClass}
                                value={this.state.userData.identification}
                                name="identification">
                                <TextInput
                                    label={<span>No. Documento<span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.identification.dangerLabel}
                                    value={this.state.userData.identification}
                                    onChange={(val) => this.onFieldChange('identification', val)}
                                />
                            </FormsyInputWrapper>
                        }
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-7 mgB2"
                            name="email"
                            validations="isEmail"
                            errorClassName={this.state.errorFields.email.errorClass}
                            validationError="Por favor ingrese un correo electrónico válido."
                            value={this.state.userData.email}>
                            <TextInput
                                label={<span>Correo electrónico <span className="color-red">*</span></span>}
                                placeholder="Inserte el correo"
                                dangerLabel={this.state.errorFields.email.dangerLabel}
                                value={this.state.userData.email}
                                onChange={(val) => this.onFieldChange('email', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-5 mgB2"
                            name="phone"
                            validations="isValidPhone"
                            errorClassName={this.state.errorFields.phone.errorClass}
                            validationError = "Por favor ingrese un número de telefono válido."
                            value={this.state.userData.phone}>
                            <TextInput
                                label={<span>Teléfono <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.phone.dangerLabel}
                                value={this.state.userData.phone}
                                onChange={(val) => this.onFieldChange('phone', val)}
                                maxLength={10}
                                numbers={true}
                                onlyNumbers={true}
                            />
                        </FormsyInputWrapper>
                    </div>
                </Formsy>
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-user-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.userData}>
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </React.Fragment>
        );
    }
}

EditClientForm.defaultProps = {creating: true};

EditClientForm.propTypes = {
    creating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
