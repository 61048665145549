'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import querystring from 'query-string';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';
import InformationModal from 'common/components/information-modal';

import ResetPasswordForm from './reset-password-form';

function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (password, passwordConfirmation, token) => {
            dispatch(userActions.resetPassword(password, passwordConfirmation, token));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        authUser: userSelectors.getAuthUser(state),
        error: userSelectors.getError(state),
        passwordChanged: userSelectors.getPasswordChanged(state)
    };
};

/**
 * Container which handles the login logic.
 */
class ResetPasswordContainer extends React.Component {
    /**
     * Set the states and makes the binds as needed.
     */
    constructor(props) {
        super(props);

        this.onResetPassword = this.onResetPassword.bind(this);
        this.onFormChange = this.onFormChange.bind(this);

        this.state = {
            email: '',
            password: '',
            token: '',
            passwordConfirmation: ''
        };
    }

    /**
     * Fired once the email or the password changes.
     */
    onFormChange(field, value) {
        this.setState({[field]: value});
    }

    /**
     * Fired once the user attempts to log in.
     */
    onResetPassword() {
        const password = this.state.password;
        const passwordConfirmation = this.state.passwordConfirmation;
        const token = this.state.token;

        this.props.resetPassword(password, passwordConfirmation, token);
    }

    goToLogin = () => {
        this.props.resetUserMessages();
        this.props.history.push('/');
    };

    renderInformationModal() {
        return (
            <InformationModal showModal={this.props.passwordChanged}>
                <div className="text-center">
                    <p>Tu contraseña ha sido modificada correctamente.</p>
                    <p><strong>Ya puedes iniciar sesión con tu nueva contraseña.</strong></p>

                    <div style={{margin: '0px 15%'}}>
                        <button
                            onClick={this.goToLogin}
                            className="btn btn-outline">
                            Iniciar sesión
                        </button>
                    </div>
                </div>
            </InformationModal>
        );
    }

    componentDidMount() {
        this.props.resetUserMessages();

        let queryObj = querystring.parse(this.props.location.search, {
            arrayFormat: 'comma',
            parseNumbers: true,
            parseBooleans: true
        });
        let token = queryObj['t'];
        let email = queryObj['email'];

        if (token) {
            this.setState({
                token: token,
                email: email
            });
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <React.Fragment>
                <div className="text-center">
                    <p>Por favor, ingresa tu nueva contraseña.</p>
                </div>

                <ResetPasswordForm
                    onResetPassword={this.onResetPassword}
                    password={this.state.password}
                    passwordConfirmation={this.state.passwordConfirmation}
                    error={this.props.error}
                    onFormChange={this.onFormChange}
                />
                {this.renderInformationModal()}
            </React.Fragment>
        );
    }
}

/**
  * Container props validation.
  */
ResetPasswordContainer.propTypes = { resetPassword: PropTypes.func.isRequired };

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer));
