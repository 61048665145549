/**
 * Convert the given object into a FormData object. This function
 * recursively calls itself on other objects.
 *
 * @param  object  obj1   the original object.
 *
 * @return object  obj3   A FormData object.
 */
export default function getFormData(formData, data, previousKey) {
    if (data instanceof Object) {
        Object.keys(data).forEach(key => {
            const value = data[key];
            let newKey = key;

            if (value instanceof Object && !Array.isArray(value) && !(value instanceof File)) {
                return getFormData(formData, value, newKey);
            }

            // For arrays.
            if (previousKey) {
                newKey = `${previousKey}[${newKey}]`;
            }
            // FIX THIS!!!!!!
            if (Array.isArray(value)) {
                value.forEach((val, i) => {
                    if (val instanceof Object && !Array.isArray(val) && !(val instanceof File)) {
                        let newValue = getFormData(formData, val, `${newKey}[${i}]`);
                        if (newValue !== null && newValue !== undefined) {
                            if (newValue === true) {
                                newValue = 1;
                            } else if (newValue === false) {
                                newValue = 0;
                            }
                            formData.append(`${newKey}[${i}]`, newValue);
                        }
                    } else {
                        if (val !== null && val !== undefined) {
                            let newValue = val;
                            if (newValue === true) {
                                newValue = 1;
                            } else if (newValue === false) {
                                newValue = 0;
                            }
                            formData.append(`${newKey}[${i}]`, newValue);
                        }
                    }
                });
            } else {
                if (value !== null && value !== undefined) {
                    let newValue = value;
                    if (newValue === true) {
                        newValue = 1;
                    } else if (newValue === false) {
                        newValue = 0;
                    }
                    formData.append(newKey, newValue);
                }
            }
            return key;
        });
    }
}
