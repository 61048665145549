import React from 'react';
import PropTypes from 'prop-types';

export default class ImagePreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            placeholder: null
        };
    }

    static getDerivedStateFromProps(nextProps) {
        if (!nextProps.image) {
            return {image: ''};
        }

        return null;
    }

    componentDidUpdate() {
        if (this.props.image && typeof this.props.image !== 'string') {
            this.loadImage(this.props.image);
        }
        if (this.props.placeholder && typeof this.props.placeholder !== 'string') {
            this.loadPlaceholder(this.props.placeholder);
        }
    }

    loadPlaceholder(placeholder) {

        if (typeof placeholder === 'string') {
            this.setState({ placeholder });
            return;
        }

        let reader = new FileReader();

        reader.onloadend = (event) => {
            this.setState({ placeholder: event.target.result });
        };
        reader.readAsDataURL(placeholder);
    }

    loadImage(image) {

        if (typeof image === 'string') {
            this.setState({ image });
            return;
        }

        let reader = new FileReader();

        reader.onloadend = (event) => {
            this.setState({image: event.target.result});
        };
        reader.readAsDataURL(image);
    }

    componentDidMount() {
        if (this.props.image) {
            this.loadImage(this.props.image);
        }
        if (this.props.placeholder) {
            this.loadPlaceholder(this.props.placeholder);
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        if (!this.state.image && !this.state.placeholder) {
            return (<div />);
        }
        let render = this.state.image;
        if (!render) {
            render = this.state.placeholder;
        }
        return (<div onClick={this.props.onClick} className={this.props.className || `image-preview image-preview__xs`} style={Object.assign({}, { backgroundImage: `url(${render})` }, this.props.style)} />);
    }
}

/**
 * Upload input properties validation.
 */
ImagePreview.propTypes = {
    className: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
};
