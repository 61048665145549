import parseBody from 'common/helpers/parse-body';
import formatQueryString from 'common/helpers/format-query-string';

import {
    post,
    deleteRequest,
    get
} from './api.service';


export function updateFinancingEntityRequest(id, data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/financing-entities/edit/' + id,
        formData
    );
}

export function addPlansRequest(id, data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/financing-entities/addPlans/' + id,
        formData
    );
}

export function copyPlansRequest(id, ids) {
    const formData = new FormData();

    parseBody(formData, {ids: ids});

    return post(
        '/json/financing-entities/copyPlans/' + id,
        formData
    );
}

export function bulkEnablePlansRequest(id, ids, value) {
    const formData = new FormData();

    parseBody(formData, {
        ids: ids,
        enabled: value
    });

    return post(
        '/json/financing-entities/bulk/enablePlans/' + id,
        formData
    );
}

export function bulkDeletePlansRequest(id, ids) {
    const formData = new FormData();

    parseBody(formData, {ids: ids});

    return post(
        '/json/financing-entities/bulk/deletePlans/' + id,
        formData
    );
}

export function updatePlanRequest(id, data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/financing-plan/edit/' + id,
        formData
    );
}

export function getFinancingEntitiesRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);
    }

    return get(
        `/json/financing-entities/search?${queryString}`
    );
}

export function getFinancingEntityRequest(id) {

    return get(
        '/json/financing-entities/' + id
    );
}

export function deleteFinancingEntityRequest(id) {

    return deleteRequest(
        '/json/financing-entities/' + id
    );
}

export function createFinancingEntityRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/financing-entities/create',
        formData
    );
}

export function activateFinancingEntityRequest(id, value) {
    const formData = new FormData();

    parseBody(formData, {enabled: value});

    return post(
        '/json/financing-entities/activate/' + id,
        formData
    );
}

export function getFinancingEntityProvincesCitiesRequest() {
    return get(
        '/json/generic/allProvincesCities'
    );
}

export function addFinancingEntityBrancheRequest(params, id) {
    const formData = new FormData();
    parseBody(formData, params);

    return post(
        '/json/financing-entities/addBranche/' + id,
        formData
    );

}

export function bulkDeleteBranchesRequest(id, ids) {
    const formData = new FormData();
    parseBody(formData, {ids: ids});

    return post(
        '/json/financing-entities/bulk/deleteBranches/' + id,
        formData
    );

}

export function updateBranchRequest(id, data) {
    const formData = new FormData();
    parseBody(formData, data);

    return post(
        '/json/financing-entities/editBranche/' + id,
        formData
    );

}

export function addFinancingEntityBinRequest(params, id) {
    const formData = new FormData();
    parseBody(formData, {binvalue: params.toString()});

    return post(
        '/json/financing-entities/addBin/' + id,
        formData
    );

}

export function bulkDeleteBinesRequest(id, ids) {
    const formData = new FormData();
    parseBody(formData, {ids: ids});

    return post(
        '/json/financing-entities/bulk/deleteBines/' + id,
        formData
    );

}

export function enabledBinesRequest(params, id) {
    const formData = new FormData();
    parseBody(formData, {binvalues: params.ids, enabled: params.enabled});

    return post(
        '/json/financing-entities/bulk/enableBines/' + id,
        formData
    );

}

export function deleteBinesRequest(params, id) {
    const formData = new FormData();
    parseBody(formData, {binvalues: params.ids});

    return post(
        '/json/financing-entities/bulk/deleteBines/' + id,
        formData
    );

}

export function plansToBinsRequest(params, id) {
    const formData = new FormData();
    parseBody(formData, {id:params.id, plans: params.plans});

    return post(
        '/json/financing-entities/bins/bulk/plans/' + id,
        formData
    );
}



