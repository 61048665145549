'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import money from 'common/helpers/money';

import _ from 'lodash';

const CartComponent = props => {

    let subTotal = _.sumBy(props.cart.items, (item) => {
        return item.count * item.price;
    }) / 100;

    let taxes = (subTotal + props.cart.deliveryCost / 100) * parseFloat(process.env.MIX_TAXES);
    return (
        <div className="cart row">
            <div className={props.className}>
                <table className="cart-items pdB2">
                    <thead>
                        <tr>
                            <th>Cantidad</th>
                            <th>
                                <span>Producto</span>
                                <span style={{ textAlign:'center'}}>
                                    Precio
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.cart.items.map((element, i) => {
                            return (
                                <tr key={'cart-item-' + i}>
                                    <td style={{textAlign:'center'}}>({element.count})</td>
                                    <td className="product">
                                        <span>{element.name}</span>
                                        <span>
                                            {(props.cart.currency === 'DOP' ? 'RD$ ' : 'US$ ') + money(element.price / 100)}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className={props.className}>
                <ul className="totals">
                    <li>
                        <span>Subtotal</span>
                        <span>{(props.cart.currency === 'DOP' ? 'RD$ ' : 'US$ ') + money(subTotal)}</span>
                    </li>
                    <li>
                        <span>Envío</span>
                        <span>
                            {(props.cart.currency === 'DOP' ? 'RD$ ' : 'US$ ') + money(props.cart.deliveryCost / 100)}
                        </span>
                    </li>
                    <li className="border-bottom mgB3 pdB2">
                        <span>Impuestos</span>
                        <span>{(props.cart.currency === 'DOP' ? 'RD$ ' : 'US$ ') + money(taxes)}</span>
                    </li>
                    <li>
                        <span><strong>Total</strong></span>
                        <span><strong>{(props.cart.currency === 'DOP' ? 'RD$ ' : 'US$ ') + money(subTotal + taxes + (props.cart.deliveryCost / 100))}</strong></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

CartComponent.defaultProps = {className: "col-12 pd0"};


CartComponent.propTypes = {cart: PropTypes.object, className: PropTypes.string, promo: PropTypes.object};

export default CartComponent;
