import { ajax } from 'rxjs/observable/dom/ajax';

export function post(url, body, headers = {}) {
    return ajax.post(
        url,
        body,
        headers
    );
}

export function deleteRequest(url) {
    return ajax.delete(
        url
    );
}

export function get(url, headers = {}) {
    return ajax.getJSON(
        url,
        headers
    );
}

export function downloadPost(url, body) {
    let ajaxObject = {
        body: body,
        url: url,
        method: 'POST',
        responseType: 'blob'
    };

    return ajax(ajaxObject);
}

