import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Media from 'react-media';

import Sidebar from 'common/components/sidebar';
import Header from 'common/components/header';

/**
* Container which wraps the authentication screens.
*/
class GuestWrapper extends React.Component {

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        if (this.props.location.pathname.includes('/transactions/checkout')) {
            return (
                <div className={`dashboard layout full-height`}>
                    <Media query='(max-width: 600px)'>
                        {matches =>
                            matches ? (
                                <div>
                                    <main className={`layout-main full-height layout-main__responsive`}>{this.props.children}</main>
                                    <Header
                                        user={this.props.user}
                                        history={this.props.history}
                                        pathname={this.props.location.pathname}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Sidebar
                                        history={this.props.history}
                                        location={this.props.location.pathname} />
                                    <main className={`layout-main full-height`}
                                    style={{paddingLeft:'241px'}}
                                    >{this.props.children}</main>
                                </div>
                            )
                        }
                    </Media>
                </div>
            );
        }
        return (
            <div className="login-container">
                <div className="login-container__body">
                    <div className="login-container__logo" />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

GuestWrapper.propTypes = { children: PropTypes.node.isRequired };

export default withRouter(GuestWrapper);
