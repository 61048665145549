/* Promotion related state changes goes here */

import PropTypes from 'prop-types';

import * as promtionsActionsLabels from '../actions/promotions/promotions.enum';

import merge from '../../helpers/merge-objects';

import { GENERIC_ERROR } from 'common/constants/errors.constant';
import {
    DEFAULT_PAGINATION,
    DEFAULT_PROMOTION_FILTERS
} from 'common/constants/objects.constant';

const promotionInitialState = Object.seal(
    {
        loading: false,
        error: null,
        simpleError: null,
        pagination: DEFAULT_PAGINATION,
        promotion: null,
        promotions: [],
        promotionFilters: DEFAULT_PROMOTION_FILTERS,
        singlePromotion: null,
        promotionCreated: false,
        promotionUpdated: false,
        promotionDeleted: false,
        cuponCreated: false,
        cuponRemoved:false,
        cupons: [],
        rules: [],
        stores: null,
        skus: [],
        skuCreated: false,
        skuDeleted: false,
        skuUpdated: false,
        promotionsActivated: [],


        // Properties validation.
        propTypes: {
            loading: PropTypes.bool,
            error: PropTypes.string,
            simpleError: PropTypes.string,
            singlePromotion: PropTypes.object
        }
    }
);

export const promotionReducer = (state = promotionInitialState, action) => {
    let newState = state;
    switch (action.type) {
    case promtionsActionsLabels.SET_FILTERS:{
        const newStateObject = Object.assign({}, {promotionFilters: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.RESET_VARIABLES_STATE_PROMOTION:{
        const newStateObject = Object.assign({}, {
            promotionDeleted:false,
            promotionUpdated: false,
            error: null,
            simpleError: null,
            cuponCreated: null,
            cuponRemoved: null,
            skuDeleted: false,
            skuCreated: false,
            skuUpdated: false
        });
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.CREATE_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: null, promotionCreated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.CREATE_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: action.payload.response, promotionCreated: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.CREATE_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, singlePromotion: null, promotionCreated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, promotions: null, loading:true, pagination: DEFAULT_PAGINATION});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({},
            {
                simpleError: null,
                error: null,
                promotions: action.payload.data ? action.payload.data : [],
                pagination: action.payload.pagination ? action.payload.pagination : DEFAULT_PAGINATION,
                loading:false
            });
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTION_ACTIVATE: {
        const newStateObject = Object.assign({}, {simpleError: null, promotionsActivated: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTION_ACTIVATE_SUCCESS: {
        const newStateObject = Object.assign({},
            {
                simpleError: null,
                error: null,
                promotionsActivated: action.payload.data ? action.payload.data : []
            });
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTION_ACTIVATE_FAILURE:
    case promtionsActionsLabels.SEARCH_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, promotions: null, loading:false, promotionsActivated: []});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: null, cupons: [], loading:true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: action.payload, cupons: action.payload.cupons, loading:false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, singlePromotion: null, loading:false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, promotionDeleted: false, loading:true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, promotionDeleted: true, loading:false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, promotionDeleted:false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ACTIVATE_PROMOTION:
    case promtionsActionsLabels.EDIT_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: null, promotionUpdated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ACTIVATE_PROMOTION_SUCCESS:
    case promtionsActionsLabels.EDIT_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: action.payload, promotionUpdated: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ACTIVATE_PROMOTION_FAILURE:
    case promtionsActionsLabels.EDIT_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, singlePromotion: null, promotionUpdated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_CUPONS_PROMOTION:
    case promtionsActionsLabels.ADD_CUPONS_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, cuponCreated: null, cuponRemoved: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_CUPONS_PROMOTION_SUCCESS:
    {
        const newStateObject = Object.assign({}, {simpleError: null, cupons: action.payload, cuponRemoved: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ADD_CUPONS_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, cupons: action.payload, cuponCreated: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_CUPONS_PROMOTION_FAILURE:
    case promtionsActionsLabels.ADD_CUPONS_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, cuponCreated: null, cuponRemoved: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_RULES_PROMOTION:
    case promtionsActionsLabels.UPDATE_RULES_PROMOTION:
    case promtionsActionsLabels.ADD_RULES_PROMOTION: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_RULES_PROMOTION_SUCCESS:
    case promtionsActionsLabels.UPDATE_RULES_PROMOTION_SUCCESS:
    case promtionsActionsLabels.ADD_RULES_PROMOTION_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, singlePromotion: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_RULES_PROMOTION_FAILURE:
    case promtionsActionsLabels.UPDATE_RULES_PROMOTION_FAILURE:
    case promtionsActionsLabels.ADD_RULES_PROMOTION_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, singlePromotion: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_STORES: {
        const newStateObject = Object.assign({}, {simpleError: null, stores: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_STORES_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, stores: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.GET_STORES_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, stores: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_SKUS: {
        const newStateObject = Object.assign({}, {simpleError: null, skus: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_SKUS_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, skus: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ADD_SKU: {
        const newStateObject = Object.assign({}, {simpleError: null, skuCreated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_SKU: {
        const newStateObject = Object.assign({}, {simpleError: null, skuDeleted: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.UPDATE_SKU: {
        const newStateObject = Object.assign({}, {simpleError: null, skus: null, skuUpdated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.REMOVE_SKU_SUCCESS:{
        const newStateObject = Object.assign({}, {simpleError: null, skus: action.payload, skuDeleted: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.UPDATE_SKU_SUCCESS:{
        const newStateObject = Object.assign({}, {simpleError: null, skus: action.payload, skuUpdated:true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.ADD_SKU_SUCCESS: {
        const newStateObject = Object.assign({}, {simpleError: null, skus: action.payload, skuCreated: true});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_SKUS_FAILURE:
    case promtionsActionsLabels.REMOVE_SKU_FAILURE:
    case promtionsActionsLabels.UPDATE_SKU_FAILURE:
    case promtionsActionsLabels.ADD_SKU_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR, skus: null});
        newState = merge(state, newStateObject);
        break;
    }
    case promtionsActionsLabels.SEARCH_PROMOTIONS_BY_ENTITY: {

        let promos = {... state.promotions};
        let entity = action.payload;

        //implement the filtered by entity


        const newStateObject = Object.assign({}, {promotionsActivated: promos});
        newState = merge(state, newStateObject);
        break;
    }
    default:
        break;
    }

    return newState;
};