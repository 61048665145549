'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _, { delay } from 'lodash';

import EmptyState from 'common/components/empty-state';
import Pagination from 'common/components/pagination';
import {
    CANCEL_ICON,
    COPY_ICON,
    DELETE_ICON,
    STAR_ICON
} from 'common/constants/icon.constant';

import Modal from 'common/components/modal';
import { Checkbox } from 'common/components/inputs';

import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitySelectors from 'common/store/selectors/financing-entity.selector';

import PlansMatrix from './plans-matrix';
import PlansListView from './plans-list-view';
import Branches from './financing-entity-branch';
import BrancheForm from '../create-financing-branch';

import BinCardsComponent from './bin-cards';
import PromotionsApllied from './promotios-applied';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            plansAdded: financingEntitySelectors.getPlansAdded(state),
            plansUpdated: financingEntitySelectors.getPlansUpdated(state),
            plansDeleted: financingEntitySelectors.getPlansDeleted(state),
            selectedPlans: financingEntitySelectors.getSelectedPlans(state),
            selectedBranches: financingEntitySelectors.getSelectedBranches(state),
            branchOP: financingEntitySelectors.getBrancheOP(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePlan: (id, plan) => {
            dispatch(financingEntityActions.updatePlan(id, plan));
        },
        addPlans: (id, plans) => {
            dispatch(financingEntityActions.addPlans(id, plans));
        },
        copyPlans: (id, plans) => {
            dispatch(financingEntityActions.copyPlans(id, plans));
        },
        selectPlans: (value, ids) => {
            dispatch(financingEntityActions.selectPlans(value, ids));
        },
        selectBranches: (value, ids) => {
            dispatch(financingEntityActions.selectBranches(value, ids));
        },
        resetFinancingEntityMessages: () => {
            dispatch(financingEntityActions.resetFinancingEntityMessages());
        },
        bulkEnablePlans: (id, ids, value) => {
            dispatch(financingEntityActions.bulkEnablePlans(id, ids, value));
        },
        bulkDeletePlans: (id, ids) => {
            dispatch(financingEntityActions.bulkDeletePlans(id, ids));
        },
        createBranch: (params, id) => {
            dispatch(financingEntityActions.createBranch(params, id));
        },
        deleteBranches: (ids, id) => {
            dispatch(financingEntityActions.deleteBranches(ids, id));
        },
        updateBranch: (params, id) => {
            dispatch(financingEntityActions.updateBranch(params, id));
        },
        appendBin: (params, id) => {
            dispatch(financingEntityActions.appendBIN(params, id));
        },
        enabledBines: (params, id) => {
            dispatch(financingEntityActions.enabledBINES(params, id));
        },
        deleteBines: (params, id) => {
            dispatch(financingEntityActions.deleteBINES(params, id));
        },
        savePlansToBin: (params, id) => {
            dispatch(financingEntityActions.plansToBin(params, id));
        }
    };
};

class FinancingEntityDetailBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showModalBranch: false,
            selectedBranches: [],
            branchData: {},
            creating: true,
            showDeleteBrancheModal: false,
            branchProcessing: false,
            plansProcessing: false,
            iniIndexPageBranche: 0,
            endIndexPageBranche: 5,
            plansSelected:[],
            binSelected: null,
            showPlansModal: false,
            isMobile: window.matchMedia('(max-width: 440px)').matches
        };
    }

    componentWillUnmount() {
        this.props.selectPlans(false, this.props.selectedPlans);
    }

    renderDeletePlansModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/deactivate_plan.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Eliminar planes</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea eliminar los planes seleccionados?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Los planes eliminados son removidos del listado y no podrán ser seleccionados por el cliente.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.bulkDeletePlans(this.props.financingEntity.id, this.props.selectedPlans)}
                            className="btn btn-outline col-md-4">
                            Eliminar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeleteModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    addPlans = (data) => {
        this.props.addPlans(this.props.financingEntity.id, data);
    };

    componentDidUpdate() {
        if (this.props.plansAdded ) {
            this.props.resetFinancingEntityMessages();
        }

        if (this.props.plansUpdated || this.props.plansDeleted ) {
            setTimeout(() => {
                this.props.resetFinancingEntityMessages();
            }, 3000);
        }

        if (this.props.plansDeleted) {
            this.setState({showDeleteModal: false});
        }

        if (this.props.branchOP.branchAdded || this.props.branchOP.branchUpdated ) {
            delay(() => {
                this.props.resetFinancingEntityMessages();
                this.setState({showModalBranch: false, branchProcessing: false});
            }, 3000);

        }

        if (this.props.branchOP.branchDeleted ) {
            delay(() => {
                this.props.resetFinancingEntityMessages();
                this.setState({showDeleteBrancheModal: false, branchProcessing: false});
            }, 3000);
        }
    }



    renderDeleteBranchesModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteBrancheModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteBrancheModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/deactivate_plan.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Eliminar Sucursales</h2>
                    <p className="text-center">¿Está seguro que desea eliminar las sucursales seleccionadas?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Las sucursales son removidas del listado y no podrán ser seleccionadas por el cliente.</p>
                    </div>
                    {this.props.selectedBranches.length > 0 && !this.state.branchProcessing &&
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => {
                                this.props.deleteBranches(this.props.selectedBranches, this.props.financingEntity.id);
                                this.setState({branchProcessing: true});
                            }}
                            className="btn btn-outline col-md-4">
                            Eliminar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeleteBrancheModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>}
                    {this.state.branchProcessing && !this.props.simpleError && !this.props.branchOP.branchDeleted &&
                     <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                         <hr />
                         <p className="text-center">Eliminando la(s) sucursal(es).</p>
                     </div>
                    }
                    {this.props.branchOP.branchDeleted &&
                        <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                            <hr />
                            <p className="text-center">Ha eliminado la(s) sucursal(es) con éxito.</p>
                        </div>
                    }
                    {this.props.simpleError && this.state.branchProcessing &&
                    <div>
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                        <div style={{margin: '0px'}} className="row">
                            <div className="col-md-4 mgT" />
                            <button
                                onClick={() => this.setState({showDeleteBrancheModal: false, branchProcessing: false})}
                                className="btn btn-primary col-md-4">
                            Volver
                            </button>
                        </div>
                    </div>
                    }
                </div>
            </Modal>
        );
    }

    rendershowModalNewBranch(){
        return(
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showModalBranch: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showModalBranch}
                id='ModalView'
            >
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image={(this.state.creating) ? "/images/add_entity.svg" : "/images/edit_entity.svg"}
                    title=""
                    text=""
                />
                <div>
                    <h2 className="text-center">{(this.state.creating) ? 'Nueva Sucursal' : 'Editar Sucursal'}</h2>
                    <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                    {this.props.simpleError && this.state.branchProcessing &&
                    <div className='has-error mgB'>
                        {typeof this.props.simpleError === 'string' &&
                            <span className='help-block'>{this.props.simpleError}</span>
                        }
                        {typeof this.props.simpleError === 'object' &&
                            Object.entries(this.props.simpleError).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                    }
                    {this.props.branchOP.branchAdded &&
                    <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                        <hr />
                        <p className="text-center">Ha creado la nueva sucursal con éxito.</p>
                    </div>
                    }
                    {this.props.branchOP.branchUpdated &&
                    <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                        <hr />
                        <p className="text-center">Ha actualizado la sucursal con éxito.</p>
                    </div>
                    }
                    {this.state.branchProcessing && !this.props.branchOP.branchAdded && !this.props.simpleError && this.state.creating &&
                     <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                         <hr />
                         <p className="text-center">Creando una sucursal nueva.</p>
                     </div>
                    }
                    {this.state.branchProcessing && !this.props.branchOP.branchUpdated && !this.props.simpleError && !this.state.creating &&
                     <div style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                         <hr />
                         <p className="text-center">Actualizando la sucursal.</p>
                     </div>
                    }
                    {!this.state.branchProcessing &&
                <React.Fragment>
                    {(!this.props.branchOP.branchAdded && !this.props.branchOP.branchUpdated) &&
                <BrancheForm
                    creating = {this.state.creating}
                    error={this.props.simpleError}
                    onCancel={() => this.setState({showModalBranch: false})}
                    onSubmit={this.onCreateBranche}
                    branchData={this.state.branchData}
                />}
                </React.Fragment>}
                    {this.props.simpleError && this.state.branchProcessing &&
                     <div style={{margin: '0px'}} className="row">
                         <div className="col-md-4 mgT" />
                         <button
                             onClick={() => this.setState({showModalBranch: false, branchProcessing: false})}
                             className="btn btn-primary col-md-4">
                            Volver
                         </button>
                     </div>
                    }
                </div>
            </Modal>
        );
    }

    onCreateBranche = (params) => {
        if (this.state.creating) { this.props.createBranch(params, this.props.financingEntity.id); } else { this.props.updateBranch(params, this.props.financingEntity.id); }
        this.setState({branchProcessing: true});
    };

    editBranch = () => {
        let branchData = this.props.financingEntity.branches.filter(elem => {
            return elem.id === this.props.selectedBranches[0];
        }).map(el => {
            return {
                id: el.id,
                name: el.branch_name,
                address: el.address,
                phone: el.phone,
                ext: el.extension,
                latitude: null,
                longitude: null,
                municipality_id: el.municipality_id[0].id,
                province: el.municipality_id[0].province.id,
                contactname: el.contact_name,
                contactphone: el.contact_phone,
                contactemail: el.contact_email
            };
        });

        this.setState({showModalBranch: true, branchData: branchData[0], creating: false});
    };

    showModal = (el) => {
        let bin = this.props.financingEntity.bins.filter((b) => { return b.id === el; })[0];
        let plansS = bin.financing_plans.map((pl) => { return pl.id; });
        this.setState({binSelected: bin, plansSelected: plansS, showPlansModal: true});
    };

    plansSelect(val, ids) {
        let selectedPlans = this.state.plansSelected.slice();
        if (val) {
            selectedPlans = _.union(selectedPlans, ids);
        } else {
            selectedPlans = _.difference(selectedPlans, ids);
        }
        this.setState({plansSelected: selectedPlans});
    }

    savePlansInBIN() {
        let planIds = (this.state.plansSelected.length > 0) ? this.state.plansSelected.toString() : '-1';
        this.props.savePlansToBin({id: this.state.binSelected.id, plans: planIds}, this.props.financingEntity.id);
        this.setState({showPlansModal: false, plansSelected: []});
    }

    renderPlansToApplyModal() {
        let lengthplans = 0;
        if (this.props.financingEntity.plans) {
            lengthplans = _.intersection(this.props.financingEntity.plans.map(sku => sku.id), this.state.plansSelected).length;
        }
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showPlansModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showPlansModal}>
                <h2 className="text-center">Planes para el BIN</h2>
                <h4 className="text-center">{` ( ${(this.state.binSelected ? this.state.binSelected.binvalue : '')} )` }</h4>
                <hr className='w-95'></hr>

                <div className="important-information mgB">
                    <p><strong>Información importante</strong></p>
                    <p style={{textAlign:'justify'}}>Los planes seleccionados serán mostrados y aplicados al cliente según la tarjeta proporcionada.</p>
                </div>
                <hr className='w-95'></hr>
                <h5 className="pdL fsbold">Seleccione los planes</h5>

                {this.props.financingEntity.plans && this.props.financingEntity.plans.length > 0 &&
                <div className="row pdL2 " style={{borderColor: '#d2d2d2'}} role="region" aria-labelledby>
                    <div style={{overflowX:'scroll', borderLeft:'none', maxHeight:'450px', minHeight:'300px', overflowY:'auto'}}>
                        <table className="white-background " width="430px" style={{borderLeft:'1px solid #d2d2d2', borderRight:'1px solid #d2d2d2'}}>
                            <thead style={{boxShadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)', borderBottom:'1px solid #d2d2d2', borderTop:'1px solid #d2d2d2'}}>
                                <tr style={{textAlign:'center', fontSize:'14px', height:'30px'}}>
                                    <th className="border-md-right">
                                        {
                                            <div style={{margin:'10px 10px 10px 20px'}} onClick={(e) => e.stopPropagation()}>
                                                <Checkbox
                                                    squared={true}
                                                    label=""
                                                    checked={lengthplans === this.props.financingEntity.plans.length}
                                                    value={'all'}
                                                    indeterminate={lengthplans < this.props.financingEntity.plans.length && lengthplans > 0}
                                                    textStyle={{marginLeft:'40px', fontSize:'16px', fontWeight:'bold', overflowWrap:'break-word'}}
                                                    onChange={(val) => this.plansSelect(val, this.props.financingEntity.plans.map(plan => plan.id))}
                                                />
                                            </div>
                                        }
                                    </th>
                                    <th width="100px" style={{textAlign:'center'}}>Cuotas</th>
                                    <th width="320px" >
                                        <span>(Plan)</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.financingEntity.plans.map(el => {
                                    return (
                                        <tr style={{fontSize:'10px', borderBottom:' 1px solid #d2d2d2', height:'50px'}}
                                            className={'cursor-pointer fsbold color-blue'}
                                            key={'entity-plan-' + el.id}
                                        >
                                            <td className="border-md-right">
                                                <div style={{marginTop: '7px', marginLeft:'20px'}} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        squared={true}
                                                        checked={(this.state.plansSelected.indexOf(el.id) > -1)}
                                                        value={el.id}
                                                        textStyle={{marginLeft:'40px', fontSize:'16px', overflowWrap:'break-word'}}
                                                        onChange={(val, e) => this.plansSelect(val, [parseInt(e.currentTarget.value)])}
                                                    />
                                                </div>
                                            </td>
                                            <th width="100px">
                                                <div style={{textAlign:'center', paddingTop:'5px', fontWeight:'bolder', fontSize:'14px'}}>{el.payments}</div>
                                            </th>
                                            <td width="320px">
                                                <div style={{paddingLeft:'3px', paddingRight:'3px', wordWrap:'break-word', display:'flex', alignContent:'center', fontSize:'14px'}}>
                                                    <strong>({el.name})</strong></div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>}
                <div className='row no-gutters text-center' style={{marginTop:'20px'}}>
                    <div className='col-md-4'>
                        <button
                            className="btn btn-outline w-70"
                            onClick={() => this.setState({plansSelected: [], showPlansModal: false})}
                        >Descartar
                        </button>
                    </div>
                    <div className='col-md-4'>
                    </div>
                    <div className='col-md-4'>
                        <button
                            className="btn btn-primary w-70"
                            onClick={() => this.savePlansInBIN()}
                        >Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }


    renderSelected() {
        return (
            <div className="row mgT4 mgB no-gutters">
                <div className="border shadow-sm text-center col-md-2 m-auto">
                    <h5 className="mgB mgT">Seleccionados: {this.props.selectedPlans.length}</h5>
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    <button
                        onClick={() => this.props.copyPlans(this.props.financingEntity.id, this.props.selectedPlans)}
                        className="btn btn-group btn-transparent">
                        <i className={COPY_ICON} />
                        Copiar
                    </button>
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    <button
                        onClick={() => this.props.bulkEnablePlans(this.props.financingEntity.id, this.props.selectedPlans, 0)}
                        className="btn btn-group btn-transparent">
                        <i className={CANCEL_ICON} />
                        Desactivar
                    </button>
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    <button
                        onClick={() => this.props.bulkEnablePlans(this.props.financingEntity.id, this.props.selectedPlans, 1)}
                        className="btn btn-group btn-transparent">
                        <i className={STAR_ICON} />
                        Activar
                    </button>
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    <button
                        onClick={() => this.setState({showDeleteModal: true})}
                        className="btn btn-group btn-transparent">
                        <i className={DELETE_ICON} />
                        Eliminar
                    </button>
                </div>
                <div className="col-md-2"/>
            </div>
        );
    }

    onDataChange(page){
        let ini = page.selected * 5;
        let end = page.selected * 5 + 5;
        this.setState({iniIndexPageBranche: ini, endIndexPageBranche: end});
    }

    renderBotonera(){
        if (this.state.isMobile) {
            return (
                <div className="col-12 row mgT">
                    <div className="col-6 col-md-6">
                        <div className="row ">
                            <div className="col-4 mgB pdL0">
                                <button
                                    onClick = {() => this.setState({showModalBranch: true, creating: true, branchData:{}})}
                                    className="btn btn-transparent btn-link w-10  h-15"
                                >
                    Agregar
                                </button>
                            </div>
                            {this.props.selectedBranches.length === 1 &&
                <div className="col-4 mgB">
                    <button
                        onClick = {this.editBranch}
                        className="btn btn-transparent btn-link w-15  h-15">
                    Editar
                    </button>
                </div>}
                            {this.props.selectedBranches.length > 0 &&
                <div className="col-4 mgB">
                    <button
                        onClick = {() => this.setState({showDeleteBrancheModal: true})}
                        className="btn btn-transparent btn-link w-15 h-15">
                    Eliminar
                    </button>
                </div>}
                        </div>
                    </div>
                    <div className="col-6 col-md-6">
                        <div className="row mgT1" style={{float:'right'}}>
                            <Pagination
                                onPageChange={(page) => this.onDataChange(page)}
                                forcePage= {1}
                                pageCount={this.props.financingEntity.branches.length > 5 ? Math.trunc(this.props.financingEntity.branches.length / 5) + 1 : 1 }
                            />
                        </div>
                    </div>
                    <hr className="w-100 mgT3 mgB2"/>
                </div>);
        } else {
            return (
                <div className="col-12 row mgT">
                    <div className="col-6 col-md-6"> <div className="row ">
                        <div className="col-md-4 col-12 mgB">
                            <button
                                onClick = {() => this.setState({showModalBranch: true, creating: true, branchData:{}})}
                                className="btn btn-outline w-100" >
                    Agregar
                            </button>
                        </div>
                        {this.props.selectedBranches.length === 1 &&
            <div className="col-md-4 col-12 mgB">
                <button
                    onClick = {this.editBranch}
                    className="btn btn-outline w-100">
                       Editar
                </button>
            </div>}
                        {this.props.selectedBranches.length > 0 &&
                <div className="col-md-4 col-12 mgB">
                    <button
                        onClick = {() => this.setState({showDeleteBrancheModal: true})}
                        className="btn btn-outline w-100">
                    Eliminar
                    </button>
                </div>}
                    </div>
                    </div>
                    <div className="col-6 col-md-6">
                        <div className="row pagination mgT0" style={{float:'right'}}>
                            <Pagination
                                onPageChange={(page) => this.onDataChange(page)}
                                forcePage= {1}
                                pageCount={this.props.financingEntity.branches.length > 5 ? Math.trunc(this.props.financingEntity.branches.length / 5) + 1 : 1}
                            />
                        </div>
                    </div>
                    <hr className="w-100 mgT3 mgB2"/>
                </div> );
        }
    }

    enableBinCard = (binvalues, enabled) => {
        let data = {
            ids: binvalues,
            enabled: enabled
        };
        this.props.enabledBines(data, this.props.financingEntity.id);
    };

    deleteBinCard = (binvalues) => {
        let data = { ids: binvalues };
        this.props.deleteBines(data, this.props.financingEntity.id);
    };

    addBinCard = (binvalue) => {
        this.props.appendBin(binvalue, this.props.financingEntity.id);
    };

    render() {
        return (
            <div >
                <React.Fragment>

                    <Branches
                        branches={this.props.financingEntity.branches}
                        onBranchesSelected={this.props.selectBranches}
                        selectedBranches={this.props.selectedBranches}
                        page={[this.state.iniIndexPageBranche, this.state.endIndexPageBranche]
                        }
                        isMobile={this.props.isMobile}
                    />
                    {this.renderBotonera()}
                    <div className="row"> </div>
                    <div className="row"> <span className="align-center h2">Planes Aprobados</span> </div>
                    {this.props.selectedPlans.length === 0 &&
                    <PlansMatrix
                        onSubmit={this.addPlans}
                        history={this.props.history}
                        creating={true}
                        plansAdded={this.props.plansAdded}
                    />
                    }
                    {this.props.selectedPlans.length > 0 &&
                    this.renderSelected()
                    }

                    {this.props.plansUpdated &&
                    <div className='has-success mgB'>
                        <span className='help-block'>Planes actualizados correctamente</span>
                    </div>
                    }

                    {this.props.plansDeleted &&
                    <div className='has-success mgB'>
                        <span className='help-block'>Planes eliminados correctamente</span>
                    </div>
                    }

                    <div className="clearfix mgT3" />

                    {this.props.financingEntity.plans.length === 0 &&
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/financing_plan.svg"
                        title="Aquí se mostrarán los planes que se creen"
                    />
                    }
                    {this.props.simpleError &&
                    <div className='has-error mgB'>
                        {typeof this.props.simpleError === 'string' &&
                            <span className='help-block'>{this.props.simpleError}</span>
                        }
                        {typeof this.props.simpleError === 'object' &&
                            Object.entries(this.props.simpleError).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                    }
                    {this.props.financingEntity.plans.length > 0 &&
                    <PlansListView
                        plans={this.props.financingEntity.plans}
                        history={this.props.history}
                        onPlansSelected={this.props.selectPlans}
                        selectedPlans={this.props.selectedPlans}
                        updatePlan={this.props.updatePlan}
                        pagination={this.props.pagination}
                    />
                    }

                    {this.props.financingEntity.type.id === 4 &&
                        <React.Fragment>
                            <hr></hr>
                            <div className="row">
                                <span className="align-center h2 mgB2">Promociones activas  y BIN asociados</span>
                            </div>
                            <div className="row mgT2 " >
                                <div className='col-md-8 col-12 mgB '>
                                    <PromotionsApllied
                                        promotionsList = {this.props.financingEntity.activePromotions}
                                        history = {this.props.history}
                                    ></PromotionsApllied>
                                </div>
                                <div className='col-md-4 col-12 bordered ' style={{height:'410px'}}>
                                    <BinCardsComponent
                                        cardbins={this.props.financingEntity.bins}
                                        financingEntity={this.props.financingEntity.id}
                                        enableBinCard={this.enableBinCard}
                                        deleteBinCard={this.deleteBinCard}
                                        appendBinCard={this.addBinCard}
                                        plansToApply={this.showModal}
                                    >
                                    </BinCardsComponent>
                                </div>
                            </div>
                            <div className="row mgT2"> </div>
                            <hr></hr>
                        </React.Fragment>
                    }
                    {this.props.financingEntity.type.id !== 4 && /* aqui va condicionante si tiene promociones activas en planes y no es de Tarjetas */
                        <React.Fragment>
                            <hr></hr>
                            <div className="row">
                                <span className="align-center h2 mgB2">Promociones activas en planes</span>
                            </div>
                            <div className="row mgT2 " >
                                <div className='col-md-12 col-12 mgB '>
                                    <PromotionsApllied
                                        promotionsList = {this.props.financingEntity.activePromotions}
                                        history = {this.props.history}
                                    ></PromotionsApllied>
                                </div>
                            </div>
                            <div className="row mgT2"> </div>
                        </React.Fragment>
                    }
                    {this.renderDeletePlansModal()}
                    {this.rendershowModalNewBranch()}
                    {this.renderDeleteBranchesModal()}
                    {this.renderPlansToApplyModal()}
                </React.Fragment>
            </div>
        );
    }
}

/**
 * Properties validations.
 */
FinancingEntityDetailBody.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    financing: PropTypes.object,
    isMobile: PropTypes.bool
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingEntityDetailBody);

// export default FinancingEntityDetailBody;
