/* eslint-disable no-useless-constructor */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import moment from 'moment';

import {
    BACK_ICON,
    PENCIL_ICON,
    CANCEL_ICON,
    STAR_ICON,
    DELETE_ICON
} from 'common/constants/icon.constant';

import Modal from 'common/components/modal';
import EmptyState from 'common/components/empty-state';

import CreateEditPromotion from '../promotion-detail/create-edit-promotion';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from '../../../common/store/actions/user/user.actions';
import * as promotionSelector from 'common/store/selectors/promotion.selector';
import * as promotionActions from 'common/store/actions/promotions/promotions.actions';



const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            dropdowns: userSelectors.getDropdowns(state),
            loading: promotionSelector.getIsLoading(state),
            promotionUpdated: promotionSelector.getPromotionUpdated(state),
            promotion: promotionSelector.getPromotion(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        },
        updatePromotion: (params, id) => {
            dispatch(promotionActions.updatePromotion(params, id));
        },
        activatePromotion: (idpromo, val) => {
            dispatch(promotionActions.activatePromotion(idpromo, val));
        },
        removePromotion: (idpromo) => {
            dispatch(promotionActions.removePromotion(idpromo));
        }
    };
};

class PromotionDetailHeader extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showEditModal: false,
            showActivateModal: false,
            showRemoveModal: false,

            promotiondata: {
                code:  props.promotion.code,
                name: props.promotion.name,
                startDate: props.promotion.start_date,
                endDate: props.promotion.end_date,
                percentDiscount: props.promotion.percent_discount,
                maxDiscount: props.promotion.max_discount / 100,
                fixedDiscount: props.promotion.fixed_discount / 100,
                usesByClient: props.promotion.uses_by_client,
                applyWithDiscount: props.promotion.apply_with_discount,
                applyNotDiscount: props.promotion.apply_not_discount,
                store: props.promotion.store.id
            }
        };
    }

    componentDidMount() {
        if (this.props.dropdowns.stores.length === 0) {
            this.props.getDropdowns();
        }
    }

    onEditPromotion = (promotion) => {
        this.props.updatePromotion(promotion, this.props.promotion.id);
    };

    renderEditModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showEditModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/promotion_ok.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Editar Promoción</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>

                <CreateEditPromotion
                    creating={false}
                    onClose={() => this.setState({showEditModal:false})}
                    promotionData={this.state.promotiondata}
                    onSavePromo={this.onEditPromotion}
                    drops={this.props.dropdowns}
                >
                </CreateEditPromotion>
            </Modal>
        );

    }

    setEnabledPromo = () => {
        this.props.activatePromotion(this.props.promotion.id, !this.props.promotion.enabled);
    };

    renderDisabledModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showActivateModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showActivateModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/promotion_ok.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">{this.props.promotion.enabled ? 'Desactivar' : 'Reactivar'} Promoción</h3>
                <p className="text-center">¿Está seguro que desea {this.props.promotion.enabled ? 'desactivar' : 'reactivar'} esta promoción?</p>
                <div className="important-information mgB">
                    <p><strong>Información importante</strong></p>
                    <p className="text-left">Esta promoción será <span className="color-red">{this.props.promotion.enabled ? 'desactivada' : 'reactivada'}</span> en nuestro sistema {this.props.promotion.enabled ? 'y no podrá ser listada para su uso.' : '. Al terminar podrá usarla nuevamente.'}</p>
                </div>
                <div className='row no-gutters'>
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({showActivateModal: false})}
                    >Volver
                    </button>
                    <div className='col-md-4 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <button
                            className="btn btn-primary w-100"
                            onClick={this.setEnabledPromo}
                        >{this.props.promotion.enabled ? 'Desactivar' : 'Reactivar'}
                        </button>
                    </div>
                </div>
            </Modal>
        );

    }

    renderRemovePromotionModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showRemoveModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showRemoveModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/promotion_cancel.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Eliminar Promoción</h3>
                <p className="text-center">¿Está seguro que desea eliminar esta promoción?</p>
                <div className="important-information mgB">
                    <p><strong>Información importante</strong></p>
                    <p className="text-left">Esta promoción será <span className="color-red">eliminada</span> en nuestro sistema</p>
                </div>
                <div className='row no-gutters'>
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({showRemoveModal: false})}
                    >Volver
                    </button>
                    <div className='col-md-4 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <button
                            className="btn btn-primary w-100"
                            onClick={() => this.props.removePromotion(this.props.promotion.id)}
                        >Eliminar
                        </button>
                    </div>
                </div>
            </Modal>
        );

    }

    renderActions() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 pdL0 mgB">
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                            Volver
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-2 pdL0 mgB text-md-right">
                        <button
                            onClick={() => this.setState({showActivateModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                {this.props.promotion.enabled &&
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={CANCEL_ICON}/>
                                }
                                {!this.props.promotion.enabled &&
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={STAR_ICON}/>
                                }
                                {this.props.promotion.enabled ? 'Desactivar' : 'Reactivar'}
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-2 pdL0 mgB text-md-right">
                        <button
                            onClick={() => this.setState({showEditModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                            Editar
                            </strong>
                        </button>
                    </div>
                    <div className="col-md-2 mgB pdL0 text-md-right">
                        <button
                            onClick={() => this.setState({showRemoveModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={DELETE_ICON}/>
                            Eliminar
                            </strong>
                        </button>
                    </div>
                </div>
            </React.Fragment>

        );
    }

    render() {
        return (
            <div>
                {this.renderActions()}

                <div className="row">
                    <div style={{maxHeight: '247px'}} className="col-md-12">
                        <p className="mgB0">Código: <strong>{this.props.promotion.code}</strong></p>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h3 className="section-headline mgB">{this.props.promotion.name} <span style={{color: 'lightgray'}}>({this.props.promotion.enabled ? 'Activa' : 'Desactivada'})</span></h3>
                            </div>
                            <div className='col-md-6 text-right'>
                                <h3 className="section-headline mgB">Período activo: <span>({moment(this.props.promotion.start_date).format('DD-MM-YYYY') + ' - ' + moment(this.props.promotion.end_date).format('DD-MM-YYYY')})</span> </h3>
                            </div>
                        </div>
                        <div style={{minHeight: '80px'}} className="row bordered pd2 mgL0 mgR0">
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{(this.props.promotion.percent_discount !== null) ? this.props.promotion.percent_discount : 0}</strong></p>
                                    <p className="mgB0">% de Descuento</p>
                                </div>
                            </div>
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.promotion.max_discount / 100}</strong></p>
                                    <p className="mgB0">Descuento máximo</p>
                                </div>
                            </div>
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.promotion.fixed_discount / 100}</strong></p>
                                    <p className="mgB0">Descuento fijo</p>
                                </div>
                            </div>
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-sm-bottom border-md-right">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.promotion.uses_by_client}</strong></p>
                                    <p className="mgB0">Usos por cliente</p>
                                </div>
                            </div>
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-sm-bottom border-md-right">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.promotion.apply_with_discount === true ? 'Si': 'No' }</strong></p>
                                    <p className="mgB0">Aplicar con descuento</p>
                                </div>
                            </div>
                            <div style={{minHeight: '50px'}} className="col-md-2 col-6 text-center mgB border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.promotion.apply_not_discount === true ? 'Si': 'No'}</strong></p>
                                    <p className="mgB0">Aplicar sin descuento</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {this.renderEditModal()}
                {this.renderDisabledModal()}
                {this.renderRemovePromotionModal()}
            </div>
        );
    }

}


PromotionDetailHeader.propTypes = {
    promotion: PropTypes.object.isRequired,
    onActivate: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
};


export default withAlert()(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(PromotionDetailHeader));