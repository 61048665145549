'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import Steps, { Step } from 'rc-steps';

import {CHECK_ICON} from 'common/constants/icon.constant';

const StepperComponent = props => {
    return (
        <div className="bordered form-stepper pd-md-5">
            <Media query='(max-width: 600px)'>
                {matches =>
                    matches ? (
                        <React.Fragment>
                            <div className="mobile-stepper pdB" style={{padding: '10px 10px 10px'}}>
                                <Steps icons={{finish: <span style={{lineHeight: '1.9'}}><i className={CHECK_ICON}></i></span>}} labelPlacement="vertical" direction="horizontal" current={props.current}>
                                    {props.steps.map((e, i) => {
                                        return <Step key={'step-' + i} title={''} />;
                                    })}
                                </Steps>
                            </div>
                            <div style={{padding: '20px', marginTop: '-20px'}} className="white-background">
                                <h4 className="text-center">{props.steps[props.current]}</h4>
                            </div>
                        </React.Fragment>
                    ) : (
                        <Steps icons={{finish: <span style={{lineHeight: '1.9'}}><i className={CHECK_ICON}></i></span>}} labelPlacement="vertical" direction="horizontal" current={props.current}>
                            {props.steps.map((el, i) => {
                                return <Step key={'step-' + i} title={el} />;
                            })}
                        </Steps>
                    )
                }
            </Media>
        </div>

    );
};


StepperComponent.propTypes = {current: PropTypes.number};

export default StepperComponent;
