'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TransactionCheckoutContainer from './transaction-checkout';

export default class TransactionsContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route path="/transactions/checkout" component={TransactionCheckoutContainer} />
            </Switch>
        );
    }
}
