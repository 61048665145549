import parseBody from 'common/helpers/parse-body';
import formatQueryString from 'common/helpers/format-query-string';

import {
    post,
    deleteRequest,
    get
} from './api.service';

export function createPromotionRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post('/json/promotions/create', formData);
}

export function getPromotionsRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);
    }

    return get(
        `/json/promotions/search?${queryString}`
    );
}

export function getPromotionsActiveRequest() {
    return get(
        `/json/generic/promotionsActive`
    );
}

export function getPromotionRequest(id) {
    return get(`/json/promotions/` + id);
}

export function enabledPromotionRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post('/json/promotions/activate', formData);
}

export function removePromotionRequest(id) {
    return deleteRequest(`/json/promotions/` + id);
}

export function updatePromotionRequest(data) {
    const formData = new FormData();

    parseBody(formData, data.params);

    return post('/json/promotions/edit/'+ data.id, formData);
}

export function addCuponRequest(data) {
    const formData = new FormData();

    parseBody(formData, data.cupon);

    return post('/json/promotions/addcupon/'+ data.id, formData);
}

export function removeCuponPromotionRequest(data) {
    return deleteRequest('/json/promotions/'+ data.id +'/cupon/remove/' + data.cuponid);
}

export function getSkuesRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);

        return get(
            `/json/promotions/sku/search?${queryString}`
        );
    } else {
        return get(
            `/json/promotions/sku/all`
        );
    }
}

export function addSkuRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post('/json/promotions/sku', formData);
}

export function updateSkuRequest(data) {
    const formData = new FormData();

    parseBody(formData, data.params);

    return post('/json/promotions/sku/'+ data.id, formData);
}

export function removeSkuesRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post('/json/promotions/sku/bulkdelete', formData);
}

export function addRulePromotionRequest(data) {
    const formData = new FormData();

    parseBody(formData, data.params);

    return post('/json/promotions/addrule/'+ data.id, formData);

}

export function updateRulePromotionRequest(data) {
    const formData = new FormData();

    parseBody(formData, data.params);

    return post('/json/promotions/'+ data.id + '/rule/update/' + data.params.id, formData);

}

export function removeRulePromotionRequest(data) {
    return deleteRequest('/json/promotions/'+ data.id +'/rule/remove/' + data.idrule);
}