import * as apicardActionsLabel from './card.enum';

export const beginTransaction = () => ({ type: apicardActionsLabel.BEGIN_TRANSACTION});
export const closeTransaction = () => ({ type: apicardActionsLabel.CLOSE_TRANSACTION});

export const authorizeCard = (data) => ({ type: apicardActionsLabel.AUTHORIZE_CARD, payload: data});

export const authorizeCardSuccess = (data) => ({ type: apicardActionsLabel.AUTHORIZE_CARD_SUCCESS, payload: data });

export const authorizeCardFailure = (error) => ({ type: apicardActionsLabel.AUTHORIZE_CARD_FAILURE, payload: error });


export const registerToken = (data) => ({ type: apicardActionsLabel.REGISTER_CUSTOMER_TOKENS, payload: data});

export const registerTokenSuccess = (data) => ({ type: apicardActionsLabel.REGISTER_CUSTOMER_TOKENS_SUCCESS, payload: data });

export const registerTokenFailure = (error) => ({ type: apicardActionsLabel.REGISTER_CUSTOMER_TOKENS_FAILURE, payload: error });