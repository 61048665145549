import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { Document, Page } from 'react-pdf';

import {
    FormsyInputWrapper,
    Radio,
    TextInput,
    SelectInput,
    MoneyInput
} from 'common/components/inputs';
import Dropzone from 'common/components/dropzone';
import ImagePreview from 'common/components/image-preview';
import EmptyState from 'common/components/empty-state';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

import {IDENTIFICATION_TYPES} from 'common/constants/enums.constant';
import {MS_WORD_ICON} from 'common/constants/icon.constant';

export default class EditFinancingProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.objectToFields({...props.financing}),
            canSubmit: true,
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                lastname: { errorClass: null, dangerLabel: false },
                identificationType: { errorClass: null, dangerLabel: false },
                identification: { errorClass: null, dangerLabel: false },
                identificationFile: { errorClass: null, dangerLabel: false },
                email: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false },
                livingType: { errorClass: null, dangerLabel: false },
                livingTime: { errorClass: null, dangerLabel: false },

                isMarried: { errorClass: null, dangerLabel: false },
                spouseName: { errorClass: null, dangerLabel: false },
                spousePhone: { errorClass: null, dangerLabel: false },
                relativeName: { errorClass: null, dangerLabel: false },
                relativePhone: { errorClass: null, dangerLabel: false },

                companyName: { errorClass: null, dangerLabel: false },
                companyType: { errorClass: null, dangerLabel: false },
                companyStreet: { errorClass: null, dangerLabel: false },
                companyPhone: { errorClass: null, dangerLabel: false },
                companyTitle: { errorClass: null, dangerLabel: false },
                salary: { errorClass: null, dangerLabel: false },
                otherIncomes: { errorClass: null, dangerLabel: false },
                workingTime: { errorClass: null, dangerLabel: false }
            }
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    objectToFields = (data) => {

        let fields = {};

        if (!_.isEmpty(data)) {
            const financing = _.cloneDeep(data);
            fields = {
                userid: this.props.user.id,
                name: financing.name,
                lastname: financing.lastname,
                identificationType: financing.identificationType.id,
                identification: financing.identification,
                identificationFile: financing.identificationFile,
                identificationFile2: financing.identificationFile2,
                email: financing.email,
                phone: financing.phone,
                livingType: financing.livingType.id,
                livingTime: financing.livingTime.id,

                isMarried: financing.isMarried,
                spouseName: financing.spouseName || '',
                spouseIdentificationType: financing.spouseIdentificationType ? financing.spouseIdentificationType.id : null,
                spouseIdentification: financing.spouseIdentification || '',
                spousePhone: financing.spousePhone || '',
                relativeName: financing.relativeName,
                relativePhone: financing.relativePhone,

                companyName: financing.companyName,
                companyType: financing.companyType.id,
                companyStreet: financing.companyStreet,
                companyPhone: financing.companyPhone,
                companyTitle: financing.companyTitle,
                salary: financing.salary,
                otherIncomes: financing.otherIncomes,
                workingTime: financing.workingTime.id
            };
        } else {
            fields = {
                userid: this.props.user.id,
                name:  this.props.user.name,
                lastname: this.props.user.lastname,
                identificationType:  this.props.user.identificationType.id,
                identification: this.props.user.identification,
                identificationFile: null,
                identificationFile2: null,
                email: this.props.user.email,
                phone: this.props.user.phone,
                livingType:  null,
                livingTime: null,

                isMarried: false,
                spouseName:  '',
                spouseIdentificationType: null,
                spouseIdentification: '',
                spousePhone: '',
                relativeName: '',
                relativePhone: '',

                companyName: '',
                companyType: null,
                companyStreet: '',
                companyPhone: '',
                companyTitle: '',
                salary: '',
                otherIncomes: '',
                workingTime: null
            };
        }

        return fields;
    };

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    onIdentificationFileChanged = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('identificationFile', value);
    };

    onIdentificationFile2Changed = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('identificationFile2', value);
    };

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let data = {... this.state.data};
        data[field] = value;

        if (field === 'spouseIdentificationType') {
            data['spouseIdentification'] = '';
        }

        if (field === 'isMarried') {
            data['spouseName'] = '';
            data['spouseIdentificationType'] = null;
            data['spouseIdentification'] = '';
            data['spousePhone'] = '';
        }
        this.setState({data: data});
    };

    onSubmit = () => {
        let data = this.state.data;
        if (_.isString(data.identificationFile)) {
            data.identificationFile = data.identificationFile.replace('/documents/identifications/', '');
        }
        if (_.isString(data.identificationFile2)) {
            data.identificationFile2 = data.identificationFile2.replace('/documents/identifications/', '');
        }
        this.props.onSubmit({...data});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    renderSpouseFields() {
        return (
            <React.Fragment>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-8 mgB2"
                    name="spouseName"
                    errorClassName={this.state.errorFields.spouseName.errorClass}
                    value={this.state.data.spouseName}>
                    <TextInput
                        label={<span>Nombre completo del cónyuge <span className="color-red">*</span></span>}
                        numbers={false}
                        dangerLabel={this.state.errorFields.spouseName.dangerLabel}
                        placeholder="Inserte el nombre"
                        value={this.state.data.spouseName}
                        onChange={(val) => this.onFieldChange('spouseName', val)}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-4 mgB2"
                    name="spousePhone"
                    validations="isValidPhone"
                    errorClassName={this.state.errorFields.spousePhone.errorClass}
                    validationError = "Por favor ingrese un número de telefono válido."
                    value={this.state.data.spousePhone}>
                    <TextInput
                        label={<span>Teléfono del cónyuge<span className="color-red">*</span></span>}
                        dangerLabel={this.state.errorFields.spousePhone.dangerLabel}
                        value={this.state.data.spousePhone}
                        onChange={(val) => this.onFieldChange('spousePhone', val)}
                        maxLength={10}
                        numbers={true}
                        onlyNumbers={true}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    className="col-12 col-md-4 mgB2"
                    name="spouseIdentificationType">
                    <Radio
                        selectedValue={this.state.data.spouseIdentificationType}
                        radioStyle={{ padding: '0px' }}
                        key={'spouseIdentificationType'}
                        optionsClassName="bordered mgR"
                        className="d-flex"
                        labelStyle={{paddingRight: '5px'}}
                        inputStyle={{height: '44px', padding: '7px'}}
                        onChange={(val) => this.onFieldChange('spouseIdentificationType', val)}
                        label="Doc. de identidad del cónyuge"
                        options={this.props.dropdowns.identificationTypes.map(element => {
                            return {
                                value: element.value,
                                label: element.label
                            };
                        })}
                    />
                </FormsyInputWrapper>
                {this.state.data.spouseIdentificationType === IDENTIFICATION_TYPES.CEDULA &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB"
                        name="spouseIdentification"
                        value={this.state.data.spouseIdentification}
                        validationError='Ingrese una cédula válida.'
                        validations={this.state.data.spouseIdentification !== '' ? 'isValidIdentification' : null}>
                        <TextInput
                            label={<span>No. Documento de identidad<span className="color-red">*</span></span>}
                            value={this.state.data.spouseIdentification}
                            onChange={(val) => this.onFieldChange('spouseIdentification', val)}
                            maxLength={11}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                }
                {this.state.data.spouseIdentificationType === IDENTIFICATION_TYPES.PASSPORT &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB2"
                        value={this.state.data.spouseIdentification}
                        name="spouseIdentification">
                        <TextInput
                            value={this.state.data.spouseIdentification}
                            label="No. Documento de identidad"
                            onChange={(val) => this.onFieldChange('spouseIdentification', val)}
                        />
                    </FormsyInputWrapper>
                }
                <div className="col-md-4 mgB2"></div>

            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Formsy
                    ref={this.basicDataRef}
                    onValid={this.enableSubmit}
                    onInvalid={this.disableSubmit}>
                    <div className="row mgB2">
                        <FormsyInputWrapper
                            required
                            className="col-6 col-md-2 mgB2"
                            name="name"
                            errorClassName={this.state.errorFields.name.errorClass}
                            value={this.state.data.name}>
                            <TextInput
                                label={<span>Nombre <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.name.dangerLabel}
                                placeholder="Inserte el nombre"
                                value={this.state.data.name}
                                onChange={(val) => this.onFieldChange('name', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-6 col-md-3 mgB2"
                            name="lastname"
                            errorClassName={this.state.errorFields.lastname.errorClass}
                            value={this.state.data.lastname}>
                            <TextInput
                                label={<span>Apellidos <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.lastname.dangerLabel}
                                placeholder="Inserte los apellidos"
                                value={this.state.data.lastname}
                                onChange={(val) => this.onFieldChange('lastname', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-4 mgB2"
                            name="identificationType"
                            errorClassName={this.state.errorFields.identificationType.errorClass}
                            value={this.state.data.identificationType !== null && this.state.data.identificationType !== undefined}
                            validations="isTrue">
                            <Radio
                                selectedValue={this.state.data.identificationType}
                                radioStyle={{ padding: '0px' }}
                                optionsClassName="bordered mgR"
                                className="d-flex"
                                labelStyle={{paddingRight: '5px'}}
                                inputStyle={{height: '44px', padding: '7px'}}
                                onChange={(val) => this.onFieldChange('identificationType', val)}
                                label={<span>Documento de identidad <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.identificationType.dangerLabel}
                                options={this.props.dropdowns.identificationTypes.map(element => {
                                    return {
                                        value: element.value,
                                        label: element.label
                                    };
                                })}
                            />
                        </FormsyInputWrapper>
                        {this.state.data.identificationType === IDENTIFICATION_TYPES.CEDULA &&
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-3 mgB"
                                name="identification"
                                errorClassName={this.state.errorFields.identification.errorClass}
                                value={this.state.data.identification}
                                validationError='Ingrese una cédula válida.'
                                validations='isValidIdentification'>
                                <TextInput
                                    label={<span>No. Documento <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.identification.dangerLabel}
                                    value={this.state.data.identification}
                                    onChange={(val) => this.onFieldChange('identification', val)}
                                    maxLength={11}
                                    numbers={true}
                                    onlyNumbers={true}
                                />
                            </FormsyInputWrapper>
                        }
                        {this.state.data.identificationType === IDENTIFICATION_TYPES.PASSPORT &&
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-3 mgB2"
                                errorClassName={this.state.errorFields.identification.errorClass}
                                value={this.state.data.identification}
                                name="identification">
                                <TextInput
                                    label={<span>No. Documento<span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.identification.dangerLabel}
                                    value={this.state.data.identification}
                                    onChange={(val) => this.onFieldChange('identification', val)}
                                />
                            </FormsyInputWrapper>
                        }
                        <FormsyInputWrapper
                            className="col-12 col-md-6 mgB2"
                            name="identificationFile"
                            value={this.state.data.identificationFile}>
                            <p className={`form-label `}>
                                <span className="label">Documento de identidad de ambos lados</span>
                            </p>
                            <Dropzone
                                accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                                onDrop={this.onIdentificationFileChanged}
                                multiple={false}
                                full={true}>
                                <div>
                                    {this.state.data.identificationFile &&
                                        <div className="m-auto">
                                            {(this.state.data.identificationFile.name ? this.state.data.identificationFile.name.toLowerCase().includes('.pdf') : this.state.data.identificationFile.toLowerCase().includes('.pdf')) &&
                                                <Document
                                                    file={this.state.data.identificationFile}>
                                                    <Page height={300} pageNumber={1} />
                                                </Document>
                                            }
                                            {(this.state.data.identificationFile.name ? this.state.data.identificationFile.name.toLowerCase().includes('.doc') : this.state.data.identificationFile.toLowerCase().includes('.doc')) &&
                                                <div className="mgT15">
                                                    <div className="mgB">
                                                        <span style={{fontSize: '4rem'}}>
                                                            <i className = {MS_WORD_ICON}/>
                                                        </span>
                                                    </div>
                                                    <p>{this.state.data.identificationFile.name}</p>
                                                </div>
                                            }
                                            {!(this.state.data.identificationFile.name ? this.state.data.identificationFile.name.toLowerCase().includes('.pdf') : this.state.data.identificationFile.toLowerCase().includes('.pdf')) &&
                                                !(this.state.data.identificationFile.name ? this.state.data.identificationFile.name.toLowerCase().includes('.doc') : this.state.data.identificationFile.toLowerCase().includes('.doc')) &&
                                                <ImagePreview
                                                    style = {
                                                        {
                                                            height: '300px',
                                                            width: '100%',
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat'
                                                        }
                                                    }
                                                    className="image-preview image-preview__xl"
                                                    image={this.state.data.identificationFile}>
                                                </ImagePreview>
                                            }
                                        </div>
                                    }
                                    {!this.state.data.identificationFile &&
                                        <div className="mgT15">
                                            <EmptyState
                                                imgStyle={
                                                    {
                                                        borderRadius: '150px',
                                                        margin: 'auto',
                                                        width: '170px',
                                                        padding: '20px'
                                                    }
                                                }
                                                small={true}
                                                image="/images/add_image.svg"
                                                title=""
                                                text=""
                                            />
                                            <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento)</p>
                                        </div>
                                    }
                                </div>
                            </Dropzone>
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            className="col-12 col-md-6 mgB2"
                            name="identificationFile2">
                            <Dropzone
                                accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                                onDrop={this.onIdentificationFile2Changed}
                                multiple={false}
                                style={{marginTop: '23px'}}
                                full={true}>
                                <div>
                                    {this.state.data.identificationFile2 &&
                                        <div className="m-auto">
                                            {(this.state.data.identificationFile2.name ? this.state.data.identificationFile2.name.toLowerCase().includes('.pdf') : this.state.data.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                <Document
                                                    file={this.state.data.identificationFile2}>
                                                    <Page height={300} pageNumber={1} />
                                                </Document>
                                            }
                                            {(this.state.data.identificationFile2.name ? this.state.data.identificationFile2.name.toLowerCase().includes('.doc') : this.state.data.identificationFile2.toLowerCase().includes('.doc')) &&
                                                <div className="mgT15">
                                                    <div className="mgB">
                                                        <span style={{fontSize: '4rem'}}>
                                                            <i className = {MS_WORD_ICON}/>
                                                        </span>
                                                    </div>
                                                    <p>{this.state.data.identificationFile2.name}</p>
                                                </div>
                                            }
                                            {!(this.state.data.identificationFile2.name ? this.state.data.identificationFile2.name.toLowerCase().includes('.pdf') : this.state.data.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                !(this.state.data.identificationFile2.name ? this.state.data.identificationFile2.name.toLowerCase().includes('.doc') : this.state.data.identificationFile2.toLowerCase().includes('.doc')) &&
                                                <ImagePreview
                                                    style = {
                                                        {
                                                            height: '300px',
                                                            width: '100%',
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat'
                                                        }
                                                    }
                                                    className="image-preview image-preview__xl"
                                                    image={this.state.data.identificationFile2}>
                                                </ImagePreview>
                                            }
                                        </div>
                                    }
                                    {!this.state.data.identificationFile2 &&
                                        <div className="mgT15">
                                            <EmptyState
                                                imgStyle={
                                                    {
                                                        borderRadius: '150px',
                                                        margin: 'auto',
                                                        width: '170px',
                                                        padding: '20px'
                                                    }
                                                }
                                                small={true}
                                                image="/images/add_image.svg"
                                                title=""
                                                text=""
                                            />
                                            <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento) (opcional)</p>
                                        </div>
                                    }
                                </div>
                            </Dropzone>
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-7 mgB2"
                            name="email"
                            validations="isEmail"
                            errorClassName={this.state.errorFields.email.errorClass}
                            validationError="Por favor ingrese un correo electrónico válido."
                            value={this.state.data.email}>
                            <TextInput
                                label={<span>Correo electrónico <span className="color-red">*</span></span>}
                                placeholder="Inserte el correo"
                                dangerLabel={this.state.errorFields.email.dangerLabel}
                                value={this.state.data.email}
                                onChange={(val) => this.onFieldChange('email', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-5 mgB2"
                            name="phone"
                            validations="isValidDOMPhone"
                            errorClassName={this.state.errorFields.phone.errorClass}
                            validationError = "Por favor ingrese un número de telefono válido."
                            value={this.state.data.phone}>
                            <TextInput
                                label={<span>Teléfono <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.phone.dangerLabel}
                                value={this.state.data.phone}
                                onChange={(val) => this.onFieldChange('phone', val)}
                                maxLength={10}
                                numbers={true}
                                onlyNumbers={true}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-4 mgB2"
                            name="livingType"
                            errorClassName={this.state.errorFields.livingType.errorClass}
                            value={this.state.data.livingType !== null && this.state.data.livingType !== undefined}
                            validations="isTrue">
                            <Radio
                                selectedValue={this.state.data.livingType}
                                radioStyle={{ padding: '0px' }}
                                optionsClassName="bordered mgR"
                                className="d-flex"
                                labelStyle={{paddingRight: '5px'}}
                                inputStyle={{height: '44px', padding: '7px'}}
                                onChange={(val) => this.onFieldChange('livingType', val)}
                                label={<span>Tipo de vivienda <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.livingType.dangerLabel}
                                options={this.props.dropdowns.livingTypes.map(element => {
                                    return {
                                        value: element.value,
                                        label: element.label
                                    };
                                })}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-8 mgB2"
                            name="livingTime"
                            errorClassName={this.state.errorFields.livingTime.errorClass}
                            value={this.state.data.livingTime !== null && this.state.data.livingTime !== undefined}
                            validations="isTrue">
                            <SelectInput
                                floatingLabel={<span>Tiempo en la vivienda <span className="color-red">*</span></span>}
                                key={'userData.livingTime'}
                                dangerLabel={this.state.errorFields.livingTime.dangerLabel}
                                label="Seleccione una opción"
                                options={this.props.dropdowns.livingTimes}
                                value={this.state.data.livingTime}
                                onChange={(val) => this.onFieldChange('livingTime', val)}
                            />
                        </FormsyInputWrapper>
                        <h3 className="mgT col-12">
                            <strong>Datos adicionales</strong>
                        </h3>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-12 mgB2"
                            name="isMarried"
                            errorClassName={this.state.errorFields.isMarried.errorClass}
                            value={this.state.data.isMarried !== null && this.state.data.isMarried !== undefined}
                            validations="isTrue">
                            <Radio
                                selectedValue={this.state.data.isMarried}
                                radioStyle={{ padding: '0px' }}
                                optionsClassName="bordered mgR"
                                className="d-flex"
                                labelStyle={{paddingRight: '5px'}}
                                inputStyle={{height: '44px', padding: '7px'}}
                                onChange={(val) => this.onFieldChange('isMarried', val)}
                                label={<span>Estado civil <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.isMarried.dangerLabel}
                                options={[
                                    {value: false, label: 'Soltero/a'},
                                    {value: true, label: 'Casado/a'}
                                ]}
                            />
                        </FormsyInputWrapper>
                        {this.state.data.isMarried &&
                            this.renderSpouseFields()
                        }
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-8 mgB2"
                            name="relativeName"
                            errorClassName={this.state.errorFields.relativeName.errorClass}
                            value={this.state.data.relativeName}>
                            <TextInput
                                label={<span>Nombre completo de un familiar <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.relativeName.dangerLabel}
                                placeholder="Inserte el nombre"
                                value={this.state.data.relativeName}
                                onChange={(val) => this.onFieldChange('relativeName', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-4 mgB2"
                            name="relativePhone"
                            validations="isValidDOMPhone"
                            errorClassName={this.state.errorFields.relativePhone.errorClass}
                            validationError = "Por favor ingrese un número de telefono válido."
                            value={this.state.data.relativePhone}>
                            <TextInput
                                label={<span>Teléfono del familiar  <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.relativePhone.dangerLabel}
                                value={this.state.data.relativePhone}
                                onChange={(val) => this.onFieldChange('relativePhone', val)}
                                maxLength={10}
                                numbers={true}
                                onlyNumbers={true}
                            />
                        </FormsyInputWrapper>
                        <h3 className="mgT col-12">
                            <strong>Datos laborales</strong>
                        </h3>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-8 mgB2"
                            name="companyName"
                            errorClassName={this.state.errorFields.companyName.errorClass}
                            value={this.state.data.companyName}>
                            <TextInput
                                label={<span>Nombre de la empresa donde labora <span className="color-red">*</span></span>}
                                numbers={false}
                                dangerLabel={this.state.errorFields.companyName.dangerLabel}
                                value={this.state.data.companyName}
                                onChange={(val) => this.onFieldChange('companyName', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-4 mgB2"
                            name="companyType"
                            validations="isTrue"
                            errorClassName={this.state.errorFields.companyType.errorClass}
                            value={this.state.data.companyType !== null}>
                            <SelectInput
                                floatingLabel={<span>Tipo de empresa <span className="color-red">*</span></span>}
                                key={'data.companyType'}
                                dangerLabel={this.state.errorFields.companyType.dangerLabel}
                                label="Seleccione una opción"
                                options={this.props.dropdowns.companyTypes}
                                value={this.state.data.companyType}
                                onChange={(val) => this.onFieldChange('companyType', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-8 mgB2"
                            name="companyStreet"
                            errorClassName={this.state.errorFields.companyStreet.errorClass}
                            value={this.state.data.companyStreet}>
                            <TextInput
                                label={<span>Dirección de la empresa <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.companyStreet.dangerLabel}
                                value={this.state.data.companyStreet}
                                onChange={(val) => this.onFieldChange('companyStreet', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-4 mgB2"
                            name="companyPhone"
                            validations="isValidDOMPhone"
                            errorClassName={this.state.errorFields.companyPhone.errorClass}
                            validationError = "Por favor ingrese un número de telefono válido."
                            value={this.state.data.companyPhone}>
                            <TextInput
                                label={<span>Teléfono de la empresa  <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.companyPhone.dangerLabel}
                                value={this.state.data.companyPhone}
                                onChange={(val) => this.onFieldChange('companyPhone', val)}
                                maxLength={10}
                                numbers={true}
                                onlyNumbers={true}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-3 mgB2"
                            name="companyTitle"
                            errorClassName={this.state.errorFields.companyTitle.errorClass}
                            value={this.state.data.companyTitle}>
                            <TextInput
                                label={<span>Cargo que ocupa <span className="color-red">*</span></span>}
                                dangerLabel={this.state.errorFields.companyTitle.dangerLabel}
                                value={this.state.data.companyTitle}
                                onChange={(val) => this.onFieldChange('companyTitle', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-3 mgB2"
                            name="workingTime"
                            errorClassName={this.state.errorFields.workingTime.errorClass}
                            value={this.state.data.workingTime !== null && this.state.data.workingTime !== undefined}
                            validations="isTrue">
                            <SelectInput
                                floatingLabel={<span>Tiempo en la empresa <span className="color-red">*</span></span>}
                                key={'data.workingTime'}
                                dangerLabel={this.state.errorFields.workingTime.dangerLabel}
                                label="Seleccione una opción"
                                options={this.props.dropdowns.workingTimes}
                                value={this.state.data.workingTime}
                                onChange={(val) => this.onFieldChange('workingTime', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 col-md-3 mgB2"
                            name="salary"
                            errorClassName={this.state.errorFields.salary.errorClass}
                            value={this.state.data.salary}>
                            <MoneyInput
                                floatingLabel={<span>Sueldo <span className="color-red">*</span></span>}
                                placeholder="RD$"
                                dangerLabel={this.state.errorFields.salary.dangerLabel}
                                value={this.state.data.salary}
                                onChange={(val) => this.onFieldChange('salary', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            className="col-12 col-md-3 mgB2"
                            name="otherIncomes"
                            value={this.state.data.otherIncomes}>
                            <MoneyInput
                                floatingLabel={<span>Otros ingresos </span>}
                                placeholder="RD$"
                                dangerLabel={this.state.errorFields.otherIncomes.dangerLabel}
                                value={this.state.data.otherIncomes}
                                onChange={(val) => this.onFieldChange('otherIncomes', val)}
                            />
                        </FormsyInputWrapper>
                    </div>
                </Formsy>
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='edit-financing-profile-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.data}>
                        <button
                            id="submit-button"
                            disabled={JSON.stringify(this.objectToFields(this.props.financing)) === JSON.stringify(this.state.data)}
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </React.Fragment>
        );
    }
}

EditFinancingProfile.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    financing: PropTypes.object,
    dropdowns: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
