/* User-related state changes goes here */

import PropTypes from 'prop-types';
import _ from 'lodash';
import * as userActionsLabels from 'common/store/actions/user/user.enum';

import merge from 'common/helpers/merge-objects';

import { GENERIC_ERROR } from 'common/constants/errors.constant';
import {
    DEFAULT_PAGINATION,
    DEFAULT_USERS_FILTERS
} from 'common/constants/objects.constant';


export const userInitialState = Object.seal({
    loading: false,
    authUser: null,
    validating: null,
    isValidEmail: false,
    error: null,
    validateEmailError: null,
    simpleError: null,
    emailSent: false,
    verificationEmailSent: false,
    verificationEmailError: null,
    userdataprofile: null,

    passwordChanged: false,
    pagination: DEFAULT_PAGINATION,
    userCreated: null,
    userUpdated: false,
    userDeleted: false,
    selectedUsers: [],
    userActions: null,
    userActionsLoading: false,
    userActionsError: null,
    users: [],
    dropdowns: {
        financingEntities: [],
        stores: [],
        userTypes: []
    },
    registerDropdowns: {
        provinces: [],
        countries: [],
        identificationTypes: [],
        livingTypes: [],
        livingTimes: [],
        workingTimes: [],
        companyTypes: []
    },
    filters: DEFAULT_USERS_FILTERS,
    singleUser: null,
    userFinancings: [],

    // Properties validation.
    propTypes: {
        loading: PropTypes.bool,
        authUser: PropTypes.object,
        error: PropTypes.string,
        simpleError: PropTypes.string,
        emailSent: PropTypes.bool,
        pagination: PropTypes.object,
        dropdowns: PropTypes.object,
        passwordChanged: PropTypes.bool,
        userCreated: PropTypes.object,
        userUpdated: PropTypes.bool,
        userDeleted: PropTypes.bool,
        users: PropTypes.array,
        userActions: PropTypes.object,
        userActionsLoading: PropTypes.bool,
        userActionsError: PropTypes.string,
        selectedUsers: PropTypes.array,
        singleUser: PropTypes.object
    }
});

export const userReducer = (state = userInitialState, action) => {
    let newState = state;
    switch (action.type) {
    case userActionsLabels.AUTH_USER: {
        const newStateObject = Object.assign({}, { error: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.AUTH_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { authUser: action.payload, error: null });
        // localStorage.setItem('authUser', JSON.stringify(action.payload));
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.AUTH_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: 'Usuario o contraseña no válidos'});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.SEND_VERIFICATION_EMAIL: {
        const newStateObject = Object.assign({}, { verificationEmailError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.SEND_VERIFICATION_EMAIL_SUCCESS: {
        const newStateObject = Object.assign({}, { verificationEmailSent: true, verificationEmailError: null });
        // localStorage.setItem('authUser', JSON.stringify(action.payload));
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.SEND_VERIFICATION_EMAIL_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {verificationEmailError: error, verificationEmailSent: false});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.VALIDATE_EMAIL: {
        const newStateObject = Object.assign({}, { isValidEmail: false, validateEmailError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.VALIDATE_EMAIL_SUCCESS: {
        const newStateObject = Object.assign({}, { isValidEmail: true, validateEmailError: null });
        // localStorage.setItem('authUser', JSON.stringify(action.payload));
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.VALIDATE_EMAIL_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {validateEmailError: error, isValidEmail: false});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.LOGOUT_USER: {
        const newStateObject = Object.assign({}, { error: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.LOGOUT_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { authUser: null, error: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.LOGOUT_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FORGOT_PASSWORD: {
        const newStateObject = Object.assign({}, { error: null, emailSent: false, isValidEmail: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FORGOT_PASSWORD_SUCCESS: {
        const newStateObject = Object.assign({}, { emailSent: true, error: null, userdataprofile: null, isValidEmail: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FORGOT_PASSWORD_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: error, emailSent: false, userdataprofile: null});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.USER_FORGOT_PASSWORD: {
        const newStateObject = Object.assign({}, { simpleError: null, emailSent: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.USER_FORGOT_PASSWORD_SUCCESS: {
        const newStateObject = Object.assign({}, { emailSent: true, simpleError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.USER_FORGOT_PASSWORD_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error, emailSent: false});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.RESET_PASSWORD: {
        const newStateObject = Object.assign({}, { error: null, passwordChanged: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.RESET_PASSWORD_SUCCESS: {
        const newStateObject = Object.assign({}, { passwordChanged: true, error: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.RESET_PASSWORD_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: error, passwordChanged: false});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.RESET_USER_MESSAGES: {
        const newStateObject = Object.assign({}, {
            userUpdated: false,
            userCreated: null,
            userDeleted: false,
            emailSent: false,
            verificationEmailSent: false,
            error: null,
            validateEmailError: null,
            verificationEmailError: null,
            passwordChanged: false
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ADD_ADDRESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ADD_ADDRESS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ADD_ADDRESS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_FINANCING_PROFILE:
    case userActionsLabels.EDIT_FINANCING_PROFILE: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_FINANCING_PROFILE_SUCCESS:
    case userActionsLabels.EDIT_FINANCING_PROFILE_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_FINANCING_PROFILE_FAILURE:
    case userActionsLabels.EDIT_FINANCING_PROFILE_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_ADDRESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_ADDRESS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_ADDRESS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.EDIT_ADDRESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.EDIT_ADDRESS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.EDIT_ADDRESS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.UPDATE_USER: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.UPDATE_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.UPDATE_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ACTIVATE_USER: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ACTIVATE_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.ACTIVATE_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_ACTIVATE_USERS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_ACTIVATE_USERS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_ACTIVATE_USERS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_USER: {
        const newStateObject = Object.assign({}, { simpleError: null, userDeleted: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userDeleted: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.DELETE_USER_FAILURE: {
        const newStateObject = Object.assign({}, { userDeleted: false, simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_DELETE_USERS: {
        const newStateObject = Object.assign({}, { simpleError: null, userDeleted: false });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_DELETE_USERS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userDeleted: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.BULK_DELETE_USERS_FAILURE: {
        const newStateObject = Object.assign({}, { userDeleted: false, simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER: {
        const newStateObject = Object.assign({}, { error: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            singleUser: action.payload.user,
            userFinancings: action.payload.financings
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT: {
        const newStateObject = Object.assign({}, { simpleError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT_SUCCESS: {
        const newStateObject = Object.assign({}, {
            simpleError: null,
            singleUser: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_CLIENT: {
        const newStateObject = Object.assign({}, { error: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_CLIENT_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            singleUser: action.payload.user,
            userFinancings: action.payload.financings
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_CLIENT_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER_ACTIONS: {
        const newStateObject = Object.assign({}, {
            userActionsError: null,
            userActionsLoading: true
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER_ACTIONS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            userActionsError: null,
            userActionsLoading: false,
            userActions: Array.isArray(action.payload) ? null : action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USER_ACTIONS_FAILURE: {
        let error = 'Hubo un error al momento de cargar las acciones.';
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            userActionsLoading: false,
            userActionsError: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: true,
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            users: action.payload.data ? action.payload.data : [],
            pagination: (action.payload.pagination) ? action.payload.pagination : DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error,
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_USER: {
        const newStateObject = Object.assign({}, { simpleError: null, userCreated: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, userCreated: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { userCreated: null, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.REGISTER_USER: {
        const newStateObject = Object.assign({}, { error: null, authUser: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.REGISTER_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { error: null, authUser: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.REGISTER_USER_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { authUser: null, error: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CANCEL_GET_USERS: {
        const newStateObject = Object.assign({}, { error: null, users: [], pagination: DEFAULT_PAGINATION});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.SET_USERS_FILTERS: {
        const newStateObject = Object.assign({}, { filters: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS_DROPDOWNS: {
        const newStateObject = Object.assign({}, {simpleError: null});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS_DROPDOWNS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            simpleError: null,
            dropdowns: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_USERS_DROPDOWNS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS: {
        const newStateObject = Object.assign({}, {simpleError: null});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            simpleError: null,
            registerDropdowns: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.SELECT_USERS: {
        let selectedUsers = state.selectedUsers.slice();
        if (action.payload.value) {
            selectedUsers = _.union(selectedUsers, action.payload.ids);
        } else {
            selectedUsers = _.difference(selectedUsers, action.payload.ids);
        }
        const newStateObject = Object.assign({}, {selectedUsers: selectedUsers});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CLEAR_USER_PROFILE: {
        const newStateObject = Object.assign({}, {
            singleUser: null,
            userActions: null
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FIND_USER_EMAIL_PASSWORD: {
        const newStateObject = Object.assign({}, { error: null, userdataprofile: null, validating: true, isValidEmail: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FIND_USER_EMAIL_PASSWORD_SUCCESS: {
        const newStateObject = Object.assign({}, { userdataprofile: action.payload, error: null, validating: false, isValidEmail: true });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.FIND_USER_EMAIL_PASSWORD_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: error, userdataprofile: null, validating: false});
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CLEAR_DATA: {
        const newStateObject = Object.assign({}, {error: null, userdataprofile: null, emailSent: false});
        newState = merge(state, newStateObject);
        break;
    }
    default:
        break;
    }
    return newState;
};
