export const CURRENCIES = {
    PESO: 'DOP',
    DOLLAR: 'USD'
};

export const USERS_PER_PAGE = 10;

export const CURRENCIES_IDS = {
    PESO: 1,
    DOLLAR: 2
};

export const GENDERS = {
    MALE: 1,
    FEMALE: 2
};

export const FINANCING_CANCELATION_REASON = {
    NO_INVENTORY: 1,
    OTHER: 2,
    BANK_TIMEOUT: 3,
    BANK_REQUIRE_ACCOUNT: 4,
    DECLINED_CLIENT: 5,
    CHOOSE_OTHER_PLACE: 6,
    COMPLICATED_PROCCESS: 7
};

export const FINANCING_ENTITY_TYPE = {
    BANK: 1,
    COOPERATIVE: 2,
    FINANCIAL: 3,
    CARDS: 4
};

export const FINANCING_DECLINING_REASON = {
    NO_HISTORY: 1,
    BAD_HISTORY: 2,
    LESS_INCOME: 3,
    BAD_FORM: 4,
    OTHER: 5
};

export const IDENTIFICATION_TYPES = {
    CEDULA: 1,
    PASSPORT: 2
};

export const USERTYPES = {
    CLIENT: 1,
    AGENT: 2,
    EMPLOYEE: 3,
    ADMIN: 4
};

export const FINANCING_STATUSES = {
    SENT: 1,
    WAITING_FOR_QUOTE: 2,
    WAITING_FOR_ENTITY: 3,
    WAITING_FOR_STORE: 4,
    APPROVED: 5,
    DECLINED: 6,
    CANCELED: 7,
    WAITING_FOR_APPROVAL: 8,
    WAITING_FOR_CLIENT: 9,
    APPROVED_BY_ENTITY: 10,
    SIGNED: 11,
    SOLD_PRODUCT: 12
};

export const DISPLAY_MODE = {
    CARD: 1,
    LIST: 2
};

let GENDERS_LABELS = {};

GENDERS_LABELS[GENDERS.MALE] = 'Masculino';
GENDERS_LABELS[GENDERS.FEMALE] = 'Femenino';

let FINANCING_DECLINING_REASON_LABELS = {};

FINANCING_DECLINING_REASON_LABELS[FINANCING_DECLINING_REASON.NO_HISTORY] = 'No tiene historial crediticio';
FINANCING_DECLINING_REASON_LABELS[FINANCING_DECLINING_REASON.BAD_HISTORY] = 'Mal historial crediticio';
FINANCING_DECLINING_REASON_LABELS[FINANCING_DECLINING_REASON.LESS_INCOME] = 'Monto excede ingresos';
FINANCING_DECLINING_REASON_LABELS[FINANCING_DECLINING_REASON.BAD_FORM] = 'Error en el formulario';
FINANCING_DECLINING_REASON_LABELS[FINANCING_DECLINING_REASON.OTHER] = 'Otra';

export {GENDERS_LABELS, FINANCING_DECLINING_REASON_LABELS};

export const OPTIONALFIELDS_TYPE = [
    {value:1, name: 'Texto'},
    {value:2, name: 'Número'},
    {value:3, name: 'Moneda'},
    {value:4, name: 'Teléfono'},
    {value:5, name: 'Fecha'},
    {value:6, name: 'Selección'}
];


export const BELONGS_OPTIONALFIELDS = [
    {value:1, name: 'Datos personales'},
    {value:2, name: 'Datos de la vivienda'},
    {value:3, name: 'Datos laborales'}
];

