import 'rxjs';
import 'rxjs/add/observable/of';
import { of } from 'rxjs/observable/of';
import { mergeMap, catchError, map, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userActionsLabels from 'common/store/actions/user/user.enum';
import * as userRequests from 'common/store/services/user.service';

export const authUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.AUTH_USER),
        mergeMap(action =>
            userRequests.authUserRequest(action.payload).pipe(
                map(result => userActions.authUserSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.authUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.authUserFailure(error.response)
                    );
                })
            )

        )
    );

export const validateEmailEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.VALIDATE_EMAIL),
        mergeMap(action =>
            userRequests.validateEmailRequest(action.payload).pipe(
                map(() => userActions.validateEmailSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.validateEmailFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.validateEmailFailure(error.response)
                    );
                })
            )

        )
    );

export const sendVerificationEmail = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.SEND_VERIFICATION_EMAIL),
        mergeMap(() =>
            userRequests.sendVerificationEmailRequest().pipe(
                map(() => userActions.sendVerificationEmailSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.sendVerificationEmailFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.sendVerificationEmailFailure(error.response)
                    );
                })
            )

        )
    );

export const logoutUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.LOGOUT_USER),
        mergeMap(() =>
            userRequests.logoutUserRequest().pipe(
                map(() => userActions.logoutUserSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.logoutUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.logoutUserFailure(error.response)
                    );
                })
            )
        )
    );

export const forgotPasswordEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.FORGOT_PASSWORD),
        mergeMap(action =>
            userRequests.forgotPasswordRequest(action.payload.email).pipe(
                map(() => userActions.forgotPasswordSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.forgotPasswordFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.forgotPasswordFailure(error.response)
                    );
                })
            )
        )
    );

export const userForgotPasswordEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.USER_FORGOT_PASSWORD),
        mergeMap(action =>
            userRequests.userForgotPasswordRequest(action.payload.id).pipe(
                map(() => userActions.userForgotPasswordSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.userForgotPasswordFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.userForgotPasswordFailure(error.response)
                    );
                })
            )
        )
    );

export const resetPasswordEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.RESET_PASSWORD),
        mergeMap(action =>
            userRequests.resetPasswordRequest(
                action.payload.password,
                action.payload.passwordConfirmation,
                action.payload.token,
                action.payload.id
            ).pipe(
                map(() => userActions.resetPasswordSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.resetPasswordFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.resetPasswordFailure(error.response)
                    );
                })
            )
        )
    );

export const createUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.CREATE_USER),
        mergeMap(action =>
            userRequests.createUserRequest(action.payload).pipe(
                map((result) => userActions.createUserSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.createUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.createUserFailure(error.response)
                    );
                })
            )
        )
    );

export const registerUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.REGISTER_USER),
        mergeMap(action =>
            userRequests.registerUserRequest(action.payload).pipe(
                map((result) => userActions.registerUserSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.registerUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.registerUserFailure(error.response)
                    );
                })
            )
        )
    );

export const updateUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.UPDATE_USER),
        mergeMap(action =>
            userRequests.updateUserRequest(action.payload.id, action.payload.data).pipe(
                map((result) => userActions.updateUserSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.updateUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.updateUserFailure(error.response)
                    );
                })
            )
        )
    );

export const addAddressEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.ADD_ADDRESS),
        mergeMap(action =>
            userRequests.addAddressRequest(action.payload).pipe(
                map((result) => userActions.addAddressSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.addAddressFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.addAddressFailure(error.response)
                    );
                })
            )
        )
    );

export const editFinancingProfileEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.EDIT_FINANCING_PROFILE),
        mergeMap(action =>
            userRequests.editFinancingProfileRequest(action.payload).pipe(
                map((result) => userActions.editFinancingProfileSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.editFinancingProfileFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.editFinancingProfileFailure(error.response)
                    );
                })
            )
        )
    );
    
export const createFinancingProfileEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.CREATE_FINANCING_PROFILE),
        mergeMap(action =>
            userRequests.createFinancingProfileRequest(action.payload).pipe(
                map((result) => userActions.createFinancingProfileSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.createFinancingProfileFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.createFinancingProfileFailure(error.response)
                    );
                })
            )
        )
    );

export const editAddressEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.EDIT_ADDRESS),
        mergeMap(action =>
            userRequests.editAddressRequest(action.payload.id, action.payload.data).pipe(
                map((result) => userActions.editAddressSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.editAddressFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.editAddressFailure(error.response)
                    );
                })
            )
        )
    );

export const activateUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.ACTIVATE_USER),
        mergeMap(action =>
            userRequests.activateUserRequest(action.payload.id, action.payload.active).pipe(
                map((result) => userActions.activateUserSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.activateUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.activateUserFailure(error.response)
                    );
                })
            )
        )
    );

export const deleteUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.DELETE_USER),
        mergeMap(action =>
            userRequests.deleteUserRequest(action.payload).pipe(
                map(() => userActions.deleteUserSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.deleteUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.deleteUserFailure(error.response)
                    );
                })
            )
        )
    );

export const bulkDeleteUsersEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.BULK_DELETE_USERS),
        mergeMap(action =>
            userRequests.bulkDeleteUsersRequest(action.payload).pipe(
                map(() => userActions.bulkDeleteUsersSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.bulkDeleteUsersFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.bulkDeleteUsersFailure(error.response)
                    );
                })
            )
        )
    );

export const bulkActivateUsersEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.BULK_ACTIVATE_USERS),
        mergeMap(action =>
            userRequests.bulkActivateUsersRequest(action.payload.ids, action.payload.active).pipe(
                map(() => userActions.bulkActivateUsersSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.bulkActivateUsersFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.bulkActivateUsersFailure(error.response)
                    );
                })
            )
        )
    );

export const getUserEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_USER),
        mergeMap(action =>
            userRequests.getUserRequest(action.payload).pipe(
                map((result) => userActions.getUserSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getUserFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getUserFailure(error.response)
                    );
                })
            )
        )
    );

export const getClientEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_CLIENT),
        mergeMap(action =>
            userRequests.getClientRequest(action.payload).pipe(
                map((result) => userActions.getClientSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getClientFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getClientFailure(error.response)
                    );
                })
            )
        )
    );

export const markAddressAsDefaultEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.MARK_ADDRESS_AS_DEFAULT),
        mergeMap(action =>
            userRequests.markAddressAsDefaultRequest(action.payload).pipe(
                map((result) => userActions.markAddressAsDefaultSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.markAddressAsDefaultFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.markAddressAsDefaultFailure(error.response)
                    );
                })
            )
        )
    );

export const deleteAddressEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.DELETE_ADDRESS),
        mergeMap(action =>
            userRequests.deleteAddressRequest(action.payload).pipe(
                map((result) => userActions.deleteAddressSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.deleteAddressFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.deleteAddressFailure(error.response)
                    );
                })
            )
        )
    );

export const getUserActionsEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_USER_ACTIONS),
        mergeMap(action =>
            userRequests.getUserActionsRequest(action.payload).pipe(
                map((result) => userActions.getUserActionsSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getUserActionsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getUserActionsFailure(error.response)
                    );
                })
            )
        )
    );

export const getUsersEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_USERS),
        mergeMap(action =>
            userRequests.getUsersRequest(action.payload).pipe(
                map((result) => userActions.getUsersSuccess(result)),
                takeUntil(action$.pipe(
                    ofType(userActionsLabels.CANCEL_GET_USERS)
                )),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getUsersFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getUsersFailure(error.response)
                    );
                })
            )
        )
    );

export const getUsersDropdownsEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_USERS_DROPDOWNS),
        mergeMap(() =>
            userRequests.getUsersDropdownsRequest().pipe(
                map((result) => userActions.getUsersDropdownsSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getUsersDropdownsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getUsersDropdownsFailure(error.response)
                    );
                })
            )
        )
    );

export const getRegisterUserDropdownsEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.GET_REGISTER_USER_DROPDOWNS),
        mergeMap(() =>
            userRequests.getRegisterUserDropdownsRequest().pipe(
                map((result) => userActions.getRegisterUserDropdownsSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.getRegisterUserDropdownsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.getRegisterUserDropdownsFailure(error.response)
                    );
                })
            )
        )
    );

    export const findByEmailandPassEpic = (action$) =>
    action$.pipe(
        ofType(userActionsLabels.FIND_USER_EMAIL_PASSWORD),
        mergeMap(action =>
            userRequests.validateUserByEmailRequest(action.payload.email, action.payload.password).pipe(
                map(result => userActions.findByEmailandPassSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            userActions.findByEmailandPassFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        userActions.findByEmailandPassFailure(error.response)
                    );
                })
            )

        )
    );