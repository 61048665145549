import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Steps, { Step } from 'rc-steps';

import { CIRCLE_ICON } from 'common/constants/icon.constant';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class UserActionsList extends React.PureComponent {

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        return (
            <div>
                {Object.entries(this.props.actions).map(group => {
                    return (
                        <React.Fragment key={group[0]}>
                            <Collapsible
                                trigger={
                                    <Steps direction="vertical" current={0}>
                                        <Step
                                            icon={<i className={CIRCLE_ICON} />}
                                            title={
                                                <p className="cursor-pointer mgB"><strong>{moment(group[0], 'YYYY-MM-DD').format('DD [de] MMMM[,] YYYY')}</strong> &#8226; {group[1].length} acciones realizadas</p>
                                            }>
                                        </Step>
                                    </Steps>
                                }>
                                <Steps style={{marginLeft: '2px'}} size="small" direction="vertical" current={-1}>
                                    {group[1].map(action => {
                                        if (action.goTo) {
                                            return (
                                                <Step
                                                    key={group[0] + '-' + action.id}
                                                    icon={<i className={'fs-1-rem ' + CIRCLE_ICON} />}
                                                    title={
                                                        <p
                                                            className="cursor-pointer">
                                                            <NavLink
                                                                dangerouslySetInnerHTML={{__html: action.action}}
                                                                to={action.goTo}>
                                                            </NavLink>
                                                        </p>
                                                    }>
                                                </Step>
                                            );
                                        } else {
                                            return (
                                                <Step
                                                    key={group[0] + '-' + action.id}
                                                    icon={<i className={'fs-1-rem ' + CIRCLE_ICON} />}
                                                    title={
                                                        <p
                                                            className="cursor-default"
                                                            key={group[0] + '-' + action.id}
                                                            dangerouslySetInnerHTML={{__html: action.action}}>
                                                        </p>
                                                    }>
                                                </Step>
                                            );
                                        }
                                    })}
                                </Steps>
                            </Collapsible>
                            <hr style={{marginTop: '0px', marginLeft: '26px'}} className="w-95"/>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

/**
 * Properties validation.
 */
UserActionsList.propTypes = {
    actions: PropTypes.object,
    history: PropTypes.object.isRequired
};
