import React from 'react';
import PropTypes from 'prop-types';

export default class EmptyState extends React.Component {

    render() {
        return (
            <div className={`empty-state ${this.props.small ? 'empty-state--small': 'layout-body__content'}`}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div style={this.props.imgStyle}>
                                    <img src={this.props.image} height={this.props.height} alt="Item image" className="empty-state__image" />
                                </div>
                                {this.props.title &&
                                    <h2 className="empty-state__headline">{this.props.title}</h2>
                                }
                                {this.props.text && typeof this.props.text === 'function' &&
                                    this.props.text()
                                }
                                {this.props.text && typeof this.props.text === 'string' &&
                                    <h3 className="empty-state__headline">{this.props.text}</h3>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EmptyState.propTypes = {
    imgStyle: PropTypes.object,
    image: PropTypes.string.isRequired,
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    small: PropTypes.bool
};
