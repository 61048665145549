import React from 'react';
import PropTypes from 'prop-types';



/**
* Represents a simple textarea.
*/
export default class Textarea extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props} the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = { labelFocused: false };

        this.onTextChange = this.onTextChange.bind(this);
        this.onFocus = this.changeFocus.bind(this, true);
        this.onBlur = this.changeFocus.bind(this, false);
    }

    /**
    * Fired once the input focus changes.
    *
    * @param {bool} labelFocused determines if the element is focused or not.
    */
    changeFocus(labelFocused) {
        this.setState({ labelFocused });
    }

    /**
    * Fired once the input textarea changes.
    *
    * @param {Proxy.dispatcher} event  contains the input's new value.
    */
    onTextChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {


        return (
            <React.Fragment>
                {this.props.labelWrapped ? (
                    <label className="form-label">
                        <span className={`label ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                            {this.props.icon && (
                                <span className="fl__icon fl__icon--left">
                                    <i className={this.props.icon} />
                                </span>
                            )}
                            {this.props.label}
                        </span>
                        <textarea
                            name={this.props.name}
                            id={this.props.id ? this.props.id : ""}
                            type="text"
                            className={this.props.className ? this.props.className : "form-control text-area"}
                            rows={this.props.rows ? this.props.rows : 3}
                            onFocus={this.onFocus}
                            tabIndex={this.props.tabIndex}
                            style={this.props.inputStyle}
                            onBlur={this.onBlur}
                            onChange={this.onTextChange}
                            value={this.props.value}
                            disabled={this.props.disabled}
                        ></textarea>
                    </label>) : (
                    <textarea
                        name={this.props.name}
                        id={this.props.id ? this.props.id : ""}
                        type="text"
                        className={this.props.className ? this.props.className : "form-control text-area"}
                        rows={this.props.rows ? this.props.rows : 3}
                        onFocus={this.onFocus}
                        tabIndex={this.props.tabIndex}
                        style={this.props.inputStyle}
                        onBlur={this.onBlur}
                        onChange={this.onTextChange}
                        value={this.props.value}
                        disabled={this.props.disabled}
                    ></textarea>
                )}
            </React.Fragment>
        );
    }
}

Textarea.defaultProps = { value: '', labelWrapped: true };

/**
 * Text input properties validation.
 */
Textarea.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    rows: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.any,
    labelWrapped: PropTypes.bool
};
