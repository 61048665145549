
import * as userActionsLabels from './user.enum';

export const cleardata = () => ({
    type: userActionsLabels.CLEAR_DATA
});

export const hideMenu = () => ({
    type: userActionsLabels.USER_HIDE_MENU
});

export const authUser = (email, password, rememberMe) => ({
    type: userActionsLabels.AUTH_USER,
    payload: {email: email, password: password, rememberMe: rememberMe}
});

export const authUserSuccess = (user) => ({ type: userActionsLabels.AUTH_USER_SUCCESS, payload: user });

export const authUserFailure = (error) => ({ type: userActionsLabels.AUTH_USER_FAILURE, payload: error });

export const logoutUser = () => ({type: userActionsLabels.LOGOUT_USER});

export const logoutUserSuccess = () => ({ type: userActionsLabels.LOGOUT_USER_SUCCESS });

export const logoutUserFailure = (error) => ({ type: userActionsLabels.LOGOUT_USER_FAILURE, payload: error });

export const forgotPassword = (email) => ({
    type: userActionsLabels.FORGOT_PASSWORD,
    payload: { email: email }
});
export const forgotPasswordSuccess = () => ({ type: userActionsLabels.FORGOT_PASSWORD_SUCCESS });

export const forgotPasswordFailure = (error) => ({ type: userActionsLabels.FORGOT_PASSWORD_FAILURE, payload: error });


export const resetPassword = (password, passwordConfirmation, token, id = null) => ({
    type: userActionsLabels.RESET_PASSWORD,
    payload: {
        password: password,
        passwordConfirmation: passwordConfirmation,
        token: token,
        id: id
    }
});
export const resetPasswordSuccess = () => ({ type: userActionsLabels.RESET_PASSWORD_SUCCESS });

export const resetPasswordFailure = (error) => ({ type: userActionsLabels.RESET_PASSWORD_FAILURE, payload: error });

export const resetUserMessages = () => ({type: userActionsLabels.RESET_USER_MESSAGES});

export const createUser = (data) => ({
    type: userActionsLabels.CREATE_USER,
    payload: data
});

export const createUserSuccess = (user) => ({type: userActionsLabels.CREATE_USER_SUCCESS, payload: user});

export const createUserFailure = (error) => ({
    type: userActionsLabels.CREATE_USER_FAILURE,
    payload: error
});

export const registerUser = (data) => ({
    type: userActionsLabels.REGISTER_USER,
    payload: data
});

export const registerUserSuccess = (user) => ({type: userActionsLabels.REGISTER_USER_SUCCESS, payload: user});

export const registerUserFailure = (error) => ({
    type: userActionsLabels.REGISTER_USER_FAILURE,
    payload: error
});

export const updateUser = (id, data) => ({
    type: userActionsLabels.UPDATE_USER,
    payload: {id, data}
});

export const updateUserSuccess = (user) => ({type: userActionsLabels.UPDATE_USER_SUCCESS, payload: user});

export const updateUserFailure = (error) => ({
    type: userActionsLabels.UPDATE_USER_FAILURE,
    payload: error
});

export const addAddress = (data) => ({
    type: userActionsLabels.ADD_ADDRESS,
    payload: data
});

export const addAddressSuccess = (user) => ({type: userActionsLabels.ADD_ADDRESS_SUCCESS, payload: user});

export const addAddressFailure = (error) => ({
    type: userActionsLabels.ADD_ADDRESS_FAILURE,
    payload: error
});

export const editFinancingProfile = (data) => ({
    type: userActionsLabels.EDIT_FINANCING_PROFILE,
    payload: data
});

export const editFinancingProfileSuccess = (user) => ({type: userActionsLabels.EDIT_FINANCING_PROFILE_SUCCESS, payload: user});

export const editFinancingProfileFailure = (error) => ({
    type: userActionsLabels.EDIT_FINANCING_PROFILE_FAILURE,
    payload: error
});

export const createFinancingProfile = (data) => ({
    type: userActionsLabels.CREATE_FINANCING_PROFILE,
    payload: data
});

export const createFinancingProfileSuccess = (user) => ({type: userActionsLabels.CREATE_FINANCING_PROFILE_SUCCESS, payload: user});

export const createFinancingProfileFailure = (error) => ({
    type: userActionsLabels.CREATE_FINANCING_PROFILE_FAILURE,
    payload: error
});

export const editAddress = (id, data) => ({
    type: userActionsLabels.EDIT_ADDRESS,
    payload: {id, data}
});

export const editAddressSuccess = (user) => ({type: userActionsLabels.EDIT_ADDRESS_SUCCESS, payload: user});

export const editAddressFailure = (error) => ({
    type: userActionsLabels.EDIT_ADDRESS_FAILURE,
    payload: error
});

export const getUser = (id) => ({
    type: userActionsLabels.GET_USER,
    payload: id
});

export const getUserSuccess = (user) => ({type: userActionsLabels.GET_USER_SUCCESS, payload: user});

export const getUserFailure = (error) => ({
    type: userActionsLabels.GET_USER_FAILURE,
    payload: error
});

export const getClient = (id) => ({
    type: userActionsLabels.GET_CLIENT,
    payload: id
});

export const getClientSuccess = (user) => ({type: userActionsLabels.GET_CLIENT_SUCCESS, payload: user});

export const getClientFailure = (error) => ({
    type: userActionsLabels.GET_CLIENT_FAILURE,
    payload: error
});

export const getUsers = (filters) => ({
    type: userActionsLabels.GET_USERS,
    payload: filters
});

export const getUsersSuccess = (users) => ({type: userActionsLabels.GET_USERS_SUCCESS, payload: users});

export const getUsersFailure = (error) => ({
    type: userActionsLabels.GET_USERS_FAILURE,
    payload: error
});

export const deleteUser = (id) => ({
    type: userActionsLabels.DELETE_USER,
    payload: id
});

export const deleteUserSuccess = () => ({type: userActionsLabels.DELETE_USER_SUCCESS});

export const deleteUserFailure = (error) => ({
    type: userActionsLabels.DELETE_USER_FAILURE,
    payload: error
});

export const setFilters = (filters) => ({
    type: userActionsLabels.SET_USERS_FILTERS,
    payload: filters
});

export const cancelGetUsers = () => ({type: userActionsLabels.CANCEL_GET_USERS});

export const getUsersDropdowns = () => ({type: userActionsLabels.GET_USERS_DROPDOWNS});

export const getUsersDropdownsSuccess = (dropdowns) => ({
    type: userActionsLabels.GET_USERS_DROPDOWNS_SUCCESS,
    payload: dropdowns
});

export const getUsersDropdownsFailure = (error) => ({
    type: userActionsLabels.GET_USERS_DROPDOWNS_FAILURE,
    payload: error
});

export const getRegisterUserDropdowns = () => ({type: userActionsLabels.GET_REGISTER_USER_DROPDOWNS});

export const getRegisterUserDropdownsSuccess = (dropdowns) => ({
    type: userActionsLabels.GET_REGISTER_USER_DROPDOWNS_SUCCESS,
    payload: dropdowns
});

export const getRegisterUserDropdownsFailure = (error) => ({
    type: userActionsLabels.GET_REGISTER_USER_DROPDOWNS_FAILURE,
    payload: error
});

export const selectUsers = (value, ids) => ({
    type: userActionsLabels.SELECT_USERS,
    payload: {
        value: value,
        ids: ids
    }
});

export const clearUserProfile = () => ({type: userActionsLabels.CLEAR_USER_PROFILE});

export const bulkDeleteUsers = (ids) => ({
    type: userActionsLabels.BULK_DELETE_USERS,
    payload: ids
});

export const bulkDeleteUsersSuccess = () => ({type: userActionsLabels.BULK_DELETE_USERS_SUCCESS});

export const bulkDeleteUsersFailure = (error) => ({
    type: userActionsLabels.BULK_DELETE_USERS_FAILURE,
    payload: error
});

export const activateUser = (id, value) => ({
    type: userActionsLabels.ACTIVATE_USER,
    payload: {id: id, active: value}
});

export const activateUserSuccess = () => ({type: userActionsLabels.ACTIVATE_USER_SUCCESS});

export const activateUserFailure = (error) => ({
    type: userActionsLabels.ACTIVATE_USER_FAILURE,
    payload: error
});

export const bulkActivateUsers = (ids, value) => ({
    type: userActionsLabels.BULK_ACTIVATE_USERS,
    payload: {ids: ids, active: value}
});

export const bulkActivateUsersSuccess = () => ({type: userActionsLabels.BULK_ACTIVATE_USERS_SUCCESS});

export const bulkActivateUsersFailure = (error) => ({
    type: userActionsLabels.BULK_ACTIVATE_USERS_FAILURE,
    payload: error
});

export const getUserActions = (id) => ({type: userActionsLabels.GET_USER_ACTIONS, payload: id});

export const getUserActionsSuccess = (actions) => ({
    type: userActionsLabels.GET_USER_ACTIONS_SUCCESS,
    payload: actions
});

export const getUserActionsFailure = (error) => ({
    type: userActionsLabels.GET_USER_ACTIONS_FAILURE,
    payload: error
});

export const markAddressAsDefault = (id, userid ) => ({type: userActionsLabels.MARK_ADDRESS_AS_DEFAULT, payload: {id: id, userid: userid} });

export const markAddressAsDefaultSuccess = (user) => ({
    type: userActionsLabels.MARK_ADDRESS_AS_DEFAULT_SUCCESS,
    payload: user
});

export const markAddressAsDefaultFailure = (error) => ({
    type: userActionsLabels.MARK_ADDRESS_AS_DEFAULT_FAILURE,
    payload: error
});

export const deleteAddress = (id, userid) => ({type: userActionsLabels.DELETE_ADDRESS, payload: {id: id, userid: userid}});

export const deleteAddressSuccess = (user) => ({
    type: userActionsLabels.DELETE_ADDRESS_SUCCESS,
    payload: user
});

export const deleteAddressFailure = (error) => ({
    type: userActionsLabels.DELETE_ADDRESS_FAILURE,
    payload: error
});

export const userForgotPassword = (id) => ({
    type: userActionsLabels.USER_FORGOT_PASSWORD,
    payload: { id: id }
});

export const userForgotPasswordSuccess = () => ({ type: userActionsLabels.USER_FORGOT_PASSWORD_SUCCESS });

export const userForgotPasswordFailure = (error) => ({ type: userActionsLabels.USER_FORGOT_PASSWORD_FAILURE, payload: error });

export const validateEmail = (email) => ({
    type: userActionsLabels.VALIDATE_EMAIL,
    payload: email
});

export const validateEmailSuccess = () => ({type: userActionsLabels.VALIDATE_EMAIL_SUCCESS});

export const validateEmailFailure = (error) => ({
    type: userActionsLabels.VALIDATE_EMAIL_FAILURE,
    payload: error
});

export const sendVerificationEmail = () => ({type: userActionsLabels.SEND_VERIFICATION_EMAIL});

export const sendVerificationEmailSuccess = () => ({type: userActionsLabels.SEND_VERIFICATION_EMAIL_SUCCESS});

export const sendVerificationEmailFailure = (error) => ({
    type: userActionsLabels.SEND_VERIFICATION_EMAIL_FAILURE,
    payload: error
});


export const findByEmailandPass = (email, password) => ({
    type: userActionsLabels.FIND_USER_EMAIL_PASSWORD,
    payload: {email: email, password: password}
});

export const findByEmailandPassSuccess = (user) => ({
    type: userActionsLabels.FIND_USER_EMAIL_PASSWORD_SUCCESS, 
    payload: user
});

export const findByEmailandPassFailure = (error) => ({
    type: userActionsLabels.FIND_USER_EMAIL_PASSWORD_FAILURE,
    payload: error
});