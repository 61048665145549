'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import {
    SearchInput,
    SelectInput
} from 'common/components/inputs';
import Modal from 'common/components/modal';
import {
    PLUS_CIRCLE_ICON,
    DELETE_ICON,
    CANCEL_ICON,
    CHECK_ICON
} from 'common/constants/icon.constant';
import EmptyState from 'common/components/empty-state';

import CreateUserForm from '../create-user-form';
import { USERTYPES } from 'common/constants/enums.constant';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            dropdowns: userSelectors.getDropdowns(state),
            userCreated: userSelectors.getUserCreated(state),
            userDeleted: userSelectors.getUserDeleted(state),
            userUpdated: userSelectors.getUserUpdated(state),
            simpleError: userSelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        },
        createUser: (params) => {
            dispatch(userActions.createUser(params));
        },
        bulkDeleteUsers: (ids) => {
            dispatch(userActions.bulkDeleteUsers(ids));
        },
        bulkActivateUsers: (ids, value) => {
            dispatch(userActions.bulkActivateUsers(ids, value));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
};

class UsersListHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showDeleteModal: false,
            showActivateModal: false,
            showDeactivateModal: false
        };
    }

    componentDidUpdate() {
        if (this.props.userCreated !== null) {
            this.props.resetUserMessages();
            location.replace('users/' + this.props.userCreated.id);
        }

        if (this.props.userDeleted) {
            this.props.resetUserMessages();
            location.reload();
        }

        if (this.props.userUpdated) {
            this.props.resetUserMessages();
            location.reload();
        }
    }

    componentDidMount() {
        if (this.props.dropdowns.stores.length === 0) {
            this.props.getDropdowns();
        }
    }

    renderCreateUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showModal: false})}
                id='create-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/add_user.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Crear usuario</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <CreateUserForm
                    error={this.props.simpleError}
                    dropdowns={this.props.dropdowns}
                    authUser={this.props.authUser}
                    onCancel={() => this.setState({showModal: false})}
                    onSubmit={this.onCreateUser}
                />
            </Modal>
        );
    }

    renderDeactivateUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeactivateModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showDeactivateModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/deactivate_user.svg"
                        title=""
                        text=""
                    />
                    <h3 className="text-center">Suspender usuarios</h3>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea suspender a los usuarios seleccionados?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Los usuarios no podrán ingresar nuevamente a la plataforma.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.bulkActivateUsers(this.props.selectedUsers, false)}
                            className="btn btn-outline col-md-4">
                            Suspender
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeactivateModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderActivateUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showActivateModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showActivateModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/activate_user.svg"
                        title=""
                        text=""
                    />
                    <h3 className="text-center">Reactivar usuarios</h3>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea reactivar a los usuarios seleccionados?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Los usuarios podrán ingresar nuevamente a la plataforma.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.bulkActivateUsers(this.props.selectedUsers, true)}
                            className="btn btn-outline col-md-4">
                            Reactivar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showActivateModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderDeleteUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/delete_user.svg"
                        title=""
                        text=""
                    />
                    <h3 className="text-center">Eliminar usuarios</h3>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea eliminar los usuarios seleccionados?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Serán eliminados del listado y no podrán acceder a la plataforma.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.bulkDeleteUsers(this.props.selectedUsers)}
                            className="btn btn-outline col-md-4">
                            Eliminar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeleteModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    onCreateUser = (params) => {
        let newParams = {...params};

        newParams['phone'] = newParams['phone'].replace(/[^0-9]/g, '');
        newParams['mobile'] = newParams['mobile'].replace(/[^0-9]/g, '');
        newParams['extension'] = newParams['extension'].replace(/[^0-9]/g, '');

        if (this.props.authUser.userType.id === USERTYPES.ADMIN) {
            if (newParams['userType'] === USERTYPES.EMPLOYEE) {
                newParams['financingEntity'] = null;
            }
            if (newParams['userType'] === USERTYPES.AGENT) {
                newParams['store'] = null;
            }
        } else {
            if (this.props.authUser.userType.id === USERTYPES.EMPLOYEE) {
                newParams['userType'] = USERTYPES.EMPLOYEE;
                newParams['store'] = this.props.authUser.entity.id;
                newParams['financingEntity'] = null;
            }
            if (this.props.authUser.userType.id === USERTYPES.AGENT) {
                newParams['userType'] = USERTYPES.AGENT;
                newParams['financingEntity'] = this.props.authUser.entity.id;
                newParams['store'] = null;
            }
        }
        this.props.createUser(newParams);
    };

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB">
                <div className="col-6">
                    <h2 className="section-headline">Usuarios ({this.props.total})</h2>
                </div>
                <div
                    className="col-6 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({showModal: true})}>
                        <i className={PLUS_CIRCLE_ICON} />
                        Crear usuario
                    </button>
                </div>
            </div>
        );
    }

    renderAdminFilters() {
        return (
            <div className="row mgB">
                <div className="col-md-3 mgB">
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        value={this.props.filters.q}
                        label="Buscar nombre o email"
                        onChange={(val) => this.props.onFiltersChange(['q', 'page'], [val, 1])}
                    />
                </div>
                <div className="col-md-3 mgB">
                    <SelectInput
                        value={this.props.filters.financingEntity}
                        floatingLabel="Entidad Financiera"
                        withAllOption="Todas"
                        options={this.props.dropdowns.financingEntities}
                        onChange={(val) => this.props.onFiltersChange('financingEntity', val)}
                    />
                </div>
                <div className="col-md-2 mgB">
                    <SelectInput
                        value={this.props.filters.store}
                        floatingLabel="Tienda"
                        withAllOption="Todas"
                        options={this.props.dropdowns.stores}
                        onChange={(val) => this.props.onFiltersChange('store', val)}
                    />
                </div>
                <div className="col-md-2 mgB">
                    <SelectInput
                        value={this.props.filters.userType}
                        floatingLabel = "Tipo"
                        withAllOption="Todos"
                        options={this.props.dropdowns.userTypes}
                        onChange={(val) => this.props.onFiltersChange('userType', val)}
                    />
                </div>
                <div className="col-md-2">
                    <SelectInput
                        value={this.props.filters.active}
                        floatingLabel = "Estado"
                        withAllOption="Todos"
                        options={[
                            {value: 1, label: 'Activo'},
                            {value: 0, label: 'Inactivo'}
                        ]}
                        onChange={(val) => this.props.onFiltersChange('active', val)}
                    />
                </div>

            </div>
        );
    }

    renderAgentAndEmployeeFilters() {
        return (
            <div className="row mgB">
                <div className="col-md-4 mgB">
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        value={this.props.filters.q}
                        label="Buscar nombre o email"
                        onChange={(val) => this.props.onFiltersChange(['q', 'page'], [val, 1])}
                    />
                </div>
                <div className="col-md-4">
                    <SelectInput
                        value={this.props.filters.active}
                        floatingLabel="Estado"
                        withAllOption="Todos"
                        options={[
                            {value: 1, label: 'Activo'},
                            {value: 0, label: 'Inactivo'}
                        ]}
                        onChange={(val) => this.props.onFiltersChange('active', val)}
                    />
                </div>
                <div className="col-md-4"/>
            </div>
        );
    }

    renderFilters() {
        return (
            <div>
                {this.props.authUser.userType.id === USERTYPES.ADMIN && this.renderAdminFilters()}
                {this.props.authUser.userType.id !== USERTYPES.ADMIN && this.renderAgentAndEmployeeFilters()}
            </div>
        );
    }

    renderSelected() {
        return (
            <div className="row mgT4 mgB no-gutters">
                <div className="border shadow-sm text-center col-md-2 m-auto">
                    <h5 className="mgB mgT">Seleccionados: {this.props.selectedUsers.length}</h5>
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    {this.props.authUser.userType.id !== USERTYPES.AGENT &&
                        <button
                            disabled={this.props.selectedUsers.length === 0}
                            onClick={() => this.setState({showDeactivateModal: true})}
                            className="btn btn-group btn-transparent">
                            <i className={CANCEL_ICON} />
                            Suspender
                        </button>
                    }
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    {this.props.authUser.userType.id !== USERTYPES.AGENT &&
                        <button
                            disabled={this.props.selectedUsers.length === 0}
                            onClick={() => this.setState({showActivateModal: true})}
                            className="btn btn-group btn-transparent">
                            <i className={CHECK_ICON} />
                            Reactivar
                        </button>
                    }
                </div>
                <div className="border shadow-sm text-center col-md-2">
                    {this.props.authUser.userType.id !== USERTYPES.AGENT &&
                        <button
                            disabled={this.props.selectedUsers.length === 0}
                            onClick={() => this.setState({showDeleteModal: true})}
                            className="btn btn-group btn-transparent">
                            <i className={DELETE_ICON} />
                            Eliminar
                        </button>
                    }
                </div>
                <div className="col-md-4" />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
                <hr style={{left: '0px', top: '180px'}} className="w-100 position-absolute"/>
                {this.renderSelected()}
                {this.renderCreateUserModal()}
                {this.renderDeleteUserModal()}
                {this.renderDeactivateUserModal()}
                {this.renderActivateUserModal()}
            </div>
        );
    }
}

/**
 * Properties validations.
 */
UsersListHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    filters: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    onFiltersChange: PropTypes.func.isRequired
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(UsersListHeader);
