import React from 'react';
import PropTypes from 'prop-types';
import ReactMaskedInput from 'react-maskedinput';

/**
* Represents the default maskedInput class.
*/
export default class MaskedInput extends React.Component {

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <ReactMaskedInput
                disabled={this.props.disabled}
                className={this.props.className + ' white-background'}
                mask={this.props.mask}
                tabIndex={this.props.tabIndex}
                name={this.props.name}
                placeholder={this.props.placeholder ? this.props.placeholder : ''}
                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                id={this.props.id}
            />
        );
    }
}

/**
 * Masked input default properties.
 */
MaskedInput.defaultProperties = {
    className: '',
    name: '',
    placeholder: '',
    value: 'NA',
    disabled: false
};

/**
 * Masked input properties validation.
 */
MaskedInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    mask: PropTypes.string.isRequired,

    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func
};
