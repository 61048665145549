'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitySelectors from 'common/store/selectors/financing-entity.selector';

import {
    SearchInput,
    SelectInput
} from 'common/components/inputs';
import Modal from 'common/components/modal';

import {FINANCING_ENTITY_TYPE} from 'common/constants/enums.constant';
import { PLUS_CIRCLE_ICON } from 'common/constants/icon.constant';
import EmptyState from 'common/components/empty-state';

import CreateFinancingEntityForm from '../create-financing-entity';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            financingEntityTypes: transactionSelectors.getAllFinancingEntityTypes(state),
            financingEntityCreated: financingEntitySelectors.getFinancingEntityCreated(state),
            simpleError: financingEntitySelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllFinancingEntityTypes: () => {
            dispatch(transactionActions.getAllFinancingEntityTypes(true));
        },
        createFinancingEntity: (params) => {
            dispatch(financingEntityActions.createFinancingEntity(params));
        },
        resetFinancingEntityMessages: () => {
            dispatch(financingEntityActions.resetFinancingEntityMessages());
        }

    };
};

class FinancingEntityListHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {showModal: false};
    }

    componentDidUpdate() {
        if (this.props.financingEntityCreated !== null) {
            this.props.resetFinancingEntityMessages();
            location.replace('financing-entities/' + this.props.financingEntityCreated.id);
        }
    }

    componentWillUnmount() {
        this.props.resetFinancingEntityMessages();
    }

    renderCreateEntityModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/add_entity.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Crear entidad</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <CreateFinancingEntityForm
                    error={this.props.simpleError}
                    financingEntityTypes={this.props.financingEntityTypes}
                    authUser={this.props.authUser}
                    onCancel={() => this.setState({showModal: false})}
                    onSubmit={this.onCreateEntity}
                />
            </Modal>
        );
    }

    onCreateEntity = (params) => {
        let newParams = {...params};
        this.props.createFinancingEntity(newParams);
    };

    componentDidMount() {
        if (this.props.financingEntityTypes.length === 0) {
            this.props.getAllFinancingEntityTypes();
        }
    }

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB">
                <div className="col-6">
                    <h2 className="section-headline">Entidades financieras ({this.props.total})</h2>
                </div>
                <div
                    className="col-6 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({showModal: true})}>
                        <i className={PLUS_CIRCLE_ICON} />
                        Crear entidad
                    </button>
                </div>
            </div>
        );
    }

    renderFilters() {
        return (
            <div className="row">
                <div className={'col-md-6 mgB'}>
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        value={this.props.filters.q}
                        label="Nombre de la entidad financiera"
                        onChange={(val) => this.props.onFiltersChange('q', val)}
                    />
                </div>
                <div className={'col-md-3 mgB'}>
                    <SelectInput
                        value={this.props.filters.type.length === 1 ? this.props.filters.type[0] : null}
                        floatingLabel="Tipo"
                        withAllOption="Todos"
                        options={this.props.financingEntityTypes.map(el => {
                            return {value: el.id, label: el.name};
                        })}
                        onChange={(val) => this.props.onFiltersChange('type', val !== null ? [val] : [FINANCING_ENTITY_TYPE.BANK, FINANCING_ENTITY_TYPE.COOPERATIVE, FINANCING_ENTITY_TYPE.FINANCIAL, FINANCING_ENTITY_TYPE.CARDS ])}
                    />
                </div>
                <div className="col-md-3 mgB">
                    <SelectInput
                        value={this.props.filters.enabled}
                        floatingLabel="Estado"
                        withAllOption="Todos"
                        options={[
                            {value: 1, label: 'Habilitada'},
                            {value: 0, label: 'Deshabilitada'}
                        ]}
                        onChange={(val) => this.props.onFiltersChange('enabled', val)}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
                <hr style={{left: '0px', top: '180px'}} className="w-100 position-absolute"/>
                {this.renderCreateEntityModal()}
            </div>
        );
    }
}

/**
 * Properties validations.
 */
FinancingEntityListHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    filters: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    onFiltersChange: PropTypes.func.isRequired
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingEntityListHeader);
