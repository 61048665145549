export default function (obj) {
    let str = [];
    let newObj = {... obj};
    for (let p in newObj) {
        if (Object.prototype.hasOwnProperty.call(newObj, p) && newObj[p] !== undefined && newObj[p] !== null && newObj[p] !== '' && newObj[p].length !== 0) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(newObj[p]));
        }
    }
    return str.join("&");
}
