'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    TextInput,
    MoneyInput
} from 'common/components/inputs';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

import DataTable from "common/components/datatable";

import {
    USERS_PER_PAGE,
    CURRENCIES_IDS
} from 'common/constants/enums.constant';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class PlansMatrix extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                name: props.creating ? '' : props.planData.name,
                payments: props.creating ? '' : props.planData.payments,
                charge: props.creating ? '' : props.planData.charge,
                currency: CURRENCIES_IDS.PESO,
                monthlyFixed: props.creating ? '' : props.planData.monthlyFixed,
                monthlyFixedAmount: props.creating ? '0' : props.planData.monthlyFixedAmount.amount,
                firstPayment: props.creating ? '' : props.planData.firstPayment,
                minAdvancePayment: props.creating ? '' : props.planData.minAdvancePayment,
                firstPaymentAmount: props.creating ? '0' : props.planData.firstPaymentAmount.amount,
                minimum: props.creating ? '0' : props.planData.minimum.amount,
                maximum: props.creating ? '0' : props.planData.maximum.amount,
                enabled: props.creating ? true : props.planData.enabled,
                showIfDiscount: props.creating ? false : props.planData.showIfDiscount,
                showNotDiscount: props.creating ? false : props.planData.showNotDiscount
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                payments: { errorClass: null, dangerLabel: false },
                charge: { errorClass: null, dangerLabel: false },
                minAdvancePayment: { errorClass: null, dangerLabel: false },
                monthlyFixed: { errorClass: null, dangerLabel: false },
                monthlyFixedAmount: { errorClass: null, dangerLabel: false },
                firstPayment: { errorClass: null, dangerLabel: false },
                firstPaymentAmount: { errorClass: null, dangerLabel: false },
                minimum: { errorClass: null, dangerLabel: false },
                maximum: { errorClass: null, dangerLabel: false },
                enabled: { errorClass: null, dangerLabel: false },
                showIfDiscount: { errorClass: null, dangerLabel: false },
                showNotDiscount: { errorClass: null, dangerLabel: false }
            },
            canSubmit: false
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    componentDidUpdate() {
        if (this.props.plansAdded) {
            this.clearFields();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    onFieldChange = (field, value) => {
        let data = {...this.state.data};
        data[field] = value;
        this.setState({data: data});
    };

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    /**
     * Renders each cell in the table.
     *
     * @param  {object} cell        the corresponding cell's object.
     * @return {ReactComponent}    our custom-made cell component.
     */
    matchFeaturedRow = (state, rowInfo) => {
        if (!rowInfo) {
            return {};
        }
        const plan = rowInfo.original;
        plan;
        return {
            onClick: () => {
                // this.props.onPlansSelected(val, [parseInt(plan.id)])
            }
        };
    };

    renderInput = (field) => {
        switch (field) {
        case 'name':
            return (
                <FormsyInputWrapper
                    required
                    name="name"
                    errorClassName={this.state.errorFields.name.errorClass}
                    value={this.state.data.name}>
                    <TextInput
                        dangerLabel={this.state.errorFields.name.dangerLabel}
                        value={this.state.data.name}
                        onChange={(val) => this.onFieldChange('name', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'payments':
            return (
                <FormsyInputWrapper
                    required
                    name="payments"
                    errorClassName={this.state.errorFields.payments.errorClass}
                    value={this.state.data.payments}>
                    <TextInput
                        numbers={true}
                        onlyNumbers={true}
                        range={true}
                        dangerLabel={this.state.errorFields.payments.dangerLabel}
                        value={this.state.data.payments}
                        onChange={(val) => this.onFieldChange('payments', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'charge':
            return (
                <FormsyInputWrapper
                    required
                    name="charge"
                    errorClassName={this.state.errorFields.charge.errorClass}
                    value={this.state.data.charge}>
                    <TextInput
                        numbers={true}
                        onlyNumbers={true}
                        maxValue={1}
                        placeholder='0.1'
                        minValue={0}
                        dangerLabel={this.state.errorFields.charge.dangerLabel}
                        value={this.state.data.charge}
                        onChange={(val) => this.onFieldChange('charge', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'monthlyFixed':
            return (
                <FormsyInputWrapper
                    required
                    name="monthlyFixed"
                    errorClassName={this.state.errorFields.monthlyFixed.errorClass}
                    value={this.state.data.monthlyFixed}>
                    <TextInput
                        numbers={true}
                        onlyNumbers={true}
                        placeholder='0.1'
                        maxValue={1}
                        minValue={0}
                        dangerLabel={this.state.errorFields.monthlyFixed.dangerLabel}
                        value={this.state.data.monthlyFixed}
                        onChange={(val) => this.onFieldChange('monthlyFixed', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'monthlyFixedAmount':
            return (
                <FormsyInputWrapper
                    required
                    name="monthlyFixedAmount"
                    errorClassName={this.state.errorFields.monthlyFixedAmount.errorClass}
                    value={this.state.data.monthlyFixedAmount}>
                    <MoneyInput
                        placeholder="RD$"
                        dangerLabel={this.state.errorFields.monthlyFixedAmount.dangerLabel}
                        value={this.state.data.monthlyFixedAmount}
                        onChange={(val) => this.onFieldChange('monthlyFixedAmount', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'firstPayment':
            return (
                <FormsyInputWrapper
                    required
                    name="firstPayment"
                    errorClassName={this.state.errorFields.firstPayment.errorClass}
                    value={this.state.data.firstPayment}>
                    <TextInput
                        numbers={true}
                        onlyNumbers={true}
                        maxValue={1}
                        placeholder='0.1'
                        minValue={0}
                        dangerLabel={this.state.errorFields.firstPayment.dangerLabel}
                        value={this.state.data.firstPayment}
                        onChange={(val) => this.onFieldChange('firstPayment', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'minAdvancePayment':
            return (
                <FormsyInputWrapper
                    required
                    name="minAdvancePayment"
                    errorClassName={this.state.errorFields.minAdvancePayment.errorClass}
                    value={this.state.data.minAdvancePayment}>
                    <TextInput
                        numbers={true}
                        onlyNumbers={true}
                        maxValue={1}
                        placeholder='0.1'
                        minValue={0}
                        dangerLabel={this.state.errorFields.minAdvancePayment.dangerLabel}
                        value={this.state.data.minAdvancePayment}
                        onChange={(val) => this.onFieldChange('minAdvancePayment', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'firstPaymentAmount':
            return (
                <FormsyInputWrapper
                    required
                    name="firstPaymentAmount"
                    errorClassName={this.state.errorFields.firstPaymentAmount.errorClass}
                    value={this.state.data.firstPaymentAmount}>
                    <MoneyInput
                        placeholder="RD$"
                        dangerLabel={this.state.errorFields.firstPaymentAmount.dangerLabel}
                        value={this.state.data.firstPaymentAmount}
                        onChange={(val) => this.onFieldChange('firstPaymentAmount', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'minimum':
            return (
                <FormsyInputWrapper
                    required
                    name="minimum"
                    errorClassName={this.state.errorFields.minimum.errorClass}
                    value={this.state.data.minimum}>
                    <MoneyInput
                        placeholder="RD$"
                        dangerLabel={this.state.errorFields.minimum.dangerLabel}
                        value={this.state.data.minimum}
                        onChange={(val) => this.onFieldChange('minimum', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'maximum':
            return (
                <FormsyInputWrapper
                    required
                    name="maximum"
                    errorClassName={this.state.errorFields.maximum.errorClass}
                    value={this.state.data.maximum}>
                    <MoneyInput
                        placeholder="RD$"
                        dangerLabel={this.state.errorFields.maximum.dangerLabel}
                        value={this.state.data.maximum}
                        onChange={(val) => this.onFieldChange('maximum', val)}
                    />
                </FormsyInputWrapper>
            );
        case 'enabled':
            return (
                <FormsyInputWrapper
                    required
                    name="enabled"
                    validations="isTrue"
                    errorClassName={this.state.errorFields.enabled.errorClass}
                    value={this.state.data.enabled !== null}>
                    <Toggle
                        className="md-switch"
                        icons={false}
                        checked={this.state.data.enabled}
                        onChange={() => this.onFieldChange('enabled', !this.state.data.enabled)} />
                </FormsyInputWrapper>
            );
        case 'showIfDiscount':
            return (
                <FormsyInputWrapper
                    required
                    name="showIfDiscount"
                    validations="isTrue"
                    errorClassName={this.state.errorFields.showIfDiscount.errorClass}
                    value={this.state.data.showIfDiscount !== null}>
                    <Toggle
                        className="md-switch"
                        icons={false}
                        checked={this.state.data.showIfDiscount}
                        onChange={() => this.onFieldChange('showIfDiscount', !this.state.data.showIfDiscount)} />
                </FormsyInputWrapper>
            );
            case 'showNotDiscount':
            return (
                <FormsyInputWrapper
                    required
                    name="showNotDiscount"
                    validations="isTrue"
                    errorClassName={this.state.errorFields.showNotDiscount.errorClass}
                    value={this.state.data.showNotDiscount !== null}>
                    <Toggle
                        className="md-switch"
                        icons={false}
                        checked={this.state.data.showNotDiscount}
                        onChange={() => this.onFieldChange('showNotDiscount', !this.state.data.showNotDiscount)} />
                </FormsyInputWrapper>
            );
        default:
            break;
        }
        return null;

    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.data});
    };

    clearFields = () => {
        this.setState({
            data: {
                name: '',
                payments: '',
                charge: '',
                currency: CURRENCIES_IDS.PESO,
                monthlyFixed: '',
                monthlyFixedAmount: '0',
                firstPayment: '',
                firstPaymentAmount: '0',
                minimum: '0',
                maximum: '0',
                enabled: false,
                showIfDiscount: false
            }
        });
        setTimeout(() => {
            this.basicDataRef.current.reset();
        }, 50);
    };

    validSubmit = () => {
        let isCorrectRange = true;
        let range = this.state.data.payments.split('-');
        if (parseInt(range[0]) >= parseInt(range[1])) {
            isCorrectRange = false;
        }
        return this.state.canSubmit && isCorrectRange;
    };


    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        let data = [0];
        const columns = [
            {
                Header: this.renderHeader('Nombre del plan'),
                accessor: 'name',
                Cell: this.renderInput('name')
                // width: 110
            },
            {
                Header: this.renderHeader('Rango de cuotas'),
                accessor: 'payments',
                Cell: this.renderInput('payments')
                // width: 110
            },
            {
                Header: this.renderHeader('Tasa anual'),
                accessor: 'charge',
                Cell: this.renderInput('charge')
                // width: 130
            },
            {
                Header: this.renderHeader('Fijo mensual'),
                accessor: 'monthlyFixed',
                Cell: this.renderInput('monthlyFixed')
                // width: 152
            },
            {
                Header: this.renderHeader('Fijo mensual DOP'),
                accessor: 'monthlyFixedAmount',
                Cell: this.renderInput('monthlyFixedAmount')
                // width: 152
            },
            {
                Header: this.renderHeader('Primera cuota'),
                accessor: 'firstPayment',
                Cell: this.renderInput('firstPayment')
                // width: 152
            },
            {
                Header: this.renderHeader('Primera cuota DOP'),
                accessor: 'firstPaymentAmount',
                Cell: this.renderInput('firstPaymentAmount')
                // width: 152
            },
            {
                Header: this.renderHeader('Inicial mínimo'),
                accessor: 'minAdvancePayment',
                Cell: this.renderInput('minAdvancePayment')
                // width: 152
            },
            {
                Header: this.renderHeader('Monto min.'),
                accessor: 'minimum',
                Cell: this.renderInput('minimum')
                // width: 152
            },
            {
                Header: this.renderHeader('Monto max.'),
                accessor: 'maximum',
                Cell: this.renderInput('maximum')
                // width: 152
            },
            {
                Header: this.renderHeader('Estado'),
                accessor: 'enabled',
                Cell: this.renderInput('enabled')
                // width: 152
            },
            {
                Header: this.renderHeader('Mostrar con descuento'),
                accessor: 'showIfDiscount',
                Cell: this.renderInput('showIfDiscount')
                // width: 152
            },
            {
                Header: this.renderHeader('Mostrar sin descuento'),
                accessor: 'showNotDiscount',
                Cell: this.renderInput('showNotDiscount')
                // width: 152
            }
        ];
        return (
            <div className="row">
                <div className="col-12">
                    <Formsy
                        ref={this.basicDataRef}
                        onValid={this.enableSubmit}
                        onInvalid={this.disableSubmit}>
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-plans-matrix"
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />
                    </Formsy>
                </div>
                <div className="col-12 row mgT">
                    <div className="col-md-2 col-12 mgB">
                        <RequiredFieldsWrapper
                            onSubmit={this.onSubmit}
                            validSubmit={this.validSubmit}
                            onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                            errorFields={this.state.errorFields}
                            fields={this.state.data}>
                            <button
                                id="submit-button"
                                className={this.validSubmit() ? 'btn btn-primary w-100' :'btn btn-clickable-pseudo-disabled w-100'}>
                                {this.props.creating ? 'Agregar' : 'Guardar'}
                            </button>
                        </RequiredFieldsWrapper>
                    </div>
                    <div className="col-md-2 col-12 mgB">
                        <button
                            className="btn btn-outline w-100"
                            onClick={this.clearFields}>
                            Borrar todo
                        </button>
                    </div>
                    <div className="col-md-8 col-12 mgB" />
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
PlansMatrix.propTypes = {
    onSubmit: PropTypes.func,
    onClear: PropTypes.func,
    planData: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
