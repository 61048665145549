import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';

import {
    PasswordInput,
    FormsyInputWrapper
} from 'common/components/inputs';

import { PASSWORD_ICON } from 'common/constants/icon.constant';

/**
 * Component responsible of rendering the reset password form.
 */
export default class ResetPasswordForm extends React.Component {
    /**
     * Initializes the form.
     */
    constructor(props) {
        super(props);

        this.state = { canSubmit: false};

        this.changePassword = this.changeField.bind(this, 'password');
        this.changePasswordConfirmation = this.changeField.bind(this, 'passwordConfirmation');

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && prevProps.error !== this.props.error) {
            this.disableSubmit();
        }
    }

    /**
     * Fired once any form input changes.
     */
    changeField(field, value) {
        this.props.onFormChange(field, value);
    }

    /**
     * Fired after formsy validations
     */
    canSubmit(canSubmit) {
        this.setState({canSubmit});
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                onValidSubmit={this.props.onResetPassword}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                {this.props.error &&
                    <div className='has-error col-12'>
                        <span className='help-block'>{this.props.error}</span>
                    </div>
                }
                <FormsyInputWrapper
                    required
                    className="col-12 mgB2"
                    name="password"
                    value={this.props.passwordConfirmation === this.props.password}
                    validations="isTrue"
                    validationError="Las contraseñas deben ser iguales.">
                    <PasswordInput
                        icon={PASSWORD_ICON}
                        label="Nueva contraseña"
                        dangerLabel={this.props.passwordConfirmation !== this.props.password}
                        value={this.props.password}
                        onChange={this.changePassword}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    required
                    className="col-12 mgB2"
                    name="passwordConfirmation"
                    value={this.props.passwordConfirmation === this.props.password}
                    validations="isTrue"
                    validationError="Las contraseñas deben ser iguales.">
                    <PasswordInput
                        icon={PASSWORD_ICON}
                        label="Confirmar contraseña"
                        dangerLabel={this.props.passwordConfirmation !== this.props.password}
                        value={this.props.passwordConfirmation}
                        onChange={this.changePasswordConfirmation}
                    />
                </FormsyInputWrapper>
                <div className="clearfix" />

                <div className="col-12 text-center mgB">
                    <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={!this.state.canSubmit}>
                        Guardar nueva contraseña
                    </button>
                </div>

                <div className="col-12 mgT3 mgB3">
                    <hr />
                </div>

                <div className="col-12 text-center mgB">
                    <Link to="/">
                        <button type="button" className="btn btn-outline w-100">Iniciar sesión</button>
                    </Link>
                </div>
            </Formsy>
        );
    }
}

/**
 * Login form properties validation.
 */
ResetPasswordForm.propTypes = {
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    onFormChange: PropTypes.func
};
