import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';
import { financingEntityReducer } from './financing-entity.reducer';
import { transactionReducer } from './transaction.reducer';
import { promotionReducer } from './promotion.reducer';
import { apiCardReducer } from './card.reducer';

import { genericUIReducer } from './generic-ui.reducer';

export const rootReducers = combineReducers({
    userReducer,
    transactionReducer,
    financingEntityReducer,
    genericUIReducer,
    promotionReducer,
    apiCardReducer
});
