'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FinancingDetailContainer from './financing-detail';
import FinancingsListContainer from './financings-list';
import FinancingsListNotCompletedContainer from './financing-notcompleted'

export default class FinancingsContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route exact path="/financings" component={FinancingsListContainer} />
                <Route path="/financings/notcompleted/:id" component={FinancingsListNotCompletedContainer} />
                <Route path="/financings/:id" component={FinancingDetailContainer} />
            </Switch>
        );
    }
}
