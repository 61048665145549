import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    TextInput,
    SelectInput
} from 'common/components/inputs';

import { USERTYPES } from 'common/constants/enums.constant';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

export default class CreateUserForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            userData: {
                id: props.userid,
                name: props.creating ? '' : props.userData.name,
                lastname: props.creating ? '' : props.userData.lastname,
                email: props.creating ? '' : props.userData.email,
                phone: props.creating ? '' : props.userData.phone ? props.userData.phone : '',
                store: props.creating ? null : props.userData.entity && props.userData.userType.id === USERTYPES.EMPLOYEE ? props.userData.entity.id : null,
                financingEntity: props.creating ? null : props.userData.entity && props.userData.userType.id === USERTYPES.AGENT ? props.userData.entity.id : null,
                brancheAsociate: props.creating ? null : props.userData.brancheAsociate ? props.userData.brancheAsociate : '',
                mobile: props.creating ? '' : props.userData.mobile ? props.userData.mobile : '',
                extension: props.creating ? '' : props.userData.extension ? props.userData.extension : '',
                userType: props.creating ? null : props.userData.userType.id
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                lastname: { errorClass: null, dangerLabel: false },
                email: { errorClass: null, dangerLabel: false },
                userType: { errorClass: null, dangerLabel: false },
                store: { errorClass: null, dangerLabel: false },
                financingEntity: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false }
            },
            showBranches: false,
            branches: []
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let userData = {... this.state.userData};
        userData[field] = value;
        let branches = [];
        if ((field === 'financingEntity') || (field === 'brancheAsociate')) {
            let idEntity = (field === 'brancheAsociate') ? userData['financingEntity'] : value;
            branches = this.props.dropdowns.financingEntities.filter(fe => { return fe.value === idEntity; })[0].branches;
            if ( branches.length > 0) {
                branches = branches.map((br => {
                    return {
                        value: br.value,
                        label: br.name
                    };
                }));
            }
        }
        this.setState({userData: userData, showBranche: branches.length > 0, branches: branches});
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.userData});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    {this.props.authUser.userType.id === USERTYPES.ADMIN &&
                        <FormsyInputWrapper
                            required
                            className="col-md-5 col-12 mgB2"
                            name="userType"
                            errorClassName={this.state.errorFields.userType.errorClass}
                            value={this.state.userData.userType}>
                            <SelectInput
                                floatingLabel={<span>Tipo <span className="color-red">*</span></span>}
                                key={'userData.userType'}
                                dangerLabel={this.state.errorFields.userType.dangerLabel}
                                label="Seleccione"
                                options={this.props.dropdowns.userTypes}
                                value={this.state.userData.userType}
                                onChange={(val) => this.onFieldChange('userType', val)}
                            />
                        </FormsyInputWrapper>
                    }
                    { this.props.authUser.userType.id === USERTYPES.ADMIN &&
                        <div className="col-md-7">
                            {this.state.userData.userType === USERTYPES.AGENT &&
                        <FormsyInputWrapper
                            required
                            className="col-md-12 col-12 mgB2"
                            name="financingEntity"
                            errorClassName={this.state.errorFields.financingEntity.errorClass}
                            value={this.state.userData.financingEntity}
                        >
                            <SelectInput
                                floatingLabel={<span>Entidad <span className="color-red">*</span></span>}
                                key={'userData.financingEntity'}
                                label="Seleccione"
                                dangerLabel={this.state.errorFields.financingEntity.dangerLabel}
                                options={this.props.dropdowns.financingEntities}
                                value={this.state.userData.financingEntity}
                                onChange={(val) => this.onFieldChange('financingEntity', val)}
                            />
                        </FormsyInputWrapper>
                            }
                            {this.state.userData.userType === USERTYPES.EMPLOYEE &&
                        <FormsyInputWrapper
                            required
                            className="col-md-12 col-12 mgB2"
                            name="store"
                            errorClassName={this.state.errorFields.store.errorClass}
                            value={this.state.userData.store}>
                            <SelectInput
                                floatingLabel={<span>Tienda <span className="color-red">*</span></span>}
                                key={'userData.store'}
                                dangerLabel={this.state.errorFields.store.dangerLabel}
                                label="Seleccione"
                                options={this.props.dropdowns.stores}
                                value={this.state.userData.store}
                                onChange={(val) => this.onFieldChange('store', val)}
                            />
                        </FormsyInputWrapper>
                            }
                        </div>
                    }
                    { this.props.authUser.userType.id === USERTYPES.ADMIN && this.state.userData.userType === USERTYPES.AGENT && this.state.branches.length > 0 &&
                        <FormsyInputWrapper
                            className="col-md-12 col-12 mgB2"
                            name="brancheAsociate"
                            value={this.state.userData.brancheAsociate}
                        >
                            <SelectInput
                                floatingLabel={<span>Sucursal </span>}
                                key={'userData.financingEntity.branche'}
                                label="Seleccione"
                                options={this.state.branches}
                                value={this.state.userData.brancheAsociate}
                                onChange={(val) => this.onFieldChange('brancheAsociate', val)}
                            />
                        </FormsyInputWrapper>
                    }
                    <FormsyInputWrapper
                        required
                        className="col-6 mgB2"
                        name="name"
                        errorClassName={this.state.errorFields.name.errorClass}
                        value={this.state.userData.name}>
                        <TextInput
                            label={<span>Nombre <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.state.errorFields.name.dangerLabel}
                            placeholder="Inserte el nombre"
                            value={this.state.userData.name}
                            onChange={(val) => this.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-6 mgB2"
                        name="lastname"
                        errorClassName={this.state.errorFields.lastname.errorClass}
                        value={this.state.userData.lastname}>
                        <TextInput
                            label={<span>Apellidos <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.state.errorFields.lastname.dangerLabel}
                            placeholder="Inserte los apellidos"
                            value={this.state.userData.lastname}
                            onChange={(val) => this.onFieldChange('lastname', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="email"
                        validations="isEmail"
                        errorClassName={this.state.errorFields.email.errorClass}
                        validationError="Por favor ingrese un correo electrónico válido."
                        value={this.state.userData.email}>
                        <TextInput
                            label={<span>Correo electrónico <span className="color-red">*</span></span>}
                            placeholder="Inserte el correo"
                            dangerLabel={this.state.errorFields.email.dangerLabel}
                            value={this.state.userData.email}
                            onChange={(val) => this.onFieldChange('email', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-5 col-12 mgB2"
                        name="phone"
                        validations={this.state.userData.phone !== '' ? 'isValidDOMPhone' : null}
                        validationError={this.state.userData.phone !== '' ? "Por favor ingrese un número de telefono válido." : null}
                        value={this.state.userData.phone}>
                        <TextInput
                            label={<span>Teléfono <span className="color-red">*</span></span>}
                            dangerLabel={this.state.errorFields.phone.dangerLabel}
                            value={this.state.userData.phone}
                            placeholder="Inserte el teléfono"
                            onChange={(val) => this.onFieldChange('phone', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-7 col-12 mgB2"
                        name="extension"
                        value={this.state.userData.extension}>
                        <TextInput
                            label="Extensión"
                            onlyNumbers={true}
                            numbers={true}
                            placeholder="Inserte extensión"
                            value={this.state.userData.extension}
                            onChange={(val) => this.onFieldChange('extension', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-12"
                        name="mobile"
                        validations={this.state.userData.mobile !== '' ? 'isValidPhone' : null}
                        validationError={this.state.userData.mobile !== '' ? "Por favor ingrese un número de celular válido." : null}
                        value={this.state.userData.mobile}>
                        <TextInput
                            label="Celular"
                            value={this.state.userData.mobile}
                            placeholder="Inserte el celular"
                            onChange={(val) => this.onFieldChange('mobile', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                </div>
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-user-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.userData}>
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            {this.props.creating ? 'Crear' : 'Guardar'}
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </Formsy>
        );
    }
}

CreateUserForm.defaultProps = {creating: true};

CreateUserForm.propTypes = {
    creating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
