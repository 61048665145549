import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import EmptyState from 'common/components/empty-state';

/**
 * A simple modal to confirm an user's action.
 */
export default class InformationModal extends React.Component {

    render() {
        return (
            <div>
                <ReactModal
                    style={{ content: this.props.style }}
                    className={`modal__content ${this.props.className}`}
                    overlayClassName={`modal__overlay ${this.props.overlayClassName}`}
                    bodyOpenClassName={`modal--is-open`}
                    id={this.props.id}
                    isOpen={this.props.showModal}
                    ariaHideApp={false}
                    contentLabel="Minimal Modal Example">
                    {this.props.showHeader &&
                        <div className="modal-header-container">
                            <div className="row">
                                <div className="align-center col-10 ">
                                    {this.props.success &&
                                        <EmptyState
                                            height={100}
                                            image={this.props.imageSuccess}
                                        />
                                    }
                                    {!this.props.success &&
                                        <EmptyState
                                            height={100}
                                            image={this.props.imageFailure}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        {this.props.children}
                    </div>
                </ReactModal>
            </div>
        );
    }

}

InformationModal.defaultProps = {
    className: '',
    showModal: false,
    overlayClassName: '',
    success: true,
    showHeader: true,
    closable: true,
    imageSuccess: "/images/check.svg",
    imageFailure: "/images/cancel.svg",
    noDivider: false
};

/**
 * InformationModal properties validation.
 */
InformationModal.propTypes = {
    children: PropTypes.node.isRequired,
    showModal: PropTypes.bool,
    showHeader: PropTypes.bool,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    bodyOpenClassName: PropTypes.string,
    onSuccess: PropTypes.func
};
