
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as apicardActionsLabel from '../actions/card/card.enum';

import merge from 'common/helpers/merge-objects';

import { GENERIC_ERROR } from 'common/constants/errors.constant';


export const apicardInitialState = Object.seal({
    responseData: null,
    doOperation: false,
    error: null,
    triesFails: 0,

    // Properties validation.
    propTypes: {
        loading: PropTypes.bool,
        error: PropTypes.string
    }
});

export const apiCardReducer = (state = apicardInitialState, action) => {
    let newState = state;
    switch (action.type) {
    case apicardActionsLabel.BEGIN_TRANSACTION: {
        const newStateObject = Object.assign({}, {doOperation: true, responseData: null});
        newState = merge(state, newStateObject);
        break;
    }
    case apicardActionsLabel.CLOSE_TRANSACTION: {
        const newStateObject = Object.assign({}, {doOperation: false, responseData: null});
        newState = merge(state, newStateObject);
        break;
    }

    case apicardActionsLabel.AUTHORIZE_CARD: {
        const newStateObject = Object.assign({}, {
            error: null,
            triesFails: newState.triesFails + 1
        });
        newState = merge(state, newStateObject);
        break;
    }

    case apicardActionsLabel.AUTHORIZE_CARD_SUCCESS: {
        const newStateObject = Object.assign({}, {
            responseData: action.payload,
            doOperation: false,
            error: null
        });
        newState = merge(state, newStateObject);
        break;
    }

    case apicardActionsLabel.REGISTER_CUSTOMER_TOKENS: {
        const newStateObject = Object.assign({}, { error: null });
        newState = merge(state, newStateObject);
        break;
    }

    case apicardActionsLabel.AUTHORIZE_CARD_FAILURE:
    case apicardActionsLabel.REGISTER_CUSTOMER_TOKENS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { error: error, doOperation: false});
        newState = merge(state, newStateObject);
        break;
    }
    default:
        break;
    }
    return newState;
};

