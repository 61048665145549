import React from 'react';
import {connect} from 'react-redux';
import _, { forEach } from 'lodash';
import Formsy from 'formsy-react';

import money from 'common/helpers/money';
import calculateFinancing from 'common/helpers/calculate-financing';
import calculatePromo from 'common/helpers/calculate-promo';
import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as apiActions from 'common/store/actions/card/card.actions';
import * as apiSelectors from 'common/store/selectors/card.selector';
import * as promotionActions from 'common/store/actions/promotions/promotions.actions';
import * as promotionSelectors from 'common/store/selectors/promotion.selector';

import {
    CURRENCIES,
    CURRENCIES_IDS
} from 'common/constants/enums.constant';

import Modal from 'common/components/modal';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import StepperComponent from 'common/components/stepper-component';
import CartComponent from 'common/components/cart-component';
import ImagePreview from 'common/components/image-preview';
import EmptyState from 'common/components/empty-state';

import {
    SelectInput,
    FormsyInputWrapper,
    MoneyInput,
    PasswordInput
} from 'common/components/inputs';


import SelectPlanForm from './select-plan-form';

const mapStateToProps = (state) => {
    return {
        financingForm: transactionSelectors.getFinancingForm(state),
        financingTemp: transactionSelectors.getTemporaryFinancing(state),
        financingEntityTypes: transactionSelectors.getAllFinancingEntityTypes(state),
        financingEntities: transactionSelectors.getFilteredFinancingEntities(state),
        financingEntityType: transactionSelectors.getFinancingEntityType(state),
        plansToCompare: transactionSelectors.getPlansToCompare(state),
        emailValidated: userSelectors.getIsValidEmail(state),
        error: userSelectors.getError(state),
        validatingUser: userSelectors.getValidatingUser(state),
        userDataProfile: userSelectors.getUserDataProfile(state),
        emailSent: userSelectors.getEmailSent(state),
        responseData: apiSelectors.responseData(state),
        fails: apiSelectors.triesFails(state),
        doOperation: apiSelectors.loadingApi(state),
        cardTransaction: transactionSelectors.getCardTransactionStatus(state),
        promotionsActivated: promotionSelectors.getPromotionsActivated(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllEntities: (storeId) => {
            dispatch(transactionActions.getAllEntities(storeId));
        },
        getAllFinancingEntityTypes: (flag) => {
            dispatch(transactionActions.getAllFinancingEntityTypes(flag));
        },
        selectPlanToCompare: (plan, value) => {
            dispatch(transactionActions.selectPlanToCompare(plan, value));
        },
        setFinancingEntityType: (type) => {
            dispatch(transactionActions.setFinancingEntityType(type));
        },
        VerifyEmailandPass: (email, password) => {
            dispatch(userActions.findByEmailandPass(email, password));
        },
        resetPlansToCompare: () => {
            dispatch(transactionActions.resetPlansToCompare());
        },
        userForgotPassword: (email) => {
            dispatch(userActions.forgotPassword(email));
        },
        clearData: () => {
            dispatch(userActions.cleardata());
        },
        createCardTransaction: (dataTransaction) => {
            dispatch(transactionActions.createCardFinancig(dataTransaction));
        },
        cleanCardTransaction: () => {
            dispatch(transactionActions.resetFinancingMessages());
        },
        authorizeCard: (params) => {
            dispatch(apiActions.authorizeCard(params));
        },
        beginTransaction: () => {
            dispatch(apiActions.beginTransaction());
        },
        closeTransaction: () => {
            dispatch(apiActions.closeTransaction());
        },
        loadPromotions: () => {
            dispatch(promotionActions.searchPromotionsActivate());
        },
        getPromoApplied: (filters) => {
            dispatch(promotionActions.searchPromotionsApplidByEntity(filters));
        },
        setDataPaymentDiscounnt: (data) => {
            dispatch(transactionActions.setDataPaymentDiscounnt(data));
        }
    };
};

class StepOneFinancingCheckout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financingData: {},
            orderBy: null,
            errorFields: {
                financingEntity: { errorClass: null, dangerLabel: false },
                plan: { errorClass: null, dangerLabel: false }
            },
            showCancelModal: false,
            discount: props.cart.discount,

            totaltopay: null,
            canSubmit: props.financingForm.canSubmitStepOne,
            comparing: false,
            showComparisonTableModal: false,
            showPlanConfirmed: false,
            showLoginModal: false,
            showCardModal: false,
            processingCard: false,
            userLogin: {
                email: this.props.financingForm['email'],
                pass: ''
            },
            cardsData: {
                cardNumber: '',
                monthValid: '',
                yearValid: '',
                cvv: '',
                owner: '',
                cardtype: null
            },
            temporaryFinancing: {},
            promoPlan: null,
            plansInPromo: []
        };
    }

    onFieldChange = (field, value) => {
        let financingData = Object.assign({}, this.state.financingData);

        if (field === 'plan') {
            if (financingData.plan !== null) {
                financingData['advancePayment'] = '';
            }
        }

        financingData[field] = value;

        if (field === 'financingEntity') {
            financingData['plan'] = null;
            financingData['advancePayment'] = '';

            this.setState({promoPlan: null});

        }

        this.setState({financingData: financingData});
    };

    canSubmit = (canSubmit) => {
        this.setState({ canSubmit: canSubmit });
    };

    onNext = () => {
        let financingData = {};
        if ((this.props.authUser !== null)) {
            financingData = Object.assign({}, this.state.financingData, {canSubmitStepOne: this.state.canSubmit});
        } else {
            if (this.props.emailValidated) {
                if (!this.props.userDataProfile) {
                    this.props.clearData();
                    this.setState({showLoginModal:true});
                    return;
                } else {
                    if (this.props.userDataProfile.financingProfile) {
                        /*Rellenar financinData con el perfil cargado */
                        financingData = Object.assign({}, this.state.financingData, {canSubmitStepOne: this.state.canSubmit});
                        financingData = Object.assign({}, financingData, this.props.objectToFields(this.props.userDataProfile.financingProfile));
                        if (this.props.userDataProfile.defaultAddress !== null) {
                            financingData['street'] = this.props.userDataProfile.defaultAddress.street;
                            financingData['city'] = this.props.userDataProfile.defaultAddress.city;
                            financingData['province'] = this.props.userDataProfile.defaultAddress.province.id;
                        }
                    } else{
                        financingData = Object.assign({}, this.state.financingData, {canSubmitStepOne: this.state.canSubmit});
                    }
                }
            } else if (this.props.emailValidated === null) {
                this.props.clearData();
                this.setState({showLoginModal:true});
                return;
            } else {
                financingData = Object.assign({}, this.state.financingData, {canSubmitStepOne: this.state.canSubmit});

            }
        }
        if ((this.state.financingData.advancePayment === '' || !this.state.financingData.advancePayment) && ((this.props.plansToCompare.length === 1) && this.props.plansToCompare[0].minAdvancePayment > 0)) {
            let advancePayment = this.props.plansToCompare[0].minAdvancePayment * (this.props.cart.total);
            financingData = Object.assign({}, financingData, {advancePayment: advancePayment});
        }

        //if (this.props.authUser === null && this.props.emailValidated === false ) {
        let data = Object.assign({}, this.state.temporaryFinancing);
        data.advancePayment = financingData.advancePayment;
        this.props.onSetFinancingTempData(data);
        //}

        this.props.onSetFinancingFormData(financingData);
        this.props.resetPlansToCompare();
        this.props.nextStep({});
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.financingEntities.length === 0) {
            this.props.getAllEntities(this.props.cart.storeId);
        }
        if (this.props.financingEntityTypes.length === 0) {
            this.props.getAllFinancingEntityTypes(true);
        }

        this.props.loadPromotions();

        let financingData = {...this.props.financingForm};
        financingData['cart'] = this.props.cart;

        financingData['externalOrderId'] = this.props.cart.orderId;
        financingData['onSuccess'] = this.props.cart.onSuccess;
        financingData['onFailure'] = this.props.cart.onFailure;
        financingData['cardselected'] = this.props.cart.cardselected;

        let subTotal = _.sumBy(this.props.cart.items, (item) => {
            return item.count * item.price;
        });
        let taxes = (subTotal + this.props.cart.deliveryCost) * parseFloat(process.env.MIX_TAXES);
        let total = subTotal + taxes + this.props.cart.deliveryCost;
        this.setState({totaltopay: total/100});
        financingData['cart']['total'] = total;
        financingData['cart']['currencyId'] = this.props.cart.currency === CURRENCIES.PESO ? CURRENCIES_IDS.PESO : CURRENCIES_IDS.DOLLAR;
        financingData['currencyId'] = this.props.cart.currency === CURRENCIES.PESO ? CURRENCIES_IDS.PESO : CURRENCIES_IDS.DOLLAR;
        financingData['callback'] = this.props.callback;

        if (financingData['plan'] !== null) {
            this.props.selectPlanToCompare(financingData['plan'], true);
        }
        let datatemp = {
            name: financingData['name'],
            lastname: financingData['lastname'],
            email: financingData['email'],
            phone: financingData['phone'],
            identification: financingData['identification'],
            identificationType: financingData['identificationType'],
            cart: {
                storeId: this.props.cart.storeId,
                storeName: this.props.cart.storeName,
                items: this.props.cart.items,
                deliveryCost: this.props.cart.deliveryCost,
                discount: this.props.cart.discount,
                currency: this.props.cart.currency,
                currencyId: this.props.cart.currencyId,
                total: this.props.cart.total
            },
            street: financingData['street'],
            city: financingData['city'],
            country: financingData['country'],
            plan: null,
            externalOrder: this.props.cart.orderId
        };
        this.setState({
            temporaryFinancing: Object.assign({}, this.props.financingTemp, datatemp),
            financingData: financingData,
            canSubmit: this.props.financingForm.canSubmitStepOne
        });
        let typeEntDefault = (financingData['cardselected'] === true) ? 4 : 1;
        this.props.setFinancingEntityType(typeEntDefault);

    }

    componentDidUpdate = () => {
        if (this.props.responseData !== null && this.state.showCardModal === false && this.props.cardTransaction === null) {
            const cardTransaction = {
                externalOrder: this.props.cart.orderId,
                owner: this.state.cardsData.owner,
                clientId: this.props.responseData.cardResponse.CustomerId,
                lastFourth: this.state.cardsData.cardNumber.substring(12),
                toPay: this.state.totaltopay * 100,
                plan: (this.state.financingData.plan !== null) ? this.state.financingData.plan.id : -1,
                statuscode: this.props.responseData.cardResponse.ResponseCode,
                email: this.props.financingForm['email'],
                store: this.props.cart.storeId,
                planName: (this.state.financingData.plan !== null) ? this.state.financingData.plan.name : '',
                phone: this.state.financingData["phone"],
                identification: this.state.financingData["identification"],
                entityName: (this.state.financingData.plan !== null) ? this.state.financingData.plan.financingEntity.name : '',
                cardtype: this.state.cardsData.cardtype
            };
            this.props.createCardTransaction(cardTransaction);
        }

        if (this.props.cardTransaction !== null && this.state.showCardModal === false) {
            this.setState({showCardModal: true});
        }
    };

    onTextChange = (valor) => {
        let userLog = {...this.state.userLogin};
        userLog.pass = valor;
        this.setState({userLogin:userLog});
    };

    renderLoginModal(){
        return (<Modal
            overlayClassName="o-inherit"
            onModalClose={() => this.setState({showLoginModal: false})}
            id='edit-user-modal'
            closable={false}
            noDivider={true}
            showModal={this.state.showLoginModal}>
            <div>
                <div
                    style={{marginLeft:'90%', fontSize:'12px'}}
                    className="color-blue cursor-pointer"
                    disabled = {this.props.validatingUser}
                    onClick={() => this.setState({showLoginModal:false})}>
                          Cerrar
                </div>
                <h2 className="text-center">Iniciar Session</h2>
                {!this.props.userDataProfile &&
                      <div> <p className="text-center" style={{fontSize:'12px'}}>Hemos detectado que el correo <strong >{this.state.userLogin.email}</strong>  esta registrado en <strong className="color-blue">Electropay</strong>.
                           Por favor ingrese sus credenciales para obtener sus datos y continuar </p>

                      <div className="clearfix" />
                      <div >
                          <PasswordInput
                              label="Contraseña"
                              value={this.state.userLogin.pass}
                              onChange={(val) => this.onTextChange(val)}/>
                      </div>

                      <div className="clearfix" />
                      {this.props.error && this.props.userDataProfile === null &&
                                <div className="text-center ">
                                    <p style={{color:'red'}}>{this.props.error}</p>
                                </div>
                      }
                      <div className="clearfix" />
                      {this.props.emailSent && !this.props.error &&
                            <div className="text-center color-blue">
                                <p >Hemos enviado un enlace a su correo para reestablecer su contraseña.</p>
                            </div>
                      }
                      <div className="clearfix" />

                      <div className="row">
                          <div className="col-md-6 col-12 text-center mgB"
                          >
                              <button
                                  disabled = {this.props.validatingUser}
                                  className="btn btn-primary w-100"
                                  onClick={() => this.props.userForgotPassword(this.state.userLogin.email)} >
                                     Reestablecer Contraseña
                              </button>
                          </div>
                          <div className="col-md-6 col-12 text-center mgB">
                              <button
                                  disabled= {((this.state.userLogin.pass === null) || (this.state.userLogin.pass === undefined) || (this.state.userLogin.pass === '') || (this.props.validatingUser))}
                                  className="btn btn-primary w-100"
                                  onClick={() => this.props.VerifyEmailandPass(this.state.userLogin.email, this.state.userLogin.pass)}>
                                    Verificar
                              </button>
                          </div>
                      </div>
                      </div>}
                {this.props.userDataProfile &&
                    <div className="row"><div className="col-12">
                        <p className="text-center" style={{fontSize:'12px'}}>Los datos han sido verificado correctamente. Pasemos al próximo paso. </p>
                    </div>
                    <div className="col-md-4 col-12 "></div>
                    <div className="col-md-4 col-12 text-center mgB">
                        <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                                this.setState({showLoginModal:false});
                                this.props.setpass(this.state.userLogin.pass);
                                this.onNext();
                            }}>
                                    Continuar
                        </button>
                    </div>
                    <div className="col-md-4 col-12 "></div>
                    </div>
                }
            </div>
        </Modal>);
    }

    renderCancelModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCancelModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showCancelModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Cancelar solicitud</h2>
                    <p className="text-center">¿Está seguro que desea cancelar la solicitud? <strong>Perderá el progreso realizado.</strong></p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => {
                                this.props.onCancelCheckout(this.state.financingData.externalOrderId);
                                if (this.props.from) {
                                    location.replace(this.props.from);
                                }
                                this.props.onResetFinancingFormData();
                            }}
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showCancelModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderAdvancePayment = () => {
        let showAdvance = false;
        let minLimit = -1;
        let maxLimit = 1000000000000000;

        if (this.state.financingData.plan !== null && this.state.financingData.plan !== undefined) {
            let plan = this.state.financingData.plan;
            if (plan.minAdvancePayment) {
                showAdvance = true;
                minLimit = plan.minAdvancePayment * this.props.cart.total / 100;
            }
            if (plan.maxAdvancePayment) {
                showAdvance = true;
                maxLimit = plan.maxAdvancePayment * this.props.cart.total / 100;
            }
        }
        maxLimit;
        if (showAdvance) {
            return (
                <div className="row mgB">
                    <Formsy>
                        <FormsyInputWrapper
                            className="col-12"
                            name="advancePayment"
                            value={this.state.financingData.advancePayment / 100}
                            validations = {{
                                advanceValidation: function (values, value) {
                                    if (value !== null || value !== undefined) {
                                        if ((value === 0) || (value >= minLimit)) {
                                            return true;
                                        }
                                    }
                                    return false;
                                }
                            }}>
                            <MoneyInput
                                className="white-background"
                                floatingLabel={<span>Adelanto <span className="color-red">*</span></span>}
                                placeholder={`${minLimit > -1 ? 'Mínimo: ' + (minLimit) : ''}`}
                                value={this.state.financingData.advancePayment / 100}
                                onChange={(val) => this.onFieldChange('advancePayment', val * 100)}
                            />
                            <p className="color-red">El monto mínimo debe ser al menos {this.state.financingData.plan.currency.symbol + '$ ' + money(minLimit)}. Al dejarlo en blanco se asume el mínimo calculado. </p>
                        </FormsyInputWrapper>
                    </Formsy>
                </div>
            );
        }
        return <div></div>;
    };

    renderComparisonTableModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showComparisonTableModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showComparisonTableModal}>
                <div>
                    <h2 className="text-center">Comparar</h2>
                    <SelectInput
                        value={this.state.orderBy}
                        floatingLabel="Ordenar por"
                        options={[
                            {value: 'restPayments', label: 'Cuota Mensual'},
                            {value: 'total', label: 'Total Préstamo'},
                            {value: 'charge', label: 'Tasa Anual'}
                        ]}
                        onChange={(val) => this.setState({orderBy: val})}
                        className="selectcompare"
                    />
                    <span style={{fontSize:'12px', color:'#124b6f', marginTop:'5px', textAlign:'center'}} >(Escoja el plan que desee y presione continuar)</span>
                    <div className="row pdL2 pdR2" style={{borderColor: '#d2d2d2'}} role="region" aria-labelledby>
                        <div style={{overflowX:'scroll', borderLeft:'none', maxHeight:'300px', overflowY:'auto'}} className="tableDataplan">
                            <table className="white-background Plantable" width="510px" style={{borderLeft:'1px solid #d2d2d2'}}>
                                <thead style={{boxShadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)', borderBottom:'1px solid #d2d2d2', borderTop:'1px solid #d2d2d2'}}>
                                    <tr style={{textAlign:'center', fontSize:'14px', height:'30px'}}>
                                        <th width="100px" style={{textAlign:'right'}}>No. Cuotas</th>
                                        <th width="100px" >
                                            <span >Entidad</span>
                                            <br></br>
                                            <span>(Plan)</span>
                                        </th>
                                        <th width="60px">Cuotas</th>
                                        <th width="60px">Inicial</th>
                                        <th width="60px">Total</th>
                                        <th width="60px">
                                            <span >Gastos</span>
                                            <br></br>
                                            <span >Cierre</span>
                                        </th>
                                        <th width="60px" >
                                            <span >Tasa</span>
                                            <br></br>
                                            <span >Anual</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.sortBy(this.props.plansToCompare, (el) => {
                                        if (el[this.state.orderBy] !== null && el[this.state.orderBy] !== undefined) {
                                            return parseFloat(el[this.state.orderBy].toString().replace('RD$ ', ''));
                                        }
                                        return el;
                                    }).map(el => {
                                        let newItems = calculatePromo(el, el.inPromo, this.props.cart.items, el.financingEntity.type.id === 4);
                                        let newValuesPlan = null;
                                        if (newItems !== null) {
                                            newValuesPlan = Object.assign({}, calculateFinancing(el.payments, el, this.calculateProm(el)));
                                        }
                                        let gastos = 0;
                                        if (newValuesPlan === null) {
                                            gastos += (el.first === el.restPayments) ? '-' : (parseFloat(el.first.replace('RD$', '').replace(',', '')) - parseFloat(el.restPayments.replace('RD$', '').replace(',', ''))).toFixed(2);
                                        } else {
                                            gastos += (el.first === el.restPayments) ? '-' : (parseFloat(newValuesPlan.firstPayment.replace('RD$', '').replace(',', '')) - parseFloat(newValuesPlan.restPayments.replace('RD$', '').replace(',', ''))).toFixed(2);
                                        }
                                        return (
                                            <tr style={{fontSize:'10px', borderBottom:' 1px solid #d2d2d2', height:'50px'}}
                                                onClick={() => {
                                                    this.onFieldChange('plan', el);
                                                }}
                                                className={'cursor-pointer' + ((this.state.financingData.plan && el.id === this.state.financingData.plan.id) ? ' fsbold color-blue' : (el.inPromo !== null ) ? ' color-green': '' ) }
                                                key={'comparing-plan-' + el.id}
                                            >
                                                <th width="100px">
                                                    <div style={{float:'left'}}><img alt="imageLogo" src={el.financingEntity.logoico} height="30px" width="30px"/></div>
                                                    <div style={{textAlign:'center', paddingTop:'5px', fontWeight:'bolder', fontSize:'14px'}}>{el.payments}</div>
                                                </th>
                                                <td width="100px">
                                                    <div style={{paddingLeft:'3px', paddingRight:'3px', wordWrap:'break-word'}}>
                                                        <span>{el.financingEntity.name}</span>
                                                        <br></br>
                                                        <strong>({el.name})</strong></div>
                                                </td>
                                                <td style={{textAlign:'right', paddingRight:'10px'}} >{ (newValuesPlan === null) ? el.restPayments.replace('RD$', '') : newValuesPlan.restPayments.replace('RD$', '')}</td>
                                                <td style={{textAlign:'center'}}>{(el.minAdvancePayment) ? el.minAdvancePayment * 100 : '-' } {(el.minAdvancePayment) ? '%' : ''}</td>
                                                <td style={{textAlign:'right', paddingRight:'10px'}}> {(newValuesPlan === null) ? el.total.replace('RD$', '') : newValuesPlan.total.replace('RD$', '')}  </td>
                                                <td style={{textAlign:'right', paddingRight:'10px'}}> { money(gastos)}  </td>
                                                <td style={{textAlign:'center'}}> { (el.charge*100).toFixed(2)}% </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {this.renderAdvancePayment()}
                    <ul>
                        <li>
                            <span style={{fontSize:'12px', color:'#124b6f'}} className="mgT2">Los valores monetarios son expresados en RD$</span>
                        </li>
                        <li>
                            <span style={{fontSize:'12px', color:'#124b6f'}} className="mgT2">Los planes en color <span className='color-green'>verde</span> estan en promoción</span>
                        </li>
                    </ul>
                    <div className="row" style={{marginTop:'10px'}}>
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showComparisonTableModal: false})}
                                className="btn btn-outline w-100">
                                Volver
                            </button>
                        </div>
                        <div className="col-md-6 mgB">
                            <button
                                disabled={((this.state.financingData.plan === null) || ((this.state.financingData.plan) && (this.state.financingData.advancePayment / 100) > 0 && ((this.state.financingData.advancePayment / 100) < (this.state.financingData.plan.minAdvancePayment * this.props.cart.total / 100))))}
                                onClick={() => this.setState({showPlanConfirmed:true, showComparisonTableModal:false})}
                                className="btn btn-primary w-100">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    calculateProm = (plan) =>{
        let newData = calculatePromo(plan, plan.inPromo, this.props.cart.items, plan.financingEntity.type.id === 4);
        let subTotal = 0;
        let taxes = 0;
        if (newData !== null) {
            subTotal += _.sumBy(newData.newItems, (item) => {
                return item.count * item.price;
            }) / 100;
            taxes += (subTotal + this.props.cart.deliveryCost / 100) * parseFloat(process.env.MIX_TAXES);
        } else {
            subTotal += _.sumBy(this.props.cart.items, (item) => {
                return item.count * item.price;
            }) / 100;
            taxes += (subTotal + this.props.cart.deliveryCost / 100) * parseFloat(process.env.MIX_TAXES);
        }
        return (subTotal + this.props.cart.deliveryCost / 100) + taxes;
    };


    renderPlanSelected() {
        let data = {};
        let newData = null;
        if (this.state.financingData.plan) {
            let plan = this.state.financingData.plan;
            let newtotal = (this.state.financingData.plan.inPromo != null) ? this.calculateProm(this.state.financingData.plan) : this.state.totaltopay;
            let total = newtotal - ((this.state.financingData.plan.financingEntity.optional_delivery === false) ? (this.props.cart.deliveryCost / 100) : 0);
            let advancePayment = 0;
            if (plan.minAdvancePayment !== null && plan.minAdvancePayment > 0) {
                advancePayment = ((this.state.financingData.advancePayment / 100) <= (plan.minAdvancePayment * newtotal) ) ? plan.minAdvancePayment * newtotal : (this.state.financingData.advancePayment / 100);
                if (advancePayment > 0 ) {
                    total = total - advancePayment;
                }
            }
            let calculatedData = calculateFinancing(plan.payments, plan, total);
            data = {
                calculatedData: calculatedData,
                totalpay: calculatedData.rawTotal + advancePayment,
                totalrequested: total,
                advancePayment: advancePayment
            };

            if (this.state.financingData.plan.inPromo !== null) {
                newData = calculatePromo(this.state.financingData.plan, this.state.financingData.plan.inPromo, this.props.cart.items, this.state.financingData.plan.financingEntity.type.id === 4);
            }

        }
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showPlanConfirmed: false})}
                id='confirmedPlan'
                closable={false}
                noDivider={true}
                showModal={this.state.showPlanConfirmed && this.state.processingCard === false}>
                <div >
                    <div className="text-right cursor-pointer" style={{marginLeft:'85%'}} onClick={() => {
                        if (this.props.plansToCompare.length === 1) {
                            this.setState({showPlanConfirmed: false});
                        } else {
                            this.setState({showPlanConfirmed: false, showComparisonTableModal: true});
                        }
                    }}>
                        <p style={{fontSize:'12px', color:'#124b6f'}}>Cerrar</p>
                    </div>
                    {this.state.financingData.plan &&
             <React.Fragment >
                 <p style={{fontSize:'14px', color:'#124b6f', fontWeight:'bold'}} className="text-center">Detalles del plan escogido</p>
                 <div
                     className={'bordered mgB text-center bordered-blue '} >
                     <div className="no-gutters bordered row w-100 pd2">
                         <div className="col-5">
                             <ImagePreview
                                 style = {
                                     {
                                         height: '100%',
                                         width: 'inherit',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundSize: 'contain'
                                     }
                                 }
                                 className="image-preview"
                                 image={this.state.financingData.plan.financingEntity.logo}>
                             </ImagePreview>
                         </div>
                         <div className="col-7 align-center">
                             {this.state.financingData.plan.financingEntity.name}
                             <br></br>
                             <h4><strong>{this.state.financingData.plan.name}</strong></h4>
                             {this.state.financingData.plan.inPromo !== null &&
                             <p><strong className='color-blue'>{this.state.financingData.plan.inPromo.name}</strong>
                                 <br/>
                                 <span>{(newData !== null) ?
                                     <strong className='color-blue'>- {(this.state.financingData.plan.inPromo.percent_discount > 0) ? this.state.financingData.plan.inPromo.percent_discount + '%': this.state.financingData.plan.currency.symbol + '$ ' + money(this.state.financingData.plan.inPromo.fixed_discount)}</strong>
                                     : <strong className='color-blue'>No aplica</strong>}</span></p>
                             }
                         </div>
                     </div>


                     <div className="row pdT2" style={{fontSize:'12px'}}>
                         <div className="col-md-12 col-12 mgB">
                             <div className="d-flex border-bottom justify-content-between">
                                 <p className="text-gray mgB0 pdL2">Cuota mensual: </p>
                                 <p className="mgB0 pdR2"><strong>{data.calculatedData.restPayments}</strong></p>
                             </div>
                         </div>
                         <div className="col-md-12 col-12 mgB">
                             <div className="d-flex border-bottom justify-content-between">
                                 <p className="text-gray mgB0 pdL2">Inicial: </p>
                                 <p className="mgB0 pdR2"><strong>{this.state.financingData.plan.currency.symbol + '$ ' + money(data.advancePayment)}</strong></p>
                             </div>
                         </div>
                         <div className="col-md-12 col-12 mgB ">
                             <div className="d-flex border-bottom justify-content-between">
                                 <p className="text-gray mgB0 pdL2" >Total: </p>
                                 <p className="mgB0 pdR2"><strong>{this.state.financingData.plan.currency.symbol + '$ ' + money(data.totalpay)}</strong></p>
                             </div>
                         </div>
                         <div className="col-md-12 col-12 mgB">
                             <div className="d-flex border-bottom justify-content-between">
                                 <p className="text-gray mgB0 pdL2">Gastos Cierre: </p>
                                 <p className="mgB0 pdR2"><strong>{data.calculatedData.gastos_cierre}</strong></p>
                             </div>
                         </div>
                         <div className="col-md-12 col-12 mgB ">
                             <div className="d-flex border-bottom justify-content-between">
                                 <p className="text-gray mgB0 pdL2" >Tasa anual: </p>
                                 <p className="mgB0 pdR2"><strong>{(this.state.financingData.plan.charge * 100).toFixed(2)}%</strong></p>
                             </div>
                         </div>
                     </div>
                 </div>
                 {this.state.financingData.plan.financingEntity.require_warranties &&
                    <p style={{fontSize:'14px', fontWeight:'bold'}} className="text-center color-red"> <span style={{color:'#124b6f'}}>{this.state.financingData.plan.financingEntity.name}</span> requiere garantía vehicular o inmobiliaria.</p>
                 }
             </React.Fragment>
                    }

                    <p style={{fontSize:'10px', color:'#124b6f', fontWeight:'bold', wordWrap:'break-word'}} >Si está seguro del plan que ha elegido presione el botón de <strong>continuar</strong>. En caso
        de que desee volver para elegir otro plan, haga clic en Cerrar y continúe el proceso.</p>
                    <div className="col-12 mgB">
                        <button
                            onClick={() => this.setState({totaltopay: data.totalrequested}, this.onContinue)}
                            className="btn btn-primary w-100">
                Continuar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    ontriesFails = () => {
        let dataClear = {
            cardNumber: '',
            monthValid: '',
            yearValid: '',
            cvv: '',
            owner: '',
            cardtype: null
        };
        this.setState({cardsData: dataClear}, this.cleanData());
    };

    cleanData(){
        this.props.cleanCardTransaction();
        this.props.closeTransaction();
        this.setState({showCardModal: false});
    }


    renderCardModalResponse() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCardModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showCardModal}>
                <div >
                    {this.props.responseData && this.props.responseData.cardResponse.ResponseCode.split('-')[0] !== '00' &&
                        <React.Fragment>
                            <h2 className="text-center">Operación fallida</h2>
                            <div className="mgB color-red fsbold fsize-16 text-center">
                                {(this.props.responseData.cardResponse.ResponseCode.split('-')[0] !== '999') ? <p>Hubo un error al procesar su pago. <br/> Favor de contactarnos.</p> : 'Error en la conexión.'}</div>
                            {(this.props.responseData.cardResponse.ResponseCode.split('-')[0] === '999') &&
                            <div className="mgB fsbold fsize-12 text-center">
                                Intentelo mas tarde</div>
                            }
                            {this.props.fails === 3 &&
                            <div className="mgB fsbold fsize-12 text-center">
                                Ha alcanzado el máximo de intentos</div> }
                            <div className='row'>
                                <div className={` ${((this.props.fails) < 3 && (this.props.responseData.cardResponse.ResponseCode.split('-')[0] !== '999')) ? 'col-6 col-md-6 ' : 'col-12 col-md-12'} mgB text-center`}>
                                    <button
                                        onClick={() => location.replace(this.props.cart.onBack)}
                                        className={`btn btn-primary ${((this.props.fails) < 3 && (this.props.responseData.cardResponse.ResponseCode.split('-')[0] !== '999')) ? 'w-75' : 'w-55'}`}
                                    >
                                Volver a la tienda
                                    </button>
                                </div>
                                {(this.props.fails < 3) && this.props.responseData.cardResponse.ResponseCode.split('-')[0] !== '999' &&
                            <div className="col-6 mgB text-center">
                                <button
                                    onClick={this.ontriesFails}
                                    className="btn btn-primary w-75">
                                 Volver a intentar
                                </button>
                            </div>
                                }
                            </div>
                        </React.Fragment>}
                    {this.props.responseData && this.props.responseData.cardResponse.ResponseCode.split('-')[0] === '00' &&
                        <React.Fragment>
                            <h2 className="text-center">Operación exitosa</h2>
                            <div className="col-12 mgB text-center">Excelente! su tarjeta ha sido aprobada.</div>
                            <div className="col-12 mgB">
                                <button
                                    onClick={() => {
                                        let urlPaid = this.props.cart.data.host+'slirx_electropaycard/index/response?paid='
                                                        +this.props.cart.data.amount+'&order_id='+this.props.cart.data.order_id
                                                        +'&transaction_id='+this.props.cardTransaction
                                                        +'&discount_amount='+this.props.financingForm.discountAmount
                                                        +'&payment_method='+this.props.financingForm.paymentMethod;
                                        location.replace(urlPaid);
                                    }}
                                    className="btn btn-primary w-55">
                                Continuar
                                </button>
                            </div>
                        </React.Fragment>}
                </div>
            </Modal>
        );
    }

    onSubmit = () => {
        if (this.props.plansToCompare.length === 1) {
            this.setState({showPlanConfirmed: true});

            if (this.state.financingData.plan === null) {
                this.onFieldChange('plan', this.props.plansToCompare[0]);
            }

        } else {
            this.onFieldChange('plan', null);
            this.onCompare();
        }
    };
    onContinue = () => {
        this.setState({showPlanConfirmed: false});
        let total = this.state.totaltopay - ((this.state.financingData.plan.financingEntity.optional_delivery === false) ? this.props.cart.deliveryCost : 0);
        let discountA = (this.props.cart.data.amount - total).toFixed(2);
        let paymentMethod = '';
        if (this.state.financingData.plan.financingEntity.type.id === 4) {
            this.props.beginTransaction();
            const dataCard = {
                "MerchantKey": '',
                AccountNumber: this.state.cardsData.cardNumber,
                ExpirationMonth: this.state.cardsData.monthValid,
                ExpirationYear: this.state.cardsData.yearValid,
                CustomerName: this.state.cardsData.owner,
                IsDefault: false,
                AccountType: "1",
                CustomerEmail: this.props.financingForm['email'],
                ZipCode: "33167",
                Amount: total,
                Currency: "840",
                Tax: "0",
                Invoice: this.props.cart.orderId,
                Transaction_Detail: "",
                ReserveFunds: false,
                CVV: this.state.cardsData.cvv,
                IsInstallment: true,
                InstallmentCount: this.state.financingData.plan.payments,
                "storeId": this.props.cart.storeId,
                nameClient: this.props.financingForm['name'] + ' ' + this.props.financingForm['lastname'],
                entityID: this.state.financingData.plan.financingEntity.id
            };
            paymentMethod = 'Cuotas tarjeta con ' + this.state.financingData.plan.financingEntity.name + ' ('+ this.state.financingData.plan.name+')';
            this.props.setDataPaymentDiscounnt({discountAmount:  discountA, paymentMethod: paymentMethod});
            this.props.authorizeCard(dataCard);
        } else {
            let datatemp = {...this.state.temporaryFinancing};
            let newFinancingData = {...this.state.financingData};
            datatemp.plan = this.state.financingData.plan.id;
            datatemp.advancePayment = this.state.financingData.advancePayment;
            //creating optional fields
            let fields ={fields:[]};
            if (this.state.financingData.plan.financingEntity.optional_fields !== null) {
                forEach(this.state.financingData.plan.financingEntity.optional_fields.fields, (elem) => {
                    fields.fields.push({
                        name: elem.name,
                        type: elem.type,
                        belongto: elem.belongto,
                        var: elem.var,
                        value: null,
                        values: elem.values,
                        require: elem.require
                    });
                });
            }
            newFinancingData.optionalFields = fields;

            datatemp = Object.assign({}, this.state.temporaryFinancing, datatemp);
            this.setState({temporaryFinancing: datatemp, financingData: newFinancingData});
            paymentMethod = 'Solicitud financiamiento con ' + this.state.financingData.plan.financingEntity.name + ' ('+ this.state.financingData.plan.name+')';
            this.props.setDataPaymentDiscounnt({discountAmount:  discountA, paymentMethod: paymentMethod});
            setTimeout(() => {
                this.onNext();
            }, 10);
        }
    };

    onCompare = () => {
        this.setState({showComparisonTableModal: true});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    promoToApply = (plan, checked) => {
        if (checked === true ) {
            if (plan.financingEntity.type.id !== 4 ) {
            //find plan in promotions activated
                let promo = {};
                promo = this.props.promotionsActivated.filter((pro) => {
                    return pro.rules.filter((rule) => {
                        return (rule.plans[0] !== '-1' && rule.plans.filter(pl => { return pl.id === plan.id; }).length > 0);
                    }).length > 0;
                });

                if (promo.length > 0) {
                    let plansSelectedinPromo = this.state.plansInPromo;
                    plansSelectedinPromo.push(plan);
                    this.setState({promoPlan: {promotion: promo[0], plan: plan.id, plansInPromo: plansSelectedinPromo}});
                }
            }
        } else {
            let plansSelectedinPromo = this.state.plansInPromo;
            plansSelectedinPromo.splice(plansSelectedinPromo.indexOf(plan), 1);
            this.setState({promoPlan: null, plansInPromo: plansSelectedinPromo});
        }


    };

    render() {
        return (
            <React.Fragment>
                <StepperComponent
                    steps={['Datos del plan', 'Datos personales', 'Datos adicionales', 'Datos laborales']}
                    current={0}
                />
                <div style={{padding: '10px'}} className="bordered mgT2 white-background">
                    <h2 className="text-center mgT">Completa los datos del plan</h2>
                    <p className="text-center">Los campos requeridos están marcados con <span className="color-red">*</span> </p>
                    <div className="pd2 mgB2 mgT">
                        <CartComponent
                            cart={this.props.cart}
                            promo={this.state.promoPlan}
                        />
                    </div>
                    <div className="mgB2">
                        <SelectPlanForm
                            onFieldChange={this.onFieldChange}
                            total={this.state.totaltopay}
                            currency={this.props.cart.currency}
                            canSubmit={this.canSubmit}
                            financingEntityTypes={this.props.financingEntityTypes}
                            setFinancingEntityType={this.props.setFinancingEntityType}
                            plan={this.state.financingData.plan}
                            topay={this.state.totaltopay}
                            plansToCompare={this.props.plansToCompare}
                            selectPlanToCompare={this.props.selectPlanToCompare}
                            errorFields={this.state.errorFields}
                            discount={this.state.discount}
                            financingEntities={this.props.financingEntities}
                            advancePayment={this.state.financingData.advancePayment}
                            currencyId={this.state.financingData.currencyId}
                            financingEntity={this.state.financingData.financingEntity}
                            financingEntityType={this.props.financingEntityType}
                            isMobile={this.props.isMobile}
                            dataClient={{name: this.props.financingForm['name'], lastname: this.props.financingForm['lastname']}}
                            cardSelected={this.props.cart.cardselected}
                            updateCardData={(data) => this.setState({cardsData: data})}
                            cardInit={this.state.cardsData}
                            deliveryCost={this.props.cart.deliveryCost}
                            promotionsActivated={this.props.promotionsActivated}
                            onPromotoApply={this.promoToApply}
                            cart = {this.props.cart}
                        />
                    </div>
                    {this.renderPlanSelected()}
                </div>
                <div className="row">
                    <div className="col-md-4 mgT">
                        <button
                            onClick={() => this.setState({showCancelModal: true})}
                            className="btn btn-outline w-100">
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-4 mgT" />
                    <div className="col-md-4 mgT">
                        <RequiredFieldsWrapper
                            onSubmit={this.onSubmit}
                            validSubmit={this.validSubmit}
                            onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                            errorFields={this.state.errorFields}
                            fields={this.state.financingData}>
                            <button
                                id="submit-button"
                                className={this.validSubmit() ? 'btn btn-primary w-100' :'btn btn-clickable-pseudo-disabled w-100'}>
                                Continuar
                            </button>
                        </RequiredFieldsWrapper>
                    </div>
                </div>
                {this.renderLoginModal()}
                {this.renderCancelModal()}
                {this.renderComparisonTableModal()}
                {this.renderCardModalResponse()}
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StepOneFinancingCheckout);
