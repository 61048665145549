/* ICONS */

// Direction
export const CREVRON_DOWN_ICON = 'mdi mdi-menu-down';
export const CREVRON_LEFT_ICON = 'mdi mdi-menu-left';
export const CREVRON_RIGHT_ICON = 'mdi mdi-menu-right';
export const CREVRON_UP_ICON = 'mdi mdi-menu-up';

export const PAGINATION_LEFT_ICON = 'mdi mdi-chevron-left';
export const PAGINATION_RIGHT_ICON = 'mdi mdi-chevron-right';
export const HIDE_MENU_ICON = 'mdi mdi-chevron-double-left';
export const SHOW_MENU_ICON = 'mdi mdi-chevron-double-right';

export const LOGOUT_ICON = 'mdi mdi-logout';
export const PROFILE_ICON = 'mdi mdi-face-profile';

export const CALENDAR_ICON = 'mdi mdi-calendar-range';

// Password eye icons.
export const EYE_HIDE_ICON = 'mdi mdi-eye';
export const EYE_SHOW_ICON = 'mdi mdi-eye-off';

// Markers icons location.
// NOTE: theses are actually paths to images, and must be used
// as such.
// export const MARKER_BLUE = '/map-marker-blue.svg';
export const MARKER_BLUE = '/images/map-marker-blue.svg';
export const MARKER_RED = '/images/map-marker-red.svg';
export const MARKER_GREEN = '/images/map-marker-green.svg';
export const MARKER_ORANGE = '/images/map-marker-orange.svg';

// Miscellaneous icons.
export const PHONE_ICON = 'mdi mdi-phone';
export const USER_ICON = 'mdi mdi-account';
export const USERS_ICON = 'mdi mdi-account-multiple';
export const PASSWORD_ICON = 'mdi mdi-lock';
export const MAGNIFY_ICON = 'mdi mdi-magnify';

export const CLOSE_ICON = 'mdi mdi-close';
export const PLAY_ICON = 'mdi mdi-play-circle';
export const PENCIL_ICON = 'mdi mdi-pencil';
export const PLUS_CIRCLE_ICON = 'mdi mdi-plus-circle';
export const MINUS_CIRCLE_ICON = 'mdi mdi-minus-circle';

export const CAMERA_ICON = 'mdi mdi-camera';
export const PAUSE_CIRCLE_ICON = 'mdi mdi-pause-circle';
export const CLOSE_CIRCLE_ICON = 'mdi mdi-close-circle';

export const NOTIFICATION_ICON = 'mdi mdi-bell';
export const CART_ICON = 'mdi mdi-cart';

export const LIST_ICON = 'mdi mdi-view-list';
export const DASHBOARD_ICON = 'mdi mdi-view-dashboard';
export const MAP_MARKER_ICON = 'mdi mdi-map-marker';

export const TOKEN_ICON = 'mdi mdi-token';
export const TOKEN_ICON_48 = 'mdi mdi-token mdi-token--48px';
export const STORE_ICON = 'mdi mdi-store';
export const HOME_ICON = 'mdi mdi-home';
export const AGENCIES_ICON = 'mdi mdi-city';
export const GAMEPAD_ICON = 'mdi mdi-gamepad-variant';
export const WALLED_ICON = 'mdi mdi-wallet';
export const TICKET_ICON = 'mdi mdi-ticket';
export const CHART_BAR_ICON = 'mdi mdi-chart-bar';
export const SETTING_ICON = 'mdi mdi-settings';
export const CLIPBOARD_ICON = 'mdi mdi-clipboard-text';
export const HUMAN_ICON = 'mdi mdi-human';
export const PRINTER_ICON = 'mdi mdi-printer';
export const DOWNLOAD_ICON = 'mdi mdi-download';
export const OFFER_ICON = 'mdi mdi-offer';

export const BACK_ICON = 'mdi mdi-keyboard-backspace';

export const STAR_ICON = 'mdi mdi-star';
export const STAR_HALF_ICON = 'mdi mdi-star-half';
export const STAR_OUTLINE_ICON = 'mdi mdi-star-outline';

export const ALERT_ICON = 'mdi mdi-alert';
export const MARKED_CHECKBOX_ICON = 'mdi mdi-checkbox-marked-circle';
export const UNMARKED_CHECKBOX_ICON = 'mdi mdi-checkbox-blank-circle-outline';

export const SQUARED_MARKED_CHECKBOX_ICON = 'mdi mdi-checkbox-marked';

export const UNMARK_ICON = 'mdi mdi-do-not-disturb';

export const ACCOUNT_ICON = 'mdi mdi-account';

export const MESSAGE_TEXT_ICON = 'mdi mdi-message-text';

export const CURRENCY_ICON = 'mdi mdi-currency-usd';

export const CONTACTS_ICON = 'mdi mdi-contacts';

export const OPPORTUNITIES_ICON = 'mdi mdi-navigation';

export const RESOURCES_ICON = 'mdi mdi-book';

export const ACCOUNT_BOX_ICON = 'mdi mdi-account-box';

export const GRID_ICON = 'mdi mdi-view-grid';

export const CAKE_ICON = 'mdi mdi-cake-variant';

export const WORLD_ICON = 'mdi mdi-earth';

export const LINK_ICON = 'mdi mdi-link';

export const COPY_ICON = 'mdi mdi-content-copy';

export const DELETE_ICON = 'mdi mdi-delete';

export const SOCIAL_NETWORKS_ICONS = {
    1: 'mdi mdi-facebook',
    2: 'mdi mdi-twitter',
    3: 'mdi mdi-google-plus',
    4: 'mdi mdi-instagram'
};

export const ADD_USER_ICON = 'mdi mdi-account-plus';

export const LOCK_ICON = 'mdi mdi-lock';

export const ARROW_DOWN = 'mdi mdi-arrow-down';
export const ARROW_RIGHT = 'mdi mdi-arrow-right-circle';
export const ARROW_LEFT = 'mdi mdi-arrow-left-circle';

export const FILTERS_ICON = 'mdi mdi-filter-variant';

export const PENDING_ICON = 'mdi mdi-bell-plus';

export const SYSTEM_ICON = 'mdi mdi-vector-triangle';

export const MEMBERSHIP_ICON = 'mdi mdi-wallet-membership';

export const RECEIPT_ICON = 'mdi mdi-receipt';

export const CHECK_ICON = 'mdi mdi-check';

export const EMAIL_ICON = 'mdi mdi-email';

export const DUMBBELL_ICON = 'mdi mdi-dumbbell';

export const CHART_LINE_ICON = 'mdi mdi-chart-line';

export const BANK_ICON = 'mdi mdi-bank';

export const CASH_ICON = 'mdi mdi-cash-usd-outline';

export const CARD_ICON = 'mdi mdi-credit-card';

export const CANCEL_ICON = 'mdi mdi-cancel';

export const MOBILE_ICON = 'mdi mdi-cellphone';

export const CIRCLE_ICON = 'mdi mdi-checkbox-blank-circle';

export const CIRCLE_OUTLINE_ICON = 'mdi mdi-checkbox-blank-circle-outline';

export const ADD_IMAGE_ICON = 'mdi mdi-image-plus';

export const MS_WORD_ICON = 'mdi mdi-file-word';

export const COMMENT_ICON = 'mdi mdi-comment-outline';
