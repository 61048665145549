'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import {
    SearchInput,
    SelectInput,
    DateRangePicker
} from 'common/components/inputs';

import {
    USERTYPES,
    FINANCING_STATUSES
} from 'common/constants/enums.constant';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {dropdowns: userSelectors.getDropdowns(state)};
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        }
    };
};

class FinancingsListHeader extends React.PureComponent {

    onDateChanged = (value) => {
        let filters = Object.assign({}, this.props.filters);
        filters['from'] = value.startDate;
        filters['to'] = value.endDate;
        this.props.setFilters(filters);
    };

    componentDidMount() {
        if (this.props.dropdowns.financingEntities.length === 0) {
            this.props.getDropdowns();
        }
    }

    onClearFilters = () => {

        let dateClean = {
            startDate: '',
            endDate: ''
        };
        this.onDateChanged(dateClean);
        let datesel = document.getElementById('dateselect');
        datesel.value ='';
        this.props.clearFilters();
    };

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB ">
                <div className="col-md-8 col-12">
                    <h2 className="section-headline mgT2">Financiamientos solicitados ({this.props.total})</h2>
                </div>
                {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                 <div className="col-md-4 col-12 text-right" style={{paddingRight: '20px'}}>
                     {this.props.authUser.userType.id === USERTYPES.ADMIN && 
                     <button
                        className="btn btn-primary" style={{marginRight: '5px'}}
                        onClick={this.props.generaExcel} >
                    Export XLS</button>}
                     <button
                         className="btn btn-primary"
                         onClick={this.onClearFilters}>
                    Limpiar filtros</button>
                 </div>}
            </div>
        );
    }

    renderAdminFilters() {
        return (
            <div className="row">
                <div className={`${this.props.tab === 2 ? 'col-md-3' : 'col-md-5'} mgB`}>
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        ActiveFilter={this.props.filters.q !== '' ? 'filter-active' : ''}
                        value={this.props.filters.q}
                        label="Nombre del cliente, teléfono o código"
                        onChange={(val) => this.props.onFiltersChange('q', val)}
                    />
                </div>
                <div className={`${this.props.tab === 2 ? 'col-md-2' : 'col-md-2'} mgB`}>
                    <SelectInput
                        value={this.props.filters.store}
                        floatingLabel="Tienda"
                        classNamePrefix={this.props.filters.store !== null ? 'filterDrop' : ''}
                        withAllOption="Todas"
                        options={this.props.dropdowns.stores}
                        onChange={(val) => this.props.onFiltersChange(['store', 'page'], [val, 1])}
                    />
                </div>
                <div className={`${this.props.tab === 2 ? 'col-md-2' : 'col-md-2'} mgB`}>
                    <SelectInput
                        value={this.props.filters.financingEntity}
                        floatingLabel="Entidad"
                        classNamePrefix={this.props.filters.financingEntity !== null ? 'filterDrop' : ''}
                        withAllOption="Todas"
                        options={this.props.dropdowns.financingEntities}
                        onChange={(val) => this.props.onFiltersChange(['financingEntity', 'page'], [val, 1])}
                    />
                </div>
                {this.props.tab === 2 &&
                    <div className="col-md-2">
                        <SelectInput
                            value={this.props.filters.status.length === 1 ? this.props.filters.status[0] : null}
                            floatingLabel="Estado"
                            classNamePrefix={(this.props.filters.status.length === 1 ? this.props.filters.status[0] : null) !== null ? 'filterDrop' : ''}
                            withAllOption="Todos"
                            options={[
                                {value: FINANCING_STATUSES.APPROVED, label: 'Aprobadas'},
                                {value: FINANCING_STATUSES.APPROVED_BY_ENTITY, label: 'Aprobadas por la entidad'},
                                {value: FINANCING_STATUSES.DECLINED, label: 'No proceden'},
                                {value: 70, label: 'Canceladas'},
                                {value: 71, label: 'Canceladas post aprobada'},
                                {value: FINANCING_STATUSES.SIGNED, label: 'Firmadas por el cliente'},
                                {value: FINANCING_STATUSES.SOLD_PRODUCT, label: 'Mercancía Entregada'}
                            ]}
                            onChange={(val) => this.props.onFiltersChange(['status', 'page'], [val !== null ? [val] : [FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT], 1])}
                        />
                    </div>
                }
                <div className="col-md-3">
                    <DateRangePicker
                        id="dateselect"
                        label="Fecha"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        withIcon={true}
                        className="full-width"
                        onChange={this.onDateChanged}
                        from={this.props.filters.from}
                        to={this.props.filters.to}
                    />
                </div>
            </div>
        );
    }

    renderEmployeeFilters() {
        return (
            <div className="row">
                <div className={`${this.props.tab === 2 ? 'col-md-4' : 'col-md-6'} mgB`}>
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        ActiveFilter={this.props.filters.q !== '' ? 'filter-active' : ''}
                        value={this.props.filters.q}
                        label="Nombre del cliente, teléfono o código"
                        onChange={(val) => this.props.onFiltersChange('q', val)}
                    />
                </div>
                <div className={`${this.props.tab === 2 ? 'col-md-3' : 'col-md-3'} mgB`}>
                    <SelectInput
                        value={this.props.filters.financingEntity}
                        floatingLabel="Entidad"
                        withAllOption="Todas"
                        options={this.props.dropdowns.financingEntities}
                        onChange={(val) => this.props.onFiltersChange(['financingEntity', 'page'], [val, 1])}
                    />
                </div>
                {this.props.tab === 2 &&
                    <div className="col-md-2">
                        <SelectInput
                            value={this.props.filters.status.length === 1 ? this.props.filters.status[0] : null}
                            floatingLabel="Estado"
                            withAllOption="Todos"
                            options={[
                                {value: FINANCING_STATUSES.APPROVED, label: 'Aprobadas'},
                                {value: FINANCING_STATUSES.APPROVED_BY_ENTITY, label: 'Aprobadas por la entidad'},
                                {value: FINANCING_STATUSES.DECLINED, label: 'No Aprobadas'},
                                {value: FINANCING_STATUSES.CANCELED, label: 'Canceladas'},
                                {value: FINANCING_STATUSES.SIGNED, label: 'Firmadas por el cliente'},
                                {value: FINANCING_STATUSES.SOLD_PRODUCT, label: 'Mercancía Entregada'}

                            ]}
                            onChange={(val) => this.props.onFiltersChange(['status', 'page'], [val !== null ? [val] : [FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT], 1])}
                        />
                    </div>
                }
                <div className="col-md-3">
                    <DateRangePicker
                        label="Fecha"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        withIcon={true}
                        className="full-width"
                        onChange={this.onDateChanged}
                        from={this.props.filters.from}
                        to={this.props.filters.to}
                    />
                </div>
            </div>
        );
    }

    renderAgentFilters() {
        return (
            <div className="row">
                <div className={`${this.props.tab === 2 ? 'col-md-6' : 'col-md-7'} mgB`}>
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        ActiveFilter={this.props.filters.q !== '' ? 'filter-active' : ''}
                        value={this.props.filters.q}
                        label="Nombre del cliente, teléfono o código"
                        onChange={(val) => this.props.onFiltersChange(['q', 'page'], [val, 1])}
                    />
                </div>
                {this.props.tab === 2 &&
                    <div className="col-md-2">
                        <SelectInput
                            value={this.props.filters.status.length === 1 ? this.props.filters.status[0] : null}
                            floatingLabel="Estado"
                            withAllOption="Todos"
                            options={[
                                {value: FINANCING_STATUSES.APPROVED, label: 'Aprobadas'},
                                {value: FINANCING_STATUSES.APPROVED_BY_ENTITY, label: 'Aprobadas por la entidad'},
                                {value: FINANCING_STATUSES.DECLINED, label: 'No Aprobadas'},
                                {value: FINANCING_STATUSES.CANCELED, label: 'Canceladas'},
                                {value: FINANCING_STATUSES.SIGNED, label: 'Firmadas por el cliente'},
                                {value: FINANCING_STATUSES.SOLD_PRODUCT, label: 'Mercancía Entregada'}
                            ]}
                            onChange={(val) => this.props.onFiltersChange(['status', 'page'], [val !== null ? [val] : [FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT], 1])}
                        />
                    </div>
                }
                <div className={`${this.props.tab === 2 ? 'col-md-4' : 'col-md-5'} mgB`}>
                    <DateRangePicker
                        label="Fecha"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        withIcon={true}
                        className="full-width"
                        onChange={this.onDateChanged}
                        from={this.props.filters.from}
                        to={this.props.filters.to}
                    />
                </div>
            </div>
        );
    }

    renderClientFilters() {
        return (
            <div className="row">
                <div className="col-md-7 mgB">
                    <SelectInput
                        value={this.props.filters.financingEntity}
                        floatingLabel="Entidad"
                        withAllOption="Todas"
                        options={this.props.dropdowns.financingEntities}
                        onChange={(val) => this.props.onFiltersChange('financingEntity', val)}
                    />
                </div>
                <div className="col-md-5 mgB">
                    <DateRangePicker
                        label="Fecha"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        withIcon={true}
                        className="full-width"
                        onChange={this.onDateChanged}
                        from={this.props.filters.from}
                        to={this.props.filters.to}
                    />
                </div>
            </div>
        );
    }

    renderFilters() {
        return (
            <div>
                {this.props.authUser.userType.id === USERTYPES.ADMIN && this.renderAdminFilters()}
                {this.props.authUser.userType.id === USERTYPES.EMPLOYEE && this.renderEmployeeFilters()}
                {this.props.authUser.userType.id === USERTYPES.AGENT && this.renderAgentFilters()}
                {this.props.authUser.userType.id === USERTYPES.CLIENT && this.renderClientFilters()}
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
                <hr style={{left: '0px', top: '180px'}} className="w-100"/>
            </div>
        );
    }
}

/**
 * Properties validations.
 */
FinancingsListHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    filters: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    onFiltersChange: PropTypes.func.isRequired
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingsListHeader);
