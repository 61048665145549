import 'rxjs';
import 'rxjs/add/observable/of';
import { of } from 'rxjs/observable/of';
import {
    mergeMap,
    catchError,
    map,
    takeUntil
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionActionsLabels from 'common/store/actions/transaction/transaction.enum';
import * as transactionRequests from 'common/store/services/transaction.service';

export const verifyOrderTokenEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.VERIFY_ORDER_TOKEN),
        mergeMap(action =>
            transactionRequests.verifyOrderTokenRequest(action.payload).pipe(
                map((result) => transactionActions.verifyOrderTokenSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.verifyOrderTokenFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.verifyOrderTokenFailure(error.response)
                    );
                })
            )
        )
    );

export const getAllEntitiesEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_ALL_ENTITIES),
        mergeMap(action =>
            transactionRequests.getAllEntitiesRequest(action.payload).pipe(
                map((result) => transactionActions.getAllEntitiesSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getAllEntitiesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getAllEntitiesFailure(error.response)
                    );
                })
            )
        )
    );

export const getAllFinancingEntityTypesEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES),
        mergeMap(() =>
            transactionRequests.getAllFinancingEntityTypesRequest().pipe(
                map((result) => transactionActions.getAllFinancingEntityTypesSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getAllFinancingEntityTypesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getAllFinancingEntityTypesFailure(error.response)
                    );
                })
            )
        )
    );

export const createFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CREATE_FINANCING),
        mergeMap(action =>
            transactionRequests.createFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.createFinancingSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.createFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.createFinancingFailure(error.response)
                    );
                })
            )
        )
    );


export const getFinancingsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_FINANCINGS),
        mergeMap(action =>
            transactionRequests.getFinancingsRequest(action.payload).pipe(
                map((result) => transactionActions.getFinancingsSuccess(result)),
                takeUntil(action$.pipe(
                    ofType(transactionActionsLabels.CANCEL_GET_FINANCINGS)
                )),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getFinancingsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getFinancingsFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_FINANCING),
        mergeMap(action =>
            transactionRequests.getFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.getFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const getDashboardEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_DASHBOARD),
        mergeMap(action =>
            transactionRequests.getDashboardRequest(action.payload).pipe(
                map((result) => transactionActions.getDashboardSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getDashboardFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getDashboardFailure(error.response)
                    );
                })
            )
        )
    );

export const cancelFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CANCEL_FINANCING),
        mergeMap(action =>
            transactionRequests.cancelFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.cancelFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.cancelFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.cancelFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const approveFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.APPROVE_FINANCING),
        mergeMap(action =>
            transactionRequests.approveFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.approveFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.approveFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.approveFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const signFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.SIGN_FINANCING),
        mergeMap(action =>
            transactionRequests.signFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.signFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.signFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.signFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const resendFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.RESEND_FINANCING),
        mergeMap(action =>
            transactionRequests.resendFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.resendFinancingSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.resendFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.resendFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const sellConfirmFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.SELL_CONFIRM),
        mergeMap(action =>
            transactionRequests.sellConfirmFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.sellConfirmFinancingSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.sellConfirmFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.sellConfirmFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const changeFinancingStatusEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CHANGE_FINANCING_STATUS),
        mergeMap(action =>
            transactionRequests.changeFinancingStatusRequest(action.payload).pipe(
                map((result) => transactionActions.changeFinancingStatusSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.changeFinancingStatusFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.changeFinancingStatusFailure(error.response)
                    );
                })
            )
        )
    );

export const declineFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.DECLINE_FINANCING),
        mergeMap(action =>
            transactionRequests.declineFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.declineFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.declineFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.declineFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingActionsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_FINANCING_ACTIONS),
        mergeMap(action =>
            transactionRequests.getFinancingActionsRequest(action.payload).pipe(
                map((result) => transactionActions.getFinancingActionsSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getFinancingActionsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getFinancingActionsFailure(error.response)
                    );
                })
            )
        )
    );

export const addCommentEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.ADD_COMMENT),
        mergeMap(action =>
            transactionRequests.addCommentRequest(action.payload).pipe(
                map((result) => transactionActions.addCommentSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.addCommentFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.addCommentFailure(error.response)
                    );
                })
            )
        )
    );


export const updateFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.UPDATE_FINANCING),
        mergeMap(action =>
            transactionRequests.updateFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.updateFinancingSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.updateFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.updateFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const uploadQuoteEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.UPLOAD_QUOTE),
        mergeMap(action =>
            transactionRequests.uploadQuoteRequest(action.payload).pipe(
                map((result) => transactionActions.uploadQuoteSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.uploadQuoteFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.uploadQuoteFailure(error.response)
                    );
                })
            )
        )
    );

export const uploadDocumentEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.UPLOAD_DOCUMENT),
        mergeMap(action =>
            transactionRequests.uploadDocumentRequest(action.payload).pipe(
                map((result) => transactionActions.uploadDocumentSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.uploadDocumentFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.uploadDocumentFailure(error.response)
                    );
                })
            )
        )
    );

export const removeDocumentEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.REMOVE_DOCUMENT),
        mergeMap(action =>
            transactionRequests.removeDocumentRequest(action.payload).pipe(
                map((result) => transactionActions.removeDocumentSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.removeDocumentFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.removeDocumentFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingsTotalsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_FINANCINGS_TOTAL),
        mergeMap((action) =>
            transactionRequests.getFinancingsTotalsRequest(action.payload).pipe(
                map((result) => transactionActions.getFinancingsTotalSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getFinancingsTotalFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getFinancingsTotalFailure(error.response)
                    );
                })
            )
        )
    );

export const createTempFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CREATE_TEMP_FINANCING),
        mergeMap(action =>
            transactionRequests.createTempFinancingRequest(action.payload).pipe(
                map(() => transactionActions.createTempFinancingSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.createTempFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.createTempFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const createFinancingFromTempEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CREATE_FINANCING_FROM_TEMP),
        mergeMap(action =>
            transactionRequests.createFinancingFromNotCompletedRequest(action.payload).pipe(
                map((result) => transactionActions.createFinancingFromTempSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.createFinancingFromTempFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.createFinancingFromTempFailure(error.response)
                    );
                })
            )
        )
    );

export const getTempFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.GET_TEMP_FINANCING),
        mergeMap(action =>
            transactionRequests.getTempFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.getTempFinancingSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.getTempFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.getTempFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const delTempFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.DELETE_TEMP_FINANCING),
        mergeMap(action =>
            transactionRequests.deleteTempFinancingRequest(action.payload).pipe(
                map(() => transactionActions.delTempFinancingSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.delTempFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.delTempFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const undoApproveFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.UNDO_APPROVE_FINANCING),
        mergeMap(action =>
            transactionRequests.undoApproveFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.undoApproveFinancingSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.undoApproveFinancingFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.undoApproveFinancingFailure(error.response)
                    );
                })
            )
        )
    );

export const createCardFinancingEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CREATE_CARD_FINANCING),
        mergeMap(action =>
            transactionRequests.createCardFinancingRequest(action.payload).pipe(
                map((result) => transactionActions.createCardFinancigSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.createCardFinancigFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.createCardFinancigFailure(error.response)
                    );
                })
            )
        )
    );

export const getCardTransactionsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.SEARCH_CARD_FINANCING),
        mergeMap(action =>
            transactionRequests.getCardTransactionsRequest(action.payload).pipe(
                map((result) => transactionActions.searchCardFinancigSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.searchCardFinancigFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.searchCardFinancigFailure(error.response)
                    );
                })
            )
        )
    );

export const assignBranchFinancingTransactionsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.ASSIGN_BRANCH_FINANCING),
        mergeMap(action =>
            transactionRequests.assignBranchRequest(action.payload).pipe(
                map((result) => transactionActions.assignBranchFinancigSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.assignBranchFinancigFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.assignBranchFinancigFailure(error.response)
                    );
                })
            )
        )
    );

export const cancelCheckoutTransactionsEpic = (action$) =>
    action$.pipe(
        ofType(transactionActionsLabels.CANCEL_CHECKOUT),
        mergeMap(action =>
            transactionRequests.cancelCheckoutRequest(action.payload).pipe(
                map(() => transactionActions.cancelCheckoutSuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            transactionActions.cancelCheckoutFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        transactionActions.cancelCheckoutFailure(error.response)
                    );
                })
            )
        )
    );