import Color from './color.constant';

/**
 * Creates a dataset given the labels and data attributes.
 *
 * @param {array[number]} data           the data that the dataset will contain.
 * @param {array[string]} labels         the labels to display.
 * @param {object}        defaultDataset the default dataset model.
 */
export const createDataset = (data, labels) => {
    let datasets = [];

    let colors = Color.random(labels.length);

    let newData = [];
    let borderColor = [];
    let backgroundColor = [];

    data[0].data.forEach((value, i) => {
        if (value > 0) {
            borderColor.push(colors[i]);
            backgroundColor.push(colors[i]);
            newData.push(value);
        }

    });

    datasets.push({
        data: newData,
        backgroundColor: backgroundColor,
        borderColor: borderColor
    });

    return {
        datasets,
        labels
    };
};
