import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { CALENDAR_ICON } from 'common/constants/icon.constant';
import {TextInput} from 'common/components/inputs';



export default class CardDatePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showPanel: false,
            value: this.props.value,
            year: moment().year(),
            monthSel: null
        };

    }

    onChangeYear(val) {
        let newYear = this.state.year;
        if (val < 0) {
            newYear = (this.state.year > moment().year()) ? (this.state.year + val) : this.state.year;
        } else {
            newYear += 1;
        }
        this.setState({year: newYear});
        this.selectMonth(13);
    }

    selectMonth(ind) {
        let monthSel = document.getElementsByClassName('monthSelected');
        if (monthSel.length > 0) {
            monthSel[0].classList.remove('monthSelected');
        }
        let neMonthSel = document.getElementById('month_'+ind);
        neMonthSel.classList.add('monthSelected');

        this.setState({monthSel: (ind !== 13) ? ind + 1 : null});
    }

    onChangeDate() {
        let dateValid = this.state.year.toString().substring(2);
        if (this.state.monthSel !== null) {
            dateValid = (this.state.monthSel < 10 ? '0'+ this.state.monthSel.toString() : this.state.monthSel.toString()) +'/'+ dateValid;
            this.setState({showPanel: false, value: dateValid}, this.props.onChange(dateValid));
        }
    }

    render() {
        moment.locale('es');
        let months = moment.months();
        return (
            <div>
                <label className="form-label">
                    <TextInput
                        placeholder={this.props.placeholder}
                        label={this.props.label}
                        dangerLabel={this.props.dangerLabel}
                        maxLength={5}
                        value={this.props.value}
                        onlyNumbers={true}
                        numbers={true}
                        onFocus={() => this.setState({showPanel: true})}
                    />

                    <span
                        style={{top: this.props.label ? '27px' : '0px'}}
                        onClick={() => this.setState({showPanel: true})}
                        className="calendar--icon">
                        <i className={ CALENDAR_ICON } />
                    </span>
                </label>
                {this.state.showPanel &&
                <div className='panel'>
                    <div className='row text-center pdL pdR3'>
                        <div className='col-4 '>
                            <a
                                disabled={this.state.year === moment().year()}
                                className='btn btn-transparent fsbold fsize-14'
                                onClick={() => this.onChangeYear(-1)}>{'<<'}</a>
                        </div>
                        <div className='col-4' style={{marginTop:'5px'}}>{this.state.year}</div>
                        <div className='col-4'>
                            <a
                                className='btn btn-transparent fsbold fsize-14'
                                onClick={() => this.onChangeYear(1)}>{'>>'}</a>
                        </div>
                    </div>
                    <div className='row pdL pdR'>
                        {months.map((m, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div key={`month_${index}`}
                                        className='col-3 cursor-pointer monthcell'
                                        id={`month_${index}`}
                                        onClick={() => this.selectMonth(index)}>
                                        {m.substring(0, 3)}
                                    </div>
                                </React.Fragment>);
                        })}
                    </div>
                    <div className='row mgT'>
                        <div className='col-8'></div>
                        <div className='col-4 pdR3'>
                            <a
                                className='btn btn-link mgB'
                                onClick={() => this.onChangeDate() }
                            >OK</a></div>
                    </div>
                </div>}
            </div>
        );
    }
}

CardDatePicker.defaultProps = {
    labelClassName: ''
};

/**
 * Text input properties validation.
 */
CardDatePicker.propTypes = {
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    labelStyle: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    dangerLabel: PropTypes.bool
};