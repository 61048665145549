import 'rxjs';
import 'rxjs/add/observable/of';
import { of } from 'rxjs/observable/of';
import {
    mergeMap,
    catchError,
    map
} from 'rxjs/operators';
import { ofType } from 'redux-observable';


import * as apicardActions from '../actions/card/card.actions';
import * as apicardActionsLabels from '../actions/card/card.enum';
import * as apicardRequests from '../services/card.service';



export const authorizeCardEpic = (action$) =>
    action$.pipe(
        ofType(apicardActionsLabels.AUTHORIZE_CARD),
        mergeMap(action =>
            apicardRequests.authorizeCard(action.payload).pipe(
                map(result => apicardActions.authorizeCardSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            apicardActions.authorizeCardFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        apicardActions.authorizeCardFailure(error.response)
                    );
                })
            )

        )
    );

export const registerTokensEpic = (action$) =>
    action$.pipe(
        ofType(apicardActionsLabels.REGISTER_CUSTOMER_TOKENS),
        mergeMap(action =>
            apicardRequests.registerTokens(action.payload.data, action.payload.token).pipe(
                map(result => apicardActions.registerTokenSuccess(result.response)),
                catchError(error => {
                    return of(
                        apicardActions.registerTokenFailure(error.response)
                    );
                })
            )

        )
    );

