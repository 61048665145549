import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    Radio,
    TextInput,
    SelectInput,
    CustomMaskedInput
} from 'common/components/inputs';

import {IDENTIFICATION_TYPES} from 'common/constants/enums.constant';

/**
* Component responsible of the forgot password form.
*/
export default class AditionalDataForm extends React.Component {
    /**
  * Initializes the form.
  */
    constructor(props) {
        super(props);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);

        this.optionalFieldHere = [];
    }

    /**
  * Fired after formsy validations
  */
    canSubmit(canSubmit) {
        this.props.canSubmit(canSubmit);
    }

    renderSpouseFields() {
        return (
            <React.Fragment>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-8 mgB2"
                    name="spouseName"
                    errorClassName={this.props.errorFields.spouseName.errorClass}
                    value={this.props.data.spouseName}>
                    <TextInput
                        label={<span>Nombre completo del cónyuge <span className="color-red">*</span></span>}
                        numbers={false}
                        dangerLabel={this.props.errorFields.spouseName.dangerLabel}
                        placeholder="Inserte el nombre"
                        value={this.props.data.spouseName}
                        onChange={(val) => this.props.onFieldChange('spouseName', val)}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-4 mgB2"
                    name="spousePhone"
                    validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                    errorClassName={this.props.errorFields.spousePhone.errorClass}
                    validationError = "Por favor ingrese un número de telefono válido."
                    value={this.props.data.spousePhone}>
                    <TextInput
                        label={<span>Teléfono del cónyuge <span className="color-red">*</span></span>}
                        dangerLabel={this.props.errorFields.spousePhone.dangerLabel}
                        value={this.props.data.spousePhone}
                        onChange={(val) => this.props.onFieldChange('spousePhone', val)}
                        maxLength={10}
                        numbers={true}
                        onlyNumbers={true}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    className="col-12 col-md-4 mgB2"
                    name="spouseIdentificationType"
                    errorClassName={this.props.errorFields.spouseIdentificationType.errorClass}>
                    <Radio
                        selectedValue={this.props.data.spouseIdentificationType}
                        radioStyle={{ padding: '0px' }}
                        optionsClassName="bordered mgR"
                        className="d-flex"
                        labelStyle={{paddingRight: '5px'}}
                        inputStyle={{height: '44px', padding: '7px'}}
                        onChange={(val) => this.props.onFieldChange('spouseIdentificationType', val)}
                        label="Doc. de identidad del cónyuge"
                        dangerLabel={this.props.errorFields.spouseIdentificationType.dangerLabel}
                        options={this.props.dropdowns.identificationTypes.map(element => {
                            return {
                                value: element.value,
                                label: element.label
                            };
                        })}
                    />
                </FormsyInputWrapper>
                {this.props.data.spouseIdentificationType === IDENTIFICATION_TYPES.CEDULA &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB"
                        name="spouseIdentification"
                        errorClassName={this.props.errorFields.spouseIdentification.errorClass}
                        value={this.props.data.spouseIdentification}
                        validationError='Ingrese una cédula válida.'
                        validations={this.props.data.spouseIdentification !== '' ? 'isValidIdentification' : null}>

                        <TextInput
                            label={<span>No. Documento de identidad <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.spouseIdentification.dangerLabel}
                            value={this.props.data.spouseIdentification}
                            onChange={(val) => this.props.onFieldChange('spouseIdentification', val)}
                            maxLength={11}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                }
                {this.props.data.spouseIdentificationType === IDENTIFICATION_TYPES.PASSPORT &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB2"
                        errorClassName={this.props.errorFields.spouseIdentification.errorClass}
                        value={this.props.data.spouseIdentification}
                        name="spouseIdentification">
                        <TextInput
                            dangerLabel={this.props.errorFields.spouseIdentification.dangerLabel}
                            value={this.props.data.spouseIdentification}
                            label="No. Documento de identidad"
                            onChange={(val) => this.props.onFieldChange('spouseIdentification', val)}
                        />
                    </FormsyInputWrapper>
                }
            </React.Fragment>
        );
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="isMarried"
                        errorClassName={this.props.errorFields.isMarried.errorClass}
                        value={this.props.data.isMarried !== null && this.props.data.isMarried !== undefined}
                        validations="isTrue">
                        <Radio
                            selectedValue={this.props.data.isMarried}
                            radioStyle={{ padding: '0px' }}
                            optionsClassName="bordered mgR"
                            className="d-flex"
                            labelStyle={{paddingRight: '5px'}}
                            inputStyle={{height: '44px', padding: '7px'}}
                            onChange={(val) => this.props.onFieldChange('isMarried', val)}
                            label={<span>Estado civil <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.isMarried.dangerLabel}
                            options={[
                                {value: false, label: 'Soltero/a'},
                                {value: true, label: 'Casado/a'}
                            ]}
                        />
                    </FormsyInputWrapper>
                    {this.props.data.isMarried &&
                        this.renderSpouseFields()
                    }
                    <h3 className="mgT col-12">
                        <strong>Datos de familiar</strong>
                    </h3>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-8 mgB2"
                        name="relativeName"
                        errorClassName={this.props.errorFields.relativeName.errorClass}
                        value={this.props.data.relativeName}>
                        <TextInput
                            label={<span>Nombre completo de un familiar <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.props.errorFields.relativeName.dangerLabel}
                            placeholder="Inserte el nombre"
                            value={this.props.data.relativeName}
                            onChange={(val) => this.props.onFieldChange('relativeName', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="relativePhone"
                        validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                        errorClassName={this.props.errorFields.relativePhone.errorClass}
                        validationError = "Por favor ingrese un número de telefono válido."
                        value={this.props.data.relativePhone}>

                        <TextInput
                            label={<span>Teléfono del familiar <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.relativePhone.dangerLabel}
                            value={this.props.data.relativePhone}
                            onChange={(val) => this.props.onFieldChange('relativePhone', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>

                    <h3 className="mgT col-12">
                        <strong>De la Vivienda</strong>
                    </h3>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="livingType"
                        errorClassName={this.props.errorFields.livingType.errorClass}
                        value={this.props.data.livingType !== null && this.props.data.livingType !== undefined}
                        validations="isTrue">
                        <Radio
                            selectedValue={this.props.data.livingType}
                            radioStyle={{ padding: '0px' }}
                            optionsClassName="bordered mgR"
                            className="d-flex"
                            labelStyle={{paddingRight: '5px'}}
                            inputStyle={{height: '44px', padding: '7px'}}
                            onChange={(val) => this.props.onFieldChange('livingType', val)}
                            label={<span>Tipo de vivienda <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.livingType.dangerLabel}
                            options={this.props.dropdowns.livingTypes.map(element => {
                                return {
                                    value: element.value,
                                    label: element.label
                                };
                            })}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="livingTime"
                        errorClassName={this.props.errorFields.livingTime.errorClass}
                        value={this.props.data.livingTime !== null && this.props.data.livingTime !== undefined}
                        validations="isTrue">
                        <SelectInput
                            floatingLabel={<span>Tiempo en la vivienda <span className="color-red">*</span></span>}
                            key={'userData.livingTime'}
                            dangerLabel={this.props.errorFields.livingTime.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.livingTimes}
                            value={this.props.data.livingTime}
                            onChange={(val) => this.props.onFieldChange('livingTime', val)}
                        />
                    </FormsyInputWrapper>

                    {this.props.data.plan.financingEntity.optional_fields!== null &&
                    this.props.data.plan.financingEntity.optional_fields.fields.length > 0 &&
                    this.props.data.plan.financingEntity.optional_fields.fields.filter((elem) => {
                        return elem.belongto === 2;
                    }).length > 0 &&
                    <React.Fragment>
                        <h3 className="mgT col-12">
                            <strong>Otros datos de interes</strong>
                        </h3>
                        {this.props.data.plan.financingEntity.optional_fields.fields.filter((elem) => {
                            return elem.belongto === 2;
                        }).map((data) => {
                            if (data.require === false) {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <div className='col-md-6 col-12'>
                                            <TextInput
                                                label={<span>{data.name} </span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </div>
                                    );
                                case 2:
                                    return (
                                        <div className='col-md-6 col-12'>
                                            <TextInput
                                                label={<span>{data.name} </span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumber={true}
                                            />
                                        </div>);
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                        >
                                            <TextInput
                                                label={<span>{data.name}</span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>);
                                case 5:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/(3?[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|1?[012])\/(19|20)[0-9]{2}/}}
                                            value={this.getValue(data)}>
                                            <CustomMaskedInput
                                                label={<span>{data.name}</span>}
                                                placeholder='DD/MM/YYYY'
                                                mask="11/11/1111"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 6:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <SelectInput
                                                floatingLabel={<span>{data.name}</span>}
                                                key={'optionalfieldType'}
                                                label="Seleccionar"
                                                searchable={false}
                                                options={data.values.split(',').map(element => {
                                                    return {
                                                        value: element,
                                                        label: element
                                                    };
                                                })}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                default:
                                    return '';
                                }
                            } else {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]|[a-z]|[A-Z]/}}
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name} <span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 2:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]/}}
                                            validationError = "Por favor solo números."
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                        >
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 5:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/(3?[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|1?[012])\/(19|20)[0-9]{2}/}}
                                            required
                                            value={this.getValue(data)}>
                                            <CustomMaskedInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                placeholder='DD/MM/YYYY'
                                                mask="11/11/1111"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 6:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={this.getValue(data) !== null}
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <SelectInput
                                                floatingLabel={<span>{data.name}<span className="color-red">*</span></span>}
                                                key={'optionalfieldType'}
                                                label="Seleccionar"
                                                searchable={false}
                                                options={data.values.split(',').map(element => {
                                                    return {
                                                        value: element,
                                                        label: element
                                                    };
                                                })}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                default:
                                    return '';
                                }
                            }
                        })
                        }
                    </React.Fragment>}
                </div>
            </Formsy>
        );
    }

    getValue = (val) => {
        let valout = this.props.data.optionalFields.fields.filter(el => {
            return (el.name === val.name && el.type === val.type && el.belongto === val.belongto);
        })[0].value;
        return valout;
    };
}

/**
 * Forgot password form properties validation.
 */
AditionalDataForm.propTypes = {
    dropdowns: PropTypes.object,
    data: PropTypes.object,
    errorFields: PropTypes.object,
    onFieldChange: PropTypes.func.isRequired
};
