import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import $ from 'jquery';

import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitySelectors from 'common/store/selectors/financing-entity.selector';

import {
    FormsyInputWrapper,
    TextInput,
    SelectInput
} from 'common/components/inputs';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            listProvinces: financingEntitySelectors.getProvincesList(state),
            listCities: financingEntitySelectors.getCitiesList(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadAllDrops: () => {
            dispatch(financingEntityActions.getProvinceCities());
        }
    };
};

class BrancheForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            branchData: {
                id: props.creating ? null : props.branchData.id,
                name: props.creating ? '' : props.branchData.name,
                address: props.creating ? null : props.branchData.address ? props.branchData.address : null,
                phone: props.creating ? null : props.branchData.phone ? props.branchData.phone : null,
                ext: props.creating ? null : props.branchData.ext ? props.branchData.ext : null,
                latitude: props.creating ? null : props.branchData.latitud ? props.branchData.latitud : null,
                longitude: props.creating ? null : props.branchData.longitud ? props.branchData.longitud : null,
                municipality_id: props.creating ? null : props.branchData.municipality_id ? props.branchData.municipality_id : null,
                province: props.creating ? null : props.branchData.province ? props.branchData.province : null,
                contactname: props.creating ? null : props.branchData.contactname ? props.branchData.contactname : null,
                contactemail: props.creating ? null : props.branchData.contactemail ? props.branchData.contactemail : null,
                contactphone: props.creating ? null : props.branchData.contactphone ? props.branchData.contactphone : null
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                address: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false },
                ext: { errorClass: null, dangerLabel: false },
                latitude: { errorClass: null, dangerLabel: false },
                longitude: { errorClass: null, dangerLabel: false },
                municipality_id: { errorClass: null, dangerLabel: false },
                province: { errorClass: null, dangerLabel: false },
                contactname: { errorClass: null, dangerLabel: false },
                contactemail: { errorClass: null, dangerLabel: false },
                contactphone: { errorClass: null, dangerLabel: false }
            }

        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }

        if (this.props.listCities.length === 0) {
            this.props.loadAllDrops();
        }
    }



    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let brancheData = {... this.state.branchData};
        brancheData[field] = value;
        this.setState({branchData: brancheData});
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.branchData});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    menuOpen() {
        var top = $('#ModalView')[0].scrollHeight;
        $('body, #ModalView').animate({scrollTop: top}, 1000);
    }


    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}
                id='BranchForm'
            >
                <div className="row mgB2">
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="name"
                        errorClassName={this.state.errorFields.name.errorClass}
                        value={this.state.branchData.name}>
                        <TextInput
                            label={<span>Nombre <span className="color-red">*</span></span>}
                            placeholder="Escriba el nombre"
                            value={this.state.branchData.name}
                            dangerLabel={this.state.errorFields.name.dangerLabel}
                            onChange={(val) => this.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-12 mgB2"
                        name="address"
                        errorClassName={this.state.errorFields.address.errorClass}
                        value={this.state.branchData.address}
                    >
                        <TextInput
                            label={<span>Dirección Particular </span>}
                            placeholder="Inserte la dirección"
                            dangerLabel={this.state.errorFields.address.dangerLabel}
                            value={this.state.branchData.address}
                            onChange={(val) => this.onFieldChange('address', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-12 col-12 mgB2"
                        name="city"
                        errorClassName={this.state.errorFields.municipality_id.errorClass}
                        value={this.state.branchData.municipality_id}
                    >
                        <SelectInput
                            value={this.state.branchData.municipality_id}
                            floatingLabel="Seleccione el municipio"
                            options = {this.props.listCities.map(elem =>{
                                return {"value":elem.value, "label": elem.label};
                            })}
                            onChange={(val) => {
                                let branchData = {... this.state.branchData};
                                branchData['municipality_id'] = val;
                                branchData['province'] = this.props.listCities.find(fil => { return fil.value === val; }).province;
                                this.setState({branchData: branchData});
                            }}
                            dangerLabel={this.state.errorFields.municipality_id.dangerLabel}
                            onMenuOpen={this.menuOpen}
                        />
                        {this.state.branchData['province'] && this.props.listProvinces.length > 0 &&
                                <div>Pertenece a la Provincia: <span style={{fontWeight: 'bold'}}>{
                                    this.props.listProvinces.find(ele => {
                                        return ele.value === this.state.branchData['province'];
                                    }).label}</span></div>}
                    </FormsyInputWrapper>

                    <FormsyInputWrapper
                        className="col-md-7 col-12 mgB2"
                        name="phone"
                        errorClassName={this.state.errorFields.phone.errorClass}
                        value={this.state.branchData.phone}
                        validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                    >
                        <TextInput
                            label="Teléfono"
                            placeholder="Inserte el teléfono"
                            value={this.state.branchData.phone}
                            dangerLabel={this.state.errorFields.phone.dangerLabel}
                            onChange={(val) => this.onFieldChange('phone', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-5 col-12 mgB2"
                        name="ext"
                        errorClassName={this.state.errorFields.ext.errorClass}
                        value={this.state.branchData.ext}
                    >
                        <TextInput
                            label="Extensión"
                            onlyNumbers={true}
                            numbers={true}
                            dangerLabel={this.state.errorFields.ext.dangerLabel}
                            placeholder="Inserte extensión"
                            value={this.state.branchData.ext}
                            onChange={(val) => this.onFieldChange('ext', val)}
                        />
                    </FormsyInputWrapper>
                    {/* <FormsyInputWrapper
                                className="col-md-6 col-12 mgB2"
                                name="latitud"
                                errorClassName={this.state.errorFields.latitud.errorClass}
                                value={this.state.branchData.latitud}
                                >
                                <CustomMaskedInput
                                    label="Latitud"
                                    mask="11.111111"
                                    placeholder="XX.XXXXXX"
                                    value={this.state.branchData.latitud}
                                    onChange={(val) => this.onFieldChange('latitud', val)}
                                />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                                className="col-md-6 col-12 mgB2"
                                name="longitud"
                                errorClassName={this.state.errorFields.longitud.errorClass}
                                value={this.state.branchData.longitud}
                                >
                                <CustomMaskedInput
                                    label="Longitud"
                                    mask="11.111111"
                                    placeholder="XX.XXXXXX"
                                    value={this.state.branchData.longitud}
                                    onChange={(val) => this.onFieldChange('longitud', val)}
                                />
                   </FormsyInputWrapper>*/}

                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="contacname"
                        errorClassName={this.state.errorFields.contactname.errorClass}
                        value={this.state.branchData.contactname}>
                        <TextInput
                            label={<span>Nombre del contacto </span>}
                            placeholder="Escriba el nombre"
                            value={this.state.branchData.contactname}
                            dangerLabel={this.state.errorFields.contactname.dangerLabel}
                            onChange={(val) => this.onFieldChange('contactname', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-6 col-12 mgB2"
                        name="contactemail"
                        errorClassName={this.state.errorFields.contactemail.errorClass}
                        value={this.state.branchData.contactemail}
                    >
                        <TextInput
                            label="Correo Electrónico"
                            placeholder="Escriba el correo electrónico"
                            value={this.state.branchData.contactemail}
                            dangerLabel={this.state.errorFields.contactemail.dangerLabel}
                            onChange={(val) => this.onFieldChange('contactemail', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-6 col-12 mgB2"
                        name="contactphone"
                        errorClassName={this.state.errorFields.contactphone.errorClass}
                        value={this.state.branchData.contactphone}
                        validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                    >
                        <TextInput
                            label="Celular"
                            placeholder="Inserte el celular"
                            value={this.state.branchData.contactphone}
                            dangerLabel={this.state.errorFields.contactphone.dangerLabel}
                            onChange={(val) => this.onFieldChange('contactphone', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                </div>

                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Volver
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='ModalView'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.branchData}
                    >
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            {this.props.creating ? 'Crear' : 'Guardar'}
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </Formsy>
        );
    }

}

BrancheForm.defaultProps = {creating: true};

BrancheForm.propTypes = {
    creating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(BrancheForm);