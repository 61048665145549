'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';

import UserDetailBody from './user-detail-body';
import UserDetailHeader from './user-detail-header';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            user: userSelectors.getUserProfile(state),
            loading: userSelectors.getIsLoading(state),
            error: userSelectors.getError(state),
            userUpdated: userSelectors.getUserUpdated(state),
            simpleError: userSelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (id) => {
            dispatch(userActions.getUser(id));
        },
        getClient: (id) => {
            dispatch(userActions.getClient(id));
        },
        clearUserProfile: () => {
            dispatch(userActions.clearUserProfile());
        },
        updateUser: (id, params) => {
            dispatch(userActions.updateUser(id, params));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class UsersDetailContainer extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        if (!id) {
            this.props.getUser(this.props.authUser.id);
        } else {
            if (this.props.match.url.includes("clients")) {
                this.props.getClient(id);
            } else {
                this.props.getUser(id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userUpdated) {
            this.props.resetUserMessages();
            location.reload();
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const id = this.props.match.params.id;
            if (!id) {
                this.props.getUser(this.props.authUser.id);
            } else {
                if (this.props.match.url.includes("clients")) {
                    this.props.getClient(id);
                } else {
                    this.props.getUser(id);
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.clearUserProfile();
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {!this.props.loading &&
                    this.props.user &&
                    <React.Fragment
                        key='user-detail'>
                        <div style={{paddingBottom: '0px'}} className="layout-body__header">
                            <UserDetailHeader
                                user={this.props.user}
                                history={this.props.history}
                                simpleError={this.props.simpleError}
                                authUser={this.props.authUser}
                                updateUser={this.props.updateUser}
                                resetUserMessages={this.props.resetUserMessages}
                            />
                        </div>
                        <hr className="mgT3 mgB3 w-100"/>
                        <div className="layout-body__content">
                            <UserDetailBody
                                user={this.props.user}
                                simpleError={this.props.simpleError}
                                resetUserMessages={this.props.resetUserMessages}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                updateUser={this.props.updateUser}
                            />
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

UsersDetailContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(UsersDetailContainer));
