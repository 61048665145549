import React from 'react';
import PropTypes from 'prop-types';

/**
 * Represents a simple text input.
 */
export default class TextInput extends React.PureComponent {

    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props} the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = { labelFocused: false, sufixPosition: 0 };

        this.onTextChange = this.onTextChange.bind(this);
        //this.onFocus = this.changeFocus.bind(this, true);
    }

    onBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value);
        }
        this.changeFocus(false);
    };

    onFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e.target.value);
        }
        this.changeFocus(true);
    };

    /**
     * Fired once the input focus changes.
     *
     * @param {bool} labelFocused determines if the element is focused or not.
     */
    changeFocus(labelFocused) {
        this.setState({ labelFocused });
    }

    componentDidMount() {
        if (this.props.sufix) {
            if (document.getElementById('invisible-text-input')) {
                document.getElementById('invisible-text-input').innerText = this.props.value;
                this.setState({sufixPosition: document.getElementById('invisible-text-input').getBoundingClientRect().width});
            }
        }
    }

    /**
     * Fired once the input text changes.
     *
     * @param {Proxy.dispatcher} event  contains the input's new value.
     */
    onTextChange(event) {
        let value = event.target.value;
        if (this.props.noSpecialCharacters) {
            value = value.replace(/[^\w\s]/gi, '');
        }
        if (this.props.onChange) {
            if (this.props.onlyNumbers) {
                if (this.props.range) {
                    if ((value[0] < 1 || isNaN(value[0])) && value !== '') {
                        return;
                    }
                    if ((value.match(/-/g) || []).length === 0) {
                        if (isNaN(event.currentTarget.value)) {
                            return;
                        }
                    } else {
                        if ((value.match(/-/g) || []).length > 1) {
                            return;
                        } else {
                            if (isNaN(value.replace(/[-]/gi, ''))) {
                                return;
                            }
                        }
                    }

                } else {
                    if (isNaN(event.currentTarget.value)) {
                        return;
                    }
                }
                if (parseFloat(event.currentTarget.value) > this.props.maxValue) {
                    return;
                }

                if (parseFloat(event.currentTarget.value) < this.props.minValue) {
                    return;
                }
            }

            if (!this.props.numbers) {
                this.props.onChange(this.props.digits ? value.replace(/[0-9]/g, '').substring(0, this.props.digits) : value.replace(/[0-9]/g, ''));
            } else {
                if (this.props.decimals) {
                    this.props.onChange(this.props.digits ? value.substring(0, this.props.digits) : value);
                } else {
                    let withoutDots = value.replace(/\./g, '');
                    this.props.onChange(this.props.digits ? withoutDots.substring(0, this.props.digits) : withoutDots);
                }
            }
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <label className="form-label">
                {this.props.label &&
                    <span className={`label ${this.props.labelClassName} ${this.props.dangerLabel ? 'text-danger' : ''}`} style={this.props.labelStyle}>
                        {this.props.icon && (
                            <span className="fl__icon fl__icon--left mgR">
                                <i className={this.props.icon} />
                            </span>
                        )}
                        {this.props.label}
                    </span>
                }
                {this.props.innerIcon &&
                    <span className="text-input__with-icon"><i className={ this.props.innerIcon } /></span>
                }
                <input
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    id={this.props.id}
                    type="text"
                    className={`white-background form-control ${this.props.innerIcon ? 'with-icon' : ''}`}
                    onFocus={this.onFocus}
                    onKeyPress={(e) => this.props.onKeyPress(e)}
                    onBlur={this.onBlur}
                    tabIndex={this.props.tabIndex}
                    style={this.props.inputStyle}
                    onChange={this.onTextChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength}
                    size={this.props.maxLength}
                />
                {this.props.sufix && <span style={{ left: this.state.sufixPosition + 15.00 + 'px'}} className="text-input__with-sufix">{this.props.sufix}</span>}
                <div style={{fontSize: '1rem'}} id='invisible-text-input' className='position-absolute invisible'></div>
            </label>
        );
    }
}

TextInput.defaultProps = {
    numbers: true,
    onlyNumbers: false,
    decimals: true,
    noSpecialCharacters: false,
    sufix: '',
    digits: null,
    dangerLabel: false,
    labelClassName: '',
    range: false,
    spanStyle: {},
    maxLength: null
};

/**
 * Text input properties validation.
 */
TextInput.propTypes = {
    placeholder: PropTypes.string,
    labelStyle: PropTypes.object,
    disabled: PropTypes.bool,
    numbers: PropTypes.bool,
    name: PropTypes.string,
    icon: PropTypes.string,
    onlyNumbers: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    dangerLabel: PropTypes.bool,
    maxLenght: PropTypes.number,
    id: PropTypes.string
};
