'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { isNull } from 'lodash';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import $ from 'jquery';
import Media from 'react-media';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as financingActions from 'common/store/actions/financing-entity/financing-entity.actions.js';
import * as financingSelectors from 'common/store/selectors/financing-entity.selector';
import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';


import EmptyState from 'common/components/empty-state';
import ImagePreview from 'common/components/image-preview';
import LoadingComponent from 'common/components/loading';

import {
    BACK_ICON,
    COMMENT_ICON,
    PRINTER_ICON
} from 'common/constants/icon.constant';
import {
    USERTYPES,
    FINANCING_CANCELATION_REASON,
    FINANCING_STATUSES,
    IDENTIFICATION_TYPES,
    FINANCING_DECLINING_REASON,
    CURRENCIES_IDS
} from 'common/constants/enums.constant';
import { DEFAULT_FINANCING_ENTITIES_FILTERS } from 'common/constants/objects.constant';
import {
    FormsyInputWrapper,
    SelectInput,
    TextInput,
    TextArea,
    CustomMaskedInput,
    MoneyInput,
    Radio
} from 'common/components/inputs';


import mask from 'common/helpers/mask';
import money from 'common/helpers/money';
import Modal from 'common/components/modal';
import Dropzone from 'common/components/dropzone';
import calculateFinancing from 'common/helpers/calculate-financing';

import FilePDF from './filepdf';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            simpleError: transactionSelectors.getSimpleError(state),
            user: userSelectors.getUserProfile(state),
            actionsLoadingModal: transactionSelectors.getFinancingActionsLoadingModal(state),
            financingUpdated: transactionSelectors.getFinancingUpdated(state),
            financingEntities: financingSelectors.getFinancingEntitiesList(state),
            financingsEntitiesType: transactionSelectors.getAllFinancingEntityTypes(state),
            financingResend: transactionSelectors.getNewFinancingCreated(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelFinancing: (params) => {
            dispatch(transactionActions.cancelFinancing(params));
        },
        declineFinancing: (params) => {
            dispatch(transactionActions.declineFinancing(params));
        },
        approveFinancing: (id) => {
            dispatch(transactionActions.approveFinancing(id));
        },
        changeFinancingStatus: (id, status) => {
            dispatch(transactionActions.changeFinancingStatus(id, status));
        },
        addComment: (id, comment) => {
            dispatch(transactionActions.addComment(id, comment));
        },
        signFinancing: (id) => {
            dispatch(transactionActions.signFinancing(id));
        },
        loadEntityType: () =>{
            dispatch(transactionActions.getAllFinancingEntityTypes());
        },
        setFilters: (filters) => {
            dispatch(financingActions.setEntitiesFilters(filters));
        },
        loadAllEntities: (filter) =>{
            dispatch(financingActions.getFinancingEntitiesList(filter));
        },
        sendNewFinancingRequest: (id, newData) =>{
            dispatch(transactionActions.resendFinancing(id, newData));
        },
        clearModalFinancing: () => {
            dispatch(transactionActions.clearModalFinancing());
        },
        sellConfirmFinancing: (id, data) => {
            dispatch(transactionActions.sellConfirmFinancing(id, data));
        },
        uploadInvoice: (id, file) => {
            dispatch(transactionActions.uploadDocument(id, file));
        },
        undoApproveFinancing: (id, undoComment) => {
            dispatch(transactionActions.undoApproveFinancing(id, undoComment));
        },
        getClient: (id) => {
            dispatch(userActions.getClient(id));
        }
    };
};

class FinancingDetailHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showCancelFinancingModal: false,
            showApproveFinancingModal: false,
            showSignFinancingModal: false,
            showChangeFinancingStatusModal: false,
            showSellFinancingProductsStatusModal: false,
            showCommentsModal: false,
            showResendFinancingModal:false,
            comment: '',
            undoComment: '',
            financingStatus: null,
            cancelationReason: FINANCING_CANCELATION_REASON.NO_INVENTORY,
            otherCancelationReason: '',
            decliningReason: FINANCING_DECLINING_REASON.NO_HISTORY,
            otherDecliningReason: '',
            entityType: null,
            entitySelected: null,
            planSelected: null,
            entitiesFilterd: [],
            filteredPlans: [],
            enviado: false,
            soldit:{
                invoice: null,
                date: null,
                file: null
            },
            resendOther: null,
            enabledbutton: true,
            errorOnThis: null,
            isMobile: window.matchMedia('(max-width: 440px)').matches,
            clientValid: props.financing.order.user.active,
            fullProfile: false,
            calculatedData: null,
            advancePayment: null,
            newAdvancePayment: 0,
            canContinue: true,
            deliveryCost: props.financing.order.deliveryCost / 100,
            showoptions: false
        };


    }

    componentDidMount(){
        if (this.props.financingsEntitiesType.length === 0) {
            this.props.loadEntityType();
        }
        let filters = DEFAULT_FINANCING_ENTITIES_FILTERS;
        filters.enabled = 1;
        filters.perPage=50;
        this.props.setFilters(filters);
        this.props.loadAllEntities(filters);
        this.props.getClient(this.props.financing.order.user.id);
    }

    componentDidUpdate() {
        if (this.props.financingUpdated) {
            this.props.resetFinancingMessages();
            location.reload();
            this.setState({enabledbutton:true});
        }

    }

    loadFinancingEntities = (Id) => {
        let filtered = this.props.financingEntities.filter(el => {
            return el.type.id === Id;
        });
        this.setState({entitiesFilterd: filtered, entityType: Id, entitySelected: null});
    };


    setCancelationReason = (val) => {
        let otherCancelationReason = this.state.otherCancelationReason;
        if (val === FINANCING_CANCELATION_REASON.NO_INVENTORY) {
            otherCancelationReason = '';
        }
        this.setState({
            cancelationReason: val,
            otherCancelationReason: otherCancelationReason
        });
    };

    setDecliningReason = (val) => {
        let otherDecliningReason = this.state.otherDecliningReason;
        if (val !== FINANCING_DECLINING_REASON.OTHER) {
            otherDecliningReason = '';
        }
        this.setState({
            decliningReason: val,
            otherDecliningReason: otherDecliningReason
        });
    };

    renderApproveFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showApproveFinancingModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showApproveFinancingModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/financing_success.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Aprobar solicitud</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea aprobar la solicitud? <strong>Se enviará una notificación {this.props.authUser.userType.id === USERTYPES.AGENT ? 'a la tienda' : 'al cliente'}.</strong></p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick = {
                                () => this.props.approveFinancing(this.props.financing.id)
                            }
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showApproveFinancingModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderSignedFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showSignFinancingModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showSignFinancingModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/financing_success.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Firma de contrato</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿El cliente ha firmado el contrato de financiamiento? <br></br> <strong>Se enviará una notificación a la tienda.</strong></p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick = {
                                () => this.props.signFinancing(this.props.financing.id)
                            }
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showSignFinancingModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderCommentsModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                // className="o-md-inherit"
                onModalClose={() => this.setState({showCommentsModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showCommentsModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/add_comment.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Nuevo comentario</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">
                        Su comentario será registrado en el historial de acciones
                        y estará visible para todos los empleados y administradores.
                    </p>
                    <React.Fragment>
                        <div style={{margin: '15px 0px'}} className="row">
                            <TextArea
                                value={this.state.comment}
                                placeholder="Escriba aquí"
                                rows="3"
                                label={<span>Comentario <span className="color-red">*</span></span>}
                                onChange={(val) => this.setState({comment: val})}
                            />
                        </div>
                    </React.Fragment>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            disabled={this.state.comment === ''}
                            onClick = {
                                () => this.props.addComment(
                                    this.props.financing.id,
                                    this.state.comment
                                )
                            }
                            className="btn btn-outline col-md-4">
                            Guardar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showCommentsModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    menuOpen() {
        var top = $('#ModalView')[0].scrollHeight;
        $('body, #ModalView').animate({scrollTop: top}, 1000);
    }

    renderChangeFinancingStatusModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                // className="o-md-inherit"
                onModalClose={() => this.setState({showChangeFinancingStatusModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showChangeFinancingStatusModal}
                id='ModalView'>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/change_financing_status.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Cambiar estado</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea cambiar el estado de esta solicitud?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Los cambios serán visibles para el cliente y la entidad.</p>
                    </div>
                    <React.Fragment>
                        <div style={{margin: '15px 0px'}} className="row">
                            <SelectInput
                                value={this.state.financingStatus}
                                floatingLabel="Seleccione el estado"
                                options={this.props.authUser.userType.id === USERTYPES.AGENT ? [{value: FINANCING_STATUSES.WAITING_FOR_CLIENT, label: 'Esperando por el cliente'}]:[
                                    {value: FINANCING_STATUSES.SENT, label: 'Enviada'},
                                    {value: FINANCING_STATUSES.WAITING_FOR_CLIENT, label: 'Esperando por el cliente'},
                                    {value: FINANCING_STATUSES.APPROVED, label: 'Aprobada'},
                                    {value: FINANCING_STATUSES.CANCELED, label: 'Cancelada'},
                                    {value: 100, label: 'Revertir Aprobación'}
                                ]}
                                onChange={(val) => this.setState({financingStatus: val})}
                                onMenuOpen={this.menuOpen}
                            />
                        </div>
                        {this.state.financingStatus === FINANCING_STATUSES.CANCELED &&
                            <div style={{margin: '15px 0px'}} className="row">
                                <SelectInput
                                    value={this.state.cancelationReason}
                                    floatingLabel="Especifique la razón para cancelar"
                                    options={[
                                        {value: FINANCING_CANCELATION_REASON.NO_INVENTORY, label: 'No hay productos en inventario'},
                                        {value: FINANCING_CANCELATION_REASON.OTHER, label: 'Otra'}
                                    ]}
                                    onChange={(val) => this.setCancelationReason(val)}
                                    onMenuOpen={this.menuOpen}
                                />
                                {this.state.cancelationReason === FINANCING_CANCELATION_REASON.OTHER &&
                                    <div className="mgT w-100">
                                        <TextInput
                                            placeholder="Indique la razón"
                                            value={this.state.otherCancelationReason}
                                            onChange={(val) => this.setState({otherCancelationReason: val})}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {this.state.financingStatus === 100 &&
                            <div style={{margin: '15px 0px'}} className="row">
                                <TextInput
                                    placeholder="Indique la razón"
                                    value={this.state.undoComment}
                                    onChange={(val) => this.setState({undoComment: val})}
                                />
                            </div>
                        }
                    </React.Fragment>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            disabled={this.state.financingStatus === null}
                            onClick = {
                                this.state.financingStatus === FINANCING_STATUSES.CANCELED ?
                                    () => this.props.cancelFinancing({
                                        id: this.props.financing.id,
                                        financingCancelationReason: this.state.cancelationReason,
                                        otherFinancingCancelationReason: this.state.otherCancelationReason
                                    }) : this.state.financingStatus === 100?
                                        () => this.props.undoApproveFinancing(this.props.financing.id, this.state.undoComment)
                                        :() => this.props.changeFinancingStatus(
                                            this.props.financing.id,
                                            this.state.financingStatus
                                        )
                            }
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showChangeFinancingStatusModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderCancelFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                // className="o-md-inherit"
                onModalClose={() => this.setState({showCancelFinancingModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showCancelFinancingModal}
                id='ModalView'>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">{(this.props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED) ? 'Eliminar Solicitud' : 'Cancelar solicitud'}</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">{(this.props.financing.financingStatus.id !== FINANCING_STATUSES.CANCELED) ? '¿Está seguro que desea cancelar la solicitud?' : '¿Está seguro que desea eliminar la solicitud?'}</p>
                    {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                        <React.Fragment>
                            {(this.props.financing.financingStatus.id !== FINANCING_STATUSES.CANCELED) &&
                            <div className="important-information mgB">
                                <p><strong>Información importante</strong></p>
                                <p>Se enviará una notificación al cliente y a la entidad.</p>
                            </div>}
                            {(this.props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED) &&
                            <div className="important-information mgB">
                                <p><strong>Información importante</strong></p>
                                <p>Esta solicitud se eliminará completamente del sistema.</p>
                            </div>}
                            {(this.props.financing.financingStatus.id !== FINANCING_STATUSES.CANCELED) && <div style={{margin: '15px 0px'}} className="row">
                                <SelectInput
                                    value={this.state.cancelationReason}
                                    floatingLabel="Especifique la razón para cancelar"
                                    options={[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY].indexOf(this.props.financing.financingStatus.id) < 0 ? [
                                        {value: FINANCING_CANCELATION_REASON.NO_INVENTORY, label: 'No hay productos en inventario'},
                                        {value: FINANCING_CANCELATION_REASON.OTHER, label: 'Otra'}
                                    ] : [
                                        {value: FINANCING_CANCELATION_REASON.BANK_TIMEOUT, label: 'El banco se ha dilatado en aprobar la solicitu'},
                                        {value: FINANCING_CANCELATION_REASON.BANK_REQUIRE_ACCOUNT, label: 'El banco exige abrir una cuenta'},
                                        {value: FINANCING_CANCELATION_REASON.DECLINED_CLIENT, label: 'El cliente decidió que ya no quiere el financiamiento'},
                                        {value: FINANCING_CANCELATION_REASON.CHOOSE_OTHER_PLACE, label: 'El cliente compró en otro lugar por conveniencia o tiempo'},
                                        {value: FINANCING_CANCELATION_REASON.COMPLICATED_PROCCESS, label: 'El cliente no quiere pasar por el banco a buscar estados financieros'},
                                        {value: FINANCING_CANCELATION_REASON.OTHER, label: 'Otra'}
                                    ]}
                                    onChange={(val) => this.setCancelationReason(val)}
                                    onMenuOpen={this.menuOpen}
                                />
                                {this.state.cancelationReason === FINANCING_CANCELATION_REASON.OTHER &&
                                    <div className="mgT w-100">
                                        <TextInput
                                            placeholder="Indique la razón"
                                            value={this.state.otherCancelationReason}
                                            onChange={(val) => this.setState({otherCancelationReason: val})}
                                        />
                                    </div>
                                }
                            </div>}
                        </React.Fragment>
                    }
                    {this.props.authUser.userType.id === USERTYPES.CLIENT &&
                        <div className="important-information mgB">
                            <p><strong>Información importante</strong></p>
                            <p>Perderá el progreso realizado.</p>
                        </div>
                    }
                    <div style={{margin: '0px'}} className="row">
                        <button
                            disabled={this.props.authUser.userType.id !== USERTYPES.CLIENT && (this.state.cancelationReason === null || (this.state.cancelationReason === FINANCING_CANCELATION_REASON.OTHER && this.state.otherCancelationReason === ''))}
                            onClick = {
                                () => this.props.cancelFinancing({
                                    id: this.props.financing.id,
                                    financingCancelationReason: this.state.cancelationReason,
                                    otherFinancingCancelationReason: this.state.otherCancelationReason
                                })
                            }
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showCancelFinancingModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderDeclineFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                // className="o-md-inherit"
                onModalClose={() => this.setState({showDeclineFinancingModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showDeclineFinancingModal}
                id='ModalView'>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Declinar solicitud</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿La solicitud de financiamiento no procede?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Se enviará una notificación al cliente.</p>
                    </div>
                    <div style={{margin: '15px 0px'}} className="row">
                        <SelectInput
                            value={this.state.decliningReason}
                            floatingLabel="Especifique la razón por la cual no procede"
                            options={[
                                {value: FINANCING_DECLINING_REASON.NO_HISTORY, label: 'No tiene historial crediticio'},
                                {value: FINANCING_DECLINING_REASON.BAD_HISTORY, label: 'Mal historial crediticio'},
                                {value: FINANCING_DECLINING_REASON.LESS_INCOME, label: 'Monto excede ingresos'},
                                {value: FINANCING_DECLINING_REASON.BAD_FORM, label: 'Error en el formulario'},
                                {value: FINANCING_DECLINING_REASON.OTHER, label: 'Otra'}
                            ]}
                            onChange={(val) => this.setDecliningReason(val)}
                            onMenuOpen={this.menuOpen}
                        />
                        {this.state.decliningReason === FINANCING_DECLINING_REASON.OTHER &&
                            <div className="mgT w-100">
                                <TextInput
                                    placeholder="Indique la razón"
                                    value={this.state.otherDecliningReason}
                                    onChange={(val) => this.setState({otherDecliningReason: val})}
                                />
                            </div>
                        }
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            disabled={this.state.decliningReason === null || (this.state.decliningReason === FINANCING_DECLINING_REASON.OTHER && this.state.otherDecliningReason === '')}
                            onClick = {
                                () => this.props.declineFinancing({
                                    id: this.props.financing.id,
                                    financingDecliningReason: this.state.decliningReason,
                                    otherFinancingDecliningReason: this.state.otherDecliningReason
                                })
                            }
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeclineFinancingModal: false })}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    onChangeEntityonModal(valu) {
        let preciototal = _.sumBy(this.props.financing.order.items, (item) => {
            return item.count * item.price;
        }) / 100;

        let discountall = this.props.financing.order.items.reduce((discount, val) =>{
            if (val.discount === true) { discount = true; }
            return discount;
        }, false);

        let difTrans = (this.state.entitiesFilterd.find(el => { return el.id === valu; }).optional_delivery === false) ? 0 : this.props.financing.order.deliveryCost / 100;

        let requireP = this.state.entitiesFilterd.find(el => { return el.id === valu; }).require_profile;
        if ((this.props.user.financingProfile !== null && requireP) || !requireP ) {
            let plans = this.state.entitiesFilterd.find(el => {
                return el.id === valu;
            }).plans.filter(fp => {
                if (discountall === true) {
                    return fp.enabled === true && fp.showIfDiscount === true && ((fp.minimum === null) ? 0 : fp.minimum.amount) < preciototal && fp.id !== this.props.financing.financingPlan.id;
                } else {
                    return fp.enabled === true && fp.showNotDiscount === true &&((fp.minimum === null) ? 0 : fp.minimum.amount) < preciototal && fp.id !== this.props.financing.financingPlan.id;
                }
            });
            this.setState({entitySelected: valu, filteredPlans: plans, canContinue:true, deliveryCost: difTrans});
        } else {
            this.setState({canContinue:false, deliveryCost: difTrans});
        }
    }

    sendNewFinancing = () => {
        let Fprofile = {};
        if (this.props.user.financingProfile !== null) {
            Fprofile = {
                isRequired: (this.state.resendOther)
                    ? this.state.entitiesFilterd.find(el => { return el.id === this.state.entitySelected; }).require_profile
                    : this.props.financing.financingPlan.financingEntity.require_profile,
                livingType: this.props.user.financingProfile.livingType.id,
                livingTime: this.props.user.financingProfile.livingTime.id,
                companyType: this.props.user.financingProfile.companyType.id,
                companyPhone: this.props.user.financingProfile.companyPhone,
                workingTime: this.props.user.financingProfile.workingTime.id,
                companyName: this.props.user.financingProfile.companyName,
                companyStreet: this.props.user.financingProfile.companyStreet,
                companyTitle: this.props.user.financingProfile.companyTitle,
                relativeName: this.props.user.financingProfile.relativeName,
                relativePhone: this.props.user.financingProfile.relativePhone,
                salary: this.props.user.financingProfile.salary,
                otherIncomes: this.props.user.financingProfile.otherIncomes || 0.00,
                name: this.props.user.financingProfile.name,
                lastname: this.props.user.financingProfile.lastname,
                phone: this.props.user.financingProfile.phone,
                email: this.props.user.financingProfile.email,
                isMarried: this.props.user.financingProfile.isMarried,
                spouseIdentification: this.props.user.financingProfile.spouseIdentification ?? '',
                spouseIdentificationType: this.props.user.financingProfile.isMarried ? (this.props.user.financingProfile.spouseIdentificationType ? this.props.user.financingProfile.spouseIdentificationType.id : 1) : null,
                spouseName: this.props.user.financingProfile.spouseName,
                spousePhone: this.props.user.financingProfile.spousePhone,
                identification: this.props.user.financingProfile.identification,
                id: this.props.user.financingProfile.id,
                identificationType: this.props.user.financingProfile.identificationType.id
            };
        } else {
            Fprofile = {
                isRequired: this.state.entitiesFilterd.find(el => { return el.id === this.state.entitySelected; }).require_profile,
                isMarried: false
            };
        }
        let data = {};

        data = (this.state.resendOther) ? Object.assign({},
            {
                planId: this.state.planSelected.id,
                advPayment: (this.state.newAdvancePayment > this.state.advancePayment) ? this.state.newAdvancePayment * 100 : this.state.advancePayment * 100,
                financingProfile: Object.assign({}, Fprofile),
                deliveryC: ((this.state.entitiesFilterd.find(el => { return el.id === this.state.entitySelected; }).optional_delivery === false) ? 0 : this.state.deliveryCost) * 100
            }) : Object.assign({},
            {
                planId: this.props.financing.financingPlan.id,
                advPayment: (this.props.financing.advancePayment !== null) ? this.props.financing.advancePayment : 0,
                financingProfile: Object.assign({}, Fprofile),
                deliveryC: ((this.state.entitiesFilterd.find(el => { return el.id === this.state.entitySelected; }).optional_delivery === false) ? 0 : this.state.deliveryCost) * 100
            });
        this.props.sendNewFinancingRequest(this.props.financing.id, data);
        this.setState({entitySelected: null, entityType: null, planSelected: null, calculatedData: null, advancePayment: null});
    };

    onChangeOption = (val) => {
        this.setState({resendOther: val}, () => {
            if (!val) {
                this.setState({planSelected: this.props.financing.financingPlan, newAdvancePayment: this.props.financing.advancePayment});
            } else {
                this.setState({planSelected: null, newAdvancePayment: 0});
            }
        });
    };

    onChangeDeliveryCost = (val) => {
        this.setState({deliveryCost: val});
    };

    renderResendFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showResendFinancingModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showResendFinancingModal}
                id='ModalView'>
                {this.props.actionsLoadingModal && isNull(this.props.financingResend) &&
                    <div>
                        <LoadingComponent
                            height={160}
                            style={{padding: '50px 0px 0px'}}
                            width={120} />
                        <div className="row" style={{color: '#124B6F', fontSize: '1.0em', fontWeight:'bold', width:'100%'}}>
                            <p>Enviando la solicitud a la nueva Entidad Financiera. Espere...</p>
                        </div>
                    </div>
                }
                {!this.props.actionsLoadingModal &&
                    <div>
                        <EmptyState
                            imgStyle={
                                {
                                    borderRadius: '150px',
                                    margin: 'auto',
                                    width: '170px',
                                    padding: '20px'
                                }
                            }
                            small={true}
                            image="/images/change_financing_status.svg"
                            title=""
                            text=""
                        />
                        {this.props.financingResend &&
                            <h2 className="text-center">Financiamiento Reenviado</h2>
                        }
                        {!this.props.financingResend &&
                            <h2 className="text-center">Reenviar Financiamiento</h2>
                        }
                        {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }

                        </div>
                        }

                        {this.props.financingResend &&
                        <div style={{color: '#124B6F', fontSize: '1.0em', width:'100%'}}>
                            <p>Se ha creado el financiamiento No. {this.props.financingResend.id} y enviado a <span style={{ fontWeight:'bold'}} >{this.props.financingResend.financingPlan.financingEntity.name}</span> solicitando el plan &ldquo;
                                <span style={{ fontWeight:'bold'}}>{this.props.financingResend.financingPlan.name}</span> &rdquo;</p>
                        </div>
                        }


                        {!this.props.financingResend &&
                            <React.Fragment>
                                <p className="text-center">¿Está seguro que desea enviar esta solicitud a otra entidad financiera?</p>
                                <div className="important-information mgB">
                                    <p><strong>Información importante</strong></p>
                                    <p>Los cambios serán visibles para el cliente y la nueva entidad.</p>
                                </div>
                            </React.Fragment>
                        }

                        {!this.props.financingResend &&
                            <div className="mgB2 mgT2">
                                <Radio
                                    selectedValue={this.state.resendOther}
                                    radioStyle={{ padding: '0px' }}
                                    onChange={(val) => this.onChangeOption(val)}
                                    label="¿Desea enviarla a la misma entidad y el mismo plan?"
                                    options={[
                                        {value: false, label: 'Sí'},
                                        {value: true, label: 'No'}
                                    ]}
                                />
                            </div>
                        }

                        {this.state.planSelected === null && this.state.resendOther &&
                    <React.Fragment>
                        {(!this.props.simpleError && isNull(this.props.financingResend)) && <div style={{margin: '15px 0px'}} className="row">
                            <SelectInput
                                value={this.state.entityType}
                                floatingLabel="Seleccione el tipo de la Entidad Financiera"
                                options={this.props.financingsEntitiesType.map( data => {
                                    return { "value": data.id, "label": data.name};
                                })}
                                onChange={(val) => this.loadFinancingEntities(val)}
                                onMenuOpen={this.state.entitySelected=== null && this.menuOpen}
                            />
                        </div>}
                        {this.state.entityType !== null &&
                            <div style={{margin: '15px 0px'}} className="row">
                                <SelectInput
                                    value={this.state.entitySelected}
                                    floatingLabel="Seleccione la Entidad"
                                    options = {this.state.entitiesFilterd.map(elem => {
                                        return {"value":elem.id, "label": elem.name};
                                    })}
                                    onChange={(val) => this.onChangeEntityonModal(val)}
                                    onMenuOpen={ this.state.entitySelected === null && this.menuOpen}
                                />
                            </div>
                        }
                        {!this.state.canContinue &&
                        <React.Fragment>
                            <p className="fsize-14 color-red">Lo sentimos, no podemos continuar.
                                <ul>
                                    <li>La entidad requiere Perfil Financiero.</li>
                                    <li>El cliente no posee Perfil Financiero.</li>
                                </ul>
                                 Usted debe indicar al cliente llenar su Perfil Financiero.</p>
                        </React.Fragment>
                        }
                        {this.state.entitySelected && this.state.canContinue === true &&
                        <React.Fragment>
                            <div>
                                {this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).logo !== '' &&
                            <div className="row" style={{marginLeft: '20%'}}>
                                <EmptyState
                                    imgStyle={
                                        {
                                            borderRadius: '10px',
                                            margin: 'auto',
                                            width: '60px',
                                            height: '60px'
                                        }
                                    }
                                    height='60px'
                                    image={this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).logo}
                                    small={true}
                                />
                            </div> }
                                {this.props.financing.order.deliveryCost === 0 && this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).optional_delivery === true &&
                                <div >
                                    <div className='text-center'><span className='color-red fsize-14 '>La nueva entidad incluye costo de envio.</span></div>
                                    <MoneyInput
                                        floatingLabel="Valor del costo de envio"
                                        placeholder="Ingrese el valor"
                                        value={this.state.deliveryCost}
                                        onChange={(val) => this.onChangeDeliveryCost(val)}
                                    />
                                </div>

                                }
                                <div className="mgT2" >
                                    {this.state.filteredPlans.length > 0
                                        ? <span style={{color: '#124B6F'}}>  Envie la solicitud con el plan que desee. </span>
                                        : <span style={{color: '#124B6F'}}>   La Entidad no tiene planes activos que cumplen con los requerimientos.</span> }
                                </div>
                                {this.state.filteredPlans.map((pl, index) => {
                                    let preciototal = _.sumBy(this.props.financing.order.items, (item) => {
                                        return item.count * item.price;
                                    }) / 100;
                                    let taxes = (preciototal + (this.state.deliveryCost)) * parseFloat(process.env.MIX_TAXES);
                                    preciototal = preciototal + taxes + (this.state.deliveryCost);
                                    let advancePayment = (pl.minAdvancePayment !== null) ? (pl.minAdvancePayment * preciototal) : 0;
                                    let financigncalculated = calculateFinancing(pl.payments, pl, (preciototal - advancePayment));
                                    return (
                                        <div className="row" key={index}>
                                            <hr className="w-95 mgT2"/>
                                            <div className="col-md-10 col-sm-10 col-12">
                                                <div className="row">
                                                    <div className="col-12"> <span className="label-in-card"> Plan: </span>{pl.name}</div>
                                                </div>
                                                {advancePayment > 0 &&
                                            <div className="row">
                                                <div className="col-12"> <span className="label-in-card"> Mínimo Adelanto: </span>  {pl.currency.symbol + '$ ' + money(advancePayment)}</div>
                                            </div>}
                                                <div className="row">
                                                    <div className="col-12"> <span className="label-in-card"> Gastos de Cierre: </span>  { financigncalculated.gastos_cierre}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12"> <span className="label-in-card">  Cuotas:  </span>{financigncalculated.payments} x {financigncalculated.restPayments} </div>
                                                </div> <div className="row">
                                                    <div className="col-12" > <span className="label-in-card">  Tasa Anual: </span>{(pl.charge * 100).toFixed(2)}% </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-2 col-12">
                                                <button
                                                    className="btn btn-primary float-right"
                                                    onClick = {() => this.setState({planSelected: pl, calculatedData: financigncalculated, advancePayment: advancePayment})}>
                                                    Aceptar
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                                <hr className="w-100 mgT2"/>
                            </div>
                        </React.Fragment>
                        }
                    </React.Fragment>
                        }
                        {this.state.planSelected !== null && this.state.resendOther && this.state.entitySelected != null &&
                     <React.Fragment>
                         <div className="row">
                             <p style={{fontSize:'16px', fontWeight:'bold'}} className="col-md-6">Plan escogido</p>
                             <span
                                 style={{fontSize:'16px', color:'#124b6f', fontWeight:'bold'}}
                                 className="col-md-6 text-right cursor-pointer"
                                 onClick={() => this.setState({planSelected:null, calculatedData: null, advancePayment:null, newAdvancePayment:0})}>
                                 {'<<'} Atrás</span>
                         </div>
                         <div
                             className={'bordered mgB text-center bordered-blue '} >
                             <div className="no-gutters bordered row w-100 pd2">
                                 <div className="col-5">
                                     <ImagePreview
                                         style = {
                                             {
                                                 height: '100%',
                                                 width: 'inherit',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundSize: 'contain'
                                             }
                                         }
                                         className="image-preview"
                                         image={this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).logo}>
                                     </ImagePreview>
                                 </div>
                                 <div className="col-7 align-center">
                                     {this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).name}
                                     <br></br>
                                     <h4><strong>{this.state.planSelected.name}</strong></h4>
                                 </div>
                             </div>


                             <div className="row pdT2" style={{fontSize:'12px'}}>
                                 <div className="col-md-12 col-12 mgB">
                                     <div className="d-flex border-bottom justify-content-between">
                                         <p className="text-gray mgB0 pdL2">Cuota mensual: </p>
                                         <p className="mgB0 pdR2"><strong>{this.state.calculatedData.restPayments}</strong></p>
                                     </div>
                                 </div>
                                 <div className="col-md-12 col-12 mgB">
                                     <div className="d-flex border-bottom justify-content-between">
                                         <p className="text-gray mgB0 pdL2">Inicial: </p>
                                         <p className="mgB0 pdR2"><strong>{this.state.planSelected.currency.symbol + '$ ' + money(this.state.advancePayment)}</strong></p>
                                     </div>
                                 </div>

                                 <div className="col-md-12 col-12 mgB">
                                     <div className="d-flex border-bottom justify-content-between">
                                         <p className="text-gray mgB0 pdL2">Gastos Cierre: </p>
                                         <p className="mgB0 pdR2"><strong>{this.state.calculatedData.gastos_cierre}</strong></p>
                                     </div>
                                 </div>
                                 <div className="col-md-12 col-12 mgB ">
                                     <div className="d-flex border-bottom justify-content-between">
                                         <p className="text-gray mgB0 pdL2" >Tasa anual: </p>
                                         <p className="mgB0 pdR2"><strong>{(this.state.planSelected.charge * 100).toFixed(2)}%</strong></p>
                                     </div>
                                 </div>
                                 <div className="col-md-12 col-12 mgB ">
                                     <div className="d-flex border-bottom justify-content-between">
                                         <p className="text-gray mgB0 pdL2" >Total: </p>
                                         <p className="mgB0 pdR2"><strong>{this.state.planSelected.currency.symbol + '$ ' + money(this.state.calculatedData.rawTotal + this.state.advancePayment)}</strong></p>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         {this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).require_warranties &&
                                <p style={{fontSize:'14px', fontWeight:'bold'}} className="text-center color-red"> <span style={{color:'#124b6f'}}>{this.state.entitiesFilterd.find((el) => { return el.id === this.state.entitySelected; }).name}</span> requiere garantía vehicular o inmobiliaria.</p>
                         }
                         {this.state.planSelected.minAdvancePayment !== 0 &&

                               <React.Fragment>
                                   <Formsy>
                                       <FormsyInputWrapper
                                           className="col-12"
                                           name="advancePayment"
                                           value={this.state.newAdvancePayment}
                                           validations = {!this.state.newAdvancePayment && this.state.newAdvancePayment > this.state.advancePayment}>
                                           <MoneyInput
                                               className={`${(this.state.newAdvancePayment > 0 && this.state.newAdvancePayment < this.state.advancePayment) ? 'border-red' : ''} white-background`}
                                               floatingLabel={<span>Adelanto </span>}
                                               placeholder={`${'Mínimo: ' + (this.state.advancePayment)}`}
                                               value={this.state.newAdvancePayment}
                                               onChange={(val) => this.setState({newAdvancePayment: val})}
                                           />
                                           <p className="color-red">El monto mínimo debe ser al menos {this.state.planSelected.currency.symbol + '$ ' + money(this.state.advancePayment)}. Al dejarlo en blanco se asume el mínimo calculado. </p>
                                       </FormsyInputWrapper>
                                   </Formsy>
                               </React.Fragment>
                         }
                     </React.Fragment>
                        }
                        {!this.props.financingResend &&
                        <div style={{margin: '0px'}} className="row">
                            <button
                                disabled = {this.state.planSelected === null || (this.state.planSelected != null && this.state.planSelected.minAdvancePayment != null && this.state.newAdvancePayment > 0 && this.state.newAdvancePayment < this.state.advancePayment)}
                                className="btn btn-primary col-md-4 mgT"
                                onClick={this.sendNewFinancing}>
                            Enviar
                            </button>
                            <div className="col-md-4 mgT" />
                            <button
                                onClick={() => this.setState({showResendFinancingModal: false, entityType: null, entitySelected: null, simpleError: false, planSelected: null, calculatedData: null})}
                                className="btn btn-primary col-md-4 mgT">
                            Volver
                            </button>
                        </div>}
                        {this.props.financingResend &&
                        <div style={{margin: '0px'}} className="row">
                            <button
                                onClick={() => this.setState({showResendFinancingModal: false, entityType: null, entitySelected: null, simpleError: false, planSelected: null, calculatedData: null})}
                                className="btn btn-primary col-md-4 mgT">
                            Salir
                            </button>
                            <div className="col-md-4 mgT"/>
                            <a className="btn btn-primary col-md-4 mgT" href={this.props.financingResend.id}>Ir a solicitud</a>
                        </div>}
                    </div>
                }
            </Modal>
        );
    }

    sellConfFinancing() {
        if (this.state.errorOnThis) {
            this.setState({errorOnThis:'Existen errores que debe corregir antes de continuar'});
            return;
        }

        if (!_.isNull(this.state.soldit.date) && !_.isNull(this.state.soldit.invoice) ) {

            const data = {
                date_sell: this.state.soldit.date,
                invoice: this.state.soldit.invoice
            };
            this.props.sellConfirmFinancing(this.props.financing.id, data);
            if (!_.isNull(this.state.soldit.file)) { this.props.uploadInvoice(this.props.financing.id, this.state.soldit.file); }
            this.setState({enabledbutton:false});
            this.setState({errorOnThis: null});
        } else {
            this.setState({enabledbutton: true});
            this.setState({errorOnThis:'Faltan datos en el formulario.'});
        }


    }

    onValidateDate = (val) => {
        if ((val.replace(/_/g, '').length === 10) ) { //&&  (val.indexOf('_') === -1)
            this.setState({errorOnThis: null});
            let data = _.split(val, '/');
            let day = parseInt(data[0]);
            let month = parseInt(data[1]);
            let year = parseInt(data[2]);


            if (!_.inRange(day, 1, 32) || !_.inRange(month, 1, 13) || !_.inRange(year, moment().subtract(1, 'year').year(), moment().add(2, 'year').year())) {
                this.setState({errorOnThis:'Error en fecha.'});
            } else {
                this.setState({soldit:{date:val, invoice:this.state.soldit.invoice, file: this.state.soldit.file}});
            }
        }
    };

    upInvoice = (invoice) => {
        let upInv = Array.isArray(invoice) ? invoice[0] : invoice;
        this.setState({soldit:{invoice:this.state.soldit.invoice, date:this.state.soldit.date, file: upInv}});
    };

    renderSellFinancingModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showSellFinancingProductsStatusModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showSellFinancingProductsStatusModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/financing_success.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Entrega de Mercancías</h2>
                    {this.state.errorOnThis &&
                    <div className='has-error mgB text-center'>
                        <span className='help-block'>{this.state.errorOnThis}</span>
                    </div>
                    }
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿El cliente ha recibido sus artículos? <br></br> <strong>Se enviará una notificación a la entidad financiera.</strong></p>
                    <Formsy>
                        <div >

                            <div className="mgT w-100">
                                <FormsyInputWrapper
                                    required
                                    name="invoice"
                                    value={this.state.soldit.invoice}
                                >
                                    <TextInput
                                        label="Número de conduce"
                                        placeholder="Número de conduce"
                                        value={this.state.soldit.invoice}
                                        onChange={(val) => this.setState({soldit:{invoice:val, date:this.state.soldit.date, file: this.state.soldit.file}})}
                                    />
                                </FormsyInputWrapper>
                            </div>
                            <div className="col-md-12 mgB" >
                                <Dropzone
                                    accept={['.pdf', '.jpeg', '.jpg', '.png']}
                                    onDrop={this.upInvoice}
                                    multiple={false}
                                    full={true}
                                    disabled={_.isNull(this.state.soldit.invoice) || _.isEmpty(this.state.soldit.invoice)}>
                                    <div >
                                        {_.isNull(this.state.soldit.file) &&
                                <p>Agregar archivo del conduce <strong className="color-blue">(Opcional)</strong></p>}
                                        {!_.isNull(this.state.soldit.file) &&
                                <p>El archivo a subir es: <strong className="color-blue">{this.state.soldit.file.name}</strong></p>}
                                    </div>
                                </Dropzone>
                            </div>

                            <div className="mgT w-100">
                                <FormsyInputWrapper
                                    required
                                    name='date_sell'
                                    value={this.state.soldit.date}
                                    error={_.isNull(this.state.soldit.date)}
                                    validation="isValidDate"
                                >
                                    <CustomMaskedInput
                                        label='Fecha de la Venta'
                                        placeholder='DD/MM/YYYY'
                                        mask="11/11/1111"
                                        value={this.state.soldit.date}
                                        onChange={this.onValidateDate}
                                    />
                                </FormsyInputWrapper>
                            </div>
                        </div>

                        <div style={{margin: '0px'}} className="row">
                            <button
                                onClick = {() => this.sellConfFinancing()}
                                className="btn btn-outline col-md-4"
                                disabled={!this.state.enabledbutton}>
                            Confirmar
                            </button>
                            <div className="col-md-4 mgT" />
                            <button
                                onClick={() => this.setState({showSellFinancingProductsStatusModal: false, soldit:{date:null, invoice:null, file:null}})}
                                className="btn btn-primary col-md-4">
                            Volver
                            </button>
                        </div>
                    </Formsy>
                </div>
            </Modal>
        );
    }

    openModalFinancing(val) {
        this.props.clearModalFinancing();
        switch (val) {
        case 'Cancel':
            this.setState({showCancelFinancingModal: true});
            break;
        case 'Resend':
            this.setState({showResendFinancingModal: true, entityType: null, entitySelected: null});
            break;
        case 'Sign':
            this.setState({showSignFinancingModal: true});
            break;
        case 'Approve':
            this.setState({showApproveFinancingModal: true});
            break;
        case 'Change':
            this.setState({showChangeFinancingStatusModal: true});
            break;
        case 'Sell':
            this.setState({showSellFinancingProductsStatusModal: true, enabledbutton:true});
            break;
        default:
            break;
        }
    }

    renderAdminAndEmployeesActions() {
        return (
            <div className="row">
                <div className="col-md-2 mgB">
                    {(this.props.authUser.userType.id === USERTYPES.ADMIN) && (this.props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED) &&
                  <button
                      onClick={() => this.openModalFinancing('Cancel')}
                      className="btn btn-primary btn-block">
                        Eliminar
                  </button>}
                    {([FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED].indexOf(this.props.financing.financingStatus.id) < 0) && <button
                        onClick={() => this.openModalFinancing('Cancel')}
                        className="btn btn-primary btn-block">
                        Cancelar
                    </button>}
                </div>
                <div className="col-md-2 mgB">
                    {([FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) < 0) &&
                  (this.props.authUser.userType.id === USERTYPES.ADMIN) &&
                   <button
                       onClick={() => this.setState({showDeclineFinancingModal: true})}
                       className="btn btn-primary btn-block">
                        No procede
                   </button>}
                </div>
                <div className="col-md-2 mgB">
                    {([FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.CANCELED].indexOf(this.props.financing.financingStatus.id) < 0) && <button
                        onClick={() => this.openModalFinancing('Resend')}
                        className="btn btn-primary btn-block">
                        Reenviar
                    </button>}
                </div>
                <div className="col-md-2 mgB">
                    {[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED ].indexOf(this.props.financing.financingStatus.id) < 0 &&
                    <button
                        onClick={() => this.openModalFinancing('Approve')}
                        className="btn btn-primary btn-block">
                            Aprobar
                    </button>}
                    {[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY].indexOf(this.props.financing.financingStatus.id) > -1 &&
                    <button
                        onClick={() => this.openModalFinancing('Sign')}
                        className="btn btn-primary btn-block">
                            Firmar por Cliente
                    </button>}
                </div>
                <div className="col-md-3 mgB">
                    {([FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) < 0) &&
                   <button
                       onClick={() => this.openModalFinancing('Change')}
                       className="btn btn-primary btn-block">
                        Cambiar estado
                   </button>}
                    {(this.props.financing.financingStatus.id === FINANCING_STATUSES.SIGNED) && <button
                        onClick={() => this.openModalFinancing('Sell')}
                        className="btn btn-primary btn-block">
                        Confirmar Venta
                    </button>}
                </div>
            </div>
        );
    }


    renderAgentsActions() {
        return (
            <div className="row">
                <div className="col-md-3 mgB">
                </div>
                <div className="col-md-3 mgB">
                    {[FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED].indexOf(this.props.financing.financingStatus.id) < 0 &&
                    <button
                        onClick={() => this.setState({showDeclineFinancingModal: true})}
                        className="btn btn-primary btn-block">
                        No procede
                    </button>}
                </div>
                <div className="col-md-3 mgB">
                    {[FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) < 0 &&<button
                        onClick={() => this.setState({showApproveFinancingModal: true})}
                        className="btn btn-primary btn-block">
                        Aprobar
                    </button>}
                </div>
                <div className="col-md-3 mgB">
                    {[FINANCING_STATUSES.WAITING_FOR_QUOTE, FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL].indexOf(this.props.financing.financingStatus.id) > 0 && <button
                        onClick={() => this.openModalFinancing('Change')}
                        className="btn btn-primary btn-block">
                        Cambiar estado
                    </button>}
                    {[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY].indexOf(this.props.financing.financingStatus.id) > -1 &&
                    <button
                        onClick={() => this.openModalFinancing('Sign')}
                        className="btn btn-primary btn-block">
                        Firmar por Cliente
                    </button>}
                </div>
            </div>
        );
    }

    renderClientsActions() {
        return (
            <div className="row">
                <div className="col-md-7" />
                <div className="col-md-5 mgB">
                    <button
                        onClick={() => this.setState({showCancelFinancingModal: true})}
                        className="btn btn-primary btn-block">
                        Cancelar Solicitud
                    </button>
                </div>
            </div>
        );
    }

    renderActions() {
        return (
            <div className="row">
                <div className="section-headline mgB col-md-4">
                    <h1>Solicitud {this.props.financing.id}</h1>
                    <p className="mgB0">Número de pedido en ElectroPay: <strong>{this.props.financing.order.id}</strong></p>
                </div>
                {this.props.financing.order.user.active === true &&
                <div className="col-md-8">
                    {[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY].indexOf(this.props.financing.financingStatus.id) > -1 &&
                        <React.Fragment>
                            {this.props.authUser.userType.id === USERTYPES.AGENT && this.renderAgentsActions()}
                            {([USERTYPES.EMPLOYEE, USERTYPES.ADMIN].indexOf(this.props.authUser.userType.id) > -1) && this.renderAdminAndEmployeesActions()}
                        </React.Fragment>
                    }
                    {([FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED].indexOf(this.props.financing.financingStatus.id) < 0) &&
                        <React.Fragment>
                            {this.props.authUser.userType.id === USERTYPES.CLIENT && !this.state.isMobile && this.renderClientsActions()}
                            {this.props.authUser.userType.id === USERTYPES.AGENT && this.renderAgentsActions()}
                            {([USERTYPES.EMPLOYEE, USERTYPES.ADMIN].indexOf(this.props.authUser.userType.id) > -1) && this.renderAdminAndEmployeesActions()}
                        </React.Fragment>
                    }
                    {(FINANCING_STATUSES.CANCELED === this.props.financing.financingStatus.id) &&
                        <React.Fragment>
                            {this.props.authUser.userType.id === USERTYPES.ADMIN && !this.state.isMobile && this.renderAdminAndEmployeesActions()}
                        </React.Fragment>
                    }

                    {[FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.DECLINED].indexOf(this.props.financing.financingStatus.id) > -1 &&
                        <React.Fragment>
                            {([USERTYPES.EMPLOYEE, USERTYPES.ADMIN].indexOf(this.props.authUser.userType.id) > -1) && this.renderAdminAndEmployeesActions()}
                        </React.Fragment>
                    }
                </div>}
                {!this.props.financing.order.user.active &&
                    <div className="col-md-8 pdT2 text-center">
                        <span className="color-red"><strong>El cliente de esta solicitud esta desactivado o eliminado.</strong></span>
                    </div>

                }
            </div>
        );
    }

    renderPDF = () => {
        let Data = {
            number: this.props.financing.id,
            estado: '( '+this.props.financing.financingStatus.name+' )',
            createdate: moment.utc(this.props.financing.createdAt).format('DD[/]MMMM[/]YYYY'),
            order: this.props.financing.order,
            advancePayment: this.props.financing.advancePayment,
            plan: this.props.financing.financingPlan,
            client: {
                name: this.props.financing.order.user.name +' '+this.props.financing.order.user.lastname,
                identification: (this.props.financing.identificationType && this.props.financing.identificationType.id === IDENTIFICATION_TYPES.CEDULA) ? mask(this.props.financing.order.user.identification ? this.props.financing.order.user.identification : '', '###-#######-#') : this.props.financing.order.user.identification ? this.props.financing.order.user.identification : 'No tiene',
                phone: this.props.financing.order.user.phone ? mask(this.props.financing.order.user.phone, '(###) ###-####') : '',
                address: this.props.financing.order.transactions[0].address.fullAddress,
                email: this.props.financing.order.user.email
            },
            optionalFields: this.props.financing.optional_fields,
            financingProfile: this.state.fullProfile ? {
                fullname: this.props.financing.name +' '+this.props.financing.lastname,
                identification: (this.props.financing.identificationType && this.props.financing.identificationType.id === IDENTIFICATION_TYPES.CEDULA) ? mask(this.props.financing.identification ? this.props.financing.identification : '', '###-#######-#') : this.props.financing.identification ? this.props.financing.identification : 'No tiene',
                phone: this.props.financing.phone ? mask(this.props.financing.phone, '(###) ###-####') : '',
                email: this.props.financing.email,
                livingTime:this.props.financing.livingTime.name,
                livingType:this.props.financing.livingType.name,
                isMarried:this.props.financing.isMarried,
                relativeName: this.props.financing.relativeName,
                relativePhone: this.props.financing.relativePhone ? mask(this.props.financing.relativePhone, '(###) ###-####') : '',
                salary: this.props.financing.salary,
                spouseIdentification: (this.props.financing.spouseIdentificationType) ? (this.props.financing.spouseIdentificationType.id === IDENTIFICATION_TYPES.CEDULA && this.props.financing.spouseIdentification !== null) ? mask(this.props.financing.spouseIdentification, '###-#######-#') : '---' : '---',
                spouseName: this.props.financing.spouseName,
                spousePhone: this.props.financing.spousePhone ? mask(this.props.financing.spousePhone, '(###) ###-####') : '',
                companyName: this.props.financing.companyName,
                companyPhone: this.props.financing.companyPhone ? mask(this.props.financing.companyPhone, '(###) ###-####') : '',
                companyStreet: this.props.financing.companyStreet,
                companyTitle: this.props.financing.companyTitle,
                companyType: this.props.financing.companyType.name,
                otherIncomes:  this.props.financing.otherIncomes,
                workingTime: this.props.financing.workingTime.name
            } : null
        };

        let file = FilePDF(Data);
        if (this.state.isMobile) {
            file.download();
        } else {
            file.open();
        }


    };

    renderFinancingInfo() {
        return (
            <React.Fragment>
                <Media query='(max-width: 440px)'>
                    {matches =>
                        matches ? (
                            <div>
                                <div className="row">
                                    <div className={`${[USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) === -1 ? 'col-6' : 'col-4'} pdL0 mgB text-left`}>
                                        <button
                                            onClick={() => this.props.history.goBack()}
                                            className="btn btn-transparent btn-link">
                                            <strong>
                                                <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                        Volver
                                            </strong>
                                        </button>
                                    </div>
                                    {<div className="col-3 pdL0 mgB text-right">
                                        <button
                                            onClick={this.renderPDF}
                                            className="btn btn-transparent btn-link">
                                            <strong style={{fontSize: '14px'}}>
                                    Descargar
                                            </strong>
                                        </button>
                                    </div>}
                                    { [USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) === -1 && this.state.clientValid && this.state.isMobile &&
                            <div className="col-3 pdL0 mgB text-right">
                                <button
                                    onClick={() => this.setState({showCancelFinancingModal: true})}
                                    className="btn btn-transparent btn-link">
                                    <strong style={{fontSize: '14px'}}>
                                        Cancelar
                                    </strong>
                                </button>
                            </div>
                                    }
                                    {[USERTYPES.ADMIN, USERTYPES.AGENT, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 && this.state.clientValid &&
                            <div className="col-4 pdL0 mgB text-right">
                                <button
                                    onClick={() => this.setState({showCommentsModal: true})}
                                    className="btn btn-transparent btn-link">
                                    <strong>
                                        <i style={{fontSize: '14px', marginRight: '10px'}} className={COMMENT_ICON}/>
                                        Agregar comentario
                                    </strong>
                                </button>
                            </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className={`${[USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 ? 'col-md-4' : 'col-md-4'} mgB text-center mgT`}>
                                        <span>Fecha: <strong>{moment.utc(this.props.financing.createdAt).format('DD[/]MMMM[/]YYYY[,] h:mma')}</strong></span>
                                    </div>
                                </div>
                            </div>
                        ):(
                            <div className="row mgT2">

                                <div className={`${[USERTYPES.ADMIN, USERTYPES.AGENT, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 ? 'col-md-3' : 'col-md-6'}  pdL0 mgB`}>
                                    <button
                                        onClick={() => this.props.history.goBack()}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                            Volver
                                        </strong>
                                    </button>
                                </div>
                                <div className={`${[USERTYPES.ADMIN, USERTYPES.AGENT, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 ? 'col-md-4' : 'col-md-4'} mgB text-md-right mgT`}>
                                    <span>Fecha: <strong>{moment.utc(this.props.financing.createdAt).format('DD[/]MMMM[/]YYYY[,] h:mma')}</strong></span>
                                </div>
                                <div className="col-md-2 pdL0 mgB text-md-right">
                                    <button
                                        onClick={this.renderPDF}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '14px', marginRight: '10px'}} className={PRINTER_ICON}/>
                                Descargar
                                        </strong>
                                    </button>
                                </div>
                                {[USERTYPES.ADMIN, USERTYPES.AGENT, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 && this.state.clientValid &&
                    <div className="col-md-3 pdL0 mgB text-md-right">
                        <button
                            onClick={() => this.setState({showCommentsModal: true})}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={COMMENT_ICON}/>
                                Agregar comentario
                            </strong>
                        </button>
                    </div>
                                }
                            </div>
                        )}
                </Media>
                {this.renderActions()}
                {this.renderCounts()}
            </React.Fragment>
        );
    }

    renderCounts() {
        let subTotal = _.sumBy(this.props.financing.order.items, (item) => {
            return item.count * item.price;
        }) / 100;

        let taxes = (subTotal + (this.props.financing.order.deliveryCost / 100)) * parseFloat(process.env.MIX_TAXES);
        subTotal = subTotal + taxes + (this.props.financing.order.deliveryCost / 100) - (this.props.financing.advancePayment / 100);

        return (
            <div style={{minHeight: '150px'}} className="row bordered pd2 mgL0 mgR0 white-background">
                <div style={{minHeight: '110px'}} className="col-md-3 text-center mgB border-md-right border-sm-bottom">
                    <div className="vertical--center">
                        <p className="subtitle-1 mgB0"><strong>{this.props.financing.order.externalId ? this.props.financing.order.externalId : '-'}</strong></p>
                        <p className="mgB0">Número de pedido en <strong>{this.props.financing.order.store.name}</strong></p>
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-3 text-center mgB border-sm-bottom">
                    <div className="vertical--center">
                        <p className="subtitle-1 mgB0"><strong>{this.props.financing.financingStatus.name}</strong></p>
                        <p className="mgB0">Estado</p>
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-3 text-center mgB border-md-left border-sm-bottom">
                    <div className="vertical--center">
                        <p className="subtitle-1 mgB0"><strong>{(this.props.financing.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(subTotal)}</strong></p>
                        <p className="mgB0">Monto total - Inicial</p>
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-3 text-center mgB border-md-left border-sm-bottom">
                    <div className="vertical--center">
                        <p className="subtitle-1 mgB0"><strong>{this.props.financing.financingPlan.name}</strong></p>
                        <p className="mgB0">Plazo</p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let profilevalid = (this.props.financing.livingType !==null && this.props.financing.workingTime !==null && this.props.financing.companyType !==null );
        this.setState({fullProfile: profilevalid});
        return (
            <React.Fragment>
                {this.renderFinancingInfo()}
                {this.renderDeclineFinancingModal()}
                {this.renderChangeFinancingStatusModal()}
                {this.renderCancelFinancingModal()}
                {this.renderApproveFinancingModal()}
                {this.renderSignedFinancingModal()}
                {this.renderCommentsModal()}
                {this.renderResendFinancingModal()}
                {this.renderSellFinancingModal()}
            </React.Fragment>
        );
    }

}

FinancingDetailHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    financing: PropTypes.object,
    authUser: PropTypes.object
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingDetailHeader);
