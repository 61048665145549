'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import $ from 'jquery';
import { Document, Page } from 'react-pdf';

import money from 'common/helpers/money';
import CartComponent from 'common/components/cart-component';
import calculateFinancing from 'common/helpers/calculate-financing';
import ImagePreview from 'common/components/image-preview';
import Dropzone from 'common/components/dropzone';
import EmptyState from 'common/components/empty-state';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

import {
    PENCIL_ICON,
    MS_WORD_ICON
} from 'common/constants/icon.constant';


import {
    IDENTIFICATION_TYPES,
    CURRENCIES_IDS
} from 'common/constants/enums.constant';

import {
    FormsyInputWrapper,
    Radio,
    TextInput,
    SelectInput,
    MoneyInput
} from 'common/components/inputs';

class FinancingNotCompletedDetailContainer extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            financingData: {
                externalOrderId: this.props.financingTemp.external_id,
                name: this.props.financingTemp.name,
                lastname: this.props.financingTemp.lastname,
                identificationType: this.props.financingTemp.identification_type_id,
                identification: this.props.financingTemp.identification,
                identificationFile: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.identificationFile : null,
                identificationFile2: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.identificationFile2 : null,
                advancePayment: this.props.financingTemp.plan_advance_payment,
                currencyId: this.props.financingTemp.financing_plan.currency.id,
                financingTotal: null,
                email: this.props.financingTemp.email,
                phone: this.props.financingTemp.phone,
                postalCode: '',
                street: this.props.financingTemp.street,
                city: this.props.financingTemp.city,
                provinceName: '',
                province: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.provinceCode : '',
                country: 1,
                financingEntity:this.props.financingTemp.financing_plan.financing_entity.id,
                plan: this.props.financingTemp.financing_plan.id,
                cart: this.props.financingTemp.order_external,
                livingType: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.livingType) : null,
                livingTime: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.livingTime) : null,

                isMarried: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.isMarried) === 1 : false,
                spouseName: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.spouseName : '',
                spouseIdentificationType: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.spouseIdentificationType) : null,
                spouseIdentification: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.spouseIdentification : '',
                spousePhone:  (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.spousePhone : '',
                relativeName: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.relativeName : '',
                relativePhone: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.relativePhone : '',

                companyName: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.companyName : '',
                companyType: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.companyType) : null,
                companyStreet: (this.props.financingTemp.data_profile) ?this.props.financingTemp.data_profile.companyStreet : '',
                companyPhone: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.companyPhone : '',
                companyTitle: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.companyTitle : '',
                salary: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.salary : '',
                otherIncomes: (this.props.financingTemp.data_profile) ? this.props.financingTemp.data_profile.otherIncomes : '',
                workingTime: (this.props.financingTemp.data_profile) ? parseInt(this.props.financingTemp.data_profile.workingTime) : null
            },
            showProvinceEdit: false,
            canSubmit: true,
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                lastname: { errorClass: null, dangerLabel: false },
                identificationType: { errorClass: null, dangerLabel: false },
                identification: { errorClass: null, dangerLabel: false },
                identificationFile: { errorClass: null, dangerLabel: false },
                email: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false },
                livingType: { errorClass: null, dangerLabel: false },
                livingTime: { errorClass: null, dangerLabel: false },

                isMarried: { errorClass: null, dangerLabel: false },
                spouseName: { errorClass: null, dangerLabel: false },
                spousePhone: { errorClass: null, dangerLabel: false },
                relativeName: { errorClass: null, dangerLabel: false },
                relativePhone: { errorClass: null, dangerLabel: false },

                companyName: { errorClass: null, dangerLabel: false },
                companyType: { errorClass: null, dangerLabel: false },
                companyStreet: { errorClass: null, dangerLabel: false },
                companyPhone: { errorClass: null, dangerLabel: false },
                companyTitle: { errorClass: null, dangerLabel: false },
                salary: { errorClass: null, dangerLabel: false },
                workingTime: { errorClass: null, dangerLabel: false }
            },
            disabledProfile: true
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (!(this.props.financingTemp.phone.length === 10) || this.props.financingTemp.phone.includes('+1') || (['809', '829', '849'].indexOf(this.props.financingTemp.phone.substring(0, 3)) === -1)) {
            let textphone = document.getElementById('phonedata');
            textphone.classList.add('border-red');
        }

        if ((this.props.financingTemp.identification === undefined) || (this.props.financingTemp.identification === null) || !(this.props.financingTemp.identification.length === 11) || !_.isNumber(parseInt(this.props.financingTemp.identification)) ) {
            let identification = document.getElementById('identificationdata');
            identification.classList.add('border-red');
        }

        let total = this.calculateDataNum().rawTotal * 100;
        let financingTot = Object.assign({}, this.state.financingData, {financingTotal: total});

        this.setState({financingData:financingTot});
    }

    onIdentificationFileChanged = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('identificationFile', value);
    };

    onIdentificationFile2Changed = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('identificationFile2', value);
    };

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let data = {... this.state.financingData};
        data[field] = value;

        if (field === 'spouseIdentificationType') {
            data['spouseIdentification'] = '';
        }

        if (field === 'isMarried') {
            data['spouseName'] = '';
            data['spouseIdentificationType'] = null;
            data['spouseIdentification'] = '';
            data['spousePhone'] = '';
        }

        if (field === 'province') {
            data['provinceName'] = this.props.dropdowns.provinces.filter(el => el.value === value)[0].label;
        }

        if (field === 'phone') {
            let textphone = document.getElementById('phonedata');
            if ((data.phone.length === 10) && !data.phone.includes('+1') || (['809', '829', '849'].indexOf(data.phone.substring(0, 3)) > -1)) {
                textphone.classList.remove('border-red');
            }
        }

        if (field === 'identification') {
            let identification = document.getElementById('identificationdata');
            if ((data.identification.length === 11) && _.isNumber(parseInt(data.identification))) {
                identification.classList.remove('border-red');
            }
        }
        this.setState({financingData: data});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    dropOpen() {
        var top = $('#viewclient')[0].scrollHeight;
        $('body, #viewclient').animate({scrollTop: top}, 500);
    }

    renderClientInfo() {
        let provinceName =[];
        if ( this.props.dropdowns.provinces !== undefined && this.props.financingTemp.data_profile !== null) {
            provinceName = this.props.dropdowns.provinces.filter(el => { return el.value === parseInt(this.props.financingTemp.data_profile.provinceCode); });
        }

        return (
            <div id="viewclient"
                style={{maxHeight: '377px'}}
                className="user-detail-box row mgL0 mgR0  white-background">
                <div className="col-md-7 col-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Datos del cliente</p></strong>
                </div>
                <div className="col-md-5 col-6 align-center">
                    {/*!this.state.financingData.province &&
                        <button
                            onClick={() => {
                                this.setState({showProvinceEdit: true});
                                this.dropOpen();
                            }}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px', textDecoration:'none'}} className={PENCIL_ICON}/>
                                Completar datos
                            </strong>
                        </button>*/}
                </div>
                <hr className="w-100" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Nombre y Apellidos</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.name +' '+ this.props.financingTemp.lastname}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Teléfono</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.phone}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>No. de {this.props.financingTemp.identification_type_id === IDENTIFICATION_TYPES.CEDULA ? 'cédula' : 'pasaporte'}</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.identification ? this.props.financingTemp.identification : '---'}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Correo electrónico</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.email}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Dirección</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.state.financingData.street +', '+ this.state.financingData.city + ((provinceName.length > 0) ? ', '+ provinceName[0].label : '')}</p></strong>
                </div>
            </div>
        );
    }

    calculateDataNum() {
        let subTotal = _.sumBy(this.props.financingTemp.order_external.items, (item) => {
            return item.count * item.price;
        }) / 100 + this.props.financingTemp.order_external.deliveryCost / 100;
        let taxes = (subTotal) * parseFloat(process.env.MIX_TAXES);
        subTotal = subTotal + taxes - (this.props.financingTemp.plan_advance_payment / 100);

        let Plan = {
            firstPayment: this.props.financingTemp.financing_plan.first_payment,
            monthlyFixed: this.props.financingTemp.financing_plan.monthly_fixed,
            monthlyFixedAmount: this.props.financingTemp.financing_plan.monthly_fixed_amount / 100,
            firstPaymentAmount: this.props.financingTemp.financing_plan.first_payment_amount / 100,
            charge: this.props.financingTemp.financing_plan.charge,
            currency: this.props.financingTemp.financing_plan.currency,
            minAdvancePayment: this.props.financingTemp.financing_plan.min_advance_payment,
            payments: this.props.financingTemp.financing_plan.payments
        };


        return calculateFinancing(this.props.financingTemp.financing_plan.payments, Plan, subTotal);

    }

    renderPlanInfo() {

        let calculos = this.calculateDataNum();
        const payments = calculos.restPayments;
        const firstPayment = calculos.gastos_cierre;
        const total = calculos.rawTotal;


        return (
            <div className="product-detail-box row mgL0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Entidad seleccionada</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-md-5 align-center">
                    <ImagePreview
                        style = {
                            {
                                height: '70px',
                                width: 'inherit',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain'
                            }
                        }
                        className="image-preview"
                        image={'/pictures/logos/' + this.props.financingTemp.financing_plan.financing_entity.logo}>
                    </ImagePreview>
                </div>
                <div className="col-md-7">
                    <p className="subtitle-1">{this.props.financingTemp.financing_plan.financing_entity.name}</p>
                    <p>Total a pagar: <strong>{this.props.financingTemp.financing_plan.currency.symbol + '$ ' + money(total + this.props.financingTemp.plan_advance_payment / 100)}</strong></p>
                </div>
                <div className="col-md-12">
                    <strong><p className="mgB0">Detalles del plan</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Plan</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.financing_plan.name }</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Cuotas</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.financing_plan.payments } X {payments} </p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Gastos de Cierre inicial</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{firstPayment}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Adelanto</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{(parseInt(this.props.financingTemp.order_external.currencyId) === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(this.props.financingTemp.plan_advance_payment / 100)}</p></strong>
                </div>
                <React.Fragment>
                    <hr className="w-95" />
                    <div className="col-md-6">
                        <p className="mgB0" style={{color: '#616161'}}>Tasa</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financingTemp.financing_plan.charge * 100}%</p></strong>
                    </div>
                    <hr className="w-95" />
                    <div className="col-md-6">
                        <p className="mgB0" style={{color: '#616161'}}>Fijo mensual</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <strong><p className="mgB0" style={{color: '#616161'}}>{(this.props.financingTemp.financing_plan.monthly_fixed * 100).toFixed(2)}%</p></strong>
                    </div>
                    <hr className="w-95" />
                    <div className="col-md-6">
                        <p className="mgB0" style={{color: '#616161'}}>Fijo mensual RD$</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <strong><p className="mgB0" style={{color: '#616161'}}>{money(this.props.financingTemp.financing_plan.monthly_fixed_amount / 100)}</p></strong>
                    </div>
                </React.Fragment>
            </div>
        );
    }

    renderProductInfo() {
        let cart = this.props.financingTemp.order_external;

        return (
            <div className="product-detail-box row mgL0 mgR0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Datos de los productos</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-md-12">
                    <CartComponent
                        user={null}
                        cart={cart}
                        className="col-12"
                    />
                </div>
            </div>
        );
    }

    renderSpouseFields() {
        return (
            <React.Fragment>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-8 mgB2"
                    name="spouseName"
                    errorClassName={this.state.errorFields.spouseName.errorClass}
                    value={this.state.financingData.spouseName}>
                    <TextInput
                        label={<span>Nombre completo del cónyuge <span className="color-red">*</span></span>}
                        numbers={false}
                        dangerLabel={this.state.errorFields.spouseName.dangerLabel}
                        placeholder="Inserte el nombre"
                        value={this.state.financingData.spouseName}
                        onChange={(val) => this.onFieldChange('spouseName', val)}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    required
                    className="col-12 col-md-4 mgB2"
                    name="spousePhone"
                    validations="isValidDOMPhone"
                    errorClassName={this.state.errorFields.spousePhone.errorClass}
                    validationError = "Por favor ingrese un número de telefono válido."
                    value={this.state.financingData.spousePhone}>
                    <TextInput
                        label={<span>Teléfono del cónyuge<span className="color-red">*</span></span>}
                        dangerLabel={this.state.errorFields.spousePhone.dangerLabel}
                        value={this.state.financingData.spousePhone}
                        onChange={(val) => this.onFieldChange('spousePhone', val)}
                        maxLength={10}
                        numbers={true}
                        onlyNumbers={true}
                    />

                </FormsyInputWrapper>
                <FormsyInputWrapper
                    className="col-12 col-md-4 mgB2"
                    name="spouseIdentificationType">
                    <Radio
                        selectedValue={this.state.financingData.spouseIdentificationType}
                        radioStyle={{ padding: '0px' }}
                        key={'spouseIdentificationType'}
                        optionsClassName="bordered mgR"
                        className="d-flex"
                        labelStyle={{paddingRight: '5px'}}
                        inputStyle={{height: '44px', padding: '7px'}}
                        onChange={(val) => this.onFieldChange('spouseIdentificationType', val)}
                        label="Doc. de identidad del cónyuge"
                        options={this.props.dropdowns.identificationTypes.map(element => {
                            return {
                                value: element.value,
                                label: element.label
                            };
                        })}
                    />
                </FormsyInputWrapper>
                {this.state.financingData.spouseIdentificationType === IDENTIFICATION_TYPES.CEDULA &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB"
                        name="spouseIdentification"
                        value={this.state.financingData.spouseIdentification}
                        validationError='Ingrese una cédula válida.'
                        validations={this.state.financingData.spouseIdentification !== '' ? 'isValidIdentification' : null}>
                        <TextInput
                            label={<span>No. Documento de identidad<span className="color-red">*</span></span>}
                            value={this.state.financingData.spouseIdentification}
                            onChange={(val) => this.onFieldChange('spouseIdentification', val)}
                            maxLength={11}
                            numbers={true}
                            onlyNumbers={true}
                        />

                    </FormsyInputWrapper>
                }
                {this.state.financingData.spouseIdentificationType === IDENTIFICATION_TYPES.PASSPORT &&
                    <FormsyInputWrapper
                        className="col-12 col-md-4 mgB2"
                        value={this.state.financingData.spouseIdentification}
                        name="spouseIdentification">
                        <TextInput
                            value={this.state.financingData.spouseIdentification}
                            label="No. Documento de identidad"
                            onChange={(val) => this.onFieldChange('spouseIdentification', val)}
                        />
                    </FormsyInputWrapper>
                }

            </React.Fragment>
        );
    }

    renderCreateProfile() {
        return (
            <React.Fragment>
                <div className="row mgL0 mgR0 white-background " style={{padding:'15px 15px 15px 15px'}}>
                    <div className="col-md-6 col-6 align-center">
                        <strong><p style={{fontSize: '18px'}} className="mgB0">Perfil Financiero</p></strong>
                    </div>
                    <div className="col-md-6 col-6 text-md-right">
                        <button
                            disabled={!this.state.disabledProfile}
                            onClick={() => this.setState({disabledProfile: false})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px', textDecoration:'none'}} className={PENCIL_ICON}/>
                                Completar Perfil
                            </strong>
                        </button>
                    </div>
                    <hr className="w-100" />
                    <Formsy
                        ref={this.basicDataRef}
                        onValid={this.enableSubmit}
                        onInvalid={this.disableSubmit}
                        disabled={this.state.disabledProfile}>
                        <div className="row mgB2">
                            <FormsyInputWrapper
                                required
                                className="col-6 col-md-2 mgB2"
                                name="name"
                                errorClassName={this.state.errorFields.name.errorClass}
                                value={this.state.financingData.name}>
                                <TextInput
                                    label={<span>Nombre <span className="color-red">*</span></span>}
                                    numbers={false}
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.name.dangerLabel}
                                    placeholder="Inserte el nombre"
                                    value={this.state.financingData.name}
                                    onChange={(val) => this.onFieldChange('name', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-6 col-md-3 mgB2"
                                name="lastname"
                                errorClassName={this.state.errorFields.lastname.errorClass}
                                value={this.state.financingData.lastname}>
                                <TextInput
                                    label={<span>Apellidos <span className="color-red">*</span></span>}
                                    numbers={false}
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.lastname.dangerLabel}
                                    placeholder="Inserte los apellidos"
                                    value={this.state.financingData.lastname}
                                    onChange={(val) => this.onFieldChange('lastname', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-4 mgB2"
                                name="identificationType"
                                errorClassName={this.state.errorFields.identificationType.errorClass}
                                value={this.state.financingData.identificationType !== null && this.state.financingData.identificationType !== undefined}
                                validations="isTrue">
                                <Radio
                                    selectedValue={this.state.financingData.identificationType}
                                    disabled={this.state.disabledProfile}
                                    radioStyle={{ padding: '0px' }}
                                    optionsClassName="bordered mgR"
                                    className="d-flex"
                                    labelStyle={{paddingRight: '5px'}}
                                    inputStyle={{height: '44px', padding: '7px'}}
                                    onChange={(val) => this.onFieldChange('identificationType', val)}
                                    label={<span>Documento de identidad <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.identificationType.dangerLabel}
                                    options={this.props.dropdowns.identificationTypes.map(element => {
                                        return {
                                            value: element.value,
                                            label: element.label
                                        };
                                    })}
                                />
                            </FormsyInputWrapper>
                            {this.state.financingData.identificationType === IDENTIFICATION_TYPES.CEDULA &&
                                <FormsyInputWrapper
                                    required
                                    className="col-12 col-md-3 mgB"
                                    name="identification"
                                    errorClassName={this.state.errorFields.identification.errorClass}
                                    value={this.state.financingData.identification}
                                    validationError='Ingrese una cédula válida.'
                                    validations='isValidIdentification'>
                                    <TextInput
                                        label={<span>No. Documento <span className="color-red">*</span></span>}
                                        id="identificationdata"
                                        // className={`${(!(this.state.financingData.identification.length === 11) || _.isNumber(this.state.financingData.identification) ) ? 'border-red' : ''} `}
                                        disabled={this.state.disabledProfile}
                                        dangerLabel={this.state.errorFields.identification.dangerLabel}
                                        value={this.state.financingData.identification}
                                        onChange={(val) => this.onFieldChange('identification', val)}
                                        maxLength={11}
                                        numbers={true}
                                        onlyNumbers={true}
                                    />
                                </FormsyInputWrapper>
                            }
                            {this.state.financingData.identificationType === IDENTIFICATION_TYPES.PASSPORT &&
                                <FormsyInputWrapper
                                    required
                                    className="col-12 col-md-3 mgB2"
                                    errorClassName={this.state.errorFields.identification.errorClass}
                                    value={this.state.financingData.identification}
                                    name="identification">
                                    <TextInput
                                        label={<span>No. Documento<span className="color-red">*</span></span>}
                                        dangerLabel={this.state.errorFields.identification.dangerLabel}
                                        value={this.state.financingData.identification}
                                        disabled={this.state.disabledProfile}
                                        onChange={(val) => this.onFieldChange('identification', val)}
                                    />
                                </FormsyInputWrapper>
                            }
                            <FormsyInputWrapper
                                className="col-12 col-md-6 mgB2"
                                name="identificationFile"
                                value={this.state.financingData.identificationFile}>
                                <p className={`form-label `}>
                                    <span className="label">Documento de identidad de ambos lados</span>
                                </p>
                                <Dropzone
                                    accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                                    onDrop={this.onIdentificationFileChanged}
                                    multiple={false}
                                    full={true}
                                    disabled={this.state.disabledProfile} >
                                    <div>
                                        {this.state.financingData.identificationFile &&
                                            <div className="m-auto">
                                                {(this.state.financingData.identificationFile.name ? this.state.financingData.identificationFile.name.toLowerCase().includes('.pdf') : this.state.financingData.identificationFile.toLowerCase().includes('.pdf')) &&
                                                    <Document
                                                        file={this.state.financingData.identificationFile}>
                                                        <Page height={300} pageNumber={1} />
                                                    </Document>
                                                }
                                                {(this.state.financingData.identificationFile.name ? this.state.financingData.identificationFile.name.toLowerCase().includes('.doc') : this.state.financingData.identificationFile.toLowerCase().includes('.doc')) &&
                                                    <div className="mgT15">
                                                        <div className="mgB">
                                                            <span style={{fontSize: '4rem'}}>
                                                                <i className = {MS_WORD_ICON}/>
                                                            </span>
                                                        </div>
                                                        <p>{this.state.financingData.identificationFile.name}</p>
                                                    </div>
                                                }
                                                {!(this.state.financingData.identificationFile.name ? this.state.financingData.identificationFile.name.toLowerCase().includes('.pdf') : this.state.financingData.identificationFile.toLowerCase().includes('.pdf')) &&
                                                    !(this.state.financingData.identificationFile.name ? this.state.financingData.identificationFile.name.toLowerCase().includes('.doc') : this.state.financingData.identificationFile.toLowerCase().includes('.doc')) &&
                                                    <ImagePreview
                                                        style = {
                                                            {
                                                                height: '300px',
                                                                width: '100%',
                                                                backgroundPosition: 'center',
                                                                backgroundSize: 'contain',
                                                                backgroundRepeat: 'no-repeat'
                                                            }
                                                        }
                                                        className="image-preview image-preview__xl"
                                                        image={this.state.financingData.identificationFile}>
                                                    </ImagePreview>
                                                }
                                            </div>
                                        }
                                        {!this.state.financingData.identificationFile &&
                                            <div className="mgT15">
                                                <EmptyState
                                                    imgStyle={
                                                        {
                                                            borderRadius: '150px',
                                                            margin: 'auto',
                                                            width: '170px',
                                                            padding: '20px'
                                                        }
                                                    }
                                                    small={true}
                                                    image="/images/add_image.svg"
                                                    title=""
                                                    text=""
                                                />
                                                <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento)</p>
                                            </div>
                                        }
                                    </div>
                                </Dropzone>
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                className="col-12 col-md-6 mgB2"
                                name="identificationFile2">
                                <Dropzone
                                    accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                                    onDrop={this.onIdentificationFile2Changed}
                                    multiple={false}
                                    style={{marginTop: '23px'}}
                                    full={true}
                                    disabled={this.state.disabledProfile} >
                                    <div>
                                        {this.state.financingData.identificationFile2 &&
                                            <div className="m-auto">
                                                {(this.state.financingData.identificationFile2.name ? this.state.financingData.identificationFile2.name.toLowerCase().includes('.pdf') : this.state.financingData.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                    <Document
                                                        file={this.state.financingData.identificationFile2}>
                                                        <Page height={300} pageNumber={1} />
                                                    </Document>
                                                }
                                                {(this.state.financingData.identificationFile2.name ? this.state.financingData.identificationFile2.name.toLowerCase().includes('.doc') : this.state.financingData.identificationFile2.toLowerCase().includes('.doc')) &&
                                                    <div className="mgT15">
                                                        <div className="mgB">
                                                            <span style={{fontSize: '4rem'}}>
                                                                <i className = {MS_WORD_ICON}/>
                                                            </span>
                                                        </div>
                                                        <p>{this.state.financingData.identificationFile2.name}</p>
                                                    </div>
                                                }
                                                {!(this.state.financingData.identificationFile2.name ? this.state.financingData.identificationFile2.name.toLowerCase().includes('.pdf') : this.state.financingData.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                    !(this.state.financingData.identificationFile2.name ? this.state.financingData.identificationFile2.name.toLowerCase().includes('.doc') : this.state.financingData.identificationFile2.toLowerCase().includes('.doc')) &&
                                                    <ImagePreview
                                                        style = {
                                                            {
                                                                height: '300px',
                                                                width: '100%',
                                                                backgroundPosition: 'center',
                                                                backgroundSize: 'contain',
                                                                backgroundRepeat: 'no-repeat'
                                                            }
                                                        }
                                                        className="image-preview image-preview__xl"
                                                        image={this.state.financingData.identificationFile2}>
                                                    </ImagePreview>
                                                }
                                            </div>
                                        }
                                        {!this.state.financingData.identificationFile2 &&
                                            <div className="mgT15">
                                                <EmptyState
                                                    imgStyle={
                                                        {
                                                            borderRadius: '150px',
                                                            margin: 'auto',
                                                            width: '170px',
                                                            padding: '20px'
                                                        }
                                                    }
                                                    small={true}
                                                    image="/images/add_image.svg"
                                                    title=""
                                                    text=""
                                                />
                                                <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento) (opcional)</p>
                                            </div>
                                        }
                                    </div>
                                </Dropzone>
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-7 mgB2"
                                name="email"
                                validations="isEmail"
                                errorClassName={this.state.errorFields.email.errorClass}
                                validationError="Por favor ingrese un correo electrónico válido."
                                value={this.state.financingData.email}>
                                <TextInput
                                    label={<span>Correo electrónico <span className="color-red">*</span></span>}
                                    placeholder="Inserte el correo"
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.email.dangerLabel}
                                    value={this.state.financingData.email}
                                    onChange={(val) => this.onFieldChange('email', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-5 mgB2"
                                name="phone"
                                validations="isValidDOMPhone"
                                errorClassName={this.state.errorFields.phone.errorClass}
                                validationError = "Por favor ingrese un número de telefono válido."
                                value={this.state.financingData.phone}>
                                <TextInput
                                    label={<span>Teléfono <span className="color-red">*</span></span>}
                                    id="phonedata"
                                    dangerLabel={this.state.errorFields.phone.dangerLabel}
                                    value={this.state.financingData.phone}
                                    disabled={this.state.disabledProfile}
                                    onChange={(val) => this.onFieldChange('phone', val)}
                                    maxLength={10}
                                    numbers={true}
                                    onlyNumbers={true}
                                />

                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-4 mgB2"
                                name="livingType"
                                errorClassName={this.state.errorFields.livingType.errorClass}
                                value={this.state.financingData.livingType !== null && this.state.financingData.livingType !== undefined}
                                validations="isTrue">
                                <Radio
                                    selectedValue={this.state.financingData.livingType}
                                    disabled={this.state.disabledProfile}
                                    radioStyle={{ padding: '0px' }}
                                    optionsClassName="bordered mgR"
                                    className="d-flex"
                                    labelStyle={{paddingRight: '5px'}}
                                    inputStyle={{height: '44px', padding: '7px'}}
                                    onChange={(val) => this.onFieldChange('livingType', val)}
                                    label={<span>Tipo de vivienda <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.livingType.dangerLabel}
                                    options={this.props.dropdowns.livingTypes.map(element => {
                                        return {
                                            value: element.value,
                                            label: element.label
                                        };
                                    })}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-8 mgB2"
                                name="livingTime"
                                errorClassName={this.state.errorFields.livingTime.errorClass}
                                value={this.state.financingData.livingTime !== null && this.state.financingData.livingTime !== undefined}
                                validations="isTrue">
                                <SelectInput
                                    floatingLabel={<span>Tiempo en la vivienda <span className="color-red">*</span></span>}
                                    disabled={this.state.disabledProfile}
                                    key={'userData.livingTime'}
                                    dangerLabel={this.state.errorFields.livingTime.dangerLabel}
                                    label="Seleccione una opción"
                                    options={this.props.dropdowns.livingTimes}
                                    value={this.state.financingData.livingTime}
                                    onChange={(val) => this.onFieldChange('livingTime', val)}
                                />
                            </FormsyInputWrapper>
                            <h3 className="mgT col-12">
                                <strong>Datos adicionales</strong>
                            </h3>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-12 mgB2"
                                name="isMarried"
                                errorClassName={this.state.errorFields.isMarried.errorClass}
                                value={this.state.financingData.isMarried !== null && this.state.financingData.isMarried !== undefined}
                                validations="isTrue">
                                <Radio
                                    selectedValue={this.state.financingData.isMarried}
                                    disabled={this.state.disabledProfile}
                                    radioStyle={{ padding: '0px' }}
                                    optionsClassName="bordered mgR"
                                    className="d-flex"
                                    labelStyle={{paddingRight: '5px'}}
                                    inputStyle={{height: '44px', padding: '7px'}}
                                    onChange={(val) => this.onFieldChange('isMarried', val)}
                                    label={<span>Estado civil <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.isMarried.dangerLabel}
                                    options={[
                                        {value: false, label: 'Soltero/a'},
                                        {value: true, label: 'Casado/a'}
                                    ]}
                                />
                            </FormsyInputWrapper>
                            {this.state.financingData.isMarried &&
                                this.renderSpouseFields()
                            }
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-8 mgB2"
                                name="relativeName"
                                errorClassName={this.state.errorFields.relativeName.errorClass}
                                value={this.state.financingData.relativeName}>
                                <TextInput
                                    label={<span>Nombre completo de un familiar <span className="color-red">*</span></span>}
                                    numbers={false}
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.relativeName.dangerLabel}
                                    placeholder="Inserte el nombre"
                                    value={this.state.financingData.relativeName}
                                    onChange={(val) => this.onFieldChange('relativeName', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-4 mgB2"
                                name="relativePhone"
                                validations="isValidDOMPhone"
                                errorClassName={this.state.errorFields.relativePhone.errorClass}
                                validationError = "Por favor ingrese un número de telefono válido."
                                value={this.state.financingData.relativePhone}>
                                <TextInput
                                    label={<span>Teléfono del familiar  <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.relativePhone.dangerLabel}
                                    disabled={this.state.disabledProfile}
                                    value={this.state.financingData.relativePhone}
                                    onChange={(val) => this.onFieldChange('relativePhone', val)}
                                    maxLength={10}
                                    numbers={true}
                                    onlyNumbers={true}
                                />

                            </FormsyInputWrapper>
                            <h3 className="mgT col-12">
                                <strong>Datos laborales</strong>
                            </h3>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-8 mgB2"
                                name="companyName"
                                errorClassName={this.state.errorFields.companyName.errorClass}
                                value={this.state.financingData.companyName}>
                                <TextInput
                                    label={<span>Nombre de la empresa donde labora <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.companyName.dangerLabel}
                                    disabled={this.state.disabledProfile}
                                    value={this.state.financingData.companyName}
                                    onChange={(val) => this.onFieldChange('companyName', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-4 mgB2"
                                name="companyType"
                                validations="isTrue"
                                errorClassName={this.state.errorFields.companyType.errorClass}
                                value={this.state.financingData.companyType !== null}>
                                <SelectInput
                                    floatingLabel={<span>Tipo de empresa <span className="color-red">*</span></span>}
                                    key={'data.companyType'}
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.companyType.dangerLabel}
                                    label="Seleccione una opción"
                                    options={this.props.dropdowns.companyTypes}
                                    value={this.state.financingData.companyType}
                                    onChange={(val) => this.onFieldChange('companyType', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-8 mgB2"
                                name="companyStreet"
                                errorClassName={this.state.errorFields.companyStreet.errorClass}
                                value={this.state.financingData.companyStreet}>
                                <TextInput
                                    label={<span>Dirección de la empresa <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.companyStreet.dangerLabel}
                                    disabled={this.state.disabledProfile}
                                    value={this.state.financingData.companyStreet}
                                    onChange={(val) => this.onFieldChange('companyStreet', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-4 mgB2"
                                name="companyPhone"
                                validations="isValidDOMPhone"
                                errorClassName={this.state.errorFields.companyPhone.errorClass}
                                validationError = "Por favor ingrese un número de telefono válido."
                                value={this.state.financingData.companyPhone}>
                                <TextInput
                                    label={<span>Teléfono de la empresa  <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.companyPhone.dangerLabel}
                                    value={this.state.financingData.companyPhone}
                                    disabled={this.state.disabledProfile}
                                    onChange={(val) => this.onFieldChange('companyPhone', val)}
                                    maxLength={10}
                                    numbers={true}
                                    onlyNumbers={true}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-3 mgB2"
                                name="companyTitle"
                                errorClassName={this.state.errorFields.companyTitle.errorClass}
                                value={this.state.financingData.companyTitle}>
                                <TextInput
                                    label={<span>Cargo que ocupa <span className="color-red">*</span></span>}
                                    dangerLabel={this.state.errorFields.companyTitle.dangerLabel}
                                    disabled={this.state.disabledProfile}
                                    value={this.state.financingData.companyTitle}
                                    onChange={(val) => this.onFieldChange('companyTitle', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-3 mgB2"
                                name="workingTime"
                                errorClassName={this.state.errorFields.workingTime.errorClass}
                                value={this.state.financingData.workingTime !== null && this.state.financingData.workingTime !== undefined}
                                validations="isTrue"
                                disabled={this.state.disabledProfile} >
                                <SelectInput
                                    floatingLabel={<span>Tiempo en la empresa <span className="color-red">*</span></span>}
                                    key={'financingData.workingTime'}
                                    dangerLabel={this.state.errorFields.workingTime.dangerLabel}
                                    disabled={this.state.disabledProfile}
                                    label="Seleccione una opción"
                                    options={this.props.dropdowns.workingTimes}
                                    value={this.state.financingData.workingTime}
                                    onChange={(val) => this.onFieldChange('workingTime', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 col-md-3 mgB2"
                                name="salary"
                                errorClassName={this.state.errorFields.salary.errorClass}
                                value={this.state.financingData.salary}>
                                <MoneyInput
                                    floatingLabel={<span>Sueldo <span className="color-red">*</span></span>}
                                    placeholder="RD$"
                                    disabled={this.state.disabledProfile}
                                    dangerLabel={this.state.errorFields.salary.dangerLabel}
                                    value={this.state.financingData.salary}
                                    onChange={(val) => this.onFieldChange('salary', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                className="col-12 col-md-3 mgB2"
                                name="otherIncomes"
                                value={this.state.financingData.otherIncomes}>
                                <TextInput
                                    label={<span>Otros ingresos </span>}
                                    disabled={this.state.disabledProfile}
                                    value={this.state.financingData.otherIncomes}
                                    onChange={(val) => this.onFieldChange('otherIncomes', val)}
                                />
                            </FormsyInputWrapper>
                        </div>
                    </Formsy>
                    {this.props.error &&
                        <div className='has-error mgB'>
                            {typeof this.props.error === 'string' &&
                                <span className='help-block'>{this.props.error}</span>
                            }
                            {typeof this.props.error === 'object' &&
                                Object.entries(this.props.error).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <hr />
                    <div className="col-12" >
                        <div className="mgT" style={{float:'right'}}>
                            <RequiredFieldsWrapper
                                onSubmit={this.onSubmit}
                                validSubmit={this.validSubmit}
                                containerId='create-financing-profile'
                                onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                                errorFields={this.state.errorFields}
                                fields={this.state.financingData}>
                                <button
                                    id="submit-button"
                                    disabled={this.state.disabledProfile}
                                    className={this.validSubmit() ? 'btn btn-primary' :'btn btn-clickable-pseudo-disabled '}>
                                Guardar
                                </button>
                            </RequiredFieldsWrapper>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onSubmit = () => {
        this.setState({disabledProfile: true, canSubmit: true});
        this.props.onSubmit(this.state.financingData);

    };

    render() {
        return (
            <React.Fragment>
                {!this.props.isMobile &&
                 <div className="row mgB">
                     <div className="col-md-6 col-12">{this.renderClientInfo()}</div>
                     <div className="col-md-6 col12">{this.renderPlanInfo()}</div>
                     <hr className="w-100 mgT3 mgB2"/>
                     <div className="col-12">{this.renderProductInfo()}</div>
                     <hr className="w-100 mgT3 mgB2"/>
                     <div className="col-12">{this.renderCreateProfile()}</div>
                 </div>}
                {this.props.isMobile &&
                 <div className="row mgB">
                     <div className="col-12">{this.renderClientInfo()}</div>
                     <hr className="mgT3 mgB2"/>
                     <div className="col-12">{this.renderPlanInfo()}</div>
                     <hr className="mgT3 mgB2"/>
                     <div className="col-12">{this.renderProductInfo()}</div>
                     <hr className="w-100 mgT3 mgB2"/>
                     <div className="col-12">{this.renderCreateProfile()}</div>
                 </div>}


            </React.Fragment>
        );

    }



}

/**
 * Properties validations.
 */
FinancingNotCompletedDetailContainer.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    financingTemp: PropTypes.object
};

export default connect(
    null,
    null
)(FinancingNotCompletedDetailContainer);