import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

export default class DataTable extends React.Component {

    render() {
        const minRows = this.props.hideExtraColumns ? 0 : this.props.minRows;
        const hasItems = this.props.data.length !== 0;

        return (
            <ReactTable
                manual = {this.props.manual}
                className={`dt ${this.props.className ? this.props.className : ""}`}
                data={this.props.data}
                style={this.props.style}
                columns={this.props.columns}
                getTdProps={this.props.getTdProps}
                getTheadThProps={this.props.getTheadThProps}
                page={this.props.page}
                getTrProps={this.props.getTrProps}
                showPagination={false}
                multiSort={false}
                filtered={this.props.filtered}
                resizable={this.props.resizable}
                sorted={this.props.sorted}
                loading={this.props.loading}
                onSortedChange={this.props.onSortedChange}
                defaultPageSize={this.props.defaultPageSize}
                pageSizeOptions={this.props.pageSizeOptions}
                pageSize={this.props.pageSize}
                minRows={hasItems ? minRows : 2} />
        );
    }
}

/**
 * Default properties.
 */
DataTable.defaultProps = {
    manual: true,
    sorted: [{}],
    resizable: false,
    defaultPageSize: 10,
    filtered: [{}]
};

/**
 * Datatable prop types.
 */
DataTable.propTypes = {
    manual: PropTypes.bool,
    loading: PropTypes.bool,
    filtered: PropTypes.array,
    className: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array.isRequired,
    page: PropTypes.number,
    getTheadThProps: PropTypes.func,
    sorted: PropTypes.array.isRequired,
    onSortedChange: PropTypes.func,
    getTrProps: PropTypes.func,
    resizable: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    minRows: PropTypes.number,
    pageSize: PropTypes.number,
    hideExtraColumns: PropTypes.bool
};
