import React from 'react';
import PropTypes from 'prop-types';


// Redux & React Router.
import { connect } from 'react-redux';
import { Redirect, BrowserRouter, Switch, Route } from 'react-router-dom';

// Wrappers
import AuthWrapper from './auth-wrapper';
import GuestWrapper from './guest-wrapper';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';

// Containers
import LoginContainer from './login';
import NotFoundContainer from './404';
import ForgotPasswordContainer from './forgot-password';
import ResetPasswordContainer from './reset-password';
import InConstructionContainer from './in-construction';
import TransactionsContainer from './transactions';
import UsersContainer from './users';
import UsersDetailContainer from './users/users-detail';
import UsersWelcomeContainer from './users/user-welcome';
import ClientsContainer from './clients';
import DashboardContainer from './dashboard';
import FinancingsContainer from './financings';
import FinancingEntityContainer from './financing-entity';
import PromotionsContainer from './promotions';
import InstallmentsContainer from './installments';
// import RegisterContainer from './register';

import { USERTYPES } from '../common/constants/enums.constant';
import TransactionCheckoutContainer from './transactions/transaction-checkout';

/**
 * Connect the Root element to the redux store.
 */
function mapStateToProps(state) {
    return {
        authUser: userSelectors.getAuthUser(state),
        creatingFinancing: transactionSelectors.getCreatingFinancing(state)
    };
}

const HalfConditionalRoute = (props) => {
    if(props.authUser.userType.id !== USERTYPES.AGENT) {
        return <Route {...props} />;
    }
    return <Route path="/:route" component={NotFoundContainer} />;
};

const ConditionalRoute = (props) => {
    if(props.authUser.userType.id !== USERTYPES.CLIENT && props.authUser.userType.id !== USERTYPES.AGENT) {
        return <Route {...props} />;
    }
    return <Route path="/:route" component={NotFoundContainer} />;
};

/**
 * Root container, which wrap the application to be used with React-Router.
 * Routing logic is also contained here.
 */
class Root extends React.Component {

    /**
     * Initializes the component.
     */
    constructor(props) {
        super(props);

        // Renders
        this.renderGuestRoutes = this.renderGuestRoutes.bind(this);
        this.renderAuthenticatedRoutes = this.renderAuthenticatedRoutes.bind(this);
    }

    /**
     * Renders the authenticated user's routes.
     *
     * @return {ReactComponent}
     */
    renderAuthenticatedRoutes() {
        return (
            <BrowserRouter>
                <AuthWrapper user={this.props.authUser}>
                    <Switch>
                        <Route exact path="/">
                            {this.props.authUser.userType.id === USERTYPES.CLIENT || this.props.authUser.userType.id === USERTYPES.AGENT ? <Redirect to="/financings" /> : <DashboardContainer />}
                        </Route>
                        <HalfConditionalRoute authUser={this.props.authUser} path="/transactions" component={TransactionsContainer} />
                        <Route path="/financings" component={FinancingsContainer} />

                        <ConditionalRoute authUser={this.props.authUser} path="/clients" component={ClientsContainer} />
                        <ConditionalRoute authUser={this.props.authUser} path="/financing-entities" component={FinancingEntityContainer} />
                        <ConditionalRoute authUser={this.props.authUser} path="/promotions" component={PromotionsContainer} />
                        <ConditionalRoute authUser={this.props.authUser} path="/installments" component={InstallmentsContainer} />
                        <ConditionalRoute authUser={this.props.authUser} path="/users" component={UsersContainer} />

                        <Route path="/my-profile" component={UsersDetailContainer} />

                        <Route path="/:route" component={NotFoundContainer} />
                        <Redirect exact to={{location: '/'}} />
                    </Switch>
                </AuthWrapper>
            </BrowserRouter>
        );
    }

    /**
     * Renders the guest routes.
     *
     * @return {ReactComponent}
     */
    renderGuestRoutes() {
        let params = location.search;

        return (
            <BrowserRouter>
                <GuestWrapper>
                    <Switch>
                        <Route exact path="/" component={LoginContainer} />
                        <Route path="/welcome" component={UsersWelcomeContainer} />
                        <Route path="/register" component={InConstructionContainer} />
                        <Route exact path="/transactions/checkout" component={TransactionCheckoutContainer} />
                        <Route path="/forgot-password" component={ForgotPasswordContainer} />
                        <Route path="/reset-password" component={ResetPasswordContainer} />
                        <Redirect exact to={{location: '/', search: params}} />
                    </Switch>
                </GuestWrapper>
            </BrowserRouter>
        );
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        const user = this.props.authUser;

        if (user) {
            if (!user.userType) {
                location.reload();
            }
        }

        return ((user && location.pathname !== '/reset-password' && !this.props.creatingFinancing))
            ? this.renderAuthenticatedRoutes()
            : this.renderGuestRoutes();
    }
}

/**
 * Root properties validation.
 */
Root.propTypes = { authUser: PropTypes.object };

export default connect(
    mapStateToProps,
    null
)(Root);
