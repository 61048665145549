import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/**
 * Represents a simple select.
 */
export default class SelectInput extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props}  the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            isMobile: window.matchMedia('(max-width: 600px)').matches
        };

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    checkDeviceFunction = () => {
        this.setState({isMobile: window.matchMedia('(max-width: 600px)').matches});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkDeviceFunction, true);
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkDeviceFunction, true);
    }

    onBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    };

    onFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    onInputChange(input) {
        if (this.props.onInputChange) {
            if (this.props.onlyNumbers) {
                if (isNaN(input)) {
                    return;
                }
            }
            if (!this.props.numbers) {
                this.props.onInputChange(input.replace(/[0-9]/g, ''));
            } else {
                this.props.onInputChange(input);
            }
        }
        if (!this.props.numbers) {
            this.setState({ inputValue: input.replace(/[0-9]/g, '') });
        } else {
            this.setState({ inputValue: input });
        }
    }

    /**
     * Fired once the select changes.
     *
     * @param {int|null} value  contains the input's new value.
     */
    onSelectChange(value) {
        if (this.props.onChange) {
            if (value.value === -1) {
                this.props.onChange(null);
                return;
            }
            if (Array.isArray(value)) {
                if (value) {
                    this.props.onChange(value.map(element => element.value));
                    return;
                }
            }
            if (value) {
                this.props.onChange(value.value, value.label);
                return;
            }
        }
    }
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        let { options, label, value, searchable, clearable, withSearchIcon, withPlusIcon } = this.props;

        if (this.props.withAllOption && options.length > 0 && options[0].value !== -1) {
            options.unshift({value: -1, label: this.props.withAllOption});
        }

        if (!this.props.withAllOption && options.length > 0 && options[0].value === -1) {
            options.shift();
        }

        return (
            <div onTouchStart={this.props.onTouchStart} onClick={this.props.onClick} className={`form-label select-container${this.props.icon || withSearchIcon ? '--with-icon' : ''} ${this.props.innerIcon ? 'with-inner-icon' : ''} ${this.props.wrapperClassName}`}>
                {this.props.floatingLabel &&
                    <span className={`label ${this.props.labelClassName} ${this.props.dangerLabel ? 'text-danger' : ''}`} style={this.props.labelStyle}>
                        {this.props.floatingLabel}
                    </span>
                }
                <Select
                    className={`${this.props.className ? this.props.className : ''} select-default ${this.props.innerIcon ? 'with__icon' : ''}`}
                    classNamePrefix={`${this.props.classNamePrefix || ''}`}
                    disabled={this.props.disabled}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    ref={this.props.wrapperRef}
                    onChange={(val) => this.onSelectChange(val)}
                    isSearchable={this.state.isMobile ? false : searchable}
                    id={this.props.id}
                    closeOnSelect={this.props.closeOnSelect}
                    inputValue={this.state.inputValue}
                    onInputChange={this.onInputChange}
                    tabIndex={this.props.tabIndex}
                    menuShouldScrollIntoView={true}
                    arrowRenderer={withPlusIcon ? () => { } : undefined}
                    isClearable={clearable}
                    blurInputOnSelect={true}
                    menuIsOpen={this.props.open}
                    noResultsText={this.props.noResultsText || undefined}
                    menuRenderer={this.props.menuRenderer || undefined}
                    optionRenderer={this.props.optionRenderer || undefined}
                    isMulti={this.props.multi}
                    onMenuOpen={this.props.onMenuOpen || undefined}
                    labelKey={this.props.labelKey}
                    valueKey={this.props.valueKey}
                    value={options.filter(option => option.value === value)[0] ? options.filter(option => option.value === value)[0] : null}
                    placeholder={this.props.withAllOption ? this.props.withAllOption : label}
                    options={options}
                    formatCreateLabel={userInput => `${userInput}`}
                />
            </div>
        );
    }
}

/**
 * Select properties validation.
 */
Select.defaultProps = {
    label: '',
    open: undefined,
    floatingLabel: '',
    showValue: true,
    searchable: true,
    truncable: false,
    withAllOption: false,
    clearable: false,
    closeOnSelect: true,
    labelKey: 'name',
    valueKey: 'id',
    multi: false,
    withSearchIcon: false,
    withPlusIcon: false,
    onlyNumbers: false,
    numbers: true,
    dangerLabel: false
};

/**
 * Select properties validation.
 */
Select.propTypes = {
    withSearchIcon: PropTypes.bool,
    withPlusIcon: PropTypes.bool,
    multi: PropTypes.bool,
    showValue: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    disabled: PropTypes.bool,
    floatingLabel: PropTypes.string,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    dangerLabel: PropTypes.bool
};
