import Color from './color.constant';

/***
 ***
 *** This file predefines some chart.js settings.
 ***
 ***/


const DATASET_GREEN = {
    lineTension: 0,
    pointHoverBorderWidth: 2,
    borderColor: Color.names.GREEN,
    pointBorderColor: Color.names.GREEN,
    pointRadius: 2,
    pointHoverRadius: 4,
    pointHitRadius: 8,
    borderWidth: 2,
    backgroundColor: 'rgba(0,0,0,0)',
    pointBackgroundColor: Color.names.GREEN
};

/**
 ** Tricks
 **/
const DEFAULT_TRICK = {
    beginAtZero: false,
    display: true,
    fontColor: Color.names.BLACK,
    precision: 0,
    fontSize: '12',
    fontStyle: 'normal'
};


/**
 ** Gridlines
 **/

const DEFAULT_GRID_LINES = {
    color: Color.names.BLACK,
    tickMarkLength: 10,
    display: false,
    zeroLineColor: Color.names.BLACK,
    zeroLineWidth: 2,
    lineWidth: 2
};

/**
 ** Axis
 **/
const DEFAULT_X_AXIS = {
    ticks: DEFAULT_TRICK,
    gridLines: DEFAULT_GRID_LINES
};

const DEFAULT_Y_AXIS = {
    ticks: DEFAULT_TRICK,
    gridLines: DEFAULT_GRID_LINES
};

/**
 ** Scales
 **/
const DEFAULT_SCALES = {
    xAxes: [DEFAULT_X_AXIS],
    yAxes: [DEFAULT_Y_AXIS]
};

/**
 ** Combined options
 **/
const DEFAULT_OPTIONS = {
    legend: {
        display: true,
        position: 'bottom'
    },
    responsive: true,
    scales: DEFAULT_SCALES
};

/**
 * Creates a dataset given the labels and data attributes.
 *
 * @param {array[number]} data           the data that the dataset will contain.
 * @param {array[string]} labels         the labels to display.
 * @param {object}        defaultDataset the default dataset model.
 */
const createDataset = (data, labels, defaultDataset, colored = false) => {
    let datasets = [];
    let colors = [Color.names.GREEN];
    if (colored) {
        colors = Color.normal();
    }
    data.forEach((dataset, i) => {
        let datasetOptions = Object.assign({}, defaultDataset);
        if (colored) {
            datasetOptions['borderColor'] = colors[i];
            datasetOptions['pointBorderColor'] = colors[i];
            datasetOptions['pointBackgroundColor'] = colors[i];
        }
        datasets.push(Object.assign({label: dataset.label, data: dataset.data, dragData: (dataset.dragData === undefined ? true : dataset.dragData)}, datasetOptions));
    });

    return {
        datasets,
        labels
    };
};

export const createColoredDataset = (data, labels) =>
    createDataset(data, labels, DATASET_GREEN, true);


/**
 * Creates a dataset given the labels and data attributes.
 *
 * @param {array[number]} data   the data that the dataset will contain.
 * @param {array[string]} labels the labels to display.
 */
export const createGreenDataset = (data, labels) =>
    createDataset(data, labels, DATASET_GREEN);

/**
 * Returns the chart's default options.
 *
 * @return {object} the default options.
 */
export const getDefaultOptions = (multiple = false) => {
    let defaultOptions = Object.assign({}, DEFAULT_OPTIONS);
    if (!multiple) {
        defaultOptions.legend.display = false;
    } else {
        defaultOptions.legend.display = true;
    }
    return defaultOptions;
};
