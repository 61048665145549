'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';

import PromotionDetailHeader from './promotio-detail-header';
import PromotionDetailBody from './promotion-detail-body';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as promotionSelectors from 'common/store/selectors/promotion.selector';
import * as promotionActions from 'common/store/actions/promotions/promotions.actions';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            promotion: promotionSelectors.getPromotion(state),
            loading: promotionSelectors.getIsLoading(state),
            error: promotionSelectors.getError(state),
            promotionUpdated: promotionSelectors.getPromotionUpdated(state),
            promotionDeleted: promotionSelectors.getPromotionDeleted(state),
            simpleError: promotionSelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPromotion: (id) => {
            dispatch(promotionActions.getPromotion(id));
        },
        resetVariables: () => {
            dispatch(promotionActions.resetVariables());
        }
    };
};


class PromotionsDetails extends React.Component{
    constructor(props){
        super(props);

        this.state = { isMobile: window.matchMedia('(max-width: 440px)').matches, alertShown: false};
    }

    componentDidMount() {
        let idpromo = this.props.match.params.id;
        this.props.resetVariables();
        this.props.getPromotion(idpromo);
    }

    componentDidUpdate() {
        if (this.props.promotionDeleted === true) {
            this.props.history.goBack();
        }

        if (this.props.promotion && this.props.promotionUpdated === true) {
            this.props.alert.show(<div><span>La promoción <strong>{this.props.promotion.code}</strong> ha sido actualizada.</span></div>, {type: 'info'});
        }
    }

    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {!this.props.loading &&
                    this.props.promotion &&
                    <React.Fragment
                        key='financing-entity-detail'>
                        <div className="pdB0 layout-body__header">
                            <PromotionDetailHeader
                                history={this.props.history}
                                promotion={this.props.promotion}
                            ></PromotionDetailHeader>
                        </div>
                        <hr className='w-95'></hr>
                        <div className="mgT2 layout-body__content">
                            <PromotionDetailBody
                                promotion={this.props.promotion}>
                            </PromotionDetailBody>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default withAlert()(withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(PromotionsDetails)));