'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Toggle from 'react-toggle';

import {Checkbox} from 'common/components/inputs';

import money from 'common/helpers/money';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class PlansListView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            field: '',
            errorField: { errorClass: null, dangerLabel: false },
            canSubmit: false,
            cellEditing: null
        };

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.plans) !== JSON.stringify(prevProps.plans)) {
            this.setState({
                cellEditing: null,
                field: '',
                errorField: {
                    errorClass: null,
                    dangerLabel: false
                }
            });
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    planRepeated = (name) => {
        return this.props.plans.filter(el => el.name === name).length > 1;
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            let id = this.state.cellEditing.split('-')[1];
            let data = {[this.state.cellEditing.split('-')[0]]: this.state.field};
            this.props.updatePlan(id, Object.assign({}, data));
        } else {
            this.setState({
                cellEditing: null,
                field: '',
                errorField: {
                    errorClass: null,
                    dangerLabel: false
                }
            });
        }
    };


    renderData = (original, column) => {
        if (['monthlyFixedAmount', 'firstPaymentAmount'].indexOf(column.id) > -1) {
            if (original[column.id]) {
                return original[column.id].currency.symbol + '$ ' + money(original[column.id].amount);
            }
            return 'RD$ 0.00';
        }
        if (['minimum', 'maximum'].indexOf(column.id) > -1) {
            if (original[column.id]) {
                return original[column.id].currency.symbol + '$ ' + money(original[column.id].amount);
            }
            return '-';
        }
        return original[column.id];
    };

    clickOnCell = (value) => {
        let val = value.target.innerHTML;
        value.target.innerHTML = "";
        let obText = document.createElement("input", {type:'text'});
        obText.id = value.target.id; //['monthlyFixedAmount', 'firstPaymentAmount', 'minimum', 'maximum'].indexOf(field) > -1 ? field : '';
        obText.value = val.replace('RD$ ', '');
        obText.style.border= "0.5px solid #d2d2d2";
        obText.setAttribute('size', '10');
        value.target.appendChild(obText);
        value.target.childNodes[0].focus();
    };
    onBlurCell = (value) => {
        let parent = value.target.parentNode;
        let val = value.target.value;
        let idplan = value.target.id.split('-')[1];
        let data ={[value.target.id.split('-')[0]]:val};
        parent.removeChild(value.target);
        parent.innerHTML = ['monthlyFixedAmount', 'firstPaymentAmount', 'minimum', 'maximum'].indexOf(value.target.id.split('-')[0]) > -1 ? 'RD$ '+money(val) : ['minimum', 'maximum'].indexOf(value.target.id.split('-')[0]) > -1 ? '-': (val !== '') ? val : 0;
        this.props.updatePlan(idplan, Object.assign({}, data));
    };

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        let length = _.intersection(this.props.plans.map(plan => plan.id), this.props.selectedPlans).length;
        let data = _.sortBy(this.props.plans, 'payments');
        return (
            <div className="row">
                <div className="col-12">
                    <div style={{borderColor: '#d2d2d2'}} role="region" aria-labelledby>
                        <div style={{overflowX:'scroll', borderLeft:'none', overflowY:'scroll', width:'100%', height:'600px', borderTop:'1px solid #d2d2d2'}} className="tableDataplan">
                            <table className="white-background Planstable">
                                <thead >
                                    <tr style={{textAlign:'center', height:'60px', boxShadow:'0 5px 5px 1px rgba(0, 0, 0, 0.10)' }}>
                                        <th className="border-md-right">
                                            {
                                                <div style={{margin:'10px 10px 10px 20px'}} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        squared={true}
                                                        label="Nombre del plan"
                                                        checked={length === this.props.plans.length}
                                                        value={'all'}
                                                        indeterminate={length < this.props.plans.length && length > 0}
                                                        textStyle={{marginLeft:'40px', fontSize:'16px', fontWeight:'bold', overflowWrap:'break-word'}}
                                                        onChange={(val) => this.props.onPlansSelected(val, this.props.plans.map(plan => plan.id))}/>
                                                </div>
                                            }
                                        </th>
                                        <th className="border-md-right">
                                            <div className="  dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}}>Cuotas </div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}}>Tasa anual (%) </div>
                                        </th >
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} >Fijo Mensual (%) </div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} >Fijo Mensual (DOP) </div>
                                        </th >
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>Primera Cuota (%) </div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>Primera Cuota (DOP) </div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}} >Inicial mínimo (%)</div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}} >Monto min.</div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}} >Monto max.</div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px'}} >Estado</div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>Mostrar con descuento </div>
                                        </th>
                                        <th className="border-md-right">
                                            <div className="dt__header-label" style={{width:'150px', margin:'10px 10px 10px 10px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} >Mostrar sin descuento </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <React.Fragment>
                                        {data.map((original) => {
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <tr style={{textAlign:'center', height:'60px'}} id={original.id} key={original.id}>
                                                    <td className="border-md-right">
                                                        <div style={{marginTop: '7px', marginLeft:'20px', float:'left'}} onClick={(e) => e.stopPropagation()}>
                                                            <Checkbox
                                                                squared={true}
                                                                label=''
                                                                checked={(this.props.selectedPlans.indexOf(original.id) > -1)}
                                                                value={original.id}
                                                                style={{float:'left'}}
                                                                onChange={(val, e) => this.props.onPlansSelected(val, [parseInt(e.currentTarget.value)])}/>
                                                            <div onClick={(val) => this.clickOnCell(val)} onBlur={(val) => this.onBlurCell(val)} id={'name-' + original.id}
                                                                style={{paddingLeft:'30px'}}>
                                                                {this.renderData(original, {id:'name'})}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)} onBlur={(val) => this.onBlurCell(val)} id={'payments-' + original.id}>
                                                            {this.renderData(original, {id:'payments'})}
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div onClick={(val) => this.clickOnCell(val)} onBlur={(val) => this.onBlurCell(val)} id={'charge-' + original.id}>{this.renderData(original, {id:'charge'})} </div>
                                                    </td >
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'monthlyFixed-' + original.id}
                                                        >{this.renderData(original, {id:'monthlyFixed'})} </div>
                                                    </td >
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'monthlyFixedAmount-' + original.id}>{this.renderData(original, {id:'monthlyFixedAmount'})} </div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'firstPayment-' + original.id}>{this.renderData(original, {id:'firstPayment'})}</div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'firstPaymentAmount-' + original.id}>{this.renderData(original, {id:'firstPaymentAmount'})}</div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'minAdvancePayment-' + original.id}>{this.renderData(original, {id:'minAdvancePayment'})}</div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'minimum-' + original.id}>{this.renderData(original, {id:'minimum'})}</div>
                                                    </td>
                                                    <td >
                                                        <div
                                                            onClick={(val) => this.clickOnCell(val)}
                                                            onBlur={(val) => this.onBlurCell(val)}
                                                            id={'maximum-' + original.id}>{this.renderData(original, {id:'maximum'})}</div>
                                                    </td>
                                                    <td >
                                                        <div>
                                                            <Toggle
                                                                className="md-switch"
                                                                icons={false}
                                                                checked={original.enabled}
                                                                onChange={() => this.props.updatePlan(original.id, {enabled: !original.enabled})} />
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div>
                                                            <Toggle
                                                                className="md-switch"
                                                                icons={false}
                                                                checked={original.showIfDiscount}
                                                                onChange={() => this.props.updatePlan(original.id, {showIfDiscount: !original.showIfDiscount})} /> </div>
                                                    </td>
                                                    <td >
                                                        <div><Toggle
                                                            className="md-switch"
                                                            icons={false}
                                                            checked={original.showNotDiscount}
                                                            onChange={() => this.props.updatePlan(original.id, {showNotDiscount: !original.showNotDiscount})} /> </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
PlansListView.propTypes = {
    onFiltersChange: PropTypes.func,
    plans: PropTypes.array.isRequired,
    selectedPlans: PropTypes.array,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
