'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';


import { DateRange } from 'react-date-range';
import moment from 'moment';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import {
    TextInput,
    FormsyInputWrapper,
    SelectInput,
    Checkbox
} from 'common/components/inputs';

import { CALENDAR_ICON } from 'common/constants/icon.constant';


export default class CreateEditPromotion extends React.PureComponent {
    constructor (props) {
        super(props);

        this.state = {
            creating: props.creating,
            showCalendar: false,
            promotiondata: {
                code: props.creating ? '' : props.promotionData.code,
                name: props.creating ? '' : props.promotionData.name,
                startDate: props.creating ? '' : props.promotionData.startDate,
                endDate: props.creating ? '' : props.promotionData.endDate,
                percentDiscount: props.creating ? '' : props.promotionData.percentDiscount,
                maxDiscount: props.creating ? '' : props.promotionData.maxDiscount,
                fixedDiscount: props.creating ? '' : props.promotionData.fixedDiscount,
                usesByClient: props.creating ? '' : props.promotionData.usesByClient,
                applyWithDiscount: props.creating ? false : props.promotionData.applyWithDiscount,
                applyNotDiscount: props.creating ? false : props.promotionData.applyNotDiscount,
                store: props.creating ? '' : props.promotionData.store
            },
            errorFields: {
                code: { errorClass: null, dangerLabel: false },
                name: { errorClass: null, dangerLabel: false },
                percentDiscount: { errorClass: null, dangerLabel: false },
                maxDiscount: { errorClass: null, dangerLabel: false },
                fixedDiscount: { errorClass: null, dangerLabel: false },
                usesByClient: { errorClass: null, dangerLabel: false },
                store: { errorClass: null, dangerLabel: false }
            },
            rangeDate:[
                {
                    startDate: moment( props.promotionData.startDate !== null ? props.promotionData.startDate : Date.now()).toDate(),
                    endDate: moment(props.promotionData.endDate !== null ? props.promotionData.endDate : Date.now()).toDate(),
                    key: 'selection'
                }
            ],
            dayformated: moment(props.promotionData.startDate !== null ? props.promotionData.startDate : Date.now()).format('DD/MM/yyyy') + ' - ' + moment(props.promotionData.endDate !== null ? props.promotionData.endDate : Date.now()).format('DD/MM/yyyy')
        };

    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onChangeDate = (item) => {
        let dayformated = moment(item.selection.startDate).format('DD/MM/yyyy') + ' - ' + moment(item.selection.endDate).format('DD/MM/yyyy');
        this.setState({rangeDate:[item.selection], dayformated: dayformated}, this.onFieldChange('dates', item.selection));
    };

    showCalendar = () => {
        if (this.state.showCalendar) {
            this.setState({showCalendar: !this.state.showCalendar});
        } else {
            this.setState({showCalendar: !this.state.showCalendar});
        }
    };

    onFieldChange = (field, value) => {
        let PromotionData = {... this.state.promotiondata};
        if (field !== 'dates') {
            PromotionData[field] = value;
        } else {
            PromotionData['startDate'] = moment(value.startDate).format('yyyy-MM-DD');
            PromotionData['endDate'] = moment(value.endDate).format('yyyy-MM-DD');
        }
        this.setState({promotiondata: PromotionData});
    };

    onSubmit = () => {
        this.props.onSavePromo(this.state.promotiondata);
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };


    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={() => this.canSubmit(true)}
                onInvalid={() => this.canSubmit(false)}>
                <div className='row mgB2'>
                    <FormsyInputWrapper
                        required
                        className='col-12'
                        name="name"
                        validations="isTrue"
                        errorClassName={this.state.errorFields.name.errorClass}
                        value={this.state.promotiondata.name !== null}>
                        <TextInput
                            label={<span>Nombre de la promoción <span className="color-red">*</span></span>}
                            placeholder="Ingrese nombre"
                            value={this.state.promotiondata.name}
                            onChange={(val) => this.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>
                </div>
                <div className='row'>
                    <FormsyInputWrapper
                        className='col-md-5 col-12'
                        name="code"
                        validations="isTrue"
                        errorClassName={this.state.errorFields.code.errorClass}
                        value={this.state.promotiondata.code !== null}>
                        <TextInput
                            label="Código"
                            placeholder="Ingrese código"
                            numbers={true}
                            value={this.state.promotiondata.code}
                            onChange={(val) => this.onFieldChange('code', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className='col-md-7 col-12'
                        name="store"
                        validations={'isTrue'}
                        errorClassName={this.state.errorFields.store.errorClass}
                        value={this.state.promotiondata.store !== null}>
                        <SelectInput
                            floatingLabel={<span>Tienda <span className="color-red">*</span></span>}
                            //key={'userData.userType'}
                            dangerLabel={this.state.errorFields.store.dangerLabel}
                            label="Seleccione"
                            options={this.props.drops.stores}
                            value={this.state.promotiondata.store}
                            onChange={(val) => this.onFieldChange('store', val)}
                        ></SelectInput>
                    </FormsyInputWrapper>
                </div>
                <div className='row'>
                    <div className='col-md-10 col-12'>
                        <label style={{width:'100%'}}>
                            <span >
                                <TextInput
                                    label="Tiempo efectivo"
                                    value={this.state.dayformated}
                                />
                                <span
                                    style={{top:'27px'}}
                                    onClick={() => this.showCalendar()}
                                    className="calendar--icon">
                                    <i className={ CALENDAR_ICON } />
                                </span>
                            </span>
                        </label >
                        <label >
                            {this.state.showCalendar &&
                                    <div style={{position:'absolute', top:'75px', left:'15px', zIndex:'2'}}>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={this.onChangeDate}
                                            showSelectionPreview={false}
                                            moveRangeOnFirstSelection={false}
                                            retainEndDateOnFirstSelection={true}
                                            showDateDisplay={false}
                                            ranges={this.state.rangeDate}
                                        />
                                    </div>}
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <FormsyInputWrapper
                        className='col-md-6 col-12'
                        name="percentDiscount"
                        validations={{matchRegexp:/[0-9]/}}
                        errorClassName={this.state.errorFields.percentDiscount.errorClass}
                        value={this.state.promotiondata.percentDiscount}>
                        <TextInput
                            label="Descuento porcentual"
                            placeholder="Ingrese valor"
                            numbers={true}
                            onlyNumbers={true}
                            value={this.state.promotiondata.percentDiscount}
                            onChange={(val) => this.onFieldChange('percentDiscount', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className='col-md-6 col-12'
                        name="maxDiscount"
                        validations={{matchRegexp:/[0-9]/}}
                        errorClassName={this.state.errorFields.maxDiscount.errorClass}
                        value={this.state.promotiondata.maxDiscount}>
                        <TextInput
                            label="Descuento máximo"
                            placeholder="Ingrese valor"
                            numbers={true}
                            onlyNumbers={true}
                            value={this.state.promotiondata.maxDiscount}
                            onChange={(val) => this.onFieldChange('maxDiscount', val)}
                        />
                    </FormsyInputWrapper>
                </div>
                <div className='row'>
                    <FormsyInputWrapper
                        className='col-md-6 col-12'
                        name="fixedDiscount"
                        validations={{matchRegexp:/[0-9]/}}
                        errorClassName={this.state.errorFields.fixedDiscount.errorClass}
                        value={this.state.promotiondata.fixedDiscount}>
                        <TextInput
                            label="Descuento fijo"
                            placeholder="Ingrese valor"
                            numbers={true}
                            onlyNumbers={true}
                            value={this.state.promotiondata.fixedDiscount}
                            onChange={(val) => this.onFieldChange('fixedDiscount', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className='col-md-6 col-12'
                        name="usesByClient"
                        validations={{matchRegexp:/[0-9]{1}|[0-9]{2}/}}
                        errorClassName={this.state.errorFields.usesByClient.errorClass}
                        value={this.state.promotiondata.usesByClient}>
                        <TextInput
                            label="Usos por cliente"
                            placeholder="Ingrese valor"
                            numbers={true}
                            onlyNumbers={true}
                            maxLength={2}
                            value={this.state.promotiondata.usesByClient}
                            onChange={(val) => this.onFieldChange('usesByClient', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className='col-md-6 col-12 mgT'
                        name="applyWithDiscount"
                        value={this.state.promotiondata.applyWithDiscount}>
                        <Checkbox
                            squared={true}
                            label="Aplicar con descuento"
                            checked={this.state.promotiondata.applyWithDiscount}
                            onChange={(val) => this.onFieldChange('applyWithDiscount', val)}/>
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className='col-md-6 col-12 mgT'
                        name="applyNotDiscount"
                        value={this.state.promotiondata.applyNotDiscount}>
                        <Checkbox
                            squared={true}
                            label="Aplicar sin descuento"
                            checked={this.state.promotiondata.applyNotDiscount}
                            onChange={(val) => this.onFieldChange('applyNotDiscount', val)}/>
                    </FormsyInputWrapper>
                </div>
                <hr></hr>
                <div className='row no-gutters'>
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={this.props.onClose}
                    >Volver
                    </button>
                    <div className='col-md-4 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <RequiredFieldsWrapper
                            onSubmit={this.onSubmit}
                            validSubmit={this.validSubmit}
                            containerId='create-promotion-modal'
                            onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                            errorFields={this.state.errorFields}
                            fields={this.state.promotiondata}>
                            <button
                                className="btn btn-primary w-100"
                                onClick={this.onSubmit}
                            >{(this.props.creating) ? 'Crear' : 'Salvar'}
                            </button>
                        </RequiredFieldsWrapper>
                    </div>
                </div>
            </Formsy>
        );
    }


}

CreateEditPromotion.defaultProps = {creating: true };

CreateEditPromotion.propTypes = {
    creating: PropTypes.bool,
    promotionData: PropTypes.object,
    onSavePromo: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    drops: PropTypes.object.isRequired
};



