'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import {SearchInput} from 'common/components/inputs';

class ClientsListHeader extends React.PureComponent {

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB">
                <div className="col-12">
                    <h2 className="section-headline">Clientes ({this.props.total})</h2>
                </div>
            </div>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-md-12 mgB">
                        <SearchInput
                            floatingLabel={'Buscar'}
                            weight={500}
                            value={this.props.filters.q}
                            label="Buscar por nombre, teléfono o correo electrónico"
                            onChange={(val) => this.props.onFiltersChange(['q', 'page'], [val, 1])}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
            </div>
        );
    }
}

/**
 * Properties validations.
 */
ClientsListHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    filters: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    onFiltersChange: PropTypes.func.isRequired
};

export default ClientsListHeader;
