
function quitarAcentos(cadena){
    const acentos = {'á':'a', 'é':'e', 'í':'i', 'ó':'o', 'ú':'u', 'Á':'A', 'É':'E', 'Í':'I', 'Ó':'O', 'Ú':'U'};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();
}

export default function (dataName) {
    let nameLower = dataName.toLowerCase().split(/[ ]/g);
    if (nameLower.length === 0) {
        return nameLower[0];
    } else {
        let newName = '';
        for(let i=0; i< nameLower.length; i++) {
            if (i>0) {
                let newCad = quitarAcentos(nameLower[i]);
                newName += newCad.charAt(0).toUpperCase()+newCad.slice(1);
            } else {
                newName = quitarAcentos(nameLower[i]);
            }
        }
        return newName;
    }

}

