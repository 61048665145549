/* Financing Entity related state changes goes here */

import PropTypes from 'prop-types';

import * as transactionActionsLabels from 'common/store/actions/transaction/transaction.enum';

import merge from 'common/helpers/merge-objects';

import { GENERIC_ERROR } from 'common/constants/errors.constant';
import {
    IDENTIFICATION_TYPES,
    FINANCING_ENTITY_TYPE
} from 'common/constants/enums.constant';

import {
    DEFAULT_PAGINATION,
    DEFAULT_FINANCINGS_FILTERS
} from 'common/constants/objects.constant';

const defaultState = Object.seal({
    loading: false,
    error: null,
    creatingFinancing: false,
    financingActions: null,
    financingActionsLoading: false,
    financingActionsLoadingModal: false,
    financingActionsError: null,
    singleFinancing: null,
    dashboard: null,
    simpleError: null,
    uploadError: null,
    financingCreated: null,
    financingUpdated: null,
    tempFinancingDeleted: null,
    tempFinancing: null,
    newfinancingCreated: null,
    financingManuallyUpdated: null,
    financingEntities: [],
    financingEntityType: FINANCING_ENTITY_TYPE.BANK,
    financingEntityTypes: [],
    financingEntityTypesAll: true,
    plansToCompare: [],
    pagination: DEFAULT_PAGINATION,
    financingsFilters: DEFAULT_FINANCINGS_FILTERS,
    financings: [],
    cardTransactions: [],
    cart: null,
    financingTotals: [],
    waitingquote: null,
    financingForm: {
        callback: null,
        externalOrderId: null,
        onSuccess: null,
        onFailure: null,
        cardselected: null,

        name: '',
        lastname: '',
        identificationType: IDENTIFICATION_TYPES.CEDULA,
        identification: '',
        identificationFile: null,
        identificationFile2: null,
        advancePayment: '',
        currencyId: null,
        email: '',
        phone: '',
        postalCode: '',
        street: '',
        city: '',
        provinceName: '',
        province: null,
        country: 1,
        livingType: null,
        livingTime: null,
        financingEntity: null,
        plan: null,
        partner_number: null,

        isMarried: null,
        spouseName: '',
        spouseIdentificationType: IDENTIFICATION_TYPES.CEDULA,
        spouseIdentification: '',
        spousePhone: '',
        relativeName: '',
        relativePhone: '',

        companyName: '',
        companyType: null,
        companyStreet: '',
        companyPhone: '',
        companyTitle: '',
        salary: '',
        otherIncomes: '',
        workingTime: null,
        approval: null,

        canSubmitStepOne: false,
        canSubmitStepTwo: false,
        canSubmitStepThree: false,
        canSubmitStepFour: false,
        canSubmitAuthStep: false,
        optionalFields: '',
        branch_id: null,
        discountAmount:  null,
        paymentMethod: null
    },
    cardTransaction: null,

    temporaryFinancing: {
        name: null,
        lastname: null,
        email: null,
        phone: null,
        identification: null,
        identificationType: null,
        cart: null,
        street: null,
        city: null,
        country: null,
        plan: null,
        advancePayment: null,
        externalOrder: null,
        dataProfile: {
            provinceCode: null,
            identificationFile: null,
            identificationFile2: null,
            livingType: null,
            livingTime: null,

            isMarried: null,
            spouseName: '',
            spouseIdentificationType: IDENTIFICATION_TYPES.CEDULA,
            spouseIdentification: '',
            spousePhone: '',
            relativeName: '',
            relativePhone: '',

            companyName: '',
            companyType: null,
            companyStreet: '',
            companyPhone: '',
            companyTitle: '',
            salary: '',
            otherIncomes: '',
            workingTime: null
        }
    },

    // Properties validation.
    propTypes: {
        loading: PropTypes.bool,
        financingEntities: PropTypes.array,
        error: PropTypes.string,
        financingCreated: PropTypes.object,
        simpleError: PropTypes.string,
        financingActions: PropTypes.object,
        financingActionsLoading: PropTypes.bool,
        financingActionsLoadingModal: PropTypes.bool,
        financingActionsError: PropTypes.string,
        cart: PropTypes.object,
        financingForm: PropTypes.object
    }
});

export const transactionInitialState = Object.seal(JSON.parse(JSON.stringify(defaultState)));

export const transactionReducer = (state = transactionInitialState, action) => {
    let newState = state;
    switch (action.type) {
    case transactionActionsLabels.VERIFY_ORDER_TOKEN: {
        const newStateObject = Object.assign({}, { error: null, cart: null, loading: true });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.VERIFY_ORDER_TOKEN_SUCCESS: {
        const newStateObject = Object.assign({}, { error: null, cart: action.payload, loading: false });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.VERIFY_ORDER_TOKEN_FAILURE: {
        const newStateObject = Object.assign({}, { cart: null, error: action.payload ? action.payload.error : GENERIC_ERROR, loading: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityTypes: [], financingEntityTypesAll: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_SUCCESS: {
        let financingEntityTypes = action.payload;
        if (!state.financingEntityTypesAll) {
            financingEntityTypes = financingEntityTypes.filter((el) => { return (el.id < 4); });
        }
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityTypes: financingEntityTypes });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_ALL_ENTITIES_FAILURE: 
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_FAILURE: {
        const newStateObject = Object.assign({}, {simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_ALL_ENTITIES: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntities: [] });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_ALL_ENTITIES_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntities: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SET_FINANCING_FORM_DATA: {
        try {
            let stateForm = Object.assign({}, action.payload);
            const newStateObject = Object.assign({}, { financingForm: stateForm });
            newState = merge(state, newStateObject);
            break;
        } catch (error) {
            let currentState = Object.assign({}, state);
            let financingForm = action.payload;
            financingForm['identificationFile'] = null;
            financingForm['identificationFile2'] = null;

            currentState['financingForm'] = Object.assign({}, financingForm);

            newState = merge(state, currentState);
            break;
        }
    }

    case transactionActionsLabels.SET_FINANCING_TEMP_DATA: {
        try {
            let stateTempForm = Object.assign({}, action.payload);
            const newStateObject = Object.assign({}, { temporaryFinancing: stateTempForm });
            newState = merge(state, newStateObject);
            break;
        } catch (error) {
            let currentState = Object.assign({}, state);
            let financingTemp = action.payload;
            financingTemp.dataProfile['identificationFile'] = null;
            financingTemp.dataProfile['identificationFile2'] = null;

            currentState['tempFinancing'] = Object.assign({}, financingTemp);

            newState = merge(state, currentState);
            break;
        }
    }
    case transactionActionsLabels.DATA_PAYMENT_DISCOUNT: {
        let newFinancingFormData = Object.assign({}, state.financingForm, action.payload);
        const newStateObject = Object.assign({}, {financingForm: newFinancingFormData});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.RESET_FINANCING_MESSAGES: {
        const newStateObject = Object.assign({}, { financingCreated: null, simpleError: null, uploadError: null, financingUpdated: null, financingManuallyUpdated: null, tempFinancingDeleted: null, tempFinancing: null, cardTransaction: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SET_CREATING_FINANCING: {
        const newStateObject = Object.assign({}, {creatingFinancing: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP: 
    case transactionActionsLabels.CREATE_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, financingCreated: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_SUCCESS:
    case transactionActionsLabels.CREATE_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingCreated: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_FAILURE:
    case transactionActionsLabels.CREATE_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { financingCreated: null, simpleError: error});
        newState = merge(state, newStateObject);
        if (state.financingForm.onFailure) {
            location.replace(state.financingForm.onFailure);
        }
        break;
    }
    case transactionActionsLabels.SEARCH_CARD_FINANCING:
    case transactionActionsLabels.GET_FINANCINGS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: true,
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCINGS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            financings: action.payload.data ? action.payload.data : [],
            pagination: action.payload.pagination ? action.payload.pagination : DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SEARCH_CARD_FINANCING_FAILURE:
    case transactionActionsLabels.GET_FINANCINGS_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error,
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CANCEL_GET_FINANCINGS: {
        const newStateObject = Object.assign({}, {
            error: null,
            financings: [],
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SET_FINANCINGS_FILTERS: {
        const newStateObject = Object.assign({}, { financingsFilters: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SET_FINANCING_ENTITY_TYPE: {
        const newStateObject = Object.assign({}, { financingEntityType: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING:
    case transactionActionsLabels.GET_DASHBOARD: {
        const newStateObject = Object.assign({}, { error: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_DASHBOARD_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            dashboard: action.payload,
            waitingquote: action.payload.waitingqoute
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING_FAILURE:
    case transactionActionsLabels.GET_DASHBOARD_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CANCEL_CHECKOUT:
    case transactionActionsLabels.CANCEL_CHECKOUT_SUCCESS:
    case transactionActionsLabels.GET_FINANCINGS_TOTAL: {
        const newStateObject = Object.assign({}, { error: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCINGS_TOTAL_SUCCESS: {
        const newStateObject = Object.assign({}, {error: null, financingTotals: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCINGS_TOTAL_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {error: error, financingTotals: [] });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            singleFinancing: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CANCEL_FINANCING:
    case transactionActionsLabels.DECLINE_FINANCING:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS:
    case transactionActionsLabels.SIGN_FINANCING:
    case transactionActionsLabels.ADD_COMMENT:
    case transactionActionsLabels.APPROVE_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, financingUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.APPROVE_FINANCING_SUCCESS:
    case transactionActionsLabels.DECLINE_FINANCING_SUCCESS:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS_SUCCESS:
    case transactionActionsLabels.SIGN_FINANCING_SUCCESS:
    case transactionActionsLabels.ADD_COMMENT_SUCCESS:
    case transactionActionsLabels.CANCEL_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, {
            simpleError: null,
            financingUpdated: true
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.APPROVE_FINANCING_FAILURE:
    case transactionActionsLabels.DECLINE_FINANCING_FAILURE:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS_FAILURE:
    case transactionActionsLabels.SIGN_FINANCING_FAILURE:
    case transactionActionsLabels.SELL_CONFIRM_FAILURE:
    case transactionActionsLabels.CANCEL_CHECKOUT_FAILURE:
    case transactionActionsLabels.CANCEL_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error, financingActionsLoadingModal: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CLEAR_FINANCING_PROFILE: {
        const newStateObject = Object.assign({}, {singleFinancing: null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CLEAR_DASHBOARD: {
        const newStateObject = Object.assign({}, {dashboard: null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SELECT_PLAN_TO_COMPARE: {
        let plansToCompare = state.plansToCompare.slice();
        if (action.payload.value) {
            plansToCompare.push(action.payload.plan);
        } else {
            plansToCompare = plansToCompare.filter(el => el.id !== action.payload.plan.id);
        }
        const newStateObject = Object.assign({}, {plansToCompare: plansToCompare});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.RESET_PLANS_TO_COMPARE: {
        const newStateObject = Object.assign({}, {plansToCompare: []});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.ADD_COMMENT_FAILURE: {
        let error = 'Hubo un error al momento de agregar el comentario. Intenta más tarde.';
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING_ACTIONS: {
        const newStateObject = Object.assign({}, {
            financingActionsError: null,
            financingActionsLoading: true
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING_ACTIONS_SUCCESS: {
        const newStateObject = Object.assign({}, {
            financingActionsError: null,
            financingActionsLoading: false,
            financingActions: Array.isArray(action.payload) ? action.payload : null // null : action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_FINANCING_ACTIONS_FAILURE: {
        let error = 'Hubo un error al momento de cargar las acciones.';
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            financingActionsLoading: false,
            financingActionsError: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPDATE_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, financingManuallyUpdated: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPDATE_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingManuallyUpdated: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPDATE_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { financingManuallyUpdated: null, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPLOAD_DOCUMENT:
    case transactionActionsLabels.REMOVE_DOCUMENT:
    case transactionActionsLabels.UPLOAD_QUOTE: {
        const newStateObject = Object.assign({}, { uploadError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPLOAD_DOCUMENT_SUCCESS:
    case transactionActionsLabels.REMOVE_DOCUMENT_SUCCESS:
    case transactionActionsLabels.UPLOAD_QUOTE_SUCCESS: {
        const newStateObject = Object.assign({}, { uploadError: null, singleFinancing: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.UPLOAD_DOCUMENT_FAILURE:
    case transactionActionsLabels.REMOVE_DOCUMENT_FAILURE:
    case transactionActionsLabels.UPLOAD_QUOTE_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {uploadError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.RESEND_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, newfinancingCreated: null, financingActionsLoadingModal:true});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.RESEND_FINANCING_SUCCESS:{
        const newStateObject = Object.assign({}, { simpleError: null, newfinancingCreated: action.payload, financingActionsLoadingModal: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.RESEND_FINANCING_FAILURE: {
        let error = 'Hubo un Error al crear la nueva solicitud con el plan escogido.';
        const newStateObject = Object.assign({}, {simpleError: error, financingActionsLoadingModal: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CLEAR_MODAL_FINANCING: {
        const newStateObject = Object.assign({}, {simpleError: null, newfinancingCreated: null, financingActionsLoadingModal: false});
        newState = merge(state, newStateObject);
        break;
    }

    case transactionActionsLabels.SELL_CONFIRM: {
        const newStateObject = Object.assign({}, { simpleError: null, financingUpdated:false, financingActionsLoadingModal: true});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SELL_CONFIRM_SUCCESS:{
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancing: action.payload, financingUpdated: true, financingActionsLoadingModal: false});
        newState = merge(state, newStateObject);
        break;
    }

    case transactionActionsLabels.CREATE_TEMP_FINANCING: 
    case transactionActionsLabels.CREATE_TEMP_FINANCING_SUCCESS:{
        const newStateObject = Object.assign({}, { simpleError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_TEMP_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }

    case transactionActionsLabels.GET_TEMP_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, tempFinancing: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_TEMP_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, tempFinancing: action.payload, loading: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.GET_TEMP_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error, tempFinancing: null, loading: false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.DELETE_TEMP_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: true, tempFinancingDeleted:null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.DELETE_TEMP_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: false, tempFinancingDeleted:true});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.DELETE_TEMP_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error, loading: false, tempFinancingDeleted:false});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.ASSIGN_BRANCH_FINANCING:
    case transactionActionsLabels.UNDO_APPROVE_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: true, singleFinancing:null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.ASSIGN_BRANCH_FINANCING_SUCCESS:
    case transactionActionsLabels.UNDO_APPROVE_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: false, singleFinancing: action.payload});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.ASSIGN_BRANCH_FINANCING_FAILURE:
    case transactionActionsLabels.UNDO_APPROVE_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error, loading: false, singleFinancing:null});
        newState = merge(state, newStateObject);
        break;
    }

    case transactionActionsLabels.CREATE_CARD_FINANCING: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: true, cardTransaction: null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_CARD_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, loading: false, cardTransaction: action.payload});
        newState = merge(state, newStateObject);
        break;
    }

    case transactionActionsLabels.CREATE_CARD_FINANCING_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error, loading: false, cardTransaction: null});
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.SEARCH_CARD_FINANCING_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            cardTransactions: action.payload.data ? action.payload.data : [],
            pagination: action.payload.pagination ? action.payload.pagination : DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }

    default:
        break;
    }
    return newState;
};
