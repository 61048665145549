"use strict";

import _ from 'lodash';
import calculateFinancing from 'common/helpers/calculate-financing';
import money from 'common/helpers/money';

var pdfMake = require('pdfmake/build/pdfmake');
var pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default function (Data) {

    let subTotal = _.sumBy(Data.order.items, (item) => {
        return item.count * item.price;
    }) / 100;
    let taxes = (subTotal + (Data.order.deliveryCost / 100)) * parseFloat(process.env.MIX_TAXES);
    let subTotal1 = subTotal + taxes + (Data.order.deliveryCost / 100) - (Data.advancePayment / 100);

    let payments = calculateFinancing(Data.plan.payments, Data.plan, subTotal1).restPayments;

    var doc = {
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [20, 30, 20, 30],
        content: [],
        footer: {
            margin: [50, 15],
            text: [{ text: 'ElectroPay | Calle Haim Lopez Penha 19, Ensanche Paraíso. Santo Domingo. República Dominicana. Tel: 809-541-5202. E-mail: comercial@electropay.do', fontSize: 7, margin: [0, 5, 0, 0], alignment: 'center' }],
            width: 700,
            height: 60

        }

    };

    /* Seccion cabecera del documentos */
    doc.content.push({
        layout:'noBorders',
        table:{
            widths:[250, '*'],
            body : [
                [
                    {
                        layout:'noBorders',
                        table: {
                            body: [
                                [{ text: [{text: 'Solicitud de Financiamiento: '}, { text: Data.number}], fontSize: 14, bold: true, margin: [0, 5, 0, 0], alignment: 'center' }],
                                [{ text: Data.estado, fontSize: 16, bold: true, margin: [0, 5, 0, 0], alignment: 'center' }]
                            ], widths:['*']
                        }
                    },
                    {
                        layout:'noBorders',
                        table: {
                            body: [
                                [{ text: [{text: 'Fecha de solicitud: ', fontSize: 10, bold: true}, { text: Data.createdate, fontSize: 9}], alignment: 'right' }],
                                [{ text: [{text: 'Número en Electropay: ', fontSize: 10, bold: true}, { text: Data.order.id, fontSize: 9}], alignment: 'right' }],
                                [{ text: [{text: 'Tienda: ', fontSize: 10, bold: true}, {text: Data.order.store.name, fontSize: 9, bold: true}], alignment: 'right'}]
                            ], widths:['*']
                        }
                    }
                ]
            ]
        }
    });

    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 1
            }
        ], margin: [0, 10, 0, 5]
    });

    /* Seccion de datos de la entidad y el cliente */
    doc.content.push({
        table:{
            widths:['50%', '50%'],
            body : [
                [
                    { text: Data.plan.financingEntity.name, fontSize: 14, bold: true },
                    { text: 'Datos del Cliente ', fontSize: 14, bold: true }
                ],
                [
                    {
                        layout:'noBorders',
                        table: {
                            body: [
                                [{ text: [{text: 'Plan escogido: ', bold:true}, {text: Data.plan.name}], fontSize: 8 }],
                                [{ text: [{text: 'Fijo mensual: ', bold:true}, {text:(Data.plan.monthlyFixed * 100)+'%'}], fontSize: 8 }],
                                [{ text: [{text: 'Monto de cuota: ', bold:true}, {text: payments}], fontSize: 8 }],
                                [{ text: [{text: 'Tasa anual: ', bold:true}, {text: (Data.plan.charge * 100)+'%'}], fontSize: 8 }]
                            ], widths:['*']
                        }
                    },
                    {
                        layout:'noBorders',
                        table: {
                            body: [
                                [{ text: [{text: 'Nombre y Apellidos: ', bold:true}, {text: Data.client.name}], fontSize: 8 }],
                                [{ text: [{text: 'Identificación: ', bold:true}, {text:Data.client.identification}], fontSize: 8 }],
                                [{ text: [{text: 'Dirección: ', bold:true}, {text: Data.client.address}], fontSize: 8 }],
                                [{ text: [{text: 'Telefono: ', bold:true}, {text:Data.client.phone}], fontSize: 8 }],
                                [{ text: [{text: 'Correo electrónico: ', bold:true}, {text:Data.client.email}], fontSize: 8 }]
                            ], widths:['*']
                        }
                    }
                ]
            ]
        }, margin: [0, 5, 0, 0]
    });

    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 1
            }
        ], margin: [0, 10, 0, 5]
    });
    /* Seccion de datos de los productos, precios y montos a pagar */
    doc.content.push({text: 'Datos de Productos ', fontSize: 13, bold: true, alignment:'center', border:[false, false, false, true]});
    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 2
            }
        ], margin: [0, 5, 0, 0]
    });
    let arraydata = [
        [
            { text: 'Cantidad ', fontSize: 12, bold: true, alignment:'center' },
            { text: 'Producto ', fontSize: 12, bold: true, alignment:'center' },
            { text: 'Precio ', fontSize: 12, bold: true, alignment:'center' }
        ]
    ];
    let dataprod = _.map(Data.order.items, (elem) => {
        return [
            { text: elem.count, fontSize: 9, bold: true, alignment:'center'},
            { text: elem.name, fontSize: 9, bold: true },
            { text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(elem.price / 100), fontSize: 9, bold: true, alignment:'center' }
        ];
    });
    arraydata = arraydata.concat(dataprod);
    doc.content.push({
        layout:'lightHorizontalLines',
        table:{
            widths:['20%', '60%', '*'],
            headerRows: 1,
            body : arraydata
        }
    });

    let deliveryCost = Data.order.deliveryCost / 100;
    /* Seccion precios */
    doc.content.push({
        table:{
            widths:['50%', '50%'],
            body : [
                [
                    {
                        layout:'noBorders',
                        table: {
                            body: [
                                [{ text: [{text: 'Pedido en Tienda: ', bold:true}, {text:Data.order.externalId}], fontSize: 14, alignment:'center' }],
                                [{ text: [{text: 'Orden: ', bold:true}, {text:Data.order.id}], fontSize: 14, alignment:'center' }]
                            ], widths:['*']
                        }, margin: [0, 10, 0, 0]
                    },
                    {
                        table: {
                            body: [
                                [{text: 'Subtotal ', bold:true, alignment:'center'}, {text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(subTotal), alignment:'right'} ],
                                [{text: 'Envio: ', bold:true, alignment:'center'}, {text:(Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(deliveryCost), alignment:'right'}],
                                [{text: 'Impuestos: ', bold:true, alignment:'center'}, {text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(taxes), alignment:'right'}],
                                [{text: 'Total: ', bold:true, alignment:'center'}, {text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money((subTotal + taxes + deliveryCost)), bold:true, alignment:'right'}]
                            ], widths:['50%', '50%']
                        }
                    }
                ]
            ]
        }, margin: [0, 100, 0, 0]
    });

    /*Seccion del perfil financiero */
    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 1
            }
        ], margin: [0, 10, 0, 5]
    });
    doc.content.push({text: 'Perfil Financiero ', fontSize: 13, bold: true, alignment:'center', border:[false, false, false, true]});

    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 2
            }
        ], margin: [0, 5, 0, 0]
    });

    if (Data.financingProfile !== null) {


        doc.content.push({
            layout:'noBorders',
            table:{
                widths:['50%', '50%'],
                headerRows: 1,
                body : [
                    [
                        { text:[{text: 'Nombre: ', bold: true}, {text: Data.financingProfile.fullname }], fontSize: 8 },
                        { text:[{text: 'Cédula: ', bold: true}, {text: Data.financingProfile.identification}], fontSize: 8 }
                    ],
                    [
                        { text:[{text: 'Correo electrónico: ', bold: true}, {text: Data.financingProfile.email}], fontSize: 8 },
                        { text:[{text: 'Teléfono: ', bold: true}, {text: Data.financingProfile.phone}], fontSize: 8 }
                    ],
                    [
                        { text:[{text: 'Tipo Vivienda: ', bold: true}, {text: Data.financingProfile.livingType}], fontSize: 8 },
                        { text:[{text: 'Tiempo en la Vivienda: ', bold: true}, {text: Data.financingProfile.livingTime}], fontSize: 8 }
                    ]
                ]
            }
        });

        doc.content.push({
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: 3,
                    x2: 555, y2: 3,
                    lineWidth: 1
                }
            ]
        });
        doc.content.push({
            columns: [
                {text: 'Datos laborales ', fontSize: 12, bold: true, alignment:'left', margin:[10, 0, 0, 0]},
                {text: [{text :'Cargo: ', fontSize: 10, bold: true}, {text: Data.financingProfile.companyTitle, fontSize: 9}], alignment:'right', margin:[0, 2, 20, 0]}
            ]
        });
        doc.content.push({
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: 3,
                    x2: 555, y2: 3,
                    lineWidth: 1
                }
            ]
        });

        doc.content.push({
            columns: [
                {text: [{text :'Nombre de la empresa: ', bold: true}, {text: Data.financingProfile.companyName}], fontSize: 8, width:'45%'},
                {text: [{text :'Tipo: ', bold: true}, {text: Data.financingProfile.companyType}], fontSize: 8, width:'20%'},
                {text: [{text :'Tiempo de trabajo: ', bold: true}, {text: Data.financingProfile.workingTime}], fontSize: 8, width:'35%'}
            ], margin:[0, 3, 0, 0]
        } );

        doc.content.push({
            columns: [
                {text: [{text :'Dirección de la empresa: ', bold: true}, {text: Data.financingProfile.companyStreet}], fontSize: 8, width:'70%'},
                {text: [{text :'Telefono: ', bold: true}, {text: Data.financingProfile.companyPhone}], fontSize: 8, width:'30%'}
            ], margin:[0, 3, 0, 0]
        });

        doc.content.push({
            columns: [
                {text: [{text :'Sueldo: ', bold: true}, {text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(Data.financingProfile.salary)}], fontSize: 8, width:'40%'},
                {text: [{text :'Otros Ingresos: ', bold: true}, {text: (Data.order.currency.iso === 'DOP' ? 'RD$ ' : 'US$ ') + money(Data.financingProfile.otherIncomes)}], fontSize: 8, width:'40%'}
            ], margin:[0, 3, 0, 0]
        });


        doc.content.push({
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: 3,
                    x2: 555, y2: 3,
                    lineWidth: 1
                }
            ]
        });

        doc.content.push({columns: [{text: 'Datos Familiares ', fontSize: 12, bold: true, alignment:'left', margin:[10, 0, 0, 0]}, {text: [{text :'Estado Civil: ', fontSize: 10, bold: true}, {text: (Data.financingProfile.isMarried === true) ? 'Casado' : 'Soltero', fontSize: 9}], margin: [0, 2, 20, 0], alignment:'right'}]});
        doc.content.push({
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: 3,
                    x2: 555, y2: 3,
                    lineWidth: 1
                }
            ]
        });

        doc.content.push({
            layout:'noBorders',
            table:{
                widths:['50%', '50%'],
                headerRows: 1,
                body :[
                    [
                        { text:[{text: 'Nombre del conyuge: ', bold: true}, {text: Data.financingProfile.spouseName}], fontSize: 8 },
                        { text:[{text: 'Cédula del conyuge: ', bold: true}, {text: Data.financingProfile.spouseIdentification}], fontSize: 8 }
                    ],
                    [
                        { text:[{text: 'Teléfono: ', bold: true}, {text: Data.financingProfile.spousePhone}], fontSize: 8 },
                        { text:'', fontSize: 8 }
                    ],
                    [
                        { text:[{text: 'Familiar: ', bold: true}, {text: Data.financingProfile.relativeName}], fontSize: 8 },
                        { text:[{text: 'Teléfono del familiar: ', bold: true}, {text: Data.financingProfile.relativePhone}], fontSize: 8 }
                    ]
                ]
            }
        });

        if (Data.optionalFields.fields.length > 0) {
            doc.content.push({
                canvas: [
                    {
                        type: 'line',
                        x1: 0, y1: 3,
                        x2: 555, y2: 3,
                        lineWidth: 1
                    }
                ]
            });

            doc.content.push({text: 'Otros datos de interes ', fontSize: 12, bold: true, alignment:'left', border:[false, false, false, true], margin:[10, 0, 0, 0]});
            doc.content.push({
                canvas: [
                    {
                        type: 'line',
                        x1: 0, y1: 3,
                        x2: 555, y2: 3,
                        lineWidth: 1
                    }
                ]
            });

            let datos = Data.optionalFields.fields.map((dataField) => {
                return [{ text:[{text: dataField.name +':  ', bold: true}, {text: dataField.value}], fontSize: 8 }];
            });

            doc.content.push({
                layout:'noBorders',
                table:{
                    widths:['50%', '50%'],
                    headerRows: 1,
                    body :datos
                }
            });
        }
    } else {
        doc.content.push({text :'Sin perfil financiero ...', bold: false, fontSize: 12, alignment:'left', width:'40%', border:[false, false, false, true], margin:[10, 10, 0, 0]});

    }

    doc.content.push({
        canvas: [
            {
                type: 'line',
                x1: 0, y1: 3,
                x2: 555, y2: 3,
                lineWidth: 2
            }
        ]
        , margin:[0, 10, 0, 0]
    });
    return pdfMake.createPdf(doc);
}