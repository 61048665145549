/* eslint-disable react/jsx-key */
/* eslint-disable no-useless-constructor */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Link } from 'react-router-dom';

export default class PromotionsApllied extends React.PureComponent{
    constructor (props) {
        super(props);
    }

    render(){
        return (
            <React.Fragment>
                <div className='user-logs-box' style={{height:'410px'}}>
                    <div style={{paddingLeft:'16px', paddingRight:'3px', width:'96%', marginLeft:'5px'}}>
                        <div className='row w-90'>
                            <div className='col-md-12 col-12 mgT'>
                                <strong style={{fontSize:'18px', marginLeft:'5px'}}>Promociones activas</strong>
                            </div>
                            <hr></hr>
                            {this.props.promotionsList !== null && this.props.promotionsList !== undefined && this.props.promotionsList.length === 0 &&
                                <div className='col-md-12 col-12'>
                                    <div style={{height:'304px'}} className='white-background'>
                                        <span style={{marginTop:'40%', marginLeft:'30%', fontSize:'16px', fontWeight:'bold'}}>No existen promociones activas</span>
                                    </div>
                                </div>}
                            {this.props.promotionsList !== null && this.props.promotionsList !== undefined &&this.props.promotionsList.length > 0 &&
                            <React.Fragment>
                                {this.props.promotionsList.map((promo) => {
                                    return (
                                        <React.Fragment>
                                            <div className='col-md-5 col-12 white-background mgT2 border pdL2 pdR2'>
                                               Promocion:
                                                <a
                                                    style={{fontSize:'16px', fontWeight:'bold', textDecoration:'none', cursor:'pointer'}}
                                                    onClick={() => this.props.history.push({ pathname:'/promotions/' + promo.id})}>
                                                    {promo.name}
                                                </a>
                                                <br></br>
                                               Periodo Activo: <span style={{fontSize:'16px', fontWeight:'bold'}}>({moment(promo.startDate).format('DD-MM-YYYY') + ' - ' + moment(promo.endDate).format('DD-MM-YYYY')})</span>
                                                <br></br>
                                               Comercio: <span style={{fontSize:'16px', fontWeight:'bold'}}>{promo.store.name}</span>

                                            </div>
                                            <div className='col-md-1 col-12'></div>
                                        </React.Fragment>
                                    );
                                }
                                )}
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

PromotionsApllied.propTypes = {
    promotionsList: PropTypes.array,
    financingEntity: PropTypes.object,
    applyPromotion: PropTypes.func,
    deletePromotion: PropTypes.func
};