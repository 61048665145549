import parseBody from 'common/helpers/parse-body';

import {post, get} from './api.service';


export function authorizeCard(data) {

    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/transactions/authorizecard',
        formData
    );
}


export function registerTokens(data) {
    const formData = new FormData();

    parseBody(formData, data);
    
}