import React from 'react';
import PropTypes from 'prop-types';

/**
 * Represents a radio group.
 */
export default class Radio extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props}  the required React properties.
     */
    constructor(props) {
        super(props);

        this.changeRadio = this.changeRadio.bind(this);
    }

    /**
     * Fired once the checked status changes.
     */
    changeRadio(e) {
        if (this.props.onChange) {
            this.props.onChange(isNaN(parseInt(e.currentTarget.value)) ? e.currentTarget.value === 'true' ? true : e.currentTarget.value === 'false' ? false : e.currentTarget.value : parseInt(e.currentTarget.value), e);
        }
    }

      /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div>
                {this.props.label &&
                    <p style={this.props.outerLabelStyle} className={`form-label ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                        {this.props.icon && (
                            <span className="fl__icon fl__icon--left mgR">
                                <i className={this.props.icon} />
                            </span>
                        )}
                        <span className="label">
                            {this.props.label}
                        </span>
                    </p>
                }
                {this.props.children &&
                    this.props.children
                }
                <div className={`md-radio ${this.props.className || ''}`}>
                    {this.props.options.map(e => (
                        <div className={this.props.optionsClassName} style={this.props.inputStyle} key={e.value} disabled={this.props.disabled} 
                        onClick={e.button && e.button.onClick } >
                            <input
                                id={`rdr-${e.label}-${e.value}`.split(' ').join('-') + '-' + this.props.key}
                                name={`rdr-${e.label}-${e.value}`.split(' ').join('-') + '-' + this.props.key}
                                type="radio"
                                value={e.value}
                                style={this.props.innerInputStyle}
                                className={this.props.inputClassName}
                                checked={e.value === this.props.selectedValue}
                                onChange={this.changeRadio}
                                disabled={this.props.disabled}
                           />
                            {e.label &&
                                <label style={this.props.labelStyle && this.props.labelStyle} htmlFor={`rdr-${e.label}-${e.value}`.split(' ').join('-') + '-' + this.props.key}>
                                    {e.label}
                                </label>
                            }
                            {e.button &&
                                <React.Fragment>
                                    {e.button.show &&
                                        <div className={`col-md-12 pr-0 pl-0 `}>
                                            <div onClick={e.button.onClick} className={`pdG ${e.button.className}`}>
                                                <button
                                                    style={{
                                                        backgroundImage: e.button.image ? 'url(' + e.button.image + ')' : undefined,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center',
                                                        width: '100%',
                                                        height: '70px'
                                                    }}
                                                    className={e.button.class + ' btn btn-transparent'}>
                                                    {e.button.text}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    ))}
                </div>
            </div>

        );
    }
}

Radio.defaultProps = {inputStyle: {marginTop: '6px'}, key: ''};

/**
 * Password properties validation.
 */
Radio.propTypes = {
    variant: PropTypes.bool,
    squared: PropTypes.bool,
    value: PropTypes.any,
    options: PropTypes.array,
    className: PropTypes.string,
    selectedValue: PropTypes.any,
    onChange: PropTypes.func,
    radioStyle: PropTypes.object,
    labelStyle: PropTypes.object
};
