import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    TextInput,
    SelectInput,
    MoneyInput,
    Checkbox,
    CustomMaskedInput
} from 'common/components/inputs';

/**
* Component responsible of the forgot password form.
*/
export default class CompanyForm extends React.Component {
    /**
  * Initializes the form.
  */
    constructor(props) {
        super(props);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);

    }

    /**
  * Fired after formsy validations
  */
    canSubmit(canSubmit) {
        this.props.canSubmit(canSubmit);
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-8 mgB2"
                        name="companyName"
                        errorClassName={this.props.errorFields.companyName.errorClass}
                        value={this.props.data.companyName}>
                        <TextInput
                            label={<span>Nombre de la empresa donde labora <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.companyName.dangerLabel}
                            value={this.props.data.companyName}
                            onChange={(val) => this.props.onFieldChange('companyName', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="companyType"
                        validations="isTrue"
                        errorClassName={this.props.errorFields.companyType.errorClass}
                        value={this.props.data.companyType !== null}>
                        <SelectInput
                            floatingLabel={<span>Tipo de empresa <span className="color-red">*</span></span>}
                            key={'data.companyType'}
                            dangerLabel={this.props.errorFields.companyType.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.companyTypes}
                            value={this.props.data.companyType}
                            onChange={(val) => this.props.onFieldChange('companyType', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-8 mgB2"
                        name="companyStreet"
                        errorClassName={this.props.errorFields.companyStreet.errorClass}
                        value={this.props.data.companyStreet}>
                        <TextInput
                            label={<span>Dirección de la empresa <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.companyStreet.dangerLabel}
                            value={this.props.data.companyStreet}
                            onChange={(val) => this.props.onFieldChange('companyStreet', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="companyPhone"
                        validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                        errorClassName={this.props.errorFields.companyPhone.errorClass}
                        validationError = "Por favor ingrese un número de telefono válido."
                        value={this.props.data.companyPhone}>
                        <TextInput
                            label={<span>Teléfono de la empresa <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.companyPhone.dangerLabel}
                            value={this.props.data.companyPhone}
                            onChange={(val) => this.props.onFieldChange('companyPhone', val)}
                            maxLength={10}
                            numbers={true}
                            onlyNumbers={true}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-3 mgB2"
                        name="companyTitle"
                        errorClassName={this.props.errorFields.companyTitle.errorClass}
                        value={this.props.data.companyTitle}>
                        <TextInput
                            label={<span>Cargo que ocupa <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.companyTitle.dangerLabel}
                            value={this.props.data.companyTitle}
                            onChange={(val) => this.props.onFieldChange('companyTitle', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-3 mgB2"
                        name="workingTime"
                        errorClassName={this.props.errorFields.workingTime.errorClass}
                        value={this.props.data.workingTime !== null && this.props.data.workingTime !== undefined}
                        validations="isTrue">
                        <SelectInput
                            floatingLabel={<span>Tiempo en la empresa <span className="color-red">*</span></span>}
                            key={'data.workingTime'}
                            dangerLabel={this.props.errorFields.workingTime.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.workingTimes}
                            value={this.props.data.workingTime}
                            onChange={(val) => this.props.onFieldChange('workingTime', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-3 mgB2"
                        name="salary"
                        errorClassName={this.props.errorFields.salary.errorClass}
                        value={this.props.data.salary}>
                        <MoneyInput
                            floatingLabel={<span>Sueldo <span className="color-red">*</span></span>}
                            placeholder="RD$"
                            dangerLabel={this.props.errorFields.salary.dangerLabel}
                            value={this.props.data.salary}
                            onChange={(val) => this.props.onFieldChange('salary', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-12 col-md-3 mgB2"
                        name="otherIncomes"
                        value={this.props.data.otherIncomes}>
                        <MoneyInput
                            floatingLabel={<span>Otros ingresos</span>}
                            placeholder="RD$"
                            value={this.props.data.otherIncomes}
                            onChange={(val) => this.props.onFieldChange('otherIncomes', val)}
                        />
                    </FormsyInputWrapper>
                    {this.props.data.plan.financingEntity.optional_fields !== null &&
                    this.props.data.plan.financingEntity.optional_fields.fields.length > 0 &&
                    this.props.data.plan.financingEntity.optional_fields.fields.filter((elem) => {
                        return elem.belongto === 3;
                    }).length > 0 &&
                    <React.Fragment>
                        <h3 className="mgT col-12">
                            <strong>Otros datos de interes</strong>
                        </h3>
                        {this.props.data.plan.financingEntity.optional_fields.fields.filter((elem) => {
                            return elem.belongto === 3;
                        }).map((data) => {
                            if (data.require === false) {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <div className='col-md-6 col-12'>
                                            <TextInput
                                                label={<span>{data.name} </span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </div>
                                    );
                                case 2:
                                    return (
                                        <div className='col-md-6 col-12'>
                                            <TextInput
                                                label={<span>{data.name} <span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumber={true}
                                            />
                                        </div>);
                                case 3:
                                    return (
                                        <div className='col-md-6 col-12'>
                                            <MoneyInput
                                                floatingLabel={<span>{data.name} </span>}
                                                placeholder="RD$"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </div>);
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                        >
                                            <TextInput
                                                label={<span>{data.name}</span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>);
                                default:
                                    return '';
                                }
                            } else {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]|[a-z]|[A-Z]/}}
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name} <span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 2:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]/}}
                                            validationError = "Por favor solo números."
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 3:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]/}}
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <MoneyInput
                                                floatingLabel={<span>{data.name} <span className="color-red">*</span></span>}
                                                placeholder="RD$"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>);
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                        >
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 5:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/(3?[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|1?[012])\/(19|20)[0-9]{2}/}}
                                            required
                                            value={this.getValue(data)}>
                                            <CustomMaskedInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                placeholder='DD/MM/YYYY'
                                                mask="11/11/1111"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 6:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={this.getValue(data) !== null}
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <SelectInput
                                                floatingLabel={<span>{data.name}<span className="color-red">*</span></span>}
                                                key={'optionalfieldType'}
                                                label="Seleccionar"
                                                searchable={false}
                                                options={data.values.split(',').map(element => {
                                                    return {
                                                        value: element,
                                                        label: element
                                                    };
                                                })}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                default:
                                    return '';
                                }
                            }
                        })
                        }
                    </React.Fragment>}
                    <div className="col-12 mgT">
                        <p>
                            Las <strong>cuotas mostradas en esta aplicación son una estimación</strong> de acuerdo a las tasas
                            provistas por el banco, las mismas <strong>pueden variar al momento del cierre con la institución</strong> financiera
                            elegida y según las políticas de financiamiento de esa institución.
                        </p>
                        <p>
                            Algunos <strong>préstamos &quot;sin intereses&quot; llevan una comisión por &quot;gastos de cierre&quot; y/o &quot;seguros del deudor&quot;</strong>.
                            que tratamos de reflejar correctamente en las cuotas mostradas, pero que también <strong>serán confirmadas al momento del cierre.</strong>
                        </p>
                        <p>
                            Declaro bajo la fe de juramento que <strong>me encuentro en total capacidad y calidad legal para completar esta solicitud </strong>
                            y que <strong>todos los datos aportados en la misma son ciertos.</strong> Así mismo <strong>autorizo tanto a {this.props.storeName} y las entidades financieras </strong>
                            utilizadas por {this.props.storeName} <strong>a verificar la información suministrada</strong> por todos los medios,
                            y mediante la consulta de los distintos buró de créditos.
                        </p>
                    </div>
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="approval"
                        errorClassName={this.props.errorFields.approval.errorClass}
                        validations="isTrue"
                        value={this.props.data.approval}>
                        <Checkbox
                            squared={true}
                            label={<span>Confirmo y autorizo lo declarado en este formulario <span className="color-red">*</span></span>}
                            checked={this.props.data.approval}
                            dangerLabel={this.props.errorFields.approval.dangerLabel}
                            value={this.props.data.approval}
                            onChange={() => this.props.onFieldChange('approval', !this.props.data.approval)}/>
                    </FormsyInputWrapper>
                </div>
            </Formsy>
        );
    }

    getValue = (val) => {
        let valout = this.props.data.optionalFields.fields.filter(el => {
            return (el.name === val.name && el.type === val.type && el.belongto === val.belongto);
        })[0].value;
        return valout;
    };
}

/**
 * Forgot password form properties validation.
 */
CompanyForm.propTypes = {
    dropdowns: PropTypes.object,
    data: PropTypes.object,
    errorFields: PropTypes.object,
    onFieldChange: PropTypes.func.isRequired
};
