import React from 'react';
import PropTypes from 'prop-types';

import { CREVRON_DOWN_ICON } from 'common/constants/icon.constant';

/**
 * Reusable dropdown component which displays it's items in a list.
 */
export default class Dropdown extends React.Component {
    /**
     * Initializes the component.
     */
    constructor(props) {
        super(props);

        this.onUserClick = this.onUserClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);

        this.state = { show: false };
    }

    /**
     * Adds an mouseup event when the component has been mounted.
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.onUserClick);
    }

    /**
     * Clear the DOM from the added events.
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onUserClick);
    }

    /**
     * Fired once the user's click anywhere. The idea is to close the dropdown
     * if the user clicked outside of it.
     *
     * @param  {object} event the DOM event.
     */
    onUserClick(event) {
        if (this.dropdown == null || !this.dropdown.contains(event.target)) {
            this.setState({ show: false });
        }
    }

    /**
     * Switches the current dropdown show state.
     */
    toggleDropdown() {
        this.setState({ show: !this.state.show });
    }

    /**
     * Renders the authenticated user's routes.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div
                className={`dropdown ${this.state.show ? 'open' : ''} ${this.props.className} dropdown--${this.props.align}`}
                ref={dropdown => this.dropdown = dropdown}>
                <button
                    className={`dropdown__toggle ${this.props.buttonClassName}`}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={this.state.show}
                    onClick={this.toggleDropdown}>
                    {this.props.content}
                    {!this.props.content &&
                        <div>
                            {this.props.label}
                            <i className={CREVRON_DOWN_ICON}></i>
                        </div>
                    }
                </button>
                <div
                    className={`dropdown__menu ${this.props.open}`}
                    aria-labelledby="dropdownMenuButton">
                    <div className="dropdown__arrow"></div>
                    <ul className="dropdown__list">
                        { this.props.children }
                    </ul>
                </div>
            </div>
        );
    }
}

Dropdown.defaultProps = {
    className: '',
    buttonClassName: '',
    align: 'left',
    open: 'default'
};

/**
 * Dropdown properties validation.
 */
Dropdown.propTypes = {
    align: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    buttonClassName: PropTypes.string
};
