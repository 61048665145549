import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Document, Page } from 'react-pdf';

import {
    FormsyInputWrapper,
    Radio,
    TextInput,
    SelectInput,
    MoneyInput,
    CustomMaskedInput
} from 'common/components/inputs';
import Dropzone from 'common/components/dropzone';
import ImagePreview from 'common/components/image-preview';
import EmptyState from 'common/components/empty-state';

import {IDENTIFICATION_TYPES} from 'common/constants/enums.constant';
import {MS_WORD_ICON} from 'common/constants/icon.constant';

import _ from 'lodash';

/**
* Component responsible of the forgot password form.
*/
export default class RegisterUserForm extends React.Component {
    /**
  * Initializes the form.
  */
    constructor(props) {
        super(props);

        this.state = {
            phonEdit: false,
            cedEdit: false
        };
        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);

    }

    /**
  * Fired after formsy validations
  */
    canSubmit(canSubmit) {
        this.props.canSubmit(canSubmit);
    }

    onIdentificationFileChanged = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.props.onFieldChange('identificationFile', value);
    };

    onIdentificationFile2Changed = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.props.onFieldChange('identificationFile2', value);
    };

    componentDidMount = () => {
        if (!(this.props.userData.phone.length === 10) || this.props.userData.phone.includes('+1') || (['809', '829', '849'].indexOf(this.props.userData.phone.substring(0, 3)) === -1)) {
            let textphone = document.getElementById('phonedata');
            textphone.style.borderColor = 'red';
        }
        if ((this.props.userData.identification === null) || !(this.props.userData.identification.length === 11) ) {
            let textphone = document.getElementById('identificationdata');
            textphone.style.borderColor = 'red';
        } else {
            if (!this.props.userData.plan.financingEntity.services_foreigners && !_.isNumber(parseInt(this.props.userData.identification))) {
                let textphone = document.getElementById('identificationdata');
                textphone.style.borderColor = 'red';
            }
        }

        if (this.props.userData.province === null) {
            let province = document.getElementById('dropProvince');
            province.firstChild.style.borderColor = 'red';
        }

        /*if (this.props.optionalFields.fields.length > 0) {
            this.optionalFieldHere = this.props.optionalFields.fields.filter((elem) => {
                return elem.belongto === 1;
            });
        }*/
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'name'; }) === -1 && <FormsyInputWrapper
                        required
                        className="col-12 col-md-6 mgB2"
                        name="name"
                        errorClassName={this.props.errorFields.name.errorClass}
                        value={this.props.userData.name}>
                        <TextInput
                            label={<span>Nombre <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.props.errorFields.name.dangerLabel}
                            placeholder="Inserte el nombre"
                            value={this.props.userData.name}
                            onChange={(val) => this.props.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>}
                    {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'lastname'; }) === -1 && <FormsyInputWrapper
                        required
                        className="col-12 col-md-6 mgB2"
                        name="lastname"
                        errorClassName={this.props.errorFields.lastname.errorClass}
                        value={this.props.userData.lastname}>
                        <TextInput
                            label={<span>Apellidos <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.props.errorFields.lastname.dangerLabel}
                            placeholder="Inserte los Apellidos"
                            value={this.props.userData.lastname}
                            onChange={(val) => this.props.onFieldChange('lastname', val)}
                        />
                    </FormsyInputWrapper>}
                    {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'identificationType'; }) === -1 &&
                     <FormsyInputWrapper
                         required
                         className="col-12 col-md-4 mgB2"
                         name="identificationType"
                         errorClassName={this.props.errorFields.identificationType.errorClass}
                         value={this.props.userData.identificationType !== null && this.props.userData.identificationType !== undefined}
                         validations="isTrue">
                         <Radio
                             selectedValue={this.props.userData.identificationType}
                             radioStyle={{ padding: '0px' }}
                             optionsClassName="bordered mgR"
                             className="d-flex"
                             labelStyle={{paddingRight: '5px'}}
                             inputStyle={{height: '44px', padding: '7px'}}
                             onChange={(val) => this.props.onFieldChange('identificationType', val)}
                             label={<span>Documento de identidad <span className="color-red">*</span></span>}
                             dangerLabel={this.props.errorFields.identificationType.dangerLabel}
                             options={this.props.dropdowns.identificationTypes.map(element => {
                                 return {
                                     value: element.value,
                                     label: element.label
                                 };
                             })}
                         />
                     </FormsyInputWrapper>}
                    <div className="col-12 col-md-4 mgB2">
                        {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'identification'; }) === -1 && this.props.userData.identificationType === IDENTIFICATION_TYPES.CEDULA &&
                        <FormsyInputWrapper
                            required
                            //className="col-12 col-md-4 mgB"
                            name="identification"
                            errorClassName={this.props.errorFields.identification.errorClass}
                            value={this.props.userData.identification}
                            validationError='Ingrese una cédula válida.'
                            validations={{matchRegexp:/[0-9]{11}/}}
                        >
                            <TextInput
                                id="identificationdata"
                                label={<span>No. Documento de identidad <span className="color-red">*</span></span>}
                                dangerLabel={this.props.errorFields.identification.dangerLabel}
                                value={this.props.userData.identification}
                                onChange={(val) => this.props.onFieldChange('identification', val)}
                                maxLength={11}
                                numbers={!this.props.userData.plan.financingEntity.services_foreigners}
                            />
                        </FormsyInputWrapper>
                        }
                        { _.findIndex(this.props.notVisibleFields, function(el) { return el === 'identification'; }) === -1 && this.props.userData.identificationType === IDENTIFICATION_TYPES.PASSPORT &&
                        <FormsyInputWrapper
                            required
                            //className="col-12 col-md-4 mgB2"
                            errorClassName={this.props.errorFields.identification.errorClass}
                            value={this.props.userData.identification}
                            name="identification">
                            <TextInput
                                label={<span>No. Documento de identidad <span className="color-red">*</span></span>}
                                dangerLabel={this.props.errorFields.identification.dangerLabel}
                                value={this.props.userData.identification}
                                onChange={(val) => this.props.onFieldChange('identification', val)}
                            />
                        </FormsyInputWrapper>
                        }
                    </div>
                    <div className="col-12 col-md-4 mgB2">
                        {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'partner_number'; }) === -1 && this.props.userData.plan.financingEntity.type.id === 2 && !this.props.userData.plan.financingEntity.only_partners &&
                        <FormsyInputWrapper
                            value={this.props.userData.partner_number}
                            name="partner_number">
                            <TextInput
                                label={<span>No. de Asociado</span>}
                                value={this.props.userData.partner_number}
                                onChange={(val) => this.props.onFieldChange('partner_number', val)}
                            />
                        </FormsyInputWrapper>

                        }

                        {_.findIndex(this.props.notVisibleFields, function(el) { return el === 'partner_number'; }) === -1 && this.props.userData.plan.financingEntity.type.id === 2 && this.props.userData.plan.financingEntity.only_partners &&
                        <FormsyInputWrapper
                            required
                            errorClassName={this.props.errorFields.partner_number.errorClass}
                            value={this.props.userData.partner_number}
                            name="partner_number">
                            <TextInput
                                label={<span>No. de Asociado <span className="color-red">*</span></span>}
                                dangerLabel={this.props.errorFields.partner_number.dangerLabel}
                                value={this.props.userData.partner_number}
                                onChange={(val) => this.props.onFieldChange('partner_number', val)}
                            />
                        </FormsyInputWrapper>

                        }
                    </div>


                    { _.findIndex(this.props.notVisibleFields, function(el) { return el === 'identificationFile'; }) === -1 && (this.props.userData.plan.financingEntity.require_photo === false) &&
                    <FormsyInputWrapper
                        className="col-12 col-md-6 mgB2"
                        name="identificationFile"
                        //${this.props.errorFields.identificationFile.dangerLabel ? 'text-danger' : ''}
                        value={this.props.userData.identificationFile !== null}>
                        <p className={`form-label  `}>
                            <span className="label">Documento de identidad de ambos lados (Opcional)</span>
                        </p>
                        <Dropzone
                            accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                            onDrop={this.onIdentificationFileChanged}
                            multiple={false}
                            full={true}>
                            <div>
                                {this.props.userData.identificationFile &&
                                    <div className="m-auto">
                                        {(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile.toLowerCase().includes('.pdf')) &&
                                            <Document
                                                file={this.props.userData.identificationFile}>
                                                <Page height={300} pageNumber={1} />
                                            </Document>
                                        }
                                        {(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile.toLowerCase().includes('.doc')) &&
                                            <div className="mgT15">
                                                <div className="mgB">
                                                    <span style={{fontSize: '4rem'}}>
                                                        <i className = {MS_WORD_ICON}/>
                                                    </span>
                                                </div>
                                                <p>{this.props.userData.identificationFile.name}</p>
                                            </div>
                                        }
                                        {!(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile.toLowerCase().includes('.pdf')) &&
                                            !(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile.toLowerCase().includes('.doc')) &&
                                            <ImagePreview
                                                style = {
                                                    {
                                                        height: '300px',
                                                        width: '100%',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat'
                                                    }
                                                }
                                                className="image-preview image-preview__xl"
                                                image={this.props.userData.identificationFile}>
                                            </ImagePreview>
                                        }
                                    </div>
                                }
                                {!this.props.userData.identificationFile &&
                                    <div className="mgT15">
                                        <EmptyState
                                            imgStyle={
                                                {
                                                    borderRadius: '150px',
                                                    margin: 'auto',
                                                    width: '170px',
                                                    padding: '20px'
                                                }
                                            }
                                            small={true}
                                            image="/images/add_image.svg"
                                            title=""
                                            text=""
                                        />
                                        <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento)</p>
                                    </div>
                                }
                            </div>
                        </Dropzone>
                    </FormsyInputWrapper>}

                    { _.findIndex(this.props.notVisibleFields, function(el) { return el === 'identificationFile'; }) === -1 && (this.props.userData.plan.financingEntity.require_photo === true) &&
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-6 mgB2"
                        name="identificationFile"
                        errorClassName={this.props.errorFields.identificationFile.errorClass}
                        validations="isTrue" //${this.props.errorFields.identificationFile.dangerLabel ? 'text-danger' : ''}
                        value={this.props.userData.identificationFile !== null}>
                        <p className={`form-label  `}>
                            <span className="label">Documento de identidad de ambos lados <span className="color-red">*</span></span>
                        </p>
                        <Dropzone
                            accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                            onDrop={this.onIdentificationFileChanged}
                            multiple={false}
                            full={true}>
                            <div>
                                {this.props.userData.identificationFile &&
                                    <div className="m-auto">
                                        {(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile.toLowerCase().includes('.pdf')) &&
                                            <Document
                                                file={this.props.userData.identificationFile}>
                                                <Page height={300} pageNumber={1} />
                                            </Document>
                                        }
                                        {(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile.toLowerCase().includes('.doc')) &&
                                            <div className="mgT15">
                                                <div className="mgB">
                                                    <span style={{fontSize: '4rem'}}>
                                                        <i className = {MS_WORD_ICON}/>
                                                    </span>
                                                </div>
                                                <p>{this.props.userData.identificationFile.name}</p>
                                            </div>
                                        }
                                        {!(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile.toLowerCase().includes('.pdf')) &&
                                            !(this.props.userData.identificationFile.name ? this.props.userData.identificationFile.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile.toLowerCase().includes('.doc')) &&
                                            <ImagePreview
                                                style = {
                                                    {
                                                        height: '300px',
                                                        width: '100%',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat'
                                                    }
                                                }
                                                className="image-preview image-preview__xl"
                                                image={this.props.userData.identificationFile}>
                                            </ImagePreview>
                                        }
                                    </div>
                                }
                                {!this.props.userData.identificationFile &&
                                    <div className="mgT15">
                                        <EmptyState
                                            imgStyle={
                                                {
                                                    borderRadius: '150px',
                                                    margin: 'auto',
                                                    width: '170px',
                                                    padding: '20px'
                                                }
                                            }
                                            small={true}
                                            image="/images/add_image.svg"
                                            title=""
                                            text=""
                                        />
                                        <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento)</p>
                                    </div>
                                }
                            </div>
                        </Dropzone>
                    </FormsyInputWrapper>}
                    { _.findIndex(this.props.notVisibleFields, function(el) { return el === 'identificationFile2'; }) === -1 && <FormsyInputWrapper
                        className="col-12 col-md-6 mgB2"
                        name="identificationFile2">
                        <Dropzone
                            accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                            onDrop={this.onIdentificationFile2Changed}
                            multiple={false}
                            style={{marginTop: '23px'}}
                            full={true}>
                            <div>
                                {this.props.userData.identificationFile2 &&
                                    <div className="m-auto">
                                        {(this.props.userData.identificationFile2.name ? this.props.userData.identificationFile2.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile2.toLowerCase().includes('.pdf')) &&
                                            <Document
                                                file={this.props.userData.identificationFile2}>
                                                <Page height={300} pageNumber={1} />
                                            </Document>
                                        }
                                        {(this.props.userData.identificationFile2.name ? this.props.userData.identificationFile2.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile2.toLowerCase().includes('.doc')) &&
                                            <div className="mgT15">
                                                <div className="mgB">
                                                    <span style={{fontSize: '4rem'}}>
                                                        <i className = {MS_WORD_ICON}/>
                                                    </span>
                                                </div>
                                                <p>{this.props.userData.identificationFile2.name}</p>
                                            </div>
                                        }
                                        {!(this.props.userData.identificationFile2.name ? this.props.userData.identificationFile2.name.toLowerCase().includes('.pdf') : this.props.userData.identificationFile2.toLowerCase().includes('.pdf')) &&
                                            !(this.props.userData.identificationFile2.name ? this.props.userData.identificationFile2.name.toLowerCase().includes('.doc') : this.props.userData.identificationFile2.toLowerCase().includes('.doc')) &&
                                            <ImagePreview
                                                style = {
                                                    {
                                                        height: '300px',
                                                        width: '100%',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat'
                                                    }
                                                }
                                                className="image-preview image-preview__xl"
                                                image={this.props.userData.identificationFile2}>
                                            </ImagePreview>
                                        }
                                    </div>
                                }
                                {!this.props.userData.identificationFile2 &&
                                    <div className="mgT15">
                                        <EmptyState
                                            imgStyle={
                                                {
                                                    borderRadius: '150px',
                                                    margin: 'auto',
                                                    width: '170px',
                                                    padding: '20px'
                                                }
                                            }
                                            small={true}
                                            image="/images/add_image.svg"
                                            title=""
                                            text=""
                                        />
                                        <p><strong className="color-blue">Insertar</strong> documento de identidad (en formato de imágen o documento) (opcional)</p>
                                    </div>
                                }
                            </div>
                        </Dropzone>
                    </FormsyInputWrapper>}
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-7 mgB2"
                        name="email"
                        validations="isEmail"
                        errorClassName={this.props.errorFields.email.errorClass}
                        validationError="Por favor ingrese un correo electrónico válido."
                        value={this.props.userData.email}>
                        <TextInput
                            label={<span>Correo electrónico <span className="color-red">*</span></span>}
                            placeholder="Inserte el correo"
                            dangerLabel={this.props.errorFields.email.dangerLabel}
                            value={this.props.userData.email}
                            onChange={(val) => this.props.onFieldChange('email', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-5 mgB2"
                        name="phone"
                        validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                        errorClassName={this.props.errorFields.phone.errorClass}
                        validationError = "Por favor ingrese un número de telefono válido."
                        value={this.props.userData.phone}
                    >
                        <TextInput
                            label={<span>Teléfono <span className="color-red">*</span></span>}
                            id="phonedata"
                            dangerLabel={this.props.errorFields.phone.dangerLabel}
                            value={this.props.userData.phone}
                            onChange={(val) => this.props.onFieldChange('phone', val)}
                            maxLength={10}
                            numbers={true}
                        />
                    </FormsyInputWrapper>
                    <h3 className="mgT col-12">
                        <strong>Datos del domicilio</strong>
                    </h3>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-7 mgB2"
                        name="street"
                        errorClassName={this.props.errorFields.street.errorClass}
                        value={this.props.userData.street}>
                        <TextInput
                            label={<span>Dirección <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.street.dangerLabel}
                            value={this.props.userData.street}
                            onChange={(val) => this.props.onFieldChange('street', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-5 mgB2"
                        name="city"
                        errorClassName={this.props.errorFields.city.errorClass}
                        value={this.props.userData.city}>
                        <TextInput
                            label={<span>Ciudad <span className="color-red">*</span></span>}
                            dangerLabel={this.props.errorFields.city.dangerLabel}
                            value={this.props.userData.city}
                            onChange={(val) => this.props.onFieldChange('city', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="province"
                        validations="isTrue"
                        errorClassName={this.props.errorFields.province.errorClass}
                        value={this.props.userData.province !== null}>
                        <SelectInput
                            floatingLabel={<span>Provincia <span className="color-red">*</span></span>}
                            key={'userData.province'}
                            id="dropProvince"
                            dangerLabel={this.props.errorFields.province.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.provinces}
                            value={this.props.userData.province}
                            onChange={(val, name) => this.props.onFieldChange('province', val, name)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 col-md-4 mgB2"
                        name="country"
                        validations="isTrue"
                        errorClassName={this.props.errorFields.country.errorClass}
                        value={this.props.userData.country !== null}>
                        <SelectInput
                            floatingLabel={<span>País <span className="color-red">*</span></span>}
                            key={'userData.country'}
                            dangerLabel={this.props.errorFields.country.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.countries}
                            value={this.props.userData.country}
                            onChange={(val) => this.props.onFieldChange('country', val)}
                        />
                    </FormsyInputWrapper>

                    {this.props.userData.optionalFields != null && this.props.userData.optionalFields.fields.length > 0 && this.props.userData.optionalFields.fields.filter((elem) => {
                        return elem.belongto === 1;
                    }).length > 0 &&
                    <React.Fragment>
                        <h3 className="mgT col-12">
                            <strong>Otros datos de interes</strong>
                        </h3>
                        {this.props.userData.optionalFields.fields.filter((elem) => {
                            return elem.belongto === 1;
                        }).map((data) => {
                            if (data.require === true) {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]|[a-z]|[A-Z]/}}
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name} <span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 2:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]/}}
                                            validationError = "Por favor solo números."
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 3:
                                    return (
                                        <FormsyInputWrapper
                                            required
                                            className="col-12 col-md-6"
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/[0-9]/}}
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <MoneyInput
                                                floatingLabel={<span>{data.name} <span className="color-red">*</span></span>}
                                                placeholder="RD$"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>);
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                        >
                                            <TextInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 5:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/(3?[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|1?[012])\/(19|20)[0-9]{2}/}}
                                            required
                                            value={this.getValue(data)}>
                                            <CustomMaskedInput
                                                label={<span>{data.name}<span className="color-red">*</span></span>}
                                                placeholder='DD/MM/YYYY'
                                                mask="11/11/1111"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 6:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            required
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={this.getValue(data) !== null}
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <SelectInput
                                                floatingLabel={<span>{data.name}<span className="color-red">*</span></span>}
                                                key={'optionalfieldType'}
                                                label="Seleccionar"
                                                searchable={false}
                                                options={data.values.split(',').map(element => {
                                                    return {
                                                        value: element,
                                                        label: element
                                                    };
                                                })}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                default:
                                    return '';
                                }
                            } else {
                                switch (data.type) {
                                case 1:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name} </span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 2:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/[0-9]/}}
                                            validationError = "Por favor solo números."
                                            value={this.getValue(data)}>
                                            <TextInput
                                                label={<span>{data.name}</span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                numbers={true}
                                                onlyNumbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 3:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <MoneyInput
                                                floatingLabel={<span>{data.name} </span>}
                                                placeholder="RD$"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>);
                                case 4:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            validations={{matchRegexp:/(809|829|849)[0-9]{7}/}}
                                            value={this.getValue(data)}
                                        >
                                            <TextInput
                                                label={<span>{data.name}</span>}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                                maxLength={10}
                                                numbers={true}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 5:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            errorClassName={this.props.errorFields.optionalFields.errorClass}
                                            validations={{matchRegexp:/(3?[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|1?[012])\/(19|20)[0-9]{2}/}}
                                            value={this.getValue(data)}>
                                            <CustomMaskedInput
                                                label={<span>{data.name}</span>}
                                                placeholder='DD/MM/YYYY'
                                                mask="11/11/1111"
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                case 6:
                                    return (
                                        <FormsyInputWrapper
                                            className="col-12 col-md-6"
                                            name={`text-${data.var}`}
                                            value={this.getValue(data)}>
                                            <SelectInput
                                                floatingLabel={<span>{data.name}</span>}
                                                key={'optionalfieldType'}
                                                label="Seleccionar"
                                                searchable={false}
                                                options={data.values.split(',').map(element => {
                                                    return {
                                                        value: element,
                                                        label: element
                                                    };
                                                })}
                                                value={this.getValue(data)}
                                                onChange={(val) => this.props.onFieldChange('optionalFields', {data:data, val:val })}
                                            />
                                        </FormsyInputWrapper>
                                    );
                                default:
                                    return '';
                                }
                            }
                        })
                        }
                    </React.Fragment>}

                </div>
            </Formsy>
        );
    }

    getValue = (val) => {
        let valout = this.props.userData.optionalFields.fields.filter(el => {
            return (el.name === val.name && el.type === val.type && el.belongto === val.belongto);
        })[0].value;
        return valout;
    };
}



/**
 * Forgot password form properties validation.
 */
RegisterUserForm.propTypes = {
    dropdowns: PropTypes.object,
    userData: PropTypes.object,
    errorFields: PropTypes.object,
    onFieldChange: PropTypes.func.isRequired,
    notVisibleFields: PropTypes.array
};
