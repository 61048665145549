'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Document, Page } from 'react-pdf';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import {Checkbox} from 'common/components/inputs';
import Modal from 'common/components/modal';
import {
    PENCIL_ICON,
    DELETE_ICON,
    PLUS_CIRCLE_ICON,
    MS_WORD_ICON,
    MAP_MARKER_ICON
} from 'common/constants/icon.constant';
import {
    USERTYPES,
    IDENTIFICATION_TYPES
} from 'common/constants/enums.constant';
import mask from 'common/helpers/mask';
import money from 'common/helpers/money';
import ImagePreview from 'common/components/image-preview';

import CreateUserForm from '../create-user-form';
import UserActionsList from './user-actions-list';
import EditClientForm from '../edit-client-form';
import CreateUserAddressForm from '../create-user-address-form';
import EditFinancingProfile from './edit-financing-profile';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            dropdowns: userSelectors.getDropdowns(state),
            actions: userSelectors.getUserActions(state),
            financings: userSelectors.getUserFinancings(state),
            registerUserDropdowns: userSelectors.getRegisterDropdowns(state),
            actionsLoading: userSelectors.getUserActionsLoading(state),
            actionsError: userSelectors.getUserActionsError(state),
            simpleError: userSelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        },
        getUserActions: (id) => {
            dispatch(userActions.getUserActions(id));
        },
        markAddressAsDefault: (id, userid) => {
            dispatch(userActions.markAddressAsDefault(id, userid));
        },
        deleteAddress: (id, userid) => {
            dispatch(userActions.deleteAddress(id, userid));
        },
        getRegisterUserDropdowns: () => {
            dispatch(userActions.getRegisterUserDropdowns());
        },
        addAddress: (params) => {
            dispatch(userActions.addAddress(params));
        },
        editAddress: (id, params) => {
            dispatch(userActions.editAddress(id, params));
        },
        editFinancingProfile: (data) => {
            dispatch(userActions.editFinancingProfile(data));
        },
        createFinancingProfile: (data) => {
            dispatch(userActions.createFinancingProfile(data));
        }
    };
};

class UserDetailBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showEditModal: false,
            showAddressesModal: false,
            showAddAddressModal: false,
            showEditFinancingProfile: false,
            selectedAddress: null,
            showEditAddressModal: false,
            isDefault: false,
            showDeleteAddressModal: false,
            isAuth: [USERTYPES.CLIENT, USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(props.authUser.userType.id) > -1,
            isClient: props.user.userType.id === USERTYPES.CLIENT,
            isClientProfile: props.authUser.id === props.user.id && props.user.userType.id === USERTYPES.CLIENT
        };
    }

    componentDidMount() {
        if (this.props.dropdowns.stores.length === 0) {
            this.props.getDropdowns();
        }

        if (this.props.registerUserDropdowns.countries.length === 0) {
            this.props.getRegisterUserDropdowns();
        }

        this.props.getUserActions(this.props.user.id);
    }

    renderAddressesModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showAddressesModal: false})}
                id='edit-user-modal'
                className="white-background"
                closable={true}
                noDivider={true}
                showModal={this.state.showAddressesModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/add_address.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Mis direcciones ({this.props.user.addresses.length})</h3>
                {this.props.user.addresses.map(el => {
                    return (
                        <div key={'address-' + el.id} className="user-detail-box col-12 mgL0 mgB">
                            <p>{el.id === this.props.user.defaultAddress.id ? 'Dirección preferida' : ''}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{el.name}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{el.street}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{el.city}, {el.province.name}, {el.country.name}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{el.phone}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{el.postalCode ? 'Código postal ' + el.postalCode : ''}</p>
                            {el.id !== this.props.user.defaultAddress.id &&
                                <Checkbox
                                    squared={true}
                                    label={'Marcar como preferida'}
                                    checked={false}
                                    value={true}
                                    onChange={() => this.props.markAddressAsDefault(el.id, this.props.user.id)}/>
                            }
                            {this.state.isAuth &&
                                <div className="row">
                                    <div className="col-md-4">
                                        <button
                                            onClick={() => this.setState({
                                                showEditAddressModal: true,
                                                selectedAddress: el,
                                                isDefault: el.id === this.props.user.defaultAddress.id
                                            })}
                                            className="btn btn-transparent btn-link pdR0">
                                            <strong>
                                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                                Editar
                                            </strong>
                                        </button>
                                    </div>
                                    {this.props.user.addresses.length > 1 &&
                                    <div className="col-md-4">
                                        <button
                                            onClick={() => this.setState({
                                                showDeleteAddressModal: true,
                                                selectedAddress: el
                                            })}
                                            className="btn btn-transparent btn-link pdR0">
                                            <strong>
                                                <i style={{fontSize: '14px', marginRight: '10px'}} className={DELETE_ICON}/>
                                                Eliminar
                                            </strong>
                                        </button>
                                    </div>}
                                    <div className="col-md-4"/>
                                </div>
                            }
                        </div>
                    );
                })}
            </Modal>
        );
    }

    renderEditUserModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditModal: false})}
                id='edit-user-modal'
                className="white-background"
                closable={false}
                noDivider={true}
                showModal={this.state.showEditModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_user.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Editar usuario</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                {this.state.isClientProfile &&
                    <EditClientForm
                        error={this.props.simpleError}
                        creating={false}
                        userData={this.props.user}
                        dropdowns={this.props.registerUserDropdowns}
                        authUser={this.props.authUser}
                        onCancel={() => this.setState({showEditModal: false})}
                        onSubmit={this.onEditUser}
                    />
                }
                {!this.state.isClientProfile &&
                    <CreateUserForm
                        error={this.props.simpleError}
                        creating={false}
                        userData={this.props.user}
                        dropdowns={this.props.dropdowns}
                        authUser={this.props.authUser}
                        onCancel={() => this.setState({showEditModal: false})}
                        onSubmit={this.onEditUser}
                    />
                }
            </Modal>
        );
    }

    renderAddAddressModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showAddAddressModal: false})}
                id='edit-user-modal'
                className="white-background"
                closable={false}
                noDivider={true}
                showModal={this.state.showAddAddressModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/add_address.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Nueva dirección</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <CreateUserAddressForm
                    error={this.props.simpleError}
                    creating={true}
                    data={this.state.selectedAddress}
                    isDefault={this.props.user.addresses.length === 0}
                    dropdowns={this.props.registerUserDropdowns}
                    authUser={this.props.authUser}
                    userid={(this.props.authUser.userType.id === 1) ? this.props.authUser.id : this.props.user.id}
                    onCancel={() => this.setState({showAddAddressModal: false})}
                    onSubmit={this.props.addAddress}
                />
            </Modal>
        );
    }

    renderEditAddressModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditAddressModal: false, isDefault: false})}
                id='edit-user-modal'
                className="white-background"
                closable={false}
                noDivider={true}
                showModal={this.state.showEditAddressModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_address.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Editar dirección</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <CreateUserAddressForm
                    error={this.props.simpleError}
                    creating={false}
                    data={this.state.selectedAddress}
                    isDefault={this.state.isDefault}
                    dropdowns={this.props.registerUserDropdowns}
                    authUser={this.props.authUser}
                    userid={(this.props.authUser.userType.id === 1) ? this.props.authUser.id : this.props.user.id}
                    onCancel={() => this.setState({showEditAddressModal: false, isDefault: false})}
                    onSubmit={(data) => this.props.editAddress(this.state.selectedAddress.id, data)}
                />
            </Modal>
        );
    }

    renderDeleteAddressModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteAddressModal: false})}
                id='edit-user-modal'
                className="white-background"
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteAddressModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_address.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Eliminar dirección</h3>
                {this.props.simpleError &&
                    <div className='has-error mgB'>
                        {typeof this.props.simpleError === 'string' &&
                            <span className='help-block'>{this.props.simpleError}</span>
                        }
                        {typeof this.props.simpleError === 'object' &&
                            Object.entries(this.props.simpleError).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <p className="text-center">¿Está seguro que desea eliminar esta dirección?</p>
                <div style={{margin: '0px'}} className="row">
                    <button
                        onClick={() => this.props.deleteAddress(this.state.selectedAddress.id, this.props.user.id)}
                        className="btn btn-outline col-md-4">
                        Confirmar
                    </button>
                    <div className="col-md-4 mgT" />
                    <button
                        onClick={() => this.setState({showDeleteAddressModal: false})}
                        className="btn btn-primary col-md-4">
                        Cancelar
                    </button>
                </div>
            </Modal>
        );
    }

    renderEditFinancingProfileModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditFinancingProfile: false})}
                id='edit-financing-profile-modal'
                className="white-background edit-financing-modal"
                closable={false}
                noDivider={true}
                showModal={this.state.showEditFinancingProfile}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_user.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">{(this.props.user.financingProfile) ? 'Editar datos del perfil' : 'Crear perfil financiero'}</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <EditFinancingProfile
                    dropdowns={this.props.registerUserDropdowns}
                    error={this.props.simpleError}
                    financing={this.props.user.financingProfile}
                    user={this.props.user}
                    onSubmit={(data) => {
                        if (this.props.user.financingProfile) {
                            this.props.editFinancingProfile(data);
                        } else {
                            this.props.createFinancingProfile(data);
                        }
                    }}
                    onCancel={() => this.setState({showEditFinancingProfile: false})}
                />
            </Modal>
        );
    }

    onEditUser = (params) => {
        let newParams = {...params};

        newParams['phone'] = newParams['phone'].replace(/[^0-9]/g, '');
        newParams['mobile'] = newParams['mobile'].replace(/[^0-9]/g, '');
        newParams['extension'] = newParams['extension'].replace(/[^0-9]/g, '');

        if (this.props.authUser.userType.id === USERTYPES.ADMIN) {
            if (newParams['userType'] === USERTYPES.EMPLOYEE) {
                newParams['financingEntity'] = null;
            }
            if (newParams['userType'] === USERTYPES.AGENT) {
                newParams['store'] = null;
            }
        } else {
            if (this.props.authUser.userType.id === USERTYPES.EMPLOYEE) {
                newParams['userType'] = USERTYPES.EMPLOYEE;
                newParams['store'] = this.props.authUser.entity.id;
                newParams['financingEntity'] = null;
            }
        }

        this.props.updateUser(this.props.user.id, newParams);
    };

    renderUserInfo() {
        let identification = this.props.user.identificationType && this.props.user.identificationType.id === IDENTIFICATION_TYPES.CEDULA ? mask(this.props.user.identification, '###-#######-#') : this.props.user.identification;
        return (
            <div className="user-detail-box row mgL0 mgB0 white-background">
                <div className="col-md-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Datos del usuario</p></strong>
                </div>
                <div className="col-md-6 text-md-right">
                    {(this.state.isAuth || (this.props.authUser.id === this.props.user.id)) &&
                        <button
                            onClick={() => this.setState({showEditModal: true})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Editar datos
                            </strong>
                        </button>
                    }
                </div>
                <hr className="w-100" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>{this.state.isClientProfile && this.props.user.identificationType ? this.props.user.identificationType.name : this.props.user.userType.name}</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.state.isClientProfile && this.props.user.identification ? identification : this.props.user.entity ? this.props.user.entity.name : 'Electropay'}</p></strong>
                </div>
                {this.props.user.branch !== null &&
                <React.Fragment>
                    <hr className="w-95" />
                    <div className="col-md-6">
                        <p className="mgB0" style={{color: '#616161'}}>Sucursal que pertence</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.branch[0].branch_name}</p></strong>
                    </div>
                </React.Fragment>}
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Correo electrónico</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.email}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Teléfono</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.phone ? mask(this.props.user.phone, '(###) ###-####') : ''}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Extensión</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.extension}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Celular</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.mobile ? mask(this.props.user.mobile, '(###) ###-####') : ''}</p></strong>
                </div>
            </div>
        );
    }

    renderAddresses() {
        let defaultAddress = this.props.user.defaultAddress ? this.props.user.defaultAddress : this.props.user.addresses ? this.props.user.addresses[0] : null;
        return (
            <div className="user-detail-box row mgL0 mgB0 white-background">
                <div className="col-md-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">{this.state.isClientProfile ? 'Mis direcciones' : 'Direcciones'} ({this.props.user.addresses.length})</p></strong>
                </div>
                <div className="col-md-3 text-md-right">
                    {this.state.isAuth &&
                        <button
                            onClick={() => this.setState({showAddAddressModal: true})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PLUS_CIRCLE_ICON}/>
                                Agregar
                            </strong>
                        </button>
                    }
                </div>
                <div className="col-md-3 text-md-right">
                    {this.state.isAuth &&
                        <button
                            onClick={() => this.setState({showAddressesModal: true})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={MAP_MARKER_ICON}/>
                                Ver todas
                            </strong>
                        </button>
                    }
                </div>
                <hr className="w-100" />
                <div className="col-12">
                    {!defaultAddress &&
                        <div className="m-auto">
                            <EmptyState
                                imgStyle={
                                    {
                                        borderRadius: '150px',
                                        margin: 'auto',
                                        width: '170px',
                                        padding: '20px'
                                    }
                                }
                                image="/images/add_address.svg"
                                title=""
                                small={true}
                                text={'Aún no hay direcciones agregadas'}
                            />
                        </div>
                    }
                    {defaultAddress &&
                        <React.Fragment>
                            <p>{this.props.user.defaultAddress ? 'Dirección preferida' : 'Dirección'}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{defaultAddress.name}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{defaultAddress.street}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{defaultAddress.city}, {defaultAddress.province.name}, {defaultAddress.country.name}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{defaultAddress.phone}</p>
                            <p className="mgB0" style={{color: '#616161', fontSize: '14px'}}>{defaultAddress.postalCode ? 'Código postal ' + defaultAddress.postalCode : ''}</p>
                            {this.state.isAuth &&
                                <div className="row">
                                    <div className="col-md-3">
                                        <button
                                            onClick={() => this.setState({
                                                showEditAddressModal: true,
                                                selectedAddress: defaultAddress,
                                                isDefault: true
                                            })}
                                            className="btn btn-transparent btn-link pdR0">
                                            <strong>
                                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                                Editar
                                            </strong>
                                        </button>
                                    </div>
                                    {this.props.user.addresses.length > 1 &&
                                    <div className="col-md-3">
                                        <button
                                            onClick={() => this.setState({
                                                showDeleteAddressModal: true,
                                                selectedAddress: defaultAddress
                                            })}
                                            className="btn btn-transparent btn-link pdR0">
                                            <strong>
                                                <i style={{fontSize: '14px', marginRight: '10px'}} className={DELETE_ICON}/>
                                                Eliminar
                                            </strong>
                                        </button>
                                    </div>}
                                    <div className="col-md-6"/>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }

    renderTransactions() {
        let transactionsCount = 0;
        this.props.user.orders.forEach(element => {
            element.transactions.forEach(() => {
                transactionsCount++;
            });
        });
        return (
            <div style={{maxHeight: '600px'}} className="user-detail-box row mgL0 mgB0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Mis transacciones ({transactionsCount})</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-12">
                    {transactionsCount === 0 &&
                        <div className="m-auto">
                            <EmptyState
                                imgStyle={
                                    {
                                        borderRadius: '150px',
                                        margin: 'auto',
                                        width: '170px',
                                        padding: '20px'
                                    }
                                }
                                image="/images/financing_plan.svg"
                                title=""
                                small={true}
                                text={'No se han realizado transacciones en este mes.'}
                            />
                        </div>
                    }
                    {this.props.user.orders.map(el => {
                        let dataentities = this.props.financings.filter(fl => {
                            return fl.OID === el.id;
                        });
                        return el.transactions.map(el2 => {
                            return (
                                <div key={'transaction-' + el2.id} className="user-detail-box col-12 mgL0 mgB">
                                    <div className="row">
                                        <p className="col-8"><strong>Transacción {el2.id} - {el2.transactionStatus.name}</strong></p>
                                        <p className="col-4 text-right" style={{color: '#616161', fontSize: '14px'}}>{moment.utc(el2.createdAt).format('DD[/]MMMM[/]YYYY')}</p>
                                    </div>
                                    <hr className="w-100" />
                                    <div className="row mgB">
                                        <p className="col-3" style={{color: '#616161', fontSize: '14px'}}>Tienda</p>
                                        <p className="col-4" style={{color: '#616161', fontSize: '14px'}}>Entidad Financiera</p>
                                        <p className="col-3" style={{color: '#616161', fontSize: '14px'}}>Plan</p>
                                        <p className="col-2" style={{color: '#616161', fontSize: '14px'}}>Monto</p>
                                    </div>
                                    <div className="row">
                                        <p className="col-3">{el.store.name}</p>
                                        <p className="col-4">{(dataentities && dataentities.length > 0) ? dataentities[0].entity : '-'}</p>
                                        <p className="col-3">{(dataentities && dataentities.length > 0) ? dataentities[0].planName : '-'}</p>
                                        <p className="col-2">{el2.amount ? el2.currency.symbol + '$ ' + money(el2.amount.amount) : '-'}</p>
                                    </div>
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
        );
    }

    renderFinancingProfile() {
        return (
            <div style={{maxHeight: '600px'}} className="user-detail-box row mgL0 mgB white-background">
                <div className="col-md-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Perfil financiero</p></strong>
                </div>
                <div className="col-md-6 text-md-right">
                    {this.state.isAuth && this.state.isClient && this.props.user.financingProfile &&
                        <button
                            onClick={() => this.setState({showEditFinancingProfile: true})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Editar datos
                            </strong>
                        </button>
                    }
                    {this.state.isAuth && !this.props.user.financingProfile &&
                        <button
                            onClick={() => this.setState({showEditFinancingProfile: true})}
                            className="btn btn-transparent btn-link pdR0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Crear Perfil
                            </strong>
                        </button>
                    }
                </div>
                <hr className="w-100" />
                {!this.props.user.financingProfile &&
                    <div className="m-auto">
                        <EmptyState
                            imgStyle={
                                {
                                    borderRadius: '150px',
                                    margin: 'auto',
                                    width: '170px',
                                    padding: '20px'
                                }
                            }
                            image="/images/financing_plan.svg"
                            title=""
                            small={true}
                            text={'Aún no tiene un perfil financiero.'}
                        />
                    </div>
                }
                {this.props.user.financingProfile &&
                    <React.Fragment>
                        <div className="col-md-6 mgB">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Nombre Completo</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.name+' '+this.props.user.financingProfile.lastname}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.identificationType.name}</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.identification}</p></strong>
                                </div>
                                <div className="col-md-6">
                                    {this.props.user.financingProfile.identificationFile &&
                                    <div className="m-auto">
                                        {(this.props.user.financingProfile.identificationFile.name ? this.props.user.financingProfile.identificationFile.name.toLowerCase().includes('.pdf') : this.props.user.financingProfile.identificationFile.toLowerCase().includes('.pdf')) &&
                                            <Document
                                                file={this.props.user.financingProfile.identificationFile}>
                                                <Page height={300} pageNumber={1} />
                                            </Document>
                                        }
                                        {(this.props.user.financingProfile.identificationFile.name ? this.props.user.financingProfile.identificationFile.name.toLowerCase().includes('.doc') : this.props.user.financingProfile.identificationFile.toLowerCase().includes('.doc')) &&
                                            <div className="mgT15">
                                                <div className="mgB">
                                                    <span style={{fontSize: '4rem'}}>
                                                        <i className = {MS_WORD_ICON}/>
                                                    </span>
                                                </div>
                                                <p>{this.props.user.financingProfile.identificationFile.name}</p>
                                            </div>
                                        }
                                        {!(this.props.user.financingProfile.identificationFile.name ? this.props.user.financingProfile.identificationFile.name.toLowerCase().includes('.pdf') : this.props.user.financingProfile.identificationFile.toLowerCase().includes('.pdf')) &&
                                            !(this.props.user.financingProfile.identificationFile.name ? this.props.user.financingProfile.identificationFile.name.toLowerCase().includes('.doc') : this.props.user.financingProfile.identificationFile.toLowerCase().includes('.doc')) &&
                                            <ImagePreview
                                                style = {
                                                    {
                                                        height: '300px',
                                                        width: '100%',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat'
                                                    }
                                                }
                                                className="image-preview image-preview__xl"
                                                image={this.props.user.financingProfile.identificationFile}>
                                            </ImagePreview>
                                        }
                                    </div>
                                    }
                                </div>
                                <div className="col-md-6">
                                    {this.props.user.financingProfile.identificationFile2 &&
                                        <div className="m-auto">
                                            {(this.props.user.financingProfile.identificationFile2.name ? this.props.user.financingProfile.identificationFile2.name.toLowerCase().includes('.pdf') : this.props.user.financingProfile.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                <Document
                                                    file={this.props.user.financingProfile.identificationFile2}>
                                                    <Page height={300} pageNumber={1} />
                                                </Document>
                                            }
                                            {(this.props.user.financingProfile.identificationFile2.name ? this.props.user.financingProfile.identificationFile2.name.toLowerCase().includes('.doc') : this.props.user.financingProfile.identificationFile2.toLowerCase().includes('.doc')) &&
                                                <div className="mgT15">
                                                    <div className="mgB">
                                                        <span style={{fontSize: '4rem'}}>
                                                            <i className = {MS_WORD_ICON}/>
                                                        </span>
                                                    </div>
                                                    <p>{this.props.user.financingProfile.identificationFile2.name}</p>
                                                </div>
                                            }
                                            {!(this.props.user.financingProfile.identificationFile2.name ? this.props.user.financingProfile.identificationFile2.name.toLowerCase().includes('.pdf') : this.props.user.financingProfile.identificationFile2.toLowerCase().includes('.pdf')) &&
                                                !(this.props.user.financingProfile.identificationFile2.name ? this.props.user.financingProfile.identificationFile2.name.toLowerCase().includes('.doc') : this.props.user.financingProfile.identificationFile2.toLowerCase().includes('.doc')) &&
                                                <ImagePreview
                                                    style = {
                                                        {
                                                            height: '300px',
                                                            width: '100%',
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat'
                                                        }
                                                    }
                                                    className="image-preview image-preview__xl"
                                                    image={this.props.user.financingProfile.identificationFile2}>
                                                </ImagePreview>
                                            }
                                        </div>
                                    }
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Correo electrónico</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.email}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Teléfono</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{mask(this.props.user.financingProfile.phone, '(###) ###-####')}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Tipo de vivienda</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.livingType.name}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Tiempo en la vivienda</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.livingTime.name}</p></strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mgB">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Estado civil</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.isMarried ? 'Casado/a' : 'Soltero/a'}</p></strong>
                                </div>
                                {this.props.user.financingProfile.isMarried &&
                                    <React.Fragment>
                                        <hr className="w-95" />
                                        <div className="col-md-6">
                                            <p className="mgB0" style={{color: '#616161'}}>Nombre del cónyuge</p>
                                        </div>
                                        <div className="col-md-6 text-md-right">
                                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.spouseName}</p></strong>
                                        </div>
                                        <hr className="w-95" />
                                        <div className="col-md-6">
                                            <p className="mgB0" style={{color: '#616161'}}>Teléfono del cónyuge</p>
                                        </div>
                                        <div className="col-md-6 text-md-right">
                                            <strong><p className="mgB0" style={{color: '#616161'}}>{mask(this.props.user.financingProfile.spousePhone, '(###) ###-####')}</p></strong>
                                        </div>
                                        <hr className="w-95" />
                                        <div className="col-md-6">
                                            <p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.spouseIdentificationType ? this.props.user.financingProfile.spouseIdentificationType.name + 'del cónyuge' : 'Cédula del cónyuge'} </p>
                                        </div>
                                        <div className="col-md-6 text-md-right">
                                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.spouseIdentification ? this.props.user.financingProfile.spouseIdentification : '---'}</p></strong>
                                        </div>
                                    </React.Fragment>
                                }
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Nombre completo de un familiar</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.relativeName}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Teléfono de un familiar</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{mask(this.props.user.financingProfile.relativePhone, '(###) ###-####')}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Nombre de la empresa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.companyName}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Tipo de empresa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.companyType.name}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Dirección de la empresa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.companyStreet}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Teléfono de la empresa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{mask(this.props.user.financingProfile.companyPhone, '(###) ###-####')}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Cargo que ocupa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.companyTitle}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Tiempo en la empresa</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.user.financingProfile.workingTime.name}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Sueldo</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{'RD$ ' + money(this.props.user.financingProfile.salary)}</p></strong>
                                </div>
                                <hr className="w-95" />
                                <div className="col-md-6">
                                    <p className="mgB0" style={{color: '#616161'}}>Otros ingresos</p>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <strong><p className="mgB0" style={{color: '#616161'}}>{'RD$ ' + money(this.props.user.financingProfile.otherIncomes)}</p></strong>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }

    renderLogs() {
        return (
            <div className="user-logs-box row mgL0 mgB0 white-background">
                <div style={{height: '40px', paddingTop: '7px'}} className="col-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Registro de acciones</p></strong>
                </div>
                <hr className="w-100" />
                {!this.props.actionsLoading &&
                    this.props.actions !== null &&
                    !this.props.actionsError &&
                    <div className="col-12">
                        <UserActionsList
                            history={this.props.history}
                            actions={this.props.actions}
                        />
                    </div>
                }
                {!this.props.actionsLoading &&
                    this.props.actions === null &&
                    !this.props.actionsError &&
                    <div className="m-auto">
                        <EmptyState
                            imgStyle={
                                {
                                    borderRadius: '150px',
                                    margin: 'auto',
                                    width: '170px',
                                    padding: '20px'
                                }
                            }
                            image="/images/log_empty_state.svg"
                            title=""
                            small={true}
                            text={'Aún no hay acciones registradas'}
                        />
                    </div>
                }
                {this.props.actionsLoading &&
                    <LoadingComponent
                        height={160}
                        style={{padding: '50px 0px 0px'}}
                        width={120} />
                }
                {this.props.actionsError &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.actionsError}
                    />
                }
            </div>
        );
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 mgB">
                    {this.renderUserInfo()}
                </div>
                <div className="col-md-6 mgB">
                    {!this.state.isClient && this.renderLogs()}
                    {this.state.isClient && this.renderAddresses()}
                </div>
                {this.state.isClient &&
                    <div className="col-12">
                        {this.renderFinancingProfile()}
                    </div>
                }
                {this.state.isClient &&
                    <div className="col-12">
                        {this.renderTransactions()}
                    </div>
                }
                {this.renderEditUserModal()}
                {this.renderAddressesModal()}
                {this.renderAddAddressModal()}
                {this.renderEditAddressModal()}
                {this.renderDeleteAddressModal()}
                {this.renderEditFinancingProfileModal()}
            </div>
        );
    }
}

/**
 * Properties validations.
 */
UserDetailBody.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    user: PropTypes.object
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(UserDetailBody);
