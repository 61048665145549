import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import money from 'common/helpers/money';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import Formsy from 'formsy-react';
import {
    FormsyInputWrapper,
    TextInput,
    SelectInput,
    MoneyInput,
    CounterInput
} from 'common/components/inputs';
import { CURRENCIES_IDS } from 'common/constants/enums.constant';
import { DELETE_ICON } from 'common/constants/icon.constant';

export default class EditProductForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.objectToFields(props.financing),
            canSubmit: true,
            errorFields: {
                items: { errorClass: null, dangerLabel: false },
                deliveryCost: { errorClass: null, dangerLabel: false }
            }
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    objectToFields = (data) => {
        const financing = _.cloneDeep(data);
        let subTotal = _.sumBy(financing.order.items.slice(), (item) => {
            return item.count * item.price;
        });
        let taxes = (subTotal + financing.order.deliveryCost) * parseFloat(process.env.MIX_TAXES);
        let total = subTotal + taxes + financing.order.deliveryCost;

        let fields = {
            name: financing.name,
            lastname: financing.lastname ? financing.lastname : '',
            advancePayment: financing.advancePayment ? financing.advancePayment : '',
            currencyId: financing.currency ? financing.currency.id : null,
            cart: {
                items: financing.order.items.slice(),
                currencyId: financing.order.currency.id,
                storeId: financing.order.store.id,
                deliveryCost: financing.order.deliveryCost,
                total: total
            },
            externalOrderId:financing.order.externalId,
            identificationType: financing.identificationType.id,
            identification: financing.identification,
            identificationFile: financing.identificationFile,
            identificationFile2: financing.identificationFile2,
            email: financing.email,
            phone: financing.phone,
            street: financing.order.transactions[0].address.street,
            city: financing.order.transactions[0].address.city,
            province: financing.order.transactions[0].address.province.id,
            country: financing.order.transactions[0].address.country.id,
            livingType: financing.livingType.id,
            livingTime: financing.livingTime.id,
            financingEntity: financing.financingPlan.financingEntity.id,
            plan: financing.financingPlan,

            isMarried: financing.isMarried,
            spouseName: financing.spouseName,
            spouseIdentificationType: financing.spouseIdentificationType ? financing.spouseIdentificationType.id : null,
            spouseIdentification: financing.spouseIdentification,
            spousePhone: financing.spousePhone,
            relativeName: financing.relativeName,
            relativePhone: financing.relativePhone,

            companyName: financing.companyName,
            companyType: financing.companyType.id,
            companyStreet: financing.companyStreet,
            companyPhone: financing.companyPhone,
            companyTitle: financing.companyTitle,
            salary: financing.salary,
            otherIncomes: financing.otherIncomes,
            workingTime: financing.workingTime.id
        };

        return fields;
    };

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let data = {...this.state.data};
        data['cart'][field] = value;
        this.setState({data: data});
    };

    onItemChange = (index, field, value) => {
        let items = this.state.data.cart.items.slice();

        let item = items[index];

        item[field] = value;

        items[index] = item;

        this.onFieldChange('items', items);
    };

    addItem = () => {
        let items = this.state.data.cart.items.slice();

        items.push({
            name: '',
            count: '',
            discount: false,
            price: '',
            description: ''
        });

        this.onFieldChange('items', items);
    };

    removeItem = (index) => {
        let items = this.state.data.cart.items.slice();

        items.splice(index, 1);

        this.onFieldChange('items', items);
    };

    onSubmit = () => {
        /*let laststate ={...this.state.data};
        let subTotal = _.sumBy(laststate.cart.items, (item) => {
            return item.count * (item.price / 100);
        });
        let taxes = subTotal * parseFloat(process.env.MIX_TAXES);
        laststate.cart.total = subTotal + taxes + (this.state.data.cart.deliveryCost / 100)
        this.setState({data: laststate});*/
        this.props.onSubmit({...this.state.data});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    renderItemCard(el, key) {
        return (
            <div key={key} className="row mgB bordered p-1 mgL0 mgR0">
                <div className="col-md-6 row mgB">
                    <FormsyInputWrapper
                        required
                        className="col-md-3 col-6"
                        name="count"
                        validations="isTrue"
                        value={el.count > 0}>
                        <CounterInput
                            label={<span>Producto <span className="color-red">*</span></span>}
                            value={el.count}
                            wrapperStyle={{alignItems: 'end', marginTop: '-2px', margin: 'auto', width: 'fit-content'}}
                            inputStyle={{minHeight: '33px'}}
                            onChange={(val) => this.onItemChange(key, 'count', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-9 col-6 pdL0"
                        name="name"
                        validations="isTrue"
                        value={el.name !== ''}>
                        <TextInput
                            label={''}
                            inputStyle={{marginTop: '23px'}}
                            value={el.name}
                            onChange={(val) => this.onItemChange(key, 'name', val)}
                        />
                    </FormsyInputWrapper>
                </div>
                <div className="col-md-6 row mgB">
                    <FormsyInputWrapper
                        required
                        className="col-md-3 col-6"
                        name="currencyId"
                        validations="isTrue"
                        value={this.state.data.cart.currencyId !== null}>
                        <SelectInput
                            floatingLabel={<span>Monto <span className="color-red">*</span></span>}
                            options={[
                                {value: CURRENCIES_IDS.PESO, label: 'RD$'},
                                {value: CURRENCIES_IDS.DOLLAR, label: 'US$'}
                            ]}
                            value={this.state.data.cart.currencyId}
                            onChange={(val) => this.onFieldChange('currencyId', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-9 col-6 pdL0"
                        name="name"
                        validations="isTrue"
                        value={el.price > 0}>
                        <MoneyInput
                            floatingLabel={''}
                            placeholder=" "
                            inputStyle={{marginTop: '23px'}}
                            value={el.price / 100}
                            onChange={(val) => this.onItemChange(key, 'price', val * 100)}
                        />
                    </FormsyInputWrapper>
                </div>
                <div style={{right: '26px'}} className="pdT2 position-absolute">
                    <span
                        style={{ fontSize: '26px'}}
                        className="cursor-pointer color-blue"
                        onClick={() => this.removeItem(key)}>
                        <i className={DELETE_ICON} />
                    </span>
                </div>
            </div>
        );
    }

    render() {
        let subTotal = _.sumBy(this.state.data.cart.items, (item) => {
            return item.count * (item.price / 100);
        });
        let taxes = (subTotal + this.state.data.cart.deliveryCost / 100 ) * parseFloat(process.env.MIX_TAXES);
        return (
            <React.Fragment>
                <Formsy
                    ref={this.basicDataRef}
                    onValid={this.enableSubmit}
                    onInvalid={this.disableSubmit}>
                    {this.state.data.cart.items.map((el, index) => {
                        return this.renderItemCard(el, index);
                    })}
                    <div className="mgB">
                        <button
                            onClick={this.addItem}
                            className="w-100 btn btn-primary">
                            Agregar producto
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-7"/>
                        <div className="col-md-5 row">
                            <FormsyInputWrapper
                                className="col-12"
                                name="name"
                                value={this.state.data.cart.deliveryCost}>
                                <MoneyInput
                                    floatingLabel={'Envío'}
                                    placeholder={this.state.data.cart.currencyId === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ '}
                                    value={this.state.data.cart.deliveryCost / 100}
                                    onChange={(val) => this.onFieldChange('deliveryCost', val * 100)}
                                />
                            </FormsyInputWrapper>
                        </div>
                    </div>
                    <hr className="w-100"/>
                    <div className="cart row">
                        <div className="col-md-7">
                            <ul className="totals">
                                <li>
                                    <span>Subtotal</span>
                                    <span>{(this.state.data.cart.currencyId === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(subTotal)}</span>
                                </li>
                                <li>
                                    <span>Envío</span>
                                    <span>
                                        {(this.state.data.cart.currencyId === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(this.state.data.cart.deliveryCost / 100)}
                                    </span>
                                </li>
                                <li>
                                    <span>Impuestos</span>
                                    <span>{(this.state.data.cart.currencyId === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(taxes)}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5"/>
                    </div>
                    <hr className="w-100"/>
                    <div className="cart row">
                        <div className="col-md-7">
                            <ul className="totals">
                                <li style={{fontSize: '18px'}}>
                                    <span><strong>Total</strong></span>
                                    <span><strong>{(this.state.data.cart.currencyId === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(subTotal + taxes + (this.state.data.cart.deliveryCost / 100))}</strong></span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5"/>
                    </div>
                </Formsy>
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-user-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.data}>
                        <button
                            id="submit-button"
                            disabled={JSON.stringify(this.objectToFields(this.props.financing).cart) === JSON.stringify(this.state.data.cart)}
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </React.Fragment>
        );
    }
}

EditProductForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    cart: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
