import React from 'react';
import {connect} from 'react-redux';

import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';

import Modal from 'common/components/modal';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import StepperComponent from 'common/components/stepper-component';
import EmptyState from 'common/components/empty-state';

import AditionalDataForm from './aditional-data-form';

const mapStateToProps = (state) => {
    return {
        financingForm: transactionSelectors.getFinancingForm(state),
        financingTemp: transactionSelectors.getTemporaryFinancing(state),
        registerUserDropdowns: userSelectors.getRegisterDropdowns(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRegisterUserDropdowns: () => {
            dispatch(userActions.getRegisterUserDropdowns());
        }
    };
};


class StepThreeFinancingCheckout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            financingData: {},
            tempFinancing: {},
            errorFields: {
                isMarried: { errorClass: null, dangerLabel: false },
                spouseName: { errorClass: null, dangerLabel: false },
                spouseIdentificationType: { errorClass: null, dangerLabel: false },
                spouseIdentification: { errorClass: null, dangerLabel: false },
                spousePhone: { errorClass: null, dangerLabel: false },
                relativeName: { errorClass: null, dangerLabel: false },
                relativePhone: { errorClass: null, dangerLabel: false },
                livingType: { errorClass: null, dangerLabel: false },
                livingTime: { errorClass: null, dangerLabel: false },
                optionalFields: { errorClass: null, dangerLabel: false }
            },
            showCancelModal: false,
            canSubmit: props.financingForm.canSubmitStepThree
        };
    }

    onFieldChange = (field, value) => {
        let financingData = Object.assign({}, this.state.financingData);
        let financingT= Object.assign({}, this.state.tempFinancing);

        if (field === 'optionalFields') {
            for (let index = 0; index < financingData['optionalFields'].fields.length; index++) {
                if ((financingData['optionalFields'].fields[index].name === value.data.name) &&
                (financingData['optionalFields'].fields[index].type === value.data.type) &&
                (financingData['optionalFields'].fields[index].belongto === value.data.belongto)) {
                    financingData['optionalFields'].fields[index].value = value.val;
                }
            }
        } else {
            financingData[field] = value;
        }

        if (field === 'spouseIdentificationType') {
            financingData['spouseIdentification'] = '';
        }

        if (field === 'isMarried') {
            financingData['spouseName'] = '';
            financingData['spouseIdentificationType'] = null;
            financingData['spouseIdentification'] = '';
            financingData['spousePhone'] = '';
        }

        if (financingT.dataProfile[field] !== undefined) {
            financingT.dataProfile[field] = value;
        }

        this.setState({financingData: financingData, tempFinancing: financingT});
    };

    canSubmit = (canSubmit) => {
        this.setState({ canSubmit: canSubmit });
    };

    onNext = () => {
        let financingData = Object.assign({}, this.state.financingData, {canSubmitStepThree: this.state.canSubmit});
        let financingTemp = Object.assign({}, this.state.tempFinancing);
        this.props.onSetFinancingFormData(financingData);
        this.props.onSetFinancingTempData(financingTemp);
        this.props.nextStep({});
    };

    onBack = () => {
        let financingData = Object.assign({}, this.state.financingData, {canSubmitStepThree: this.state.canSubmit});
        this.props.onSetFinancingFormData(financingData);
        this.props.previousStep({});
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.registerUserDropdowns.countries.length === 0) {
            this.props.getRegisterUserDropdowns();
        }
        this.setState({
            financingData: this.props.financingForm,
            tempFinancing: this.props.financingTemp,
            canSubmit: this.props.financingForm.canSubmitStepThree
        });
    }

    renderCancelModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCancelModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showCancelModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Cancelar solicitud</h2>
                    <p className="text-center">¿Está seguro que desea cancelar la solicitud? <strong>Perderá el progreso realizado.</strong></p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => {
                                this.props.onCancelCheckout(this.state.financingData.externalOrderId);
                                if (this.props.from) {
                                    location.replace(this.props.from);
                                }
                                this.props.onResetFinancingFormData();
                            }}
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showCancelModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <StepperComponent
                    steps={['Datos del plan', 'Datos personales', 'Datos adicionales', 'Datos laborales']}
                    current={2}
                />
                <div style={{padding: '10px'}} className="bordered mgT2 white-background">
                    <h2 className="text-center mgT">Completa datos adicionales</h2>
                    <div className="text-center">
                        <button
                            disabled={!this.validSubmit()}
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-transparent btn-link' :'btn btn-clickable-pseudo-disabled btn-transparent'}
                            onClick={this.onNext}
                            style={{border:'none'}}>
                            <p style={{textDecoration:'underline'}}> Saltar este paso {"---->"}</p>
                        </button>
                    </div>
                    <p className="text-center">Los campos requeridos están marcados con <span className="color-red">*</span> </p>
                    {Object.entries(this.state.financingData).length !== 0 &&
                        <AditionalDataForm
                            data={this.state.financingData}
                            dropdowns={this.props.registerUserDropdowns}
                            onFieldChange={this.onFieldChange}
                            canSubmit={this.canSubmit}
                            errorFields={this.state.errorFields}
                        />
                    }
                </div>
                <div className="row">
                    <div className="col-md-3 mgT">
                        <button
                            onClick={() => this.setState({showCancelModal: true})}
                            className="btn btn-outline w-100">
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-3 mgT" />
                    <div className="col-md-3 mgT">
                        <button
                            onClick={this.onBack}
                            className="btn w-100">
                            Volver
                        </button>
                    </div>
                    <div className="col-md-3 mgT">
                        <RequiredFieldsWrapper
                            onSubmit={this.onNext}
                            validSubmit={this.validSubmit}
                            onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                            errorFields={this.state.errorFields}
                            fields={this.state.financingData}>
                            <button
                                id="submit-button"
                                className={this.validSubmit() ? 'btn btn-primary w-100' :'btn btn-clickable-pseudo-disabled w-100'}>
                                Continuar
                            </button>
                        </RequiredFieldsWrapper>

                    </div>
                </div>
                {this.renderCancelModal()}
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StepThreeFinancingCheckout);
