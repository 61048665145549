/* Financing Entity related state changes goes here */

import PropTypes from 'prop-types';
import _ from 'lodash';

import * as financingEntityActionsLabels from 'common/store/actions/financing-entity/financing-entity.enum';

import merge from 'common/helpers/merge-objects';

import { GENERIC_ERROR } from 'common/constants/errors.constant';
import {
    DEFAULT_PAGINATION,
    DEFAULT_FINANCING_ENTITIES_FILTERS
} from 'common/constants/objects.constant';

export const financingEntityInitialState = Object.seal({
    loading: false,
    error: null,
    simpleError: null,
    pagination: DEFAULT_PAGINATION,
    financingEntityCreated: null,
    financingEntityUpdated: false,
    financingEntityDeleted: false,
    plansAdded: false,
    plansUpdated: false,
    plansDeleted: false,
    financingEntities: [],
    selectedPlans: [],
    financingEntitiesList: [],
    entitiesFilters: DEFAULT_FINANCING_ENTITIES_FILTERS,
    singleFinancingEntity: null,
    branchOP: {
        branchAdded: false,
        branchUpdated: false,
        branchDeleted: false
    },
    selectedBranches: [],
    listProvinces: [],
    listMunicipality: [],


    // Properties validation.
    propTypes: {
        loading: PropTypes.bool,
        error: PropTypes.string,
        simpleError: PropTypes.string,
        financingEntityCreated: PropTypes.object,
        financingEntityUpdated: PropTypes.bool,
        financingEntityDeleted: PropTypes.bool,
        financingEntities: PropTypes.array,
        selectedPlans: PropTypes.array,
        singleFinancingEntity: PropTypes.object
    }
});

export const financingEntityReducer = (state = financingEntityInitialState, action) => {
    let newState = state;
    switch (action.type) {
    case financingEntityActionsLabels.RESET_FINANCING_ENTITY_MESSAGES: {
        const newStateObject = Object.assign({}, {
            financingEntityUpdated: false,
            financingEntityCreated: null,
            financingEntityDeleted: false,
            plansAdded: false,
            plansUpdated: false,
            plansDeleted: false,
            branchOP: {
                branchAdded: false,
                branchUpdated: false,
                branchDeleted: false
            }
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_FAILURE: {
        const newStateObject = Object.assign({}, { financingEntityUpdated: false, simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ADD_PLANS: {
        const newStateObject = Object.assign({}, { simpleError: null, plansAdded: false });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ADD_PLANS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancingEntity: action.payload, plansAdded: true });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ADD_PLANS_FAILURE: {
        const newStateObject = Object.assign({}, { simpleError: action.payload ? action.payload.error : GENERIC_ERROR, plansAdded: false});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_ENABLE_PLANS: {
        const newStateObject = Object.assign({}, { simpleError: null, plansUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_ENABLE_PLANS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancingEntity: action.payload, plansUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_ENABLE_PLANS_FAILURE: {
        const newStateObject = Object.assign({}, { simpleError: action.payload ? action.payload.error : GENERIC_ERROR, plansUpdated: false});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_DELETE_PLANS: {
        const newStateObject = Object.assign({}, { simpleError: null, plansDeleted: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_DELETE_PLANS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancingEntity: action.payload, plansDeleted: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.BULK_DELETE_PLANS_FAILURE: {
        const newStateObject = Object.assign({}, { simpleError: action.payload ? action.payload.error : GENERIC_ERROR, plansDeleted: false});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.COPY_PLANS: {
        const newStateObject = Object.assign({}, { simpleError: null, plansAdded: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.COPY_PLANS_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancingEntity: action.payload, plansAdded: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.COPY_PLANS_FAILURE: {
        const newStateObject = Object.assign({}, { simpleError: action.payload ? action.payload.error : GENERIC_ERROR, plansAdded: false});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.UPDATE_PLAN: {
        const newStateObject = Object.assign({}, { simpleError: null });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.UPDATE_PLAN_SUCCESS: {
        let singleFinancingEntity = {...state.singleFinancingEntity};
        let plans = singleFinancingEntity.plans.map(plan => {
            if (action.payload.id === plan.id) {
                return action.payload;
            }
            return plan;
        });

        singleFinancingEntity['plans'] = plans;
        const newStateObject = Object.assign({}, { simpleError: null, singleFinancingEntity: singleFinancingEntity });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_PROVINCES_CITIES_FAILURE:
    case financingEntityActionsLabels.UPDATE_PLAN_FAILURE: {
        const newStateObject = Object.assign({}, { simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.SET_ENTITIES_FILTERS: {
        const newStateObject = Object.assign({}, { entitiesFilters: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityDeleted: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityDeleted: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY_FAILURE: {
        const newStateObject = Object.assign({}, { financingEntityDeleted: false, simpleError: action.payload ? action.payload.error : GENERIC_ERROR});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, { error: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.GET_FINANCING_ENTITY_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            singleFinancingEntity: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITY_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST: {
        const newStateObject = Object.assign({}, { error: null, loading: true, pagination: DEFAULT_PAGINATION});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            financingEntitiesList: action.payload.data ? action.payload.data : [],
            pagination: action.payload.pagination ? action.payload.pagination : DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error,
            pagination: DEFAULT_PAGINATION
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES: {
        const newStateObject = Object.assign({}, { error: null, loading: true});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            loading: false,
            financingEntities: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_FINANCING_ENTITIES_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, {
            loading: false,
            error: error
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityCreated: null });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityCreated: action.payload });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { financingEntityCreated: null, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.CLEAR_FINANCING_ENTITY_PROFILE: {
        const newStateObject = Object.assign({}, {singleFinancingEntity: null});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityUpdated: false });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_SUCCESS: {
        const newStateObject = Object.assign({}, { simpleError: null, financingEntityUpdated: true });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { financingEntityUpdated: false, simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.SELECT_PLANS: {
        let selectedPlans = state.selectedPlans.slice();
        if (action.payload.value) {
            selectedPlans = _.union(selectedPlans, action.payload.ids);
        } else {
            selectedPlans = _.difference(selectedPlans, action.payload.ids);
        }
        const newStateObject = Object.assign({}, {selectedPlans: selectedPlans});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.SELECT_BRANCHE: {
        let selectedBranches = state.selectedBranches.slice();
        if (action.payload.value) {
            selectedBranches = _.union(selectedBranches, action.payload.ids);
        } else {
            selectedBranches = _.difference(selectedBranches, action.payload.ids);
        }
        const newStateObject = Object.assign({}, {selectedBranches: selectedBranches});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.GET_PROVINCES_CITIES:{
        const newStateObject = Object.assign({}, { simpleError: null});
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.GET_PROVINCES_CITIES_SUCCESS:{
        const newStateObject = Object.assign({}, {
            simpleError: null,
            listProvinces: action.payload.provinces,
            listMunicipality: action.payload.municipalities
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ADD_BRANCHE:{
        const newStateObject = Object.assign({}, {
            simpleError: null,
            branchOP: {branchAdded: false}
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ADD_BRANCHE_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: action.payload,
            branchOP: {branchAdded: true}
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ADD_BRANCHE_FAILURE:
    case financingEntityActionsLabels.DELETE_BRANCHES_FAILURE:
    case financingEntityActionsLabels.UPDATE_BRANCHE_FAILURE: {
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error, selectedBranches: []});
        newState = merge(state, newStateObject);
        break;
    }


    case financingEntityActionsLabels.DELETE_BRANCHES:{
        const newStateObject = Object.assign({}, {
            simpleError: null,
            branchOP: {branchDeleted: false}
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.DELETE_BRANCHES_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: action.payload,
            branchOP: {branchDeleted: true},
            selectedBranches: []
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.UPDATE_BRANCHE:{
        const newStateObject = Object.assign({}, {
            simpleError: null,
            branchOP: {branchUpdated: false}
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.UPDATE_BRANCHE_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: action.payload,
            branchOP: {branchUpdated: true},
            selectedBranches: []
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ENABLED_BINES:
    case financingEntityActionsLabels.DELETE_BINES:
    case financingEntityActionsLabels.ADD_BIN:{
        const newStateObject = Object.assign({}, { simpleError: null });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ENABLED_BINES_SUCCESS:
    case financingEntityActionsLabels.DELETE_BINES_SUCCESS:
    case financingEntityActionsLabels.ADD_BIN_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.ENABLED_BINES_FAILURE:
    case financingEntityActionsLabels.DELETE_BINES_FAILURE:
    case financingEntityActionsLabels.ADD_BIN_FAILURE:
    case financingEntityActionsLabels.PLANS_TO_BIN_FAILURE:{
        let error = GENERIC_ERROR;
        if (action.payload.error) {
            error = action.payload.error;
        } else {
            error = action.payload;
        }
        const newStateObject = Object.assign({}, { simpleError: error});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.PLANS_TO_BIN: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: null
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.PLANS_TO_BIN_SUCCESS: {
        const newStateObject = Object.assign({}, {
            error: null,
            singleFinancingEntity: action.payload
        });
        newState = merge(state, newStateObject);
        break;
    }

    default:
        break;
    }
    return newState;
};
