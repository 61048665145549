import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component responsible of displaying a custom page when the given one
 * could not be found.
 */
export default class NotFoundContainer extends React.Component {

    /**
     * Initializes the component.
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="in-construction">
                <h1 className="text--align-center">This page was not found.</h1>
            </div>
        );
    }
}

/**
 * NotFound properties validation.
 */
NotFoundContainer.propTypes = { name: PropTypes.string };
