import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {es} from 'react-date-range/dist/locale/index';
import * as _ from 'lodash';

import { DateRange } from 'react-date-range';
import {CustomMaskedInput} from 'common/components/inputs';
import ErrorCatcher from 'common/components/error-catcher';
import { CALENDAR_ICON } from 'common/constants/icon.constant';

/**
 * Represents a date range picker component.
 */
export default class DateRangePicker extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props} the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = {
            labelFocused: false,
            show: false,
            value: {
                startDate: moment(props.from).format('DD/MM/YYYY'),
                endDate: moment(props.to).format('DD/MM/YYYY')
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.changeFocus.bind(this, true);
        this.onBlur = this.onBlur.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {

        Array.from(document.getElementsByClassName('rdrWeekDay')).forEach(element => {
            element.innerHTML = _.capitalize(element.innerHTML)[0];
        });

        Array.from(document.getElementsByClassName('rdrMonthPicker')[0].firstChild.childNodes).forEach(element => {
            element.innerHTML = _.capitalize(element.innerHTML);
        });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    onClick() {
        this.setState({show: !this.state.show});
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    leftIconClicked() {
        let newValue = moment(this.props.value);
        newValue = newValue.subtract(1, this.props.viewMode);
        if (newValue.year() === moment().year()) {
            this.onChange(newValue);
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({show: false});
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    rightIconClicked() {
        let newValue = moment(this.props.value);
        newValue = newValue.add(1, this.props.viewMode);
        if (newValue.year() === moment().year()) {
            this.onChange(newValue);
        }
    }

    onBlur(value) {
        this.onChange(value);
        this.changeFocus(false);
    }

    /**
     * Fired once the input focus changes.
     *
     * @param {bool} labelFocused determines if the element is focused or not.
     */
    changeFocus(labelFocused) {
        this.setState({labelFocused});
    }

    /**
     * Fired once the date changes.
     *
     * @param {Moment | string} value  contains the date time value.
     */
    onChange(ranges) {
        if (typeof ranges !== 'string') {
            let value = ranges.selection;
            if (this.props.onChange) {
                value.startDate = moment(value.startDate).format('YYYY-MM-DD');
                value.endDate = moment(value.endDate).format('YYYY-MM-DD');
                this.props.onChange(value);
            }
        } else {
            if (this.props.onChange) {
                let value = {
                    startDate: '',
                    endDate: ''
                };

                if (ranges !== '') {
                    value = {
                        startDate: ranges.split(' - ')[0].replace(/_/g, ''),
                        endDate: ranges.split(' - ')[1].replace(/_/g, '')
                    };
                }

                this.setState({value: value});
                let endDateArray = value.endDate.split('/');
                let startDateArray = value.startDate.split('/');

                let startDateValid = startDateArray[0] !== '' && startDateArray[1] !== '' && startDateArray[2].length === 4;
                let endDateValid = endDateArray[0] !== '' && endDateArray[1] !== '' && endDateArray[2].length === 4;

                if (moment(value.startDate, 'DD/MM/YYYY').isValid() && moment(value.endDate, 'DD/MM/YYYY').isValid() && startDateValid && endDateValid) {
                    let formatedValue = {
                        startDate: moment(value.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        endDate: moment(value.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                    };
                    this.props.onChange(formatedValue);
                } else {
                    this.props.onChange(value);
                }
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        let endDateArray = nextProps.to.split('-');
        let startDateArray = nextProps.from.split('-');
        let startDateValid = startDateArray[2] !== '' && startDateArray[1] !== '' && startDateArray[0].length === 4;
        let endDateValid = endDateArray[2] !== '' && endDateArray[1] !== '' && endDateArray[0].length === 4;

        if (endDateArray.length === 1) {
            endDateArray = endDateArray[0].split('/');
            endDateValid = endDateArray[0] !== '' && endDateArray[1] !== '' && endDateArray[2].length === 4;
        }

        if (startDateArray.length === 1) {
            startDateArray = startDateArray[0].split('/');
            startDateValid = startDateArray[0] !== '' && startDateArray[1] !== '' && startDateArray[2].length === 4;
        }
        if (moment(nextProps.from).isValid() && moment(nextProps.to).isValid() && startDateValid && endDateValid) {
            let value = {
                startDate: moment(nextProps.from).format('DD/MM/YYYY'),
                endDate: moment(nextProps.to).format('DD/MM/YYYY')
            };
            return {value: value};
        }

        return null;
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        let selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        };

        if (moment(this.props.from).isValid() && moment(this.props.to).isValid() && !moment(this.props.to).isBefore(moment(this.props.from))) {
            selectionRange = {
                startDate: new Date(moment(this.props.from).format('MM/DD/YYYY')),
                endDate: new Date(moment(this.props.to).format('MM/DD/YYYY')),
                key: 'selection'
            };
        }
        const formatedDate = this.state.value.startDate + ' - ' + this.state.value.endDate;
        return (
            <label ref={this.setWrapperRef} className="form-label">
                <span>
                    <CustomMaskedInput
                        id={this.props.id}
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        mask="11/11/1111 - 11/11/1111"
                        value={formatedDate}
                        onFocus={this.onClick}
                        onChange={this.onChange}
                    />
                    <span
                        style={{top: this.props.label ? '27px' : '0px'}}
                        onClick={(e) => e.stopPropagation()}
                        className="calendar--icon">
                        <i className={ CALENDAR_ICON } />
                    </span>
                </span>
                <ErrorCatcher
                    auxComponent={
                        <DateRange
                            locale={es}
                            className={`form-control dateRange ${!this.state.show ? 'dateRange--none' : ''}`}
                            ranges = {
                                [
                                    {
                                        startDate: null,
                                        endDate: null,
                                        key: 'selection'
                                    }
                                ]
                            }
                            onChange={this.onChange}
                        />
                    }>
                    <DateRange
                        locale={es}
                        className={`form-control dateRange ${!this.state.show ? 'dateRange--none' : ''}`}
                        ranges={[selectionRange]}
                        onChange={this.onChange}
                    />
                </ErrorCatcher>
            </label>
        );
    }
}


/**
 * Datetime picker default props.
 */
DateRangePicker.defaultProps = {
    positionClass: '',
    className: '',
    placeholder: '',
    floating: false,
    value: '',
    withIcon: false,
    viewMode: 'days',
    format: 'DD/MM/YYYY',
    alternativeView: false
};

/**
 * Select properties validation.
 */

DateRangePicker.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    alternativeView: PropTypes.bool,
    floating: PropTypes.bool,
    from: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    withIcon: PropTypes.bool,
    maxDate: PropTypes.instanceOf(moment),
    minDate: PropTypes.instanceOf(moment),
    onChange: PropTypes.func,
    positionClass: PropTypes.string
};
