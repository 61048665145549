'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DataTable from "common/components/datatable";
import {Checkbox} from 'common/components/inputs';


/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class Branches extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {sucursalselected: true};
    }





    renderHeader(name) {
        let length = _.intersection(this.props.branches.map(bran => bran.id), this.props.selectedBranches).length;
        if (name === 'checkbox') {
            return (
                <span className="d-flex dt__header-head breakLine">
                    <span className="dt__header-label">
                        <div style={{marginTop: '15px', width:'20px'}} onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                squared={true}
                                checked={(length === this.props.branches.length) && (length > 0)}
                                value={'all'}
                                indeterminate={length < this.props.branches.length && length > 0}
                                onChange={(val, e) => this.props.onBranchesSelected(val, this.props.branches.map(bran => bran.id))}
                            />
                        </div>
                    </span>
                </span>
            );
        }

        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    render() {
        let data = _.slice(this.props.branches, this.props.page[0], this.props.page[1]);
        let columnsMobile = [
            {
                Header: this.renderHeader('checkbox'),
                id: 'checkbox',
                accessor: '',
                Cell: ({original}) => {
                    return (
                        <div style={{marginTop: '7px'}} onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                squared={true}
                                checked={(this.props.selectedBranches.indexOf(original.id) > -1)}
                                value={original.id}
                                onChange={(val, e) => this.props.onBranchesSelected(val, [parseInt(e.currentTarget.value)])}
                            />
                        </div>
                    );
                },
                width: 20
            },
            {
                Header: this.renderHeader('Nombre'),
                Cell: ({original}) => {
                    return (
                        <div style={{width:'340px'}}>
                            <p style={{marginTop:'2px', marginBottom:'2px', fontWeight:'bold'}}>{original['branch_name']}</p>
                            <div style={{marginBottom:'2px', textAlign:'justify'}} className="paragraph">{(original['address']) ? original['address'] +', ' + original['municipality_id'][0].name : 'Sin Dirección'}</div>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}>{((original['phone']) ? '('+original['phone'].substr(0, 3)+') ' + original['phone'].substr(3, 3) +'-' +original['phone'].substr(6, 4) + ' / '+ ((original['extension'] !== null) ? original['extension'] : '---') : '--- / ---') + ', ' + original['contact_phone'] ? original['contact_phone'] : '---'} </p>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>Ejecutivo: </span>{original['contact_name']}</p>
                            { original['contact_email'] &&
                            <a style={{marginTop:'2px', marginBottom:'2px'}} href={original['contact_email']}>{original['contact_email']}</a> }
                        </div>
                    );
                },
                width: '300'
            }
        ];
        let columnsNoMobile =[
            {
                Header: this.renderHeader('checkbox'),
                id: 'checkbox',
                accessor: '',
                Cell: ({original}) => {
                    return (
                        <div style={{marginTop: '7px'}} onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                squared={true}
                                checked={(this.props.selectedBranches.indexOf(original.id) > -1)}
                                value={original.id}
                                onChange={(val, e) => this.props.onBranchesSelected(val, [parseInt(e.currentTarget.value)])}
                            />
                        </div>
                    );
                },
                width: 50
            },
            {
                Header: this.renderHeader('Nombre'),
                accessor: 'branch_name',
                width: '150'
            },
            {
                Header: this.renderHeader('Ubicación'),
                accessor: '',
                Cell: ({original}) => {
                    return (
                        <div >
                            <div style={{marginBottom:'2px', textAlign:'justify'}} className="paragraph"><span style={{fontWeight:'bold'}}>Dirección: </span>{(original['address']) ? original['address'] : 'Sin Dirección'}</div>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>Municipio: </span>{original['municipality_id'][0].name}</p>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>Provincia: </span>{original['municipality_id'][0].province.name}</p>
                        </div>
                    );
                },
                width: '400'
            },
            {
                Header: this.renderHeader('Teléfono / Extension'),
                accessor: '',
                Cell: ({original}) => {
                    return (original['phone']) ? '('+original['phone'].substr(0, 3)+') ' + original['phone'].substr(3, 3) +'-' +original['phone'].substr(6, 4) + ' / '+ ((original['extension'] !== null) ? original['extension'] : '---') : '--- / ---';
                },
                width: '250'

            }/* ,
            {
                Header: this.renderHeader('Extensión'),
                accessor: '',
                Cell: ({original}) => {
                    return (original['extension'] !== null) ?  original['extension'] : '---';
                },
                width: '150',

            } ,
            {
                Header: this.renderHeader('Municipio / Provincia'),
                accessor: '',
                Cell: ({original}) => {
                    return  original['municipality_id'][0].name + ' / ' + original['municipality_id'][0].province.name;
                },
                width: '350',
            }*/,
            {
                Header: this.renderHeader('Persona a Contactar'),
                accessor: '',
                Cell: ({original}) => {
                    return (
                        <div>
                            <p style={{marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>Nombre: </span>{original['contact_name']}</p>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>Tel: </span>{original['contact_phone'] ? original['contact_phone'] : '---'}</p>
                            <p style={{marginTop:'2px', marginBottom:'2px'}}><span style={{fontWeight:'bold'}}>E-mail: </span>{original['contact_email'] ? original['contact_email'] : '---'}</p>
                        </div>
                    );
                },
                width: '350'
            }
        ];
        const columns = (this.props.isMobile) ? columnsMobile : columnsNoMobile;
        return (
            <div className="row ">
                {this.props.isMobile && <div className="row" style={{marginTop:'110px', marginLeft:'100px'}}><div className="align-center"> <span className="h2">Sucursales</span> </div></div>}
                {!this.props.isMobile && <div className="align-center"> <span className="h2">Sucursales</span> </div>}
                <div className="col-12">
                    <DataTable
                        manual={true}
                        data={data}
                        columns={columns}
                        className="dt-plans"
                        minRows={0}
                        getTrProps={this.matchFeaturedRow}
                        getTdProps={this.matchFeaturedCell}
                    />
                </div>
            </div>
        );
    }
}

Branches.defaultProps ={isMobile: false};

Branches.propTypes = {
    onClickNew: PropTypes.func,
    branches: PropTypes.array.isRequired,
    selectedBranches: PropTypes.array,
    page: PropTypes.array,
    isMobile: PropTypes.bool
};