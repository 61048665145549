
import * as financingEntityActionsLabels from './financing-entity.enum';

export const resetFinancingEntityMessages = () => ({type: financingEntityActionsLabels.RESET_FINANCING_ENTITY_MESSAGES});

export const createFinancingEntity = (data) => ({
    type: financingEntityActionsLabels.CREATE_FINANCING_ENTITY,
    payload: data
});

export const createFinancingEntitySuccess = (financingEntity) => ({type: financingEntityActionsLabels.CREATE_FINANCING_ENTITY_SUCCESS, payload: financingEntity});

export const createFinancingEntityFailure = (error) => ({
    type: financingEntityActionsLabels.CREATE_FINANCING_ENTITY_FAILURE,
    payload: error
});

export const clearFinancingEntityProfile = () => ({type: financingEntityActionsLabels.CLEAR_FINANCING_ENTITY_PROFILE});

export const updateFinancingEntity = (id, data) => ({
    type: financingEntityActionsLabels.UPDATE_FINANCING_ENTITY,
    payload: {id, data}
});

export const updateFinancingEntitySuccess = (financingEntity) => ({type: financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_SUCCESS, payload: financingEntity});

export const updateFinancingEntityFailure = (error) => ({
    type: financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_FAILURE,
    payload: error
});

export const getFinancingEntity = (id) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITY,
    payload: id
});

export const getFinancingEntitySuccess = (financingEntity) => ({type: financingEntityActionsLabels.GET_FINANCING_ENTITY_SUCCESS, payload: financingEntity});

export const getFinancingEntityFailure = (error) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITY_FAILURE,
    payload: error
});

export const getFinancingEntities = (filters) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITIES,
    payload: filters
});

export const getFinancingEntitiesSuccess = (financingEntities) => ({type: financingEntityActionsLabels.GET_FINANCING_ENTITIES_SUCCESS, payload: financingEntities});

export const getFinancingEntitiesFailure = (error) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITIES_FAILURE,
    payload: error
});

export const deleteFinancingEntity = (id) => ({
    type: financingEntityActionsLabels.DELETE_FINANCING_ENTITY,
    payload: id
});

export const deleteFinancingEntitySuccess = () => ({type: financingEntityActionsLabels.DELETE_FINANCING_ENTITY_SUCCESS});

export const deleteFinancingEntityFailure = (error) => ({
    type: financingEntityActionsLabels.DELETE_FINANCING_ENTITY_FAILURE,
    payload: error
});

export const cancelGetFinancingEntities = () => ({type: financingEntityActionsLabels.CANCEL_GET_FINANCING_ENTITIES});

export const getFinancingEntitiesList = (filters) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST,
    payload: filters
});

export const getFinancingEntitiesListSuccess = (financingEntities) => ({type: financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST_SUCCESS, payload: financingEntities});

export const getFinancingEntitiesListFailure = (error) => ({
    type: financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST_FAILURE,
    payload: error
});

export const setEntitiesFilters = (filters) => ({
    type: financingEntityActionsLabels.SET_ENTITIES_FILTERS,
    payload: filters
});

export const activateFinancingEntity = (id, value) => ({
    type: financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY,
    payload: {id: id, enabled: value}
});

export const activateFinancingEntitySuccess = () => ({type: financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_SUCCESS});

export const activateFinancingEntityFailure = (error) => ({
    type: financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_FAILURE,
    payload: error
});

export const updatePlan = (id, data) => ({
    type: financingEntityActionsLabels.UPDATE_PLAN,
    payload: {id, data}
});

export const updatePlanSuccess = (financingPlan) => ({type: financingEntityActionsLabels.UPDATE_PLAN_SUCCESS, payload: financingPlan});

export const updatePlanFailure = (error) => ({
    type: financingEntityActionsLabels.UPDATE_PLAN_FAILURE,
    payload: error
});

export const addPlans = (id, data) => ({
    type: financingEntityActionsLabels.ADD_PLANS,
    payload: {id, data}
});

export const addPlansSuccess = (financingEntity) => ({type: financingEntityActionsLabels.ADD_PLANS_SUCCESS, payload: financingEntity});

export const addPlansFailure = (error) => ({
    type: financingEntityActionsLabels.ADD_PLANS_FAILURE,
    payload: error
});

export const copyPlans = (id, ids) => ({
    type: financingEntityActionsLabels.COPY_PLANS,
    payload: {id, ids}
});

export const copyPlansSuccess = (financingEntity) => ({type: financingEntityActionsLabels.COPY_PLANS_SUCCESS, payload: financingEntity});

export const copyPlansFailure = (error) => ({
    type: financingEntityActionsLabels.COPY_PLANS_FAILURE,
    payload: error
});

export const selectPlans = (value, ids) => ({
    type: financingEntityActionsLabels.SELECT_PLANS,
    payload: {
        value: value,
        ids: ids
    }
});
export const selectBranches = (value, ids) => ({
    type: financingEntityActionsLabels.SELECT_BRANCHE,
    payload: {
        value: value,
        ids: ids
    }
});

export const bulkEnablePlans = (id, ids, value) => ({
    type: financingEntityActionsLabels.BULK_ENABLE_PLANS,
    payload: {id, ids, value}
});

export const bulkEnablePlansSuccess = (financingEntity) => ({
    type: financingEntityActionsLabels.BULK_ENABLE_PLANS_SUCCESS,
    payload: financingEntity
});

export const bulkEnablePlansFailure = (error) => ({
    type: financingEntityActionsLabels.BULK_ENABLE_PLANS_FAILURE,
    payload: error
});

export const bulkDeletePlans = (id, ids) => ({
    type: financingEntityActionsLabels.BULK_DELETE_PLANS,
    payload: {id, ids}
});

export const bulkDeletePlansSuccess = (financingEntity) => ({
    type: financingEntityActionsLabels.BULK_DELETE_PLANS_SUCCESS,
    payload: financingEntity
});

export const bulkDeletePlansFailure = (error) => ({
    type: financingEntityActionsLabels.BULK_DELETE_PLANS_FAILURE,
    payload: error
});

export const getProvinceCities = () => ({
    type: financingEntityActionsLabels.GET_PROVINCES_CITIES
});
export const getProvinceCitiesSuccess = (listProvinces) => ({
    type: financingEntityActionsLabels.GET_PROVINCES_CITIES_SUCCESS,
    payload: listProvinces
});
export const getProvinceCitiesFailure = (error) => ({
    type: financingEntityActionsLabels.GET_PROVINCES_CITIES_FAILURE,
    payload: error
});

export const createBranch = (params, id) => ({
    type: financingEntityActionsLabels.ADD_BRANCHE,
    payload: {data: params, id_entity: id}
});

export const createBrancheSuccess = (result) => ({
    type: financingEntityActionsLabels.ADD_BRANCHE_SUCCESS,
    payload: result
});

export const createBrancheFailure = (error) => ({
    type: financingEntityActionsLabels.ADD_BRANCHE_FAILURE,
    payload: error
});

export const deleteBranches = (Ids, id) => ({
    type: financingEntityActionsLabels.DELETE_BRANCHES,
    payload: {Ids: Ids, id_entity: id}
});

export const deleteBranchesSuccess = (result) => ({
    type: financingEntityActionsLabels.DELETE_BRANCHES_SUCCESS,
    payload: result
});

export const deleteBranchesFailure = (error) => ({
    type: financingEntityActionsLabels.DELETE_BRANCHES_FAILURE,
    payload: error
});


export const updateBranch = (params, id) => ({
    type: financingEntityActionsLabels.UPDATE_BRANCHE,
    payload: {data: params, id_entity: id}
});

export const updateBranchSuccess = (result) => ({
    type: financingEntityActionsLabels.UPDATE_BRANCHE_SUCCESS,
    payload: result
});

export const updateBranchFailure = (error) => ({
    type: financingEntityActionsLabels.UPDATE_BRANCHE_FAILURE,
    payload: error
});


export const appendBIN = (params, id) => ({
    type: financingEntityActionsLabels.ADD_BIN,
    payload: {data: params, idEntity: id}
});

export const appendBINSuccess = (result) => ({
    type: financingEntityActionsLabels.ADD_BIN_SUCCESS,
    payload: result
});

export const appendBINFailure = (error) => ({
    type: financingEntityActionsLabels.ADD_BIN_FAILURE,
    payload: error
});

export const enabledBINES = (params, id) => ({
    type: financingEntityActionsLabels.ENABLED_BINES,
    payload: {data: params, idEntity: id}
});

export const enabledBINESSuccess = (result) => ({
    type: financingEntityActionsLabels.ENABLED_BINES_SUCCESS,
    payload: result
});

export const enabledBINESFailure = (error) => ({
    type: financingEntityActionsLabels.ENABLED_BINES_FAILURE,
    payload: error
});

export const deleteBINES = (params, id) => ({
    type: financingEntityActionsLabels.DELETE_BINES,
    payload: {data: params, idEntity: id}
});

export const deleteBINESSuccess = (result) => ({
    type: financingEntityActionsLabels.DELETE_BINES_SUCCESS,
    payload: result
});

export const deleteBINESFailure = (error) => ({
    type: financingEntityActionsLabels.DELETE_BINES_FAILURE,
    payload: error
});

export const plansToBin= (params, id) => ({
    type: financingEntityActionsLabels.PLANS_TO_BIN,
    payload: {data: params, idEntity: id}
});

export const plansToBinSuccess = (result) => ({
    type: financingEntityActionsLabels.PLANS_TO_BIN_SUCCESS,
    payload: result
});

export const plansToBinFailure = (error) => ({
    type: financingEntityActionsLabels.PLANS_TO_BIN_FAILURE,
    payload: error
});
