/**
 * Safely merge the two given objects.
 *
 * @param  object  obj1   the original object.
 * @param  object  obj2   the object that will be merged.
 *
 * @return object  obj3   A new object, which contains obj1's properties,
 *                        overriden by obj2's properties.
 *
 * @throws Error if the obj2 has properties that aren't in obj1.
 */
export default function (obj1, obj2) {

    // Creates a new, freezed object from object 1.
    let newObject = Object.seal(Object.assign({}, obj1));

    // Return the new object with obj2's overriding obj1's properties.
    // This will thrown an error if a property that doesn't exists was added.

    return Object.assign(newObject, obj2);
}
