import {addValidationRule} from 'formsy-react';

import * as Regex from 'common/constants/regex.constant';

/**
 * Adds all the formsy rules that we'll need.
 */
export default function addFormsyRules() {
    addValidationRule('isValidTime', (values, value) => {
        return Regex.isValidTime.test(value);
    });

    addValidationRule('isValidDate', (values, value) => {
        return Regex.isValidDate.test(value);
    });

    addValidationRule('isValidTimeOptional', (values, value) => {
        return Regex.isValidTime.test(value) || value === '';
    });

    addValidationRule('isValidIdentification', (values, value) => {
        return Regex.isValidIdentification.test(value);
    });

    addValidationRule('isValidPhone', (values, value) => {
        return Regex.isValidUSAPhone.test(value);
    });

    addValidationRule('isValidDOMPhone', (values, value) => {
        return Regex.isValidDOMPhone.test(value);
    });

    addValidationRule('isURL', (values, value) => {
        return Regex.isURL.test(value);
    });

    addValidationRule('isValidCard', (values, value) => {
        return Regex.isValidCard.test(value);
    });

    addValidationRule('isValidCVV', (values, value) => {
        return Regex.isValidCVV.test(value);
    });

    addValidationRule('isValidDateCard', (values, value) => {
        return Regex.isValidDateCard.test(value);
    });
}
