/* User-related state changes goes here */

import PropTypes from 'prop-types';

import * as userActionsLabels from 'common/store/actions/user/user.enum';
import * as transactionActionsLabels from 'common/store/actions/transaction/transaction.enum';
import * as financingEntityActionsLabels from 'common/store/actions/financing-entity/financing-entity.enum';
import * as apicardActionsLabel from 'common/store/actions/card/card.enum';

import merge from 'common/helpers/merge-objects';

const initialState = Object.seal({
    loading: false,
    loadingMessage: null,
    hideMenu: false,

    // Properties validation.
    propTypes: {loading: PropTypes.bool}
});

export const genericUIReducer = (state = initialState, action) => {
    let newState = state;
    switch (action.type) {
    case userActionsLabels.AUTH_USER:
    case userActionsLabels.LOGOUT_USER:
    case userActionsLabels.UPDATE_USER:
    case userActionsLabels.VALIDATE_EMAIL:
    case userActionsLabels.ACTIVATE_USER:
    case userActionsLabels.BULK_ACTIVATE_USERS:
    case userActionsLabels.DELETE_USER:
    case userActionsLabels.BULK_DELETE_USERS:
    case userActionsLabels.GET_USERS_DROPDOWNS:
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS:
    case userActionsLabels.FORGOT_PASSWORD:
    case userActionsLabels.USER_FORGOT_PASSWORD:
    case userActionsLabels.SEND_VERIFICATION_EMAIL:
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT:
    case userActionsLabels.ADD_ADDRESS:
    case userActionsLabels.EDIT_FINANCING_PROFILE:
    case userActionsLabels.EDIT_ADDRESS:
    case userActionsLabels.DELETE_ADDRESS:
    case transactionActionsLabels.UPDATE_FINANCING:
    case transactionActionsLabels.UPLOAD_QUOTE:
    case transactionActionsLabels.UPLOAD_DOCUMENT:
    case transactionActionsLabels.REMOVE_DOCUMENT:
    case transactionActionsLabels.CANCEL_FINANCING:
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY:
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY:
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY:
    case financingEntityActionsLabels.UPDATE_PLAN:
    case financingEntityActionsLabels.BULK_ENABLE_PLANS:
    case financingEntityActionsLabels.BULK_DELETE_PLANS:
    case transactionActionsLabels.APPROVE_FINANCING:
    case transactionActionsLabels.ADD_COMMENT:
    case transactionActionsLabels.DECLINE_FINANCING:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS:
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES:
    case userActionsLabels.RESET_PASSWORD: {
        const newStateObject = Object.assign({}, { loading: true, loadingMessage: null });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.REGISTER_USER:{
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Registrando usuario'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.VERIFY_ORDER_TOKEN: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Verificando la orden...'
        });
        newState = merge(state, newStateObject);
        break;
    }

    case financingEntityActionsLabels.ADD_PLANS: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Agregando planes'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP:
    case transactionActionsLabels.CREATE_FINANCING: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Creando Solicitud'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Creando Entidad'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.CREATE_USER: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Creando Usuario'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case userActionsLabels.USER_HIDE_MENU: {
        let status = state.hideMenu;
        const newStateObject = Object.assign({}, {hideMenu: !status});
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.PLANS_TO_BIN: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Asociando planes'
        });
        newState = merge(state, newStateObject);
        break;
    }
    case financingEntityActionsLabels.PLANS_TO_BIN_SUCCESS:
    case financingEntityActionsLabels.PLANS_TO_BIN_FAILURE:
    case userActionsLabels.FORGOT_PASSWORD_FAILURE:
    case userActionsLabels.FORGOT_PASSWORD_SUCCESS:
    case userActionsLabels.USER_FORGOT_PASSWORD_FAILURE:
    case userActionsLabels.USER_FORGOT_PASSWORD_SUCCESS:
    case userActionsLabels.LOGOUT_USER_FAILURE:
    case userActionsLabels.LOGOUT_USER_SUCCESS:
    case userActionsLabels.VALIDATE_EMAIL_FAILURE:
    case userActionsLabels.VALIDATE_EMAIL_SUCCESS:
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT_FAILURE:
    case userActionsLabels.MARK_ADDRESS_AS_DEFAULT_SUCCESS:
    case userActionsLabels.CREATE_USER_FAILURE:
    case userActionsLabels.CREATE_USER_SUCCESS:
    case userActionsLabels.UPDATE_USER_FAILURE:
    case userActionsLabels.UPDATE_USER_SUCCESS:
    case userActionsLabels.REGISTER_USER_FAILURE:
    case userActionsLabels.REGISTER_USER_SUCCESS:
    case userActionsLabels.ACTIVATE_USER_FAILURE:
    case userActionsLabels.ACTIVATE_USER_SUCCESS:
    case userActionsLabels.GET_USERS_DROPDOWNS_FAILURE:
    case userActionsLabels.GET_USERS_DROPDOWNS_SUCCESS:
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS_FAILURE:
    case userActionsLabels.GET_REGISTER_USER_DROPDOWNS_SUCCESS:
    case userActionsLabels.DELETE_USER_FAILURE:
    case userActionsLabels.DELETE_USER_SUCCESS:
    case userActionsLabels.BULK_DELETE_USERS_FAILURE:
    case userActionsLabels.BULK_DELETE_USERS_SUCCESS:
    case userActionsLabels.BULK_ACTIVATE_USERS_FAILURE:
    case userActionsLabels.BULK_ACTIVATE_USERS_SUCCESS:
    case userActionsLabels.RESET_PASSWORD_FAILURE:
    case userActionsLabels.RESET_PASSWORD_SUCCESS:
    case userActionsLabels.SEND_VERIFICATION_EMAIL_FAILURE:
    case userActionsLabels.SEND_VERIFICATION_EMAIL_SUCCESS:
    case userActionsLabels.ADD_ADDRESS_FAILURE:
    case userActionsLabels.ADD_ADDRESS_SUCCESS:
    case userActionsLabels.EDIT_FINANCING_PROFILE_FAILURE:
    case userActionsLabels.EDIT_FINANCING_PROFILE_SUCCESS:
    case userActionsLabels.EDIT_ADDRESS_FAILURE:
    case userActionsLabels.EDIT_ADDRESS_SUCCESS:
    case userActionsLabels.DELETE_ADDRESS_FAILURE:
    case userActionsLabels.DELETE_ADDRESS_SUCCESS:
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY_SUCCESS:
    case financingEntityActionsLabels.CREATE_FINANCING_ENTITY_FAILURE:
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_SUCCESS:
    case financingEntityActionsLabels.UPDATE_FINANCING_ENTITY_FAILURE:
    case financingEntityActionsLabels.UPDATE_PLAN_SUCCESS:
    case financingEntityActionsLabels.UPDATE_PLAN_FAILURE:
    case financingEntityActionsLabels.BULK_ENABLE_PLANS_SUCCESS:
    case financingEntityActionsLabels.BULK_ENABLE_PLANS_FAILURE:
    case financingEntityActionsLabels.BULK_DELETE_PLANS_SUCCESS:
    case financingEntityActionsLabels.BULK_DELETE_PLANS_FAILURE:
    case financingEntityActionsLabels.ADD_PLANS_SUCCESS:
    case financingEntityActionsLabels.ADD_PLANS_FAILURE:
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_SUCCESS:
    case financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY_FAILURE:
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY_SUCCESS:
    case financingEntityActionsLabels.DELETE_FINANCING_ENTITY_FAILURE:
    case transactionActionsLabels.CREATE_FINANCING_FAILURE:
    case transactionActionsLabels.CREATE_FINANCING_SUCCESS:
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_SUCCESS:
    case transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_FAILURE:
    case transactionActionsLabels.CREATE_CARD_FINANCING_SUCCESS:
    case transactionActionsLabels.CREATE_CARD_FINANCING_FAILURE:
    case transactionActionsLabels.ADD_COMMENT_FAILURE:
    case transactionActionsLabels.ADD_COMMENT_SUCCESS:
    case transactionActionsLabels.UPDATE_FINANCING_FAILURE:
    case transactionActionsLabels.UPDATE_FINANCING_SUCCESS:
    case transactionActionsLabels.UPLOAD_QUOTE_FAILURE:
    case transactionActionsLabels.UPLOAD_QUOTE_SUCCESS:
    case transactionActionsLabels.UPLOAD_DOCUMENT_FAILURE:
    case transactionActionsLabels.UPLOAD_DOCUMENT_SUCCESS:
    case transactionActionsLabels.REMOVE_DOCUMENT_FAILURE:
    case transactionActionsLabels.REMOVE_DOCUMENT_SUCCESS:
    case transactionActionsLabels.CANCEL_FINANCING_FAILURE:
    case transactionActionsLabels.CANCEL_FINANCING_SUCCESS:
    case transactionActionsLabels.APPROVE_FINANCING_FAILURE:
    case transactionActionsLabels.APPROVE_FINANCING_SUCCESS:
    case transactionActionsLabels.DECLINE_FINANCING_FAILURE:
    case transactionActionsLabels.DECLINE_FINANCING_SUCCESS:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS_FAILURE:
    case transactionActionsLabels.CHANGE_FINANCING_STATUS_SUCCESS:
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_FAILURE:
    case transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_SUCCESS:
    case apicardActionsLabel.AUTHORIZE_CARD_FAILURE:
    case apicardActionsLabel.CLOSE_TRANSACTION:
    case userActionsLabels.AUTH_USER_FAILURE:
    case userActionsLabels.AUTH_USER_SUCCESS: {
        const newStateObject = Object.assign({}, { loading: false, loadingMessage: null });
        newState = merge(state, newStateObject);
        break;
    }

    case apicardActionsLabel.BEGIN_TRANSACTION: {
        const newStateObject = Object.assign({}, {
            loading: true,
            loadingMessage: 'Validando operación'
        });
        newState = merge(state, newStateObject);
        break;
    }
    default:
        break;
    }
    return newState;
};

//   