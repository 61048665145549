
//USER ENUM ACTIONS

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILURE = 'AUTH_USER_FAILURE';

export const USER_HIDE_MENU = 'USER_HIDE_MENU';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_USER_MESSAGES = 'RESET_USER_MESSAGES';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const CANCEL_GET_USERS = 'CANCEL_GET_USERS';

export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';

export const GET_USERS_DROPDOWNS = 'GET_USERS_DROPDOWNS';
export const GET_USERS_DROPDOWNS_SUCCESS = 'GET_USERS_DROPDOWNS_SUCCESS';
export const GET_USERS_DROPDOWNS_FAILURE = 'GET_USERS_DROPDOWNS_FAILURE';

export const GET_REGISTER_USER_DROPDOWNS = 'GET_REGISTER_USER_DROPDOWNS';
export const GET_REGISTER_USER_DROPDOWNS_SUCCESS = 'GET_REGISTER_USER_DROPDOWNS_SUCCESS';
export const GET_REGISTER_USER_DROPDOWNS_FAILURE = 'GET_REGISTER_USER_DROPDOWNS_FAILURE';

export const SELECT_USERS = 'SELECT_USERS';

export const BULK_DELETE_USERS = 'BULK_DELETE_USERS';
export const BULK_DELETE_USERS_SUCCESS = 'BULK_DELETE_USERS_SUCCESS';
export const BULK_DELETE_USERS_FAILURE = 'BULK_DELETE_USERS_FAILURE';

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const BULK_ACTIVATE_USERS = 'BULK_ACTIVATE_USERS';
export const BULK_ACTIVATE_USERS_SUCCESS = 'BULK_ACTIVATE_USERS_SUCCESS';
export const BULK_ACTIVATE_USERS_FAILURE = 'BULK_ACTIVATE_USERS_FAILURE';

export const GET_USER_ACTIONS = 'GET_USER_ACTIONS';
export const GET_USER_ACTIONS_SUCCESS = 'GET_USER_ACTIONS_SUCCESS';
export const GET_USER_ACTIONS_FAILURE = 'GET_USER_ACTIONS_FAILURE';

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAILURE = 'USER_FORGOT_PASSWORD_FAILURE';

export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const CLEAR_DATA = 'CLEAR_DATA';

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';

export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE';

export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

export const MARK_ADDRESS_AS_DEFAULT = 'MARK_ADDRESS_AS_DEFAULT';
export const MARK_ADDRESS_AS_DEFAULT_SUCCESS = 'MARK_ADDRESS_AS_DEFAULT_SUCCESS';
export const MARK_ADDRESS_AS_DEFAULT_FAILURE = 'MARK_ADDRESS_AS_DEFAULT_FAILURE';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAILURE = 'EDIT_ADDRESS_FAILURE';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

export const EDIT_FINANCING_PROFILE = 'EDIT_FINANCING_PROFILE';
export const EDIT_FINANCING_PROFILE_SUCCESS = 'EDIT_FINANCING_PROFILE_SUCCESS';
export const EDIT_FINANCING_PROFILE_FAILURE = 'EDIT_FINANCING_PROFILE_FAILURE';

export const CREATE_FINANCING_PROFILE = 'CREATE_FINANCING_PROFILE';
export const CREATE_FINANCING_PROFILE_SUCCESS = 'CREATE_FINANCING_PROFILE_SUCCESS';
export const CREATE_FINANCING_PROFILE_FAILURE = 'CREATE_FINANCING_PROFILE_FAILURE';

export const FIND_USER_EMAIL_PASSWORD = 'FIND_USER_EMAIL_PASSWORD';
export const FIND_USER_EMAIL_PASSWORD_SUCCESS = 'FIND_USER_EMAIL_PASSWORD_SUCCESS';
export const FIND_USER_EMAIL_PASSWORD_FAILURE = 'FIND_USER_EMAIL_PASSWORD_FAILURE';

