import 'rxjs';
import 'rxjs/add/observable/of';
import { of } from 'rxjs/observable/of';
import {
    mergeMap,
    catchError,
    map,
    takeUntil
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as promotionsActionsLabel from '../actions/promotions/promotions.enum';
import * as promotionsActions from '../actions/promotions/promotions.actions';
import * as promotionRequest from '../services/promotion.service';

export const getAllPromotionsEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.SEARCH_PROMOTION),
        mergeMap(action =>
            promotionRequest.getPromotionsRequest(action.payload).pipe(
                map((result) => promotionsActions.searchPromotionsSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.searchPromotionsFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.searchPromotionsFailure(error.response)
                    );
                })
            )
        )
    );

export const getPromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.GET_PROMOTION),
        mergeMap(action =>
            promotionRequest.getPromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.getPromotionSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.getPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.getPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const createPromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.CREATE_PROMOTION),
        mergeMap(action =>
            promotionRequest.createPromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.createPromotionSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.createPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.createPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const setEnabledPromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.ACTIVATE_PROMOTION),
        mergeMap(action =>
            promotionRequest.enabledPromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.activatePromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.activatePromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.activatePromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const removePromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.REMOVE_PROMOTION),
        mergeMap(action =>
            promotionRequest.removePromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.removePromotionSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.removePromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.removePromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const updatePromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.EDIT_PROMOTION),
        mergeMap(action =>
            promotionRequest.updatePromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.updatePromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.updatePromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.updatePromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const addCuponPromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.ADD_CUPONS_PROMOTION),
        mergeMap(action =>
            promotionRequest.addCuponRequest(action.payload).pipe(
                map((result) => promotionsActions.addCuponsPromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.addCuponsPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.addCuponsPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const removeCuponPromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.REMOVE_CUPONS_PROMOTION),
        mergeMap(action =>
            promotionRequest.removeCuponPromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.removeCuponsPromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.removeCuponsPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.removeCuponsPromotionFailure(error.response)
                    );
                })
            )
        )
    );

/** Operations for SKUs */
export const searchSkuEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.SEARCH_SKUS),
        mergeMap(action =>
            promotionRequest.getSkuesRequest(action.payload).pipe(
                map((result) => promotionsActions.searchSkuesSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.searchSkuesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.searchSkuesFailure(error.response)
                    );
                })
            )
        )
    );

export const addSkuEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.ADD_SKU),
        mergeMap(action =>
            promotionRequest.addSkuRequest(action.payload).pipe(
                map((result) => promotionsActions.addSkuSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.addSkuFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.addSkuFailure(error.response)
                    );
                })
            )
        )
    );

export const removeBulkSkuesEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.REMOVE_SKU),
        mergeMap(action =>
            promotionRequest.removeSkuesRequest(action.payload).pipe(
                map((result) => promotionsActions.delSkuesSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.delSkuesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.delSkuesFailure(error.response)
                    );
                })
            )
        )
    );

export const updateSkuEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.UPDATE_SKU),
        mergeMap(action =>
            promotionRequest.updateSkuRequest(action.payload).pipe(
                map((result) => promotionsActions.updateSkuSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.updateSkuFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.updateSkuFailure(error.response)
                    );
                })
            )
        )
    );

export const addRulePromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.ADD_RULES_PROMOTION),
        mergeMap(action =>
            promotionRequest.addRulePromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.addRulesPromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.addRulesPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.addRulesPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const updateRulePromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.UPDATE_RULES_PROMOTION),
        mergeMap(action =>
            promotionRequest.updateRulePromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.updateRulesPromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.updateRulesPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.updateRulesPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const removeRulePromotionEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.REMOVE_RULES_PROMOTION),
        mergeMap(action =>
            promotionRequest.removeRulePromotionRequest(action.payload).pipe(
                map((result) => promotionsActions.removeRulesPromotionSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            promotionsActions.removeRulesPromotionFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        promotionsActions.removeRulesPromotionFailure(error.response)
                    );
                })
            )
        )
    );

export const getAllPromotionsActiveEpic = (action$) =>
    action$.pipe(
        ofType(promotionsActionsLabel.SEARCH_PROMOTION_ACTIVATE),
        mergeMap(action => promotionRequest.getPromotionsActiveRequest().pipe(
            map((result) => promotionsActions.searchPromotionsActivateSuccess(result)),
            catchError(error => {
                if (error.status === 403) {
                    return of(
                        promotionsActions.searchPromotionsActivateFailure('No tiene permisos para realizar esta acción')
                    );
                }
                if (error.status === 401) {
                    location.reload();
                }
                return of(
                    promotionsActions.searchPromotionsActivateFailure(error.response)
                );
            })
        )
        )
    );