import React from 'react';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';

import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';
import EmptyState from 'common/components/empty-state';

import {
    FormsyInputWrapper,
    PasswordInput
} from 'common/components/inputs';

import {PASSWORD_ICON} from 'common/constants/icon.constant';

import Modal from 'common/components/modal';


const mapStateToProps = (state) => {
    return {
        financingForm: transactionSelectors.getFinancingForm(state),
        registerUserDropdowns: userSelectors.getRegisterDropdowns(state),
        authUser: userSelectors.getAuthUser(state),
        error: userSelectors.getError(state),
        emailValidated: userSelectors.getIsValidEmail(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRegisterUserDropdowns: () => {
            dispatch(userActions.getRegisterUserDropdowns());
        }
    };
};

class AuthStepFinancingCheckout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            //email: '',
            passwordConfirmation: '',
            isRegistered: this.props.emailValidated,
            financingData: {},
            showCancelModal: false,
            canSubmit: props.financingForm.canSubmitAuthStep
        };
    }

    onBack = () => {
        let financingData = Object.assign({}, this.state.financingData, {canSubmitAuthStep: this.state.canSubmit});
        this.props.onSetFinancingFormData(financingData);
        this.props.previousStep({});
    };

    onFieldChange = (field, value) => {
        this.setState({[field]: value});
    };

    canSubmit = (canSubmit) => {
        this.setState({ canSubmit: canSubmit });
    };

    onSubmit = () => {
        let financingData = Object.assign({}, this.state.financingData, {canSubmitAuthStep: this.state.canSubmit});
        this.props.onSubmit(financingData, {
            registering: (this.state.passwordConfirmation !== null || this.state.passwordConfirmation !== undefined ),
            email: this.state.financingData.email,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            financingData: this.props.financingForm,
            canSubmit: this.props.financingForm.canSubmitAuthStep
        });
    }

    renderCancelModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCancelModal: false})}
                id='edit-user-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showCancelModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Cancelar solicitud</h2>
                    <p className="text-center">¿Está seguro que desea cancelar la solicitud? <strong>Perderá el progreso realizado.</strong></p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => {
                                this.props.onCancelCheckout(this.state.financingData.externalOrderId);
                                if (this.props.from) {
                                    location.replace(this.props.from);
                                }
                                this.props.onResetFinancingFormData();
                            }}
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showCancelModal: false})}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <div className="mgT3 white-background">
                    <div>
                        <ol style={{lineHeight:'100%'}} className="pdT2">
                            <li>
                                <p>Podrá dar seguimiento a esta solicitud ingresando a <strong className="color-blue">electropay.do</strong> con los siguientes datos: </p>
                            </li>
                        </ol>

                        <p>Usuario <strong>{this.state.financingData.email}</strong></p>
                    </div>
                    <Formsy
                        onValid={() => this.canSubmit(true)}
                        onInvalid={() => this.canSubmit(false)}>
                        <FormsyInputWrapper
                            required
                            className="mgB2 text-left"
                            name="password"
                            customError={this.props.error}
                            value={this.state.password}>
                            <PasswordInput
                                icon={PASSWORD_ICON}
                                label="Ingrese una contraseña"
                                dangerLabel={this.props.error !== null}
                                value={this.state.password}
                                onChange={(val) => this.setState({password: val})}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="mgB2"
                            name="passwordConfirmation"
                            value={this.state.passwordConfirmation === this.state.password}
                            validations="isTrue"
                            validationError="Las contraseñas deben ser iguales">
                            <PasswordInput
                                icon={PASSWORD_ICON}
                                label="Confirme la contraseña"
                                dangerLabel={this.state.passwordConfirmation !== this.state.password}
                                value={this.state.passwordConfirmation}
                                onChange={(val) => this.setState({passwordConfirmation: val})}
                            />
                        </FormsyInputWrapper>
                    </Formsy>
                    <div>
                        <ol style={{lineHeight:'100%'}} start="2" className="pdB2 pdT2">
                            <li>
                                <p>Le enviaremos un mensaje a <strong>{this.state.financingData.email}</strong> para la validación de su correo electrónico y procesar su solicitud. </p>
                            </li>
                        </ol>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-3 mgT">
                        <button
                            onClick={() => this.setState({showCancelModal: true})}
                            className="btn btn-outline w-100">
                            Cancelar
                        </button>
                    </div>
                    <div className="col-md-3 mgT" />
                    <div className="col-md-3 mgT">
                        <button
                            onClick={this.onBack}
                            className="btn w-100">
                            Volver
                        </button>
                    </div>
                    <div className="col-md-3 mgT">

                        <button
                            onClick={this.onSubmit}
                            disabled={(this.state.password === '' || this.state.passwordConfirmation === '' || this.state.password !== this.state.passwordConfirmation)}
                            className={((this.state.password !== '' && this.state.passwordConfirmation !== '' && this.state.password === this.state.passwordConfirmation)) ? 'btn btn-primary w-100' :'btn btn-clickable-pseudo-disabled w-100'}>
                            Finalizar
                        </button>

                    </div>
                </div>
                {this.renderCancelModal()}
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthStepFinancingCheckout);
