'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withAlert } from 'react-alert';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import {
    USERTYPES,
    FINANCING_STATUSES,
    FINANCING_CANCELATION_REASON
} from 'common/constants/enums.constant';

import FinancingDetailBody from './financing-detail-body';
import FinancingDetailHeader from './financing-detail-header';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            financing: transactionSelectors.getFinancingProfile(state),
            loading: transactionSelectors.getIsLoading(state),
            error: transactionSelectors.getError(state),
            uploadError: transactionSelectors.getUploadError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancing: (id) => {
            dispatch(transactionActions.getFinancing(id));
        },
        clearFinancingProfile: () => {
            dispatch(transactionActions.clearFinancingProfile());
        },
        resetFinancingMessages: () => {
            dispatch(transactionActions.resetFinancingMessages());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingDetailContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {from: queryString.parse(this.props.location.search)['from'], alertShown: false};
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (!id) {
            this.props.getFinancing(this.props.authUser.id);
        } else {
            this.props.getFinancing(id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const id = this.props.match.params.id;
            if (!id) {
                this.props.getFinancing(this.props.authUser.id);
            } else {
                this.props.getFinancing(id);
            }
        }

        if (this.props.uploadError) {
            this.props.resetFinancingMessages();
            this.props.alert.show(this.props.uploadError, {type: 'error'});
        }

        if (this.props.financing && !this.state.alertShown) {
            this.setState({alertShown: true});

            if (this.props.financing.order.user.active === true) {

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.WAITING_FOR_QUOTE && [USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1) {
                    if (this.props.financing.order.user.emailVerifiedAt === null) {
                        this.props.alert.show(<div>
                            <span>El cliente <strong>no ha validado el correo</strong></span>
                            <br></br>
                            <span>Tiene pendiente <strong>adjuntar la cotización</strong></span></div>
                        , {type: 'info'});
                    } else {
                        this.props.alert.show(<span >Tiene pendiente <strong>adjuntar la cotización</strong></span>, {type:'info'});
                    }
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.WAITING_FOR_STORE && [USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1) {
                    this.props.alert.show(<span>La solicitud está en <strong>espera por revisión de la tienda</strong></span>, {type: 'info'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED && this.props.authUser.userType.id !== USERTYPES.AGENT) {
                    this.props.alert.show(
                        <span>
                        Su solicitud no procedió con esta entidad financiera, favor de comunicarse con el ejecutivo de cuenta para más información.
                        </span>, {type: 'error'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED && this.props.authUser.userType.id === USERTYPES.CLIENT) {
                    this.props.alert.show(
                        <span>
                        Solicitud cancelada por la tienda. Razón:
                            <strong>
                                {' '}{this.props.financing.financingCancelationReason.id === FINANCING_CANCELATION_REASON.OTHER ? this.props.financing.otherFinancingCancelationReason : this.props.financing.financingCancelationReason.name}
                            </strong>
                        </span>, {type: 'error'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.APPROVED && this.props.authUser.userType.id === USERTYPES.CLIENT) {
                    this.props.alert.show(
                        <span>
                        Tu solicitud ha sido
                            <strong>
                                {' '}aprobada por la tienda
                            </strong>
                        </span>, {type: 'success'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.APPROVED_BY_ENTITY && this.props.authUser.userType.id === USERTYPES.CLIENT) {
                    this.props.alert.show(
                        <span>
                        Tu solicitud ha sido
                            <strong>
                                {' '}aprobada por la entidad financiera
                            </strong>
                        </span>, {type: 'success'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.APPROVED_BY_ENTITY && [USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1) {
                    this.props.alert.show(
                        <span>
                        Solicitud
                            <strong>
                                {' '}aprobada por la entidad financiera
                            </strong>
                        </span>, {type: 'success'});
                }

                if (this.props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED && this.props.financing.childId) {
                    this.props.alert.show(
                        <span
                            onClick={() => location.replace('/financings/' + this.props.financing.childId + this.props.location.search)}
                            className="cursor-pointer">
                        Esta solicitud ha sido cancelada debido a que fue modificada. Se ha creado la
                            <strong>
                                {' '} solicitud {this.props.financing.childId}
                            </strong>
                        </span>, {type: 'error'});
                }
            }
        }



    }

    componentWillUnmount() {
        this.props.clearFinancingProfile();
        this.props.resetFinancingMessages();
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error && (this.props.error !== 'Financiamiento no encontrado') &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {this.props.error && (this.props.error === 'Financiamiento no encontrado') &&
                    this.props.history.goBack()
                }
                {!this.props.loading &&
                    this.props.financing &&
                    <React.Fragment
                        key='financing-detail'>
                        <div className="layout-body__header pdB0">
                            <FinancingDetailHeader
                                financing={this.props.financing}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                resetFinancingMessages={this.props.resetFinancingMessages}
                            />
                            <hr className="w-100 mgT3 mgB2"/>
                        </div>
                        <div className="layout-body__content">
                            <FinancingDetailBody
                                financing={this.props.financing}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                resetFinancingMessages={this.props.resetFinancingMessages}
                            />
                        </div>
                    </React.Fragment>
                }
                {this.state.from &&
                    <div style={{bottom: '0px', width: '85%'}} className="position-fixed pd3">
                        <button
                            onClick={() => location.replace(this.state.from)}
                            className="text-center w-100 btn btn-primary">
                            Volver a la tienda
                        </button>
                    </div>
                }
            </div>
        );
    }
}

FinancingDetailContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withAlert()(withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingDetailContainer)));
