import { USERS_PER_PAGE, FINANCING_STATUSES, FINANCING_ENTITY_TYPE } from './enums.constant';

export const DEFAULT_PAGINATION = {
    count: 0,
    total: 0,
    hasMorePages: false,
    currentPage: 0,
    perPage: USERS_PER_PAGE
};

export const DEFAULT_USERS_FILTERS = {
    page: 1,
    perPage: USERS_PER_PAGE,
    q: '',
    store: null,
    financingEntity: null,
    active: null,
    userType: null
};

export const DEFAULT_FINANCINGS_FILTERS = {
    page: 1,
    perPage: USERS_PER_PAGE,
    from: '',
    to: '',
    q: '',
    store: null,
    financingEntity: null,
    status: [FINANCING_STATUSES.WAITING_FOR_QUOTE]
};

export const DEFAULT_TRANSACTIONS_FILTERS = {
    page: 1,
    perPage: USERS_PER_PAGE,
    from: '',
    to: '',
    q: '',
    store: null,
    financingEntity: null,
    status: 'All'
};


export const DEFAULT_FINANCING_ENTITIES_FILTERS = {
    page: 1,
    perPage: USERS_PER_PAGE,
    q: '',
    store: null,
    enabled: null, 
    type: [FINANCING_ENTITY_TYPE.BANK, FINANCING_ENTITY_TYPE.COOPERATIVE, FINANCING_ENTITY_TYPE.FINANCIAL, FINANCING_ENTITY_TYPE.CARDS]
};

export const DEFAULT_PROMOTION_FILTERS = {
    page: 1,
    perPage: USERS_PER_PAGE,
    q: '',
    enabled: null
};
