import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    TextInput,
    SelectInput,
    Checkbox
} from 'common/components/inputs';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';

export default class CreateUserAddressForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            data: {
                name: props.creating ? '' : props.data.name,
                street: props.creating ? '' : props.data.street,
                city: props.creating ? '' : props.data.city,
                postalCode: props.creating ? '' : props.data.postalCode,
                isDefault: props.creating ? false : props.isDefault,
                country: props.creating ? null : props.data.country ? props.data.country.id : null,
                province: props.creating ? null : props.data.province ? props.data.province.id : null,
                id: props.userid
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                street: { errorClass: null, dangerLabel: false },
                city: { errorClass: null, dangerLabel: false },
                postalCode: { errorClass: null, dangerLabel: false },
                country: { errorClass: null, dangerLabel: false },
                province: { errorClass: null, dangerLabel: false }
            }
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let data = {... this.state.data};
        data[field] = value;
        this.setState({data: data});
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.data});
    };

    validSubmit = () => {
        let isDifferent = true;
        if (!this.props.creating) {
            isDifferent = JSON.stringify(this.state.data) !== JSON.stringify({
                name: this.props.data.name,
                street: this.props.data.street,
                city: this.props.data.city,
                postalCode: this.props.data.postalCode,
                isDefault: this.props.isDefault,
                country: this.props.data.country.id,
                province: this.props.data.province.id,
                id: this.props.userid
            });
        }

        return this.state.canSubmit && isDifferent && ((this.props.isDefault && this.state.data.isDefault) || !this.props.isDefault);
    };

    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="name"
                        errorClassName={this.state.errorFields.name.errorClass}
                        value={this.state.data.name}>
                        <TextInput
                            label={<span>Nombre <span className="color-red">*</span></span>}
                            dangerLabel={this.state.errorFields.name.dangerLabel}
                            placeholder="Ej. Casa, Trabajo, etc..."
                            value={this.state.data.name}
                            onChange={(val) => this.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="street"
                        errorClassName={this.state.errorFields.street.errorClass}
                        value={this.state.data.street}>
                        <TextInput
                            label={<span>Dirección <span className="color-red">*</span></span>}
                            dangerLabel={this.state.errorFields.street.dangerLabel}
                            placeholder="Calle, número, nombre edificio, etc."
                            value={this.state.data.street}
                            onChange={(val) => this.onFieldChange('street', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-6 mgB2"
                        name="city"
                        errorClassName={this.state.errorFields.city.errorClass}
                        value={this.state.data.city}>
                        <TextInput
                            label={<span>Ciudad <span className="color-red">*</span></span>}
                            dangerLabel={this.state.errorFields.city.dangerLabel}
                            placeholder="Nombre de ciudad"
                            value={this.state.data.city}
                            onChange={(val) => this.onFieldChange('city', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-6 mgB2"
                        name="province"
                        validations="isTrue"
                        errorClassName={this.state.errorFields.province.errorClass}
                        value={this.state.data.province !== null}>
                        <SelectInput
                            floatingLabel={<span>Provincia <span className="color-red">*</span></span>}
                            key={'data.province'}
                            dangerLabel={this.state.errorFields.province.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.provinces}
                            value={this.state.data.province}
                            onChange={(val) => this.onFieldChange('province', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-6 mgB2"
                        name="country"
                        validations="isTrue"
                        errorClassName={this.state.errorFields.country.errorClass}
                        value={this.state.data.country !== null}>
                        <SelectInput
                            floatingLabel={<span>País <span className="color-red">*</span></span>}
                            key={'data.country'}
                            dangerLabel={this.state.errorFields.country.dangerLabel}
                            label="Seleccione una opción"
                            options={this.props.dropdowns.countries}
                            value={this.state.data.country}
                            onChange={(val) => this.onFieldChange('country', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-6 mgB2"
                        name="postalCode"
                        errorClassName={this.state.errorFields.postalCode.errorClass}
                        value={this.state.data.postalCode}>
                        <TextInput
                            label={<span>Código postal</span>}
                            numbers={true}
                            onlyNumbers={true}
                            dangerLabel={this.state.errorFields.postalCode.dangerLabel}
                            placeholder="10000"
                            value={this.state.data.postalCode}
                            onChange={(val) => this.onFieldChange('postalCode', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-12 mgB"
                        name="isDefault">
                        <Checkbox
                            squared={true}
                            label='Marcar como preferida'
                            checked={this.state.data.isDefault}
                            value={true}
                            onChange={() => this.onFieldChange('isDefault', !this.state.data.isDefault)}/>
                    </FormsyInputWrapper>
                </div>
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                {!((this.props.isDefault && this.state.data.isDefault) || !this.props.isDefault) &&
                    <div className='has-error mgB'>
                        <span className='help-block'>Debe tener 1 dirección marcada como preferida</span>
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-entity-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.data}>
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </Formsy>
        );
    }
}

CreateUserAddressForm.defaultProps = {creating: true};

CreateUserAddressForm.propTypes = {
    creating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
