'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Document, Page } from 'react-pdf';
import _ from 'lodash';

import ImagePreview from 'common/components/image-preview';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import Dropzone from 'common/components/dropzone';

import {
    USERTYPES,
    IDENTIFICATION_TYPES,
    CURRENCIES_IDS,
    FINANCING_STATUSES
} from 'common/constants/enums.constant';
import InformationModal from 'common/components/information-modal';
import Modal from 'common/components/modal';

import { SelectInput } from 'common/components/inputs';

import mask from 'common/helpers/mask';
import money from 'common/helpers/money';
import CartComponent from 'common/components/cart-component';

import FinancingActionsList from './financing-actions-list';

import {
    PENCIL_ICON,
    CLIPBOARD_ICON,
    MS_WORD_ICON,
    DELETE_ICON,
    EYE_HIDE_ICON
} from 'common/constants/icon.constant';

import EditFinancingClientInfo from './edit-financing-client-info';
import EditProductForm from './edit-product-form';
import calculateFinancing from 'common/helpers/calculate-financing';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            actions: transactionSelectors.getFinancingActions(state),
            actionsLoading: transactionSelectors.getFinancingActionsLoading(state),
            actionsError: transactionSelectors.getFinancingActionsError(state),
            registerUserDropdowns: userSelectors.getRegisterDropdowns(state),
            simpleError: transactionSelectors.getSimpleError(state),
            financingManuallyUpdated: transactionSelectors.getFinancingManuallyUpdated(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancingActions: (id) => {
            dispatch(transactionActions.getFinancingActions(id));
        },
        getRegisterUserDropdowns: () => {
            dispatch(userActions.getRegisterUserDropdowns());
        },
        updateFinancing: (id, data) => {
            dispatch(transactionActions.updateFinancing(id, data));
        },
        uploadQuote: (id, file) => {
            dispatch(transactionActions.uploadQuote(id, file));
        },
        uploadDocument: (id, file, admin_employees) => {
            dispatch(transactionActions.uploadDocument(id, file, admin_employees));
        },
        removeDocument: (id, filename) => {
            dispatch(transactionActions.removeDocument(id, filename));
        },
        assigBranch: (id, branchId) => {
            dispatch(transactionActions.assignBranchFinancig(id, branchId));
        }
    };
};

class FinancingDetailBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showWarningClientModal: false,
            showEditClientModal: false,
            showWarningProductModal: false,
            showAssignBranchModal: false,
            showProfile: false,
            enableEditProductMode: false,
            showSecondWarning: false,
            dataToUpdate: null,
            clientValid: props.financing.order.user.active,
            fullProfile: false,
            branchSelected: null
        };
    }

    shouldShowSecondWarning = (data) => {
        if (data.name !== this.props.financing.name) {
            return true;
        }

        if (data.identification !== this.props.financing.identification) {
            return true;
        }

        if (data.identificationType !== this.props.financing.identificationType.id) {
            return true;
        }

        if (JSON.stringify(data.identificationFile) !== JSON.stringify(this.props.financing.identificationFile)) {
            return true;
        }

        if (JSON.stringify(data.identificationFile2) !== JSON.stringify(this.props.financing.identificationFile2)) {
            return true;
        }

        return false;
    };

    renderFinancingUpdateModal() {
        return (
            <InformationModal imageSuccess="/images/financing_success.svg" showModal={this.props.financingManuallyUpdated !== null}>
                <div>
                    <h2 className="text-center">Modificación exitosa</h2>

                    <p className="text-center">La modificación ha sido realizada {this.props.financingManuallyUpdated && this.props.financingManuallyUpdated.id !== this.props.financing.id ? ' y se ha creado la Solicitud ' + this.props.financingManuallyUpdated.id : ''}</p>
                    <div className="row">
                        <div className="text-center col-md-12 mgB">
                            <button
                                onClick={() => {
                                    this.props.resetFinancingMessages();
                                    location.reload();
                                }}
                                className="btn btn-outline">
                                Entendido
                            </button>
                        </div>
                    </div>
                </div>
            </InformationModal>
        );
    }

    renderSecondWarning() {
        return (
            <InformationModal imageSuccess="/images/edit_financing.svg" showModal={this.state.showSecondWarning}>
                <div>
                    <h2 className="text-center">Modificar solicitud</h2>

                    <p className="text-center">¿Está seguro que desea modificar la solicitud?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Esta solicitud se cancelará automáticamente y se creará una nueva solicitud con los cambios realizados.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => {
                                    this.updateFinancing(this.state.dataToUpdate);
                                    this.setState({showSecondWarning: false, dataToUpdate: null});
                                }}
                                className="btn w-100 btn-outline">
                                Continuar
                            </button>
                        </div>
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showSecondWarning: false})}
                                className="btn w-100 btn-primary">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </InformationModal>
        );
    }

    renderWarningClientModal() {
        return (
            <InformationModal imageSuccess="/images/edit_financing.svg" showModal={this.state.showWarningClientModal}>
                <div>
                    <h2 className="text-center">Modificar solicitud</h2>

                    <p className="text-center">¿Está seguro que desea modificar la solicitud?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Al modificar el nombre o documento de identidad del solicitante esta solicitud se cancelará automáticamente y se creará una nueva solicitud con los cambios realizados.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showWarningClientModal: false, showEditClientModal: true})}
                                className="btn w-100 btn-outline">
                                Continuar
                            </button>
                        </div>
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showWarningClientModal: false})}
                                className="btn w-100 btn-primary">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </InformationModal>
        );
    }

    renderProfileClientModal() {
        let twostr = (this.props.financing.identificationFile2) ? this.props.financing.identificationFile2.split('.') : 0;
        return (
            <Modal
                onModalClose={() => this.setState({showProfile: false})}
                className="white-background"
                closable={false}
                noDivider={true}
                style={{width:'650px'}}
                showModal={this.state.showProfile}>
                <div>
                    <h2 className="text-center">Perfil del Cliente</h2>

                    <hr className="header-divider"/>

                    <div style={{fontSize:'14px', fontFamily:'Montserrat', fontStyle:'Normal', lineHeight:'3px'}}>
                        <h3 className="mgT col-12 color-blue">
                            <strong>Datos Personales</strong>
                        </h3>
                        <hr />
                        <div className="row">
                            <p className="col-4">Nombre y apellidos: </p> <p className="fsbold col-8">{this.props.financing.name + ' ' + this.props.financing.lastname}</p>
                            <p className="col-4">{this.props.financing.identificationType.name}: </p> <p className="col-8"><span className="fsbold">{this.props.financing.identification}</span></p>
                            <div style={{height:'55px'}} className="w-100">
                                <p className="col-4" style={{float:'left', paddingTop:'10px'}}>Dirección: </p>
                                <textarea wrap="soft" rows="2" className="col-8" style={{lineHeight:'18px', height:'45px', fontWeight:'bold', border:'none', resize:'none'}}>
                                    {this.props.financing.order.transactions[0].address.street +', '+ this.props.financing.order.transactions[0].address.city}
                                </textarea>
                            </div>
                            <p className="col-4">Teléfono: </p> <p className="col-8"><span className="fsbold">{this.props.financing.phone}</span></p>
                            <p className="col-4">Correo electrónico: </p> <p className="col-8"><span className="fsbold">{this.props.financing.email}</span></p>

                            {/* this.props.financing.optional_fields !== null && this.props.financing.optional_fields.fields.length > 0 &&
                                <React.Fragment>
                                    {this.props.financing.optional_fields.fields.filter((elem) => {
                                        return elem.belongto === 1;
                                    }).map((data, index) => {
                                        return (
                                            <div className="w-100" key={index}>
                                                <p className="col-4" style={{float:'left'}}>{data.name}: </p>
                                                <p className="col-8" ><span className="fsbold" style={{paddingLeft:'15px'}}>{data.value}</span></p>
                                            </div>
                                        );
                                    })
                                    }
                                </React.Fragment> */
                            }
                        </div>
                        {this.props.financing.identificationFile &&
                        <React.Fragment>
                            <hr />
                            <h3 className="mgT col-12 color-blue">
                                <strong> Datos de la cédula</strong>
                            </h3>
                            <hr />

                            <div className='row'>
                                {twostr.length !== 2 && <div className='col-md-3'></div>}
                                <div className='col-md-6' >
                                    <img src={this.props.financing.identificationFile} alt='' width='100%' height='180px'></img>
                                </div>
                                {twostr.length !== 2 && <div className='col-md-3'></div>}
                                {twostr.length === 2 &&
                                <div className='col-md-6'>
                                    <img src={this.props.financing.identificationFile2} alt='' width='100%' height='180px'>
                                    </img>
                                </div>}
                            </div>
                        </React.Fragment>
                        }
                        <hr />
                        <h3 className="mgT col-12 color-blue">
                            <strong>Datos adicionales</strong>
                        </h3>
                        <hr />
                        <div className="row">
                            {this.props.financing.isMarried &&
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                <p className="col-4">Estado civil: </p> <p className="col-8"><strong>Casado(a)</strong></p>
                                <p className="col-4">Nombre del conyuge: </p> <p className="col-8"><span className="fsbold">{this.props.financing.spouseName}</span></p>
                                <p className="col-4">Cédula del conyuge: </p> <p className="col-8"><span className="fsbold">{this.props.financing.spouseIdentification ? this.props.financing.spouseIdentification : '---'}</span></p>
                                <p className="col-4">Teléfono del conyuge: </p> <p className="col-8"><span className="fsbold">{this.props.financing.spousePhone}</span></p>
                            </div>
                            }
                            {!this.props.financing.isMarried &&
                            <div style={{display:'flex', flexWrap:'wrap', width:'98%'}}>
                                <p className="col-4" >Estado civil: </p> <p className="col-8" style={{paddingLeft:'20px'}}><strong>Soltero(a)</strong></p>
                            </div>
                            }
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                <p className="col-4 mgT2">Nombre del familiar:</p> <p className="col-8 mgT2"><span className="fsbold">{this.props.financing.relativeName}</span></p>
                                <p className="col-4">Teléfono:</p> <p className="col-8"><span className="fsbold">{this.props.financing.relativePhone}</span></p>

                                <h5 className="col-12 mgT2"><strong>Datos de la vivienda</strong></h5>
                                <p className="col-4">Tipo de vivienda: </p> <p className="col-8"><span className="fsbold">{this.props.financing.livingType ? this.props.financing.livingType.name : ''}</span></p>
                                <p className="col-4">Tiempo en la vivienda: </p> <p className="col-8"><span className="fsbold">{this.props.financing.livingTime ? this.props.financing.livingTime.name : ''}</span></p>
                            </div>

                        </div>
                        <hr />
                        <h3 className="mgT col-12 color-blue">
                            <strong>Datos laborales</strong>
                        </h3>
                        <hr />
                        <div className="row">
                            <div style={{height:'55px'}} className="w-100">
                                <p className="col-4" style={{float:'left', paddingTop:'10px'}}>Nombre de la empresa:  </p>
                                <textarea wrap="soft" rows="2" className="col-8" style={{lineHeight:'18px', height:'45px', fontWeight:'bold', border:'none', resize:'none'}}>
                                    {this.props.financing.companyName}
                                </textarea>
                            </div>
                            <div style={{height:'55px'}} className="w-100">
                                <p className="col-4" style={{float:'left', paddingTop:'10px'}}>Dirección de la empresa: </p>
                                <textarea wrap="soft" rows="2" className="col-8" style={{lineHeight:'18px', height:'45px', fontWeight:'bold', border:'none', resize:'none'}}>
                                    {this.props.financing.companyStreet}
                                </textarea>
                            </div>
                            <p className="col-4">Tipo de empresa: </p> <p className="col-8"><span className="fsbold">{this.props.financing.companyType ? this.props.financing.companyType.name : ''}</span></p>
                            <p className="col-4">Teléfono: </p> <p className="col-8"><span className="fsbold">{this.props.financing.companyPhone}</span></p>
                            <p className="col-4">Tiempo de Trabajo: </p> <p className="col-8"><span className="fsbold">{this.props.financing.workingTime ? this.props.financing.workingTime.name : ''}</span></p>
                            <div style={{height:'55px'}} className="w-100">
                                <p className="col-4" style={{float:'left', paddingTop:'10px'}}>Cargo que Ocupa: </p>
                                <textarea wrap="soft" rows="2" className="col-8" style={{lineHeight:'18px', height:'45px', fontWeight:'bold', border:'none', resize:'none'}}>
                                    {this.props.financing.companyTitle}
                                </textarea>
                            </div>
                            <p className="col-4">Salario: </p> <p className="col-8"><span className="fsbold">{this.props.financing.financingPlan.currency.symbol + '$ ' + money(this.props.financing.salary)}</span></p>
                            <p className="col-4">Otros Ingresos: </p> <p className="col-8"><span className="fsbold">{this.props.financing.financingPlan.currency.symbol + '$ ' + money(this.props.financing.otherIncomes)}</span></p>
                        </div>

                        <hr />
                        <h3 className="mgT col-12 color-blue">
                            <strong>Datos extras de interes</strong>
                        </h3>
                        <hr />
                        <div className="row">
                            {this.props.financing.optional_fields !== null && this.props.financing.optional_fields.fields.length > 0 &&
                                <React.Fragment>
                                    {this.props.financing.optional_fields.fields.map((data, index) => {
                                        return (
                                            <div className="w-100" key={index}>
                                                <p className="col-4" style={{float:'left'}}>{data.name}: </p>
                                                <p className="col-8" ><span className="fsbold" style={{paddingLeft:'15px'}}>{data.value}</span></p>
                                            </div>
                                        );
                                    })
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row text-center">
                        <div className="col-12 mgB">
                            <button
                                onClick={() => this.setState({showProfile: false})}
                                className="btn btn-primary"
                                style={{width:'70px'}}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    renderWarningProductModal() {
        return (
            <InformationModal imageSuccess="/images/edit_financing.svg" showModal={this.state.showWarningProductModal}>
                <div>
                    <h2 className="text-center">Modificar solicitud</h2>

                    <p className="text-center">¿Está seguro que desea modificar la solicitud?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>Al modificar los productos de la solicitud, esta se cancelará automáticamente y se creará una nueva solicitud con los cambios realizados.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showWarningProductModal: false, enableEditProductMode: true})}
                                className="btn w-100 btn-outline">
                                Continuar
                            </button>
                        </div>
                        <div className="col-md-6 mgB">
                            <button
                                onClick={() => this.setState({showWarningProductModal: false})}
                                className="btn w-100 btn-primary">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </InformationModal>
        );
    }

    renderEditClientModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditClientModal: false})}
                id='create-user-modal'
                className="white-background edit-financing-modal"
                closable={false}
                noDivider={true}
                showModal={this.state.showEditClientModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_user.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Editar datos del cliente</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <EditFinancingClientInfo
                    dropdowns={this.props.registerUserDropdowns}
                    error={this.props.simpleError}
                    financing={this.props.financing}
                    onSubmit={(data) => {
                        if (this.shouldShowSecondWarning(data)) {
                            this.setState({dataToUpdate: data, showSecondWarning: true});
                        } else {
                            this.updateFinancing(data);
                        }
                    }}
                    onCancel={() => this.setState({showEditClientModal: false})}
                />
            </Modal>
        );
    }

    updateFinancing = (data) => {
        let plan = {...data.plan};
        plan['monthlyFixedAmount'] = plan.monthlyFixedAmount ? plan.monthlyFixedAmount.amount : 0;
        plan['firstPaymentAmount'] = plan.firstPaymentAmount ? plan.firstPaymentAmount.amount : 0;

        data['financingTotal'] = calculateFinancing(plan.payments, plan, (data.cart.total - (data.advancePayment === '' ? 0 : parseInt(data.advancePayment))) / 100).rawTotal * 100;
        data['plan'] = data.plan.id;

        this.props.updateFinancing(this.props.financing.id, data);
    };

    uploadQuote = (quoteFile) => {
        let file = Array.isArray(quoteFile) ? quoteFile[0] : quoteFile;
        this.props.uploadQuote(this.props.financing.id, file);
    };

    uploadDocument = (doc) => {
        let file = Array.isArray(doc) ? doc[0] : doc;
        this.props.uploadDocument(this.props.financing.id, file, 0);
    };

    uploadDocumentToAdmin = (doc) => {
        let file = Array.isArray(doc) ? doc[0] : doc;
        this.props.uploadDocument(this.props.financing.id, file, 1);
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.financing) !== JSON.stringify(this.props.financing)) {
            setTimeout(() => {
                this.props.getFinancingActions(this.props.financing.id);
            }, 2000);
        }
    }

    componentDidMount() {
        this.props.getFinancingActions(this.props.financing.id);
        if (this.props.registerUserDropdowns.countries.length === 0) {
            this.props.getRegisterUserDropdowns();
        }
    }

    renderLog() {
        return (
            <div style={{maxHeight: '377px'}} className="user-logs-box row mgL0 mgB0 white-background">
                <div style={{height: '40px', paddingTop: '7px'}} className="col-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Historial de acciones</p></strong>
                </div>
                <hr className="w-100" />
                {!this.props.actionsLoading &&
                    this.props.actions !== null &&
                    !this.props.actionsError &&
                    <div className="col-12">
                        <FinancingActionsList
                            history={this.props.history}
                            actions={this.props.actions}
                            financing={this.props.financing}
                            usertype={this.props.authUser.userType.id}
                        />
                    </div>
                }
                {!this.props.actionsLoading &&
                    this.props.actions === null &&
                    !this.props.actionsError &&
                    <div className="m-auto">
                        <EmptyState
                            imgStyle={
                                {
                                    borderRadius: '150px',
                                    margin: 'auto',
                                    width: '170px',
                                    padding: '20px'
                                }
                            }
                            image="/images/log_empty_state.svg"
                            title=""
                            small={true}
                            text={'Aún no hay acciones registradas'}
                        />
                    </div>
                }
                {this.props.actionsLoading &&
                    <LoadingComponent
                        height={160}
                        style={{padding: '50px 0px 0px'}}
                        width={120} />
                }
                {this.props.actionsError &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.actionsError}
                    />
                }
            </div>
        );
    }

    renderProductInfo() {
        let cart = {
            items: this.props.financing.order.items,
            currency: this.props.financing.order.currency.iso,
            deliveryCost: this.props.financing.order.deliveryCost
        };

        return (
            <div className="product-detail-box row mgL0 mgR0 white-background">
                <div className="col-md-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Datos de los productos</p></strong>
                </div>
                <div className="col-md-6 text-md-right">
                    {[USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 && !this.state.enableEditProductMode && [FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) === -1 &&
                        <button
                            onClick={() => {
                                if (this.props.financing.manuallyUpdatedAt === null) {
                                    this.setState({showWarningProductModal: true});
                                } else {
                                    this.setState({enableEditProductMode: true});
                                }
                            }}
                            className="btn btn-transparent btn-link pdR0 pdL0">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Editar
                            </strong>
                        </button>
                    }
                </div>
                <hr className="w-100" />
                <div className="col-md-12">
                    {!this.state.enableEditProductMode &&
                        <CartComponent
                            user={this.props.authUser}
                            cart={cart}
                            className="col-12"
                        />
                    }
                    {this.state.enableEditProductMode &&
                        <EditProductForm
                            error={this.props.simpleError}
                            financing={this.props.financing}
                            onSubmit={(data) => {
                                this.setState({dataToUpdate: data, showSecondWarning: true});
                            }}
                            onCancel={() => this.setState({enableEditProductMode: false})}
                        />
                    }
                </div>
            </div>
        );
    }

    renderClientInfo() {
        return (
            <div style={{maxHeight: '377px'}} className="user-detail-box row mgL0 mgR0  white-background">
                <div className="col-md-6 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Datos del cliente</p></strong>
                </div>
                <div className="col-md-3 text-md-right">{//`${this.props.financing.pdfFile !== null ? 'col-md-3' : 'col-md-6'} `
                }
                {[USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 && [FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) === -1 && this.state.clientValid && this.state.fullProfile &&
                        <div>
                            <button
                                onClick={() => {
                                    if (this.props.financing.manuallyUpdatedAt === null) {
                                        this.setState({showWarningClientModal: true});
                                    } else {
                                        this.setState({showEditClientModal: true});
                                    }
                                }}
                                className="btn btn-transparent btn-link pdL0 pdR0">
                                <strong>
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Editar
                                </strong>
                            </button>
                        </div>

                }
                </div>
                <div className="col-md-3 text-md-right">
                    {this.state.fullProfile && <button
                        className="btn btn-transparent btn-link pdL0 pdR0"
                        onClick={() => this.setState({showProfile: true})}>
                        <strong>
                            <i style={{fontSize: '14px', marginRight: '10px'}} className={EYE_HIDE_ICON}/>
                                Ver Perfil
                        </strong>
                    </button>}
                </div>
                <hr className="w-100" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Nombre y Apellidos</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.name +' '+ this.props.financing.lastname}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Teléfono</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.phone ? mask(this.props.financing.phone, '(###) ###-####') : ''}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>No. de {this.props.financing.identificationType.id === IDENTIFICATION_TYPES.CEDULA ? 'cédula' : 'pasaporte'}</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.identificationType.id === IDENTIFICATION_TYPES.CEDULA ? mask(this.props.financing.identification, '###-#######-#') : this.props.financing.identification}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Correo electrónico</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.email}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Dirección</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.order.transactions[0].address.fullAddress}</p></strong>
                </div>
            </div>
        );
    }

    onFileChanged = (event) => {
        if (event.target.files.length > 0) {
            this.uploadQuote(event.target.files[0]);
        }
    };

    renderQuoteFile() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-6">
                        <p style={{fontSize: '18px'}} className="mgB"><strong>Cotización (pdf)</strong></p>
                    </div>
                    <div className={`${this.props.financing.order.quoteFile !== null ? 'col-md-3' : 'col-md-6'} text-md-right`}>
                        {[USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1 && this.props.financing.order.quoteFile !== null && [FINANCING_STATUSES.SENT, FINANCING_STATUSES.WAITING_FOR_QUOTE, FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL].indexOf(this.props.financing.financingStatus.id) > -1 && this.state.clientValid &&
                            <React.Fragment>
                                <button
                                    onClick={() => {
                                        document.getElementById('quote-file-input').click();
                                    }}
                                    className="btn btn-transparent btn-link pdR0 pdL0">
                                    <strong>
                                        <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                        Cambiar
                                    </strong>
                                </button>
                            </React.Fragment>
                        }
                    </div>
                    {this.props.financing.order.quoteFile !== null &&
                        <div className="col-md-3 text-md-right">
                            <button
                                className="btn btn-transparent btn-link pdL0 pdR0">
                                <a className="w-100 text-decoration-none" rel="noreferrer" target="_blank" href={this.props.financing.order.quoteFile}>
                                    <strong>
                                        <i style={{fontSize: '14px', marginRight: '10px'}} className={CLIPBOARD_ICON}/>
                                        Ver cotización
                                    </strong>
                                </a>
                            </button>
                        </div>
                    }
                </div>
                <hr className="w-100" />
                {this.props.financing.order.quoteFile !== null &&
                    <a className="w-100 text-decoration-none text-white" rel="noreferrer" target="_blank" href={this.props.financing.order.quoteFile}>
                        {(this.props.financing.order.quoteFile.name ? this.props.financing.order.quoteFile.name.toLowerCase().includes('.pdf') : this.props.financing.order.quoteFile.toLowerCase().includes('.pdf')) &&
                            <Document
                                className="gray-background"
                                file={this.props.financing.order.quoteFile}>
                                <Page height={300} width={500} pageNumber={1} />
                            </Document>
                        }
                        {!(this.props.financing.order.quoteFile.name ? this.props.financing.order.quoteFile.name.toLowerCase().includes('.pdf') : this.props.financing.order.quoteFile.toLowerCase().includes('.pdf')) &&
                            <ImagePreview
                                style = {
                                    {
                                        height: '300px',
                                        width: 'inherit'
                                    }
                                }
                                className="image-preview image-preview__xl"
                                image={this.props.financing.order.quoteFile}>
                            </ImagePreview>
                        }
                    </a>
                }
                {this.props.financing.order.quoteFile === null && [FINANCING_STATUSES.SENT, FINANCING_STATUSES.WAITING_FOR_QUOTE].indexOf(this.props.financing.financingStatus.id) > -1 && this.state.clientValid &&
                    <Dropzone
                        accept={['.pdf']}
                        onDrop={this.uploadQuote}
                        multiple={false}
                        full={true}>
                        <div className="mgT15">
                            <EmptyState
                                imgStyle={
                                    {
                                        borderRadius: '150px',
                                        margin: 'auto',
                                        width: '170px',
                                        padding: '20px'
                                    }
                                }
                                small={true}
                                image="/images/add_image.svg"
                                title=""
                                text='Adjuntar cotización'
                            />
                            <p>Arroje el archivo aquí o<strong className="color-blue"> busque </strong> en sus archivos <span className="color-gray">(.pdf)</span></p>
                        </div>
                    </Dropzone>
                }
                {this.props.financing.order.quoteFile === null && ([FINANCING_STATUSES.SENT, FINANCING_STATUSES.WAITING_FOR_QUOTE].indexOf(this.props.financing.financingStatus.id) === -1 || !this.state.clientValid) &&
                    <p className="color-blue">Sin archivo de cotización.</p>
                }
            </React.Fragment>
        );
    }

    renderSingleDocument(document) {
        if (document.toLowerCase().includes('.pdf')) {
            return (
                <Document
                    file={document}>
                    <Page height={232} pageNumber={1} />
                </Document>
            );
        }

        if (document.toLowerCase().includes('.doc')) {
            return (
                <div className="text-center">
                    <span style={{fontSize: '8rem'}}>
                        <i className = {MS_WORD_ICON}/>
                    </span>
                    <p className="paragraph mgB0" style={{fontSize: '10px'}}>{document.substring(document.lastIndexOf('/') + 1)}</p>
                </div>
            );
        }

        return (
            <ImagePreview
                style = {
                    {
                        height: '232px',
                        width: 'inherit',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }
                }
                className="image-preview image-preview__xl"
                image={document}>
            </ImagePreview>
        );
    }

    renderDocuments() {
        let docs = this.props.financing.documents.map((files) => { return files; });
        if (this.props.financing.identificationFile !== null) {
            docs.push({file: this.props.financing.identificationFile, toadmin: 0});
        }
        if (this.props.financing.identificationFile2 !== null) {
            docs.push({file: this.props.financing.identificationFile2, toadmin: 0});
        }
        return (
            <div className="product-detail-box row mgL0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Documentos adjuntos</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-md-12 row">
                    {docs.filter((doc) => { return doc.toadmin === 0; }).map((el, i) => {
                        return (
                            <div
                                className="col-md-6 mgB"
                                key={'document-' + i}>
                                <span
                                    className="position-absolute color-blue"
                                    style={{
                                        right: '30px',
                                        top: '10px',
                                        fontSize: '1.5rem',
                                        zIndex: 1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => this.props.removeDocument(this.props.financing.id, el.file.substring(el.file.lastIndexOf('/') + 1))}>
                                    <i className={DELETE_ICON}></i>
                                </span>
                                {this.renderSingleDocument(el.file)}
                            </div>
                        );
                    })}
                    {[FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) === -1 && this.state.clientValid &&
                    <div className="col-md-6 mgB">
                        <Dropzone
                            accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                            onDrop={this.uploadDocument}
                            multiple={false}
                            full={true}>
                            <div className="mgT15">
                                <EmptyState
                                    imgStyle={
                                        {
                                            borderRadius: '150px',
                                            margin: 'auto',
                                            width: '170px',
                                            padding: '20px'
                                        }
                                    }
                                    small={true}
                                    image="/images/add_image.svg"
                                    title=""
                                    text=''
                                />
                                <p><strong className="color-blue">Agregar documento </strong></p>
                            </div>
                        </Dropzone>
                    </div>}
                </div>
                {([FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) > -1 || !this.state.clientValid) && docs.filter((doc) => { return doc.toadmin === 0; }).length === 0 &&
                    <div className="col-12 row"><p className="color-blue col-12">Sin archivos adjuntos.</p></div>
                }
                {docs.filter((doc) => { return doc.toadmin === 0; }).length > 0 &&
                    <div className="col-md-12">
                        <a className="text-decoration-none" rel="noreferrer" target="_blank" href={`/transactions/financings/download/${this.props.financing.id}`}>
                            <button
                                className="btn btn-primary w-100">
                                Descargar archivos
                            </button>
                        </a>
                    </div>
                }

            </div>
        );
    }
    renderDocumentsToEntity() {
        return (
            <div className="product-detail-box row mgL0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Documentos solo para la tienda</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-md-12 row">
                    {this.props.financing.documents.filter((doc) => { return doc.toadmin === 1; }).map((el, i) => {
                        return (
                            <div
                                className="col-md-3 mgB"
                                key={'document-' + i}>
                                <span
                                    className="position-absolute color-blue"
                                    style={{
                                        right: '30px',
                                        top: '10px',
                                        fontSize: '1.5rem',
                                        zIndex: 1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => this.props.removeDocument(this.props.financing.id, el.file.substring(el.file.lastIndexOf('/') + 1))}>
                                    <i className={DELETE_ICON}></i>
                                </span>
                                {this.renderSingleDocument(el.file)}
                            </div>
                        );
                    })}
                    {[FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) === -1 && this.state.clientValid &&
                    <div className="col-md-3 mgB">
                        <Dropzone
                            accept={['.pdf', '.jpeg', '.jpg', '.png', '.doc', '.docx']}
                            onDrop={this.uploadDocumentToAdmin}
                            multiple={false}
                            full={true}>
                            <div className="mgT15">
                                <EmptyState
                                    imgStyle={
                                        {
                                            borderRadius: '150px',
                                            margin: 'auto',
                                            width: '170px',
                                            padding: '20px'
                                        }
                                    }
                                    small={true}
                                    image="/images/add_image.svg"
                                    title=""
                                    text=''
                                />
                                <p><strong className="color-blue">Agregar documento </strong></p>
                            </div>
                        </Dropzone>
                    </div>}
                </div>
                {([FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(this.props.financing.financingStatus.id) > -1 || !this.state.clientValid) && this.props.financing.documents.filter((doc) => { return doc.toadmin === 1; }).length === 0 &&
                    <div className="col-12 row"><p className="color-blue col-12">Sin archivos adjuntos.</p></div>
                }
                {this.props.financing.documents.filter((doc) => { return doc.toadmin === 1; }).length > 0 &&
                    <div className="col-md-12">
                        <a className="text-decoration-none" rel="noreferrer" target="_blank" href={`/transactions/financings/download/${this.props.financing.id}/1`}>
                            <button className="btn btn-primary w-100">
                                Descargar archivos
                            </button>
                        </a>
                    </div>
                }

            </div>
        );
    }


    renderAssignBranche() {
        return (
            <Modal
                overlayClassName="o-inherit"
                // className="o-md-inherit"
                onModalClose={() => this.setState({showAssignBranchModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showAssignBranchModal}
                id='ModalView'>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/change_financing_status.svg"
                        title=""
                        text=""
                    />

                    <h2 className="text-center">Asignar a Sucursal</h2>

                    <div style={{margin: '15px 0px'}} className="row">
                        <SelectInput
                            value={this.state.branchSelected}
                            floatingLabel="Seleccione la sucursal"
                            options={this.props.financing.financingPlan.financingEntity.branches.map((bra) => {
                                return {
                                    label: bra.branch_name,
                                    value: bra.id
                                };
                            })}
                            onChange={(val) => this.setState({branchSelected: val})}
                        />
                    </div>

                    <div style={{margin: '0px', paddingTop: '20px'}} className="row">
                        <button
                            onClick={() => this.props.assigBranch(this.props.financing.id, this.state.branchSelected)}
                            disabled = {this.state.branchSelected === null}
                            className="btn btn-primary col-4">
                            Aplicar
                        </button>
                        <div className='col-4' />
                        <button
                            onClick={() => this.setState({showAssignBranchModal: false})}
                            className="btn btn-primary col-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderPlanInfo() {
        let subTotal = _.sumBy(this.props.financing.order.items, (item) => {
            return item.count * item.price;
        }) / 100;
        let taxes = (subTotal + (this.props.financing.order.deliveryCost / 100)) * parseFloat(process.env.MIX_TAXES);
        subTotal = subTotal + taxes + (this.props.financing.order.deliveryCost / 100) - (this.props.financing.advancePayment / 100);

        let calculos = calculateFinancing(this.props.financing.financingPlan.payments, this.props.financing.financingPlan, subTotal);
        const payments = calculos.restPayments;
        const firstPayment = calculos.gastos_cierre;
        const total = calculos.rawTotal;

        //sucursales
        let branches =[];
        if (this.props.financing.financingPlan.financingEntity.branches.length > 0) {
            branches = this.props.financing.financingPlan.financingEntity.branches;
        }
        return (
            <div className="product-detail-box row mgL0 white-background">
                <div className="col-md-12 align-center">
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Entidad seleccionada</p></strong>
                </div>
                <hr className="w-100" />
                <div className="col-md-5 align-center">
                    <ImagePreview
                        style = {
                            {
                                height: '70px',
                                width: 'inherit',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain'
                            }
                        }
                        className="image-preview"
                        image={this.props.financing.financingPlan.financingEntity.logo}>
                    </ImagePreview>
                </div>
                <div className="col-md-7">
                    <p className="subtitle-1">{this.props.financing.financingPlan.financingEntity.name}</p>
                    {this.props.financing.partner_number !== null &&
                        <p>Id de asociado: <strong>{this.props.financing.partner_number}</strong></p>
                    }
                    <p>Total a pagar: <strong>{this.props.financing.financingPlan.currency.symbol + '$ ' + money(total + this.props.financing.advancePayment / 100)}</strong></p>
                </div>
                <div className="col-md-12">
                    <strong><p className="mgB0">Detalles del plan</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Incluye transporte</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.financingEntity.optional_delivery ? 'Si': 'No'}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Requiere garantía</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.financingEntity.require_warranties ? 'Si': 'No'}</p></strong>
                </div>
                {this.props.financing.financingPlan.financingEntity.branches.length > 0 && 
                <React.Fragment>
                    <hr className="w-95" />
                    <div className="col-md-6">
                        <p className={`'mgB0' ${this.props.financing.branch === null ? ' mgT' : ''}`} style={{color: '#616161'}}>Sucursal que atiende</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        {(this.props.financing.branch !== null && this.props.financing.financingPlan.financingEntity.branches.length > 0)
                            ?
                            <p><strong>{branches.filter((el) => { return el.id === this.props.financing.branch; })[0].branch_name}</strong>
                                {this.props.authUser.userType.id !== USERTYPES.CLIENT && 
                             <a className='btn btn-link btn-transparent' style={{marginLeft:'10px'}}
                                 onClick={() => this.setState({showAssignBranchModal: true, branchSelected: this.props.financing.branch})}>{'(Cambiar)'}</a>}
                            </p>
                            :
                            <button
                                disabled={branches.length === 0}
                                className={`${branches.length === 0 ? 'btn btn-disabled btn-transparent ' : 'btn btn-link btn-transparent'}`}
                                onClick={() => this.setState({showAssignBranchModal: true, branchSelected: null})}>
                            Asignar</button>}
                    </div>
                </React.Fragment>}
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Plan</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.name}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Cuotas</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.payments} X {payments}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Gastos de Cierre inicial</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{firstPayment}</p></strong>
                </div>
                <hr className="w-95" />
                <div className="col-md-6">
                    <p className="mgB0" style={{color: '#616161'}}>Adelanto</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <strong><p className="mgB0" style={{color: '#616161'}}>{(this.props.financing.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(this.props.financing.advancePayment / 100)}</p></strong>
                </div>
                {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                    <React.Fragment>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Tasa</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.charge * 100}%</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Fijo mensual</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{(this.props.financing.financingPlan.monthlyFixed * 100).toPrecision(4)}%</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Fijo mensual RD$</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{money(this.props.financing.financingPlan.monthlyFixedAmount)}</p></strong>
                        </div>
                    </React.Fragment>
                }
                {this.props.authUser.userType.id !== USERTYPES.CLIENT && this.props.financing.financingPlan.financingEntity.fromtoapply !== '' &&
                    <React.Fragment><div className="col-md-3"></div>
                        <div className="col-md-6 ">
                            <a className="text-decoration-none" rel="noreferrer" target="_blank" href={`/json/transactions/financings/${this.props.financing.id+'/'+this.props.financing.financingPlan.financingEntity.fromtoapply}/download`}>
                                <button
                                    className="btn btn-primary w-100">
                                    Descargar solicitud
                                </button>
                            </a>
                        </div>
                        <div className="col-md-3"></div>
                    </React.Fragment>
                }
            </div>
        );
    }

    render() {
        let profilevalid = (this.props.financing.livingType !==null && this.props.financing.workingTime !==null && this.props.financing.companyType !==null );
        this.setState({fullProfile: profilevalid});

        return (
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-md-6 mgB">
                        {this.renderLog()}
                    </div>
                    {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                        <div className="col-md-6 mgB">
                            {this.renderClientInfo()}
                        </div>
                    }
                    <div className={`${this.props.authUser.userType.id !== USERTYPES.CLIENT ? 'col-md-12' : 'col-md-6'} mgB`}>
                        {this.renderProductInfo()}
                        {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                            <div style={{maxHeight: '400px'}} className="col-md-12 product-detail-box mgT white-background">
                                {this.renderQuoteFile()}
                            </div>
                        }
                    </div>
                    <div className="col-md-6 mgB">
                        {this.renderPlanInfo()}
                    </div>
                    <div className="col-md-6 mgB">
                        {this.renderDocuments()}
                    </div>
                    <div className="col-12 mgB">
                        {([USERTYPES.ADMIN, USERTYPES.EMPLOYEE, USERTYPES.AGENT].indexOf(this.props.authUser.userType.id) > -1) && this.renderDocumentsToEntity()}
                    </div>
                </div>
                {this.renderWarningClientModal()}
                {this.renderWarningProductModal()}
                {this.renderEditClientModal()}
                {this.renderFinancingUpdateModal()}
                {this.renderSecondWarning()}
                {this.state.fullProfile && this.renderProfileClientModal()}
                {this.renderAssignBranche()}

                <input
                    id="quote-file-input"
                    type="file"
                    accept={".pdf,.png,.jpg,.jpeg"}
                    onChange={this.onFileChanged}
                    className="d-none"
                />
            </React.Fragment>
        );
    }
}

/**
 * Properties validations.
 */
FinancingDetailBody.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    financing: PropTypes.object
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingDetailBody);
