import * as PromotionsLabels from './promotions.enum';

export const setFilters = (filters) => ({type:PromotionsLabels.SET_FILTERS, payload:filters});
export const resetVariables = () => ({type:PromotionsLabels.RESET_VARIABLES_STATE_PROMOTION});

export const searchPromotions = (filters) => ({type: PromotionsLabels.SEARCH_PROMOTION, payload: filters});

export const searchPromotionsSuccess = (promotions) => ({type: PromotionsLabels.SEARCH_PROMOTION_SUCCESS, payload: promotions});

export const searchPromotionsFailure = (error) => ({type: PromotionsLabels.SEARCH_PROMOTION_FAILURE, payload: error});

export const searchPromotionsActivate = () => ({type: PromotionsLabels.SEARCH_PROMOTION_ACTIVATE});

export const searchPromotionsActivateSuccess = (promotions) => ({type: PromotionsLabels.SEARCH_PROMOTION_ACTIVATE_SUCCESS, payload: promotions});

export const searchPromotionsActivateFailure = (error) => ({type: PromotionsLabels.SEARCH_PROMOTION_ACTIVATE_FAILURE, payload: error});

export const searchPromotionsApplidByEntity = (params) => ({type: PromotionsLabels.SEARCH_PROMOTIONS_BY_ENTITY, payload: params});

export const createPromotion = (data) => ({type: PromotionsLabels.CREATE_PROMOTION, payload: data});

export const createPromotionSuccess = (promotion) => ({type: PromotionsLabels.CREATE_PROMOTION_SUCCESS, payload: promotion});

export const createPromotionFailure = (error) => ({type: PromotionsLabels.CREATE_PROMOTION_FAILURE, payload: error});

export const getPromotion = (id) => ({ type: PromotionsLabels.GET_PROMOTION, payload: id});

export const getPromotionSuccess = (promotion) => ({type: PromotionsLabels.GET_PROMOTION_SUCCESS, payload: promotion});

export const getPromotionFailure = (error) => ({type: PromotionsLabels.GET_PROMOTION_FAILURE, payload: error});

export const removePromotion = (id) => ({ type: PromotionsLabels.REMOVE_PROMOTION, payload: id});

export const removePromotionSuccess = (result) => ({type: PromotionsLabels.REMOVE_PROMOTION_SUCCESS, payload: result});

export const removePromotionFailure = (error) => ({type: PromotionsLabels.REMOVE_PROMOTION_FAILURE, payload: error});

export const activatePromotion = (id, val) => ({type: PromotionsLabels.ACTIVATE_PROMOTION, payload: {id: id, enabled: val}});

export const activatePromotionSuccess = (promotion) => ({type: PromotionsLabels.ACTIVATE_PROMOTION_SUCCESS, payload: promotion});

export const activatePromotionFailure = (error) => ({type: PromotionsLabels.ACTIVATE_PROMOTION_FAILURE, payload: error});

export const updatePromotion = (params, id) => ({type: PromotionsLabels.EDIT_PROMOTION, payload: {params:params, id:id}});

export const updatePromotionSuccess = (promotion) => ({type: PromotionsLabels.EDIT_PROMOTION_SUCCESS, payload: promotion});

export const updatePromotionFailure = (error) => ({type: PromotionsLabels.EDIT_PROMOTION_FAILURE, payload: error});

export const addCuponsPromotion = (params, id) => ({type: PromotionsLabels.ADD_CUPONS_PROMOTION, payload: {cupon:params, id:id}});

export const addCuponsPromotionSuccess = (cupons) => ({type: PromotionsLabels.ADD_CUPONS_PROMOTION_SUCCESS, payload: cupons});

export const addCuponsPromotionFailure = (error) => ({type: PromotionsLabels.ADD_CUPONS_PROMOTION_FAILURE, payload: error});

export const removeCuponsPromotion = (cuponid, id) => ({type: PromotionsLabels.REMOVE_CUPONS_PROMOTION, payload: {cuponid:cuponid, id:id}});

export const removeCuponsPromotionSuccess = (cupons) => ({type: PromotionsLabels.REMOVE_CUPONS_PROMOTION_SUCCESS, payload: cupons});

export const removeCuponsPromotionFailure = (error) => ({type: PromotionsLabels.REMOVE_CUPONS_PROMOTION_FAILURE, payload: error});

export const addRulesPromotion = (rule, id) => ({type: PromotionsLabels.ADD_RULES_PROMOTION, payload: {params:rule, id:id}});

export const addRulesPromotionSuccess = (promotion) => ({type: PromotionsLabels.ADD_RULES_PROMOTION_SUCCESS, payload: promotion});

export const addRulesPromotionFailure = (error) => ({type: PromotionsLabels.ADD_RULES_PROMOTION_FAILURE, payload: error});

export const updateRulesPromotion = (rule, id) => ({type: PromotionsLabels.UPDATE_RULES_PROMOTION, payload: {params:rule, id:id}});

export const updateRulesPromotionSuccess = (promotion) => ({type: PromotionsLabels.UPDATE_RULES_PROMOTION_SUCCESS, payload: promotion});

export const updateRulesPromotionFailure = (error) => ({type: PromotionsLabels.UPDATE_RULES_PROMOTION_FAILURE, payload: error});

export const removeRulesPromotion = (idrule, id) => ({type: PromotionsLabels.REMOVE_RULES_PROMOTION, payload: {idrule:idrule, id:id}});

export const removeRulesPromotionSuccess = (promotion) => ({type: PromotionsLabels.REMOVE_RULES_PROMOTION_SUCCESS, payload: promotion});

export const removeRulesPromotionFailure = (error) => ({type: PromotionsLabels.REMOVE_RULES_PROMOTION_FAILURE, payload: error});

export const getStores = () => ({ type: PromotionsLabels.GET_STORES});

export const getStoresSuccess = (stores) => ({type: PromotionsLabels.GET_STORES_SUCCESS, payload: stores});

export const getStoresFailure = (error) => ({type: PromotionsLabels.GET_STORES_FAILURE, payload: error});

/* Sku Operations */
export const searchSkues = (query) => ({ type: PromotionsLabels.SEARCH_SKUS, payload: query});

export const searchSkuesSuccess = (skues) => ({type: PromotionsLabels.SEARCH_SKUS_SUCCESS, payload: skues});

export const searchSkuesFailure = (error) => ({type: PromotionsLabels.SEARCH_SKUS_FAILURE, payload: error});

export const addSku = (data) => ({ type: PromotionsLabels.ADD_SKU, payload:data});

export const addSkuSuccess = (skues) => ({type: PromotionsLabels.ADD_SKU_SUCCESS, payload: skues});

export const addSkuFailure = (error) => ({type: PromotionsLabels.ADD_SKU_FAILURE, payload: error});

export const delSkues = (ids) => ({ type: PromotionsLabels.REMOVE_SKU, payload: {skues:ids}});

export const delSkuesSuccess = (skues) => ({type: PromotionsLabels.REMOVE_SKU_SUCCESS, payload: skues});

export const delSkuesFailure = (error) => ({type: PromotionsLabels.REMOVE_SKU_FAILURE, payload: error});

export const updateSku = (data, id) => ({ type: PromotionsLabels.UPDATE_SKU, payload:{params: data, id:id}});

export const updateSkuSuccess = (skues) => ({type: PromotionsLabels.UPDATE_SKU_SUCCESS, payload: skues});

export const updateSkuFailure = (error) => ({type: PromotionsLabels.UPDATE_SKU_FAILURE, payload: error});

