'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import LoadingComponent from 'common/components/loading';
import EmptyState from 'common/components/empty-state';

import FinancingsNotcompletedHeader from './financings-notcompleted-header';
import FinancingsNotcompletedDetail from './financings-notcomplete-detail';

import { IDENTIFICATION_TYPES} from 'common/constants/enums.constant';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            dropdowns: userSelectors.getDropdowns(state),
            registerUserDropdowns: userSelectors.getRegisterDropdowns(state),
            tempFinancing: transactionSelectors.getTempFinancing(state),
            tempFinancingDeleted: transactionSelectors.getTempFinancingDelete(state),
            financingCreated: transactionSelectors.getFinancingCreated(state),
            loading: transactionSelectors.getIsLoading(state),
            error: transactionSelectors.getError(state),
            isMobile: window.matchMedia('(max-width: 640px)').matches
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        },
        getRegisterUserDropdowns: () => {
            dispatch(userActions.getRegisterUserDropdowns());
        },
        getFinancingsNotCompleted: (externalId) => {
            dispatch(transactionActions.getTempFinancing(externalId));
        },
        createFinancings: (params) => {
            dispatch(transactionActions.createFinancingFromTemp(params));
        },
        deleteFinancings: (externalId) => {
            dispatch(transactionActions.delTempFinancing(externalId));
        },
        resetFinancingM: () => {
            dispatch(transactionActions.resetFinancingMessages());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingsListNotCompletedContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            error: null,
            profileCompleted: false,
            provinceComplete: false,
            financing: {
                externalOrderId: null,
                name: '',
                lastname: '',
                identificationType: IDENTIFICATION_TYPES.CEDULA,
                identification: '',
                identificationFile: null,
                identificationFile2: null,
                advancePayment: '',
                currencyId: null,
                financingTotal: null,
                email: '',
                phone: '',
                postalCode: '',
                street: '',
                city: '',
                provinceName: '',
                province: null,
                country: 1,
                livingType: null,
                livingTime: null,
                financingEntity: null,
                plan: null,
                cart: null,
                isMarried: null,
                spouseName: '',
                spouseIdentificationType: IDENTIFICATION_TYPES.CEDULA,
                spouseIdentification: '',
                spousePhone: '',
                relativeName: '',
                relativePhone: '',
                companyName: '',
                companyType: null,
                companyStreet: '',
                companyPhone: '',
                companyTitle: '',
                salary: '',
                otherIncomes: '',
                workingTime: null,
                approval: null,
                optionalFields: JSON.stringify({fields:[]})
            }
        };
    }


    componentDidUpdate() {
        if (this.props.tempFinancingDeleted || this.props.financingCreated) {
            this.props.history.goBack();
        }
    }

    componentDidMount() {
        this.props.resetFinancingM();
        const id = this.props.match.params.id;
        if (!id) {
            this.setState({error: 'Hay un error en la página.'});
        } else {
            this.props.getFinancingsNotCompleted(id);
        }

        if (this.props.dropdowns.stores.length === 0) {
            this.props.getDropdowns();
        }

        if (this.props.registerUserDropdowns.countries.length === 0) {
            this.props.getRegisterUserDropdowns();
        }


        let dataFields = Object.assign({}, this.state.financing, this.props.tempFinancing);
        this.setState({financing: dataFields});
    }

    setDataProfile(data) {
        let datafields = Object.assign({}, this.state.financing, data);

        this.setState({financing: datafields, profileCompleted: true});
    }

    setProvince = (data) => {
        let datafields = Object.assign({}, this.state.financing, data);
        this.setState({financing: datafields, provinceComplete: true});
    };

    createFinancing = () => {
        this.props.createFinancings(this.state.financing);
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {!this.props.loading &&
                this.props.tempFinancing &&
                  <React.Fragment
                      key='financing-detail'>
                      <div className="layout-body__header pdB0">
                          <FinancingsNotcompletedHeader
                              financingTemp={this.props.tempFinancing}
                              history={this.props.history}
                              onDelete={this.props.deleteFinancings}
                              onCreate={this.createFinancing}
                              resetDataM={this.props.resetFinancingM}
                              profileCompleted={this.state.profileCompleted}
                              provinceComplete={this.state.provinceComplete}
                              isMobile={this.props.isMobile}
                          />
                          <hr className="w-100 mgT3 mgB2"/>
                      </div>
                      <div className="layout-body__content">
                          <FinancingsNotcompletedDetail
                              financingTemp={this.props.tempFinancing}
                              history={this.props.history}
                              dropdowns={this.props.registerUserDropdowns}
                              setProvince={this.setProvince}
                              onSubmit={(data) => this.setDataProfile(data)}
                              isMobile={this.props.isMobile}
                          />
                      </div>
                  </React.Fragment>
                }
            </div>
        );
    }
}

FinancingsListNotCompletedContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingsListNotCompletedContainer));