import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';

import {
    TextInput,
    PasswordInput,
    FormsyInputWrapper,
    Checkbox
} from 'common/components/inputs';

import { EMAIL_ICON, PASSWORD_ICON } from 'common/constants/icon.constant';

/**
 * Component responsible of rendering the login form.
 */
export default class LoginForm extends React.Component {
    /**
     * Initializes the form.
     */
    constructor(props) {
        super(props);

        this.state = { canSubmit: false };

        this.changeEmail = this.changeField.bind(this, 'email');
        this.changePassword = this.changeField.bind(this, 'password');
        this.changeRememberMe = this.changeField.bind(this, 'rememberMe');

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && prevProps.error !== this.props.error) {
            this.disableSubmit();
        }
    }

    /**
     * Fired once any form input changes.
     */
    changeField(field, value) {
        this.props.onFormChange(field, value);
    }

    /**
     * Fired after formsy validations
     */
    canSubmit(canSubmit) {
        this.setState({canSubmit});
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                onValidSubmit={this.props.onLogin}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <FormsyInputWrapper
                    required
                    className="col-12 mgB2 text-left"
                    name="email"
                    customError={this.props.validateEmailError}
                    value={this.props.email}>
                    <TextInput
                        icon={EMAIL_ICON}
                        dangerLabel={this.props.validateEmailError !== null}
                        onBlur={() => this.props.onValidateEmail(this.props.email)}
                        label="Correo electrónico"
                        value={this.props.email}
                        onChange={this.changeEmail}
                    />
                </FormsyInputWrapper>
                <FormsyInputWrapper
                    required
                    className="col-12 mgB2 text-left"
                    name="password"
                    value={this.props.password}>

                    <PasswordInput
                        icon={PASSWORD_ICON}
                        label="Contraseña"
                        dangerLabel={this.props.error !== null}
                        value={this.props.password}
                        onChange={this.changePassword}
                    />
                </FormsyInputWrapper>
                <div className="clearfix" />
                {this.props.error  && 
                    <div className="text-center ">
                        <p style={{color:'red'}}>{this.props.error}</p>
                    </div>
                }
                <FormsyInputWrapper
                    className="col-12 mgB2"
                    name="rememberMe"
                    value={this.props.rememberMe}>
                    <Checkbox
                        squared={true}
                        label="Recordar mis datos"
                        checked={this.props.rememberMe}
                        value={true}
                        onChange={() => this.changeRememberMe(!this.props.rememberMe)}/>
                </FormsyInputWrapper>
                <div className="clearfix" />

                <div className="text-center mgB2">
                    <Link to={"/forgot-password"+this.props.location.search}>¿Olvidaste tu contraseña?</Link>
                </div>

                <div className="col-12 text-center mgB">
                    <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={!this.state.canSubmit}>
                        Iniciar sesión
                    </button>
                </div>

                {/* <div className="col-12 text-center mgB">
                    ¿Aun no tienes cuenta?
                    <Link to={"/register"+this.props.location.search}> Regístrate</Link>
                </div> */}
            </Formsy>
        );
    }
}

/**
 * Login form properties validation.
 */
LoginForm.propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onLogin: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onFormChange: PropTypes.func
};
