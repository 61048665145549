import 'rxjs';
import 'rxjs/add/observable/of';
import { of } from 'rxjs/observable/of';
import {
    mergeMap,
    catchError,
    map,
    takeUntil
} from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntityActionsLabels from 'common/store/actions/financing-entity/financing-entity.enum';
import * as financingEntityRequests from 'common/store/services/financing-entity.service';

export const createFinancingEntityEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.CREATE_FINANCING_ENTITY),
        mergeMap(action =>
            financingEntityRequests.createFinancingEntityRequest(action.payload).pipe(
                map((result) => financingEntityActions.createFinancingEntitySuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.createFinancingEntityFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.createFinancingEntityFailure(error.response)
                    );
                })
            )
        )
    );

export const updateFinancingEntityEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.UPDATE_FINANCING_ENTITY),
        mergeMap(action =>
            financingEntityRequests.updateFinancingEntityRequest(action.payload.id, action.payload.data).pipe(
                map((result) => financingEntityActions.updateFinancingEntitySuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.updateFinancingEntityFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.updateFinancingEntityFailure(error.response)
                    );
                })
            )
        )
    );

export const updatePlanEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.UPDATE_PLAN),
        mergeMap(action =>
            financingEntityRequests.updatePlanRequest(action.payload.id, action.payload.data).pipe(
                map((result) => financingEntityActions.updatePlanSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.updatePlanFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.updatePlanFailure(error.response)
                    );
                })
            )
        )
    );

export const addPlansEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.ADD_PLANS),
        mergeMap(action =>
            financingEntityRequests.addPlansRequest(action.payload.id, action.payload.data).pipe(
                map((result) => financingEntityActions.addPlansSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.addPlansFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.addPlansFailure(error.response)
                    );
                })
            )
        )
    );

export const bulkEnablePlansEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.BULK_ENABLE_PLANS),
        mergeMap(action =>
            financingEntityRequests.bulkEnablePlansRequest(action.payload.id, action.payload.ids, action.payload.value).pipe(
                map((result) => financingEntityActions.bulkEnablePlansSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.bulkEnablePlansFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.bulkEnablePlansFailure(error.response)
                    );
                })
            )
        )
    );

export const bulkDeletePlansEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.BULK_DELETE_PLANS),
        mergeMap(action =>
            financingEntityRequests.bulkDeletePlansRequest(action.payload.id, action.payload.ids).pipe(
                map((result) => financingEntityActions.bulkDeletePlansSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.bulkDeletePlansFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.bulkDeletePlansFailure(error.response)
                    );
                })
            )
        )
    );

export const copyPlansEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.COPY_PLANS),
        mergeMap(action =>
            financingEntityRequests.copyPlansRequest(action.payload.id, action.payload.ids).pipe(
                map((result) => financingEntityActions.copyPlansSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.copyPlansFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.copyPlansFailure(error.response)
                    );
                })
            )
        )
    );

export const deleteFinancingEntityEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.DELETE_FINANCING_ENTITY),
        mergeMap(action =>
            financingEntityRequests.deleteFinancingEntityRequest(action.payload).pipe(
                map(() => financingEntityActions.deleteFinancingEntitySuccess()),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.deleteFinancingEntityFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.deleteFinancingEntityFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingEntityEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.GET_FINANCING_ENTITY),
        mergeMap(action =>
            financingEntityRequests.getFinancingEntityRequest(action.payload).pipe(
                map((result) => financingEntityActions.getFinancingEntitySuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.getFinancingEntityFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.getFinancingEntityFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingEntitiesEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.GET_FINANCING_ENTITIES),
        mergeMap(action =>
            financingEntityRequests.getFinancingEntitiesRequest(action.payload).pipe(
                map((result) => financingEntityActions.getFinancingEntitiesSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.getFinancingEntitiesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.getFinancingEntitiesFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingEntitiesListEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.GET_FINANCING_ENTITIES_LIST),
        mergeMap(action =>
            financingEntityRequests.getFinancingEntitiesRequest(action.payload).pipe(
                map((result) => financingEntityActions.getFinancingEntitiesListSuccess(result)),
                takeUntil(action$.pipe(
                    ofType(financingEntityActionsLabels.CANCEL_GET_FINANCING_ENTITIES)
                )),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.getFinancingEntitiesListFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.getFinancingEntitiesListFailure(error.response)
                    );
                })
            )
        )
    );

export const activateFinancingEntityEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.ACTIVATE_FINANCING_ENTITY),
        mergeMap(action =>
            financingEntityRequests.activateFinancingEntityRequest(action.payload.id, action.payload.enabled).pipe(
                map((result) => financingEntityActions.activateFinancingEntitySuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.activateFinancingEntityFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.activateFinancingEntityFailure(error.response)
                    );
                })
            )
        )
    );

export const getFinancingEntityProvincesCitiesEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.GET_PROVINCES_CITIES),
        mergeMap(()=>
            financingEntityRequests.getFinancingEntityProvincesCitiesRequest().pipe(
                map((result) => financingEntityActions.getProvinceCitiesSuccess(result)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.getProvinceCitiesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.getProvinceCitiesFailure(error.response)
                    );
                })
            )
        )
    );

export const addFinancingEntityBrancheEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.ADD_BRANCHE),
        mergeMap(action =>
            financingEntityRequests.addFinancingEntityBrancheRequest(action.payload.data, action.payload.id_entity).pipe(
                map((result) => financingEntityActions.createBrancheSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.createBrancheFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.createBrancheFailure(error.response)
                    );
                })
            )
        )
    );

export const bulkDeleteBranchesEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.DELETE_BRANCHES),
        mergeMap(action =>
            financingEntityRequests.bulkDeleteBranchesRequest(action.payload.id_entity, action.payload.Ids).pipe(
                map((result) => financingEntityActions.deleteBranchesSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.deleteBranchesFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.deleteBranchesFailure(error.response)
                    );
                })
            )
        )
    );

export const updateBranchEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.UPDATE_BRANCHE),
        mergeMap(action =>
            financingEntityRequests.updateBranchRequest(action.payload.id_entity, action.payload.data).pipe(
                map((result) => financingEntityActions.updateBranchSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.updateBranchFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.updateBranchFailure(error.response)
                    );
                })
            )
        )
    );

export const addFinancingEntityBinEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.ADD_BIN),
        mergeMap(action =>
            financingEntityRequests.addFinancingEntityBinRequest(action.payload.data, action.payload.idEntity).pipe(
                map((result) => financingEntityActions.appendBINSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.appendBINFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.appendBINFailure(error.response)
                    );
                })
            )
        )
    );

export const enabledFinancingEntityBinesEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.ENABLED_BINES),
        mergeMap(action =>
            financingEntityRequests.enabledBinesRequest(action.payload.data, action.payload.idEntity).pipe(
                map((result) => financingEntityActions.enabledBINESSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.enabledBINESFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.enabledBINESFailure(error.response)
                    );
                })
            )
        )
    );

export const deleteFinancingEntityBinesEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.DELETE_BINES),
        mergeMap(action =>
            financingEntityRequests.deleteBinesRequest(action.payload.data, action.payload.idEntity).pipe(
                map((result) => financingEntityActions.deleteBINESSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.deleteBINESFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.deleteBINESFailure(error.response)
                    );
                })
            )
        )
    );

export const agregatePlanstoBinEpic = (action$) =>
    action$.pipe(
        ofType(financingEntityActionsLabels.PLANS_TO_BIN),
        mergeMap(action =>
            financingEntityRequests.plansToBinsRequest(action.payload.data, action.payload.idEntity).pipe(
                map((result) => financingEntityActions.plansToBinSuccess(result.response)),
                catchError(error => {
                    if (error.status === 403) {
                        return of(
                            financingEntityActions.plansToBinFailure('No tiene permisos para realizar esta acción')
                        );
                    }
                    if (error.status === 401) {
                        location.reload();
                    }
                    return of(
                        financingEntityActions.plansToBinFailure(error.response)
                    );
                })
            )
        )
    );

