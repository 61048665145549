import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { CLOSE_ICON } from 'common/constants/icon.constant';

/**
 * A simple modal to confirm an user's action.
 */
export default class Modal extends React.Component {

    render() {
        return (
            <div>
                <ReactModal
                    style={{ content: this.props.style }}
                    className={`modal__content ${this.props.className}`}
                    overlayClassName={`modal__overlay ${this.props.overlayClassName}`}
                    bodyOpenClassName={`modal--is-open`}
                    id={this.props.id}
                    isOpen={this.props.showModal}
                    ariaHideApp={false}
                    contentLabel="Minimal Modal Example">
                    {this.props.showHeader &&
                        <div className="modal-header-container">
                            <div className="row">
                                <div className="align-center col-10 ">
                                    <h3 className="modal-title">{this.props.title}</h3>
                                </div>
                                {this.props.closable &&
                                    <div className="col-2 text-right">
                                        <span style={{ fontSize: '26px' }} className="cursor-pointer close-button" onClick={this.props.onModalClose}><i className={CLOSE_ICON}></i></span>
                                    </div>
                                }
                            </div>
                            {!this.props.noDivider &&
                                <hr className="header-divider" />
                            }
                        </div>
                    }
                    <div>
                        {this.props.children}
                    </div>
                </ReactModal>
            </div>
        );
    }

}

Modal.defaultProps = {
    className: '',
    showModal: false,
    overlayClassName: '',
    showHeader: true,
    closable: true,
    noDivider: false
};

/**
 * Modal properties validation.
 */
Modal.propTypes = {
    onModalClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    showModal: PropTypes.bool,
    showHeader: PropTypes.bool,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    bodyOpenClassName: PropTypes.string,
    onSuccess: PropTypes.func
};
