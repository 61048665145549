import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';

export default class LoadingComponent extends React.Component {

    render() {
        return (
            <div style={this.props.style} className="layout-body__content loading">
            <ReactLoading
                type={'spin'}
                color={'#124B6F'}
                height={this.props.height}
                width={this.props.width} />
            </div>
        );
    }

}

LoadingComponent.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
};
