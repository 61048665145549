'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import * as userSelectors from 'common/store/selectors/user.selector';

import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitiySelector from 'common/store/selectors/financing-entity.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import formatQueryString from 'common/helpers/format-query-string';

import { DEFAULT_FINANCING_ENTITIES_FILTERS } from 'common/constants/objects.constant';


import FinancingEntityListView from './financing-entity-list-view';
import FinancingEntityListHeader from './financing-entity-list-header';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            financingEntities: financingEntitiySelector.getFinancingEntitiesList(state),
            pagination: financingEntitiySelector.getPagination(state),
            filters: financingEntitiySelector.getEntitiesFilters(state),
            loading: financingEntitiySelector.getIsLoading(state),
            error: financingEntitiySelector.getError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancingEntities: (filters) => {
            dispatch(financingEntityActions.getFinancingEntitiesList(filters));
        },
        setFilters: (filters) => {
            dispatch(financingEntityActions.setEntitiesFilters(filters));
        },
        cancelGetFinancingEntities: () => {
            dispatch(financingEntityActions.cancelGetFinancingEntities());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingEntityListContainer extends React.Component {

    componentDidMount() {
        let filters = DEFAULT_FINANCING_ENTITIES_FILTERS;
        filters.enabled = null;

        if (this.props.location.search !== '') {
            let queryObject = queryString.parse(this.props.location.search, {parseNumbers: true});
            filters = Object.assign(filters, queryObject);
        }

        if (!Array.isArray(filters.type)) {
            if (typeof filters.type === 'string') {
                filters.type = filters.type.split(',').map(el => parseInt(el));
            } else {
                filters.type = [filters.type];
            }
        }

        this.setFilters(filters);
    }

    onFiltersChange = (filter, value) => {
        let filters = {... this.props.filters};
        filters[filter] = value;

        this.setFilters(filters);
    };

    setFilters = (filters) => {
        this.props.cancelGetFinancingEntities();

        let queryString = formatQueryString(filters);

        this.props.history.replace('/financing-entities?' + queryString);

        this.props.setFilters(filters);

        let newFilters = {...filters};

        if (filters.type.length === 0) {
            delete newFilters['type'];
        }

        this.props.getFinancingEntities(newFilters);
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                <div className="pdB0 layout-body__header">
                    <FinancingEntityListHeader
                        total={this.props.pagination.total}
                        filters={this.props.filters}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        setFilters={this.setFilters}
                        onFiltersChange={this.onFiltersChange}
                    />
                </div>
                {!this.props.loading &&
                    this.props.financingEntities.length > 0 &&
                    !this.props.error &&
                    <FinancingEntityListView
                        financingEntities={this.props.financingEntities}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        filters={this.props.filters}
                        onFiltersChange={this.onFiltersChange}
                        pagination={this.props.pagination}
                    />
                }
                {!this.props.loading &&
                    this.props.financingEntities.length < 1 &&
                    !this.props.error &&
                    <EmptyState
                        imgStyle={
                            {
                                backgroundColor: 'rgba(0, 125, 195, 0.08)',
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/ic_search.svg"
                        title=""
                        text={this.props.filters.q === '' ? 'No se encontraron resultados para la búsqueda.' : () => <h3 className="empty-state__headline">No se encontraron resultados de búsqueda con <strong>{this.props.filters.q}</strong></h3>}
                    />
                }
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
            </div>
        );
    }
}

FinancingEntityListContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingEntityListContainer));
