
//TRANSACTION ENUM ACTIONS

export const VERIFY_ORDER_TOKEN = 'VERIFY_ORDER_TOKEN';
export const VERIFY_ORDER_TOKEN_SUCCESS = 'VERIFY_ORDER_TOKEN_SUCCESS';
export const VERIFY_ORDER_TOKEN_FAILURE = 'VERIFY_ORDER_TOKEN_FAILURE';

export const GET_ALL_ENTITIES = 'GET_ALL_ENTITIES';
export const GET_ALL_ENTITIES_SUCCESS = 'GET_ALL_ENTITIES_SUCCESS';
export const GET_ALL_ENTITIES_FAILURE = 'GET_ALL_ENTITIES_FAILURE';

export const SET_FINANCING_FORM_DATA = 'SET_FINANCING_FORM_DATA';
export const SET_FINANCING_TEMP_DATA = 'SET_FINANCING_TEMP_DATA';
export const RESET_FINANCING_FORM_DATA = 'RESET_FINANCING_FORM_DATA';

export const RESET_FINANCING_MESSAGES = 'RESET_FINANCING_MESSAGES';
export const DATA_PAYMENT_DISCOUNT = 'DATA_PAYMENT_DISCOUNT';

export const CREATE_FINANCING = 'CREATE_FINANCING';
export const CREATE_FINANCING_SUCCESS = 'CREATE_FINANCING_SUCCESS';
export const CREATE_FINANCING_FAILURE = 'CREATE_FINANCING_FAILURE';

export const CREATE_FINANCING_FROM_TEMP = 'CREATE_FINANCING_FROM_TEMP';
export const CREATE_FINANCING_FROM_TEMP_SUCCESS = 'CREATE_FINANCING_FROM_TEMP_SUCCESS';
export const CREATE_FINANCING_FROM_TEMP_FAILURE = 'CREATE_FINANCING_FROM_TEMP_FAILURE';

export const SET_CREATING_FINANCING = 'SET_CREATING_FINANCING';

export const GET_FINANCINGS = 'GET_FINANCINGS';
export const GET_FINANCINGS_SUCCESS = 'GET_FINANCINGS_SUCCESS';
export const GET_FINANCINGS_FAILURE = 'GET_FINANCINGS_FAILURE';

export const SET_FINANCINGS_FILTERS = 'SET_FINANCINGS_FILTERS';
export const CANCEL_GET_FINANCINGS = 'CANCEL_GET_FINANCINGS';

export const GET_FINANCING = 'GET_FINANCING';
export const GET_FINANCING_SUCCESS = 'GET_FINANCING_SUCCESS';
export const GET_FINANCING_FAILURE = 'GET_FINANCING_FAILURE';

export const CLEAR_FINANCING_PROFILE = 'CLEAR_FINANCING_PROFILE';

export const CANCEL_FINANCING = 'CANCEL_FINANCING';
export const CANCEL_FINANCING_SUCCESS = 'CANCEL_FINANCING_SUCCESS';
export const CANCEL_FINANCING_FAILURE = 'CANCEL_FINANCING_FAILURE';

export const APPROVE_FINANCING = 'APPROVE_FINANCING';
export const APPROVE_FINANCING_SUCCESS = 'APPROVE_FINANCING_SUCCESS';
export const APPROVE_FINANCING_FAILURE = 'APPROVE_FINANCING_FAILURE';

export const SIGN_FINANCING = 'SIGN_FINANCING';
export const SIGN_FINANCING_SUCCESS = 'SIGN_FINANCING_SUCCESS';
export const SIGN_FINANCING_FAILURE = 'SIGN_FINANCING_FAILURE';

export const RESEND_FINANCING = 'RESEND_FINANCING';
export const RESEND_FINANCING_SUCCESS = 'RESEND_FINANCING_SUCCESS';
export const RESEND_FINANCING_FAILURE = 'RESEND_FINANCING_FAILURE';

export const CLEAR_MODAL_FINANCING = 'CLEAR_MODAL_FINANCING';

export const CHANGE_FINANCING_STATUS = 'CHANGE_FINANCING_STATUS';
export const CHANGE_FINANCING_STATUS_SUCCESS = 'CHANGE_FINANCING_STATUS_SUCCESS';
export const CHANGE_FINANCING_STATUS_FAILURE = 'CHANGE_FINANCING_STATUS_FAILURE';

export const DECLINE_FINANCING = 'DECLINE_FINANCING';
export const DECLINE_FINANCING_SUCCESS = 'DECLINE_FINANCING_SUCCESS';
export const DECLINE_FINANCING_FAILURE = 'DECLINE_FINANCING_FAILURE';

export const SELECT_PLAN_TO_COMPARE = 'SELECT_PLAN_TO_COMPARE';
export const RESET_PLANS_TO_COMPARE = 'RESET_PLANS_TO_COMPARE';

export const SET_FINANCING_ENTITY_TYPE = 'SET_FINANCING_ENTITY_TYPE';

export const GET_ALL_FINANCING_ENTITY_TYPES = 'GET_ALL_FINANCING_ENTITY_TYPES';
export const GET_ALL_FINANCING_ENTITY_TYPES_SUCCESS = 'GET_ALL_FINANCING_ENTITY_TYPES_SUCCESS';
export const GET_ALL_FINANCING_ENTITY_TYPES_FAILURE = 'GET_ALL_FINANCING_ENTITY_TYPES_FAILURE';

export const GET_FINANCING_ACTIONS = 'GET_FINANCING_ACTIONS';
export const GET_FINANCING_ACTIONS_SUCCESS = 'GET_FINANCING_ACTIONS_SUCCESS';
export const GET_FINANCING_ACTIONS_FAILURE = 'GET_FINANCING_ACTIONS_FAILURE';

export const UPDATE_FINANCING = 'UPDATE_FINANCING';
export const UPDATE_FINANCING_SUCCESS = 'UPDATE_FINANCING_SUCCESS';
export const UPDATE_FINANCING_FAILURE = 'UPDATE_FINANCING_FAILURE';

export const UPLOAD_QUOTE = 'UPLOAD_QUOTE';
export const UPLOAD_QUOTE_SUCCESS = 'UPLOAD_QUOTE_SUCCESS';
export const UPLOAD_QUOTE_FAILURE = 'UPLOAD_QUOTE_FAILURE';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILURE = 'REMOVE_DOCUMENT_FAILURE';

export const SELL_CONFIRM = 'SELL_CONFIRM';
export const SELL_CONFIRM_SUCCESS = 'SELL_CONFIRM_SUCCESS';
export const SELL_CONFIRM_FAILURE = 'SELL_CONFIRM_FAILURE';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';


export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

export const GET_FINANCINGS_TOTAL = 'GET_FINANCINGS_TOTAL';
export const GET_FINANCINGS_TOTAL_SUCCESS = 'GET_FINANCINGS_TOTAL_SUCCESS';
export const GET_FINANCINGS_TOTAL_FAILURE = 'GET_FINANCINGS_TOTAL_FAILURE';

export const GET_TEMP_FINANCING = 'GET_TEMP_FINANCING';
export const GET_TEMP_FINANCING_SUCCESS = 'GET_TEMP_FINANCING_SUCCESS';
export const GET_TEMP_FINANCING_FAILURE = 'GET_TEMP_FINANCING_FAILURE';

export const CREATE_TEMP_FINANCING = 'CREATE_TEMP_FINANCING';
export const CREATE_TEMP_FINANCING_SUCCESS = 'CREATE_TEMP_FINANCING_SUCCESS';
export const CREATE_TEMP_FINANCING_FAILURE = 'CREATE_TEMP_FINANCING_FAILURE';

export const DELETE_TEMP_FINANCING = 'DELETE_TEMP_FINANCING';
export const DELETE_TEMP_FINANCING_SUCCESS = 'DELETE_TEMP_FINANCING_SUCCESS';
export const DELETE_TEMP_FINANCING_FAILURE = 'DELETE_TEMP_FINANCING_FAILURE';

export const UNDO_APPROVE_FINANCING = 'UNDO_APPROVE_FINANCING';
export const UNDO_APPROVE_FINANCING_SUCCESS = 'UNDO_APPROVE_FINANCING_SUCCESS';
export const UNDO_APPROVE_FINANCING_FAILURE = 'UNDO_APPROVE_FINANCING_FAILURE';

export const CREATE_CARD_FINANCING = 'CREATE_CARD_FINANCING';
export const CREATE_CARD_FINANCING_SUCCESS = 'CREATE_CARD_FINANCING_SUCCESS';
export const CREATE_CARD_FINANCING_FAILURE = 'CREATE_CARD_FINANCING_FAILURE';

export const REFUND_CARD_FINANCING = 'REFUND_CARD_FINANCING';
export const REFUND_CARD_FINANCING_SUCCESS = 'REFUND_CARD_FINANCING_SUCCESS';
export const REFUND_CARD_FINANCING_FAILURE = 'REFUND_CARD_FINANCING_FAILURE';

export const SEARCH_CARD_FINANCING = 'SEARCH_CARD_FINANCING';
export const SEARCH_CARD_FINANCING_SUCCESS = 'SEARCH_CARD_FINANCING_SUCCESS';
export const SEARCH_CARD_FINANCING_FAILURE = 'SEARCH_CARD_FINANCING_FAILURE';

export const ASSIGN_BRANCH_FINANCING = 'ASSIGN_BRANCH_FINANCING';
export const ASSIGN_BRANCH_FINANCING_SUCCESS = 'ASSIGN_BRANCH_FINANCING_SUCCESS';
export const ASSIGN_BRANCH_FINANCING_FAILURE = 'ASSIGN_BRANCH_FINANCING_FAILURE';

export const CANCEL_CHECKOUT = 'CANCEL_CHECKOUT';
export const CANCEL_CHECKOUT_SUCCESS = 'CANCEL_CHECKOUT_SUCCESS';
export const CANCEL_CHECKOUT_FAILURE = 'CANCEL_CHECKOUT_FAILURE';

