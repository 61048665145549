import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';


export default class SidebarLink extends React.Component {

    isActive = (path, match, location) => {
        return !!(match || path === location.pathname);
    };

    render() {
        return (
            <li className="sidebar__item">
                <NavLink
                    onClick={() => {
                        if (location.pathname === this.props.href) {
                            this.props.history.go(0);
                        }
                    }}
                    exact
                    to={this.props.href}
                    isActive={(match, location) => this.isActive(this.props.href, match, location)}
                    className="sidebar__link"
                    activeClassName="sidebar__link--active">
                    <i className={ `sidebar__link__icon ${this.props.icon}` }></i> <span className="sidebar__link__text">{ this.props.title }</span>
                </NavLink>
            </li>
        );
    }
}


SidebarLink.propTypes = {
    exact: PropTypes.bool,
    title: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string
};
