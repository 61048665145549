import React from 'react';
import PropTypes from 'prop-types';

import MaskedInput from 'common/components/inputs/masked-input';

/**
 * Represents a simple phone input.
 */
export default class PhoneInput extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props}  the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = { labelFocused: false };

        this.onFocus = this.changeFocus.bind(this, true);
        this.onBlur = this.changeFocus.bind(this, false);
        this.onTextChange = this.onTextChange.bind(this);
    }

   /* onBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value);
        }
        this.changeFocus(false);
    };

    onFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e.target.value);
        }
        this.changeFocus(true);
    };*/

    /**
     * Fired once the input focus changes.
     *
     * @param {bool} labelFocused determines if the element is focused or not.
     */
    changeFocus(labelFocused) {
        
        this.setState({ labelFocused });
    }

    /**
     * Fired once the input text changes.
     *
     * @param {Proxy.dispatcher} event  contains the input's new value.
     */
    onTextChange(event) {
        if (this.props.onChange) {
            let value = event.target.value.replace(/[^0-9]/g, '');
            this.props.onChange(value);
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <label className="form-label">
                <span className={`label text-truncate ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                    {this.props.icon && (
                        <span className="fl__icon fl__icon--left">
                            <i className={this.props.icon} />
                        </span>
                    )}
                    {this.props.label}
                </span>
                <MaskedInput
                    disabled={this.props.disabled}
                    name={this.props.name}
                    mask="(111) 111-1111"
                    tabIndex={this.props.tabIndex}
                    className="form-control "
                    onFocus={this.onFocus}
                    placeholder={this.props.placeholder}
                    onBlur={this.onBlur}
                    value={this.props.value}
                    onChange={this.onTextChange}
                />
            </label>
        );
    }
}

/**
 * Phone input properties validation.
 */
PhoneInput.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};
