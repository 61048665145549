'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import DataTable from "common/components/datatable";

import {
    USERS_PER_PAGE,
    CURRENCIES_IDS
} from 'common/constants/enums.constant';

import money from 'common/helpers/money';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class FinancingsListTempView extends React.PureComponent {

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    /**
     * Renders each cell in the table.
     *
     * @param  {object} cell        the corresponding cell's object.
     * @return {ReactComponent}    our custom-made cell component.
     */
    matchFeaturedRow = (state, rowInfo) => {
        if (!rowInfo) {
            return {};
        }
        const financing = rowInfo.original;
        return {
            onClick: () => {
                this.props.history.push('financings/notcompleted/' + financing.external_id);
            }
        };
    };

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const data=this.props.financings;
        // const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        let columns = [
            {
                Header: this.renderHeader('Fecha'),
                accessor: 'created_at',
                Cell: ({ original }) => {
                    return(
                        <span style={{fontSize:'14px'}}> {moment.utc(original.created_at).format('DD/MM/YYYY')} </span>
                    );
                },
                width: 110
            },
            {
                Header: this.renderHeader('Orden en Tienda'),
                accessor: 'id',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.external_id} </span>
                    );

                },
                width: 150
            },
            {
                Header: this.renderHeader('Nombre del cliente'),
                accessor: 'name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original['name'] + ' '+ original['lastname']} </span>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Teléfono'),
                accessor: 'phone',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original['phone']} </span>
                    );
                },
                width: 130
            },
            {
                Header: this.renderHeader('Monto'),
                Cell: ({ original }) => {
                    let total = _.sumBy(original.order_external.items, (elem) => { return elem.count * elem.price; }) / 100 + original.order_external.deliveryCost / 100;
                    let taxes = total * parseFloat(process.env.MIX_TAXES);
                    return (
                        <span style={{fontSize:'14px'}}> {(parseInt(original.order_external.currencyId) === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(total + taxes)} </span>
                    );
                },
                width: 160
            },
            {
                Header: this.renderHeader('Entidad financiera'),
                accessor: 'financingPlan.financingEntity.name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.financing_plan.financing_entity.name} </span>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Plazo'),
                accessor: 'financingPlan.payments',
                Cell: ({ original }) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.financing_plan.name} </span>
                    );
                },
                width: 152
            }
        ];
        return (
            <div className={`layout-body__content`} >
                <div className="row">
                    <div data-for='financing-tooltip' className="col-12">
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-users"
                            getTrProps={this.matchFeaturedRow}
                            //page={this.props.page}
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
FinancingsListTempView.propTypes = {
    //onFiltersChange: PropTypes.func,
    financings: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
