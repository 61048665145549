'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRange } from 'react-date-range';

import {
    SelectInput,
    TextInput
} from 'common/components/inputs';

import { CALENDAR_ICON } from 'common/constants/icon.constant';

class DashboardHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state={
            rangeDate:[
                {
                    startDate: moment(this.props.dateFilters.from).toDate(),
                    endDate: moment(this.props.dateFilters.to).toDate(),
                    key: 'selection'
                }
            ],
            dayformated: moment(this.props.dateFilters.from).format('DD/MM/yyyy') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/yyyy'),
            monthselected: this.props.monthSelected,
            showCalendar: false,
            screenWidth: window.screen.width
        };
    }

    onChangeDate = (item) => {
        let dayformated = moment(item.selection.startDate).format('DD/MM/yyyy') + ' - ' + moment(item.selection.endDate).format('DD/MM/yyyy');
        this.setState({rangeDate:[item.selection], dayformated: dayformated});
    };

    onShowCalendar = () => {
        if (this.state.showCalendar) {
            this.setState({showCalendar: !this.state.showCalendar}, this.props.onDateChanged(this.state.rangeDate[0]));
        } else {
            this.setState({showCalendar: !this.state.showCalendar});
        }
    };

    componentDidMount() {
        let dataRange = document.getElementById('dataText');
    }

    render() {

        let mom = moment().locale('es');
        let months = mom.localeData().months();

        months = months.filter((ele, ind) => {
            return ind <= mom.month();
        }).map((elem, index) => {
            return {value: index, label:elem};
        });
        months.push({value: 12, label:'Hasta la fecha'});
        return (
            <React.Fragment>
                <div className="row">
                    <div className="section-headline mgB col-md-7 col-12">
                        <h1>Dashboard </h1>
                    </div>
                    <div className="col-md-5 mgTm2 col-12">
                        <div className="row">
                            <div className="col-md-5 mgB ">
                                <SelectInput
                                    value={this.props.monthSelected}
                                    floatingLabel="Mes"
                                    labelClassName="fsize-14 "
                                    className="color-blue"
                                    classNamePrefix="month-selected"
                                    options={months}
                                    onChange={(val) => this.props.onUpdateMonth(val)}
                                />
                            </div>
                            <div className="col-md-7 mgB" id="dataText">
                                <label style={{width:'100%'}}>
                                    <span >
                                        <TextInput
                                            label="Fecha"
                                            value={this.state.dayformated}
                                        />
                                        <span
                                            style={{top:'27px'}}
                                            onClick={this.onShowCalendar}
                                            className="calendar--icon">
                                            <i className={ CALENDAR_ICON } />
                                        </span>
                                    </span>
                                </label >
                                {this.state.showCalendar &&
                                    <div style={{position:'absolute', zIndex:'2', right:'20px', top:'75px'}}>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={this.onChangeDate}
                                            showSelectionPreview={false}
                                            moveRangeOnFirstSelection={false}
                                            retainEndDateOnFirstSelection={true}
                                            showDateDisplay={false}
                                            ranges={this.state.rangeDate}
                                        />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.dashboard.waitingquote > 0 &&
                <div className="row">
                    <div className="col-12 mgL2 " style={{fontSize:'18px'}}>
                        <span className="value-down fsbold" style={{fontSize:'24px'}}>!!! Atención !!!</span>, tenemos <span className="color-blue fsbold">({this.props.dashboard.waitingquote})</span> solicitudes nuevas.
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

DashboardHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    dashboard: PropTypes.object,
    authUser: PropTypes.object
};

export default DashboardHeader;
