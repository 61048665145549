import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import Toggle from 'react-toggle';

import {
    FormsyInputWrapper,
    TextInput,
    PhoneInput,
    SelectInput
} from 'common/components/inputs';
import Dropzone from 'common/components/dropzone';
import ImagePreview from 'common/components/image-preview';
import EmptyState from 'common/components/empty-state';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import { DELETE_ICON } from 'common/constants/icon.constant';

export default class CreateFinancingEntityForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            showOptionalFields: false,
            entityData: {
                name: props.creating ? '' : props.entityData.name,
                type: props.creating ? null : props.entityData.type ? props.entityData.type.id : null,
                logo: props.creating ? null : props.entityData.logo ? props.entityData.logo : null,
                logoico: props.creating ? null : props.entityData.logoico ? props.entityData.logoico : null,
                agent: props.creating ? {
                    name: '',
                    email: '',
                    phone: '',
                    extension: ''
                } : props.entityData.agents ? props.entityData.agents : {
                    name: '',
                    email: '',
                    phone: '',
                    extension: ''
                },
                require_photo: props.creating ? false : props.entityData.require_photo,
                only_partners: props.creating ? false : props.entityData.only_partners,
                services_foreigners: props.creating ? false : props.entityData.services_foreigners,
                require_profile: props.creating ? true : props.entityData.require_profile,
                require_warranties: props.creating ? false : props.entityData.require_warranties,
                optional_delivery: props.creating ? true : props.entityData.optional_delivery,
                canchoose_branche: props.creating ? true : props.entityData.canchoose_branche,
                fromtoapply: props.creating ? null : props.entityData.fromtoapply,
                processor_payment: props.creating ? null : props.entityData.processor_payment
            },
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                logo: { errorClass: null, dangerLabel: false },
                logoico: { errorClass: null, dangerLabel: false },
                type: { errorClass: null, dangerLabel: false },
                agentName: { errorClass: null, dangerLabel: false },
                agentEmail: { errorClass: null, dangerLabel: false }
            },
            errorMessage: ''
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);

    }

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onLogoChange = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('logo', value);
    };

    onLogoIcoChange = (data) => {
        let value = Array.isArray(data) ? data[0] : data;
        this.onFieldChange('logoico', value);
    };

    onFieldChange = (field, value) => {
        let entityData = {... this.state.entityData};
        entityData[field] = value;
        this.setState({entityData: entityData});
    };

    onAgentChange = (field, value) => {
        let agent = {...this.state.entityData.agent};

        agent[field] = value;

        this.onFieldChange('agent', agent);
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.entityData});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }
    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    render() {
        return (
            <Formsy
                ref={this.basicDataRef}
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <div className="row mgB2">
                    <FormsyInputWrapper
                        required
                        className="col-md-5 mgB2"
                        name="type"
                        validations="isTrue"
                        errorClassName={this.state.errorFields.type.errorClass}
                        value={this.state.entityData.type !== null}>
                        <SelectInput
                            floatingLabel={<span>Tipo <span className="color-red">*</span></span>}
                            key={'entityData.type'}
                            dangerLabel={this.state.errorFields.type.dangerLabel}
                            label="Seleccionar"
                            searchable={false}
                            options={this.props.financingEntityTypes.map(el => {
                                return { value: el.id, label: el.name};
                            })}
                            value={this.state.entityData.type}
                            onChange={(val) => this.onFieldChange('type', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-md-7 mgB2"
                        name="name"
                        errorClassName={this.state.errorFields.name.errorClass}
                        value={this.state.entityData.name}>
                        <TextInput
                            label={<span>Nombre <span className="color-red">*</span></span>}
                            numbers={false}
                            dangerLabel={this.state.errorFields.name.dangerLabel}
                            placeholder="Escriba el nombre"
                            value={this.state.entityData.name}
                            onChange={(val) => this.onFieldChange('name', val)}
                        />
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        className="col-md-6 mgB2 mgT4"
                        name="name"
                        errorClassName={this.state.errorFields.logoico.errorClass}
                    >
                        <div >
                            <span className="form-label">Logotipos de la  <strong className="color-blue">Entidad Financiera</strong>. Los archivos a usar deben ser (.jpeg, .jpg o .png)</span>
                        </div>
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-6 mgB2"
                        name="logoico"
                        errorClassName={this.state.errorFields.logoico.errorClass}
                        validations="isTrue"
                        value={this.state.entityData.logoico !== null}>
                        <Dropzone
                            accept={['.jpeg', '.jpg', '.png']}
                            onDrop={this.onLogoIcoChange}
                            multiple={false}
                            full={true}>
                            <div>
                                {typeof this.state.entityData.logoico !== 'string' && this.state.entityData.logoico !== null &&
                                    <div className="m-auto">
                                        <span
                                            className="position-absolute color-blue"
                                            style={{
                                                right: '20px',
                                                top: '2px',
                                                fontSize: '1.5rem',
                                                zIndex: 1,
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                this.onLogoIcoChange(null);
                                                e.stopPropagation();
                                            }}>
                                            <i className={DELETE_ICON}></i>
                                        </span>
                                        <ImagePreview
                                            style = {
                                                {
                                                    height: '90px',
                                                    width: '100%',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat'
                                                }
                                            }
                                            className="image-preview image-preview__xl"
                                            image={this.state.entityData.logoico}>
                                        </ImagePreview>
                                    </div>
                                }
                                {(!this.state.entityData.logoico || typeof this.state.entityData.logoico === 'string') &&
                                    <div className="mgT15">
                                        {this.props.creating &&
                                        <EmptyState
                                            imgStyle={
                                                {
                                                    borderRadius: '50px',
                                                    margin: 'auto',
                                                    height: '90px',
                                                    width: '90px'
                                                }
                                            }
                                            small={true}
                                            image={!this.props.creating ? this.state.entityData.logoico : "/images/add_image.svg"}
                                            title=""
                                            text=""
                                        />}
                                        {!this.props.creating &&
                                            <ImagePreview
                                                style = {
                                                    {
                                                        height: '90px',
                                                        width: '90%',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat'
                                                    }
                                                }
                                                className="image-preview image-preview__xl"
                                                image={this.state.entityData.logoico}>
                                            </ImagePreview>
                                        }
                                        <p>Aquí el logotipo sin <strong className="color-blue">rotulado</strong></p>
                                    </div>
                                }
                            </div>
                        </Dropzone>
                    </FormsyInputWrapper>
                    <FormsyInputWrapper
                        required
                        className="col-12 mgB2"
                        name="logo"
                        errorClassName={this.state.errorFields.logo.errorClass}
                        validations="isTrue"
                        value={this.state.entityData.logo !== null}>
                        <Dropzone
                            accept={['.jpeg', '.jpg', '.png']}
                            onDrop={this.onLogoChange}
                            multiple={false}
                            full={true}>
                            <div>
                                {typeof this.state.entityData.logo !== 'string' && this.state.entityData.logo !== null &&
                                    <div className="m-auto">
                                        <span
                                            className="position-absolute color-blue"
                                            style={{
                                                right: '30px',
                                                top: '10px',
                                                fontSize: '1.5rem',
                                                zIndex: 1,
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                this.onLogoChange(null);
                                                e.stopPropagation();
                                            }}>
                                            <i className={DELETE_ICON}></i>
                                        </span>
                                        <ImagePreview
                                            style = {
                                                {
                                                    height: '300px',
                                                    width: '100%',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat'
                                                }
                                            }
                                            className="image-preview image-preview__xl"
                                            image={this.state.entityData.logo}>
                                        </ImagePreview>
                                    </div>
                                }
                                {(!this.state.entityData.logo || typeof this.state.entityData.logo === 'string') &&
                                    <div className="mgT15">
                                        <EmptyState
                                            imgStyle={
                                                {
                                                    borderRadius: '150px',
                                                    margin: 'auto',
                                                    width: '170px',
                                                    padding: '20px'
                                                }
                                            }
                                            small={true}
                                            image={!this.props.creating ? this.state.entityData.logo : "/images/add_image.svg"}
                                            title=""
                                            text=""
                                        />
                                        <p>Aquí la imagen del logotipo <strong className="color-blue">completo</strong></p>
                                    </div>
                                }
                            </div>
                        </Dropzone>
                    </FormsyInputWrapper>

                    <span className="col-10 mgB2">Se requiere perfil financiero del solicitante</span>

                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="require_profile"
                        value={this.state.entityData.require_profile !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.require_profile}
                            onChange={() => this.onFieldChange('require_profile', !this.state.entityData.require_profile)} />

                    </FormsyInputWrapper>

                    <span className="col-10 mgB2">Se requiere imagen de cedula del solicitante</span>
                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="require_photo"
                        value={this.state.entityData.require_photo !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.require_photo}
                            onChange={() => this.onFieldChange('require_photo', !this.state.entityData.require_photo)} />

                    </FormsyInputWrapper>
                    {this.state.entityData.type === 2 &&
                     <span className="col-10 mgB2">Limitada solo para asociados</span>}
                    {this.state.entityData.type === 2 && <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="only_partners"
                        value={this.state.entityData.only_partners !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.only_partners}
                            onChange={() => this.onFieldChange('only_partners', !this.state.entityData.only_partners)} />
                    </FormsyInputWrapper>}

                    <span className="col-10 mgB2">Permite solicitudes de extranjeros</span>
                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="services_foreigners"
                        value={this.state.entityData.services_foreigners !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.services_foreigners}
                            onChange={() => this.onFieldChange('services_foreigners', !this.state.entityData.services_foreigners)} />
                    </FormsyInputWrapper>

                    <span className="col-10 mgB2">Se requiere garantía vehícular/inmobiliaria</span>
                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="require_warranties"
                        value={this.state.entityData.require_warranties !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.require_warranties}
                            onChange={() => this.onFieldChange('require_warranties', !this.state.entityData.require_warranties)} />
                    </FormsyInputWrapper>

                    <span className="col-10 mgB2">Incluye transporte</span>
                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="optional_delivery"
                        value={this.state.entityData.optional_delivery !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.optional_delivery}
                            onChange={() => this.onFieldChange('optional_delivery', !this.state.entityData.optional_delivery)} />

                    </FormsyInputWrapper>

                    <span className="col-10 mgB2">Cliente escoge la sucursal</span>
                    <FormsyInputWrapper
                        className="col-2 mgB2"
                        name="canchoose_branche"
                        value={this.state.entityData.canchoose_branche !== null}
                    >
                        <Toggle
                            className="md-switch"
                            icons={false}
                            checked={this.state.entityData.canchoose_branche}
                            onChange={() => this.onFieldChange('canchoose_branche', !this.state.entityData.canchoose_branche)} />

                    </FormsyInputWrapper>

                    {!this.props.creating && 
                    <React.Fragment>
                        <span className="col-6 mgB2 pdT">Formulario a aplicar</span>
                        <FormsyInputWrapper
                            className="col-md-6 mgB2"
                            name="fromtoApply"
                            value={this.state.entityData.fromtoapply !== null}>
                            <SelectInput
                                key={'entityData.templates'}
                                label="Seleccionar"
                                searchable={false}
                                options={this.props.entityData.templates.map(el => {
                                    return { value: el, label: el};
                                })}
                                value={this.state.entityData.fromtoapply}
                                onChange={(val) => this.onFieldChange('fromtoapply', val)}
                            />
                        </FormsyInputWrapper>


                        {this.state.entityData.type === 4 &&
                        <React.Fragment>
                            <span className="col-6 mgB2 pdT">Procesador de pago</span>
                            <FormsyInputWrapper
                                className="col-md-6 mgB2"
                                name="fromtoApply"
                                value={this.state.entityData.processor_payment !== null}>
                                <SelectInput
                                    key={'entityData.processors'}
                                    label="Seleccionar"
                                    searchable={false}
                                    options={this.props.entityData.processors.map(proc => {
                                        return {value: proc.valueID, label: proc.label};
                                    })}
                                    value={this.state.entityData.processor_payment}
                                    onChange={(val) => this.onFieldChange('processor_payment', val)}
                                />
                            </FormsyInputWrapper>
                        </React.Fragment>}
                    </React.Fragment>}

                    <span className="col-3 mgB2"></span>
                    {this.props.creating &&
                        <React.Fragment>
                            <h3 className="mgT2 col-12">
                                <strong>Representante</strong>
                            </h3>
                            <FormsyInputWrapper
                                required
                                className="col-12 mgB2"
                                name="name"
                                errorClassName={this.state.errorFields.agentName.errorClass}
                                value={this.state.entityData.agent.name}>
                                <TextInput
                                    label={<span>Nombre completo <span className="color-red">*</span></span>}
                                    numbers={false}
                                    dangerLabel={this.state.errorFields.agentName.dangerLabel}
                                    placeholder="Inserte el nombre"
                                    value={this.state.entityData.agent.name}
                                    onChange={(val) => this.onAgentChange('name', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                className="col-12 mgB2"
                                name="email"
                                validations="isEmail"
                                errorClassName={this.state.errorFields.agentEmail.errorClass}
                                validationError="Por favor ingrese un correo electrónico válido."
                                value={this.state.entityData.agent.email}>
                                <TextInput
                                    label={<span>Correo electrónico <span className="color-red">*</span></span>}
                                    placeholder="Inserte el correo"
                                    dangerLabel={this.state.errorFields.agentEmail.dangerLabel}
                                    value={this.state.entityData.agent.email}
                                    onChange={(val) => this.onAgentChange('email', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                className="col-md-7 col-12 mgB2"
                                name="phone"
                                validations={this.state.entityData.agent.phone !== '' ? 'isValidPhone' : null}
                                validationError={this.state.entityData.agent.phone !== '' ? "Por favor ingrese un número de telefono válido." : null}
                                value={this.state.entityData.agent.phone}>
                                <PhoneInput
                                    label="Teléfono"
                                    placeholder="Inserte el teléfono"
                                    value={this.state.entityData.agent.phone}
                                    onChange={(val) => this.onAgentChange('phone', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                className="col-md-5 col-12 mgB2"
                                name="extension"
                                value={this.state.entityData.agent.extension}>
                                <TextInput
                                    label="Extensión"
                                    onlyNumbers={true}
                                    numbers={true}
                                    placeholder="Inserte extensión"
                                    value={this.state.entityData.agent.extension}
                                    onChange={(val) => this.onAgentChange('extension', val)}
                                />
                            </FormsyInputWrapper>
                        </React.Fragment>
                    }
                </div>
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-entity-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.entityData}>
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            {this.props.creating ? 'Crear' : 'Guardar'}
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </Formsy>
        );
    }
}

CreateFinancingEntityForm.defaultProps = {creating: true};

CreateFinancingEntityForm.propTypes = {
    creating: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
