

export const getStores = state => state.promotionReducer.stores;
export const getError = state => state.promotionReducer.error;
export const getSimpleError = state => state.promotionReducer.simpleError;
export const getIsLoading = state => state.promotionReducer.loading;
export const getPagination = state => state.promotionReducer.pagination;
export const getFilters = state => state.promotionReducer.promotionFilters;

export const getPromotion = state => state.promotionReducer.singlePromotion;
export const getAllPromotions = state => state.promotionReducer.promotions;
export const getSkus = state => state.promotionReducer.skus;
export const getCupons = state => state.promotionReducer.cupons;
export const getRules = state => state.promotionReducer.rules;
export const getPromotionsActivated = state => state.promotionReducer.promotionsActivated;

export const getPromotionCreated = state => state.promotionReducer.promotionCreated;
export const getPromotionUpdated = state => state.promotionReducer.promotionUpdated;
export const getPromotionDeleted = state => state.promotionReducer.promotionDeleted;
export const getCuponCreated = state => state.promotionReducer.cuponCreated;
export const getCuponRemoved = state => state.promotionReducer.cuponRemoved;
export const getSkuCreated = state => state.promotionReducer.skuCreated;
export const getSkuUpdated = state => state.promotionReducer.skuUpdated;
export const getSkuDeleted = state => state.promotionReducer.skuDeleted;