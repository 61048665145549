import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs as ReactTab, TabList, TabPanel } from 'react-tabs';

/**
* Creates a set of tabs based on the given properties.
*/
export default class Tabs extends React.Component {
    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        let tabList = [];
        let tabPanel = [];

        // Iterates over the list to get the title along with it's element.

        this.props.list.forEach((elem, i) => {
            tabList.push(<Tab className={'react-tabs__tab ' + this.props.tabClassName} key={`tab-${i}`}> {elem.title} </Tab>);
            tabPanel.push(
                <TabPanel key={`tabPanel-${i}`}>
                    {typeof elem.element === "function"
                        ? elem.element()
                        : elem.element
                    }
                </TabPanel>
            );
        });

        const selectedIndex = this.props.selectedIndex != null ? this.props.selectedIndex : null;
        const defaultIndex = selectedIndex != null ? null : this.props.defaultIndex;

        return (
            <ReactTab
                className={`react-tabs ${this.props.className ? this.props.className : ""}`}
                defaultIndex={defaultIndex}
                selectedIndex={selectedIndex}
                onSelect={this.props.onSelected || null}>
                <TabList className={this.props.listClassName}>{tabList}</TabList>
                {tabPanel}
            </ReactTab>
        );
    }
}

/**
 * Tabs properties validation.
 */
Tabs.propTypes = {
    className: PropTypes.string,
    defaultIndex: PropTypes.number,
    // A property called list which contains an array of objects. Each object
    // of this array must contain a title and an element.
    list: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node.isRequired,
            element: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
        })
    ).isRequired,
    selectedIndex: PropTypes.number,
    onSelected: PropTypes.func
};
