import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import {
    DASHBOARD_ICON,
    USERS_ICON,
    USER_ICON,
    BANK_ICON,
    CASH_ICON,
    CARD_ICON,
    NOTIFICATION_ICON,
    HIDE_MENU_ICON,
    SHOW_MENU_ICON,
    HOME_ICON,
    LOGOUT_ICON,
    OFFER_ICON
} from 'common/constants/icon.constant';

import Dropdown from 'common/components/dropdown';

import SidebarLink from './sidebar-link';
import { USERTYPES } from 'common/constants/enums.constant';

class Sidebar extends React.PureComponent {

    constructor (props) {
        super(props);

        this.state = { hidemenu: false };
    }


    logOut = () => {
        this.props.logOut();
    };

    hideMenu = (val) =>{
        this.setState({hidemenu:val});
        this.props.hideMenu();
    };

    render() {
        return (
            <React.Fragment>
                <aside className={`sidebar${this.props.mobile ? '-mobile' : ''}`}
                    style={!this.state.hidemenu ? this.props.mobile ? {width:'300px'} : {width:'256px'}: {width:'60px'}}>
                    <div style={{margin: this.props.mobile ? '10px 0px 0px 40px' : undefined}} className="d-flex">
                        {!this.state.hidemenu &&
                     <NavLink
                         to="/"
                     >
                         <div className="sidebar__logo"/>
                     </NavLink>}
                        {this.props.authUser && !this.state.hidemenu &&
                            <a
                                style={{fontSize: '27px'}}
                                className="text-white"
                                id='bell-icon'
                                href="#">
                                <i className={NOTIFICATION_ICON} />
                            </a>
                        }
                        {this.state.hidemenu &&
                            <a
                                style={{fontSize: '40px', paddingLeft:'1rem'}}
                                className="text-white"
                                href="#">
                                <i className={HOME_ICON} />
                            </a>
                        }
                    </div>
                    {this.props.authUser &&
                    <div
                        style={!this.state.hidemenu ? this.props.mobile ? {width:'300px'} : {width:'256px'} : {width:'60px'}}>
                        {!this.state.hidemenu &&
                         <div style={{fontSize: '1rem', paddingLeft: '30px'}}>
                             <p style={{color:'#fff', marginBottom:'-10px'}}>{`${this.props.authUser.name}`} </p>
                             <a href="/my-profile" style={{ color:'#fff', textDecoration:'none'}}>
                                 {`(${this.props.authUser.userType.name})`} </a> <i className={`${LOGOUT_ICON} cursor-pointer`} style={{ color:'#fff', fontSize: '1.5rem', marginLeft:'55px'}} onClick={this.logOut} />
                         </div>}
                        {this.state.hidemenu &&
                            <div >
                                <li style={{listStyleType:'none', paddingLeft:'1.5rem'}}>
                                    <NavLink to="/my-profile" style={{fontSize: '27px', color:'white'}}><i className={LOGOUT_ICON} onClick={this.logOut} />  </NavLink>
                                </li>
                            </div>
                        }
                    </div>
                    }
                    {this.props.authUser && this.props.authUser.userType.id !== USERTYPES.CLIENT && !this.props.mobile &&
                        <div className="d-flex">
                            {!this.state.hidemenu &&
                            <a
                                style={{fontSize: '40px', color:'white', marginLeft:'80%'}}
                                className="cursor-pointer"
                            >
                                <i className={HIDE_MENU_ICON}
                                    onClick={() => this.hideMenu(true)}
                                />
                            </a>}
                            {this.state.hidemenu &&
                            <a
                                style={{fontSize: '40px', color:'white', paddingLeft:'1rem'}}
                                className="cursor-pointer"
                            >
                                <i className={SHOW_MENU_ICON}
                                    onClick={() => this.hideMenu(false)}
                                />
                            </a>}
                        </div>
                    }

                    {this.props.authUser &&
                        <nav className={`sidebar${this.props.mobile ? '-mobile-nav' : '-nav'}`}>
                            {this.props.authUser.userType.id === USERTYPES.CLIENT &&
                                <ul className={`sidebar${this.props.mobile ? '-mobile__list' : '__list'}`}>
                                    <SidebarLink history={this.props.history} title="Financiamientos" icon={CASH_ICON} href="/financings" />
                                    <SidebarLink history={this.props.history} title="Mi Perfil" icon={ USER_ICON } href="/my-profile" />
                                </ul>
                            }
                            {this.props.authUser.userType.id === USERTYPES.AGENT &&
                                <ul className={`sidebar${this.props.mobile ? '-mobile__list' : '__list'}`}>
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Financiamientos" : ""} icon={CASH_ICON} href="/financings" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Mi Perfil" : ""} icon={ USER_ICON } href="/my-profile" />
                                </ul>
                            }
                            {this.props.authUser.userType.id !== USERTYPES.CLIENT && this.props.authUser.userType.id !== USERTYPES.AGENT &&
                                <ul className={`sidebar${this.props.mobile ? '-mobile__list' : '__list'}`}>
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Dashboard" : ""} icon={ DASHBOARD_ICON } href="/" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Financiamientos" : ""} icon={CASH_ICON} href="/financings" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Transacciones tarjetas" : ""} icon={CARD_ICON} href="/installments" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Entidades financieras" : ""} icon={ BANK_ICON } href="/financing-entities" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Promociones" : ""} icon={ OFFER_ICON } href="/promotions" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Clientes" : ""} icon={ USERS_ICON } href="/clients" />
                                    <SidebarLink history={this.props.history} title={!this.state.hidemenu ? "Usuarios" : ""} icon={ USER_ICON } href="/users" />
                                </ul>
                            }
                        </nav>
                    }
                    {this.props.authUser && !this.state.hidemenu &&
                    <div className='electrodato'>
                        <p style={{marginBottom:'6px'}}>&copy; {moment().format('YYYY')} ElectroPay</p>
                        <p style={{marginBottom:'6px'}}>Calle Haim Lopez Penha 19, Ensanche Para&iacute;so. Santo Domingo. República Dominicana.</p>
                        <p >Tel: 809-541-5202</p>
                    </div>
                    }
                </aside>
                {/*this.props.authUser &&
                    <div className="sidebar__footer"
                        style={!this.state.hidemenu ? this.props.mobile ? {width:'300px'} : {width:'256px'} : {width:'60px'}}>
                        <hr className="w-100" />
                        {!this.state.hidemenu &&
                         <div style={{paddingLeft: '30px'}} className="layout-header__dropdown">
                             <Dropdown
                                 open="right-up"
                                 content={
                                     <span className="user-name">
                                         <span>
                                             <p>{this.props.authUser.name}</p>
                                             <p style={{fontSize: '14px'}}>{this.props.authUser.userType.name}</p>
                                         </span>
                                         <i className={PAGINATION_RIGHT_ICON}></i>
                                     </span>}
                             >
                                 <li>
                                     <NavLink className="dropdown__item" to="/my-profile">
                                        Mi perfil
                                     </NavLink>
                                 </li>
                                 <li role="separator" className="divider" />
                                 <li><a className="dropdown__item" href="#" onClick={this.logOut}>Cerrar sesión</a></li>
                             </Dropdown>
                         </div>}
                        {this.state.hidemenu &&
                            <div >
                                <li style={{listStyleType:'none', paddingLeft:'1.5rem'}}>
                                    <NavLink to="/my-profile" style={{fontSize: '27px', color:'white'}}>   <i className={PROFILE_ICON}/> </NavLink>
                                </li>
                                <li style={{listStyleType:'none', paddingLeft:'1.5rem'}}>
                                    <NavLink to="/my-profile" style={{fontSize: '27px', color:'white'}}><i className={LOGOUT_ICON} onClick={this.logOut} />  </NavLink>
                                </li>
                            </div>
                        }
                    </div>*/
                }

            </React.Fragment>
        );
    }
}

Sidebar.defaultProps = {mobile: false};

export default Sidebar;
