'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ClientsListContainer from './clients-list';

export default class ClientsContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route exact path="/clients" component={ClientsListContainer} />
            </Switch>
        );
    }
}
