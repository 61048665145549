import money from './money';


export default function (n, plan, total) {
    let i = plan.charge / 12;
    let firstPayment = plan.firstPayment * total;
    let monthlyFixed = plan.monthlyFixed * total;
    let advancePayment = plan.minAdvancePayment * total;

    let monthlyFixedAmount = plan.monthlyFixedAmount ? plan.monthlyFixedAmount.amount ? plan.monthlyFixedAmount.amount : plan.monthlyFixedAmount : 0;
    let firstPaymentAmount = plan.firstPaymentAmount ? plan.firstPaymentAmount.amount ? plan.firstPaymentAmount.amount : plan.firstPaymentAmount : 0;

    let subTotal = total / n;
    if (i !== 0) {
        subTotal = total * ((Math.pow(1 + i, n) * i) / (Math.pow(1 + i, n) - 1));
    }

    let rawTotal = subTotal + firstPayment + firstPaymentAmount + monthlyFixed + monthlyFixedAmount + ((n - 1) * (subTotal + monthlyFixed + monthlyFixedAmount));

    return {
        rawfirstPayment: subTotal + firstPayment + firstPaymentAmount + monthlyFixed + monthlyFixedAmount ,
        firstPayment: plan.currency.symbol + '$ ' + money((subTotal + firstPayment + firstPaymentAmount + monthlyFixed + monthlyFixedAmount )),
        rawrestPayments: n > 1 ? (subTotal + monthlyFixed + monthlyFixedAmount) : (subTotal + firstPayment + firstPaymentAmount + monthlyFixed + monthlyFixedAmount ),
        restPayments: plan.currency.symbol + '$ ' + money(n > 1 ? (subTotal + monthlyFixed + monthlyFixedAmount) : (subTotal + firstPayment + firstPaymentAmount + monthlyFixed + monthlyFixedAmount )),
        payments: n,
        total: plan.currency.symbol + '$ ' + money(rawTotal),
        rawTotal: rawTotal,
        gastos_cierre: plan.currency.symbol + '$ ' + money((firstPayment + firstPaymentAmount )),
        advancePayment: advancePayment
    };
}
