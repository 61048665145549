


export const AUTHORIZE_CARD = 'AUTHORIZE_CARD';
export const AUTHORIZE_CARD_SUCCESS = 'AUTHORIZE_CARD_SUCCESS';
export const AUTHORIZE_CARD_FAILURE = 'AUTHORIZE_CARD_FAILURE';

export const REGISTER_CUSTOMER_TOKENS = 'REGISTER_CUSTOMER_TOKENS';
export const REGISTER_CUSTOMER_TOKENS_SUCCESS = 'REGISTER_CUSTOMER_TOKENS_SUCCESS';
export const REGISTER_CUSTOMER_TOKENS_FAILURE = 'REGISTER_CUSTOMER_TOKENS_FAILURE';

export const BEGIN_TRANSACTION = 'BEGIN_TRANSACTION';
export const CLOSE_TRANSACTION = 'CLOSE_TRANSACTION';