import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

import {
    PAGINATION_LEFT_ICON,
    PAGINATION_RIGHT_ICON
} from 'common/constants/icon.constant';

export default class Pagination extends React.Component {

    render() {
        if (this.props.pageCount <= 1) {
            return (
                <div></div>
            );
        }
        return (
            <ReactPaginate
                disabledClassName="pagination__item--no-display"
                pageClassName="pagination__item"
                nextClassName="pagination__item"
                previousClassName="pagination__item"
                breakClassName="pagination__item pagination__item--break"
                activeClassName="pagination__item--active"
                pageLinkClassName="pagination__item__archor"
                breakLabel="..."
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={this.props.onPageChange}
                forcePage={this.props.forcePage - 1}
                nextLabel={
                    this.props.nextLabel !== '' ?
                        <span>{this.props.nextLabel}<i className={PAGINATION_RIGHT_ICON} /></span> :
                        <span className={PAGINATION_RIGHT_ICON} />
                }
                previousLabel={
                    this.props.previousLabel !== '' ?
                        <span><i className={PAGINATION_LEFT_ICON} />{this.props.previousLabel}</span> :
                        <span className={PAGINATION_LEFT_ICON} />
                }
                pageCount={ this.props.pageCount } />
        );
    }
}

Pagination.defaultProps = {
    previousLabel: '',
    nextLabel: ''
};

Pagination.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    forcePage: PropTypes.number,
    pageCount: PropTypes.number.isRequired,
    previousLabel: PropTypes.string,
    nextLabel: PropTypes.string
};
