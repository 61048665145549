export const getAuthUser = state => state.userReducer.authUser;
export const getError = state => state.userReducer.error;
export const getValidatingUser = state => state.userReducer.validating;
export const getSimpleError = state => state.userReducer.simpleError;
export const getIsLoading = state => state.userReducer.loading;
export const getEmailSent = state => state.userReducer.emailSent;
export const getPasswordChanged = state => state.userReducer.passwordChanged;
export const getUserCreated = state => state.userReducer.userCreated;
export const getUserUpdated = state => state.userReducer.userUpdated;
export const getUserDeleted = state => state.userReducer.userDeleted;
export const getUserProfile = state => state.userReducer.singleUser;
export const getUserFinancings = state => state.userReducer.userFinancings;

export const getUsers = state => state.userReducer.users;
export const getPagination = state => state.userReducer.pagination;
export const getUsersFilters = state => state.userReducer.filters;

export const getDropdowns = state => state.userReducer.dropdowns;
export const getRegisterDropdowns = state => state.userReducer.registerDropdowns;

export const getSelectedUsers = state => state.userReducer.selectedUsers;

export const getUserActions = state => state.userReducer.userActions;
export const getUserActionsLoading = state => state.userReducer.userActionsLoading;
export const getUserActionsError = state => state.userReducer.userActionsError;

export const getValidateEmailError = state => state.userReducer.validateEmailError;
export const getIsValidEmail = state => state.userReducer.isValidEmail;
export const getUserDataProfile = state => state.userReducer.userdataprofile;

export const getVerificationEmailError = state => state.userReducer.verificationEmailError;
export const getVerificationEmailSent = state => state.userReducer.verificationEmailSent;

export const statusMenu = state => state.genericUIReducer.hideMenu;
