/**
 * The idea is to place all the regex that the application will use here.
 */

// Matches from 00:00 to 99:99.
export const isValidTime = /([0-9][0-9]):[0-9][0-9]/;

export const isValidDate = /(?:3[01]|[12][0-9]|0?[1-9])\/(0?[1-9]|[1-9][1-2])\/\d{4}$/;

export const isValidIdentification = /([0-9]{3})([0-9]{7})([0-9]{1})/;

export const isValidUSAPhone = /^(\(1\) )?([0-9]|[A-Z]){3}([0-9]|[A-Z]){3}([0-9]|[A-Z]){4}$/;

export const isURL = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?(\..{2,})/;

export const isValidDOMPhone = /(809|829|849)[0-9]{7}/;

export const isValidCard = /^(?:4[0-9]{12}(?:[0-9]{3})?)|(?:5[1-5][0-9]{14})|(?:3[47][0-9]{13})$/;

export const isValidCVV = /[0-9]{3}|[0-9]{4}/;

export const isValidDateCard = /^(0[1-9]|1[012])[/.]\d\d$/;
