import React from 'react';
import PropTypes from 'prop-types';

/**
* Represents a rounded checkbox.
*/
export default class Checkbox extends React.Component {
    /**
    * Sets the state and make the binds as needed.
    *
    * @param {props}  the required React properties.
    */
    constructor(props) {
        super(props);

        this.changeCheckbox = this.changeCheckbox.bind(this);
    }

    /**
    * Fired once the checked status changes.
    */
    changeCheckbox(e) {
        if (this.props.onChange) {
            this.props.onChange(!this.props.checked, e);
        }
    }

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        return (
            <div
                style={this.props.style}
                className={`checkbox ${this.props.className ? this.props.className : ""} ${this.props.checked ? "checkbox--checked" : this.props.indeterminate ? "checkbox--indeterminate" : ""} ${this.props.label ? '' : 'checkbox--no-label'}`}>
                <label style={this.props.labelStyle} className="checkbox__label">
                    {this.props.leftLabel &&
                        <span style={{fontSize: '16px', color: '#383838', fontWeight: '500'}} className={`mgR ${this.props.dangerLabel ? 'text-danger' : ''}`}>{this.props.label}</span>
                    }
                    <input
                        value={this.props.value || this.props.checked}
                        checked={this.props.checked}
                        className="checkbox__input"
                        onChange={this.changeCheckbox}
                        type="checkbox"
                    />
                    <span className={`checkbox__check ${this.props.squared ? 'checkbox--squared' : ''}`}></span>
                    {!this.props.leftLabel &&
                        <span style={this.props.textStyle} className={`checkbox__text ${this.props.dangerLabel ? 'text-danger' : ''}`}>{this.props.label}</span>
                    }
                </label>
            </div>
        );
    }
}

/**
 * Password properties validation.
 */
Checkbox.propTypes = {
    squared: PropTypes.bool,
    value: PropTypes.any,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};
