'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitySelectors from 'common/store/selectors/financing-entity.selector';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';

import EmptyState from 'common/components/empty-state';
import ImagePreview from 'common/components/image-preview';

import Modal from 'common/components/modal';
import {
    BACK_ICON,
    PENCIL_ICON,
    CANCEL_ICON,
    STAR_ICON,
    DELETE_ICON
} from 'common/constants/icon.constant';

import {
    OPTIONALFIELDS_TYPE,
    BELONGS_OPTIONALFIELDS
} from 'common/constants/enums.constant';

import toCamelCase from 'common/helpers/to-camel-case';

import {
    TextInput,
    SelectInput
} from 'common/components/inputs';

import CreateFinancingEntityForm from '../create-financing-entity';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            financingEntityTypes: transactionSelectors.getAllFinancingEntityTypes(state),
            financingEntityDeleted: financingEntitySelectors.getFinancingEntityDeleted(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteFinancingEntity: (id) => {
            dispatch(financingEntityActions.deleteFinancingEntity(id));
        },
        getAllFinancingEntityTypes: () => {
            dispatch(transactionActions.getAllFinancingEntityTypes(true));
        },
        updateFinancingEntity: (id, params) => {
            dispatch(financingEntityActions.updateFinancingEntity(id, params));
        },
        activateFinancingEntity: (id, value) => {
            dispatch(financingEntityActions.activateFinancingEntity(id, value));
        }
    };
};

class FinancingEntityDetailHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showActivateModal: false,
            showEditModal: false,
            showMenuOptional: false,
            showOptionalFields: false,
            createOptField: false,
            optField: {
                name: null,
                type: null,
                values: null,
                belongto: null,
                require: false,
                var:''
            },
            optFields: props.financingEntity.optional_fields
        };
    }

    componentDidUpdate() {
        if (this.props.financingEntityDeleted) {
            this.props.resetFinancingEntityMessages();
            location.replace('/financing-entities');
        }
    }

    componentDidMount() {
        if (this.props.financingEntityTypes.length === 0) {
            this.props.getAllFinancingEntityTypes();
        }
    }

    renderEditFinancingEntityModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showEditModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showEditModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_entity.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Editar entidad</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>
                <CreateFinancingEntityForm
                    creating={false}
                    error={this.props.simpleError}
                    entityData={this.props.financingEntity}
                    financingEntityTypes={this.props.financingEntityTypes}
                    authUser={this.props.authUser}
                    onCancel={() => this.setState({showEditModal: false})}
                    onSubmit={(data) => this.props.updateFinancingEntity(this.props.financingEntity.id, data)}
                />
            </Modal>
        );
    }

    onFieldOPTChange = (field, val) => {
        let values = {...this.state.optField};
        values[field] = val;
        this.setState({optField: values});
    };

    addOptField = (actionClose) => {
        let newOptFields = null;
        let newOptField = {...this.state.optField};
        if (this.state.optFields === null) {
            newOptField['var'] = toCamelCase(newOptField['name']);
            newOptFields = { fields: [newOptField] };
        } else {
            newOptField['var'] = toCamelCase(newOptField['name']);
            newOptFields = {...this.state.optFields};
            newOptFields.fields.push(newOptField);
        }

        if (actionClose) {
            this.setState({optFields: newOptFields, createOptField: false, optField: { name:'', type: null, belongto:null, require: false, var:''}});
        } else {
            this.setState({optFields: newOptFields, optField: {name:'', type: null, belongto:null, require: false, var:''}});
        }
    };
    removeOptField = (ind) => {
        let OptFields = {...this.state.optFields};
        OptFields.fields.splice(ind, 1);
        this.setState({optFields: OptFields});
    };

    activeRequiere = (ind) => {
        let OptFields = {...this.state.optFields};
        OptFields.fields[ind].require = !OptFields.fields[ind].require;
        this.setState({optFields: OptFields});
    };


    renderOtherdataModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showOptionalFields: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                width={350}
                showModal={this.state.showOptionalFields}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/edit_entity.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Gestion de otros datos</h3>
                <div className="important-information mgB">
                    <p>La gestión de otros datos es para aquella información especifica solicitada por la entidad financiera.</p>
                </div>
                <div className='row'>
                    <span className='fsbold fsize-18 col-9 pdL2' style={{marginLeft:'10px', paddingTop:'5px'}}>Campos adicionales</span>
                    <button
                        className='col-3 btn btn-link btn-transparent'
                        style={{marginLeft:'-20px'}}
                        onClick={() => {
                            const optField = {
                                name: null,
                                type: null,
                                belongto: null,
                                require: false,
                                var: ''
                            };
                            this.setState({createOptField: true, optField: optField});
                        }}>
                        Crear
                    </button>
                </div>
                {this.state.createOptField &&
                <div className='bordered mgB2 mgT' >
                    <div className='row'>
                        <div className='col-md-6 mgT'>
                            <SelectInput
                                floatingLabel={<span>Pertenece a <span className="color-red">*</span></span>}
                                key={'entityData.type'}
                                label="Seleccionar"
                                searchable={false}
                                options={BELONGS_OPTIONALFIELDS.map(el => {
                                    return { value: el.value, label: el.name};
                                })}
                                value={this.state.optField.belongto}
                                onChange={(val) => this.onFieldOPTChange('belongto', val)}
                            />
                        </div>
                        <div className='col-md-6 mgT'>
                            <SelectInput
                                floatingLabel={<span>Formato del dato<span className="color-red">*</span></span>}
                                key={'optionalfieldType'}
                                label="Seleccionar formato"
                                searchable={false}
                                options={OPTIONALFIELDS_TYPE.map(element => {
                                    return {
                                        value: element.value,
                                        label: element.name
                                    };
                                })}
                                value={this.state.optField.type}
                                onChange={(val) => this.onFieldOPTChange('type', val)}
                            />
                        </div>
                        {this.state.optField.type === 6 &&
                        <div className='col-md-12 mgT'>
                            <TextInput
                                label={<span>Valores a escoger</span>}
                                numbers={false}
                                placeholder="Escriba los valores separados por coma"
                                value={this.state.optField.values}
                                onChange={(val) => this.onFieldOPTChange('values', val)}
                            />
                        </div>}
                        <div className='col-md-12 mgT'>
                            <TextInput
                                label={<span>Nombre del campo <span className="color-red">*</span></span>}
                                numbers={false}
                                placeholder="Escriba el nombre"
                                value={this.state.optField.name}
                                onChange={(val) => this.onFieldOPTChange('name', val)}
                            />
                        </div>
                        <div className='col-md-6 text-center mgT2 mgB'>
                            <button
                                disabled={(this.state.optField.belongto === null || this.state.optField.name === null || this.state.optField.name === '' || this.state.optField.type === null)}
                                className='btn btn-primary '
                                onClick={() => this.addOptField(false)}>
                                    Adicionar y crear nuevo
                            </button>
                        </div>
                        <div className='col-md-6 text-center mgT2 mgB'>
                            <button
                                disabled={(this.state.optField.belongto === null || this.state.optField.name === null || this.state.optField.name === '' || this.state.optField.type === null)}
                                className='btn btn-primary'
                                onClick={() => this.addOptField(true)}>
                                    Adicionar y cerrar
                            </button>
                        </div>
                    </div>

                </div>
                }

                {this.state.optFields !== null && this.state.optFields.fields.length > 0 &&
                <div className='bordered mgB2' style={{maxHeight: '200px', overflowY:'scroll'}}>
                    {this.state.optFields.fields.map((field, index) => {
                        return(
                            <div
                                key={`Group_${index}`}
                                className="row pdB2 pdT"
                                style={{marginLeft:'10px', marginRight:'10px', borderBottom:'3px dashed #6cb2eb'}}>
                                <div key={`name${index}`} className='col-8 border-md-bottom'>
                                    <span className="fsbold">{field.name}</span>
                                </div>
                                <div key={`require${index}`} className='col-2 border-md-bottom'>
                                    <button
                                        onClick={() => this.activeRequiere(index)}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '18px', marginRight: '10px'}} className={`${STAR_ICON} ${(field.require) ? 'color-red' : ''}`}/>
                                        </strong>
                                    </button>
                                </div>
                                <div key={`require${index}`} className='col-2 border-md-bottom'>
                                    <button
                                        onClick={() => this.removeOptField(index)}
                                        className="btn btn-transparent btn-link">
                                        <strong>
                                            <i style={{fontSize: '18px', marginRight: '10px'}} className={`${DELETE_ICON} color-red`}/>
                                        </strong>
                                    </button>
                                </div>
                                <div key={`type${index}`} className='col-5'>
                                    <span className="fsbold">Formato: </span>{OPTIONALFIELDS_TYPE[field.type-1].name}
                                </div>
                                <div key={`location${index}`} className='col-7'>
                                    <span className="fsbold">En: </span>{BELONGS_OPTIONALFIELDS[field.belongto-1].name}
                                </div>
                            </div>
                        );
                    }) }
                </div>}
                {(this.state.optFields === null || this.state.optFields.fields.length === 0) &&
                <div className='bordered mgB2' style={{maxHeight: '200px', overflowY:'scroll'}}>
                    <div className='text-center' style={{height:'150px', verticalAlign:'middle'}}>
                        Sin campos adicionales
                    </div>
                </div>}

                <div style={{margin: '0px'}} className="row">
                    <button
                        onClick={() => this.setState({showOptionalFields: false})}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <button
                        onClick={this.updateOptFields}
                        className="btn btn-primary col-md-4">
                        Guardar
                    </button>
                </div>
            </Modal>
        );
    }

    updateOptFields = () => {

        let entityData = {
            name: this.props.financingEntity.name,
            type: this.props.financingEntity.type.id,
            require_photo: this.props.financingEntity.require_photo,
            only_partners: this.props.financingEntity.only_partners,
            services_foreigners: this.props.financingEntity.services_foreigners,
            require_profile: this.props.financingEntity.require_profile,
            require_warranties: this.props.financingEntity.require_warranties,
            optional_delivery: this.props.financingEntity.optional_delivery,
            canchoose_branche: this.props.financingEntity.canchoose_branche,
            enabled: this.props.financingEntity.enabled,
            optional_fields: JSON.stringify(this.state.optFields)
        };
        this.props.updateFinancingEntity(this.props.financingEntity.id, entityData);
    };

    renderActivateFinancingEntityModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showActivateModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showActivateModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image={this.props.financingEntity.enabled ? "/images/deactivate_entity.svg" : "/images/activate_entity.svg"}
                        title=""
                        text=""
                    />
                    <h2 className="text-center">{this.props.financingEntity.enabled ? 'Desactivar' : 'Reactivar'} entidad</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea {this.props.financingEntity.enabled ? 'desactivar' : 'reactivar'} esta entidad?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>La entidad {this.props.financingEntity.enabled ? 'no' : ''} podrá ser seleccionada por el cliente.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.activateFinancingEntity(this.props.financingEntity.id, !this.props.financingEntity.enabled)}
                            className="btn btn-outline col-md-4">
                            {this.props.financingEntity.enabled ? 'Desactivar' : 'Reactivar'}
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showActivateModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderDeleteFinancingEntityModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_entity.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Eliminar entidad</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea eliminar esta entidad?</p>
                    <div className="important-information mgB">
                        <p><strong>Información importante</strong></p>
                        <p>La entidad no podrá ser seleccionada por el cliente, y todos sus representantes serán eliminados por igual.</p>
                    </div>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.deleteFinancingEntity(this.props.financingEntity.id)}
                            className="btn btn-outline col-md-4">
                            Eliminar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => this.setState({showDeleteModal: false})}
                            className="btn btn-primary col-md-4">
                            Cancelar
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderActions() {
        if (!this.props.isMobile){
            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-4 pdL0 mgB">
                            <button
                                onClick={() => this.props.history.goBack()}
                                className="btn btn-transparent btn-link">
                                <strong>
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                Volver
                                </strong>
                            </button>
                        </div>
                        <div className="col-md-2 pdL0 mgB text-center">
                            <button
                                onClick={() => this.setState({showActivateModal: true})}
                                className="btn btn-transparent btn-link">
                                <strong>
                                    {this.props.financingEntity.enabled &&
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={CANCEL_ICON}/>
                                    }
                                    {!this.props.financingEntity.enabled &&
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={STAR_ICON}/>
                                    }
                                    {this.props.financingEntity.enabled ? 'Desactivar' : 'Reactivar'}
                                </strong>
                            </button>
                        </div>
                        <div className="col-md-2 pdL0 mgB text-center">
                            <button
                                onClick={() => this.setState({showOptionalFields: true, createOptField: false})}
                                className="btn btn-transparent btn-link">
                                <strong>
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Otros datos
                                </strong>
                            </button>
                        </div>
                        <div className="col-md-2 pdL0 mgB text-center">
                            <button
                                onClick={() => this.setState({showEditModal: true})}
                                className="btn btn-transparent btn-link">
                                <strong>
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={PENCIL_ICON}/>
                                Editar
                                </strong>
                            </button>
                        </div>
                        <div className="col-md-2 mgB pdL0 text-center">
                            <button
                                onClick={() => this.setState({showDeleteModal: true})}
                                className="btn btn-transparent btn-link">
                                <strong>
                                    <i style={{fontSize: '14px', marginRight: '10px'}} className={DELETE_ICON}/>
                                Eliminar
                                </strong>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (<React.Fragment>
                <div className="row">
                    <div className="col-6 pdL0 mgB">
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="btn btn-transparent btn-link">
                            <strong>
                                <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                                Volver
                            </strong>
                        </button>
                    </div>
                    <div className="col-6 pdL0 mgB">
                        <button
                            onClick={() => this.setState({showMenuOptional: !this.state.showMenuOptional})}
                            className="btn btn-transparent btn-link" style={{float:'right'}}>
                            <strong>Opciones</strong>
                        </button>
                    </div>
                </div>
                {this.renderMenuOptional()}
            </React.Fragment>
            );
        }
    }

    renderMenuOptional() {
        if (this.state.showMenuOptional) {
            return(
                <div className="row">
                    <div className="col-4 pdL0 mgB text-center">
                        <div
                            onClick={() => this.setState({showActivateModal: true, showMenuOptional:false})}
                            className="btn btn-transparent btn-link">
                            {this.props.financingEntity.enabled ? 'Desactivar' : 'Reactivar'}
                        </div>
                    </div>
                    <div className="col-4 pdL0 mgB text-center">
                        <div
                            onClick={() => this.setState({showEditModal: true, showMenuOptional:false})}
                            className="btn btn-transparent btn-link" >
                            Editar
                        </div>
                    </div>
                    <div className="col-4 pdL0 mgB text-center">
                        <div
                            onClick={() => this.setState({showDeleteModal: true, showMenuOptional:false})}
                            className="btn btn-transparent btn-link">
                            Eliminar
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<div></div>);
        }
    }

    renderEntityInfo() {
        return (
            <div>
                {this.renderActions()}
                <div className="row">
                    <div style={{maxHeight: '247px'}} className="col-md-3">
                        <ImagePreview
                            style = {
                                {
                                    height: '100%',
                                    width: '100%',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }
                            }
                            className="image-preview image-preview__xl bordered"
                            image={this.props.financingEntity.logo}>
                        </ImagePreview>
                    </div>
                    <div style={{maxHeight: '247px'}} className="col-md-9">
                        <p className="mgB0">Entidad financiera: <strong>{this.props.financingEntity.type.name}</strong></p>
                        <h1 className="section-headline mgB">{this.props.financingEntity.name} <span style={{color: 'lightgray'}}>({this.props.financingEntity.enabled ? 'Activa' : 'Desactivada'})</span></h1>
                        <div style={{minHeight: '150px'}} className="row bordered pd2 mgL0 mgR0">
                            <div style={{minHeight: '110px'}} className="col-md-3 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.financingEntity.newFinancings}</strong></p>
                                    <p className="mgB0">Financiamientos sin revisar</p>
                                </div>
                            </div>
                            <div style={{minHeight: '110px'}} className="col-md-3 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.financingEntity.waitingForApprovalFinancings}</strong></p>
                                    <p className="mgB0">Financiamientos esperando aprobación</p>
                                </div>
                            </div>
                            <div style={{minHeight: '110px'}} className="col-md-3 col-6 text-center mgB border-md-right border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.financingEntity.approvedFinancings}</strong></p>
                                    <p className="mgB0">Financiamientos aprobados</p>
                                </div>
                            </div>
                            <div style={{minHeight: '110px'}} className="col-md-3 col-6 text-center mgB border-sm-bottom">
                                <div className="vertical--center">
                                    <p className="subtitle-1 mgB0"><strong>{this.props.financingEntity.declinedFinancings}</strong></p>
                                    <p className="mgB0">Financiamientos que no proceden</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <React.Fragment>
                {this.renderEntityInfo()}
                {this.renderDeleteFinancingEntityModal()}
                {this.renderActivateFinancingEntityModal()}
                {this.renderEditFinancingEntityModal()}
                {this.renderOtherdataModal()}
            </React.Fragment>
        );
    }
}

/**
 * Properties validations.
 */
FinancingEntityDetailHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    user: PropTypes.object,
    isMobile: PropTypes.bool
};

export default withAlert()(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingEntityDetailHeader));
