import React from 'react';
import PropTypes from 'prop-types';

import { MAGNIFY_ICON, CLOSE_ICON } from 'common/constants/icon.constant';

/**
* Represents a simple text input.
*/
export default class SearchInput extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props} the required React properties.
     */
    constructor(props) {
        super(props);

        this.resetValue = this.resetValue.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    /**
    * Fired once the input text changes.
    *
    * @param {Proxy.dispatcher} event  contains the input's new value.
    */
    onTextChange(event) {
        let value = event.target.value;
        if (this.props.onlyNumbers) {
            if (isNaN(event.target.value)) {
                return;
            }
        }
        if (this.props.noSpecialCharacters) {
            value = value.replace(/[^\w\s]/gi, '');
        }
        if (this.props.onChange && value !== this.props.value) {
            this.props.onChange(value);
        }
    }


    /**
    * Reset the input value
    */
    resetValue() {
        if ( this.props.value !== '') {
            this.props.onChange('');
        }
    }


    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        const { value, clearable } = this.props;

        return (
            <div className={`${this.props.className || 'search-input'}`}>
                {this.props.floatingLabel &&
                    <span className={`label ${this.props.labelClassName} ${this.props.dangerLabel ? 'text-danger' : ''}`} style={this.props.labelStyle}>
                        {this.props.floatingLabel}
                    </span>
                }
                <input
                    style={this.props.inputStyle}
                    className={`${this.props.ActiveFilter} form-control`} 
                    disabled={this.props.disabled}
                    name={this.props.name}
                    onChange={this.onTextChange}
                    placeholder={this.props.label}
                    type="text"
                    value={value}
                />
                {clearable && value &&
                    <span
                        style={{top: this.props.floatingLabel ? '70%' : '50%'}}
                        className="search-input__reset-button search-input__icon"
                        onClick={this.resetValue}>
                        <i className={ CLOSE_ICON } />
                    </span>
                }
                {(!value || !clearable) && <span style={{top: this.props.floatingLabel ? '70%' : '50%'}} onClick={value ? this.props.onSubmit : undefined } className="search-input__icon search-input__icon--right"><i className={ MAGNIFY_ICON } /></span>}
            </div>
        );
    }
}


SearchInput.defaultProps = {
    clearable: true,
    noSpecialCharacters: false,
    onlyNumbers: false,
    ActiveFilter: ''
};

/**
 * S input properties validation.
 */
SearchInput.propTypes = {
    style: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    clearable: PropTypes.bool,
    ActiveFilter: PropTypes.string
};
