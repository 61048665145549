'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import * as userSelectors from 'common/store/selectors/user.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import FinancingCheckout from './financing-checkout';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            cart: transactionSelectors.getCart(state),
            error: transactionSelectors.getError(state),
            simpleError: transactionSelectors.getSimpleError(state),
            loading: transactionSelectors.getIsLoading(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        verifyOrderToken: (params) => {
            dispatch(transactionActions.verifyOrderToken(params));
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class TransactionCheckoutContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            from: queryString.parse(this.props.location.search)['from'],
            formToken: queryString.parse(this.props.location.search)['form']
        };
    }

    componentDidMount() {
        let queryObject = queryString.parse(this.props.location.search);
        this.props.verifyOrderToken({
            token: queryObject['t'],
            apikey: queryObject['apikey']
        });
       
    }

    componentDidUpdate() {
        if (this.props.cart && this.props.cart.onBack) {
            if (this.state.from !== this.props.cart.onBack) {
                this.setState({from: this.props.cart.onBack});
            }
        }

    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body" >
                <div className="layout-body__header pdB0">
                    <div className="row align-items-center mgB">
                        <div className="col-12">
                            <h2 className="section-headline">Solicitud de financiamiento</h2>
                        </div>
                    </div>
                </div>
                <hr className="w-100"/>
                {this.props.loading &&
                   <LoadingComponent
                        height={160}
                        width={120}
                        />
                }
                {!this.props.loading &&
                    this.props.cart !== null &&
                    <div className="layout-body__content">
                        <div className="transaction-checkout transaction-checkout-wrapper">
                            <FinancingCheckout
                                cart={this.props.cart}
                                from={this.state.from}
                                formToken={this.state.formToken}
                                history={this.props.history}
                                location={this.props.location}
                                simpleError={this.props.simpleError}
                                authUser={this.props.authUser}
                                isMobile={this.state.isMobile}
                            />
                        </div>
                    </div>
                }
                {this.props.error &&
                    <React.Fragment>
                        <EmptyState
                            height={100}
                            image="/images/error.svg"
                            title="Error"
                            text={this.props.error}
                        />
                        <div className="text-center mgT2">
                            <button
                                onClick={() => location.replace(this.state.from)}
                                className="btn btn-primary">
                                Volver a la tienda
                            </button>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

TransactionCheckoutContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(TransactionCheckoutContainer));
