import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import Media from 'react-media';

import {createDataset} from './chart.default';

export default class DoughnutChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: createDataset(this.props.data, this.props.labels),
            options: {
                plugins: {
                    datalabels: {
                        formatter: () => {
                            return '';
                        }
                    }
                },
                legend: {position: 'bottom'}
            }
        };

        if (this.props.tooltipsCallback) {
            this.state.options['tooltips'] = {callbacks: {label: this.props.tooltipsCallback}};
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div style={{margin: 'auto'}}>
                <p><strong>{this.props.yLabel || ''}</strong></p>
                <Media query='(max-width: 600px)'>
                    {matches =>
                        matches ? (
                            <Doughnut
                                className={`chart doughnut-chart ${this.props.className}`}
                                data={this.state.data}
                                width={window.screen.width * 0.9}
                                options={this.state.options}
                                height={350}
                            />
                        ) : (
                            <Doughnut
                                className={`chart doughnut-chart ${this.props.className}`}
                                data={this.state.data}
                                options={this.state.options}
                                width={window.screen.width * 0.3}
                                height={400}
                            />
                        )
                    }
                </Media>
                <p className='text-center'>{this.props.xLabel || ''}</p>
            </div>

        );
    }
}

DoughnutChart.defaultProps = {className: ''};


/**
 * Properties validations.
 */
DoughnutChart.propTypes = {
    lineTension: PropTypes.number,
    className: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.array.isRequired
};
