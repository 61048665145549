import React from 'react';
import PropTypes from 'prop-types';

export default class RequiredFieldsWrapper extends React.Component {

    onSubmit = () => {
        if (this.props.validSubmit()) {
            this.props.onSubmit();
        } else {
            this.showErrorFields();
        }
    };

    showErrorFields = () => {
        const errorClass = 'required-field';
        let errorFields = {};

        for (const item in this.props.errorFields) {
            errorFields[item] = { errorClass: null, dangerLabel: false };
        }

        for (const item in this.props.fields) {
            if (this.props.fields[item] === null || this.props.fields[item] === '') {
                errorFields[item] = {
                    errorClass: errorClass,
                    dangerLabel: true
                };
            }
        }

        this.props.onUpdateErrorFields(errorFields);
        setTimeout(() => {
            this.scrollToFieldByClass(errorClass);
        }, 10);
    };

    scrollToFieldByClass = (className) => {
        const elements = document.getElementsByClassName(className);
        if (elements.length > 0) {
            const offset = document.getElementById(this.props.containerId).scrollTop + elements[0].getBoundingClientRect().top;
            document.getElementById(this.props.containerId).scrollTo(0, offset - 180);
        }
    };

    render() {
        return (
            <React.Fragment>
                {React.cloneElement(this.props.children, {onClick: this.onSubmit})}
            </React.Fragment>
        );
    }
}

RequiredFieldsWrapper.defaultProps = {containerId: 'html'};

RequiredFieldsWrapper.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    validSubmit: PropTypes.func.isRequired,
    onUpdateErrorFields: PropTypes.func.isRequired,
    errorFields: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    containerId: PropTypes.string
};
