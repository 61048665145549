import { createSelector } from 'reselect';

export const getError = state => state.transactionReducer.error;
export const getSimpleError = state => state.transactionReducer.simpleError;
export const getUploadError = state => state.transactionReducer.uploadError;
export const getIsLoading = state => state.transactionReducer.loading;
export const getCart = state => state.transactionReducer.cart;
export const getFinancingForm = state => state.transactionReducer.financingForm;
export const getFinancingEntities = state => state.transactionReducer.financingEntities;
export const getFinancingCreated = state => state.transactionReducer.financingCreated;
export const getTempFinancingDelete = state => state.transactionReducer.tempFinancingDeleted;
export const getTempFinancing = state => state.transactionReducer.tempFinancing;
export const getTemporaryFinancing = state => state.transactionReducer.temporaryFinancing;
export const getFinancingUpdated = state => state.transactionReducer.financingUpdated;
export const getNewFinancingCreated = state => state.transactionReducer.newfinancingCreated;
export const getFinancingManuallyUpdated = state => state.transactionReducer.financingManuallyUpdated;
export const getCreatingFinancing = state => state.transactionReducer.creatingFinancing;
export const getFinancingProfile = state => state.transactionReducer.singleFinancing;
export const getDashboard = state => state.transactionReducer.dashboard;
export const getFinancingsTotals = state => state.transactionReducer.financingTotals;
export const totWaitingQuote = state => state.transactionReducer.waitingquote;

export const getFinancings = state => state.transactionReducer.financings;
export const getCardTransactions = state => state.transactionReducer.cardTransactions;
export const getPagination = state => state.transactionReducer.pagination;
export const getFinancingsFilters = state => state.transactionReducer.financingsFilters;
export const getPlansToCompare = state => state.transactionReducer.plansToCompare;
export const getFinancingEntityType = state => state.transactionReducer.financingEntityType;
export const getAllFinancingEntityTypes = state => state.transactionReducer.financingEntityTypes;

export const getFinancingActions = state => state.transactionReducer.financingActions;
export const getFinancingActionsLoading = state => state.transactionReducer.financingActionsLoading;
export const getFinancingActionsLoadingModal = state => state.transactionReducer.financingActionsLoadingModal;
export const getFinancingActionsError = state => state.transactionReducer.financingActionsError;

export const getCardTransactionStatus = state => state.transactionReducer.cardTransaction;


export const getFilteredFinancingEntities = createSelector(
    [getFinancingEntities, getFinancingEntityType],
    (financingEntities, type) => {
        return financingEntities.filter(el => el.type.id === type);
    }
);
