/* eslint-disable react/jsx-key */
/* eslint-disable no-useless-constructor */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    TextInput
} from 'common/components/inputs';

import {
    ARROW_RIGHT,
    ARROW_LEFT,
    CASH_ICON,
    CLOSE_CIRCLE_ICON
} from 'common/constants/icon.constant';


export default class BinCardsComponent extends React.PureComponent {
    constructor (props) {
        super(props);

        this.state={
            canSubmit: true,
            listbinsToEnable: [],
            listbinsToDisable: [],
            binvalue: '',
            errorFields: { bin: { errorClass: null, dangerLabel: false } },
            error: '',
            showModal: false
        };

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    canSubmit = (canSubmit) => {
        this.setState({ canSubmit: canSubmit });
    };

    setList = (id) => {
        let newlist = this.state.listbinsToDisable;
        let pos = this.state.listbinsToDisable.indexOf(parseInt(id));
        let row = document.getElementById(this.props.financingEntity + ':' + id);
        if (pos > -1) {
            newlist.splice(pos, 1);
            row.classList.remove("color-blue");
        } else {
            newlist.push(parseInt(id));
            row.classList.add("color-blue");
        }
        this.setState({listbinsToDisable: newlist});
    };

    setListDisable = (id) => {
        let newlist = this.state.listbinsToEnable;
        let pos = this.state.listbinsToEnable.indexOf(parseInt(id));
        let row = document.getElementById(this.props.financingEntity + ':' + id);
        if (pos > -1) {
            newlist.splice(pos, 1);
            row.classList.remove("color-blue");
        } else {
            newlist.push(parseInt(id));
            row.classList.add("color-blue");
        }
        this.setState({listbinsToEnable: newlist});
    };

    setEnable = (val) =>{
        if (val === true) {
            this.props.enableBinCard(this.state.listbinsToEnable, val);
            for(let i=0; i< this.state.listbinsToEnable.length; i++) {
                let row = document.getElementById(this.props.financingEntity + ':' + this.state.listbinsToEnable[i]);
                row.classList.remove("color-blue");
            }
            this.setState({listbinsToEnable:[]});
        } else {
            this.props.enableBinCard(this.state.listbinsToDisable, val);
            for(let i=0; i< this.state.listbinsToDisable.length; i++) {
                let row = document.getElementById(this.props.financingEntity + ':' + this.state.listbinsToDisable[i]);
                row.classList.remove("color-blue");
            }
            this.setState({listbinsToDisable:[]});
        }
    };

    bulkDelete = () => {
        let totaldelete = this.state.listbinsToDisable.concat(this.state.listbinsToEnable);
        this.props.deleteBinCard(totaldelete);
        for(let i=0; i< totaldelete.length; i++) {
            let row = document.getElementById(this.props.financingEntity + ':' + totaldelete[i]);
            row.classList.remove("color-blue");
        }
        this.setState({listbinsToDisable:[], listbinsToEnable:[]});
    };

    appendBin = (val) => {
        this.setState({binvalue: ''}, this.props.appendBinCard(val));
    };

    showPlansModal(){
        if (this.state.listbinsToDisable.length === 0) {
            this.setState({error:'Debe seleccionar uno de los bines habilitado.'});
        } else if (this.state.listbinsToDisable.length > 1) {
            this.setState({error:'Esta opción requiere un solo bin seleccionado.'});
        } else {
            this.props.plansToApply(this.state.listbinsToDisable[0]);
        }
    }

    render() {
        let binsenabled = this.props.cardbins.filter((el) => el.enabled === 1);
        let binsdisabled = this.props.cardbins.filter((el) => el.enabled === 0);
        return (
            <div >
                <div className='row mgT' >
                    <div className='col-md-8'>
                        <Formsy
                            ref={this.basicDataRef}
                            onValid={() => this.canSubmit(true)}
                            onInvalid={() => this.canSubmit(false)}>
                            <FormsyInputWrapper
                                required
                                className="col-12 "
                                name="binvalue"
                                errorClassName={this.state.errorFields.bin.errorClass}
                                validations={{matchRegexp:/[0-9]{6}|[0-9]{8}/}}
                                value={this.state.binvalue}>
                                <TextInput
                                    label={<span>Número BIN asignado<span className="color-red">*</span></span>}
                                    maxLength={8}
                                    inputStyle={{fontSize:'18px'}}
                                    numbers={true}
                                    onlyNumbers={true}
                                    dangerLabel={this.state.errorFields.bin.dangerLabel}
                                    placeholder="Inserte el BIN "
                                    value={this.state.binvalue}
                                    onChange={(val) => this.setState({binvalue: val})}
                                />
                            </FormsyInputWrapper>
                        </Formsy>
                    </div>
                    <div className='col-md-4'>
                        <button
                            className="btn btn-primary mgT3"
                            onClick={() => this.appendBin(this.state.binvalue)}
                        >Adicionar</button>
                    </div>
                </div>
                <hr></hr>
                <div style={{border:'solid 1px #d2d2d2'}}>
                    <div style={{height:'285px'}} className=" white-background">
                        <div className='row mgL2' style={{width:'95%', marginLeft:'10px'}} >
                            <div className='col-md-5 mgT fsbold'><span >Habilitados</span></div>
                            <div className='col-md-2 mgT'></div>
                            <div className='col-md-5 mgT fsbold'><span>Deshabilitados</span></div>
                            <div className='col-md-5 mgB2 bordered' style={{height:'200px', overflowY:'scroll'}}>
                                {binsenabled.map((el) => {
                                    return(
                                        <React.Fragment key={`binval-${el.id}`}>
                                            <div
                                                className='row'
                                                id={this.props.financingEntity + ':' + el.id}>
                                                <div
                                                    className={`col-12 text-left ${el.financing_plans[0] !== '-1' ? 'fsbold fsize-16' : ' fsize-14'}`}
                                                    onClick = {() => this.setList(el.id)} >
                                                    <span key={`binval-${el.id}`}>
                                                        {el.binvalue.substring(0, 4) + ' '+ el.binvalue.substring(4, 8)}</span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className='col-md-2 mgB2'>
                                <div className='row text-center' style={{fontSize:'30px'}}>
                                    <div className='col-12 color-blue cursor-pointer' disabled={this.state.listbinsToDisable.length === 0}><a onClick={() => this.setEnable(false)} ><i className={ARROW_RIGHT} /></a></div>
                                    <div className='col-12 color-blue cursor-pointer' disabled={this.state.listbinsToEnable.length === 0}><a onClick={() => this.setEnable(true)} ><i className={ARROW_LEFT} /></a></div>
                                    <div className='col-12 color-blue cursor-pointer'><a onClick={() => this.showPlansModal()} ><i className={CASH_ICON}/></a></div>
                                    <div className='col-12 color-red cursor-pointer'><a onClick={() => this.bulkDelete()} ><i className={CLOSE_CIRCLE_ICON}/></a></div>
                                </div>
                            </div>
                            <div className='col-md-5 mgB2 bordered' style={{ height:'200px', overflowY:'scroll'}}>
                                {binsdisabled.map((el) => {
                                    return(
                                        <React.Fragment key={`binval-${el.id}`}>
                                            <div
                                                className='row'
                                                id={this.props.financingEntity + ':' + el.id} >
                                                <div
                                                    className='col-12 text-left'
                                                    style={{fontSize:'14px'}}
                                                    onClick = {() => this.setListDisable(el.id)}>
                                                    <span >
                                                        {el.binvalue.substring(0, 4) + ' '+ el.binvalue.substring(4, 8)}</span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className='col-md-12 text-center mgTm2' ><span className='color-red'>{this.state.error}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BinCardsComponent.propTypes = {
    cardbins: PropTypes.array,
    appendBinCard: PropTypes.func,
    enableBinCard: PropTypes.func,
    deleteBinCard: PropTypes.func,
    plansToApply: PropTypes.func
};