import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import money from 'common/helpers/money';
import moment from 'moment';
import $ from 'jquery';
import {
    FormsyInputWrapper,
    Radio,
    MoneyInput,
    TextInput,
    SelectInput
} from 'common/components/inputs';
import EmptyState from 'common/components/empty-state';

import Modal from 'common/components/modal';
import FinancingSlider from 'common/components/financing-slider';
import {FINANCING_ENTITY_TYPE} from 'common/constants/enums.constant';

const toRegexRange = require('to-regex-range');
/**
* Component responsible of the forgot password form.
*/
export default class SelectPlanForm extends React.Component {
    /**
  * Initializes the form.
  */
    constructor(props) {
        super(props);

        this.state = {
            showFinancingSlider: true,
            payments: null,
            showCoopAnnounce: false,
            requireWarranties: false,
            optionalDelivery: true,
            entType: 1,
            cardsData: {
                cardNumber: this.props.cardInit.cardNumber,
                monthValid: this.props.cardInit.monthValid,
                yearValid: this.props.cardInit.yearValid,
                cvv: this.props.cardInit.cvv,
                owner: this.props.cardInit.owner,
                cardtype: null
            },
            errorFields: {
                cardNumber: { errorClass: null, dangerLabel: false },
                monthValid: { errorClass: null, dangerLabel: false },
                yearValid: { errorClass: null, dangerLabel: false },
                cvv: { errorClass: null, dangerLabel: false }
            },
            showPlans: true,
            showCard: false,
            plansBins: [],
            showBrancheModal: false,
            branchSelected: null
        };

        this.showAdvancefrag = false;

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    /**
  * Fired after formsy validations
  */
    canSubmit(canSubmit) {
        this.props.canSubmit(canSubmit);
    }

    componentDidMount() {
        if (this.props.cardSelected === true ) {
            this.setState({entType: 4, cardsData:{owner: this.props.dataClient.name + ' ' + this.props.dataClient.lastname}});
        } else {
            this.setState({entType: 1});
        }
    }


    getAllPlans = () => {
        let plans = [];
        this.props.financingEntities.forEach(entity => {
            plans = plans.concat(entity.plans.filter(el => {
                let minimum = el.minimum === null ? 0 : el.minimum.amount;
                let maximum = el.maximum === null ? 1000000000000000 : el.maximum.amount;
                if (this.props.discount === true) {
                    return ((el.showIfDiscount === true) && (
                        minimum <= this.props.topay && maximum >= this.props.topay));
                } else {
                    return ((el.showNotDiscount === true) && (
                        minimum <= this.props.topay && maximum >= this.props.topay));
                }
            }));
            plans.forEach(entplans => {
                entplans.financingEntity.type = entity.type;
            });
        });
        plans = plans.map((pl) => {
            return (this.state.entType !== 4) ? Object.assign({}, pl, {inPromo: this.planInPromo(pl.id)}) : Object.assign({}, pl, {inPromo: this.planInPromo(pl.id, this.state.cardsData.cardNumber.substring(0,6) )});
        });

        return plans;
    };

    getPlans = () => {
        let planes = this.props.financingEntities.filter(el => el.id === this.props.financingEntity)[0].plans.filter(el => {
            let minimum = el.minimum === null ? 0 : el.minimum.amount;
            let maximum = el.maximum === null ? 1000000000000000 : el.maximum.amount;
            if (this.props.discount === true) {
                return ((el.showIfDiscount === true) && (
                    minimum <= this.props.topay && maximum >= this.props.topay));
            } else {
                return ((el.showNotDiscount === true) && (
                    minimum <= this.props.topay && maximum >= this.props.topay));
            }
        });
        planes = planes.map((pl) => {
            return (this.state.entType !== 4) ? Object.assign({}, pl, {inPromo: this.planInPromo(pl.id)}) : Object.assign({}, pl, {inPromo: this.planInPromo(pl.id, this.state.cardsData.cardNumber.substring(0,6) )});
        });
        this.showAdvancefrag = false;
        return planes;
    };

    planInPromo = (planId, binEntried = -1) => {
        let promo = [];
        if ((this.state.entType !== 4) && (binEntried === -1)) {
            promo = this.props.promotionsActivated.filter((pro) => {
                return pro.rules.filter((rule) => {
                    return (rule.plans[0] !== '-1' && rule.plans.filter(pl => { return pl.id === planId; }).length > 0);
                }).length > 0;
            });
        } else {
            promo = this.props.promotionsActivated.filter((pro) => {
                return pro.rules.filter((rule) => {
                    return (rule.bins[0] !== '-1' && rule.bins.filter(bi => {
                        return bi.bin_value === binEntried && bi.plans.split(',').indexOf(planId.toString()) > -1;
                    }).length > 0);
                }).length > 0;
            });
        }
        return (promo.length > 0) ? promo[0] : null;
    };

    setFinancingEntityType = (val) => {
        this.props.onFieldChange('financingEntity', null);
        this.props.setFinancingEntityType(val);
        let allE = document.getElementsByClassName('react-tabs__tab');
        for(let i=0; i<allE.length; i++) {
            allE[i].classList.remove('react-tabs__tab--selected');
        }
        allE[val-1].classList.add('react-tabs__tab--selected');
        this.setState({showFinancingSlider:true, entType:val, showCoopAnnounce: false, cardsData: {owner: this.props.dataClient.name + ' ' + this.props.dataClient.lastname}, plansBins: [], showPlans: false, optionalDelivery: null});
    };

    hideSlider = () => {
        this.setState({showFinancingSlider:false});
    };

    setPaymentsGeneral = (payments) => {
        this.setState({payments:payments});

    };
    onFieldChange = (field, val) => {
        let data = {...this.state.cardsData};
        data[field] = val;
        let imgcard = '';
        if ((field === 'cardNumber') && val.length > 14) {
            let showC = false;
            if (/^(?:4[0-9]{12}(?:[0-9]{3})?)$/.test(val)) {
                imgcard = '/images/visacard.png';
                data['cardtype'] = 'VC';
                showC = true;
            } else if (/^(?:5[1-5][0-9]{14})$/.test(val)) {
                imgcard = '/images/mascard.png';
                data['cardtype'] = 'MC';
                showC = true;
            } else if (/^(?:3[47][0-9]{13})$/.test(val)) {
                imgcard = '/images/amexcard.png';
                data['cardtype'] = 'AMC';
                showC = true;
            }
            let plans = [];
            let entSelected = this.props.financingEntities.filter((ent) => { return ent.id === this.props.financingEntity; });
            plans = entSelected[0].bins.filter((bin) => { return bin.binvalue.includes(val.substring(0, 6)); });
            if (plans.length > 0) {
                plans = plans[0].financing_plans.map((fp) => { return fp.id; });
            }
            this.setState({plansBins: plans, showPlans: true, imgCard: imgcard, showCard: showC});
        }
        this.setState({cardsData: data}, this.props.updateCardData(data));
    };


    renderAdvancePayment = () => {
        let minLimit = -1;
        let maxLimit = 1000000000000000;
        let paymentPlan;

        if (this.props.plansToCompare.length === 1) {
            let plan = this.props.plansToCompare[0];
            if (plan.minAdvancePayment) {
                this.showAdvancefrag = true;
                minLimit = plan.minAdvancePayment * this.props.total;
            }
            if (plan.maxAdvancePayment) {
                this.showAdvancefrag = true;
                maxLimit = plan.maxAdvancePayment * this.props.total;
            }
            paymentPlan = plan.payments;
        }
        maxLimit;
        if (this.showAdvancefrag && this.state.payments === paymentPlan) {
            return (
                <div className="row mgB">
                    <FormsyInputWrapper
                        className="col-12"
                        name="advancePayment"
                        value={this.props.advancePayment / 100}
                        validations = {{
                            advanceValidation: function (values, value) {
                                if (value !== null || value !== undefined) {
                                    if (value === 0) {
                                        return true;
                                    } else if (value >= minLimit) {
                                        return true;
                                    }
                                }
                                return false;
                            }
                        }}
                    >
                        <MoneyInput
                            floatingLabel={<span>Adelanto <span className="color-red">*</span></span>}
                            placeholder={`${minLimit > -1 ? 'Mínimo: ' + (minLimit) : ''}`}
                            value={this.props.advancePayment / 100}
                            onChange={(val) => this.props.onFieldChange('advancePayment', val * 100)}
                        />
                        <p className="color-red">El monto mínimo debe ser al menos {this.props.plansToCompare[0].currency.symbol + '$ ' + money(minLimit)}. Al dejarlo en blanco se asume el mínimo calculado. </p>
                    </FormsyInputWrapper>

                </div>
            );
        }
        return <div></div>;
    };

    onChangeEntity = (element) => {
        if (element.type.id < 4) {
            this.setState({showCoopAnnounce:element.only_partners, requireWarranties: element.require_warranties, plansBins: [], showPlans: true, optionalDelivery: element.optional_delivery});
        } else {

            let newcardsData = {
                cardNumber: '',
                monthValid: '',
                yearValid: '',
                cvv: '',
                owner: this.props.dataClient.name + ' ' + this.props.dataClient.lastname,
                cardtype: null
            };
            this.setState({
                showCoopAnnounce:element.only_partners,
                requireWarranties: element.require_warranties,
                plansBins: [],
                showPlans: false,
                optionalDelivery:
                 element.optional_delivery,
                cardsData: newcardsData,
                showCard: false
            });
        }
        this.props.onFieldChange('financingEntity', element.id);
    };

    promoToApply = (promo) => {
        this.props.onPromotoApply(promo);
    };


    getShowCard = () => {
        let val = false;

        if (this.props.financingEntities.length > 0) {
            let finanEnt = this.props.financingEntities.filter((f) => { return f.id === this.props.financingEntity; });
            val = finanEnt[0].plans.filter((p) => { return p.minimum.amount < this.props.total; }).length > 0;
        }
        return val;
    };

    menuOpen() {
        var top = $('#ModalView')[0].scrollHeight;
        $('body, #ModalView').animate({scrollTop: top}, 1000);
    }

    renderShowBranche() {
        let entity = this.props.financingEntities.filter((ent) => { return ent.id === this.props.financingEntity; })[0];
        return (<Modal
            overlayClassName="o-inherit"
            onModalClose={() => this.setState({showBrancheModal: false})}
            closable={false}
            noDivider={true}
            showModal={this.state.showBrancheModal}
            id='ModalView'>
            <div>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '170px',
                            padding: '20px'
                        }
                    }
                    small={true}
                    image="/images/activate_entity.svg"
                    title=""
                    text=""
                />
                <h2 className="text-center">Sucursales</h2>
                <p className="text-center"> En caso de escoger una sucursal de esta entidad. Estará solicitando ser atendido por ellos. </p>

                <div style={{margin: '15px 0px'}} className="row">
                    <SelectInput
                        value={this.state.branchSelected}
                        label="Seleccione"
                        withAllOption={'Ninguna'}
                        options={entity.branches.map((bra) => {
                            return {
                                label: bra.branch_name,
                                value: bra.id
                            };
                        })}
                        onChange={(val) => this.setState({branchSelected: val}, this.props.onFieldChange('branch_id', val))}
                        onMenuOpen={this.menuOpen}
                    />
                </div>
                <div style={{margin: '0px'}} className="row">
                    <button
                        onClick = {
                            () => this.setState({showBrancheModal: false})
                        }
                        className="btn btn-outline col-md-4">
                        Aceptar
                    </button>
                </div>
            </div>
        </Modal>);
    }


    onKeyPress = (e) => {
        if(e.charCode === 13) {
            e.preventDefault();
        }
    };
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        let yearNow = parseInt(moment().year().toString().substring(2));
        let yaerPlusTen = yearNow + 10;
        const source = toRegexRange(yearNow.toString(), yaerPlusTen.toString());
        let regYear = new RegExp(source);

        let entities = this.props.financingEntities;
        if (this.props.cart.data.entity_id !== undefined && this.props.cart.data.entity_id !== null && this.props.financingEntityType === 4) {
            entities = this.props.financingEntities.filter((ef) => { return ef.id === this.props.cart.data.entity_id; });
        }
        return (
            <Formsy
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                <FormsyInputWrapper
                    required
                    name="financingEntity"
                    errorClassName={this.props.errorFields.financingEntity.errorClass}
                    value={this.props.financingEntity !== null && this.props.financingEntity !== undefined}
                    validations="isTrue"
                    className="mgB3">
                    <Radio
                        selectedValue={this.props.financingEntity}
                        radioStyle={{ padding: '0px' }}
                        className="row"
                        label={<span>Selecciona la entidad con la que desea financiar <span className="color-red">*</span></span>}
                        optionsClassName="col-6 col-md-4"
                        inputClassName="position-absolute d-block"
                        innerInputStyle={{right: '25px', zIndex: 1}}
                        dangerLabel={this.props.errorFields.financingEntity.dangerLabel}
                        outerLabelStyle={{ marginBottom: '10px', fontSize: '1rem', fontWeight: 800 }}
                        options={entities.map(element => {
                            return {
                                value: element.id,
                                button: {
                                    onClick: () => this.onChangeEntity(element),
                                    show: true,
                                    image: element.logo,
                                    className: `${element.id === this.props.financingEntity ? 'bordered-blue' : 'bordered'}`
                                }
                            };
                        })}>
                        <div>
                            <div className='row pdL2 pdR2'>
                                {this.props.financingEntityTypes.map(el => {
                                    return (
                                        <div
                                            key={`enti-${el.id}`}
                                            id={`enti-${el.id}`}
                                            className={`${(this.props.cardSelected && el.id === 4) ? 'react-tabs__tab--selected' : (this.props.cardSelected === false && el.id === 1) ? 'react-tabs__tab--selected' : '' } col-6 col-md-3 react-tabs__tab`}
                                            onClick={() => this.setFinancingEntityType(el.id)}
                                        >
                                            {el.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Radio>
                </FormsyInputWrapper>
                { this.props.financingEntity !== null
                && this.props.financingEntity !== undefined
                && this.props.financingEntities.length > 0
                && this.props.financingEntities.filter((ent) => { return ent.id === this.props.financingEntity; })[0].branches.length > 0 &&
                <React.Fragment>
                    <p className="text-center color-blue fsize-16" style={{marginBottom:'0px'}}>Sucursal que lo atenderá (<span className="color-red">Opcional</span>)</p>

                    <div className='row pdB15'>
                        {this.state.branchSelected === null &&
                      <React.Fragment>
                          <div className='col-3'/>
                          <button className='col-6 btn btn-primary' onClick={() => this.setState({showBrancheModal:true})}>Escoger sucursal</button>
                          <div className='col-3'/>
                      </React.Fragment>
                        }
                        {this.state.branchSelected !== null &&
                      <React.Fragment>
                          <div className='col-8 mgL pdT fsbold text-center'>{
                              this.props.financingEntities.filter((ent) => { return ent.id === this.props.financingEntity; })[0]
                                  .branches.filter((br) => { return br.id === this.state.branchSelected; })[0].branch_name
                          }</div>
                          <button className='col-3 btn btn-primary' onClick={() => this.setState({showBrancheModal:true})}>Cambiar</button>
                          <div className='col-1'/>
                      </React.Fragment>
                        }
                    </div>
                </React.Fragment>
                }

                {this.state.entType === FINANCING_ENTITY_TYPE.COOPERATIVE && this.state.showCoopAnnounce &&
                    <p style={{fontSize:'12px', fontWeight:'bold'}} className="text-center color-red">Esta entidad esta restringida solo para asociados. Si usted no lo es, su solicitud no procederá con éxito.</p>
                }
                {this.state.requireWarranties &&
                    <p style={{fontSize:'14px', fontWeight:'bold'}} className="text-center color-red">Esta entidad requiere garantía vehicular o inmobiliaria. Usted deberá facilitar la documentación en la solicitud.</p>
                }
                {this.state.optionalDelivery !== null && this.state.optionalDelivery === false &&
                    <p style={{fontSize:'14px', fontWeight:'bold'}} className="text-center color-red">Esta entidad no incluye los gastos de transportación en el financiamiento.</p>
                }
                {this.state.entType === 4 &&
                this.props.financingEntity !== null
                && this.props.financingEntity !== undefined
                && this.props.financingEntities.length > 0 &&
                this.getShowCard() &&
                <React.Fragment>
                    <div>
                        <div className='row'>
                            <FormsyInputWrapper
                                required
                                name="cardNumber"
                                value={this.state.cardsData.cardNumber}
                                errorClassName={this.state.errorFields.cardNumber.errorClass}
                                validations="isValidCard"
                                className="col-9 col-md-7">
                                <TextInput
                                    placeholder="Escriba el número"
                                    label="Número de Tarjeta"
                                    onlyNumbers={true}
                                    onKeyPress={this.onKeyPress}
                                    dangerLabel={/^(?:4[0-9]{12}(?:[0-9]{3})?)|(?:5[1-5][0-9]{14})|(?:3[47][0-9]{13})$/.test(this.state.cardsData.cardNumber) === false}
                                    numbers={true}
                                    maxLength={16}
                                    value={this.state.cardsData.cardNumber}
                                    onChange={(val) => this.onFieldChange('cardNumber', val)}/>
                            </FormsyInputWrapper>
                            <div className="col-3 col-md-5" >
                                {this.state.showCard &&
                                    <img src={this.state.imgCard} alt='' style={{height:'30px', width:'50px', marginTop:'30px'}}/>
                                }
                            </div>
                            <FormsyInputWrapper
                                required
                                name="monthValid"
                                value={this.state.cardsData.monthValid}
                                errorClassName={this.state.errorFields.monthValid.errorClass}
                                errorMessage="Formato de mes no valido"
                                validations={{matchRegexp:/(0[1-9]|1[012])/}}
                                className={(this.props.isMobile === true) ? 'col-6' : 'col-3'}>
                                <TextInput
                                    placeholder="Mes"
                                    label='Fecha de caducidad'
                                    dangerLabel={(/^(0[1-9]|1[012])$/.test(this.state.cardsData.monthValid) === false)}
                                    maxLength={2}
                                    onKeyPress={this.onKeyPress}
                                    onlyNumbers={true}
                                    value={this.state.cardsData.monthValid}
                                    onChange={(val) => this.onFieldChange('monthValid', val)}
                                />
                            </FormsyInputWrapper>
                            <FormsyInputWrapper
                                required
                                name="yearValid"
                                value={this.state.cardsData.yearValid}
                                errorClassName={this.state.errorFields.yearValid.errorClass}
                                errorMessage="Formato de año no valido"
                                validations={{matchRegexp:regYear}}
                                className={(this.props.isMobile === true) ? 'col-6' : 'col-3'}>
                                <TextInput
                                    placeholder="Año"
                                    label=''
                                    dangerLabel={(regYear.test(this.state.cardsData.yearValid) === false)}
                                    maxLength={2}
                                    onKeyPress={this.onKeyPress}
                                    onlyNumbers={true}
                                    value={this.state.cardsData.yearValid}
                                    onChange={(val) => this.onFieldChange('yearValid', val)}
                                    inputStyle={{marginTop:'1.5rem' }}
                                />
                            </FormsyInputWrapper>
                            {this.props.isMobile !== true &&
                            <div className='col-6'></div>
                            }
                            <FormsyInputWrapper
                                required
                                name="cvv"
                                value={this.state.cardsData.cvv}
                                validations="isValidCVV"
                                className={(this.props.isMobile === true) ? 'col-6' : 'col-2'}>
                                <TextInput
                                    placeholder="CVV"
                                    label="CVV"
                                    dangerLabel={/[0-9]{3}|[0-9]{4}/.test(this.state.cardsData.cvv) === false}
                                    maxLength={4}
                                    onKeyPress={this.onKeyPress}
                                    onlyNumbers={true}
                                    value={this.state.cardsData.cvv}
                                    onChange={(val) => this.onFieldChange('cvv', val)}/>
                            </FormsyInputWrapper>
                            <div className={(this.props.isMobile === true) ? 'col-6' : 'col-10'}></div>
                            <FormsyInputWrapper
                                required
                                name="owner"
                                value={this.state.cardsData.owner !== null && this.state.cardsData.owner !== ''}
                                validations="isTrue"
                                className='col-12'>
                                <TextInput
                                    placeholder="Nombre completo"
                                    label="Titular"
                                    onKeyPress={this.onKeyPress}
                                    dangerLabel={this.state.cardsData.owner === null || this.state.cardsData.owner === ''}
                                    value={this.state.cardsData.owner}
                                    onChange={(val) => this.onFieldChange('owner', val)}></TextInput>
                            </FormsyInputWrapper>
                            <div className="col-4 wdivImg mgT"><img src='/images/mascard.png' alt='' style={{width:'50px', height: '30px'}}/></div>
                            <div className="col-4 wdivImg mgT"><img src='/images/visacard.png' alt='' style={{width:'50px', height: '30px'}}/></div>
                            <div className="col-4 wdivImg mgT"><img src='/images/amexcard.png' alt='' style={{width:'50px', height: '30px'}}/></div>
                        </div>

                    </div>
                </React.Fragment>
                }
                {this.state.entType === 4 &&
                    this.props.financingEntity !== null
                    && this.props.financingEntity !== undefined
                    && this.props.financingEntities.length > 0 &&
                    !this.getShowCard() &&
                    <p style={{fontSize:'14px', fontWeight:'bold'}} className="text-center color-red">Lo sentimos! La tarjeta no esta validada para pagos en cuotas.</p>
                }
                {this.props.financingEntity !== null && this.props.financingEntity !== undefined && this.props.financingEntities.length > 0 && this.state.showPlans &&
                    <FormsyInputWrapper
                        required
                        name="plan"
                        value={this.props.plansToCompare.length !== 0 || (this.props.plan !== null && this.props.plan !== undefined)}
                        validations="isTrue"
                        errorClassName={this.props.errorFields.plan.errorClass}
                        className="mgB3">
                        <FinancingSlider
                            key={'plans-' + this.props.financingEntity}
                            total={this.props.total}
                            advancePayment={this.props.advancePayment}
                            labelStyle={{ marginBottom: '20px', fontSize: '1rem', fontWeight: 800 }}
                            plansToCompare={this.props.plansToCompare}
                            selectPlanToCompare={(plan, value) => {
                                this.props.onPromotoApply(plan, value);
                                this.props.selectPlanToCompare(plan, value);
                                this.showAdvancefrag = true;
                            }}
                            dangerLabel={this.props.errorFields.plan.dangerLabel}
                            label={<span>Seleccione la cantidad de cuotas  <span className="color-red">*</span></span>}
                            currency={this.props.currency}
                            planSelected={this.props.plan}
                            plans={this.getPlans()}
                            allPlans={this.getAllPlans()}
                            payments={this.state.payments}
                            onUpdatePayments={this.setPaymentsGeneral}
                            plansInBins={this.state.plansBins}
                            entityTypeSelected={this.state.entType}
                            cardValid={this.state.showCard}
                            deliveryCost={this.props.deliveryCost}
                            isMobile={this.props.isMobile}
                            cart = {this.props.cart}
                        />
                    </FormsyInputWrapper>
                }
                {this.renderAdvancePayment()}
                {this.props.financingEntity !== null && this.props.financingEntity !== undefined && this.props.financingEntities.length > 0 && this.renderShowBranche()}
            </Formsy>
        );
    }
}

/**
 * Forgot password form properties validation.
 */
SelectPlanForm.propTypes = {
    financingEntities: PropTypes.array,
    financingEntity: PropTypes.number,
    plan: PropTypes.number,
    total: PropTypes.number,
    currency: PropTypes.string,
    onFieldChange: PropTypes.func.isRequired
};
