'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';


import { PLUS_CIRCLE_ICON } from 'common/constants/icon.constant';
import {SearchInput} from 'common/components/inputs';
import Modal from 'common/components/modal';
import EmptyState from 'common/components/empty-state';

import CreateEditPromotion from '../promotion-detail/create-edit-promotion';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from '../../../common/store/actions/user/user.actions';
import * as promotionSelector from 'common/store/selectors/promotion.selector';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            dropdowns: userSelectors.getDropdowns(state),
            loading: promotionSelector.getIsLoading(state),
            promotionCreated: promotionSelector.getPromotionCreated(state),
            promotion: promotionSelector.getPromotion(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        }
    };
};

class PromotionsHeader extends React.PureComponent {

    constructor (props) {
        super (props);

        this.state = {
            showCreateModal: false
        };
    }

    componentDidMount() {
        if (this.props.dropdowns.stores.length === 0) {
            this.props.getDropdowns();
        }
    }
    componentDidUpdate() {
        if (this.props.promotionCreated === true) {
            location.replace('promotions/' + this.props.promotion.id);
        }
    }

    renderCreatePromotionModal() {
        let promotionData={
            startDate: null,
            endDate: null
        };
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCreateModal: false})}
                id='create-promotion-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showCreateModal}>
                <EmptyState
                    imgStyle={
                        {
                            borderRadius: '150px',
                            margin: 'auto',
                            width: '270px',
                            padding: '20px',
                            paddingBottom: '0px'
                        }
                    }
                    small={true}
                    image="/images/promotion_ok.svg"
                    title=""
                    text=""
                />
                <h3 className="text-center">Crear Promoción</h3>
                <p className="text-center">Campos requeridos están marcados con <span className="color-red">*</span> </p>

                <CreateEditPromotion
                    onClose={() => this.setState({showCreateModal:false})}
                    promotionData={promotionData}
                    onSavePromo={this.props.onCreatePromo}
                    drops={this.props.dropdowns}
                >
                </CreateEditPromotion>
            </Modal>
        );
    }

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB">
                <div className="col-6">
                    <h2 className="section-headline">Promociones o cupones ({this.props.total})</h2>
                </div>
                <div
                    className="col-6 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({showCreateModal: true})}
                        disabled={this.props.loading}
                    >
                        <i className={PLUS_CIRCLE_ICON} />
                        Crear Promoción
                    </button>
                </div>
            </div>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-md-12 mgB">
                        <SearchInput
                            floatingLabel={'Buscar'}
                            weight={500}
                            value={this.props.query}
                            label="Buscar por nombre, código"
                            onChange={(val) => this.props.onFiltersChange(['q', 'page'], [val, 1])}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render () {
        return(
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
                {this.renderCreatePromotionModal()}
            </div>
        );
    }

}

/**
 * Properties validations.
 */
PromotionsHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    user: PropTypes.object,
    query: PropTypes.string,
    onFiltersChange: PropTypes.func.isRequired,
    onCreatePromo: PropTypes.func.isRequired
};

export default withAlert()(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(PromotionsHeader));