'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import LoginForm from './login-form';

/**
 * Connect the login container to the redux store,
 * so we can dispatch a logUser event.
 */
function mapDispatchToProps(dispatch) {
    return {
        logUser: (email, password, rememberMe) => {
            dispatch(userActions.authUser(email, password, rememberMe));
        },
        validateEmail: (email) => {
            dispatch(userActions.validateEmail(email));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        authUser: userSelectors.getAuthUser(state),
        error: userSelectors.getError(state),
        validateEmailError: userSelectors.getValidateEmailError(state)
    };
};

/**
 * Container which handles the login logic.
 */
class LoginContainer extends React.Component {
    /**
     * Set the states and makes the binds as needed.
     */
    constructor(props) {
        super(props);

        this.onLogin = this.onLogin.bind(this);
        this.onFormChange = this.onFormChange.bind(this);

        this.state = {
            email: '',
            password: '',
            rememberMe: true
        };
    }

    /**
     * Fired once the email or the password changes.
     */
    onFormChange(field, value) {
        this.setState({[field]: value});
    }

    /**
     * Fired once the user attempts to log in.
     */
    onLogin() {
        const email = this.state.email;
        const password = this.state.password;
        const rememberMe = this.state.rememberMe;

        this.props.logUser(email, password, rememberMe);
    }

    onValidateEmail = (email) => {
        if (email !== '') {
            this.props.validateEmail(email);
        }
    };

    componentDidMount() {
        this.props.resetUserMessages();
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <React.Fragment>
                <div className="text-center">
                    <p>Por favor, ingresa tus datos</p>
                </div>
                <LoginForm
                    onLogin={this.onLogin}
                    email={this.state.email}
                    rememberMe={this.state.rememberMe}
                    onValidateEmail={this.onValidateEmail}
                    location={this.props.location}
                    password={this.state.password}
                    creatingFinancing={this.state.creatingFinancing}
                    error={this.props.error}
                    validateEmailError={this.props.validateEmailError}
                    onRegister={() => this.props.history.push('/register' + this.props.location.search)}
                    onFormChange={this.onFormChange}
                />
            </React.Fragment>
        );
    }
}

LoginContainer.defaultProps = {creatingFinancing: false};

/**
  * Container props validation.
  */
LoginContainer.propTypes = { logUser: PropTypes.func.isRequired };

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer));
