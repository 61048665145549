import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import { TextInput, FormsyInputWrapper } from 'common/components/inputs';


/**
* Component responsible of the forgot password form.
*/
export default class ForgotPasswordForm extends React.Component {
    /**
  * Initializes the form.
  */
    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            sendingEmail: false,
            validatingEmail: false
        };

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    /**
     * Fired after formsy validations
     */
    canSubmit(canSubmit) {
        this.setState({ canSubmit });
    }

    componentDidUpdate() {
        if (this.state.validatingEmail) {
            this.props.onValidateEmail(this.props.email);
            this.setState({validatingEmail: false});
        }

        if (this.props.isValidEmail && !this.state.sendingEmail) {
            this.setState({sendingEmail: true});
        }

        if (this.state.sendingEmail) {
            this.props.onSend();
            this.setState({sendingEmail: false});
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Formsy
                onValid={this.enableSubmit}
                onInvalid={this.disableSubmit}>
                {this.props.error &&
                    <div className='has-error col-12'>
                        <span className='help-block'>{this.props.error}</span>
                    </div>
                }
                <FormsyInputWrapper
                    required
                    name="Correo"
                    value={this.props.email}
                    validations="isEmail"
                    className="col-12 mgB3"
                    customError={this.props.validateEmailError}
                    validationError="Por favor ingrese un correo electrónico válido.">
                    <TextInput
                        label="Correo electrónico"
                        value={this.props.email}
                        dangerLabel={this.props.validateEmailError !== null}
                        onChange={this.props.onEmailChange}
                    />
                </FormsyInputWrapper>

                <div className="clearfix" />

                <div className="col-12 text-center mgB">
                    <button
                        onClick={() => {
                            this.setState({validatingEmail: true});
                        }}
                        className="btn btn-primary w-100"
                        disabled={!this.state.canSubmit}>
                        Enviar
                    </button>
                </div>

                <div className="col-12 text-center mgB">
                    ¿Ya tienes cuenta?
                    <Link to={"/"+this.props.location.search}> Iniciar sesión</Link>
                </div>
            </Formsy>
        );
    }
}

/**
 * Forgot password form properties validation.
 */
ForgotPasswordForm.propTypes = {
    email: PropTypes.string.isRequired,
    validateEmailError: PropTypes.string,
    emailSent: PropTypes.bool.isRequired,
    onSend: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired
};
