/*global accounting*/

export default function (n, formatNumber = false, decimals = true) {
    if (typeof accounting !== 'undefined') {
        if (!formatNumber) {
            if (decimals) {
                return accounting.formatMoney(n, '');
            }
            return accounting.formatNumber(n);
        } else {
            if (n.includes(".")) {
                return accounting.formatMoney(n, '');
            }
            return accounting.formatNumber(n);
        }
    }
    if (decimals) {
        return parseFloat(n).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return parseFloat(n).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
