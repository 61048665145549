'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Media from 'react-media';
import Steps, { Step } from 'rc-steps';

import {
    CIRCLE_OUTLINE_ICON,
    MARKED_CHECKBOX_ICON,
    CLOSE_CIRCLE_ICON
} from 'common/constants/icon.constant';

import ImagePreview from 'common/components/image-preview';
import calculateFinancing from 'common/helpers/calculate-financing';
import money from 'common/helpers/money';
import {
    CURRENCIES_IDS,
    FINANCING_STATUSES
} from 'common/constants/enums.constant';

export default class FinancingCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,

            waitingForQuoteIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_QUOTE ? CLOSE_CIRCLE_ICON : CIRCLE_OUTLINE_ICON,

            waitingForQuoteColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_QUOTE ? 'color-red' : 'color-primary',

            waitingForEntityIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                ((props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_ENTITY || (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_APPROVAL) ? CLOSE_CIRCLE_ICON : CIRCLE_OUTLINE_ICON,

            waitingForEntityColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                ((props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_ENTITY || (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_APPROVAL) ? 'color-red' : 'color-primary',

            waitingForStoreIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                ((props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_STORE || (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_CLIENT) ? CLOSE_CIRCLE_ICON : CIRCLE_OUTLINE_ICON,

            waitingForStoreColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                ((props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_STORE || (props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null) === FINANCING_STATUSES.WAITING_FOR_CLIENT) ? 'color-red' : 'color-primary'
        };

        if ([FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
            this.state.waitingForQuoteIcon = MARKED_CHECKBOX_ICON;
            this.state.current = 1;
        }

        if ([FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
            this.state.waitingForEntityIcon = MARKED_CHECKBOX_ICON;
            this.state.current = 2;
        }

        if ([FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
            this.state.waitingForStoreIcon = MARKED_CHECKBOX_ICON;
            this.state.current = 3;
        }

        if (this.state.waitingForQuoteIcon === CLOSE_CIRCLE_ICON) {
            this.state.current = 0;
        }

        if (this.state.waitingForEntityIcon === CLOSE_CIRCLE_ICON) {
            this.state.current = 1;
        }

        if (this.state.waitingForStoreIcon === CLOSE_CIRCLE_ICON) {
            this.state.current = 2;
        }
    }

    render() {
        let subTotal = _.sumBy(this.props.financing.order.items, (item) => {
            return item.count * item.price;
        }) / 100;
        let taxes = (subTotal + (this.props.financing.order.deliveryCost / 100)) * parseFloat(process.env.MIX_TAXES);
        subTotal = subTotal + taxes + (this.props.financing.order.deliveryCost / 100) - (this.props.financing.advancePayment / 100);

        let total = calculateFinancing(this.props.financing.financingPlan.payments, this.props.financing.financingPlan, subTotal).rawTotal;

        let payments = calculateFinancing(this.props.financing.financingPlan.payments, this.props.financing.financingPlan, subTotal).restPayments;
        return (
            <React.Fragment>
                <div style={{display: 'inherit'}} onClick={() => this.props.history.push('/financings/' + this.props.financing.id )} 
                className={`${[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.SIGNED].indexOf(this.props.financing.financingStatus.id) > -1 ? 'bordergreen' :''} financing-card cursor-pointer white-background `}>
                    <div className="row">
                        <div className="col-md-5 align-center">
                            <ImagePreview
                                style = {
                                    {
                                        height: '70px',
                                        width: 'inherit',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain'
                                    }
                                }
                                className="image-preview bordered"
                                image={this.props.financing.financingPlan.financingEntity.logo}>
                            </ImagePreview>
                        </div>
                        <div className="col-md-7 align-center">
                            <p className="subtitle-1">{this.props.financing.financingPlan.financingEntity.name}</p>
                            <p>Solicitado: <strong>{(this.props.financing.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(subTotal)}</strong></p>
                        </div>
                        <div className={`${[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.CANCELED].indexOf(this.props.financing.financingStatus.id) > -1 ? 'col-md-6': 'col-md-12' }`}>
                            <strong><p className="mgB0">Detalles del financiamiento</p></strong>
                        </div>
                       {([FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY].indexOf(this.props.financing.financingStatus.id) > -1)  &&
                            <div className="col-md-6">
                                <strong><p className="mgB0" style={{color:'green', paddingLeft:'50%'}}>( Aprobada )</p></strong>
                            </div>}
                        
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Fecha</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{moment.utc(this.props.financing.createdAt).format('DD[/]MMMM[/]YYYY[,] h:mma')}</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Número de solicitud</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.id}</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Cuotas</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.financingPlan.payments} X {payments}</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <p className="mgB0" style={{color: '#616161'}}>Adelanto</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{this.props.financing.advancePayment !== '' ? (this.props.financing.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(this.props.financing.advancePayment / 100) : '-'} </p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-md-6">
                            <strong><p className="mgB0" style={{color: '#616161'}}>Total a pagar</p></strong>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <strong><p className="mgB0" style={{color: '#616161'}}>{(this.props.financing.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(total + (this.props.financing.advancePayment / 100))}</p></strong>
                        </div>
                        <hr className="w-95" />
                        <div className="col-12 p-0 mgT2">
                            <Media query='(max-width: 600px)'>
                                {matches =>
                                    matches ? (
                                        <div className="mobile-stepper">
                                            <Steps labelPlacement="vertical" current={0}>
                                                <Step
                                                    icon={<i className={MARKED_CHECKBOX_ICON} />}>
                                                </Step>
                                                <Step
                                                    icon={<i className={this.state.waitingForQuoteIcon + ' ' + this.state.waitingForQuoteColor} />}>
                                                </Step>
                                                <Step
                                                    icon={<i className={this.state.waitingForEntityIcon + ' ' + this.state.waitingForEntityColor} />}>
                                                </Step>
                                                <Step
                                                    icon={<i className={this.state.waitingForStoreIcon + ' ' + this.state.waitingForStoreColor} />}>
                                                </Step>
                                            </Steps>
                                            <strong className="text-center"><p>{this.props.financing.financingStatus.name}</p></strong>
                                        </div>

                                    ) : (
                                        <Steps labelPlacement="vertical" current={0}>
                                            <Step
                                                icon={<i className={MARKED_CHECKBOX_ICON} />}
                                                title={''}>
                                            </Step>
                                            <Step
                                                icon={<i className={this.state.waitingForQuoteIcon + ' ' + this.state.waitingForQuoteColor} />}
                                                title={this.state.current === 0 ? this.props.financing.financingStatus.name : ''}>
                                            </Step>
                                            <Step
                                                icon={<i className={this.state.waitingForEntityIcon + ' ' + this.state.waitingForEntityColor} />}
                                                title={this.state.current === 1 ? this.props.financing.financingStatus.name : ''}>
                                            </Step>
                                            <Step
                                                icon={<i className={this.state.waitingForStoreIcon + ' ' + this.state.waitingForStoreColor} />}
                                                title={this.state.current >= 2 ? this.props.financing.financingStatus.name : ''}>
                                            </Step>
                                        </Steps>
                                    )
                                }
                            </Media>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

FinancingCard.propTypes = {financing: PropTypes.object, authUser: PropTypes.object};
