'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/pagination';
import DataTable from "common/components/datatable";
import Toggle from 'react-toggle';

import {USERS_PER_PAGE} from 'common/constants/enums.constant';

import money from 'common/helpers/money';
import moment from 'moment';

export default class PromotionsListView extends React.PureComponent {

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine ">
                <span className="dt__header-label text-center" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    /**
     * Renders each cell in the table.
     *
     * @param  {object} cell        the corresponding cell's object.
     * @return {ReactComponent}    our custom-made cell component.
     */
    matchFeaturedRow = (state, rowInfo) => {
        if (!rowInfo) {
            return {};
        }
        const promotion = rowInfo.original;
        return {
            onClick: () => {
                this.props.history.push('promotions/' + promotion.id);
            }
        };
    };

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const data = this.props.promotions;
        const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        const columns = [
            {
                Header: this.renderHeader('Código'),
                accessor: '',
                Cell: ({original}) => {
                    return original.code;
                },
                width: 100
            },
            {
                Header: this.renderHeader('Nombre'),
                accessor: '',
                Cell: ({original}) => {
                    return original.name;
                },
                width: 350
            },
            {
                Header: this.renderHeader('Inicio'),
                accessor: 'start_date',
                Cell:({original}) => {
                    return moment(original.start_date).format('DD-MM-YYYY');
                },
                width: 150
            },
            {
                Header: this.renderHeader('Fin'),
                accessor: 'end_date',
                Cell:({original}) => {
                    return moment(original.end_date).format('DD-MM-YYYY');
                },
                width: 150
            },

            {
                Header: this.renderHeader('% de Descuento'),
                accessor: 'percent',
                Cell: ({original}) => {
                    return <div className='text-center'>{((original.percent_discount) ? original.percent_discount +' %': '-')}</div>;
                },
                width: 120
            },
            {
                Header: this.renderHeader('Descuento Fijo'),
                accessor: 'fixed_discount',
                Cell: ({original}) => {
                    return <div className='text-center'>{(original.fixed_discount) > 0 ? 'RD$ ' + money(original.fixed_discount / 100) : '-'}</div>;
                },
                width: 150
            },
            {
                Header: this.renderHeader('Descuento Máximo'),
                accessor: 'max_discount',
                Cell: ({original}) => {
                    return <div className='text-center'>{(original.max_discount > 0) ? 'RD$ ' + money(original.max_discount / 100) : '-'}</div>;
                },
                width: 150
            },
            {
                Header: this.renderHeader('Usos'),
                accessor: 'usesbyclient',
                Cell: ({original}) => {
                    return <div className='text-center'>{original.uses_by_client}</div>;
                },
                width: 80
            }
        ];
        return (
            <div className={`layout-body__content`}>
                <div className="row">
                    <div className="col-12">
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-users"
                            getTrProps={this.matchFeaturedRow}
                            page={this.props.page}
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />

                        <div className="pagination">
                            <Pagination
                                onPageChange={(page) => this.props.onFiltersChange('page', page.selected + 1) }
                                forcePage={this.props.pagination.currentPage}
                                pageCount={pageCount} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



PromotionsListView.propTypes = {
    history: PropTypes.object.isRequired,
    promotions: PropTypes.array,
    onFiltersChange: PropTypes.func.isRequired
};
