import React from 'react';
import PropTypes from 'prop-types';


import { EYE_SHOW_ICON, EYE_HIDE_ICON } from 'common/constants/icon.constant';

/**
* Creates a password field with an eye to show / hide the input.
*/
export default class Password extends React.Component {
    /**
    * Sets the state and make the binds as needed.
    *
    * @param {props}  the required React properties.
    */
    constructor(props) {
        super(props);

        this.state = {
            labelFocused: false,
            showPassword: false
        };

        this.onTextChange = this.onTextChange.bind(this);

        this.onFocus = this.changeFocus.bind(this, true);
        this.onBlur = this.changeFocus.bind(this, false);

        this.togglePassword = this.togglePassword.bind(this);
    }

    /**
  * Fired once the focus status changes.
  *
  * @param {bool} labelFocused determines if the element is focused or not.
  */
    changeFocus(labelFocused) {
        this.setState({labelFocused});
    }

    /**
    * Fired once the focus status changes.
    */
    togglePassword() {
        this.setState({showPassword: !this.state.showPassword});
    }

    /**
    * Fired once the text input has changed.
    *
    * @param {Proxy.dispatcher} event  contains the new event status.
    */
    onTextChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {

        const toggleIcon = this.state.showPassword ? EYE_SHOW_ICON : EYE_HIDE_ICON;
        return (
            <label className="form-label">
                <span className={`label ${this.props.labelClassName} ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                    {this.props.icon && (
                        <span className="fl__icon fl__icon--left mgR">
                            <i className={this.props.icon} />
                        </span>
                    )}
                    {this.props.label}
                </span>
                <input
                    type={this.state.showPassword ? 'text' : 'password'}
                    className="form-control white-background"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.onTextChange}
                    value={this.props.value}
                />
                <span
                    className={
                        `toggle-password ${this.state.showPassword ? 'toggle-password--active' : ''}`
                    }
                    onClick={this.togglePassword}>
                    <i className={toggleIcon} />
                </span>
            </label>
        );
    }
}

Password.defaultProps = {dangerLabel: false};

/**
 * Password properties validation.
 */
Password.propTypes = {
    icon: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired
};
