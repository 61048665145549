import parseBody from 'common/helpers/parse-body';
import formatQueryString from 'common/helpers/format-query-string';

import {post, get, deleteRequest} from './api.service';


export function verifyOrderTokenRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/transactions/verifyOrderToken',
        formData
    );
}

export function getFinancingsTotalsRequest(filters) {
    const formData = new FormData();

    parseBody(formData, filters);

    return post('/json/transactions/totals', formData);
}

export function getAllEntitiesRequest(storeId) {

    return get(
        '/json/generic/allEntities/' + storeId,
    );
}

export function getAllFinancingEntityTypesRequest() {

    return get(
        '/json/generic/allEntityTypes',
    );
}

export function createFinancingRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/transactions/financings',
        formData
    );
}

export function getFinancingsRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);
    }

    return get(
        `/json/transactions/financings/search?${queryString}`
    );
}

export function getFinancingRequest(id) {

    return get(
        '/json/transactions/financings/' + id
    );
}

export function getDashboardRequest(params) {
    const formData = new FormData();

    parseBody(formData, params);

    return post(
        '/json/transactions/dashboard', formData
    );
}

export function cancelFinancingRequest(params) {
    const formData = new FormData();

    parseBody(formData, params);

    return post(
        '/json/transactions/financings/' + params.id + '/cancel',
        formData
    );
}

export function approveFinancingRequest(id) {
    const formData = new FormData();
    return post(
        '/json/transactions/financings/' + id + '/approve',
        formData
    );
}

export function signFinancingRequest(id) {
    const formData = new FormData();
    return post(
        '/json/transactions/financings/' + id + '/sign',
        formData
    );
}

export function resendFinancingRequest(data) {
    const formData = new FormData();


    parseBody(formData, data.newData);

    return post(
        '/json/transactions/financings/' + data.id + '/resend',
        formData
    );
}

export function changeFinancingStatusRequest(params) {
    const formData = new FormData();

    parseBody(formData, {status: params.status});

    return post(
        '/json/transactions/financings/' + params.id + '/status',
        formData
    );
}

export function addCommentRequest(params) {
    const formData = new FormData();

    parseBody(formData, {comment: params.comment});

    return post(
        '/json/transactions/financings/' + params.id + '/comment',
        formData
    );
}

export function declineFinancingRequest(params) {
    const formData = new FormData();

    parseBody(formData, params);

    return post(
        '/json/transactions/financings/' + params.id + '/decline',
        formData
    );
}

export function getFinancingActionsRequest(id) {

    return get(
        '/json/transactions/financings/actions/' + id
    );
}

export function updateFinancingRequest(params) {
    const formData = new FormData();

    parseBody(formData, params.data);

    return post(
        '/json/transactions/financings/edit/' + params.id,
        formData
    );
}

export function uploadQuoteRequest(params) {
    const formData = new FormData();

    parseBody(formData, {quote: params.file});

    return post(
        '/json/transactions/financings/upload/quote/' + params.id,
        formData
    );
}

export function uploadDocumentRequest(params) {
    const formData = new FormData();

    parseBody(formData, {document: params.file, admin: params.admin_employees});

    return post(
        '/json/transactions/financings/upload/document/' + params.id,
        formData
    );
}

export function removeDocumentRequest(params) {
    const formData = new FormData();

    parseBody(formData, {documentName: params.filename});

    return post(
        '/json/transactions/financings/remove/document/' + params.id,
        formData
    );

}

export function sellConfirmFinancingRequest(params) {
    const formData = new FormData();

    parseBody(formData, params);

    return post(
        '/json/transactions/financings/' + params.id+'/sell',
        formData
    );
}

export function createTempFinancingRequest(params) {
    const formData = new FormData();

    parseBody(formData, params);

    return post(
        '/json/transactions/notcompleted',
        formData
    );
}

export function createFinancingFromNotCompletedRequest(data) {
    const formData = new FormData();

    parseBody(formData, data);

    return post(
        '/json/transactions/notcompleted/createfinancing',
        formData
    );
}

export function deleteTempFinancingRequest(externalid) {
    return deleteRequest('/json/transactions/notcompleted/' + externalid);
}

export function getTempFinancingRequest(externalid) {
    return get('/json/transactions/notcompleted/' + externalid);
}

export function undoApproveFinancingRequest(params) {
    const formData = new FormData();
    parseBody(formData, params);

    return post('/json/transactions/financings/'+params.financingId+'/undoapprove', formData);
}

export function createCardFinancingRequest(params) {
    const formData = new FormData();
    parseBody(formData, params);

    return post('/json/transactions/bycard', formData);
}

export function getCardTransactionsRequest(params) {
    let queryString = '';
    if (params) {
        queryString = formatQueryString(params);
    }

    return get(
        `/json/transactions/installments/search?${queryString}`
    );
}

export function assignBranchRequest(params) {
    const formData = new FormData();
    parseBody(formData, params);

    return post( '/json/transactions/financings/'+params.financingId+'/branch/assign', formData );
}

export function cancelCheckoutRequest(params) {
    const formData = new FormData();
    parseBody(formData, params);

    return post( '/transactions/cancel/checkout', formData );
}
