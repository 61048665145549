'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PromotionsList from './promotion-list';
import PromotionsDetails from './promotion-detail';

export default class PromotionsContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route exact path="/promotions" component={PromotionsList} />
                <Route path="/promotions/:id" component={PromotionsDetails} />
            </Switch>
        );
    }
}




