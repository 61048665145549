'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import formatQueryString from 'common/helpers/format-query-string';

import { DEFAULT_USERS_FILTERS } from 'common/constants/objects.constant';

import ClientsListView from './clients-list-view';
import ClientsListHeader from './clients-list-header';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            users: userSelectors.getUsers(state),
            pagination: userSelectors.getPagination(state),
            filters: userSelectors.getUsersFilters(state),
            loading: userSelectors.getIsLoading(state),
            error: userSelectors.getError(state),
            selectedUsers: userSelectors.getSelectedUsers(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (filters) => {
            dispatch(userActions.getUsers(filters));
        },
        setFilters: (filters) => {
            dispatch(userActions.setFilters(filters));
        },
        selectUsers: (value, ids) => {
            dispatch(userActions.selectUsers(value, ids));
        },
        cancelGetUsers: () => {
            dispatch(userActions.cancelGetUsers());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class ClientsListContainer extends React.Component {

    componentDidMount() {
        let filters = DEFAULT_USERS_FILTERS;

        if (this.props.location.search !== '') {
            let queryObject = queryString.parse(this.props.location.search, {parseNumbers: true});
            filters = Object.assign(filters, queryObject);
        }

        this.setFilters(filters);
    }

    onFiltersChange = (filter, value) => {
        let filters = {... this.props.filters};
        if (!_.isArray(filter)) {
            filters[filter] = value;
        } else {
            let i=0;
            _.forEach(filter, (el) => {
                filters[el] = value[i];
                i++;
            });
        }

        this.setFilters(filters);
    };

    setFilters = (filters) => {
        this.props.cancelGetUsers();

        let queryString = formatQueryString(filters);

        this.props.history.replace('/clients?' + queryString);

        this.props.setFilters(filters);

        this.props.getUsers(Object.assign({}, filters, {clients: 1}));
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                <div className="layout-body__header">
                    <ClientsListHeader
                        total={this.props.pagination.total}
                        filters={this.props.filters}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        onFiltersChange={this.onFiltersChange}
                    />
                </div>
                {!this.props.loading &&
                    this.props.users.length > 0 &&
                    !this.props.error &&
                    <ClientsListView
                        users={this.props.users}
                        history={this.props.history}
                        filters={this.props.filters}
                        onFiltersChange={this.onFiltersChange}
                        pagination={this.props.pagination}
                    />
                }
                {!this.props.loading &&
                    this.props.users.length < 1 &&
                    !this.props.error &&
                    <EmptyState
                        imgStyle={
                            {
                                backgroundColor: 'rgba(0, 125, 195, 0.08)',
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/ic_search.svg"
                        title=""
                        text={this.props.filters.q === '' ? 'No se encontraron resultados para la búsqueda.' : () => <h3 className="empty-state__headline">No se encontraron resultados de búsqueda con <strong>{this.props.filters.q}</strong></h3>}
                    />
                }
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
            </div>
        );
    }
}

ClientsListContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ClientsListContainer));
