
import * as transactionActionsLabels from './transaction.enum';


export const verifyOrderToken = (params) => ({
    type: transactionActionsLabels.VERIFY_ORDER_TOKEN,
    payload: params
});

export const verifyOrderTokenSuccess = (cart) => ({type: transactionActionsLabels.VERIFY_ORDER_TOKEN_SUCCESS, payload: cart});

export const verifyOrderTokenFailure = (error) => ({
    type: transactionActionsLabels.VERIFY_ORDER_TOKEN_FAILURE,
    payload: error
});

export const getAllEntities = (storeId) => ({
    type: transactionActionsLabels.GET_ALL_ENTITIES,
    payload: storeId
});

export const getAllEntitiesSuccess = (entities) => ({type: transactionActionsLabels.GET_ALL_ENTITIES_SUCCESS, payload: entities});

export const getAllEntitiesFailure = (error) => ({
    type: transactionActionsLabels.GET_ALL_ENTITIES_FAILURE,
    payload: error
});

export const getAllFinancingEntityTypes = (alltypes) => ({
    type: transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES,
    payload: alltypes
});

export const getAllFinancingEntityTypesSuccess = (types) => ({type: transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_SUCCESS, payload: types});

export const getAllFinancingEntityTypesFailure = (error) => ({
    type: transactionActionsLabels.GET_ALL_FINANCING_ENTITY_TYPES_FAILURE,
    payload: error
});

export const setFinancingEntityType = (val) => ({
    type: transactionActionsLabels.SET_FINANCING_ENTITY_TYPE,
    payload: val
});


export const setFinancingFormData = (params) => ({
    type: transactionActionsLabels.SET_FINANCING_FORM_DATA,
    payload: params
});

export const setFinancingTempData = (params) => ({
    type: transactionActionsLabels.SET_FINANCING_TEMP_DATA,
    payload: params
});

export const setCreatingFinancing = (val) => ({
    type: transactionActionsLabels.SET_CREATING_FINANCING,
    payload: val
});

export const resetFinancingFormData = () => ({type: transactionActionsLabels.RESET_FINANCING_FORM_DATA});

export const resetFinancingMessages = () => ({type: transactionActionsLabels.RESET_FINANCING_MESSAGES});

export const setDataPaymentDiscounnt = (data) => ({type: transactionActionsLabels.DATA_PAYMENT_DISCOUNT, payload: data});

export const createFinancing = (params) => ({
    type: transactionActionsLabels.CREATE_FINANCING,
    payload: params
});

export const createFinancingSuccess = (financing) => ({type: transactionActionsLabels.CREATE_FINANCING_SUCCESS, payload: financing});

export const createFinancingFailure = (error) => ({
    type: transactionActionsLabels.CREATE_FINANCING_FAILURE,
    payload: error
});

export const getFinancings = (filters) => ({
    type: transactionActionsLabels.GET_FINANCINGS,
    payload: filters
});

export const getFinancingsSuccess = (financings) => ({type: transactionActionsLabels.GET_FINANCINGS_SUCCESS, payload: financings});

export const getFinancingsFailure = (error) => ({
    type: transactionActionsLabels.GET_FINANCINGS_FAILURE,
    payload: error
});

export const setFinancingsFilters = (filters) => ({
    type: transactionActionsLabels.SET_FINANCINGS_FILTERS,
    payload: filters
});

export const cancelGetFinancings = () => ({type: transactionActionsLabels.CANCEL_GET_FINANCINGS});

export const getFinancing = (id) => ({
    type: transactionActionsLabels.GET_FINANCING,
    payload: id
});

export const getFinancingSuccess = (financing) => ({type: transactionActionsLabels.GET_FINANCING_SUCCESS, payload: financing});

export const getFinancingFailure = (error) => ({
    type: transactionActionsLabels.GET_FINANCING_FAILURE,
    payload: error
});

export const clearFinancingProfile = () => ({type: transactionActionsLabels.CLEAR_FINANCING_PROFILE});

export const cancelFinancing = (params) => ({
    type: transactionActionsLabels.CANCEL_FINANCING,
    payload: params
});

export const cancelFinancingSuccess = (financing) => ({type: transactionActionsLabels.CANCEL_FINANCING_SUCCESS, payload: financing});

export const cancelFinancingFailure = (error) => ({
    type: transactionActionsLabels.CANCEL_FINANCING_FAILURE,
    payload: error
});

export const signFinancing = (id) => ({
    type:transactionActionsLabels.SIGN_FINANCING,
    payload: id
});

export const signFinancingSuccess = (financing) => ({
    type:transactionActionsLabels.SIGN_FINANCING_SUCCESS,
    payload: financing
});

export const signFinancingFailure = (error) => ({
    type: transactionActionsLabels.SIGN_FINANCING_FAILURE,
    payload: error
});

export const resendFinancing = (id, newData) => ({
    type:transactionActionsLabels.RESEND_FINANCING,
    payload: {id: id, newData: newData}
});

export const clearModalFinancing = () => ({
    type:transactionActionsLabels.CLEAR_MODAL_FINANCING
});


export const resendFinancingSuccess = (financing) => ({
    type:transactionActionsLabels.RESEND_FINANCING_SUCCESS,
    payload: financing
});

export const resendFinancingFailure = (error) => ({
    type: transactionActionsLabels.RESEND_FINANCING_FAILURE,
    payload: error
});

export const approveFinancing = (id) => ({
    type: transactionActionsLabels.APPROVE_FINANCING,
    payload: id
});

export const approveFinancingSuccess = (financing) => ({type: transactionActionsLabels.APPROVE_FINANCING_SUCCESS, payload: financing});

export const approveFinancingFailure = (error) => ({
    type: transactionActionsLabels.APPROVE_FINANCING_FAILURE,
    payload: error
});

export const changeFinancingStatus = (id, status) => ({
    type: transactionActionsLabels.CHANGE_FINANCING_STATUS,
    payload: {id: id, status: status}
});

export const changeFinancingStatusSuccess = (financing) => ({type: transactionActionsLabels.CHANGE_FINANCING_STATUS_SUCCESS, payload: financing});

export const changeFinancingStatusFailure = (error) => ({
    type: transactionActionsLabels.CHANGE_FINANCING_STATUS_FAILURE,
    payload: error
});

export const addComment = (id, comment) => ({
    type: transactionActionsLabels.ADD_COMMENT,
    payload: {id: id, comment: comment}
});

export const addCommentSuccess = (actions) => ({type: transactionActionsLabels.ADD_COMMENT_SUCCESS, payload: actions});

export const addCommentFailure = (error) => ({
    type: transactionActionsLabels.ADD_COMMENT_FAILURE,
    payload: error
});

export const declineFinancing = (params) => ({
    type: transactionActionsLabels.DECLINE_FINANCING,
    payload: params
});

export const declineFinancingSuccess = (financing) => ({type: transactionActionsLabels.DECLINE_FINANCING_SUCCESS, payload: financing});

export const declineFinancingFailure = (error) => ({
    type: transactionActionsLabels.DECLINE_FINANCING_FAILURE,
    payload: error
});

export const selectPlanToCompare = (plan, value) => ({
    type: transactionActionsLabels.SELECT_PLAN_TO_COMPARE,
    payload: {plan: plan, value: value}
});

export const resetPlansToCompare = () => ({type: transactionActionsLabels.RESET_PLANS_TO_COMPARE});

export const getFinancingActions = (id) => ({type: transactionActionsLabels.GET_FINANCING_ACTIONS, payload: id});

export const getFinancingActionsSuccess = (actions) => ({
    type: transactionActionsLabels.GET_FINANCING_ACTIONS_SUCCESS,
    payload: actions
});

export const getFinancingActionsFailure = (error) => ({
    type: transactionActionsLabels.GET_FINANCING_ACTIONS_FAILURE,
    payload: error
});

export const updateFinancing = (id, data) => ({
    type: transactionActionsLabels.UPDATE_FINANCING,
    payload: {id: id, data: data}
});

export const updateFinancingSuccess = (financing) => ({type: transactionActionsLabels.UPDATE_FINANCING_SUCCESS, payload: financing});

export const updateFinancingFailure = (error) => ({
    type: transactionActionsLabels.UPDATE_FINANCING_FAILURE,
    payload: error
});

export const uploadQuote = (id, file) => ({
    type: transactionActionsLabels.UPLOAD_QUOTE,
    payload: {id: id, file: file}
});

export const uploadQuoteSuccess = (financing) => ({type: transactionActionsLabels.UPLOAD_QUOTE_SUCCESS, payload: financing});

export const uploadQuoteFailure = (error) => ({
    type: transactionActionsLabels.UPLOAD_QUOTE_FAILURE,
    payload: error
});

export const uploadDocument = (id, file, admin_employees) => ({
    type: transactionActionsLabels.UPLOAD_DOCUMENT,
    payload: {id: id, file: file, admin_employees: admin_employees}
});

export const uploadDocumentSuccess = (financing) => ({type: transactionActionsLabels.UPLOAD_DOCUMENT_SUCCESS, payload: financing});

export const uploadDocumentFailure = (error) => ({
    type: transactionActionsLabels.UPLOAD_DOCUMENT_FAILURE,
    payload: error
});

export const removeDocument = (id, filename) => ({
    type: transactionActionsLabels.REMOVE_DOCUMENT,
    payload: {id: id, filename: filename}
});

export const removeDocumentSuccess = (financing) => ({type: transactionActionsLabels.REMOVE_DOCUMENT_SUCCESS, payload: financing});

export const removeDocumentFailure = (error) => ({
    type: transactionActionsLabels.REMOVE_DOCUMENT_FAILURE,
    payload: error
});

export const sellConfirmFinancing = (id, data) => ({
    type: transactionActionsLabels.SELL_CONFIRM,
    payload: {id: id, dataConfirm: data}
});

export const sellConfirmFinancingSuccess = (financing) => ({type: transactionActionsLabels.SELL_CONFIRM_SUCCESS, payload: financing});

export const sellConfirmFinancingFailure = (error) => ({
    type: transactionActionsLabels.SELL_CONFIRM_FAILURE,
    payload: error
});

export const getDashboard = (params) => ({type: transactionActionsLabels.GET_DASHBOARD, payload: params});

export const getDashboardSuccess = (dashboard) => ({type: transactionActionsLabels.GET_DASHBOARD_SUCCESS, payload: dashboard});

export const getDashboardFailure = (error) => ({
    type: transactionActionsLabels.GET_DASHBOARD_FAILURE,
    payload: error
});

export const clearDashboard = () => ({type: transactionActionsLabels.CLEAR_DASHBOARD});

export const getFinancingsTotal = (filters) => ({type: transactionActionsLabels.GET_FINANCINGS_TOTAL, payload: filters});

export const getFinancingsTotalSuccess = (totals) => ({type: transactionActionsLabels.GET_FINANCINGS_TOTAL_SUCCESS, payload: totals});

export const getFinancingsTotalFailure = (error) => ({
    type: transactionActionsLabels.GET_FINANCINGS_TOTAL_FAILURE,
    payload: error
});

export const createFinancingFromTemp = (params) => ({
    type: transactionActionsLabels.CREATE_FINANCING_FROM_TEMP,
    payload: params
});

export const createFinancingFromTempSuccess = (financing) => ({type: transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_SUCCESS, payload: financing});

export const createFinancingFromTempFailure = (error) => ({
    type: transactionActionsLabels.CREATE_FINANCING_FROM_TEMP_FAILURE,
    payload: error
});

export const createTempFinancing = (params) => ({
    type: transactionActionsLabels.CREATE_TEMP_FINANCING,
    payload: params
});

export const createTempFinancingSuccess = () => ({type: transactionActionsLabels.CREATE_TEMP_FINANCING_SUCCESS});

export const createTempFinancingFailure = (error) => ({
    type: transactionActionsLabels.CREATE_TEMP_FINANCING_FAILURE,
    payload: error
});

export const getTempFinancing = (externalId) => ({
    type: transactionActionsLabels.GET_TEMP_FINANCING, payload: externalId
});

export const getTempFinancingSuccess = (financingtemp) => ({type: transactionActionsLabels.GET_TEMP_FINANCING_SUCCESS, payload: financingtemp});

export const getTempFinancingFailure = (error) => ({
    type: transactionActionsLabels.GET_TEMP_FINANCING_FAILURE,
    payload: error
});

export const delTempFinancing = (externalId) => ({
    type: transactionActionsLabels.DELETE_TEMP_FINANCING, payload: externalId
});

export const delTempFinancingSuccess = (externalId) => ({type: transactionActionsLabels.DELETE_TEMP_FINANCING_SUCCESS, payload: externalId});

export const delTempFinancingFailure = (error) => ({
    type: transactionActionsLabels.DELETE_TEMP_FINANCING_FAILURE,
    payload: error
});


export const undoApproveFinancing = (financingId, undoComment) => ({type: transactionActionsLabels.UNDO_APPROVE_FINANCING, payload: {financingId: financingId, comment: undoComment}});
export const undoApproveFinancingSuccess = (financing) => ({type: transactionActionsLabels.UNDO_APPROVE_FINANCING_SUCCESS, payload: financing});
export const undoApproveFinancingFailure = (error) => ({type: transactionActionsLabels.UNDO_APPROVE_FINANCING_FAILURE, payload: error});

export const createCardFinancig = (dataTransaction) => ({type: transactionActionsLabels.CREATE_CARD_FINANCING, payload: dataTransaction});
export const createCardFinancigSuccess = (cardFinancing) => ({type: transactionActionsLabels.CREATE_CARD_FINANCING_SUCCESS, payload: cardFinancing});
export const createCardFinancigFailure = (error) => ({type: transactionActionsLabels.CREATE_CARD_FINANCING_FAILURE, payload: error});


export const searchCardFinancig = (filters) => ({type: transactionActionsLabels.SEARCH_CARD_FINANCING, payload: filters});
export const searchCardFinancigSuccess = (cardTransactions) => ({type: transactionActionsLabels.SEARCH_CARD_FINANCING_SUCCESS, payload: cardTransactions});
export const searchCardFinancigFailure = (error) => ({type: transactionActionsLabels.SEARCH_CARD_FINANCING_FAILURE, payload: error});

export const assignBranchFinancig = (id, branchId) => ({type: transactionActionsLabels.ASSIGN_BRANCH_FINANCING, payload: {financingId: id, branchId: branchId}});
export const assignBranchFinancigSuccess = (financing) => ({type: transactionActionsLabels.ASSIGN_BRANCH_FINANCING_SUCCESS, payload: financing});
export const assignBranchFinancigFailure = (error) => ({type: transactionActionsLabels.ASSIGN_BRANCH_FINANCING_FAILURE, payload: error});


export const cancelCheckout = (extid) => ({type: transactionActionsLabels.CANCEL_CHECKOUT, payload: {externalId: extid}});
export const cancelCheckoutSuccess = () => ({type: transactionActionsLabels.CANCEL_CHECKOUT_SUCCESS});
export const cancelCheckoutFailure = (error) => ({type: transactionActionsLabels.CANCEL_CHECKOUT_FAILURE, payload: error});