'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import UsersListContainer from './users-list';
import UserDetailContainer from './users-detail';
import UsersWelcomeContainer from './user-welcome';

export default class UsersContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route exact path="/users" component={UsersListContainer} />
                <Route path="/users/clients/:id" component={UserDetailContainer} />
                <Route path="/users/:id" component={UserDetailContainer} />
                <Route path="/welcome" component={UsersWelcomeContainer} />
            </Switch>
        );
    }
}
