//FINANCING ENTITY ENUM ACTIONS

export const RESET_FINANCING_ENTITY_MESSAGES = 'RESET_FINANCING_ENTITY_MESSAGES';

export const CREATE_FINANCING_ENTITY = 'CREATE_FINANCING_ENTITY';
export const CREATE_FINANCING_ENTITY_SUCCESS = 'CREATE_FINANCING_ENTITY_SUCCESS';
export const CREATE_FINANCING_ENTITY_FAILURE = 'CREATE_FINANCING_ENTITY_FAILURE';

export const UPDATE_FINANCING_ENTITY = 'UPDATE_FINANCING_ENTITY';
export const UPDATE_FINANCING_ENTITY_SUCCESS = 'UPDATE_FINANCING_ENTITY_SUCCESS';
export const UPDATE_FINANCING_ENTITY_FAILURE = 'UPDATE_FINANCING_ENTITY_FAILURE';

export const DELETE_FINANCING_ENTITY = 'DELETE_FINANCING_ENTITY';
export const DELETE_FINANCING_ENTITY_SUCCESS = 'DELETE_FINANCING_ENTITY_SUCCESS';
export const DELETE_FINANCING_ENTITY_FAILURE = 'DELETE_FINANCING_ENTITY_FAILURE';

export const GET_FINANCING_ENTITY = 'GET_FINANCING_ENTITY';
export const GET_FINANCING_ENTITY_SUCCESS = 'GET_FINANCING_ENTITY_SUCCESS';
export const GET_FINANCING_ENTITY_FAILURE = 'GET_FINANCING_ENTITY_FAILURE';

export const GET_FINANCING_ENTITIES = 'GET_FINANCING_ENTITIES';
export const GET_FINANCING_ENTITIES_SUCCESS = 'GET_FINANCING_ENTITIES_SUCCESS';
export const GET_FINANCING_ENTITIES_FAILURE = 'GET_FINANCING_ENTITIES_FAILURE';

export const CANCEL_GET_FINANCING_ENTITIES = 'CANCEL_GET_FINANCING_ENTITIES';

export const GET_FINANCING_ENTITIES_LIST = 'GET_FINANCING_ENTITIES_LIST';
export const GET_FINANCING_ENTITIES_LIST_SUCCESS = 'GET_FINANCING_ENTITIES_LIST_SUCCESS';
export const GET_FINANCING_ENTITIES_LIST_FAILURE = 'GET_FINANCING_ENTITIES_LIST_FAILURE';

export const SET_ENTITIES_FILTERS = 'SET_ENTITIES_FILTERS';

export const CLEAR_FINANCING_ENTITY_PROFILE = 'CLEAR_FINANCING_ENTITY_PROFILE';

export const ACTIVATE_FINANCING_ENTITY = 'ACTIVATE_FINANCING_ENTITY';
export const ACTIVATE_FINANCING_ENTITY_SUCCESS = 'ACTIVATE_FINANCING_ENTITY_SUCCESS';
export const ACTIVATE_FINANCING_ENTITY_FAILURE = 'ACTIVATE_FINANCING_ENTITY_FAILURE';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE';

export const ADD_PLANS = 'ADD_PLANS';
export const ADD_PLANS_SUCCESS = 'ADD_PLANS_SUCCESS';
export const ADD_PLANS_FAILURE = 'ADD_PLANS_FAILURE';

export const SELECT_PLANS = 'SELECT_PLANS';

export const COPY_PLANS = 'COPY_PLANS';
export const COPY_PLANS_SUCCESS = 'COPY_PLANS_SUCCESS';
export const COPY_PLANS_FAILURE = 'COPY_PLANS_FAILURE';

export const BULK_ENABLE_PLANS = 'BULK_ENABLE_PLANS';
export const BULK_ENABLE_PLANS_SUCCESS = 'BULK_ENABLE_PLANS_SUCCESS';
export const BULK_ENABLE_PLANS_FAILURE = 'BULK_ENABLE_PLANS_FAILURE';

export const BULK_DELETE_PLANS = 'BULK_DELETE_PLANS';
export const BULK_DELETE_PLANS_SUCCESS = 'BULK_DELETE_PLANS_SUCCESS';
export const BULK_DELETE_PLANS_FAILURE = 'BULK_DELETE_PLANS_FAILURE';

export const ADD_BRANCHE = 'ADD_BRANCHE';
export const ADD_BRANCHE_SUCCESS = 'ADD_BRANCHE_SUCCESS';
export const ADD_BRANCHE_FAILURE = 'ADD_BRANCHE_FAILURE';

export const UPDATE_BRANCHE = 'UPDATE_BRANCHE';
export const UPDATE_BRANCHE_SUCCESS = 'UPDATE_BRANCHE_SUCCESS';
export const UPDATE_BRANCHE_FAILURE = 'UPDATE_BRANCHE_FAILURE';

export const DELETE_BRANCHES = 'DELETE_BRANCHES';
export const DELETE_BRANCHES_SUCCESS = 'DELETE_BRANCHES_SUCCESS';
export const DELETE_BRANCHES_FAILURE = 'DELETE_BRANCHES_FAILURE';

export const SELECT_BRANCHE = 'SELECT_BRANCHE';

export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILURE = 'GET_BRANCHES_FAILURE';

export const GET_PROVINCES_CITIES = 'GET_PROVINCES_CITIES';
export const GET_PROVINCES_CITIES_SUCCESS = 'GET_PROVINCES_CITIES_SUCCESS';
export const GET_PROVINCES_CITIES_FAILURE = 'GET_PROVINCES_CITIES_FAILURE';


export const ADD_BIN = 'ADD_BIN';
export const ADD_BIN_SUCCESS = 'ADD_BIN_SUCCESS';
export const ADD_BIN_FAILURE = 'ADD_BIN_FAILURE';

export const ENABLED_BINES = 'ENABLED_BINES';
export const ENABLED_BINES_SUCCESS = 'ENABLED_BINES_SUCCESS';
export const ENABLED_BINES_FAILURE = 'ENABLED_BINES_FAILURE';

export const DELETE_BINES = 'DELETE_BINES';
export const DELETE_BINES_SUCCESS = 'DELETE_BINES_SUCCESS';
export const DELETE_BINES_FAILURE = 'DELETE_BINES_FAILURE';


export const PLANS_TO_BIN = 'PLANS_TO_BIN';
export const PLANS_TO_BIN_SUCCESS = 'PLANS_TO_BIN_SUCCESS';
export const PLANS_TO_BIN_FAILURE = 'PLANS_TO_BIN_FAILURE';
