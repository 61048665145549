
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_VARIABLES_STATE_PROMOTION = 'RESET_VARIABLES_STATE_PROMOTION';

export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE';

export const EDIT_PROMOTION = 'EDIT_PROMOTION';
export const EDIT_PROMOTION_SUCCESS = 'EDIT_PROMOTION_SUCCESS';
export const EDIT_PROMOTION_FAILURE = 'EDIT_PROMOTION_FAILURE';

export const REMOVE_PROMOTION = 'REMOVE_PROMOTION';
export const REMOVE_PROMOTION_SUCCESS = 'REMOVE_PROMOTION_SUCCESS';
export const REMOVE_PROMOTION_FAILURE = 'REMOVE_PROMOTION_FAILURE';

export const GET_PROMOTION = 'GET_PROMOTION';
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS';
export const GET_PROMOTION_FAILURE = 'GET_PROMOTION_FAILURE';

export const ACTIVATE_PROMOTION = 'ACTIVATE_PROMOTION';
export const ACTIVATE_PROMOTION_SUCCESS = 'ACTIVATE_PROMOTION_SUCCESS';
export const ACTIVATE_PROMOTION_FAILURE = 'ACTIVATE_PROMOTION_FAILURE';

export const SEARCH_PROMOTION = 'SEARCH_PROMOTION';
export const SEARCH_PROMOTION_SUCCESS = 'SEARCH_PROMOTION_SUCCESS';
export const SEARCH_PROMOTION_FAILURE = 'SEARCH_PROMOTION_FAILURE';

export const SEARCH_PROMOTION_ACTIVATE = 'SEARCH_PROMOTION_ACTIVATE';
export const SEARCH_PROMOTION_ACTIVATE_SUCCESS = 'SEARCH_PROMOTION_ACTIVATE_SUCCESS';
export const SEARCH_PROMOTION_ACTIVATE_FAILURE = 'SEARCH_PROMOTION_ACTIVATE_FAILURE';

export const SEARCH_PROMOTIONS_BY_ENTITY = 'SEARCH_PROMOTIONS_BY_ENTITY';

export const ADD_CUPONS_PROMOTION = 'ADD_CUPONS_PROMOTION';
export const ADD_CUPONS_PROMOTION_SUCCESS = 'ADD_CUPONS_PROMOTION_SUCCESS';
export const ADD_CUPONS_PROMOTION_FAILURE = 'ADD_CUPONS_PROMOTION_FAILURE';

export const REMOVE_CUPONS_PROMOTION = 'REMOVE_CUPONS_PROMOTION';
export const REMOVE_CUPONS_PROMOTION_SUCCESS = 'REMOVE_CUPONS_PROMOTION_SUCCESS';
export const REMOVE_CUPONS_PROMOTION_FAILURE = 'REMOVE_CUPONS_PROMOTION_FAILURE';

export const ADD_RULES_PROMOTION = 'ADD_RULES_PROMOTION';
export const ADD_RULES_PROMOTION_SUCCESS = 'ADD_RULES_PROMOTION_SUCCESS';
export const ADD_RULES_PROMOTION_FAILURE = 'ADD_RULES_PROMOTION_FAILURE';

export const UPDATE_RULES_PROMOTION = 'UPDATE_RULES_PROMOTION';
export const UPDATE_RULES_PROMOTION_SUCCESS = 'UPDATE_RULES_PROMOTION_SUCCESS';
export const UPDATE_RULES_PROMOTION_FAILURE = 'UPDATE_RULES_PROMOTION_FAILURE';

export const REMOVE_RULES_PROMOTION = 'REMOVE_RULES_PROMOTION';
export const REMOVE_RULES_PROMOTION_SUCCESS = 'REMOVE_RULES_PROMOTION_SUCCESS';
export const REMOVE_RULES_PROMOTION_FAILURE = 'REMOVE_RULES_PROMOTION_FAILURE';

export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';

export const SEARCH_SKUS = 'SEARCH_SKUS';
export const SEARCH_SKUS_SUCCESS = 'SEARCH_SKUS_SUCCESS';
export const SEARCH_SKUS_FAILURE = 'SEARCH_SKUS_FAILURE';

export const ADD_SKU = 'ADD_SKU';
export const ADD_SKU_SUCCESS = 'ADD_SKU_SUCCESS';
export const ADD_SKU_FAILURE = 'ADD_SKU_FAILURE';

export const REMOVE_SKU = 'REMOVE_SKU';
export const REMOVE_SKU_SUCCESS = 'REMOVE_SKU_SUCCESS';
export const REMOVE_SKU_FAILURE = 'REMOVE_SKU_FAILURE';

export const UPDATE_SKU = 'UPDATE_SKU';
export const UPDATE_SKU_SUCCESS = 'UPDATE_SKU_SUCCESS';
export const UPDATE_SKU_FAILURE = 'UPDATE_SKU_FAILURE';
