import React from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';

/**
 * Extract basic information from the dispensary.
 */
export default class Dropzone extends React.PureComponent {

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <ReactDropzone
                maxSize={this.props.maxSize}
                onDropRejected={this.props.onDropRejected}
                accept={this.props.accept}
                onDrop={this.props.onDrop}
                multiple={this.props.multiple}
                disabled={this.props.disabled}>
                {({getInputProps, getRootProps}) => (
                    <div {...getRootProps()} style={this.props.style} className={`dropzone dropzone__message ${this.props.className}`}>
                        <input {...getInputProps()} />
                        {this.props.children}
                    </div>
                )}
            </ReactDropzone>
        );
    }
}

Dropzone.defaultProps = {
    className: "",
    multiple: true,
    disabled: false,
    showSlick: true,
    full: false
};

Dropzone.propTypes = {
    onDropRejected: PropTypes.func,
    maxSize: PropTypes.number,
    showSlick: PropTypes.bool,
    full: PropTypes.bool,
    multiple: PropTypes.bool,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    className: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
    selectedImage: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
};
