/*global $*/

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import Media from 'react-media';

import Dropdown from 'common/components/dropdown';
import Sidebar from 'common/components/sidebar';

import {
    CLOSE_ICON,
    CREVRON_DOWN_ICON,
    NOTIFICATION_ICON
} from 'common/constants/icon.constant';

/**
 * Displays the dashboard's header.
 */
export default class Header extends React.PureComponent {
    /**
     * Initializes the component.
     */
    constructor(props) {
        super(props);

        this.state = {open : false};

        this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(() => {
            this.setState({open: false});
            $(".dropdown").removeClass("open");
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    renderHamburguerMenu() {
        return (
            <header className="layout-header-mobile">
                <h1 className="layout-header-mobile__title">
                    <NavLink
                        to="/"
                        className="">
                        <div className="layout-header-mobile__logo"></div>
                    </NavLink>
                </h1>
                <span style={{flex: '1'}}></span>
                {this.props.user &&
                    <Menu
                        left
                        onStateChange={(state) => this.setState({open: state.isOpen})}
                        customCrossIcon={false}
                        isOpen={this.state.open}>
                        <span
                            style={{position: 'absolute', color: 'white', zIndex: 1000, fontSize: '32px', textAlign: 'left', marginLeft: '11px', marginTop: '11px'}}>
                            <i
                                onClick={() => this.setState({open: false})}
                                className={CLOSE_ICON } />
                        </span>
                        <Sidebar
                            authUser={this.props.user}
                            mobile={true}
                            logOut={this.logOut}
                            history={this.props.history}
                            location={this.props.pathname} />
                    </Menu>
                }
            </header>
        );
    }

    renderNormalMenu() {
        return (
            <header className="layout-header">
                <h1 className="layout-header__title">
                    <NavLink
                        to="/"
                        className="">
                        <div className="layout-header-mobile__logo"></div>
                    </NavLink>
                </h1>
                <span style={{flex: '1'}}></span>
                <ul className="layout-header__nav">
                    <li>
                        <a
                            id='bell-icon'
                            href="#">
                            <i className={NOTIFICATION_ICON} />
                        </a>
                    </li>
                    <li className="divider" />
                    {this.props.user &&
                        <li className="layout-header__dropdown">
                            <Dropdown
                                align="right"
                                content={
                                    <span className="user-name">
                                        {this.props.user.name}
                                        <i className={CREVRON_DOWN_ICON}></i>
                                    </span>
                                }>
                                <li>
                                    <NavLink className="dropdown__item" to="/my-profile">
                                        Mi perfil
                                    </NavLink>
                                </li>
                                <li role="separator" className="divider" />
                                <li><a className="dropdown__item" href="#" onClick={this.logOut}>Cerrar sesión</a></li>
                            </Dropdown>
                        </li>
                    }

                </ul>
            </header>
        );
    }


    /**
     * Close the user's session and refreshes the page.
     */
    logOut() {
        this.props.onLogout();
    }

    /**
     * Renders the authenticated user's routes.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Media query='(max-width: 600px)'>
                {matches =>
                    matches ? (
                        this.renderHamburguerMenu()
                    ) : (
                        this.renderNormalMenu()
                    )
                }
            </Media>
        );
    }
}

Header.propTypes = {
    user: PropTypes.object,
    onLogout: PropTypes.func,
    pathname: PropTypes.string.isRequired
};
