'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/pagination';
import DataTable from "common/components/datatable";

import {USERS_PER_PAGE} from 'common/constants/enums.constant';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class ClientsListView extends React.PureComponent {

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    /**
     * Renders each cell in the table.
     *
     * @param  {object} cell        the corresponding cell's object.
     * @return {ReactComponent}    our custom-made cell component.
     */
    matchFeaturedRow = (state, rowInfo) => {
        if (!rowInfo) {
            return {};
        }
        const user = rowInfo.original;
        return {
            onClick: () => {
                this.props.history.push('users/clients/' + user.id);
            }
        };
    };

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const data = this.props.users;
        const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        const columns = [
            {
                Header: this.renderHeader('Nombre'),
                accessor: 'name',
                Cell: ({original}) => {
                    return original.name + ' '+ original.lastname;
                }
                // width: 110
            },
            {
                Header: this.renderHeader('Telefono'),
                accessor: 'phone'
                // width: 130
            },
            {
                Header: this.renderHeader('Email'),
                accessor: 'email'
                // width: 130
            },
            {
                Header: this.renderHeader('Ciudad'),
                accessor: 'defaultAddress.city'
                // width: 130
            }
        ];
        return (
            <div className={`layout-body__content`}>
                <div className="row">
                    <div className="col-12">
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-users"
                            getTrProps={this.matchFeaturedRow}
                            page={this.props.page}
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />

                        <div className="pagination">
                            <Pagination
                                onPageChange={(page) => this.props.onFiltersChange('page', page.selected + 1) }
                                forcePage={this.props.pagination.currentPage}
                                pageCount={pageCount} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
ClientsListView.propTypes = {
    onFiltersChange: PropTypes.func,
    users: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
