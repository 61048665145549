/* eslint-disable no-useless-constructor */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import money from 'common/helpers/money';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import formatQueryString from 'common/helpers/format-query-string';

import InstallmentsListView from './installments-list';
import InstallmentsListHeader from './installments-header';

import { DEFAULT_TRANSACTIONS_FILTERS } from 'common/constants/objects.constant';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            filters: transactionSelectors.getFinancingsFilters(state),
            cardTransactions: transactionSelectors.getCardTransactions(state),
            pagination: transactionSelectors.getPagination(state),
            authUser: userSelectors.getAuthUser(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilters: (filters) => {
            dispatch(transactionActions.setFinancingsFilters(filters));
        },
        getCardTransactions: (filters) => {
            dispatch(transactionActions.searchCardFinancig(filters));
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class InstallmentsContainer extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let filters = DEFAULT_TRANSACTIONS_FILTERS;

        if (this.props.location.search !== '') {
            let queryObject = queryString.parse(this.props.location.search, {parseNumbers: true});
            filters = Object.assign({}, filters, queryObject);
        }

        this.setFilters(filters);
    }

    clearFilters = () => {
        let filters = Object.assign({}, this.props.filters, DEFAULT_TRANSACTIONS_FILTERS);

        /*  {
            page: 1,
            perPage: 10,
            from: '',
            to: '',
            q: '',
            store: null,
            financingEntity: null,
            status: 'All'
        }); */

        this.setFilters(filters);
    };

    onFiltersChange = (filter, value) => {
        let filters = {... this.props.filters};
        if (!_.isArray(filter)) {
            filters[filter] = value;
        } else {
            let i=0;
            _.forEach(filter, (el) => {
                filters[el] = value[i];
                i++;
            });
        }
        this.setFilters(filters);
    };

    setFilters = (filters) => {

        let queryString = formatQueryString(filters);

        this.props.history.replace('/installments?' + queryString);

        this.props.setFilters(filters);

        this.props.getCardTransactions(filters);
    };

    totalAcm = () => {
        return this.props.cardTransactions
            .map(el => {return el.to_pay;})
            .reduce((total, topay) => {
                return total + topay;
            });
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                <div className="pdB0 layout-body__header">
                    <InstallmentsListHeader
                        filters={this.props.filters}
                        history={this.props.history}
                        setFilters={this.setFilters}
                        onFiltersChange={this.onFiltersChange}
                        clearFilters={this.clearFilters}
                        total={this.props.pagination.total}
                    />
                </div>
                {!this.props.loading &&
                    !this.props.error &&
                    this.props.cardTransactions.length > 0 &&
                    <React.Fragment>
                        <div className='row fsize-15r'>
                            <div className='col-6 pdR2 text-right'>
                                Cantidad de transacciones: <span className='color-blue'>( {this.props.pagination.total} )</span>
                            </div>
                            <div className='col-6  pdL2'>
                               Total:  <span className='color-blue'>{`RD$ ${money(this.props.pagination.totalAcm/100)}`}</span>
                            </div>
                        </div>
                        <InstallmentsListView
                            payments={this.props.cardTransactions}
                            history={this.props.history}
                            authUser={this.props.authUser}
                            filters={this.props.filters}
                            onFiltersChange={this.onFiltersChange}
                            pagination={this.props.pagination}
                        />
                    </React.Fragment>
                }
                {!this.props.loading &&
                    this.props.cardTransactions.length < 1 &&
                    !this.props.error &&
                    <EmptyState
                        imgStyle={
                            {
                                backgroundColor: 'rgba(0, 125, 195, 0.08)',
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/ic_search.svg"
                        title=""
                        text={this.props.filters.q === '' ? 'No se encontraron resultados para la búsqueda.' : () => <h3 className="empty-state__headline">No se encontraron resultados de búsqueda con <strong>{this.props.filters.q}</strong></h3>}
                    />
                }
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
            </div>
        );
    }
}

InstallmentsContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(InstallmentsContainer));