'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import Pagination from 'common/components/pagination';
import DataTable from "common/components/datatable";

import {
    USERS_PER_PAGE,
    CURRENCIES_IDS,
    USERTYPES,
    FINANCING_STATUSES
} from 'common/constants/enums.constant';

import money from 'common/helpers/money';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class FinancingsListView extends React.PureComponent {

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    /**
     * Renders each cell in the table.
     *
     * @param  {object} cell        the corresponding cell's object.
     * @return {ReactComponent}    our custom-made cell component.
     */
    matchFeaturedRow = (state, rowInfo) => {
        if (!rowInfo) {
            return {};
        }
        const financing = rowInfo.original;
        return {
            onClick: () => {
                this.props.history.push('financings/' + financing.id);
            },
            title: financing.childId ? 'Esta solicitud fue cancelada y se creó la solicitud ' + financing.childId : '',
            style: {backgroundColor: financing.childId ? '#F8E9EB' : '#fff'}
        };
    };

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const data = this.props.financings;
        const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        let columns = [
            {
                Header: this.renderHeader('Fecha'),
                accessor: 'createdAt',
                Cell: ({ original }) => {
                    return(
                        <span style={{fontSize:'14px'}}> {moment.utc(original.createdAt).format('DD/MM/YYYY')} </span>
                    );
                },
                width: 110
            },
            {
                Header: this.renderHeader('Código'),
                accessor: 'id',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original['id']} </span>
                    );
                },
                width: 100
            },
            {
                Header: this.renderHeader('Nombre del cliente'),
                accessor: 'name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original['name'] + ' '+ original['lastname']} </span>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Teléfono'),
                accessor: 'phone',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original['phone']} </span>
                    );
                },
                width: 130
            },
            {
                Header: this.renderHeader('Monto'),
                Cell: ({ original }) => {
                    let total = _.sumBy(original.order.items, (elem) => { return elem.count * elem.price; }) / 100;
                    let taxes = (total + (original.order.deliveryCost / 100)) * parseFloat(process.env.MIX_TAXES);
                    return (
                        <span style={{fontSize:'14px'}}> {(original.order.currency.id === CURRENCIES_IDS.PESO ? 'RD$ ' : 'US$ ') + money(total + taxes + original.order.deliveryCost / 100 )} </span>
                    );
                },
                width: 160
            },
            {
                Header: this.renderHeader('Entidad financiera'),
                accessor: 'financingPlan.financingEntity.name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.financingPlan.financingEntity.name} </span>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Plazo'),
                accessor: 'financingPlan.payments',
                Cell: ({ original }) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.financingPlan.name} </span>
                    );
                },
                width: 152
            },
            {
                Header: this.renderHeader('Actualizado'),
                accessor: 'updatedAt',
                Cell: ({ original }) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.updatedAt ? moment.utc(original.updatedAt).format('DD/MM/YYYY') : moment.utc(original.createdAt).format('DD/MM/YYYY')} </span>
                    );
                },
                width: 152
            },
            {
                Header: this.renderHeader('Estado'),
                accessor: 'financingStatus.name',
                Cell: ({ original }) => {
                    let color = '';
                    switch (original.financingStatus.id) {
                    case FINANCING_STATUSES.SOLD_PRODUCT:
                        color = 'color-primary fsbold';
                        break;
                    case FINANCING_STATUSES.SIGNED:
                        color = 'color-blue';
                        break;
                    case FINANCING_STATUSES.APPROVED:
                    case FINANCING_STATUSES.APPROVED_BY_ENTITY:
                        color = 'color-green';
                        break;
                    case FINANCING_STATUSES.CANCELED:
                        color = 'color-yellow';
                        break;
                    case FINANCING_STATUSES.DECLINED:
                        color = 'color-red';
                        break;
                    case FINANCING_STATUSES.WAITING_FOR_APPROVAL:
                    case FINANCING_STATUSES.WAITING_FOR_CLIENT:
                    case FINANCING_STATUSES.WAITING_FOR_ENTITY:
                    case FINANCING_STATUSES.WAITING_FOR_QUOTE:
                    case FINANCING_STATUSES.WAITING_FOR_STORE:
                        color = 'color-orange';
                        break;
                    default:
                        break;
                    }
                    return <span className={color} style={{fontSize:'14px'}}>{original.financingStatus.name}</span>;
                },
                width: 250
            },
            {
                Header: this.renderHeader('Ultima acción'),
                accessor: 'lastlog',
                Cell: ({ original }) => {
                    let lastlogshow = null;
                    if (([FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.DECLINED].indexOf(original.financingStatus.id) > -1) ) { //|| original.lastlog.includes('revertida') > -1
                        const posM = original.lastlog.indexOf('Razón');
                        lastlogshow = (posM > -1) ? original.lastlog.replace('Razón:', '') : original.lastlog;
                    }
                    return <span dangerouslySetInnerHTML={{__html: ((lastlogshow === null) ? original.lastlog : lastlogshow) + ' '}} />;
                },
                width: 500
            }
        ];
        if (this.props.authUser.userType.id === USERTYPES.CLIENT) {
            columns.splice(2, 1);
            columns.splice(2, 1);
            columns.splice(7, 1);
        } else if (this.props.authUser.userType.id === USERTYPES.AGENT) {
            columns.splice(5, 1);
        }
        return (
            <div className={`layout-body__content`}>
                <p style={{fontSize:'14px', color:'#124b6f', fontWeight:'bold'}} className="text-center">(Haga click sobre la solicitud para detalles)</p>
                <div className="row">
                    <div data-for='financing-tooltip' className="col-12">
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-users"
                            getTrProps={this.matchFeaturedRow}
                            page={this.props.page}
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />

                        <div className="pagination">
                            <Pagination
                                onPageChange={(page) => this.props.onFiltersChange('page', page.selected + 1) }
                                forcePage={this.props.pagination.currentPage}
                                pageCount={pageCount} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
FinancingsListView.propTypes = {
    onFiltersChange: PropTypes.func,
    financings: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
