'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';

import Pagination from 'common/components/pagination';
import DataTable from "common/components/datatable";

import { USERS_PER_PAGE } from 'common/constants/enums.constant';
import { RESPONSE_CODE_ERROR } from 'common/constants/errors.constant';

import money from 'common/helpers/money';

import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';




/**
 * Component responsible of rendering the list depending on it's type.
 */
class InstallmentsListView extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {transId:null};
    }

    renderHeader(name) {
        return (
            <span className="d-flex dt__header-head breakLine">
                <span className="dt__header-label" dangerouslySetInnerHTML={{ __html: this.htmlDecode(name) }}></span>
            </span>
        );
    }

    htmlDecode(input) {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    renderErrorCard(trans) {
        let CODE_ERROR = RESPONSE_CODE_ERROR;
        let codeResp = CODE_ERROR.filter((code) => { return code.code === trans.status; })[0];
        return (codeResp !== null && codeResp !== undefined) ? codeResp.response : 'Código desconocido';
    }


    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const data = this.props.payments;
        const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        let columns = [
            {
                Header: this.renderHeader('Fecha'),
                accessor: 'created_at',
                Cell: ({ original }) => {
                    return(
                        <span style={{fontSize:'14px'}}> {moment.utc(original.created_at).format('DD/MM/YYYY')} </span>
                    );
                },
                width: 110
            },
            {
                Header: this.renderHeader('Tienda'),
                accessor: 'store.name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.store.name} </span>
                    );
                },
                width: 200
            },
            {
                Header: this.renderHeader('Pedido'),
                accessor: 'id',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.external_id} </span>
                    );
                },
                width: 150
            },
            {
                Header: this.renderHeader('Nombre del cliente'),
                accessor: 'name',
                Cell: ({original}) => {
                    return (
                        <span style={{fontSize:'14px'}}> {original.card_client.name} </span>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Monto'),
                Cell: ({ original }) => {
                    let color = '';
                    switch (original.status.code) {
                    case "00":
                        color = 'color-green';
                        break;
                    default:
                        color = 'color-red';
                        break;
                    }
                    return (
                        <span className={color} style={{fontSize:'14px'}}> {'RD$ ' + money(original.to_pay / 100 )} </span>
                    );
                },
                width: 160
            },
            {
                Header: this.renderHeader('Plan escogido'),
                accessor: 'financingPlan.financingEntity.name',
                Cell: ({original}) => {
                    return (
                        <div>
                            <span style={{fontSize:'14px'}}> {original.financing_plan.financingEntity.name} </span>
                            <br/>
                            <span className='fsbold' style={{fontSize:'14px'}}> {original.financing_plan.name} </span>
                        </div>
                    );
                },
                width: 260
            },
            {
                Header: this.renderHeader('Tarjeta'),
                accessor: 'card',
                Cell: ({original}) => {
                    return (
                        <div>
                            <span style={{fontSize:'14px'}} > {'xxxx xxxx xxxx ' + original.last_fourth}
                                <img
                                    style={{marginLeft:'15px'}}
                                    height={"30px"}
                                    width={'50px'}
                                    alt=''
                                    src={(original.cardtype === "MC") ? '/images/mascard.png' : ((original.cardtype === "VC") ? '/images/visacard.png' : '/images/amexcard.png')}
                                />
                            </span>
                            <br/>
                            <span className='fsbold' style={{fontSize:'14px'}}> {original.owner} </span>
                        </div>
                    );
                },
                width: 250
            },
            {
                Header: this.renderHeader('Estado'),
                accessor: 'status',
                Cell: ({ original }) => {
                    let texto = '';
                    let color = '';
                    switch (original.status.code) {
                    case "00":
                        texto = 'Aprobada';
                        color = 'color-green';
                        break;
                    default:
                        texto = 'No Aprobada';
                        color = 'color-red';
                        break;
                    }
                    return (<div className='text-center'> <p style={{wordBreak:'break-word', whiteSpace:'break-spaces'}}>
                        <span className={color} style={{fontSize:'14px', textAlign:'left'}}>{texto}</span> <br/>
                        {original.status.code !== "00" &&
                        <span className='color-blue'>{original.status.codeDesc} </span>
                        }</p></div>);
                },
                width: 200
            }
        ];
        return (
            <div className={`layout-body__content`}>
                <div className="row">
                    <div data-for='financing-tooltip' className="col-12">
                        <DataTable
                            manual={true}
                            data={data}
                            columns={columns}
                            className="dt-users"
                            page={this.props.page}
                            showPagination={false}
                            minRows={0}
                            defaultPageSize={USERS_PER_PAGE} />

                        <div className="pagination">
                            <Pagination
                                onPageChange={(page) => this.props.onFiltersChange('page', page.selected + 1) }
                                forcePage={this.props.pagination.currentPage}
                                pageCount={pageCount} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
InstallmentsListView.propTypes = {
    onFiltersChange: PropTypes.func,
    payments: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};


export default withAlert()(withRouter(connect(
    null, null
)(InstallmentsListView)));