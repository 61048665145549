'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';

import * as userSelectors from 'common/store/selectors/user.selector';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';

import Tabs from 'common/components/tabs';
import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import formatQueryString from 'common/helpers/format-query-string';

import { DEFAULT_FINANCINGS_FILTERS } from 'common/constants/objects.constant';
import {
    FINANCING_STATUSES,
    USERTYPES,
    DISPLAY_MODE
} from 'common/constants/enums.constant';

import {
    GRID_ICON,
    LIST_ICON
} from 'common/constants/icon.constant';

import FinancingsListView from './financings-list-view';
import FinancingsListTempView from '../financing-notcompleted/financings-list-temp-view';
import FinancingsListHeader from './financings-list-header';
import FinancingsCardView from './financings-card-view';
import money from 'common/helpers/money';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            financings: transactionSelectors.getFinancings(state),
            pagination: transactionSelectors.getPagination(state),
            filters: transactionSelectors.getFinancingsFilters(state),
            loading: transactionSelectors.getIsLoading(state),
            error: transactionSelectors.getError(state),
            financingsTotals: transactionSelectors.getFinancingsTotals(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancings: (filters) => {
            dispatch(transactionActions.getFinancings(filters));
        },
        getFinancingsTotals: (filters) => {
            dispatch(transactionActions.getFinancingsTotal(filters));
        },
        setFilters: (filters) => {
            dispatch(transactionActions.setFinancingsFilters(filters));
        },
        cancelGetFinancings: () => {
            dispatch(transactionActions.cancelGetFinancings());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingsListContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            displayMode: props.authUser.userType.id === USERTYPES.CLIENT ? DISPLAY_MODE.CARD : DISPLAY_MODE.LIST,
            isMobile: window.matchMedia('(max-width: 600px)').matches,
            statusInit: null
        };
    }

    checkDeviceFunction = () => {
        this.setState({
            isMobile: window.matchMedia('(max-width: 600px)').matches,
            displayMode: window.matchMedia('(max-width: 600px)').matches ? DISPLAY_MODE.CARD : this.state.displayMode
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkDeviceFunction, true);
    }

    componentDidUpdate() {
        const URLquery = queryString.parse(this.props.location.search);
        const displayMode = this.props.authUser.userType.id === USERTYPES.CLIENT ? (Number(URLquery.view) || DISPLAY_MODE.CARD) : DISPLAY_MODE.LIST;

        if (displayMode !== this.state.displayMode) {
            this.setState({displayMode});
        }
    }

    componentDidMount() {
        let filters = DEFAULT_FINANCINGS_FILTERS;

        let tab = 0;

        if (this.props.location.search !== '') {
            let queryObject = queryString.parse(this.props.location.search, {parseNumbers: true});
            filters = Object.assign(filters, queryObject);
        }

        if (this.props.authUser.userType.id === USERTYPES.AGENT) {
            filters.status = [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_QUOTE];
        }

        if (!Array.isArray(filters.status)) {
            if (typeof filters.status === 'string') {
                filters.status = filters.status.split(',').map(el => parseInt(el));
            } else {
                filters.status = [filters.status];
            }
        }

        if (_.intersection([FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED], filters.status).length > 0) {
            tab = 2;
        } else if (_.intersection([FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_CLIENT], filters.status).length > 1) {
            tab = 1;
        } else if (_.intersection([FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_ENTITY], filters.status).length === 1) {
            if (filters.status[0] === FINANCING_STATUSES.WAITING_FOR_APPROVAL) {
                tab = 1;
            } else if (filters.status[0] === FINANCING_STATUSES.WAITING_FOR_ENTITY) {
                tab = 0;
            }
        }

        this.setState({statusInit:filters.status});

        this.setState({tab: tab});

        this.setFilters(filters);

        window.addEventListener('resize', this.checkDeviceFunction, true);
    }

    clearFilters = () => {
        let filters = Object.assign({}, this.props.filters, {
            page: 1,
            perPage: 10,
            from: '',
            to: '',
            q: '',
            store: null,
            financingEntity: null,
            status: this.state.statusInit
        });

        this.setFilters(filters);
    };

    onFiltersChange = (filter, value) => {
        let filters = {... this.props.filters};
        if (!_.isArray(filter)) {
            filters[filter] = value;
        } else {
            let i=0;
            _.forEach(filter, (el) => {
                filters[el] = value[i];
                i++;
            });
        }
        this.setFilters(filters);
    };

    setFilters = (filters) => {
        this.props.cancelGetFinancings();

        let queryString = formatQueryString(filters);

        this.props.history.replace('/financings?' + queryString);

        this.props.setFilters(filters);

        let newFilters = {...filters};

        if (filters.status.length === 0) {
            delete newFilters['status'];
        }

        this.props.getFinancings(newFilters);
        if (this.props.authUser.userType.id !== USERTYPES.CLIENT) {
            this.props.getFinancingsTotals(newFilters);
        }
    };

    generaExcel = () => {
        
        let filters = this.props.filters;
        let queryString = formatQueryString(filters);
        
        const link = document.createElement('a');
        link.href = 'json/transactions/financings/reportsexcel?' + queryString;
        link.target = "_blank";
        link.click();
  
    }

    onTabChange = (tab) => {
        this.setState({tab: tab});
        if (tab === 0) {
            if (this.props.authUser.userType.id === USERTYPES.AGENT) {
                this.setState({statusInit:[FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_QUOTE]});
                this.onFiltersChange(['status', 'page'], [[FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_QUOTE], 1]);
            } else {
                this.setState({statusInit:[FINANCING_STATUSES.WAITING_FOR_QUOTE]});
                this.onFiltersChange(['status', 'page'], [[FINANCING_STATUSES.WAITING_FOR_QUOTE], 1]);
            }
        } else if (tab === 1) {
            if (this.props.authUser.userType.id === USERTYPES.AGENT) {
                this.setState({statusInit:[FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT]});
                this.onFiltersChange(['status', 'page'], [[FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT], 1]);
            } else {
                this.setState({statusInit:[FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT]});
                this.onFiltersChange(['status', 'page'], [[FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT], 1]);
            }
        } else if (tab === 2) {
            this.setState({statusInit:[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT]});
            this.onFiltersChange(['status', 'page'], [[FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT], 1]);
        } else if (tab === 3) {
            this.onFiltersChange('page', 1);
        }
    };

    removeProp = (obj, prop) => {
        let newObj = {...obj};
        delete newObj[prop];

        return newObj;
    };

    getSwitchViewIconClassTab(display) {

        let switchClass = 'switch-presentation__link';
        if (this.state.displayMode === display) {
            switchClass += ' switch-presentation__tab--active';
        }
        return switchClass;
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        let totaltab1 = 0;
        let totaltab2 = 0;
        let totaltab3 = 0;
        let totaltab4 = 0;
        let totalamount1 = 0;
        let totalamount2 = 0;
        let totalamount3 = 0;
        let totalamount4 = 0;

        if ((this.props.authUser.userType.id !== USERTYPES.CLIENT) && this.props.financingsTotals.totales && this.props.financingsTotals.totales.length > 0) {
            if ((this.props.authUser.userType.id === USERTYPES.AGENT) ) {
                totaltab1 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_QUOTE].includes(el.financing_status_id);
                }), (elem) => { return elem.total; } );
                totalamount1 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_QUOTE].includes(el.financing_status_id);
                }), (elem) => { return elem.total_amount; });
            }
            if ((this.props.authUser.userType.id !== USERTYPES.AGENT) && (this.props.financingsTotals.totales.find(el => { return el.financing_status_id === FINANCING_STATUSES.WAITING_FOR_QUOTE; }))) {
                totaltab1 = this.props.financingsTotals.totales.find(el => { return el.financing_status_id === FINANCING_STATUSES.WAITING_FOR_QUOTE; }).total;
                totalamount1 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_QUOTE].includes(el.financing_status_id);
                }), (elem) => { return elem.total_amount; });
            }
            if (this.props.authUser.userType.id === USERTYPES.AGENT) {
                totaltab2 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT].includes(el.financing_status_id);
                }), (elem) => { return elem.total; });
                totalamount2 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT].includes(el.financing_status_id);
                }), (elem) => { return elem.total_amount; });
            } else {
                totaltab2 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT].includes(el.financing_status_id);
                }), (elem) => { return elem.total; });
                totalamount2 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                    return [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT].includes(el.financing_status_id);
                }), (elem) => { return elem.total_amount; });
            }

            totaltab3 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                return [FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].includes(el.financing_status_id);
            }), (elem) => { return elem.total; });
            totalamount3 = _.sumBy(_.filter(this.props.financingsTotals.totales, (el) => {
                return [FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.DECLINED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].includes(el.financing_status_id);
            }), (elem) => { return elem.total_amount; });
        }

        if (this.props.financingsTotals.temporals && this.props.financingsTotals.temporals.length > 0) {
            totaltab4 = this.props.financingsTotals.temporals.length;
            totalamount4 = _.sumBy(this.props.financingsTotals.temporals, (elem) => { return parseFloat(elem.order_external.total); });
        }

        let tabs = (this.props.authUser.userType.id !== USERTYPES.CLIENT && ([USERTYPES.ADMIN, USERTYPES.EMPLOYEE].indexOf(this.props.authUser.userType.id) > -1))
            ? [
                {
                    title: <div><p>Sin Revisar <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab1})</span></p>
                        <span>{'RD$ ' + money(totalamount1 + (totalamount1 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div>
                                </div>
                },
                {
                    title: this.props.authUser.userType.id === USERTYPES.AGENT
                        ? <div><p> Esperando aprobación <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab2})</span></p>
                            <span>{'RD$ ' + money(totalamount2 + (totalamount2 * parseFloat(process.env.MIX_TAXES)))}</span></div>
                        : <div><p> Esperando por entidad <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab2})</span></p>
                            <span>{'RD$ ' + money(totalamount2 + (totalamount2 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div></div>
                },
                {
                    title: <div><p> Historial <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab3})</span></p>
                        <span>{'RD$ ' + money(totalamount3 + (totalamount3 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div></div>
                },
                {
                    title: <div><p >Sin Completar<span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab4})</span></p>
                        <span>{'RD$ ' + money(totalamount4 / 100)}</span></div>,
                    element:
                                <div>
                                </div>
                }
            ] : [
                {
                    title: <div><p>Sin Revisar <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab1})</span></p>
                        <span>{'RD$ ' + money(totalamount1 + (totalamount1 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div>
                                </div>
                },
                {
                    title: this.props.authUser.userType.id === USERTYPES.AGENT
                        ? <div><p> Esperando aprobación <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab2})</span></p>
                            <span>{'RD$ ' + money(totalamount2 + (totalamount2 * parseFloat(process.env.MIX_TAXES)))}</span></div>
                        : <div><p> Esperando por entidad <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab2})</span></p>
                            <span>{'RD$ ' + money(totalamount2 + (totalamount2 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div></div>
                },
                {
                    title: <div><p> Historial <span className="fsbold" style={{color:'#6cb2eb'}}>({totaltab3})</span></p>
                        <span>{'RD$ ' + money(totalamount3 + (totalamount3 * parseFloat(process.env.MIX_TAXES)))}</span></div>,
                    element:
                                <div></div>
                }
            ];

        let branchName = null;
        if (this.props.authUser.branch !== null) {
            branchName = this.props.authUser.entity.branches.filter((br) => { return br.id === this.props.authUser.branch; })[0].branch_name;
        }

        return (
            <div className="layout-body">
                <div className="pdB0 layout-body__header">
                    <FinancingsListHeader
                        total={this.props.pagination.total}
                        filters={this.props.filters}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        tab={this.state.tab}
                        setFilters={this.setFilters}
                        onFiltersChange={this.onFiltersChange}
                        clearFilters={this.clearFilters}
                        generaExcel = {this.generaExcel}
                    />
                </div>
                <div style={{paddingLeft: '2.5rem', paddingRight: '2.5rem', marginTop: '30px'}}>
                    {this.props.authUser.userType.id === USERTYPES.AGENT &&
                 <p style={{fontSize:'16px', color:'#124b6f', fontWeight:'bold'}} className="pdL3">Usted esta gestionando las solicitudes para: <span className="fsize-15r">{` ${this.props.authUser.entity.name}  ${(branchName !== null) ? '( ' + branchName +' )': ''} `}</span></p>
                    }
                    {this.props.authUser.userType.id !== USERTYPES.CLIENT &&
                        <Tabs selectedIndex={this.state.tab} onSelected={this.onTabChange} list={tabs} />
                    }
                    {this.props.authUser.userType.id === USERTYPES.CLIENT && !this.state.isMobile &&
                        <div className="row">
                            <div className="col-3 col-lg-6 view-style-switch-button-list">
                                <ul style={{marginTop: '-27px'}} className="switch-presentation">
                                    <li style={{margin: '0px'}} className="switch-presentation__item">
                                        <Link
                                            to={{ pathname: `${location.pathname}`, search: `${formatQueryString(this.removeProp(queryString.parse(location.search), 'view'))}` }}
                                            className={this.getSwitchViewIconClassTab(DISPLAY_MODE.CARD)}>
                                            <i className={GRID_ICON} />
                                        </Link>
                                    </li>
                                    <li style={{margin: '0px'}} className="switch-presentation__item">
                                        <Link
                                            style={{paddingTop: '7px'}}
                                            to={{ pathname: `${location.pathname}`, search: `${formatQueryString(this.removeProp(queryString.parse(location.search), 'view'))}&view=${DISPLAY_MODE.LIST}` }}
                                            className={this.getSwitchViewIconClassTab(DISPLAY_MODE.LIST)}>
                                            <i style={{fontSize: '1.8rem', marginRight: '-3px'}} className={LIST_ICON} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-9 col-lg-6" />
                        </div>
                    }
                </div>
               
                {!this.props.loading &&
                    this.props.financings.length > 0 &&
                    !this.props.error && this.state.displayMode === DISPLAY_MODE.LIST && this.state.tab !== 3 &&
                    <FinancingsListView
                        financings={this.props.financings}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        filters={this.props.filters}
                        onFiltersChange={this.onFiltersChange}
                        pagination={this.props.pagination}
                    />
                }

                {!this.props.loading && this.props.financingsTotals.temporals &&
                    this.props.financingsTotals.temporals.length > 0 &&
                    !this.props.error && this.state.displayMode === DISPLAY_MODE.LIST && this.state.tab === 3 &&
                    <FinancingsListTempView
                        financings={this.props.financingsTotals.temporals}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        pagination={this.props.pagination}
                    />
                }

                {!this.props.loading &&
                    this.props.financings.length > 0 &&
                    !this.props.error && this.state.displayMode === DISPLAY_MODE.CARD &&
                    <FinancingsCardView
                        financings={this.props.financings}
                        history={this.props.history}
                        authUser={this.props.authUser}
                        location={this.props.location}
                        filters={this.props.filters}
                        onFiltersChange={this.onFiltersChange}
                        pagination={this.props.pagination}
                    />
                }
                {!this.props.loading &&
                    this.props.financings.length < 1 &&
                    !this.props.error &&
                    <EmptyState
                        imgStyle={
                            {
                                backgroundColor: 'rgba(0, 125, 195, 0.08)',
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/ic_search.svg"
                        title=""
                        text={this.props.filters.q === '' ? 'No se encontraron resultados para la búsqueda.' : () => <h3 className="empty-state__headline">No se encontraron resultados de búsqueda con <strong>{this.props.filters.q}</strong></h3>}
                    />
                }
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
            </div>
        );
    }
}

FinancingsListContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingsListContainer));
