

export default function (plan, promo, items, forBins = false) {
    let prods = [];

    if (promo !== null && promo !== undefined) {

        let rulesPlan=[];
        if (forBins === false) {
            rulesPlan = promo.rules.filter((rule) => {
                return ((rule.plans[0] !== '-1') && rule.plans.filter(pl => { return pl.id === plan.id; }).length > 0);
            });
        } else {
            rulesPlan = promo.rules.filter((rule) => {
                return ((rule.bins[0] !== '-1') && rule.bins.filter(pl => {
                    return pl.plans.split(',').indexOf(plan.id.toString()) > -1;
                }).length > 0);
            });
        }

        if (rulesPlan.length > 0 && rulesPlan[0].skues[0] !== '-1') {
            let hasDiscount = false;
            for (let i = 0; i < items.length; i++) {
                if ((rulesPlan[0].skues.filter(sku => { return sku.code === items[i].sku; }).length > 0)) {
                    let discount = ((promo.percent_discount / 100) * items[i].price);
                    let newPrice = items[i].price - ((promo.max_discount === 0) ? discount : (promo.max_discount > 0 && discount <= promo.max_discount) ? discount : 0);
                    prods.push(Object.assign({}, items[i], {price: newPrice}));
                    hasDiscount = true;
                } else {
                    prods.push(items[i]);
                }
            }
            if (hasDiscount === false) { return null; }
        } else {
            prods = items.map((item) => {
                let discount = ((promo.percent_discount / 100) * item.price);
                let newPrice = item.price - ((promo.max_discount === 0) ? discount : (promo.max_discount > 0 && discount <= promo.max_discount) ? discount : 0);
                return Object.assign({}, item, {price: newPrice});
            });
        }
    } else {
        return null;
    }

    return { newItems: prods };

}