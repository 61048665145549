'use strict';

import React from 'react';
import { connect } from 'react-redux';
import WizardForm from 'react-wizard-form';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import _ from 'lodash';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';

import * as transactionActions from 'common/store/actions/transaction/transaction.actions';
import * as transactionSelectors from 'common/store/selectors/transaction.selector';
import InformationModal from 'common/components/information-modal';
import {USERTYPES} from 'common/constants/enums.constant';
import calculateFinancing from 'common/helpers/calculate-financing';
import calculatePromo from 'common/helpers/calculate-promo';

import StepOneFinancingCheckout from './step-one-financing-checkout';
import StepTwoFinancingCheckout from './step-two-financing-checkout';
import StepThreeFinancingCheckout from './step-three-financing-checkout';
import StepFourFinancingCheckout from './step-four-financing-checkout';
import AuthStepFinancingCheckout from './auth-step-financing-checkout';


const mapStateToProps = (state) => {
    return {
        authUser: userSelectors.getAuthUser(state),
        financingForm: transactionSelectors.getFinancingForm(state),
        financingCreated: transactionSelectors.getFinancingCreated(state),
        simpleError: transactionSelectors.getSimpleError(state),
        emailValidated: userSelectors.getIsValidEmail(state),
        isMobile: window.matchMedia('(max-width: 640px)').matches
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setFinancingFormData: (data) => {
            dispatch(transactionActions.setFinancingFormData(data));
        },
        setFinancingTempData: (data) => {
            dispatch(transactionActions.setFinancingTempData(data));
        },
        createFinancing: (data) => {
            dispatch(transactionActions.createFinancing(data));
        },
        resetFinancingFormData: () => {
            dispatch(transactionActions.resetFinancingFormData());
        },
        resetFinancingMessages: () => {
            dispatch(transactionActions.resetFinancingMessages());
        },
        logUser: (email, password) => {
            dispatch(userActions.authUser(email, password, 0));
        },
        validateEmail: (email) => {
            dispatch(userActions.validateEmail(email));
        },
        registerUser: (data) => {
            dispatch(userActions.registerUser(data));
        },
        setCreatingFinancing: (val) => {
            dispatch(transactionActions.setCreatingFinancing(val));
        },
        cancelCheckout: (externalId) => {
            dispatch(transactionActions.cancelCheckout(externalId));
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingCheckout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formToken: '',
            formObj: null,
            key: '',
            clientpass: null
        };

        this.props.validateEmail(this.props.cart.data.billing_email);
    }

    componentDidUpdate() {
        if (this.props.authUser !== null && this.state.formObj !== null) {
            this.props.createFinancing(this.state.formObj);
            this.setState({formObj: null});
        }
        if (this.props.financingCreated !== null) {
            if (this.props.financingForm.onSuccess) {
                location.replace(this.props.financingForm.onSuccess);
            } else {
                let from = queryString.parse(this.props.location.search)['from'];
                let url = '/financings/' + this.props.financingCreated.id;
                if (from) {
                    url += ('?from=' + from);
                }
                this.props.resetFinancingFormData();
                this.props.resetFinancingMessages();
                location.replace(url);
            }
        }
    }

    objectToFields = (data) => {
        const financing = _.cloneDeep(data);

        let fields = {
            name: financing.name,
            lastname: financing.lastname,
            identificationType: financing.identificationType.id,
            identification: financing.identification,
            identificationFile: financing.identificationFile,
            identificationFile2: financing.identificationFile2,
            email: financing.email,
            phone: financing.phone,
            livingType: financing.livingType.id,
            livingTime: financing.livingTime.id,

            isMarried: financing.isMarried,
            spouseName: financing.spouseName,
            spouseIdentificationType: financing.spouseIdentificationType ? financing.spouseIdentificationType.id : null,
            spouseIdentification: financing.spouseIdentification,
            spousePhone: financing.spousePhone,
            relativeName: financing.relativeName,
            relativePhone: financing.relativePhone,

            companyName: financing.companyName,
            companyType: financing.companyType.id,
            companyStreet: financing.companyStreet,
            companyPhone: financing.companyPhone,
            companyTitle: financing.companyTitle,
            salary: financing.salary,
            otherIncomes: financing.otherIncomes,
            workingTime: financing.workingTime.id,
            partner_number: financing.partner_number
        };

        return fields;
    };

    componentDidMount() {
        if (this.props.formToken) {
            try {
                let form = jwt.verify(this.props.formToken, process.env.MIX_SECRET_ENCRYPTER);
                this.props.setFinancingFormData(form);
                this.setState({formToken: this.props.formToken});
            } catch (err) {
                console.error('Invalid form token');
            }
        }
        let financingForm = {...this.props.financingForm};
        if (this.props.authUser !== null) {
            if (this.props.authUser.financingProfile) {
                financingForm = Object.assign({}, financingForm, this.objectToFields(this.props.authUser.financingProfile));
            } else {
                financingForm['name'] = this.props.authUser.name;
                financingForm['lastname'] = this.props.authUser.lastname;
                financingForm['phone'] = this.props.authUser.phone;
                financingForm['email'] = this.props.authUser.email;
                financingForm['identification'] = this.props.authUser.identification;
                financingForm['identificationType'] = this.props.authUser.identificationType ? this.props.authUser.identificationType.id : null;
            }

            if (this.props.authUser.defaultAddress) {
                financingForm['street'] = this.props.authUser.defaultAddress.street;
                financingForm['country'] = this.props.authUser.defaultAddress.country.id;
                financingForm['city'] = this.props.authUser.defaultAddress.city;
                financingForm['province'] = this.props.authUser.defaultAddress.province.id;
                financingForm['provinceName'] = this.props.authUser.defaultAddress.province.name;
            }

            this.props.setFinancingFormData(financingForm);
            this.setState({key : 'key'});
        } else {
            financingForm['name'] = this.props.cart.data.billing_first_name;
            financingForm['lastname'] = this.props.cart.data.billing_last_name;
            financingForm['phone'] = this.props.cart.data.billing_telephone;
            financingForm['email'] = this.props.cart.data.billing_email;
            financingForm['identification'] = this.props.cart.data.cedula;
            financingForm['street'] = ((this.props.cart.data.billing_address1) ? this.props.cart.data.billing_address1 : '') + ' '+ (this.props.cart.data.billing_address2) ? this.props.cart.data.billing_address2 : '';
            financingForm['city'] = this.props.cart.data.billing_city;
            financingForm['identificationType'] = 1;
            this.props.setFinancingFormData(financingForm);
            this.setState({key : 'key'});
        }
    }

    renderInformationModal() {
        return (
            <InformationModal success={false} imageFailure={"/images/cancel_financing.svg"} showModal={this.props.simpleError !== null}>
                {this.props.simpleError !== null &&
                    <div className="text-center">
                        {typeof this.props.simpleError === 'string' &&
                            <p>{this.props.simpleError}</p>
                        }
                        {typeof this.props.simpleError === 'object' &&
                            Object.entries(this.props.simpleError).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                        <div style={{margin: '0px 15%'}}>
                            <button
                                onClick={() => this.props.resetFinancingMessages()}
                                className="btn btn-outline">
                                Entendido
                            </button>
                        </div>
                    </div>
                }
            </InformationModal>
        );
    }

    setPass = (pass) => {
        this.setState({clientpass: pass});
    };


    onSubmit = (data, authData) => {
        let plan = {...data.plan};
        plan['monthlyFixedAmount'] = plan.monthlyFixedAmount ? plan.monthlyFixedAmount.amount : 0;
        plan['firstPaymentAmount'] = plan.firstPaymentAmount ? plan.firstPaymentAmount.amount : 0;
        let advancePayment = (data.advancePayment === '' ? 0 : parseInt(data.advancePayment));

        //calcular valor final si plan esta en promocion
        let newtotal = data.cart.total;
        if (data.plan.inPromo != null) {
            let newElem = calculatePromo(data.plan, data.plan.inPromo, data.cart.items);

            if (newElem!== null) {
                data.cart.items = newElem.newItems;
            }

            let subTotal = _.sumBy(data.cart.items, (item) => {
                return item.count * item.price;
            });

            newtotal = (subTotal + data.cart.deliveryCost) + ((subTotal + data.cart.deliveryCost) * parseFloat(process.env.MIX_TAXES));
        }

        if (plan.financingEntity.optional_delivery === false) {
            newtotal -= data.cart.deliveryCost + (data.cart.deliveryCost * parseFloat(process.env.MIX_TAXES));
            data.cart.deliveryCost = 0;
        }




        data['financingTotal'] = calculateFinancing(plan.payments, plan, (newtotal - advancePayment) / 100).rawTotal * 100;
        data['plan'] = data.plan.id;
        data['optionalFields'] = JSON.stringify(data['optionalFields']);
        if (!this.props.authUser ) {
            if (this.props.emailValidated) {
                this.setState({formObj: data});
                this.props.setCreatingFinancing(true);
                this.props.logUser(data['email'], this.state.clientpass);
            } else {
                this.setState({formObj: data});
                this.props.setCreatingFinancing(true);
                let userData = {
                    name: data.name,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    userType: USERTYPES.CLIENT,
                    identification: data.identification,
                    identificationType: data.identificationType,
                    password: authData.password,
                    passwordConfirmation: authData.passwordConfirmation,
                    addressname: 'Casa',
                    street: data.street,
                    city: data.city,
                    province: data.province,
                    country: data.country
                };
                this.props.registerUser(userData);
            }
        } else {
            this.props.createFinancing(data);
        }
    };

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div key={this.state.formToken + '-' + this.state.key}>
                <WizardForm>
                    <StepOneFinancingCheckout
                        history={this.props.history}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        cart={this.props.cart}
                        from={this.props.from}
                        onResetFinancingFormData={this.props.resetFinancingFormData}
                        onSetFinancingFormData={this.props.setFinancingFormData}
                        onSetFinancingTempData={this.props.setFinancingTempData}
                        onCancelCheckout={this.props.cancelCheckout}
                        isMobile={this.props.isMobile}
                        validateEmail={this.props.validateEmail}
                        setpass={this.setPass}
                        objectToFields={this.objectToFields}/>
                    <StepTwoFinancingCheckout
                        history={this.props.history}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        from={this.props.from}
                        onSubmit={this.onSubmit}
                        onResetFinancingFormData={this.props.resetFinancingFormData}
                        onSetFinancingFormData={this.props.setFinancingFormData}
                        onSetFinancingTempData={this.props.setFinancingTempData}
                        onCancelCheckout={this.props.cancelCheckout}/>
                    <StepThreeFinancingCheckout
                        history={this.props.history}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        from={this.props.from}
                        onResetFinancingFormData={this.props.resetFinancingFormData}
                        onSetFinancingFormData={this.props.setFinancingFormData}
                        onSetFinancingTempData={this.props.setFinancingTempData}
                        onCancelCheckout={this.props.cancelCheckout}/>
                    <StepFourFinancingCheckout
                        history={this.props.history}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        from={this.props.from}
                        onSubmit={this.onSubmit}
                        storeName={this.props.cart.storeName}
                        onResetFinancingFormData={this.props.resetFinancingFormData}
                        onSetFinancingFormData={this.props.setFinancingFormData}
                        onSetFinancingTempData={this.props.setFinancingTempData}
                        onCancelCheckout={this.props.cancelCheckout}/>
                    <AuthStepFinancingCheckout
                        history={this.props.history}
                        location={this.props.location}
                        authUser={this.props.authUser}
                        from={this.props.from}
                        onSubmit={this.onSubmit}
                        storeName={this.props.cart.storeName}
                        onResetFinancingFormData={this.props.resetFinancingFormData}
                        onSetFinancingFormData={this.props.setFinancingFormData}
                        onCancelCheckout={this.props.cancelCheckout}
                    />
                </WizardForm>
                {this.renderInformationModal()}
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FinancingCheckout);
