'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as financingEntityActions from 'common/store/actions/financing-entity/financing-entity.actions';
import * as financingEntitySelectors from 'common/store/selectors/financing-entity.selector';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';

import FinancingEntityDetailBody from './financing-entity-detail-body';
import FinancingEntityDetailHeader from './financing-entity-detail-header';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            financingEntity: financingEntitySelectors.getFinancingEntityProfile(state),
            loading: financingEntitySelectors.getIsLoading(state),
            error: financingEntitySelectors.getError(state),
            financingEntityUpdated: financingEntitySelectors.getFinancingEntityUpdated(state),
            simpleError: financingEntitySelectors.getSimpleError(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancingEntity: (id) => {
            dispatch(financingEntityActions.getFinancingEntity(id));
        },
        clearFinancingEntityProfile: () => {
            dispatch(financingEntityActions.clearFinancingEntityProfile());
        },
        resetFinancingEntityMessages: () => {
            dispatch(financingEntityActions.resetFinancingEntityMessages());
        }
    };
};

/**
 * Container for the checkout of a transaction.
 */
class FinancingEntityDetailContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.matchMedia('(max-width: 440px)').matches
        };
    }
                      

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getFinancingEntity(id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const id = this.props.match.params.id;
            this.props.getFinancingEntity(id);
        }
        if (this.props.financingEntityUpdated) {
            this.props.resetFinancingEntityMessages();
            location.reload();
        }
    }

    componentWillUnmount() {
        this.props.clearFinancingEntityProfile();
        this.props.resetFinancingEntityMessages();
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="layout-body">
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {this.props.error &&
                    <EmptyState
                        height={100}
                        image="/images/error.svg"
                        title="Error"
                        text={this.props.error}
                    />
                }
                {!this.props.loading &&
                    this.props.financingEntity &&
                    <React.Fragment
                        key='financing-entity-detail'>
                        <div className="layout-body__header pdB0">
                            <FinancingEntityDetailHeader
                                financingEntity={this.props.financingEntity}
                                history={this.props.history}
                                authUser={this.props.authUser}
                                simpleError={this.props.simpleError}
                                resetFinancingEntityMessages={this.props.resetFinancingEntityMessages}
                                isMobile={this.state.isMobile}
                            />
                            <hr className="w-100 mgT3 mgB2"/>
                        </div>
                        <div className="row"></div>
                        <div className="layout-body__content">
                            <FinancingEntityDetailBody
                                    financingEntity={this.props.financingEntity}
                                    history={this.props.history}
                                    simpleError={this.props.simpleError}
                                    authUser={this.props.authUser}
                                    resetFinancingEntityMessages={this.props.resetFinancingEntityMessages}
                                    isMobile={this.state.isMobile}
                                />
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

FinancingEntityDetailContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withAlert()(withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(FinancingEntityDetailContainer)));
