'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import {
    SearchInput,
    SelectInput,
    DateRangePicker
} from 'common/components/inputs';

import {
    USERTYPES,
    FINANCING_STATUSES
} from 'common/constants/enums.constant';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {dropdowns: userSelectors.getDropdowns(state)};
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdowns: () => {
            dispatch(userActions.getUsersDropdowns());
        }
    };
};


class InstallmentsListHeader extends React.PureComponent {

    componentDidMount() {
        if (this.props.dropdowns.financingEntities.length === 0) {
            this.props.getDropdowns();
        }
    }

    onDateChanged = (value) => {
        let filters = Object.assign({}, this.props.filters);
        filters['from'] = value.startDate;
        filters['to'] = value.endDate;
        this.props.setFilters(filters);
    };

    onClearFilters = () => {
        let dateClean = {
            startDate: '',
            endDate: ''
        };
        this.onDateChanged(dateClean);
        let datesel = document.getElementById('dateselect');
        datesel.value ='';
        this.props.clearFilters();
    };

    renderTitleZone() {
        return (
            <div className="row align-items-center mgB ">
                <div className="col-md-8 col-12">
                    <h2 className="section-headline mgT2">Transacciones tarjetas</h2>
                </div>
                <div className="col-md-4 col-12 text-right" style={{paddingRight: '20px'}}>
                    <button
                        className="btn btn-primary"
                        onClick={this.onClearFilters}>
                    Limpiar filtros</button>
                </div>
            </div>
        );
    }


    renderFilters() {
        return (
            <div className="row">
                <div className='col-md-3 col-12'>
                    <SearchInput
                        floatingLabel={'Buscar'}
                        weight={500}
                        ActiveFilter={this.props.filters.q !== '' ? 'filter-active' : ''}
                        value={this.props.filters.q}
                        label="Nombre del cliente"
                        onChange={(val) => this.props.onFiltersChange('q', val)}
                    />
                </div>
                <div className='col-md-2 col-12'>
                    <SelectInput
                        value={this.props.filters.store}
                        floatingLabel="Tienda"
                        classNamePrefix={this.props.filters.store !== null ? 'filterDrop' : ''}
                        withAllOption="Todas"
                        options={this.props.dropdowns.stores}
                        onChange={(val) => this.props.onFiltersChange(['store', 'page'], [val, 1])}
                    />
                </div>
                <div className='col-md-2 col-12'>
                    <SelectInput
                        value={this.props.filters.financingEntity}
                        floatingLabel="Entidad"
                        classNamePrefix={this.props.filters.financingEntity !== null ? 'filterDrop' : ''}
                        withAllOption="Todas"
                        options={this.props.dropdowns.financingEntities.filter(ele => (ele.entityType === 4))}
                        onChange={(val) => this.props.onFiltersChange(['financingEntity', 'page'], [val, 1])}
                    />
                </div>
                <div className='col-md-2 col-12'>
                    <SelectInput
                        value={this.props.filters.status.length === 1 ? this.props.filters.status[0] : null}
                        floatingLabel="Estado"
                        classNamePrefix={(this.props.filters.status.length === 1 ? this.props.filters.status[0] : null) !== null ? 'filterDrop' : ''}
                        withAllOption="Todos"
                        options={[
                            {value: 1, label: 'Aprobadas'},
                            {value: 2, label: 'No Aprobadas'}
                        ]}
                        onChange={(val) => this.props.onFiltersChange(['status', 'page'], [val !== null ? [val] : 'All', 1])}
                    />
                </div>
                <div className='col-md-3 col-12'>
                    <DateRangePicker
                        id="dateselect"
                        label="Fecha"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        withIcon={true}
                        className="full-width"
                        onChange={this.onDateChanged}
                        from={this.props.filters.from}
                        to={this.props.filters.to}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderTitleZone()}
                {this.renderFilters()}
                <hr style={{left: '0px', top: '180px'}} className="w-100"/>
            </div>
        );
    }
}

/**
 * Properties validations.
 */
InstallmentsListHeader.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    filters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(InstallmentsListHeader);