import React from 'react';
import PropTypes from 'prop-types';


import MaskedInput from 'common/components/inputs/masked-input';

export default class CustomMaskedInput extends React.Component {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props}  the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = { labelFocused: false };

        this.onBlur = this.onBlur.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    /**
     * Fired once the input focus changes.
     *
     * @param {bool} labelFocused determines if the element is focused or not.
     */
    changeFocus(labelFocused) {
        this.setState({ labelFocused });
    }

    onFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event.target.value);
        }
        this.changeFocus(true);
    };

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(event.target.value);
        }
        this.changeFocus(false);
    }

    /**
     * Fired once the input text changes.
     *
     * @param {Proxy.dispatcher} event  contains the input's new value.
     */
    onTextChange(event) {
        if (this.props.onChange) {
            let value = event.target.value.replace(/-/g, '');
            this.props.onChange(value);
        }
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {


        return (
            <label className="form-label">
                {this.props.label &&
                    <span className={`label ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                        {this.props.icon && (
                            <span className="fl__icon fl__icon--left">
                                <i className={this.props.icon} />
                            </span>
                        )}
                        {this.props.label}
                    </span>
                }
                <MaskedInput
                    disabled={this.props.disabled}
                    name={this.props.name}
                    mask={this.props.mask}
                    tabIndex={this.props.tabIndex}
                    placeholder={this.props.placeholder}
                    className="form-control"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    value={this.props.value}
                    onChange={this.onTextChange}
                    id={this.props.id}
                />
            </label>
        );
    }
}

/**
 * Phone input properties validation.
 */
CustomMaskedInput.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    mask: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    dangerLabel: PropTypes.bool
};
