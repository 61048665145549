'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    BACK_ICON,
    DELETE_ICON
} from 'common/constants/icon.constant';

import * as transactionSelectors from 'common/store/selectors/transaction.selector';

import Modal from 'common/components/modal';
import EmptyState from 'common/components/empty-state';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {simpleError: transactionSelectors.getSimpleError(state)};
    };
    return mapStateToProps;
};


class FinancingNotCompletedHeaderContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            showDeleteModal: false,
            showCreateModal: false
        };
    }

    renderdeleteTempFinancingModa = () => {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDeleteModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showDeleteModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/cancel_financing.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Eliminar Solicitud</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea eliminar la solicitud?</p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.onDelete(this.props.financingTemp.external_id)}
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => {
                                this.setState({showDeleteModal: false});
                                this.props.resetDataM();
                            }
                            }
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderCreateFinancingModal = () => {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showCreateModal: false})}
                closable={false}
                noDivider={true}
                showModal={this.state.showCreateModal}>
                <div>
                    <EmptyState
                        imgStyle={
                            {
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        small={true}
                        image="/images/financing_success.svg"
                        title=""
                        text=""
                    />
                    <h2 className="text-center">Crear Solicitud</h2>
                    {this.props.simpleError &&
                        <div className='has-error mgB'>
                            {typeof this.props.simpleError === 'string' &&
                                <span className='help-block'>{this.props.simpleError}</span>
                            }
                            {typeof this.props.simpleError === 'object' &&
                                Object.entries(this.props.simpleError).map(item => {
                                    return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                                })
                            }
                        </div>
                    }
                    <p className="text-center">¿Está seguro que desea crear la solicitud?</p>
                    <div style={{margin: '0px'}} className="row">
                        <button
                            onClick={() => this.props.onCreate()}
                            className="btn btn-outline col-md-4">
                            Confirmar
                        </button>
                        <div className="col-md-4 mgT" />
                        <button
                            onClick={() => {
                                this.setState({showCreateModal: false});
                                this.props.resetDataM();
                            }}
                            className="btn btn-primary col-md-4">
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    };


    render() {
        return (
            <React.Fragment>
                {!this.props.isMobile &&
            <div className="row mgT2">
                <div className="col-md-3">
                    <button
                        onClick={() => this.props.history.goBack()}
                        className="btn btn-transparent btn-link">
                        <strong>
                            <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                        Volver
                        </strong>
                    </button>
                </div>

                <div className="col-md-5">
                    <span>Fecha: <strong>{moment.utc(this.props.financingTemp.created_at).format('DD[/]MMMM[/]YYYY[,] h:mma')}</strong></span>
                </div>
                <div className="col-md-2 pdL0 mgB">
                    <button
                        onClick={() => this.setState({showDeleteModal: true})}
                        className="btn btn-primary btn-block">
                                Eliminar
                    </button>

                </div>
                <div className="col-md-2 pdL0 mgB">

                    <button
                        onClick={() => this.setState({showCreateModal:true})}
                        className="btn btn-primary btn-block">
                                Crear solicitud
                    </button>
                </div>
            </div>}
                {this.props.isMobile &&
            <div className="row mgT pdL">
                <div className="col-12 text-center">
                    <button
                        onClick={() => this.props.history.goBack()}
                        className="btn btn-transparent btn-link">
                        <strong>
                            <i style={{fontSize: '14px', marginRight: '10px'}} className={BACK_ICON}/>
                        Volver
                        </strong>
                    </button>
                </div>

                <div className="col-12 text-center" >
                    <span >Fecha: <strong>{moment.utc(this.props.financingTemp.created_at).format('DD[/]MMMM[/]YYYY[,] h:mma')}</strong></span>
                </div>


                <div className="col-6 pdL0 mgB mgT">
                    <button
                        onClick={() => this.setState({showDeleteModal: true})}
                        className="btn btn-primary btn-block">
                                Eliminar
                    </button>

                </div>
                <div className="col-6 pdL0 mgB mgT">

                    <button
                        disabled={!(this.props.profileCompleted && this.props.provinceComplete)}
                        onClick={() => this.setState({showCreateModal:true})}
                        className="btn btn-primary btn-block">
                                Crear solicitud
                    </button>
                </div>
            </div>}
                {this.renderdeleteTempFinancingModa()}
                {this.renderCreateFinancingModal()}
            </React.Fragment>
        );

    }



}

/**
 * Properties validations.
 */
FinancingNotCompletedHeaderContainer.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    financingTemp: PropTypes.object,
    onDelete: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    profileCompleted: PropTypes.bool
};

export default connect(
    makeMapStateToProps,
    null
)(FinancingNotCompletedHeaderContainer);