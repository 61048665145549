'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Media from 'react-media';
import { withAlert } from 'react-alert';

import * as userSelectors from 'common/store/selectors/user.selector';
import * as userActions from 'common/store/actions/user/user.actions';

import Header from 'common/components/header';
import Sidebar from 'common/components/sidebar';
import { USERTYPES } from 'common/constants/enums.constant';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            verificationEmailSent: userSelectors.getVerificationEmailSent(state),
            verificationEmailError: userSelectors.getVerificationEmailError(state),
            hidemenu: userSelectors.statusMenu(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(userActions.logoutUser());
        },
        sendVerificationEmail: () => {
            dispatch(userActions.sendVerificationEmail());
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        },
        hideMenu: () => {
            dispatch(userActions.hideMenu());
        }
    };
};

/**
 * Container that wraps the authentication screens.
 */
class AuthWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.pathsWithoutSidebar = [];
    }

    componentDidUpdate() {
        if (this.props.verificationEmailSent) {
            this.props.alert.show(<span>Correo de verificación enviado.</span>, {type: 'success', onClose: this.props.resetUserMessages()});
        }
        if (this.props.verificationEmailError) {
            this.props.alert.show(<span>Error al enviar correo de verificación. Intente de nuevo más tarde.</span>, {type: 'error', onClose: this.props.resetUserMessages()});
        }
    }

    render() {
        let withoutSidebar = this.pathsWithoutSidebar.find(element => {
            return this.props.location.pathname === element;
        }) !== undefined;

        return (
            <div className={`dashboard layout full-height`}>
                {this.props.user.userType.id === USERTYPES.CLIENT && this.props.user.emailVerifiedAt === null &&
                   <Media query='(max-width: 440px)'>
                       {matches =>
                           matches ? (
                               <div
                                   style={{
                                       backgroundColor: '#F8E9EB',
                                       position: 'absolute',
                                       width: '100%',
                                       height: '40px',
                                       top: '70px'
                                   }}
                                   className="text-center" >
                                   <p style={{marginTop:'5px'}}>
                                Por favor, valide su correo electrónico
                                       <a
                                           className="btn btn-transparent"
                                           onClick={this.props.sendVerificationEmail}>
                                           <strong>aquí</strong>
                                       </a>
                                   </p>
                               </div>
                           ):(
                               <div
                                   style={{
                                       backgroundColor: '#F8E9EB',
                                       paddingLeft: '241px',
                                       position: 'absolute',
                                       width: '100%',
                                       height: '36px',
                                       top: '0px'
                                   }}
                                   className="text-center" >
                                   <p>
                            Por favor, valide su correo electrónico
                                       <button
                                           className="btn btn-transparent"
                                           onClick={this.props.sendVerificationEmail}>
                                           <strong>aquí</strong>
                                       </button>
                            para que su solicitud pueda ser revisada.
                                   </p>
                               </div>
                           )
                       }
                   </Media>
                }
                <Media query='(max-width: 999px)'>
                    {matches =>
                        matches ? (
                            <div>
                                <main className={`layout-main full-height layout-main__responsive`}>{this.props.children}</main>
                                <Header
                                    user={this.props.user}
                                    onLogout={this.props.logout}
                                    history={this.props.history}
                                    pathname={this.props.location.pathname}
                                />
                            </div>
                        ) : (
                            <div>
                                { !withoutSidebar &&
                                    <Sidebar
                                        authUser={this.props.user}
                                        history={this.props.history}
                                        logOut={this.props.logout}
                                        hideMenu={this.props.hideMenu}
                                        location={this.props.location.pathname} />
                                }
                                <main className={`layout-main full-height ${!withoutSidebar ? '' : 'layout-main__no-sidebar'}`}
                                    style={!this.props.hidemenu ? {paddingLeft:'241px'} : {paddingLeft:'2rem'}}
                                >{this.props.children}</main>
                            </div>
                        )
                    }
                </Media>
            </div>
        );
    }
}

AuthWrapper.propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    history: PropTypes.shape({ push: PropTypes.func })
};

export default withAlert()(withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(AuthWrapper)));
