import React from 'react';

/**
 * Component responsible of displaying a custom page when the given one
 * could not be found.
 */
export default class InConstructionContainer extends React.Component {

    /**
     * Initializes the component.
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div className="in-construction">
                <h1 className="text--align-center">Under construction</h1>
                <img height='200px' width='200px' src="/images/under-construction.png"></img>
            </div>
        );
    }
}
