'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';


import PromotionsHeader from './promotions-header';
import PromotionsListView from './promotions-list-body';

import EmptyState from 'common/components/empty-state';
import LoadingComponent from 'common/components/loading';
import formatQueryString from 'common/helpers/format-query-string';


import { DEFAULT_PROMOTION_FILTERS } from 'common/constants/objects.constant';


import * as userSelectors from 'common/store/selectors/user.selector';
import * as promotionSelector from 'common/store/selectors/promotion.selector';
import * as promotionActions from '../../../common/store/actions/promotions/promotions.actions';

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            authUser: userSelectors.getAuthUser(state),
            promotionsAll: promotionSelector.getAllPromotions(state),
            pagination: promotionSelector.getPagination(state),
            filters: promotionSelector.getFilters(state),
            loading: promotionSelector.getIsLoading(state),
            error: promotionSelector.getError(state)
        };
    };
    return mapStateToProps;
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAllPromotions: (filters) => {
            dispatch(promotionActions.searchPromotions(filters));
        },
        setFilters: (filters) => {
            dispatch(promotionActions.setFilters(filters));
        },
        createPromotion: (datapromotion) => {
            dispatch(promotionActions.createPromotion(datapromotion));
        }
    };
};

class PromotionsList extends React.Component{


    componentDidMount() {
        let filters = DEFAULT_PROMOTION_FILTERS;

        if (this.props.location.search !== '') {
            let queryObject = queryString.parse(this.props.location.search, {parseNumbers: true});
            filters = Object.assign(filters, queryObject);
        }

        this.setFilters(filters);
    }

    onFiltersChange = (filter, value) => {
        let filters = {... this.props.filters};
        if (!_.isArray(filter)) {
            filters[filter] = value;
        } else {
            let i=0;
            _.forEach(filter, (el) => {
                filters[el] = value[i];
                i++;
            });
        }

        this.setFilters(filters);
    };

    setFilters = (filters) => {

        let queryString = formatQueryString(filters);

        this.props.history.replace('/promotions?' + queryString);

        this.props.setFilters(filters);

        let newFilters = {...filters};

        this.props.getAllPromotions(newFilters);
    };

    onCreatePromotion = (data) => {
        this.props.createPromotion(data);
    };

    render() {
        return (
            <div className="layout-body">
                <div className="pdB0 layout-body__header">
                    <PromotionsHeader
                        user={null}
                        history={this.props.history}
                        total={this.props.pagination ? this.props.pagination.total : 0}
                        onFiltersChange={this.onFiltersChange}
                        query={this.props.filters.q}
                        onCreatePromo={this.onCreatePromotion}
                    >
                    </PromotionsHeader>
                </div>
                {this.props.loading &&
                    <LoadingComponent
                        height={160}
                        width={120} />
                }
                {!this.props.loading && this.props.promotionsAll.length > 0 && !this.props.error &&
                    <PromotionsListView
                        history={this.props.history}
                        pagination={this.props.pagination}
                        promotions={this.props.promotionsAll}
                        onFiltersChange={this.onFiltersChange}
                    ></PromotionsListView>}
                {!this.props.loading &&
                    this.props.promotionsAll.length < 1 &&
                    !this.props.error &&
                    <EmptyState
                        imgStyle={
                            {
                                backgroundColor: 'rgba(0, 125, 195, 0.08)',
                                borderRadius: '150px',
                                margin: 'auto',
                                width: '170px',
                                padding: '20px'
                            }
                        }
                        image="/images/ic_search.svg"
                        title=""
                        text={this.props.filters.q === '' ? 'No se encontraron resultados para la búsqueda.' : () => <h3 className="empty-state__headline">No se encontraron resultados de búsqueda con <strong>{this.props.filters.q}</strong></h3>}
                    />
                }
            </div>
        );
    }
}


PromotionsList.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(PromotionsList));