import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Steps, { Step } from 'rc-steps';

import {
    CIRCLE_OUTLINE_ICON,
    MARKED_CHECKBOX_ICON,
    CLOSE_CIRCLE_ICON
} from 'common/constants/icon.constant';
import { FINANCING_STATUSES } from 'common/constants/enums.constant';
import { USERTYPES } from '../../../common/constants/enums.constant';

/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class FinancingActionsList extends React.PureComponent {

    constructor(props) {
        super(props);

        let PrevStatus = props.financing.previousFinancingStatus ? props.financing.previousFinancingStatus.id : null;

        this.state = {
            waitingForQuoteIcon: ((props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_QUOTE || PrevStatus === FINANCING_STATUSES.SENT || PrevStatus === null )) ? CLOSE_CIRCLE_ICON : CIRCLE_OUTLINE_ICON,

            waitingForQuoteColor: ((props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_QUOTE || PrevStatus === FINANCING_STATUSES.SENT || PrevStatus === null)) ? 'color-orange' : 'color-primary',

            waitingForEntityIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_ENTITY || PrevStatus === FINANCING_STATUSES.WAITING_FOR_APPROVAL) ? CLOSE_CIRCLE_ICON: CIRCLE_OUTLINE_ICON,

            waitingForEntityColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_ENTITY || PrevStatus === FINANCING_STATUSES.WAITING_FOR_APPROVAL) ? 'color-orange' : 'color-primary',

            waitingForStoreIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_STORE || PrevStatus === FINANCING_STATUSES.WAITING_FOR_CLIENT) ? CLOSE_CIRCLE_ICON: CIRCLE_OUTLINE_ICON,

            waitingForStoreColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.WAITING_FOR_STORE || PrevStatus === FINANCING_STATUSES.WAITING_FOR_CLIENT) ? 'color-orange' : 'color-primary',

            waitingForSignedIcon: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.APPROVED_BY_ENTITY || PrevStatus === FINANCING_STATUSES.APPROVED || PrevStatus === FINANCING_STATUSES.SIGNED) ? CLOSE_CIRCLE_ICON : CIRCLE_OUTLINE_ICON,

            waitingForSignedColor: (props.financing.financingStatus.id === FINANCING_STATUSES.CANCELED || props.financing.financingStatus.id === FINANCING_STATUSES.DECLINED) &&
                (PrevStatus === FINANCING_STATUSES.APPROVED_BY_ENTITY || PrevStatus === FINANCING_STATUSES.APPROVED || PrevStatus === FINANCING_STATUSES.SIGNED) ? 'color-orange' : 'color-primary'
        };

        //|| props.financing.order.quoteFile !== null
        if ([FINANCING_STATUSES.CANCELED, FINANCING_STATUSES.DECLINED].indexOf(props.financing.financingStatus.id) === -1) {
            if ([FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_CLIENT, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT ].indexOf(props.financing.financingStatus.id) > -1 ) {
                this.state.waitingForQuoteIcon = MARKED_CHECKBOX_ICON;
            }

            if ([FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
                this.state.waitingForEntityIcon = MARKED_CHECKBOX_ICON;
            }


            if ([FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
                this.state.waitingForSignedIcon = MARKED_CHECKBOX_ICON;
            }

            if ([FINANCING_STATUSES.SOLD_PRODUCT].indexOf(props.financing.financingStatus.id) > -1) {
                this.state.waitingForStoreIcon = MARKED_CHECKBOX_ICON;
            }
        } else {

            if ([FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(PrevStatus) > -1 ) {
                this.state.waitingForQuoteIcon = MARKED_CHECKBOX_ICON;
            }

            if ([FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(PrevStatus) > -1) {
                this.state.waitingForEntityIcon = MARKED_CHECKBOX_ICON;
            }


            if ([FINANCING_STATUSES.SIGNED, FINANCING_STATUSES.WAITING_FOR_STORE, FINANCING_STATUSES.SOLD_PRODUCT].indexOf(PrevStatus) > -1) {
                this.state.waitingForSignedIcon = MARKED_CHECKBOX_ICON;
            }

            if ([FINANCING_STATUSES.SOLD_PRODUCT].indexOf(PrevStatus) > -1) {
                this.state.waitingForStoreIcon = MARKED_CHECKBOX_ICON;
            }
        }
    }

    getActionAlone(value) {
        let posSol = value.indexOf('Sol.');
        return value.substring(posSol, posSol+16) + ' Contacte a su ejecutiva de cuenta para saber las razones. ';
    }

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        return (
            <div className="financing-action-list full-width">
                <Steps direction="vertical" current={0}>
                    <Step
                        icon={<i className={MARKED_CHECKBOX_ICON} />}
                        title={
                            <React.Fragment>
                                <p className="cursor-pointer"><strong>Enviada</strong></p>
                                <div>
                                    {this.props.actions.filter( elem => {
                                        return [FINANCING_STATUSES.SENT].indexOf(elem.financingStatus.id) > -1;
                                    })
                                        .map(element => {
                                            return (
                                                <p key={element.id} style={{fontSize: '14px'}}>
                                                    <span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                    <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                    </span>
                                                </p>
                                            );
                                        })}
                                </div>
                            </React.Fragment>
                        }>
                    </Step>
                    <Step
                        icon={<i className={this.state.waitingForQuoteIcon + ' ' + this.state.waitingForQuoteColor} />}
                        title={
                            <React.Fragment>
                                <p className="cursor-pointer"><strong>Esperando cotización</strong></p>
                                <div>
                                    {this.props.actions.filter( elem => {
                                        return [FINANCING_STATUSES.WAITING_FOR_QUOTE].indexOf(elem.financingStatus.id) > -1;
                                    }).map(element => {
                                        return (
                                            <p key={element.id} style={{fontSize: '14px'}}>
                                                {this.props.usertype !== USERTYPES.CLIENT &&
                                                <div>
                                                    <span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                    <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                    </span>
                                                </div>}
                                                {this.props.usertype === USERTYPES.CLIENT && !element.action.includes('Com.') && !element.action.includes('Comentario') &&
                                                    <div><span dangerouslySetInnerHTML={{__html: element.action.includes('procede.') ? this.getActionAlone(element.action) : element.action + ' '}} />
                                                        <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                        </span>
                                                    </div>}

                                            </p>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        }>
                    </Step>
                    <Step
                        icon={<i className={this.state.waitingForEntityIcon + ' ' + this.state.waitingForEntityColor} />}
                        title={
                            <React.Fragment>
                                <p className="cursor-pointer"><strong>Esperando por entidad financiera</strong></p>
                                <div>
                                    {this.props.actions.filter( elem => {
                                        return [FINANCING_STATUSES.WAITING_FOR_ENTITY, FINANCING_STATUSES.WAITING_FOR_APPROVAL, FINANCING_STATUSES.WAITING_FOR_CLIENT, FINANCING_STATUSES.APPROVED_BY_ENTITY, FINANCING_STATUSES.APPROVED, FINANCING_STATUSES.DECLINED].indexOf(elem.financingStatus.id) > -1;
                                    }).map(element => {
                                        return (
                                            <p key={element.id} style={{fontSize: '14px'}}>
                                                {this.props.usertype !== USERTYPES.CLIENT &&
                                                <div>
                                                    <span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                    <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                    </span>
                                                </div>}
                                                {this.props.usertype === USERTYPES.CLIENT && !element.action.includes('Com.') && !element.action.includes('Comentario') &&
                                                    <div><span dangerouslySetInnerHTML={{__html: element.action.includes('procede.') ? this.getActionAlone(element.action) : element.action + ' '}} />
                                                        <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                        </span>
                                                    </div>}
                                            </p>
                                        );
                                    })
                                    }

                                </div>
                            </React.Fragment>
                        }>
                    </Step>
                    <Step
                        icon={<i className={this.state.waitingForSignedIcon + ' '+ this.state.waitingForSignedColor } />}
                        title={
                            <React.Fragment>
                                <p className="cursor-pointer"><strong>{this.props.actions[FINANCING_STATUSES.SIGNED] ? 'Firmada por el cliente' : 'Esperando por la firma del cliente'}</strong></p>
                                <div>
                                    {this.props.actions.filter( elem => {
                                        return [FINANCING_STATUSES.SIGNED].indexOf(elem.financingStatus.id) > -1;
                                    }).map(element => {
                                        return (
                                            <p key={element.id} style={{fontSize: '14px'}}>
                                                {this.props.usertype !== USERTYPES.CLIENT &&
                                                <div>
                                                    <span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                    <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                    </span>
                                                </div>}
                                                {this.props.usertype === USERTYPES.CLIENT && !element.action.includes('Com.') && !element.action.includes('Comentario') &&
                                                    <div><span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                        <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                        </span>
                                                    </div>}
                                            </p>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        }>
                    </Step>
                    <Step
                        icon={<i className={this.state.waitingForStoreIcon + ' ' + this.state.waitingForStoreColor} />}
                        title={
                            <React.Fragment>
                                <p className="cursor-pointer"><strong>Mercancía entregada por tienda</strong></p>
                                <div>
                                    {this.props.actions.filter( elem => {
                                        return [FINANCING_STATUSES.SOLD_PRODUCT].indexOf(elem.financingStatus.id) > -1;
                                    }).map(element => {
                                        return (
                                            <p key={element.id} style={{fontSize: '14px'}}>
                                                {this.props.usertype !== USERTYPES.CLIENT &&
                                                <div>
                                                    <span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                    <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                    </span>
                                                </div>}
                                                {this.props.usertype === USERTYPES.CLIENT && !element.action.includes('Com.') && !element.action.includes('Comentario') &&
                                                    <div><span dangerouslySetInnerHTML={{__html: element.action + ' '}} />
                                                        <span>
                                                    &#8226; {moment.utc(element.createdAt).format('DD [de] MMMM[,] YYYY[,] h:mma')}
                                                        </span>
                                                    </div>}
                                            </p>
                                        );
                                    })}


                                </div>
                            </React.Fragment>
                        }>
                    </Step>

                </Steps>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
FinancingActionsList.propTypes = {
    actions: PropTypes.object,
    history: PropTypes.object.isRequired,
    financing: PropTypes.object
};
