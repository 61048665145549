export const GENERIC_ERROR = 'Ha ocurrido un error de servidor. Por favor}, intente más tarde.';

export const RESPONSE_CODE_ERROR = [
    {code:'00', response: 'Aprobada'},
    {code:'01', response: 'Llamar al banco'},
    {code:'02', response: 'Llamar al banco'},
    {code:'03', response: 'Comercio invalido'},
    {code:'04', response: 'Rechazada'},
    {code:'05', response: 'Rechazada'},
    {code:'06', response: 'Error en mensaje'},
    {code:'07', response: 'Tarjeta rechazada'},
    {code:'08', response: 'Llamar al banco'},
    {code:'09', response: 'Petición en progreso'},
    {code:'10', response: 'Aprobación Parcial'},
    {code:'11', response: 'VIP aprobado'},
    {code:'12', response: 'Transacción inválida'},
    {code:'13', response: 'Monto invalido'},
    {code:'14', response: 'Cuenta invalida'},
    {code:'19', response: 'Reintentar transacción'},
    {code:'22', response: 'Transacción no aprobada'},
    {code:'23', response: 'Transacción no aceptada'},
    {code:'31', response: 'BIN no sportado'},
    {code:'33', response: 'Tarjeta Expirada'},
    {code:'34', response: 'Sospecha de fraude, extraigala'},
    {code:'36', response: 'Transacción no aprobada'},
    {code:'37', response: 'Call acquirer security}, pick-up'},
    {code:'38', response: 'Intentos de pines excedido'},
    {code:'39', response: 'Tarjeta invalida'},
    {code:'41', response: 'Tarjeta declarada como perdida'},
    {code:'42', response: 'La cuenta no es universal'},
    {code:'43', response: 'Tarjeta robada'},
    {code:'51', response: 'Sin saldo suficiente'},
    {code:'54', response: 'Tarjeta expirada'},
    {code:'55', response: 'PIN incorrecto'},
    {code:'57', response: 'Transacción no permitida en este terminal'},
    {code:'58', response: 'Transacción no permitida en este terminal'},
    {code:'59', response: 'Sospech de fraude'},
    {code:'62', response: 'Tarjeta restringida'},
    {code:'79', response: 'Rechazada'},
    {code:'81', response: 'Pin invalido'},
    {code:'82', response: 'Pin requerido'},
    {code:'91', response: 'Procesador no disponible'},
    {code:'94', response: 'Transacción duplicada'},
    {code:'97', response: 'Emisor no disponible'},
    {code:'C22', response: 'Número de ID individual inválido'},
    {code:'N01', response: 'Fondos insuficiente - entrada repetida por ODFI'},
    {code:'N09', response: 'Uncollected funds - entry repeated by ODFI'},
    {code:'R01', response: 'Fondo insuficiente'},
    {code:'R02', response: 'Cuenta cerrada'},
    {code:'R03', response: 'Imposible localizar cuenta'},
    {code:'R04', response: 'Número de cuenta inválido'},
    {code:'R05', response: 'Extracción no autorizada'},
    {code:'R06', response: 'Returned per ODFI request'},
    {code:'R07', response: 'Autorizacion revocada por el client'},
    {code:'R08', response: 'Pago detenido'},
    {code:'R09', response: 'Fondos no recaudados'},
    {code:'R10', response: 'Customer advises not authorized'},
    {code:'R11', response: 'Check truncation entry return'},
    {code:'R12', response: 'Branch sold to another DFI'},
    {code:'R13', response: 'Número de ruta ACH no válido'},
    {code:'R14', response: 'Representative Payee Deceased or Unable to continue'},
    {code:'R15', response: 'Beneficiario o titular de la cuenta fallecido'},
    {code:'R16', response: 'Cuenta congelada'},
    {code:'R17', response: 'File record edit criteria'},
    {code:'R20', response: 'La cuenta no permite transacciones'},
    {code:'R21', response: 'Identificacion de compañia no válida'},
    {code:'R23', response: 'Entrada de credito rechazada'},
    {code:'R24', response: 'Entrada duplicada'},
    {code:'R28', response: 'Error de chequeo de número de ruta'},
    {code:'R29', response: 'Corporate Customer advises not authorized'},
    {code:'R31', response: 'Entrada permisible'},
    {code:'R34', response: 'Participación DFI limitada'},
    {code:'R70', response: 'Entrada permisible no aceptada'}
];
