import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import RegisterUserForm from 'containers/transactions/transaction-checkout/financing-checkout/step-two-financing-checkout/register-user-form';

export default class EditFinancingClientInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.objectToFields({...props.financing}),
            canSubmit: true,
            errorFields: {
                name: { errorClass: null, dangerLabel: false },
                lastname: { errorClass: null, dangerLabel: false },
                identificationType: { errorClass: null, dangerLabel: false },
                identification: { errorClass: null, dangerLabel: false },
                identificationFile: { errorClass: null, dangerLabel: false },
                email: { errorClass: null, dangerLabel: false },
                phone: { errorClass: null, dangerLabel: false },
                street: { errorClass: null, dangerLabel: false },
                city: { errorClass: null, dangerLabel: false },
                province: { errorClass: null, dangerLabel: false },
                country: { errorClass: null, dangerLabel: false },
                livingType: { errorClass: null, dangerLabel: false },
                livingTime: { errorClass: null, dangerLabel: false }
            }
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }

    objectToFields = (data) => {
        const financing = _.cloneDeep(data);
        let subTotal = _.sumBy(financing.order.items, (item) => {
            return item.count * item.price;
        });
        let taxes = (subTotal + financing.order.deliveryCost) * parseFloat(process.env.MIX_TAXES);
        let total = subTotal + taxes + financing.order.deliveryCost;



        let fields = {
            name: financing.name,
            lastname: financing.lastname,
            advancePayment: financing.advancePayment ? financing.advancePayment : '',
            currencyId: financing.currency ? financing.currency.id : null,
            cart: {
                items: financing.order.items,
                currencyId: financing.order.currency.id,
                storeId: financing.order.store.id,
                deliveryCost: financing.order.deliveryCost,
                total: total
            },
            identificationType: financing.identificationType.id,
            identification: financing.identification,
            identificationFile: financing.identificationFile,
            identificationFile2: financing.identificationFile2,
            email: financing.email,
            phone: financing.phone,
            street: financing.order.transactions[0].address.street,
            city: financing.order.transactions[0].address.city,
            province: financing.order.transactions[0].address.province.id,
            country: financing.order.transactions[0].address.country.id,
            livingType: financing.livingType.id,
            livingTime: financing.livingTime.id,
            financingEntity: financing.financingPlan.financingEntity.id,
            plan: financing.financingPlan,

            isMarried: financing.isMarried,
            spouseName: financing.spouseName,
            spouseIdentificationType: financing.spouseIdentificationType ? financing.spouseIdentificationType.id : null,
            spouseIdentification: financing.spouseIdentification,
            spousePhone: financing.spousePhone,
            relativeName: financing.relativeName,
            relativePhone: financing.relativePhone,

            companyName: financing.companyName,
            companyType: financing.companyType.id,
            companyStreet: financing.companyStreet,
            companyPhone: financing.companyPhone,
            companyTitle: financing.companyTitle,
            salary: financing.salary,
            otherIncomes: financing.otherIncomes,
            workingTime: financing.workingTime.id
        };

        return fields;
    };

    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let data = {... this.state.data};
        data[field] = value;
        this.setState({data: data});
    };

    onSubmit = () => {
        this.props.onSubmit({...this.state.data});
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <RegisterUserForm
                    userData={this.state.data}
                    canSubmit={this.canSubmit}
                    onFieldChange={this.onFieldChange}
                    errorFields={this.state.errorFields}
                    dropdowns={this.props.dropdowns}
                    notVisibleFields={['name', 'lastname', 'identificationType', 'identification', 'identificationFile', 'identificationFile2']}
                />
                {this.props.error &&
                    <div className='has-error mgB'>
                        {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                        }
                        {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                        }
                    </div>
                }
                <hr />
                <div className="row no-gutters">
                    <button
                        onClick={this.props.onCancel}
                        className="btn btn-outline col-md-4">
                        Cancelar
                    </button>
                    <div className="col-md-4 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-user-modal'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.data}>
                        <button
                            id="submit-button"
                            disabled={JSON.stringify(this.objectToFields(this.props.financing)) === JSON.stringify(this.state.data)}
                            className={this.validSubmit() ? 'btn btn-primary col-md-4' :'btn btn-clickable-pseudo-disabled col-md-4'}>
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>
            </React.Fragment>
        );
    }
}

EditFinancingClientInfo.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    financing: PropTypes.object,
    dropdowns: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
