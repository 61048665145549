'use strict';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FinancingEntityListContainer from './financing-entity-list';
import FinancingEntityDetailContainer from './financing-entity-detail';

export default class FinancingEntitiesContainer extends React.Component {
    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <Switch>
                <Route exact path="/financing-entities" component={FinancingEntityListContainer} />
                <Route path="/financing-entities/:id" component={FinancingEntityDetailContainer} />
            </Switch>
        );
    }
}
