'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Formsy from 'formsy-react';

import * as userActions from 'common/store/actions/user/user.actions';
import * as userSelectors from 'common/store/selectors/user.selector';

import {
    FormsyInputWrapper,
    PasswordInput
} from 'common/components/inputs';
import RequiredFieldsWrapper from 'common/components/required-fields-wrapper';
import { PASSWORD_ICON } from 'common/constants/icon.constant';



function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (password, passwordConfirmation, token) => {
            dispatch(userActions.resetPassword(password, passwordConfirmation, token));
        },
        resetUserMessages: () => {
            dispatch(userActions.resetUserMessages());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        error: userSelectors.getError(state),
        passwordChanged: userSelectors.getPasswordChanged(state)
    };
};

class UsersWelcomeContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            userData: {
                newpassword: '',
                confirm_password: ''
            },
            errorFields: {
                newpassword: { errorClass: null, dangerLabel: false },
                confirm_password: { errorClass: null, dangerLabel: false }
            },
            error: {
                msg: '',
                onshow: false
            },
            token: queryString.parse(this.props.location.search)['t'],
            email: queryString.parse(this.props.location.search)['email']
        };

        this.basicDataRef = React.createRef();

        this.canSubmit = this.canSubmit.bind(this);

        this.enableSubmit = this.canSubmit.bind(this, true);
        this.disableSubmit = this.canSubmit.bind(this, false);
    }


    componentDidMount() {
        if (this.basicDataRef.current) {
            this.basicDataRef.current.reset();
        }
    }

    canSubmit(canSubmit) {
        this.setState({canSubmit: canSubmit});
    }

    onFieldChange = (field, value) => {
        let userData = {... this.state.userData};
        userData[field] = value;
        this.setState({userData: userData});
    };

    onSubmit = () => {
        if ((this.state.userData.newpassword !== this.state.userData.confirm_password) ) {
            this.setState({error: {msg:'Las contraseñas no coinciden', onshow:true }});
        } else {
            this.setState({error: {msg:'', onshow:false }});
            this.props.resetPassword(this.state.userData.newpassword, this.state.userData.confirm_password, this.state.token);
        }
    };

    goToLogin = () => {
        this.props.resetUserMessages();
        this.props.history.push('/');
    };

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <div className="mgB1 mgT2 text-center">
                    {!this.props.passwordChanged && <p> Bienvenido, ingresa tu nueva contraseña </p>}
                    {this.props.passwordChanged && <p> En hora buena, ahora puedes acceder!!! </p>}
                </div>
                {!this.props.passwordChanged &&
                <Formsy
                    ref={this.basicDataRef}
                    onValid={this.enableSubmit}
                    onInvalid={this.disableSubmit}>
                    <div className="row mgB2">
                        <FormsyInputWrapper
                            required
                            className="col-12 mgB2"
                            name="newpassword"
                            customError={this.state.errorFields.newpassword.errorClass}
                            value={this.state.userData.newpassword}>
                            <PasswordInput
                                icon={PASSWORD_ICON}
                                label='Nueva contraseña'
                                dangerLabel={this.state.errorFields.newpassword.dangerLabel}
                                value={this.state.userData.newpassword}
                                onChange={(val) => this.onFieldChange('newpassword', val)}
                            />
                        </FormsyInputWrapper>
                        <FormsyInputWrapper
                            required
                            className="col-12 mgB2"
                            name="confirm_password"
                            customError={this.state.errorFields.confirm_password.errorClass}
                            value={this.state.userData.confirm_password}>
                            <PasswordInput
                                icon={PASSWORD_ICON}
                                label='Confirmar contraseña '
                                dangerLabel={this.state.errorFields.confirm_password.dangerLabel}
                                value={this.state.userData.confirm_password}
                                onChange={(val) => this.onFieldChange('confirm_password', val)}
                            />
                        </FormsyInputWrapper>
                    </div>
                </Formsy>}
                <hr />
                {this.props.error &&
                <div className="has-error mgB text-center">
                    {this.state.error.onshow &&
                            <span className='help-block'>{this.state.error.msg}</span>
                    }

                    {typeof this.props.error === 'string' &&
                            <span className='help-block'>{this.props.error}</span>
                    }
                    {typeof this.props.error === 'object' &&
                            Object.entries(this.props.error).map(item => {
                                return <p key={'error-' + item[0]} className='help-block mgB'>{item[1].message}</p>;
                            })
                    }
                </div>
                }

                {this.state.error.onshow &&
                    <div className="has-error mgB text-center">
                        <span className='help-block'>{this.state.error.msg}</span>
                    </div>
                }
                {!this.props.passwordChanged &&
                <div className="row no-gutters">

                    <div className="col-12 mgT" />
                    <RequiredFieldsWrapper
                        onSubmit={this.onSubmit}
                        validSubmit={this.validSubmit}
                        containerId='create-new-password'
                        onUpdateErrorFields={(errorFields) => this.setState({errorFields: errorFields})}
                        errorFields={this.state.errorFields}
                        fields={this.state.userData}>
                        <button
                            id="submit-button"
                            className={this.validSubmit() ? 'btn btn-primary col-12' :'btn btn-clickable-pseudo-disabled col-12'}
                        >
                            Guardar
                        </button>
                    </RequiredFieldsWrapper>
                </div>}
                {this.props.passwordChanged &&
                    <button
                        id="button"
                        className={'btn btn-primary col-12'}
                        onClick = {this.goToLogin}
                    >
                    Ir a Inicio
                    </button>
                }

            </React.Fragment>
        );
    }
}


UsersWelcomeContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersWelcomeContainer));



