import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import Modal from 'common/components/modal';
import LoadingComponent from 'common/components/loading';

import configureStore from 'common/store';
import { authUserSuccess } from 'common/store/actions/user/user.actions';
import addFormsyRules from 'common/helpers/formsy-rules';
import {
    NOTIFICATION_ICON,
    CLOSE_CIRCLE_ICON
} from 'common/constants/icon.constant';

import Root from './containers';
import { } from './common/constants/icon.constant';

addFormsyRules();

const store = configureStore();

moment.locale('es');
moment.updateLocale('es', {months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']});

/*global user*/

if (typeof user !== 'undefined') {
    store.dispatch(authUserSuccess(user));
}

store.subscribe(() => {
    if (store.getState().genericUIReducer.loadingMessage) {
        ReactDOM.render(
            <Modal
                onModalClose={() => {}}
                title=""
                className="modalLoading"
                showHeader={false}
                showModal={true}>
                <LoadingComponent
                    style={{paddingTop: '0px', width: 'auto'}}
                    height={100}
                    width={80}
                />
                <h4 className="text-center">Espere por favor, seremos breves.</h4>
                <h3 className="mgB2 mgT text-center">{store.getState().genericUIReducer.loadingMessage}</h3>
            </Modal>,
            document.getElementById('loading-modal')
        );
    } else {
        ReactDOM.unmountComponentAtNode(document.getElementById('loading-modal'));
    }
    if (store.getState().genericUIReducer.loading) {
        document.getElementById('html').classList.add('wait');
    } else {
        document.getElementById('html').classList.remove('wait');
    }
});

const AlertTemplate = ({ options, message, close }) => (
    <div className="custom-alert" style={{backgroundColor: options.type === 'success' ? '#74A90A' : options.type === 'error' ? '#fd8139' : '#1FB0EE'}}>
        <i className={'mgR ' + NOTIFICATION_ICON} />
        {message}
        <i onClick={close} className = {CLOSE_CIRCLE_ICON + ' mgL cursor-pointer'} />
    </div>
);

const options = {
    position: positions.TOP_RIGHT,
    transition: transitions.FADE,
    timeout: 8000,
    containerStyle: {pointerEvents: 'all'}
};

ReactDOM.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
            <Root />
        </AlertProvider>
    </Provider>,
    document.getElementById('root')
);
