import React from 'react';
import PropTypes from 'prop-types';
import Slider, { Handle } from 'rc-slider';

import calculateFinancing from 'common/helpers/calculate-financing';
import calculatePromo from 'common/helpers/calculate-promo';
import money from 'common/helpers/money';
import _ from 'lodash';

import { Checkbox } from 'common/components/inputs';
import ImagePreview from 'common/components/image-preview';

const handle = props => {
    const { value, dragging, ...restProps } = props;
    return (
        <Handle value={value} {...restProps}>
            <div className="inner">
                <div className={`slider-tooltip${dragging ? ' active' : ''}`}>
                    <span className="slider-tooltip-inner">{value} cuota(s)</span>
                </div>
            </div>
        </Handle>
    );
};

export default class FinancingSlider extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            payments: ((props.payments === null) || (props.payments === undefined)) ? (props.plans[0]) ? props.plans[0].payments : 1 : props.payments,
            marks: this.getMarks()
        };

        if (this.props.planSelected) {
            this.state.payments = (this.props.plans.filter(el => el.id === this.props.planSelected.id)[0]) ? this.props.plans.filter(el => el.id === this.props.planSelected.id)[0].payments : this.props.plans[0].payments;
        }

        let marksAsArray = Object.values(this.state.marks);

        this.state['maxPlan'] = marksAsArray[marksAsArray.length - 1];
        this.state['minPlan'] = marksAsArray[0] - 1;
    }

    getMarks = () => {
        let plans = this.props.plans.slice();

        if (this.props.plansInBins.length > 0) {
            plans = plans.filter(el => this.props.plansInBins.indexOf(el.id) > -1);
        }

        let marks = plans.map(e => {
            return e.payments;
        });

        marks.sort();

        const initialValue = {};
        let marksout = marks.reduce((obj, item) => {
            return {
                ...obj,
                [item]: item
            };
        }, initialValue);

        let valuesShown = Object.keys(marksout);
        for (const proper in marksout) {
            let pos = valuesShown.indexOf(proper);
            if ((this.props.entityTypeSelected === 4) && (pos !== 0 && pos !== valuesShown.length-1) && (this.props.isMobile === true)) {
                marksout[proper] = {
                    style: { display: 'none'},
                    label: ''
                };
            }
        }

        return marksout;
    };

    getAmount = (id, totalRequested) => {
        let plan = this.props.plans.filter(el => el.id === id)[0];
        let total = totalRequested;
        let advancePayment = 0;
        if (plan.minAdvancePayment !== null && plan.minAdvancePayment > 0) {
            advancePayment = ((this.props.advancePayment / 100) <= (plan.minAdvancePayment * this.props.total) ) ? plan.minAdvancePayment * this.props.total : (this.props.advancePayment / 100);
            if (advancePayment > 0 ) {
                total = total - advancePayment;
            }
        }
        let difTrans = total - ((plan.financingEntity.optional_delivery === false) ? ((this.props.deliveryCost / 100) + (this.props.deliveryCost / 100 * parseFloat(process.env.MIX_TAXES))): 0);
        let calculatedData = calculateFinancing(plan.payments, plan, difTrans);
        return {
            calculatedData: calculatedData,
            totalpay: calculatedData.rawTotal + advancePayment,
            totalrequest: difTrans,
            advancePayment: advancePayment
        };
    };

    setPayments = (val) => {
        this.setState({payments: val});
        this.props.onUpdatePayments(val);
    };

    render() {
        let plansFinal = [];
        if (this.props.plansInBins.length > 0 && this.props.entityTypeSelected === 4 && this.props.cardValid) {
            plansFinal = this.props.plans.filter(el => this.props.plansInBins.indexOf(el.id) > -1);
        }
        if (this.props.entityTypeSelected !== 4) {
            plansFinal = this.props.plans;
        }
        let possiblePlans = plansFinal.filter(el => el.payments === this.state.payments);
        if (possiblePlans.length === 0) {
            if (plansFinal.length > 0) {
                possiblePlans[0] = plansFinal[0];
            }
        }
        let defaultPlan = 1;
        if (this.props.planSelected) {
            defaultPlan = (this.props.allPlans.filter(el => el.id === this.props.planSelected.id)[0]) ? this.props.allPlans.filter(el => el.id === this.props.planSelected.id)[0].payments : this.props.allPlans[0].payments;
        }
        if (plansFinal.length > 0) {
            return (
                <label onClick={(e) => e.preventDefault()} className="w-100">
                    <div className="financing-slider">

                        <h3 className="text-center mgT2"><strong>Planes disponibles</strong></h3>
                        <p style={{fontSize:'12px', color:'#124b6f', fontWeight:'bold'}} className="text-center">(Puede seleccionar mas de un plan y comparar antes de decidir)</p>
                        {possiblePlans.length > 0 &&
                    <div className="mgT2">
                        {possiblePlans.map(el => {
                            let data = this.getAmount(el.id, this.props.total);
                            let dataPromo = calculatePromo(el, el.inPromo, this.props.cart.items, el.financingEntity.type.id === 4);
                            let newData = null;
                            if (dataPromo !== null && dataPromo!== undefined) {
                                let subTotal = _.sumBy(dataPromo.newItems, (item) => {
                                    return item.count * item.price;
                                }) / 100;
                                let taxes = (subTotal + this.props.cart.deliveryCost / 100) * parseFloat(process.env.MIX_TAXES);
                                let newtotal = subTotal + taxes + this.props.cart.deliveryCost / 100;
                                newData = this.getAmount(el.id, newtotal);
                            }
                            return (
                                <React.Fragment key={el.id}>
                                    <div className="position-absolute p-2"
                                        onClick={() => {
                                            this.props.selectPlanToCompare(Object.assign({}, el, {first: data.calculatedData.firstPayment, restPayments: data.calculatedData.restPayments, total: data.calculatedData.total, rawTotal: data.calculatedData.rawTotal}), !(this.props.plansToCompare.filter(el2 => el2.id === el.id).length > 0));
                                        }}
                                    >
                                        <Checkbox
                                            labelStyle={{zIndex: 1}}
                                            squared={false}
                                            checked={(this.props.plansToCompare.filter(el2 => el2.id === el.id).length > 0 || (this.props.planSelected && this.props.planSelected.id === el.id))}
                                            value={el.id} />
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.props.selectPlanToCompare(Object.assign({}, el, {first: data.calculatedData.firstPayment, restPayments: data.calculatedData.restPayments, total: data.calculatedData.total, rawTotal: data.calculatedData.rawTotal}), !(this.props.plansToCompare.filter(el2 => el2.id === el.id).length > 0));
                                        }}
                                        className={'bordered mgB text-center cursor-pointer ' + (this.props.plansToCompare.filter(el2 => el2.id === el.id).length > 0 || (this.props.planSelected && this.props.planSelected.id === el.id) ? 'selected' : '')}>
                                        <div className="no-gutters bordered row w-100 pd2">
                                            <div className="col-4 align-center">
                                                <h5><strong>{el.name}</strong></h5>
                                                {el.minAdvancePayment > 0 && <p>(requiere adelanto de un {(el.minAdvancePayment * 100) + '%'})</p>}
                                            </div>
                                            <div className="col-4">
                                                <ImagePreview
                                                    style = {
                                                        {
                                                            height: '100%',
                                                            width: 'inherit',
                                                            maxHeight: '32px',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'contain'
                                                        }
                                                    }
                                                    className="image-preview"
                                                    image={el.financingEntity.logoico}>
                                                </ImagePreview>
                                                {el.inPromo !== null &&
                                                <React.Fragment><p className='fsize-12 color-green'><strong>{el.inPromo.name}</strong>
                                                    <br/>
                                                    <span>{(newData !== null && newData.calculatedData.total !== data.calculatedData.total) ?
                                                        <strong>- {(el.inPromo.percent_discount > 0) ? el.inPromo.percent_discount : el.inPromo.fixed_discount}%</strong>
                                                        : <strong>No aplica</strong>}</span>
                                                </p>
                                                </React.Fragment> }
                                            </div>
                                            <div className="col-4">
                                                <p className="mgB0" style={{fontSize: '10px'}}>Tasa anual: {(el.charge * 100).toFixed(2)}%</p>
                                                {el.minAdvancePayment > 0 &&
                                                <p className="mgB0" style={{fontSize: '10px'}}>Adelanto: {el.currency.symbol + '$ ' + money(data.advancePayment) }
                                                </p>}
                                                <p className="mgB0" style={{fontSize: '10px'}}>{(this.props.isMobile === true) ? 'Total solicitud':'Total por solicitud'}: <span className={`color-blue ${(newData !== null && newData.calculatedData.total !== data.calculatedData.total) ? ' tached': ''}`}>{el.currency.symbol + '$ ' + money(data.totalrequest)}</span>
                                                    {(newData !== null && newData.calculatedData.total !== data.calculatedData.total) &&
                                                    <React.Fragment>
                                                        <br/> <span className="color-green">{el.currency.symbol + '$ ' + money(newData.totalrequest)}</span>
                                                    </React.Fragment>}</p>
                                                <p className="mgB0" style={{fontSize: '10px'}}>Total a pagar: <span className={`${(newData !== null && newData.calculatedData.total !== data.calculatedData.total) ? ' tached': ''}`}>{data.calculatedData.total}</span>
                                                    {(newData !== null && newData.calculatedData.total !== data.calculatedData.total) &&
                                                    <React.Fragment>
                                                        <br/> <span className="color-green">{el.currency.symbol + '$ ' + money(newData.calculatedData.total)}</span>
                                                    </React.Fragment>}</p>

                                            </div>
                                        </div>

                                        <div className="row pd2">
                                            {data.calculatedData.firstPayment !== data.calculatedData.restPayments &&
                                                <div className="col-md-6 mgB">
                                                    <div className="d-flex border-bottom justify-content-between">
                                                        <p className="text-gray mgB0">Primera cuota: </p>
                                                        <p className="mgB0"><strong>{(newData === null) ? data.calculatedData.firstPayment : newData.calculatedData.firstPayment}</strong></p>
                                                    </div>
                                                </div>
                                            }

                                            <div className={`${data.calculatedData.firstPayment !== data.calculatedData.restPayments ? 'col-md-6' : 'col-md-12'} mgB`}>
                                                <div className="d-flex border-bottom justify-content-between">
                                                    <p className="text-gray mgB0">Cuota mensual: </p>
                                                    <p className="mgB0"><strong>{(newData === null) ? data.calculatedData.restPayments : newData.calculatedData.restPayments}</strong></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </React.Fragment>
                            );
                        })}

                        {this.props.label &&
                        <div className={`label ${this.props.labelClassName} ${this.props.dangerLabel ? 'text-danger' : ''}`} style={this.props.labelStyle}>
                            {this.props.icon && (
                                <span className="fl__icon fl__icon--left mgR">
                                    <i className={this.props.icon} />
                                </span>
                            )}
                            {this.props.label}
                        </div>
                        }
                        <Slider
                            min={this.state.minPlan}
                            max={this.state.maxPlan}
                            defaultValue={defaultPlan}
                            marks={this.state.marks}
                            handle={handle}
                            value={this.state.payments}
                            onChange={this.setPayments}
                            step={null}
                        />

                        {possiblePlans.length === 0 &&
                        <div className="mgT2">
                            <p style={{fontSize:'12px', fontWeight:'bold'}} className="text-center color-red">El monto no es aceptable para solicitar un financiamiento con esta entidad.</p>
                        </div>
                        }
                    </div>}
                    </div>
                </label>
            );
        } else {
            return (<div>
                {possiblePlans.length === 0 &&
                        <div className="mgT2">
                            {this.props.entityTypeSelected !== 4 &&
                              <p style={{fontSize:'12px', fontWeight:'bold'}} className="text-center color-red">Lo sentimos pero esta entidad financiera no contiene planes apropiados para su solicitud.</p>}
                            {this.props.entityTypeSelected === 4 && this.props.cardValid &&
                              <p style={{fontSize:'12px', fontWeight:'bold'}} className="text-center color-red">Lo sentimos! su tarjeta o el monto no son aceptable para pagos en cuotas con esta entidad.</p>}
                        </div>
                }
            </div>);
        }
    }
}

FinancingSlider.defaultProps = {plansToCompare: []};

FinancingSlider.propTypes = {
    total: PropTypes.number.isRequired,
    plans: PropTypes.array.isRequired,
    planSelected: PropTypes.object,
    onPlanSelected: PropTypes.func
};
