'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import $ from 'jquery';
import _ from 'lodash';


import * as promotionSelector from 'common/store/selectors/promotion.selector';
import * as promotionActions from 'common/store/actions/promotions/promotions.actions';
import * as financingActions from 'common/store/actions/financing-entity/financing-entity.actions.js';
import * as financingSelectors from 'common/store/selectors/financing-entity.selector';

import {
    PLUS_CIRCLE_ICON,
    MINUS_CIRCLE_ICON
} from 'common/constants/icon.constant';

import { TextInput, SelectInput, Checkbox, SearchInput } from 'common/components/inputs';

import Modal from 'common/components/modal';
import { DEFAULT_FINANCING_ENTITIES_FILTERS } from 'common/constants/objects.constant';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            loading: promotionSelector.getIsLoading(state),
            promotionUpdated: promotionSelector.getPromotionUpdated(state),
            promotion: promotionSelector.getPromotion(state),
            cupons: promotionSelector.getCupons(state),
            cuponCreated: promotionSelector.getCuponCreated(state),
            cuponRemoved: promotionSelector.getCuponRemoved(state),
            financingEntities: financingSelectors.getFinancingEntitiesList(state),
            listSku: promotionSelector.getSkus(state)
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCupon: (cupon, idpromo) => {
            dispatch(promotionActions.addCuponsPromotion(cupon, idpromo));
        },
        removeCupon: (idcupon, idpromo) => {
            dispatch(promotionActions.removeCuponsPromotion(idcupon, idpromo));
        },
        resetVariables: () => {
            dispatch(promotionActions.resetVariables());
        },
        getEntities: (filter) => {
            dispatch(financingActions.getFinancingEntitiesList(filter));
        },
        getSkues: (filter = null) => {
            dispatch(promotionActions.searchSkues(filter));
        },
        addSku: (params) => {
            dispatch(promotionActions.addSku(params));
        },
        updateSku: (data, id) => {
            dispatch(promotionActions.updateSku(data, id));
        },
        delSkues: (skues) => {
            dispatch(promotionActions.delSkues(skues));
        },
        addRulePromotion: (datarule, id) => {
            dispatch(promotionActions.addRulesPromotion(datarule, id));
        },
        updateRulePromotion: (datarule, id) => {
            dispatch(promotionActions.updateRulesPromotion(datarule, id));
        },
        removeRulePromotion:  (id, idrule) => {
            dispatch(promotionActions.removeRulesPromotion(idrule, id));
        }
    };
};

class PromotionDetailBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showFormAdd: true,
            cupondata:{
                code: null,
                uses: null
            },
            sku:{
                id: null,
                code: '',
                name: ''
            },
            skuQuery: {q: ''},
            cuponSelected: null,
            ruleSelected: null,
            showDelCuponModal: false,
            showAddCuponModal: false,
            showNewRule: false,
            showSkuModal: false,
            editRule: false,
            editIdRule: null,
            showBinPlansModal: false,
            error:null,
            plansorbins: null,
            binsSelected: [],
            binsSelectedActual: [],
            plansSelected: [],
            plansSelectedActual: [],
            skusSelected: [],
            skusSelectedActual: [],
            entitySelected: null,
            entityId: null
        };
    }

    componentDidMount(){
        let filters = DEFAULT_FINANCING_ENTITIES_FILTERS;
        filters.enabled = 1;
        filters.perPage=null;
        this.props.getEntities(filters);
        this.props.getSkues();
    }

    componentDidUpdate() {
        if (this.props.cuponRemoved) {
            this.setState({showDelCuponModal: false}, this.props.resetVariables());
        }
        if (this.props.cuponCreated) {
            this.setState({showAddCuponModal: false}, this.props.resetVariables());
        }
    }

    onMoveEnd(modal) {
        if ($('#'+ modal)[0] !== undefined || $('#'+ modal)[0] !== null) {
            let top = $('#'+ modal)[0].scrollHeight;
            $('body, #' + modal).animate({scrollTop: top}, 1000);
        }
    }
    buildStringSKU(data) {
        let stringConform = data.map((sku) => {
            return sku.code;
        }).join(', ');
        return (<span> el SKU del producto esta incluido en <span className='color-blue fsbold'> {` { ${stringConform} }`} </span></span>);
    }

    buildStringBins(data) {
        let entityname = (data.length > 0) ? data[0].belongEntity : '';
        let stringConform = data.map((bin) => {
            return bin.bin_value;
        }).join(', ');
        return (<span> el bin esta incluido en <span className='color-blue fsbold'> {` { ${stringConform} }`} </span> que pertence a la entidad <span className='fsbold'>{entityname}</span></span>);
    }

    buildStringPlans(data) {
        let entityname = (data.length > 0) ? data[0].entity : '';
        let stringConform = data.map((plan) => {
            return plan.name;
        }).join(', ');
        return (<span> el plan esta incluido en los planes <span className='color-blue fsbold'> {` { ${stringConform} }`} </span> de la entidad <span className='fsbold'>{entityname}</span></span>);
    }

    formEditRulePromotion = () => {
        return(
            <React.Fragment>
                <div className='row bordered w-100' style={{marginLeft:'0px'}}>
                    <div className='col-12 col-md-12'><span className='fsbold'>Editando condicionantes de la regla </span></div>
                    <div className='col-12 col-md-12'><p> Si <span>SKU esta incluido en </span>
                        <a
                            className='btn btn-transparent btn-link'
                            onClick={() => {
                                this.setState({
                                    showSkuModal:true,
                                    skuQuery: {q: ''},
                                    sku:{id: null, code: null, name: null},
                                    skusSelected: this.state.skusSelectedActual !== null ? this.state.skusSelectedActual : []
                                }, this.renderSkuesModal);
                            }}>
                            {(this.state.skusSelectedActual !== null && this.state.skusSelectedActual.length > 0) ? '{ '+
                            this.props.listSku.filter((sku) => {
                                return this.state.skusSelectedActual.indexOf(sku.id) > -1;
                            }).map((sk) => {
                                return sk.code;
                            }).join(', ') + ' }' : 'SKU(s)'
                            }</a></p></div>
                    <div className='col-12 col-md-12'><p> Si <span>el BIN de la tarjeta esta incluido en </span>
                        <a
                            onClick={() => this.setState({showBinPlansModal:true, plansorbins:'bins', entityId:null}, this.renderBinsPlansModal)}
                            className='btn btn-transparent btn-link'>{(this.state.binsSelectedActual === null) ? 'Bines ' :
                                (this.state.binsSelectedActual !== null && this.state.binsSelectedActual.length === 0 ) ? 'Bines ' : '{ '+
                                                this.state.entitySelected.bins.filter((bin) => {
                                                    return this.state.binsSelectedActual.indexOf(bin.id) > -1;
                                                }).map((el) => {
                                                    return el.binvalue;
                                                }).join(', ') + ' }'}
                        </a>
                    </p></div>
                    <div className='col-12 col-md-12'><p> Si <span>el plan escogido esta incluido en </span>
                        <a
                            onClick={() => this.setState({showBinPlansModal:true, plansorbins:'plans', entityId:null}, this.renderBinsPlansModal)}
                            className='btn btn-transparent btn-link'>{(this.state.plansSelectedActual === null) ? 'Planes ' :
                                (this.state.plansSelectedActual !== null && this.state.plansSelectedActual.length === 0 ) ? 'Planes ' : '{' +
                                            this.state.entitySelected.plans.filter((plan) => {
                                                return this.state.plansSelectedActual.indexOf(plan.id) > -1;
                                            }).map((el) => {
                                                return el.name;
                                            }).join(', ') + '}'}</a>
                    </p></div>
                    <div className='col-12 col-md-12'></div>
                    <div className='col-12 col-md-12'>
                        <div className='row no-gutters w-100'>
                            <div className=' col-md-5'></div>
                            <button
                                className="btn btn-outline col-md-3"
                                onClick={() => this.setState({editRule: false, editIdRule: null, showNewRule: false})}
                            >Cancelar
                            </button>
                            <div className='col-md-1'></div>
                            <button
                                className="btn btn-primary w-100 col-md-3 "
                                onClick={this.onEditRulePromo}
                            >Guardar
                            </button>
                            <hr className='w-100'></hr>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderFragmentEditRule = (rule) => {
        let entitySelected = null;
        if (rule.bins[0] !== "-1") {
            entitySelected = this.props.financingEntities.filter((fe) => {
                return fe.bins.filter((bi) => { return bi.id === rule.bins[0].id; }).length > 0;
            })[0];
        }

        if (rule.plans[0] !== "-1") {
            entitySelected = this.props.financingEntities.filter((fe) => {
                return fe.plans.filter((bi) => { return bi.id === rule.plans[0].id; }).length > 0;
            })[0];
        }

        this.setState({
            editRule:true,
            editIdRule: rule.idrule,
            skusSelectedActual: (rule.skues[0] !== "-1") ? rule.skues.map((el) => { return el.id; }) : null,
            binsSelectedActual: (rule.bins[0] !== "-1") ? rule.bins.map((el) => { return el.id; }) : null,
            plansSelectedActual: (rule.plans[0] !== "-1") ? rule.plans.map((el) => { return el.id; }) : null,
            entitySelected: entitySelected
        });
    };


    renderRulesModule() {
        return(
            <div style={{maxHeight: '377px'}} className="user-detail-box row mgL0 mgR0  white-background">
                <div className='col-md-9 '>
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Reglas de la promoción (<span className='color-red'>{this.props.promotion.rules.length}</span>)</p></strong>
                </div>
                <div className='col-md-3 text-right'>
                    <button
                        onClick={() => this.setState({showNewRule:true, plansSelected: [], plansSelectedActual: [], binsSelected: [], binsSelectedActual:[], skusSelected: [], skusSelectedActual:[]}, this.onMoveEnd('rulemodule'))}
                        className="btn btn-transparent btn-link pdL0 pdR0">
                        <strong><i style={{fontSize: '14px', marginRight: '3px'}} className={`${PLUS_CIRCLE_ICON} color-blue`}/>
                    Crear</strong>
                    </button>
                </div>
                <hr className='w-100'></hr>
                <div style={{borderColor: '#d2d2d2'}} className='col-md-12'>
                    <div id='rulemodule' style={{borderLeft:'none', overflowY:'scroll', width:'100%', height:'280px', borderTop:'1px solid #d2d2d2'}}>
                        {this.props.promotion.rules.map((rule, index) => {
                            return (
                                <div className='row w-95 mgT' key={`rulePromo_${rule.idrule}`}>
                                    <div className='col-12 col-md-12 border-bottom'>
                                        <div className='row'>
                                            <span className='fsbold col-md-10' style={{paddingTop:'7px'}}>Regla {index + 1}</span>
                                            <a
                                                onClick={() => this.renderFragmentEditRule(rule)}
                                                className='btn btn-transparent btn-link w-20 col-md-1 col-1'>
                                                <img src='/images/edit-rule.svg' style={{width:'20px'}}/>
                                            </a>
                                            <a onClick={() => this.props.removeRulePromotion(this.props.promotion.id, rule.idrule )}
                                                className='btn btn-transparent btn-link w-20 col-md-1 col-1'>
                                                <img src='/images/delete-rule.svg' style={{width:'20px'}}/>
                                            </a></div></div>
                                    {this.state.editRule === false &&
                                    <div className='col-12 col-md-12'><p> Si
                                        {rule.skues[0] !== "-1" && this.buildStringSKU(rule.skues) }
                                        {rule.skues[0] !== "-1" && <span> , </span> }
                                        {rule.bins[0] !== "-1" && this.buildStringBins(rule.bins) }
                                        {rule.bins[0] !== "-1" && <span> , </span> }
                                        {rule.plans[0] !== "-1" && this.buildStringPlans(rule.plans)}
                                    </p>
                                    </div>}
                                    {this.state.editRule === true && this.state.editIdRule === rule.idrule && this.formEditRulePromotion(rule)}
                                </div>);
                        })}
                        {this.props.promotion.rules.length === 0 &&
                            <div className='row w-95 mgT2 ' >
                                <div className='text-center fsize-15r fsbold color-blue col-md-12'>Sin reglas configuradas</div>
                            </div>
                        }
                        {this.state.showNewRule && this.state.editIdRule !== null &&
                            <div className='row w-95 mgT2 ' >
                                <div className='text-center fsize-12 fsbold color-red col-md-12'>Esta editando reglas.</div>
                            </div>
                        }
                        {this.state.showNewRule && this.state.editIdRule === null &&
                        <div className='row bordered w-100'>
                            <div className='col-12 col-md-12'><span className='fsbold'>Condicionantes</span></div>
                            <div className='col-12 col-md-12'><p> Si <span>SKU esta incluido en </span>
                                <a
                                    className='btn btn-transparent btn-link'
                                    onClick={() => this.setState({
                                        showSkuModal:true,
                                        skuQuery: {q: ''},
                                        sku:{id: null, code: null, name: null}
                                    },
                                    this.props.getSkues())}>{(this.state.skusSelectedActual.length === 0 ) ? 'SKU(s) ' : (this.props.listSku !== null) ? '{ '+
                                    this.props.listSku.filter((sku) => {
                                        return this.state.skusSelectedActual.indexOf(sku.id) > -1;
                                    }).map((sk) => {
                                        return sk.code;
                                    }).join(', ') + ' }' : 'SKU(s)'}</a></p></div>
                            <div className='col-12 col-md-12'><p> Si <span>el BIN de la tarjeta esta incluido en </span>
                                <a
                                    onClick={() => this.setState({showBinPlansModal:true, plansorbins:'bins', entityId:null}, this.renderBinsPlansModal)}
                                    className='btn btn-transparent btn-link'>{(this.state.binsSelectedActual.length === 0 ) ? 'Bines ' : '{ '+
                                        this.state.entitySelected.bins.filter((bin) => {
                                            return this.state.binsSelectedActual.indexOf(bin.id) > -1;
                                        }).map((el) => {
                                            return el.binvalue;
                                        }).join(', ') + ' }'}
                                </a>
                            </p></div>
                            <div className='col-12 col-md-12'><p> Si <span>el plan escogido esta incluido en </span>
                                <a
                                    onClick={() => this.setState({showBinPlansModal:true, plansorbins:'plans', entityId:null}, this.renderBinsPlansModal)}
                                    className='btn btn-transparent btn-link'>{(this.state.plansSelectedActual.length === 0 ) ? 'Planes ' : '{' +
                                    this.state.entitySelected.plans.filter((plan) => {
                                        return this.state.plansSelectedActual.indexOf(plan.id) > -1;
                                    }).map((el) => {
                                        return el.name;
                                    }).join(', ') + '}'}</a>
                            </p></div>
                            <div className='col-12 col-md-12'></div>
                            <div className='col-12 col-md-12'>
                                <div className='row no-gutters w-100'>
                                    <div className=' col-md-5'></div>
                                    <button
                                        className="btn btn-outline col-md-3"
                                        onClick={() => this.setState({showNewRule: false})}
                                    >Cancelar
                                    </button>
                                    <div className='col-md-1'></div>
                                    <button
                                        className="btn btn-primary w-100 col-md-3 "
                                        onClick={this.onAddRulePromo}
                                    >Guardar
                                    </button>
                                </div>
                                <hr className='w-100'></hr>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    onAddRulePromo = () => {
        let rule = {
            bines: this.state.binsSelectedActual.toString() || '-1',
            skues: this.state.skusSelected.toString() || '-1',
            planes: this.state.plansSelectedActual.toString() || '-1'
        };

        this.props.addRulePromotion(rule, this.props.promotion.id);
    };

    onEditRulePromo = () => {
        let rule = {
            id: this.state.editIdRule,
            bines: (this.state.binsSelectedActual !== null) ? ((this.state.binsSelectedActual.length > 0) ? this.state.binsSelectedActual.toString() : '-1') : '-1',
            skues: (this.state.skusSelectedActual !== null) ? ((this.state.skusSelectedActual.length > 0) ? this.state.skusSelectedActual.toString() : '-1') : '-1',
            planes: (this.state.plansSelectedActual !== null) ? ((this.state.plansSelectedActual.length > 0) ?this.state.plansSelectedActual.toString() : '-1') : '-1'
        };
        this.props.updateRulePromotion(rule, this.props.promotion.id);
    };

    onFiltersChange(val) {
        let queryDat = { q: val};
        this.setState({skuQuery:queryDat}, this.props.getSkues(queryDat));
    }
    renderFilters() {
        return (
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-md-12 mgB">
                        <SearchInput
                            floatingLabel={'Buscar'}
                            weight={500}
                            value={this.state.skuQuery && this.state.skuQuery.q }
                            label="Buscar por nombre, código"
                            onChange={(val) => this.onFiltersChange(val)}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onSkuChange(data, value) {
        let datasku = {...this.state.sku};
        datasku[data] = value;
        this.setState({sku:datasku});
    }

    renderSkuForm() {
        return (
            <React.Fragment>
                <div className="row mgB white-background w-95" style={{marginLeft:'5px'}}>
                    <p className="col-md-12 mgB" style={{fontWeight:'600', borderBottom:'1px solid #d2d2d2'}}>Store Keep Unit</p>
                    <div className="col-md-4 mgB">
                        <TextInput
                            label='Código'
                            placeholder='Referencia'
                            numbers={true}
                            value={this.state.sku.code}
                            onChange={(val) => this.onSkuChange('code', val)}
                        />
                    </div>
                    <div className="col-md-8 mgB">
                        <TextInput
                            label='Descripción'
                            placeholder='Nombre que describe el Store Keep Unit'
                            numbers={true}
                            value={this.state.sku.name}
                            onChange={(val) => this.onSkuChange('name', val)}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <div className='row no-gutters text-center' style={{marginTop:'10px', paddingBottom:'10px'}}>
                            <button
                                className="btn btn-outline col-md-4 col-12"
                                onClick={() => this.setState({sku:{ code: '', name: ''}})}
                            >Cancelar
                            </button>
                            <div className='col-md-4 col-12'></div>
                            <button
                                disabled={this.state.sku.code === null || this.state.sku.code === '' || this.state.sku.name === null || this.state.sku.name === '' }
                                className="btn btn-primary col-md-4 col-12"
                                onClick={() => {
                                    if (this.state.sku.id !== null) {
                                        this.props.updateSku(this.state.sku, this.state.sku.id);
                                        this.setState({sku:{id: null, code: '', name: ''}});
                                    } else {
                                        this.props.addSku(this.state.sku);
                                        this.setState({sku:{id: null, code: '', name: ''}});
                                    }
                                }}
                            >{this.state.sku.id === null ? 'Crear' : 'Guardar'}
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderSkuesModal(){
        let lengthSkus = 0;
        if (this.props.listSku) {
            lengthSkus = _.intersection(this.props.listSku.map(sku => sku.id), this.state.skusSelected).length;
        }
        return (
            <Modal
                style={{width:'700px', height:'600px'}}
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showSkuModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showSkuModal}>
                <div className='row' style={{padding:'10px 0px 10px 20px'}}>
                    <h2 className="text-center col-md-10 col-10">Gestión de los SKU(s)</h2>
                    <button
                        className="btn btn-transparent btn-link col-md-2 col-2"
                        onClick={() => this.setState({skusSelected: [], showSkuModal: false})}
                    >Cerrar
                    </button>
                </div>
                <hr className='w-95'></hr>
                {this.renderFilters()}
                <hr className='w-95'></hr>
                {this.renderSkuForm()}
                <hr className='w-95'></hr>
                {this.props.listSku && this.props.listSku.length > 0 && this.state.skusSelected.length > 0 &&
                <div className='row' style={{padding:'10px 0px 10px 20px'}}>
                    <button
                        className="btn btn-outline col-md-3 col-12"
                        onClick={() => this.props.delSkues(this.state.skusSelected)}
                    >Eliminar
                    </button>
                </div>}
                {this.props.listSku && this.props.listSku.length > 0 &&
                <div className="row pdL2 " style={{borderColor: '#d2d2d2'}} role="region" aria-labelledby>
                    <div style={{overflowX:'scroll', borderLeft:'none', maxHeight:'450px', minHeight:'300px', overflowY:'auto'}}>
                        <table className="white-background " width="650px" style={{borderLeft:'1px solid #d2d2d2'}}>
                            <thead style={{boxShadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)', borderBottom:'1px solid #d2d2d2', borderTop:'1px solid #d2d2d2'}}>
                                <tr style={{textAlign:'center', fontSize:'14px', height:'30px'}}>
                                    <th className="border-md-right">
                                        {
                                            <div style={{margin:'10px 10px 10px 20px'}} onClick={(e) => e.stopPropagation()}>
                                                <Checkbox
                                                    squared={true}
                                                    label=""
                                                    checked={lengthSkus === this.props.listSku.length}
                                                    value={'all'}
                                                    indeterminate={lengthSkus < this.props.listSku.length && lengthSkus > 0}
                                                    textStyle={{marginLeft:'40px', fontSize:'16px', fontWeight:'bold', overflowWrap:'break-word'}}
                                                    onChange={(val) => this.skusSelect(val, this.props.listSku.map(sku => sku.id))}
                                                />
                                            </div>
                                        }
                                    </th>
                                    <th width="100px" style={{textAlign:'center'}}>Código</th>
                                    <th width="550px" style={{borderRight:'1px solid #d2d2d2'}}>
                                        <span>Descripción</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.listSku && this.props.listSku.length > 0 && this.props.listSku.map(sku => {
                                    return (
                                        <tr style={{fontSize:'10px', borderBottom:' 1px solid #d2d2d2', height:'50px'}}
                                            className={'cursor-pointer fsbold color-blue'}
                                            key={'entity-plan-' + sku.id}
                                        >
                                            <td className="border-md-right">
                                                <div style={{marginTop: '7px', marginLeft:'20px'}} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        squared={true}
                                                        checked={(this.state.skusSelected.indexOf(sku.id) > -1)}
                                                        value={sku.id}
                                                        textStyle={{marginLeft:'40px', fontSize:'16px', overflowWrap:'break-word'}}
                                                        onChange={(val, e) => this.skusSelect(val, [parseInt(e.currentTarget.value)])}
                                                    />
                                                </div>
                                            </td>
                                            <th width="100px">
                                                <div style={{textAlign:'center', paddingTop:'5px', fontWeight:'bolder', fontSize:'14px'}}
                                                    onClick={() => this.setState({sku: {id:sku.id, code: sku.code, name: sku.name}})}>{sku.code}</div>
                                            </th>
                                            <td width="400px" style={{borderRight:'1px solid #d2d2d2'}}>
                                                <div style={{paddingLeft:'3px', paddingRight:'3px', paddingTop:'5px', wordWrap:'break-word', fontSize:'14px'}}
                                                    onClick={() => this.setState({sku: {id:sku.id, code: sku.code, name: sku.name}})}>
                                                    <strong >{sku.name}</strong></div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>}
                {!this.props.listSku || (this.props.listSku && this.props.listSku.length === 0) &&
                <div className='row no-gutters  text-center' style={{maxHeight:'400px', minHeight:'300px'}}>
                    <span className='mgT2 col-md-12 col-12 fsbold'>Sin elementos que mostrar</span>
                </div>
                }
                <div className='row no-gutters text-center' style={{marginTop:'20px'}}>
                    <div className='col-md-4'>
                        <button
                            className="btn btn-outline w-70"
                            onClick={() => this.setState({skusSelected: [], showSkuModal: false})}
                        >Descartar
                        </button>
                    </div>
                    <div className='col-md-4'>
                    </div>
                    <div className='col-md-4'>
                        <button
                            className="btn btn-primary w-70"
                            onClick={() => this.setState({skusSelectedActual: this.state.skusSelected, showSkuModal: false})}
                        >Guardar
                        </button>
                    </div>
                </div>

            </Modal>
        );
    }

    entityChanged(id) {
        let financingEnt = this.props.financingEntities.filter((fe) => { return fe.id === id; });
        if (this.state.plansorbins === 'plans') {
            this.setState({entitySelected: financingEnt[0], entityId:id, plansSelected:[]} );
        } else {
            this.setState({entitySelected: financingEnt[0], entityId:id, binsSelected: []} );
        }
    }
    skusSelect(val, ids) {
        let selectedSkus = this.state.skusSelected.slice();
        if (val) {
            selectedSkus = _.union(selectedSkus, ids);
        } else {
            selectedSkus = _.difference(selectedSkus, ids);
        }
        this.setState({skusSelected: selectedSkus, sku:{ code: '', name: ''}});
    }
    plansSelect(val, ids) {
        let selectedPlans = this.state.plansSelected.slice();
        if (val) {
            selectedPlans = _.union(selectedPlans, ids);
        } else {
            selectedPlans = _.difference(selectedPlans, ids);
        }
        this.setState({plansSelected: selectedPlans, binsSelect: []});
    }

    binsSelect(val, ids) {
        let selectedBins = this.state.binsSelected.slice();
        if (val) {
            selectedBins = _.union(selectedBins, ids);
        } else {
            selectedBins = _.difference(selectedBins, ids);
        }
        this.setState({binsSelected: selectedBins, plansSelected: []});
    }

    renderBinsPlansModal(){
        let lengthPlnas = 0;
        if (this.state.entitySelected) {
            lengthPlnas = _.intersection(this.state.entitySelected.plans.map(plan => plan.id), this.state.plansSelected).length;
        }
        let lengthBins = 0;
        if (this.state.entitySelected) {
            lengthBins = _.intersection(this.state.entitySelected.bins.map(bin => bin.id), this.state.binsSelected).length;
        }

        let dataentities = null;
        if (this.state.plansorbins !== 'plans') {
            dataentities = this.props.financingEntities.filter((fe) => { return fe.type.id === 4; });
        } else { dataentities = this.props.financingEntities.filter((fe) => { return fe.type.id !== 4; }); }

        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showBinPlansModal: false})}
                id='binsplans-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showBinPlansModal}>
                <h2 className="text-center">Selección de {this.state.plansorbins === 'plans' ? 'planes' : 'bines'}  </h2>
                <hr className='w-95'></hr>
                <div className='row w-95 pdL2 mgL2'>
                    <SelectInput
                        value={this.state.entityId}
                        floatingLabel="Seleccione la entidad financiera"
                        options={dataentities.map((entity) => {
                            return (
                                {
                                    label: entity.name,
                                    value: entity.id
                                }
                            );
                        })}
                        onMenuOpen={() => this.onMoveEnd('binsplans-entity-modal')}
                        onChange={(val) => this.entityChanged(val)}
                    />
                </div>
                {this.state.entityId !== null &&
                <React.Fragment>
                    <hr className='w-95'></hr>
                    <h4 className="text-left mgL2">Listado de {this.state.plansorbins === 'plans' ? 'planes' : 'bines'}  </h4>
                </React.Fragment>}
                <div className='row w-95 pdL2' style={{minHeight:'150px'}}>
                    {this.state.entityId !== null && this.state.plansorbins === 'plans' && this.state.entitySelected !== null && this.state.entitySelected.plans.length === 0 &&
                        <span> Esta entidad no contiene planes configurados. </span>
                    }
                    {this.state.entityId !== null && this.state.plansorbins === 'plans' && this.state.entitySelected !== null && this.state.entitySelected.plans.length > 0 &&
                        <div className="row pdL2" style={{borderColor: '#d2d2d2'}} >
                            <div style={{overflowX:'scroll', borderLeft:'none', maxHeight:'300px', overflowY:'auto'}}>
                                <table className="white-background" width="400px" style={{borderLeft:'1px solid #d2d2d2'}}>
                                    <thead style={{boxShadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)', borderBottom:'1px solid #d2d2d2', borderTop:'1px solid #d2d2d2'}}>
                                        <tr style={{textAlign:'center', fontSize:'14px', height:'30px'}}>
                                            <th className="border-md-right">
                                                {
                                                    <div style={{margin:'10px 10px 10px 20px'}} onClick={(e) => e.stopPropagation()}>
                                                        <Checkbox
                                                            squared={true}
                                                            label=""
                                                            checked={lengthPlnas === this.state.entitySelected.plans.length}
                                                            value={'all'}
                                                            indeterminate={lengthPlnas < this.state.entitySelected.plans.length && lengthPlnas > 0}
                                                            textStyle={{marginLeft:'40px', fontSize:'16px', fontWeight:'bold', overflowWrap:'break-word'}}
                                                            onChange={(val) => this.plansSelect(val, this.state.entitySelected.plans.map(plan => plan.id))}
                                                        />
                                                    </div>
                                                }
                                            </th>
                                            <th width="100px" style={{textAlign:'center'}}>Cuotas</th>
                                            <th width="200px" >
                                                <span>(Plan)</span>
                                            </th>
                                            <th width="60px" >
                                                <span >Tasa</span>
                                                <br></br>
                                                <span >Anual</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.entitySelected.plans.map(el => {
                                            return (
                                                <tr style={{fontSize:'10px', borderBottom:' 1px solid #d2d2d2', height:'50px'}}
                                                    className={'cursor-pointer fsbold color-blue'}
                                                    key={'entity-plan-' + el.id}
                                                >
                                                    <td className="border-md-right">
                                                        <div style={{marginTop: '7px', marginLeft:'20px'}} onClick={(e) => e.stopPropagation()}>
                                                            <Checkbox
                                                                squared={true}
                                                                checked={(this.state.plansSelected.indexOf(el.id) > -1)}
                                                                value={el.id}
                                                                textStyle={{marginLeft:'40px', fontSize:'16px', overflowWrap:'break-word'}}
                                                                onChange={(val, e) => this.plansSelect(val, [parseInt(e.currentTarget.value)])}
                                                            />
                                                        </div>
                                                    </td>
                                                    <th width="100px">
                                                        <div style={{textAlign:'center', paddingTop:'5px', fontWeight:'bolder', fontSize:'14px'}}>{el.payments}</div>
                                                    </th>
                                                    <td width="100px">
                                                        <div style={{paddingLeft:'3px', paddingRight:'3px', wordWrap:'break-word'}}>
                                                            <strong>({el.name})</strong></div>
                                                    </td>
                                                    <td style={{textAlign:'center'}}> {(el.charge*100).toFixed(2)}% </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    {this.state.entityId !== null && this.state.plansorbins !== 'plans' && this.state.entitySelected !== null && this.state.entitySelected.bins.length === 0 &&
                        <span> Esta entidad no contiene bines configurados. </span>
                    }
                    {this.state.entityId !== null && this.state.plansorbins !== 'plans' && this.state.entitySelected !== null && this.state.entitySelected.bins.length > 0 &&
                        <div className="row pdL2" style={{borderColor: '#d2d2d2'}} >
                            <div className="col-md-12 col-12">
                                <div onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        squared={true}
                                        label="Seleccionar todos"
                                        checked={lengthBins === this.state.entitySelected.bins.length}
                                        value={'all'}
                                        indeterminate={lengthBins < this.state.entitySelected.bins.length && lengthBins > 0}
                                        textStyle={{fontSize:'16px', fontWeight:'bold'}}
                                        onChange={(val) => this.binsSelect(val, this.state.entitySelected.bins.map(bin => bin.id))}
                                    />
                                </div>
                            </div>
                            <div style={{overflowX:'scroll', border:'1px solid #d2d2d2', maxHeight:'200px', overflowY:'auto'}} className="col-md-12 col-12 mgTm2">
                                <div className="row ">
                                    {this.state.entitySelected.bins.map(el => {
                                        return (
                                            <div className="col-md-3 col-6 cursor-pointer fsbold color-blue" key={'entity-plan-' + el.id} >
                                                <div style={{marginTop: '7px'}} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        squared={true}
                                                        checked={(this.state.binsSelected.indexOf(el.id) > -1)}
                                                        value={el.id}
                                                        label={`(${el.binvalue})`}
                                                        textStyle={{fontSize:'14px', overflowWrap:'break-word', textAlign:'center', fontWeight:'bold', color:'#1FB0EE'}}
                                                        onChange={(val, e) => this.binsSelect(val, [parseInt(e.currentTarget.value)])}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='row no-gutters text-center' style={{marginTop:'20px'}}>
                    { this.state.plansorbins !== 'plans' &&
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({binsSelected: this.state.binsSelectedActual, showBinPlansModal: false})}
                    >Descartar
                    </button>}
                    {this.state.plansorbins === 'plans' &&
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({plansSelected:this.state.plansSelectedActual, showBinPlansModal: false})}
                    >Descartar
                    </button>}
                    <div className='col-md-4'></div>
                    <button
                        className="btn btn-primary col-md-4 col-12"
                        onClick={this.onSaveBinsPlans}
                    >Guardar
                    </button>
                </div>

            </Modal>
        );
    }

    onSaveBinsPlans = () => {
        if (this.state.plansorbins === 'plans') {
            this.setState({plansSelectedActual: this.state.plansSelected, binsSelectedActual: [], showBinPlansModal: false});
        }else {
            this.setState({plansSelectedActual: [], binsSelectedActual: this.state.binsSelected, showBinPlansModal: false});
        }
    };

    renderRemoveCuponModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showDelCuponModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showDelCuponModal}>
                <h2 className="text-center">Eliminar cupones</h2>

                <div className="important-information mgB">
                    <p><strong>Información importante</strong></p>
                    <p className="text-left">Cuidado vamos a elimnar el cupón <span className='color-red'>{this.state.cuponSelected ? this.state.cuponSelected.code : ''}</span>. Al ser eliminado no podrá ser aplicado luego.</p>
                </div>
                <div className='row no-gutters'>
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({showDelCuponModal: false})}
                    >Cancelar
                    </button>
                    <div className='col-md-4 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <button
                            className="btn btn-primary w-100"
                            onClick={() => this.props.removeCupon(this.state.cuponSelected.id, this.props.promotion.id)}
                        >Eliminar
                        </button>
                    </div>
                </div>

            </Modal>
        );
    }

    renderAddCuponModal() {
        return (
            <Modal
                overlayClassName="o-inherit"
                onModalClose={() => this.setState({showAddCuponModal: false})}
                id='create-entity-modal'
                closable={false}
                noDivider={true}
                showModal={this.state.showAddCuponModal}>
                <h2 className="text-center">Nuevo cupon</h2>

                <div className="important-information mgB">
                    <p><strong>Información importante</strong></p>
                    <p className="text-left">Vamos a crear u nuevo cupón a usar por clientes.</p>
                </div>

                <div className='row no-gutters'>
                    <div className='col-md-7 col-12'>
                        <TextInput
                            label='Código del cupon'
                            placeholder='Código a mostrar'
                            value={this.state.cupondata.code}
                            onChange={(val) => this.onCuponDataChange('code', val)}
                        />
                    </div>
                    <div className='col-md-1 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <TextInput
                            label='Disponibilidad'
                            placeholder='Usos'
                            onlyNumbers={true}
                            numbers={true}
                            value={this.state.cupondata.uses}
                            onChange={(val) => this.onCuponDataChange('uses', val)}
                        />
                    </div>
                </div>

                {this.state.error &&
                    <div className='row no-gutters'>
                        <p className='color-red fsize-12'>{this.state.error}</p>
                    </div>}

                <div className='row no-gutters'>
                    <button
                        className="btn btn-outline col-md-4 col-12"
                        onClick={() => this.setState({showAddCuponModal: false})}
                    >Cancelar
                    </button>
                    <div className='col-md-4 col-12'></div>
                    <div className='col-md-4 col-12'>
                        <button
                            disabled = {(this.state.cupondata.code === null || this.state.cupondata.code === '') || (this.state.cupondata.uses === null || this.state.cupondata.uses > this.props.promotion.uses_by_client)}
                            className="btn btn-primary w-100"
                            onClick={() => this.props.addCupon(this.state.cupondata, this.props.promotion.id)}
                        >Crear
                        </button>
                    </div>
                </div>

            </Modal>
        );
    }

    onCuponDataChange(data, value) {
        let datacupon = {...this.state.cupondata};
        datacupon[data] = value;
        let errorValidation = (datacupon.uses && (datacupon.uses <= 0 || datacupon.uses > this.props.promotion.uses_by_client)) ? 'El valor de disponibilidad debe ser menor o igual al valor de cantidad de usos por cliente de la promoción.' : null;
        this.setState({cupondata:datacupon, error: errorValidation});
    }

    renderCuponModule(){
        return (
            <div style={{maxHeight: '377px'}} className="user-detail-box row mgL0 mgR0  white-background">
                <div className='col-md-9 '>
                    <strong><p style={{fontSize: '18px'}} className="mgB0">Cupones de la promoción (<span className='color-red'>{this.props.cupons.length}</span>)</p></strong>
                </div>
                <div className='col-md-3 text-right'>
                    <button
                        onClick={() => {
                            let defaultdata = Object.assign({}, {code:'', uses:this.props.promotion.uses_by_client});
                            this.setState({showAddCuponModal: true, cupondata:defaultdata});
                        }}
                        className="btn btn-transparent btn-link pdL0 pdR0">
                        <strong><i style={{fontSize: '14px', marginRight: '3px'}} className={`${PLUS_CIRCLE_ICON} color-blue`}/>
                    Crear</strong>

                    </button>
                </div>
                <hr className='w-100'></hr>
                <div style={{borderColor: '#d2d2d2'}} role="region" aria-labelledby className='col-md-12'>
                    <div style={{borderLeft:'none', overflowY:'scroll', width:'100%', height:'280px', borderTop:'1px solid #d2d2d2'}} className="tableDataplan">
                        <table className="white-background Planstable">
                            <thead >
                                <tr style={{textAlign:'center', height:'25px', boxShadow:'0 5px 5px 1px rgba(0, 0, 0, 0.10)' }}>
                                    <th className="border-md-right" style={{width:'75%'}}>
                                        <div className="  dt__header-label" style={{ margin:'10px 10px 10px 10px'}}>Código </div>
                                    </th>
                                    <th className="border-md-right" style={{width:'15%'}}>
                                        <div className="dt__header-label" style={{ margin:'10px 10px 10px 10px'}}>Disponible</div>
                                    </th >
                                    <th className="border-md-right" style={{width:'10%'}}>
                                        <div className="dt__header-label" style={{ margin:'10px 10px 10px 10px'}}></div>
                                    </th >
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.cupons && this.props.cupons.length === 0 &&
                                  <React.Fragment>
                                      <tr style={{height:'50px'}}>
                                          <th colSpan={3} className="text-center">
                                              <strong>No existen cupones para esta promoción</strong>
                                          </th>
                                      </tr>
                                  </React.Fragment>
                                }
                                {this.props.cupons && this.props.cupons.length > 0 &&
                                  <React.Fragment>
                                      {this.props.cupons.map((cupon, index) => {
                                          return (
                                              // eslint-disable-next-line react/jsx-key
                                              <tr style={{height:'40px'}} id={cupon.id} className={`${(cupon.uses_availables === 0) ? 'color-red' : ''}`} key={index}>
                                                  <th >
                                                      {cupon.code}
                                                  </th>
                                                  <th className='text-center' >
                                                      {cupon.uses_availables}
                                                  </th>
                                                  <th >
                                                      <div className='text-center cursor-pointer'
                                                          onClick={() => this.setState({showDelCuponModal:true, cuponSelected:cupon})}>
                                                          <span className='color-blue' style={{fontSize:'22px'}}><i className={MINUS_CIRCLE_ICON}/></span>
                                                      </div>
                                                  </th>
                                              </tr>
                                          );
                                      })}
                                  </React.Fragment>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return(
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-md-6 mgB">
                        {this.renderCuponModule()}
                    </div>
                    <div className="col-md-6 mgB">
                        {this.renderRulesModule()}
                    </div>
                </div>
                {this.renderRemoveCuponModal()}
                {this.renderAddCuponModal()}
                {this.renderBinsPlansModal()}
                {this.renderSkuesModal()}
            </React.Fragment>
        );
    }
}

PromotionDetailBody.propTypes = {promotion: PropTypes.object.isRequired};


export default withAlert()(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(PromotionDetailBody));