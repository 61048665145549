/* eslint-disable indent */
/* eslint-disable react/jsx-key */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import LineChart from 'common/components/line-chart';
import DoughnutChart from 'common/components/doughnut-chart';
import money from 'common/helpers/money';
import moment from 'moment';
import _ from 'lodash';
import { FINANCING_STATUSES } from 'common/constants/enums.constant';
import {PLUS_CIRCLE_ICON} from 'common/constants/icon.constant';
import {Checkbox} from 'common/components/inputs';


class DashboardBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            lineLabels: [],
            lineData: [],
            lineDataAmount: [],
            approvedByEntityLabels: [],
            approvedAmountByEntityLabels: [],
            declinedByEntityLabels: [],
            declinedByReasonLabels: [],
            approvedByEntityData: [],
            approvedAmountByEntityData: [],
            declinedByEntityData: [],
            declinedByReasonData: [],
            max: 0,
            deploy: true,
            showEntityDetails: [],
            changeByMoney: false,
            changeToTotal: true,
            graphByMoney: false,
            showAmounts: false,
            updateGraph: false
        };

        this.m = moment().locale('es');

        this.dataEntities = this.generateData();
    }

    filterPieChartData = (data) => {
        let newData = {};

        for (const prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                if (data[prop] > 0) {
                    newData[prop] = data[prop];
                }
            }
        }

        return newData;
    };

    componentDidMount() {
        this.composeDataGraph(this.state.graphByMoney);
    }

    composeDataGraph(byAmount = false) {
        let lineLabels = Object.keys(this.props.dashboard.lineChartData);
        lineLabels.unshift('');
        lineLabels.push('');

        let lineData = [];
        let lineDataAmount = [];
        let max=0;

        if (!byAmount) {

        lineData.push({data: [], label: ''});

        lineData[0].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.approved > max) {
                max = el.approved;
            }

            return el.approved;
        });

        lineData[0].label = 'Vendidas';
        lineData[0].data.unshift(null);

        lineData.push({data: [], label: ''});

        max=0;
        lineData[1].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.declined > max) {
                max = el.declined;
            }
            return el.declined;
        });

        lineData[1].label = 'No procede';
        lineData[1].data.unshift(null);

        lineData.push({data: [], label: ''});

        lineData[2].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.created > max) {
                max = el.created;
            }
            return el.created;
        });

        lineData[2].label = 'Solicitadas';
        lineData[2].data.unshift(null);

    } else {

        lineDataAmount.push({data: [], label: ''});

        lineDataAmount[0].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.amount_approved > max) {
                max = el.amount_approved;
            }

            return el.amount_approved;
        });

        lineDataAmount[0].label = 'Vendidas';
        lineDataAmount[0].data.unshift(null);

        lineDataAmount.push({data: [], label: ''});

        max=0;
        lineDataAmount[1].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.amount_declined > max) {
                max = el.amount_declined;
            }
            return el.amount_declined;
        });

        lineDataAmount[1].label = 'No procede';
        lineDataAmount[1].data.unshift(null);

        lineDataAmount.push({data: [], label: ''});

        lineDataAmount[2].data = Object.values(this.props.dashboard.lineChartData).map(el => {
            if (el.amount_created > max) {
                max = el.amount_created;
            }
            return el.amount_created;
        });

        lineDataAmount[2].label = 'Solicitadas';
        lineDataAmount[2].data.unshift(null);
    }

        this.setState({lineLabels: lineLabels, lineData: lineData, lineDataAmount: lineDataAmount});

    }

    renderLineChart() {
        return (

            <React.Fragment>
                <div className="row mgB2 fsbold " style={{boxShadow:'0 2px 15px 0 rgb(0 0 0 / 15%)', marginLeft:'0px', marginRight:'0px'}}>
                   <div className='col-md-7'> <p className="pdL2 pdT15">Comportamiento anual </p> </div>
                   <div className='col-md-3 text-center'>
                   <Checkbox
                                squared={true}
                                labelStyle={{fontSize:'12px', float:'left', marginLeft:'40%', width:'30%'}}
                                className="pdT15"
                                label="Cantidades"
                                checked={!this.state.graphByMoney}
                                onChange={() => this.setState({graphByMoney:false, updateGraph: true}, this.composeDataGraph(false))}/>
                    </div>
                    <div className='col-md-2  text-center'>
                        <Checkbox
                                labelStyle={{fontSize:'12px', float:'left', width:'30%'}}
                                label="RD$"
                                className="pdT15"
                                squared={true}
                                checked={this.state.graphByMoney}
                                onChange={() => this.setState({graphByMoney:true, updateGraph: true}, this.composeDataGraph(true))}/>
                   </div>
                </div>
                <div style={{maxHeight: 'inherit', padding: '20px'}} className="user-logs-box row mgL0 mgR0 mgB0">
                {this.state.lineData.length > 0 && this.state.lineLabels.length > 0 &&
                    <LineChart
                    labels={this.state.lineLabels}
                    suggestedMax={this.state.max + 1}
                    data={this.state.lineData}
                />}
                {this.state.lineDataAmount.length > 0 && this.state.lineLabels.length > 0 &&
                    <LineChart
                    labels={this.state.lineLabels}
                    suggestedMax={this.state.max + 1}
                    data={this.state.lineDataAmount}
                />}
            </div>
            </React.Fragment>
        );
    }


    generateData = () => {

        let data = this.props.dashboard.entitiesPlans;
        let positem =0;

        let dataordered = [];
        let entityordered = {
            'name':data[0].entity,
            'id_entity':data[0].id_entity,
            'canceled': 0,
            'declined': 0,
            'soldit': 0,
            'plans': [],
            'amounts': {
                'canceled': 0,
                'declined': 0,
                'soldit': 0
            }
        };
        let entityName = data[0].id_entity;
        while(positem < data.length) {
            while ((positem < data.length) && entityName === data[positem].id_entity) {
                let plan = data[positem].planId;
                let planordered = {
                    'name':data[positem].planName,
                    'planId':data[positem].planId,
                    'canceled': 0,
                    'declined': 0,
                    'soldit': 0,
                    'amounts': {
                        'canceled': 0,
                        'declined': 0,
                        'soldit': 0
                    }
                };
                while ((positem < data.length) && (entityName === data[positem].id_entity) && (plan === data[positem].planId) ) {
                    switch (data[positem].status) {
                    case FINANCING_STATUSES.CANCELED:
                        entityordered.canceled += data[positem]['total'];
                        entityordered.amounts.canceled += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.amounts.canceled += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.canceled += data[positem]['total'];
                        break;
                    case FINANCING_STATUSES.DECLINED:
                        entityordered.declined += data[positem]['total'];
                        entityordered.amounts.declined += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.amounts.declined += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.declined = data[positem]['total'];
                        break;
                    case FINANCING_STATUSES.SOLD_PRODUCT:
                        entityordered.soldit += data[positem]['total'];
                        entityordered.amounts.soldit += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.amounts.soldit += parseFloat(data[positem]['amounts']) + (parseFloat(process.env.MIX_TAXES) * parseFloat(data[positem]['amounts']));
                        planordered.soldit = data[positem]['total'];
                        break;
                    default:
                        break;
                    }
                    positem ++;
                }
                entityordered.plans.push(planordered);
            }
            dataordered.push(entityordered);
            this.state.showEntityDetails.push(false);

            if (positem < data.length) {
                entityName = data[positem].id_entity;
                entityordered = {
                    'name':data[positem].entity,
                    'id_entity':entityName,
                    'canceled': 0,
                    'declined': 0,
                    'soldit': 0,
                    'plans': [],
                    'amounts': {
                        'canceled': 0,
                        'declined': 0,
                        'soldit': 0
                    }
                };
            }
        }

        return dataordered;
    };



    renderApprovedByEntity() {
        return (
            <div style={{maxHeight: 'inherit', padding: '20px'}} className="user-logs-box mgL0 mgR0 mgB0 text-center">
                <div style={{top: '49%', left: '41.5%'}} className="m-auto position-sticky">
                    <p className="subtitle-1 mgB0"><strong>{this.state.approvedByEntityData[0].data.reduce((total, num) => {
                        return total + num;
                    }, 0)}</strong></p>
                    <p style={{fontSize: '12px'}} className="mgB0">Solicitudes aprobadas</p>
                </div>
                <DoughnutChart
                    yLabel={'Financiamientos aprobados'}
                    labels={this.state.approvedByEntityLabels}
                    data={this.state.approvedByEntityData}
                />
            </div>
        );
    }

    renderApprovedAmountByEntity() {
        return (
            <div style={{maxHeight: 'inherit', padding: '20px'}} className="user-logs-box mgL0 mgR0 mgB0 text-center">
                <div style={{top: '49%', left: '41.5%'}} className="m-auto position-sticky">
                    <p style={{fontSize: '14px'}} className="subtitle-1 mgB0"><strong>RD$ {money(this.state.approvedAmountByEntityData[0].data.reduce((total, num) => {
                        return total + num;
                    }, 0), false, false)}</strong></p>
                    <p style={{fontSize: '12px'}} className="mgB0">Acumulado</p>
                </div>
                <DoughnutChart
                    yLabel={'Monto acumulado'}
                    labels={this.state.approvedAmountByEntityLabels}
                    data={this.state.approvedAmountByEntityData}
                    tooltipsCallback={(tooltipItem, data) => {
                        return data.labels[tooltipItem.index] + ': RD$ ' + money(data.datasets[0].data[0]);
                    }}
                />
            </div>
        );
    }

    renderDeclinedByEntity() {
        return (
            <div style={{maxHeight: 'inherit', padding: '20px'}} className="user-logs-box mgL0 mgR0 mgB0 text-center">
                <div style={{top: '49%', left: '41.5%'}} className="m-auto position-sticky">
                    <p className="subtitle-1 mgB0"><strong>{this.state.declinedByEntityData[0].data.reduce((total, num) => {
                        return total + num;
                    }, 0)}</strong></p>
                    <p style={{fontSize: '12px'}} className="mgB0">Solicitudes que no proceden</p>
                </div>
                <DoughnutChart
                    yLabel={'Financiamientos que no proceden'}
                    labels={this.state.declinedByEntityLabels}
                    data={this.state.declinedByEntityData}
                />
            </div>
        );
    }

    renderDeclinedByReason() {
        return (
            <div style={{maxHeight: 'inherit', padding: '70px 20px 20px 20px'}} className="user-logs-box mgL0 mgR0 mgB0 text-center">
                <DoughnutChart
                    yLabel={'Por qué no procede?'}
                    labels={this.state.declinedByReasonLabels}
                    data={this.state.declinedByReasonData}
                />
            </div>
        );
    }

    renderCounts() {
        let m = moment().locale('es');
        return (
            <div style={{minHeight: '150px', paddingLeft: '110px'}} className="row">
                <div style={{minHeight: '110px'}} className="col-md-2 text-center  bordered mgB pdL2 col-12">
                    <div className="vertical--center">
                        <p className="mgB0 fsize-14">Vendidas <strong>{this.props.dashboard.counts.finishedFinancings.current.count}</strong></p>
                        <p className={`mgB0 ${(this.props.byMonth === true)
                            ?
                            this.props.dashboard.counts.finishedFinancings.current.total >= this.props.dashboard.counts.finishedFinancings.prior.total ? 'value-up' : 'value-down'
                            :'color-blue'}`}>
                            <strong>{'RD$ '+money(this.props.dashboard.counts.finishedFinancings.current.total)}</strong></p>
                        {this.props.byMonth === true &&
                            <React.Fragment><p className="mgB0 fsize-12">{'Mes Anterior RD$ ' + money(this.props.dashboard.counts.finishedFinancings.prior.total) }</p>
                                <p className="mgB0 fsize-12"><strong>{m.localeData().months()[this.props.monthSelected]}</strong></p>
                            </React.Fragment>}
                        {!this.props.byMonth &&
                            <p className="mgB0 fsize-14 fsbold"><span>({moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY')})</span></p>
                        }
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-2 text-center  bordered mgB pdL2 mgL2 col-12">
                    <div className="vertical--center">
                    <p className="mgB0 fsize-14">Firmadas <strong>{this.props.dashboard.counts.signedFinancings.current.count}</strong></p>
                    <p className={`mgB0 ${(this.props.byMonth === true)
                        ?
                        this.props.dashboard.counts.signedFinancings.current.total >= this.props.dashboard.counts.signedFinancings.prior.total ? 'value-up' : 'value-down'
                    :'color-blue'}`}>
                        <strong>{'RD$ '+money(this.props.dashboard.counts.signedFinancings.current.total)}</strong></p>
                        {this.props.byMonth === true &&
                        <React.Fragment> <p className="mgB0 fsize-12">{'Mes Anterior RD$ ' + money(this.props.dashboard.counts.signedFinancings.prior.total) }</p>
                        <p className="mgB0 fsize-12"><strong>{m.localeData().months()[this.props.monthSelected]}</strong></p>
                        </React.Fragment>}
                        {!this.props.byMonth &&
                           <p className="mgB0 fsize-14 fsbold"><span>({moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY')})</span></p>
                        }
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-2 text-center  bordered mgB pdL2 mgL2 col-12">
                    <div className="vertical--center">
                    <p className="mgB0 fsize-14">Aprobadas <strong>{this.props.dashboard.counts.approvedFinancings.current.count}</strong></p>
                    <p className={`mgB0 ${(this.props.byMonth === true)
                        ?
                        this.props.dashboard.counts.approvedFinancings.current.total >= this.props.dashboard.counts.approvedFinancings.prior.total ? 'value-up' : 'value-down'
                    : 'color-blue' }`}>
                        <strong>{'RD$ '+money(this.props.dashboard.counts.approvedFinancings.current.total)}</strong></p>
                        {this.props.byMonth === true &&
                        <React.Fragment><p className="mgB0 fsize-12">{'Mes Anterior RD$ ' + money(this.props.dashboard.counts.approvedFinancings.prior.total)}</p>
                        <p className="mgB0 fsize-12"><strong>{m.localeData().months()[this.props.monthSelected]}</strong></p>
                        </React.Fragment>}
                        {!this.props.byMonth &&
                           <p className="mgB0 fsize-14 fsbold"><span>({moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY')})</span></p>
                        }
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-2 text-center  bordered mgB pdL2  mgL2 col-12">
                    <div className="vertical--center">
                    <p className="mgB0 fsize-14">Canceladas <strong>{this.props.dashboard.counts.canceledFinancings.current.count}</strong></p>
                    <p className={`mgB0 ${(this.props.byMonth === true)
                        ? this.props.dashboard.counts.canceledFinancings.current.total <= this.props.dashboard.counts.canceledFinancings.prior.total ? 'value-up' : 'value-down'
                    : 'color-blue'}`}>
                        <strong>{'RD$ '+money(this.props.dashboard.counts.canceledFinancings.current.total)}</strong></p>
                        {this.props.byMonth === true &&
                        <React.Fragment><p className="mgB0 fsize-12">{'Mes Anterior RD$ ' + money(this.props.dashboard.counts.canceledFinancings.prior.total) }</p>
                        <p className="mgB0 fsize-12"><strong>{m.localeData().months()[this.props.monthSelected]}</strong></p>
                        </React.Fragment> }
                        {!this.props.byMonth &&
                           <p className="mgB0 fsize-14 fsbold"><span>({moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY')})</span></p>
                        }
                    </div>
                </div>
                <div style={{minHeight: '110px'}} className="col-md-2 text-center bordered mgB pdL2  mgL2 col-12">
                    <div className="vertical--center">
                    <p className="mgB0 fsize-14">No proceden <strong>{this.props.dashboard.counts.declinedFinancings.current.count}</strong></p>
                    <p className={`mgB0 ${(this.props.byMonth === true)
                        ? this.props.dashboard.counts.declinedFinancings.current.total <= this.props.dashboard.counts.declinedFinancings.prior.total ? 'value-up' : 'value-down'
                    : 'color-blue'}`}>
                        <strong>{'RD$ '+money(this.props.dashboard.counts.declinedFinancings.current.total)}</strong></p>
                        {this.props.byMonth === true &&
                        <React.Fragment>
                            <p className="mgB0 fsize-12">{'Mes Anterior RD$ ' + money(this.props.dashboard.counts.declinedFinancings.prior.total) }</p>
                        <p className="mgB0 fsize-12"><strong>{m.localeData().months()[this.props.monthSelected]}</strong></p>
                        </React.Fragment>}
                        {!this.props.byMonth &&
                           <p className="mgB0 fsize-14 fsbold"><span>({moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY')})</span></p>
                        }
                    </div>
                </div>
            </div>
        );
    }


    renderTopRequested(){
        let data = this.props.dashboard.topResquest;

        let maxSuggestVal = data.length > 0 ? data[0].total + 5 : 1;

        let values = data.length > 0 ? data.map((item, index) => {
            return (
                    <div className="row" style={{marginTop:'2px', height:'35px'}} key={`plan_${index}`}>
                        <div className="col-md-6 fsize-12 border-md-bottom border-md-left" style={{paddingTop:'5px'}}>
                            {item.planName}
                        </div>
                        <div className="col-md-6">
                            <div style={{width:((item.total / maxSuggestVal) * 100) + '%', backgroundColor:'#0d69a0', color:'#0d69a0', height:'30.5px', marginTop:'3px', paddingBottom:'2px', float:'left'}} className="text-center">
                               <div></div>
                            </div>
                            <p style={{color:'#0d69a0', marginLeft:(((item.total / maxSuggestVal) * 100) +5 ) + '%'}}>{item.total}</p>
                        </div>
                </div>
                    );
                })
                 :
                <React.Fragment>
                    <div className="row" style={{marginTop:'2px', height:'35px'}}>
                        <div className="col-12 fsize-12 text-center border-md-left" style={{paddingTop:'5px'}}>
                           Sin Datos que mostrar
                        </div>
                    </div>
                </React.Fragment>;
        return(
            <React.Fragment>
                <div className="row mgB2 fsbold" style={{boxShadow:'0 2px 15px 0 rgb(0 0 0 / 15%)'}}>
                    <p className="pdL2 pdT15">Los 10 planes mas solicitados <span style={{paddingLeft:'30px'}}>({this.props.byMonth === true ? this.m.localeData().months()[this.props.monthSelected] : (moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY'))})</span></p>
                </div>
                { values}
            </React.Fragment>
        );

    }

    renderApproveVsDeclined() {

        let data = this.props.dashboard.approveVSdecline;
        let maxApproved = data.length > 0 ? _.maxBy(data, (elem)=> { return elem.approved; }).approved + 5 : 0;
        let maxDeclined = data.length > 0 ? _.maxBy(data, (elem)=> { return elem.declined; }).declined + 5 : 0;

        let values = data.length > 0 ? data.map((item, index) => {
            return (
                    <div className="row" style={{marginTop:'2px', height:'35px'}} key={`entity_${index}`}>
                        <div className="col-md-4 fsize-12 border-md-bottom border-md-left" style={{paddingTop:'5px'}}>
                            {item.entity}
                        </div>
                        <div className="col-md-8">
                            <div style={{width:'50%',float:'left'}} className="border-md-right">
                                <div style={{width:((item.approved / maxApproved) * 100) + '%', backgroundColor:'#03bb00', color:'white', height:'30.5px', marginLeft:(100 - ((item.approved / maxApproved) * 100)) + '%'}} className="text-center">
                                    <div className="fsize-12" style={{paddingTop:'5px'}}>{item.approved > 0 ? item.approved : ''}</div>
                                </div>
                            </div>
                            <div style={{marginLeft:'50%'}}>
                                <div style={{width:((item.declined / maxDeclined) * 100) + '%', backgroundColor:'#d81414', color:'white', height:'30.5px',paddingTop:'2px', paddingBottom:'2px'}} className="text-center">
                                    <div className="fsize-12" style={{paddingTop:'5px'}}>{item.declined > 0 ? item.declined : '' }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
                }):
            <React.Fragment>
                <div className="row" style={{marginTop:'2px', height:'35px'}}>
                    <div className="col-12 fsize-12 text-center border-md-left" style={{paddingTop:'5px'}}>
                       Sin Datos que mostrar
                    </div>
                </div>
            </React.Fragment>;

        return(
            <React.Fragment>
                 <div className="row mgB2 fsbold pdL2" style={{boxShadow:'0 2px 15px 0 rgb(0 0 0 / 15%)'}}>
                     <p className="pdL2 pdT15">Aprobadas vs Declinadas  <span style={{paddingLeft:'30px'}}>({this.props.byMonth === true ? this.m.localeData().months()[this.props.monthSelected] : (moment(this.props.dateFilters.from).format('DD/MM/YYYY') + ' - ' + moment(this.props.dateFilters.to).format('DD/MM/YYYY'))})</span></p>
                 </div>
                {values}
             </React.Fragment>
         );
    }

    showplans = (val) => {
       let position = this.dataEntities.findIndex((el) => el.id_entity === val);
       let padre = document.getElementById('ent' + val);
       let padreGeneral = document.getElementById('parent' + val);
       if (padre.classList.contains('mdi-plus-circle')) {
           padre.classList.remove('mdi-plus-circle');
           padre.classList.add('mdi-minus-circle');
           this.dataEntities[position].plans.reverse().forEach(element => {
               let divele = document.createElement('div');
               divele.classList.add('row');
               divele.setAttribute("style", "height:35px; margin-left:3px; margin-right:2px");

               let divname = document.createElement('div');
               divname.classList.add('col-md-6', 'fsize-14', 'border-md-top', 'fsbold');
               divname.setAttribute("style", "height:35px");
               divname.innerHTML="<p style='padding-left:30px; padding-top:10px'>" + element.name + "</p>";
               divele.appendChild(divname);

               let divsol = document.createElement('div');
               divsol.classList.add('col-md-2', 'fsize-14', 'border-md-top', 'border-md-left', 'text-center');
               divsol.setAttribute("style", "height:35px");
               divsol.innerHTML="<p style='padding-top:10px'>" + (!this.state.showAmounts ? element.soldit : money(element.amounts.soldit.toFixed(2))) +"</p>";
               divele.appendChild(divsol);

               let divapp = document.createElement('div');
               divapp.classList.add('col-md-2', 'fsize-14', 'border-md-top', 'border-md-left', 'text-center');
               divapp.setAttribute("style", "height:35px");
               divapp.innerHTML="<p style='padding-top:10px'>" + (!this.state.showAmounts ? element.canceled : money(element.amounts.canceled.toFixed(2))) +"</p>";
               divele.appendChild(divapp);

               let divdec = document.createElement('div');
               divdec.classList.add('col-md-2', 'fsize-14', 'border-md-top', 'border-md-left', 'text-center');
               divdec.setAttribute("style", "height:35px");
               divdec.innerHTML="<p style='padding-top:10px'>" + (!this.state.showAmounts ? element.declined : money(element.amounts.declined.toFixed(2))) +"</p>";
               divele.appendChild(divdec);

               if (position < (this.dataEntities.length - 1)) {
                   padreGeneral.insertAdjacentElement('afterend', divele);
               } else {
                padreGeneral.parentNode.appendChild(divele);
               }
           });
           this.dataEntities[position].plans.reverse();
       } else {
            padre.classList.add('mdi-plus-circle');
            padre.classList.remove('mdi-minus-circle');
            let totaltodelete = this.dataEntities[position].plans.length;
            for (let index = 0; index < totaltodelete; index++) {
                padreGeneral.parentNode.removeChild(padreGeneral.nextSibling);
            }
       }
    };

    renderEntitiesPlan(){
        let entitiesdata = this.dataEntities;
        let entities = entitiesdata.map((elem, index, entitiesdata) => {
            return (
                <React.Fragment>
                    <div className="row" style={{height:'35px', marginLeft:'3px', marginRight:'2px'}}
                    id={'parent' + elem.id_entity} key={`entities_${index}`}>
                        <div className={`col-md-6 fsize-14 border-md-top ${(index === entitiesdata.length - 1) ? 'border-md-bottom' :'' } `} style={{height:'35px'}} >
                        <p style={{paddingTop:'5px'}}> <i className={` ${PLUS_CIRCLE_ICON} color-blue fsize-14`}
                        id={'ent'+elem.id_entity}
                        onClick={() => this.showplans(elem.id_entity)}/> {elem.name} </p>
                        </div>
                        <div className={`col-md-2 fsize-14 border-top border-md-left text-center fsbold  ${(index === entitiesdata.length - 1) ? 'border-md-bottom' :'' }`} style={{backgroundColor:'#0d69a0', height:'35px'}}  >
                          <p style={{paddingTop:'5px', color:'white'}}>  {!this.state.showAmounts ? elem.soldit : money(elem.amounts.soldit.toFixed(2))}</p>
                        </div>
                        <div className={`col-md-2 fsize-14 border-top border-md-left text-center fsbold ${(index === entitiesdata.length - 1) ? 'border-md-bottom' :'' }`} style={{backgroundColor:'#0d69a0', height:'35px'}} >
                           <p style={{paddingTop:'5px', color:'white'}}> {!this.state.showAmounts ? elem.canceled : money(elem.amounts.canceled.toFixed(2))} </p>
                        </div>
                        <div className={`col-md-2 fsize-14 border-top border-md-left text-center fsbold ${(index === entitiesdata.length - 1) ? 'border-md-bottom' :'' }`} style={{backgroundColor:'#0d69a0', height:'35px'}}  >
                        <p style={{paddingTop:'5px', color:'white'}}>  {!this.state.showAmounts ? elem.declined : money(elem.amounts.declined.toFixed(2))} </p>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
        return(
            <React.Fragment>
                <div className="white-background border-md-left border-md-right border-md-bottom" 
                    id="entvsplan" style={{height:'400px'}}>
                 <div className="row mgB2 fsbold pdL2" style={{boxShadow:'0 2px 15px 0 rgb(0 0 0 / 15%)', marginLeft:'3px', marginRight:'2px'}}>
                     <div className="col-7 pdT15 pdB2">Historial de solicitudes por entidades </div>
                     <div className="fsize-12 fsbold text-right col-3 pdT15 color-blue">
                        <Checkbox
                                squared={true}
                                labelStyle={{fontSize:'12px', float:'left', marginLeft:'40%', width:'30%'}}
                                label="Cantidad"
                                checked={this.state.changeToTotal}
                                onChange={() => this.setState({changeToTotal:true, changeByMoney: false, showAmounts: false})}/>
                    </div><div className="fsize-12 fsbold text-right col-2 pdT15 color-blue">
                        <Checkbox
                                labelStyle={{fontSize:'12px', float:'left', width:'30%'}}
                                label="RD$"
                                squared={true}
                                checked={this.state.changeByMoney}
                                onChange={() => this.setState({changeToTotal:false, changeByMoney: true, showAmounts: true})}/>
                    </div>
                 </div>
                 <div className="row text-center" style={{marginTop:'2px', height:'35px', marginLeft:'6px', marginRight:'4px', paddingRight:'10px'}}>
                    <div className="col-md-6 fsize-14 border-md-bottom fsbold" style={{paddingTop:'5px'}}>
                        Entidad Financiera
                    </div>
                    <div className="col-md-2 fsize-14 border-md-bottom border-md-left fsbold" style={{paddingTop:'5px'}}>
                        Completadas
                    </div>
                    <div className="col-md-2 fsize-14 border-md-bottom border-md-left fsbold" style={{paddingTop:'5px'}}>
                        Canceladas
                    </div>
                    <div className="col-md-2 fsize-14 border-md-bottom border-md-left fsbold " style={{paddingTop:'5px'}}>
                        Declinadas
                    </div>
                </div>
                <div style={{height:'285px', overflowY:'scroll', marginLeft:'3px', marginRight:'2px'}} className="border-md-bottom">{entities}</div>
                </div>
             </React.Fragment>
         );
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mgB">
                    <div className="col-12 mgB">
                        {this.renderCounts()}
                    </div>
                    <div className="col-md-8 col-12 mgB">
                        {this.renderLineChart()}
                    </div>
                    <div className="col-md-4 col-12 bordered mgB white-background">
                        {this.renderTopRequested()}
                    </div>
                    <div className="col-md-8 mgB ">
                        {this.renderEntitiesPlan()}
                    </div>
                    <div className="col-md-4 mgB white-background pdL2 bordered">
                        {this.renderApproveVsDeclined()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Properties validations.
 */
DashboardBody.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }),
    dashboard: PropTypes.object
};

export default DashboardBody;
