import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'md-react-currency-input';

/**
* Represents a simple text input.
*/
export default class MoneyInput extends React.PureComponent {
    /**
     * Sets the state and make the binds as needed.
     *
     * @param {props} the required React properties.
     */
    constructor(props) {
        super(props);

        this.state = {labelFocused: false};

        
    }
    
    onBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value);
        }
        this.changeFocus(false);
    };

    onFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e.target.value);
        }
        this.changeFocus(true);
    };

    /**
    * Fired once the input focus changes.
    *
    * @param {bool} labelFocused determines if the element is focused or not.
    */
    changeFocus(labelFocused) {
        this.setState({labelFocused});
    }

    handleChange = (event, maskedValud, floatValue) => {
        this.props.onChange(floatValue);
    };


    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {

        return (
            <label className="form-label">
                {this.props.floatingLabel &&
                    <span className={`label ${this.props.dangerLabel ? 'text-danger' : ''}`}>
                        {this.props.floatingLabel}
                    </span>
                }
                {this.props.icon &&
                    <span className="text-input__with-icon"><i className={ this.props.icon } /></span>
                }
                <CurrencyInput
                    precision="2"
                    className={`form-control white-background ${this.props.className}`}
                    prefix={this.props.symbol}
                    value={this.props.value}
                    style={this.props.inputStyle}
                    onChangeEvent={this.handleChange}/>
            </label>
        );
    }
}

MoneyInput.defaultProps = { symbol: 'RD$ ' };

/**
 * Text input properties validation.
 */
MoneyInput.propTypes = {
    symbol: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
};
