import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

/* eslint-disable no-unused-vars */
import 'chartjs-plugin-datalabels';

import Media from 'react-media';

import {
    getDefaultOptions,
    createColoredDataset
} from './chart.default';

export default class LineChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: createColoredDataset(this.props.data, this.props.labels),
            options: Object.assign({}, getDefaultOptions(this.props.data.length > 1))
        };

        this.state.data.datasets[0]['datalabels'] = {
            align: 'end',
            anchor: 'end',
            color: '#74A90A'
        };

        this.state.data.datasets[1]['datalabels'] = {
            align: 'start',
            anchor: 'start',
            color: '#D1153B'
        };


        this.state.options['lineTension'] = this.props.lineTension || 0;

        this.state.options['plugins'] = {
            datalabels: {
                formatter: (value, context) => {
                    if (context.dataIndex === 1) {
                        return '';
                    }
                    if (context.dataset.data[context.dataIndex - 1] === value) {
                        return '0%';
                    }
                    if (context.dataset.data[context.dataIndex - 1] === 0) {
                        return '';
                    }

                    return (((value / context.dataset.data[context.dataIndex - 1]) * 100) - 100) + '%';
                }
            }
        };

        this.state.options['scales']['yAxes'][0]['ticks']['suggestedMax'] = this.props.suggestedMax;
        this.state.options['scales']['yAxes'][0]['offset'] = true;

        if (this.props.tooltipsCallback) {
            this.state.options['tooltips'] = {callbacks: {label: this.props.tooltipsCallback}};
        }

    }

    /**
     * Renders the element.
     *
     * @return {ReactComponent}
     */
    render() {
        return (
            <div style={{margin: 'auto'}}>
                <div className="row">
                    <div className="col-md-12">
                        <p><strong>{this.props.yLabel || ''}</strong></p>
                    </div>
                </div>
                <Media query='(max-width: 600px)'>
                    {matches =>
                        matches ? (
                            <Line
                                className={`chart line-chart ${this.props.className}`}
                                data={this.state.data}
                                options={Object.assign({}, this.state.options)}
                                width={window.screen.width * 0.9}
                                height={350}
                            />
                        ) : (
                            <Line
                                className={`chart line-chart ${this.props.className}`}
                                data={this.state.data}
                                options={Object.assign({}, this.state.options)}
                                width={window.screen.width * 0.5}
                                height={350}
                            />
                        )
                    }
                </Media>
                <p className='text--align-center'>{this.props.xLabel || ''}</p>
            </div>

        );
    }
}

LineChart.defaultProps = {className: '', update: false};


/**
 * Properties validations.
 */
LineChart.propTypes = {
    lineTension: PropTypes.number,
    className: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.array.isRequired,
    update: PropTypes.bool
};
