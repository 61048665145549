import React from 'react';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';

import {
    FormsyInputWrapper,
    PasswordInput,
} from 'common/components/inputs';

import {PASSWORD_ICON} from 'common/constants/icon.constant';

class AuthStepJumpCheckout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            //email: '',
            passwordConfirmation: '',
            isRegistered: this.props.emailValidated,
            financingData: {},
            showCancelModal: false,
            canSubmit: props.financingForm.canSubmitStepTwo
        };
    }

    onFieldChange = (field, value) => {
        this.setState({[field]: value});
    };

    canSubmit = (canSubmit) => {
        this.setState({ canSubmit: canSubmit });
    };

    onSubmit = () => {
        let financingData = Object.assign({}, this.state.financingData);
        this.props.onSubmit(financingData, {
            registering: (this.state.passwordConfirmation !== null || this.state.passwordConfirmation !== undefined ) ,
            email: this.state.financingData.email,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation
        });
    };

    componentDidMount() {
        this.setState({
            financingData:this.props.financingForm,
            canSubmit: this.props.financingForm.canSubmitStepTwo
        });
    }

    validSubmit = () => {
        return this.state.canSubmit;
    };

    render() {
        return (
            <React.Fragment>
                <h2 className="text-center">Ya terminamos</h2>
                <div className="mgT3 white-background">
                    <div>
                        <ol style={{lineHeight:'100%'}} className="pdT2">
                            <li>
                                <p>Podrá dar seguimiento a esta solicitud ingresando a <strong className="color-blue">electropay.do</strong> con los siguientes datos: </p>
                            </li>
                        </ol>
                        
                        <p>Usuario <strong>{this.state.financingData.email}</strong></p>
                    </div>
                            <Formsy
                                onValid={() => this.canSubmit(true)}
                                onInvalid={() => this.canSubmit(false)}>
                                <FormsyInputWrapper
                                    required
                                    className="mgB2 text-left"
                                    name="password"
                                    customError={this.props.error}
                                    value={this.state.password}>
                                    <PasswordInput
                                        icon={PASSWORD_ICON}
                                        label="Ingrese una contraseña"
                                        dangerLabel={this.props.error !== null}
                                        value={this.state.password}
                                        onChange={(val) => this.setState({password: val})}
                                    />
                                </FormsyInputWrapper>
                             <FormsyInputWrapper
                                    required
                                    className="mgB2"
                                    name="passwordConfirmation"
                                    value={this.state.passwordConfirmation === this.state.password}
                                    validations="isTrue"
                                    validationError="Las contraseñas deben ser iguales">
                                    <PasswordInput
                                        icon={PASSWORD_ICON}
                                        label="Confirme la contraseña"
                                        dangerLabel={this.state.passwordConfirmation !== this.state.password}
                                        value={this.state.passwordConfirmation}
                                        onChange={(val) => this.setState({passwordConfirmation: val})}
                                    />
                                </FormsyInputWrapper>
                            </Formsy>
                        <div>
                        <ol style={{lineHeight:'100%'}} start="2" className="pdB2 pdT2">
                            <li>
                                <p>Le enviaremos un mensaje a <strong>{this.state.financingData.email}</strong> para la validación de su correo electrónico y procesar su solicitud. </p>
                            </li>
                        </ol>
                        </div>
                     
                </div>
                <div className="row">
                    <div className="col-md-3 mgT">
                        <button
                            onClick={this.props.onClose}
                            className="btn btn-outline w-100">
                            Volver
                        </button>
                    </div>
                    <div className="col-md-3 mgT" />
                    <div className="col-md-3 mgT">
                    </div>
                    <div className="col-md-3 mgT">
                        <button
                            onClick={this.onSubmit}
                            disabled={(this.state.password === '' || this.state.passwordConfirmation === ''  || this.state.password !== this.state.passwordConfirmation)}
                            className={((this.state.password !== '' && this.state.passwordConfirmation !== ''  && this.state.password === this.state.passwordConfirmation)) ? 'btn btn-primary w-100' :'btn btn-clickable-pseudo-disabled w-100'}>
                            Finalizar
                        </button>
                    
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    null
)(AuthStepJumpCheckout);
