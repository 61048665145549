'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/pagination';

import {USERS_PER_PAGE} from 'common/constants/enums.constant';

import FinancingCard from 'common/components/financing-card';


/**
 * Component responsible of rendering the list depending on it's type.
 */
export default class FinancingsCardView extends React.PureComponent {

    /**
    * Renders the element.
    *
    * @return {ReactComponent}
    */
    render() {
        const pageCount = Math.ceil(this.props.pagination.total / USERS_PER_PAGE);
        return (
            <div className={`layout-body__content`}>
                 <p style={{fontSize:'14px', color:'#124b6f', fontWeight:'bold'}} className="text-center">(Haga click sobre la solicitud para detalles)</p>
                <div className="row">
                    {this.props.financings
                        .map((financing) => {
                            return (
                                <div
                                    key={"financing-key-" + financing.id}
                                    style={{marginBottom: '16px'}}
                                    className="col-md-6 col-12">
                                    <FinancingCard
                                        queryString={this.props.location.search}
                                        authUser={this.props.authUser}
                                        financing={financing}
                                        history={this.props.history}
                                    />
                                </div>
                            );
                        })
                    }
                    <div className="col-12">
                        <div className="pagination">
                            <Pagination
                                onPageChange={(page) => this.props.onFiltersChange('page', page.selected + 1) }
                                forcePage={this.props.pagination.currentPage}
                                pageCount={pageCount} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Properties validation.
 */
FinancingsCardView.propTypes = {
    onFiltersChange: PropTypes.func,
    financings: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object
};
