export const getError = state => state.financingEntityReducer.error;
export const getSimpleError = state => state.financingEntityReducer.simpleError;
export const getIsLoading = state => state.financingEntityReducer.loading;
export const getFinancingEntityCreated = state => state.financingEntityReducer.financingEntityCreated;
export const getFinancingEntityUpdated = state => state.financingEntityReducer.financingEntityUpdated;
export const getFinancingEntityDeleted = state => state.financingEntityReducer.financingEntityDeleted;
export const getFinancingEntityProfile = state => state.financingEntityReducer.singleFinancingEntity;
export const getPagination = state => state.financingEntityReducer.pagination;
export const getEntitiesFilters = state => state.financingEntityReducer.entitiesFilters;

export const getFinancingEntities = state => state.financingEntityReducer.financingEntities;
export const getFinancingEntitiesList = state => state.financingEntityReducer.financingEntitiesList;

export const getPlansAdded = state => state.financingEntityReducer.plansAdded;
export const getPlansUpdated = state => state.financingEntityReducer.plansUpdated;
export const getPlansDeleted = state => state.financingEntityReducer.plansDeleted;
export const getSelectedPlans = state => state.financingEntityReducer.selectedPlans;


export const getProvincesList = state => state.financingEntityReducer.listProvinces;
export const getCitiesList = state => state.financingEntityReducer.listMunicipality;

export const getBrancheOP = state => state.financingEntityReducer.branchOP;
export const getSelectedBranches = state => state.financingEntityReducer.selectedBranches;
